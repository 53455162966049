import React from "react";
import "./changePasswordHeader.scss";
import { ReactComponent as MobyanLogo } from "../../../assets/images/MobyanLogo.svg";

const ChangePasswordHeader = () => {
  return (
    <div className="ChangePasswordHeader">
      <MobyanLogo className={"Logo"} />
      {process.env.REACT_APP_AMBIENTE !== "PROD" && (
        <div className="Ambiente">{process.env.REACT_APP_AMBIENTE}</div>
      )}
    </div>
  );
};

export default ChangePasswordHeader;
