import React, {useContext, useEffect, useState} from 'react'
import Header from '../../../components/header/header'
import { HomeContext } from '../../../contexts/homeContext';
import { AuthContext } from "../../../contexts/auth";
import MultipleSelectCheckmarksId from '../../../components/seletorMultiploId/seletorMultiploId';
import './consultarPerfil.scss'
import { Breadcrumb, BreadcrumbItem } from '../../../components/breadcrumb/breadcrumb';
import Input from '../../../components/Input/Input';
import moment from 'moment';
import Button from '../../../components/Button/styles';
import { Tab, Tabs } from '@mui/material';
import { TabPanelProps } from '../types';
import EditarPerfilTab from './editarPerfilTab';
import EditarTelaTab from './editarTelaTab';
import { useNavigate } from 'react-router';
import { getPerfilById, postPerfil, putPerfilById } from '../services/services';
import { AxiosResponse } from 'axios';
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import Collapse from '../../../components/collapse/collapse';
import CloseIcon from "@mui/icons-material/Close";
import _ from 'lodash'
import { getModulos, getTelaByIdModulo, getFuncionaliadesDoPerfil } from '../services/services';
import { IUsuario } from '../types';

const IncluirPerfil = () => {
  const isIncluding = window.location.pathname.includes('IncluirPerfil');
  
  const { setMenuLateral, selectedPerfil } = useContext(HomeContext);
  const { funcionalidadeDaTelaTemPermissao } = useContext(AuthContext);
  const profileHasPermission = (funcionalidade: string) => funcionalidadeDaTelaTemPermissao(
    isIncluding ? "Inclusão de perfil" : "Edição de perfil",
    funcionalidade);

  const navigate = useNavigate()

  const localStatus = [Number(selectedPerfil.ativo).toString()] || ['1']

  const [status, setStatus] = useState(localStatus);
  const [nomePerfil, setNomePerfil] = useState(selectedPerfil?.nome ? selectedPerfil?.nome : '' );
  const [listaFuncionalidadesAtivas, setListaFuncionalidadesAtivas] = useState<number[]>([]);
  const [listaUsuariosPerfil, setListaUsuariosPerfil] = useState<IUsuario[]>([]);

  const [tabValue, setTabValue] = useState(0);
  
  const [criadoPor] = useState(selectedPerfil.usuarioCriacaoNomecompleto);
  const [dataCriacao] = useState(selectedPerfil.dataCriacao);

  const [modificadoPor] = useState(selectedPerfil.usuarioModificacaoNomeCompleto);
  const [dataModificacao] = useState(selectedPerfil.dataModificacao);

  const [modulosOptions, setModulosOptions] = useState<{name: string, id: string}[]>([]);
  const [modulo, setModulo] = useState<{name: string, id: string}[]>([]);

  const [telasOptions, setTelasOptions] = useState<{label: string, name: string, id: string}[]>([]);
  const [tela, setTela] = useState<{ label: string, id: string } | null>(null);

  const [dadosTabela, setDadosTabela] = useState<{name: string, id: string, value: boolean}[]>([]);
  const [loadingDadosTabela, setLoadingDadosTabela] = useState<boolean>(false);
  const [openAlertDadosTabelaError, setOpenAlertDadosTabelaError] = useState(false);
  const [msgAlertDadosTabelaError, setMsgAlertDadosTabelaError] = useState("Nenhuma funcionalidade encontrada");

  const [tipomsgAlert, setTipoMsgAlert] = useState<'success' | 'info' | 'warning' | 'error'>("success");
  const [msgAlert, setMsgAlert] = useState("");
  const [openAlert, setOpenAlert] = useState(false);

  const getPerfilWithId = async () => {
    if (!selectedPerfil?.id) return;

    try{
      const { data } = await getPerfilById(Number(selectedPerfil?.id)) as AxiosResponse;

      const funcionalidades = data?.funcionalidades?.map((i: any) => Number(i.id)) || [];
      const usuarios = data?.usuarios || [];

      setListaFuncionalidadesAtivas(funcionalidades);
      setListaUsuariosPerfil(usuarios);
      
    } catch(e){
      setListaFuncionalidadesAtivas([]);
      setListaUsuariosPerfil([]);
    }
  };

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const statusLista = [
    {
      name: 'Ativo',
      id: '1'
    }, 
    {
      name: 'Inativo',
      id: '0'
    }
  ];

  const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        className='TabPanel'
        {...other}
      >
        {value === index && (
          <div>
            {children}
          </div>
        )}
      </div>
    );
  };

  const handleIncludeButton = async () => await includeProfile();
  const handleEditButton = async () => await updateProfile();

  const includeProfile = async () => {
    try{

      const { data } = await postPerfil({ativo: !!status[0] ? Boolean(Number(status[0])) : false, nome: nomePerfil}) as AxiosResponse

      setMsgAlert(data)
      setTipoMsgAlert('success')
      setOpenAlert(true)
    } catch(e: any){
      setMsgAlert(e.response.status === 500 ? 'Ocorreu um erro interno, tente novamente mais tarde' : e)
      setTipoMsgAlert('error')
      setOpenAlert(true)
    }
  };

  const updateProfile = async () => {
    try{
      const { data } = await putPerfilById({
        nome: nomePerfil,
        ativo: !!status[0] ? Boolean(Number(status[0])) : false,
        funcionalidades: listaFuncionalidadesAtivas
      }, selectedPerfil.id) as AxiosResponse

      setMsgAlert(data)
      setTipoMsgAlert('success')
      setOpenAlert(true)
    } catch(e: any){
      const erros = Object.values(e.response.data.errors as string[]).map(item => item[0])

      setMsgAlert(erros.toString())
      setTipoMsgAlert('error')
      setOpenAlert(true)
    }
  };

  const handleGetModulos = async () => {
    try{
      const { data } = await getModulos({ativo: true}) as AxiosResponse;

      const options = data.map((item: {nome: string, id:string, ativo: boolean}) => {
        const {nome, id} = item
        return {name: nome, id: id.toString()}
      }).sort((a: any, b: any) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));

      setModulosOptions(options);

    }catch (e){
      setModulosOptions([]);
    }
  };

  const handleChangeModulo = (modulo: any[]) => {
    setModulo(modulo);
    setTela(null);
    setDadosTabela([]);

    if (modulo[0]?.id) {
      requestListaTelas(modulo[0].id);
    } else {
      setTelasOptions([]);
    };
  };

  const handleChangeTela = (tela: any) => {
    setTela(tela);
    setDadosTabela([]);
  };

  const requestListaTelas = async (idModulo?: string) => {
    const id = idModulo || modulo[0]?.id;
    if (!id) return;

    try{
      const { data } = await getTelaByIdModulo(Number(id)) as AxiosResponse;

      const options = data.map((item: {nome: string, id:string, ativo: boolean}) => {
        const {nome, id} = item;
        return {name: nome, label: nome, id: id.toString()};
      }).sort((a: any, b: any) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));

      setTelasOptions(options);

    }catch (e){
      setTelasOptions([]);
    }
  };
  
  const requestListaFuncionalidades = async () => {
    const id = tela?.id;
    if (!id) return;

    setLoadingDadosTabela(true);
    setOpenAlertDadosTabelaError(false);

    try {
      const { data } = await getFuncionaliadesDoPerfil(Number(id), selectedPerfil?.id) as AxiosResponse;

      const options = data.map((item: { nomeFuncionalidade: string, idFuncionalidade: string, perfilPossuiFuncionalidade: boolean }) => {
        const { idFuncionalidade, nomeFuncionalidade, perfilPossuiFuncionalidade } = item;

        return { id: idFuncionalidade.toString(), name: nomeFuncionalidade, value: perfilPossuiFuncionalidade };
      });

      setLoadingDadosTabela(false);
      setDadosTabela(options);

      if (!options.length) {
        setOpenAlertDadosTabelaError(true);
        setMsgAlertDadosTabelaError("Nenhuma funcionalidade encontrada");
      }

    } catch (e) {
      setLoadingDadosTabela(false);
      setOpenAlertDadosTabelaError(true);
      setMsgAlertDadosTabelaError("Nenhuma funcionalidade encontrada");
      setDadosTabela([]);
    }
  };

  const handleSwitchFuncionalidade = (value: boolean, idFuncionalidade: string) => {

    let listaFuncionalidades = [...listaFuncionalidadesAtivas];

    if (!!value) {
      if (!listaFuncionalidades.includes(Number(idFuncionalidade))) {
        listaFuncionalidades = [...listaFuncionalidades, Number(idFuncionalidade)].sort(function(a, b){return a-b});
      }
    } else {
      if (!!listaFuncionalidades.includes(Number(idFuncionalidade))) {
        listaFuncionalidades = listaFuncionalidades.filter((id: number) => id !== Number(idFuncionalidade));
      }
    }

    let newData = dadosTabela.map((t: {id: string, name: string, value: boolean}) => {
      if (t.id === idFuncionalidade) {
        return {
          ...t,
          value
        }
      }
      return t;
    });

    setListaFuncionalidadesAtivas(listaFuncionalidades);
    setDadosTabela(newData);
  };

  useEffect(() => {
    getPerfilWithId();
    if(_.isEmpty(selectedPerfil) && !isIncluding) navigate('/ConsultarPerfil');
    if(tabValue === 0 ) {
      handleGetModulos();
    }
  }, []);
  
  return(
    <section className="ConsultarPerfil">
      <Header setMenuLateral={() => setMenuLateral(true)} title={isIncluding ? 'Incluir Perfil' : `Editar Perfil (${selectedPerfil.nome})`} />
      <Breadcrumb>
        <BreadcrumbItem onClick={() => navigate("/ConsultarPerfil")}>Perfil</BreadcrumbItem>
        <BreadcrumbItem>{isIncluding ? 'Incluir Perfil' : 'Editar Perfil'}</BreadcrumbItem>
      </Breadcrumb>

      <div className="Container">
        <div className="IncluirPerfil">
          <MultipleSelectCheckmarksId
           nodata={"Nenhum status encontrado"}
           options={statusLista}
           value={status}
           onChange={(e: any) => setStatus(e)}
           placeholder={"Selecionar status"}
           id="multiple-checkbox-status"
           idOption="multiple-checkbox-option-status"
           labelId="multiple-checkbox-label-status"
           multiple={false}
           label='Status'
           className='IncluirPerfilSelectStatus'
           dimension='sm'
          />

          {!isIncluding && (
            <>
              <Input
                id='profileName'
                label='ID Oppay'
                dimension='sm'
                value={selectedPerfil?.idOppay || ""}
                readOnly
              />
              <Input
                id='profileName'
                label='ID Mobyan'
                dimension='sm'
                value={selectedPerfil?.id || ""}
                readOnly
              />
            </>
          )}

          <Input
           id='profileName' 
           label='Nome do Perfil' 
           dimension='sm' 
           value={nomePerfil} 
           onChange={(e) => setNomePerfil(e.target.value)}
          />
        </div>
        {!isIncluding && 
        <>
          <div className="IncluirPerfil">
            <div className='InputContainer'>
              <Input id='createdBy' label='Criado Por' dimension='sm' value={criadoPor} readOnly/>
            </div>
            <Input
             id='createdAt' 
             label='Data de Criação' 
             value={moment(dataCriacao).format('DD/MM/YYYY - HH:hh:ss')} 
             readOnly 
             dimension='sm'
            />
            <Input 
             id='modifiedAt' 
             label='Data de Modificaçao' 
             value={moment(dataModificacao).format('DD/MM/YYYY - HH:hh:ss')} 
             readOnly 
             dimension='sm'
            />
            <Input
             id='modifiedBy' 
             label='Modificado Por' 
             dimension='sm' 
             value={modificadoPor}
             readOnly
            />
          </div>
        
          <Tabs variant="fullWidth" className='TabsIncluirEditarPerfil' value={tabValue} onChange={handleChangeTab} aria-label="basic tabs example">
            <Tab label="Funcionalidades vinculadas ao perfil"  />
            <Tab label="Usuários vinculados ao perfil" />
            { window.innerWidth > 800 && <Tab className='FillingButton' disabled/>}
          </Tabs>

          <TabPanel value={tabValue} index={0}>
            <EditarTelaTab
             modulosOptions={modulosOptions}
             modulo={modulo}
             handleChangeModulo={handleChangeModulo}
             telasOptions={telasOptions}
             tela={tela}
             handleChangeTela={handleChangeTela}
             requestListaFuncionalidades={requestListaFuncionalidades}
             handleSwitchFuncionalidade={handleSwitchFuncionalidade}
             requestListaTelas={requestListaTelas}
             loadingDadosTabela={loadingDadosTabela}
             openAlertDadosTabelaError={openAlertDadosTabelaError}
             setOpenAlertDadosTabelaError={setOpenAlertDadosTabelaError}
             msgAlertDadosTabelaError={msgAlertDadosTabelaError}
             dadosTabela={dadosTabela}
            />
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <EditarPerfilTab listaUsuariosPerfil={listaUsuariosPerfil}/>
          </TabPanel>
        </>
        }
        <Collapse onClose={setOpenAlert} in={openAlert}>
          <Alert
            severity={tipomsgAlert}
            icon={<div />}
            variant="filled"
            sx={{
              mb: 2,
              bgcolor: tipomsgAlert === "success" ? "#178B319E" : "rgb(255, 35, 35, 0.7)",
              height: "67px",
              borderRadius: "15px",
              boxSizing: "border-box",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginTop: "20px",
              marginBottom: 0,
            }}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="medium"
                onClick={() => setOpenAlert(false)}
              >
                <CloseIcon
                  id={"ModalEncaminharOS-AlertCloseIcon"}
                  fontSize="inherit"
                  sx={{
                    color: "white",
                  }}
                />
              </IconButton>
            }
          >
            <span
              style={{
                color: "white",
                fontSize: "16px",
              }}
              dangerouslySetInnerHTML={{__html: msgAlert}}
            />
          </Alert>
        </Collapse>
        <div className='ButtonSalvarContainer'>
          {!!isIncluding &&
            <>
              {!!profileHasPermission("Criar novo registro") ?
                <Button
                  onClick={handleIncludeButton}
                  variant='primary'
                >
                  Salvar
                </Button>
                :
                <Button
                  variant='primary'
                  style={{ cursor: 'not-allowed', opacity: 0.5 }}
                  disabled={true}>
                  Salvar
                </Button>
              }
            </>
          }

          {!isIncluding &&
            <>
              {!!profileHasPermission("Salvar Registro") ?
                <Button
                  onClick={handleEditButton}
                  variant='primary'
                >
                  Salvar
                </Button>
                :
                <Button
                  variant='primary'
                  style={{ cursor: 'not-allowed', opacity: 0.5 }}
                  disabled={true}>
                  Salvar
                </Button>
              }
            </>
          }
        </div>
      </div>
    </section>
  )
}

export default IncluirPerfil