import React, { useState, useContext } from "react";
import "./encaminharOS.scss";
import { HomeContext } from "../../../contexts/homeContext";
import { AuthContext } from "../../../contexts/auth";
import Autocompletetecnicos from "../../../components/autocompleteTecnicos/autocompleteTecnicos";
import Header from "../../../components/header/header";
import CardOSList from "../../../components/cardOSList/cardOSList";
import Pagination from "../../../components/pagination/paginacao";
import Collapse from "../../../components/collapse/collapse";
import { getListaTecnicosByPrestador, encaminharOSs } from "../services/services";
import { Breadcrumb, BreadcrumbItem } from "../../../components/breadcrumb/breadcrumb";
import _, { isEmpty } from "lodash";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AxiosResponse } from "axios";
import { IOrdemServico } from "../../../contexts/types/farolTypes";
import { ITecnico } from "../types";
import { siteTheme } from "../../../styles/themeVariables";
import { IOrdemServicoEncaminhada } from "../../farol/types";
import FullscreenLoading from "../../../components/fullscreenLoading/fullscreenLoading";

const { color } = siteTheme

const EncaminharOS = () => {
  /*eslint-disable */
  
  const {
    listaTecnicosByPrestadorTratada,
    setListaTecnicos,
    setMenuLateral,
    osselected,
    getOSsByID,
    setSelectedTecnico,
    handleSetOSSelected
  } = useContext(HomeContext)
  const { user } = useContext(AuthContext);

  const { idUsuario } = user!

  const [tecnicoSelecionado, setTecnicoSelecionado] = useState<ITecnico | null>(null);
  const [prestadorSelecionado, setPrestadorSelecionado] = useState(0)
  const [ossParaEncaminhar, setOssParaEncaminhar] = useState<number[]>(osselected);
  const [Seriais, setSeriais] = useState<string[]>([] as string[])
  const [tipomsgAlert, setTipoMsgAlert] = useState<'success' | 'info' | 'warning' | 'error'>("success");
  const [msgAlert, setMsgAlert] = useState("OS encaminhada com  sucesso!");
  const [openAlert, setOpenAlert] = useState(false);

  const [msgAlertSuccess, setMsgAlertSuccess] = useState("OS encaminhada com  sucesso!");
  const [msgAlertError, setMsgAlertError] = useState("OS encaminhada com  sucesso!");
  const [openAlertSuccess, setOpenAlertSuccess] = useState(false);
  const [openAlertError, setOpenAlertError] = useState(false);
  const [disableActions, setDisableActions] = useState(false);
  const [allSelected, setAllSelected] = useState(false);
  const [loading, setLoading] = useState(false)

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const navigate = useNavigate()

  const ListOs: IOrdemServico[] = getOSsByID(ossParaEncaminhar)

  const listaOSPaginada = () => {
    const firstPageIndex = (currentPage - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;
    return getOSsByID(osselected).slice(firstPageIndex, lastPageIndex);
  };

  const requestListaTecnicos = async () => {
  try{
    const listaTecnicoPorPrestador = await createListaTecnicos(getOSsByID(osselected)[0].idPrestador)

    setListaTecnicos(listaTecnicoPorPrestador);
  } catch(e){
    setListaTecnicos([])
  }

  };

  const createListaTecnicos = async (id: number) => {
    try {
      let retorno = await getListaTecnicosByPrestador(id) as AxiosResponse;
      return retorno?.data
    } catch (error) {
      return []
    }
  }

  const handleSetOSSelectedLocal = (item: IOrdemServico[] | IOrdemServico) => {
    if (_.isArray(item)) {
      setOssParaEncaminhar(item.map((i) => i.chamado));
      setSeriais(item.map((i) => i.numeroSerie));
      return
    }

    let ostoselect: number[]

    ostoselect = [...ossParaEncaminhar];
    let localSeriais = Seriais

    if(localSeriais.includes(item.numeroSerie)) {
      localSeriais = localSeriais.filter((filteredItem) => filteredItem !== item.numeroSerie)
    } else {
      localSeriais.push(item.numeroSerie)
    }

    setSeriais(localSeriais)
    
    if (ostoselect!.includes(item.chamado)) {
      ostoselect = ostoselect!.filter((idselected: number) => idselected !== item.chamado);
    } else {
      ostoselect!.push(item.chamado);
    }

    setOssParaEncaminhar(ostoselect);
  };

  const handleClickEncaminhar = async () => {
    //Validações
    setLoading(true)

    if (!!openAlert) {
      setOpenAlert(false);
    }

    if (_.isEmpty(tecnicoSelecionado)) {
      setTipoMsgAlert("error");
      setMsgAlertError("Selecione um técnico!");
      setOpenAlertError(true);
      setLoading(false)
      return;
    }

    setSelectedTecnico({idTecnico: tecnicoSelecionado.id, nome: tecnicoSelecionado.label})

    if (_.isEmpty(ossParaEncaminhar)) {
      setTipoMsgAlert("error");
      setMsgAlertError("Selecione ao menos uma OS!");
      setOpenAlertError(true);
      setLoading(false)
      return;
    }

    const OSs = ListOs.map(item => ({
      id: item.chamado,
      serial: ''
    }))

    const osNaoEncaminhada = ListOs.filter(item => {      
      if (item.idTecnico != null && item.idTecnico.toString() === tecnicoSelecionado.id) {
        return item.chamado;
      }
    })

    if (!_.isEmpty(osNaoEncaminhada)) {
      setTipoMsgAlert("error");
      setMsgAlertError(`${osNaoEncaminhada.map(item => ' ' + item.chamado.toString())} não foram encaminhadas, pois já estão com esse técnico`);
      setOpenAlertError(true);
      setLoading(false)
      return;
    }

    const serials = ListOs.map(item => ({
      serial: item.numeroSerie || ''
    }))

    encaminharOSs({
      idUsuario: idUsuario,
      idTecnico: Number(tecnicoSelecionado.id),
      idPrestador: getOSsByID(osselected)[0].idPrestador,
      ordensServico: OSs,
      seriais: serials,
    })
      .then((retorno: any) => {
        const { data } = retorno as AxiosResponse

        const listaEncaminharResponseMsgError = data.ordensOppay.filter(
          (item: IOrdemServicoEncaminhada) => !item.isSucesso)

        const listaEncaminharResponseMsgSuccess = data.ordensOppay.filter(
          (item: IOrdemServicoEncaminhada) => item.isSucesso)

        setMsgAlertSuccess(listaEncaminharResponseMsgSuccess.map(
          (item: IOrdemServicoEncaminhada) =>
            item.mensagem !== '' ?
              `${item.idOs}: ${item.message}` :
              `OS ${item.id} encaminhado com sucesso`
        )
          .toString()
          .replaceAll(',', '</br>'))

        setMsgAlertError(listaEncaminharResponseMsgError.map(
          (item: IOrdemServicoEncaminhada) =>
            item.mensagem !== '' ?
              `${item.idOs}: ${item.message}` :
              `Situação da OS ${item.id} inválida para o encaminhamento.`
        )
          .toString()
          .replaceAll(',', '</br>'))

        handleChangeTechnician(null)
        const OSsencaminhadas = OSs.map(item => item.id)
        setOssParaEncaminhar([])
        handleSetOSSelected(osselected.filter(item => !OSsencaminhadas.includes(item)))

        if (!_.isEmpty(listaEncaminharResponseMsgSuccess)) {
          setOpenAlertSuccess(true)
        }
        if (!_.isEmpty(listaEncaminharResponseMsgError)) {
          setOpenAlertError(true)
        }

        setLoading(false)
      })
      .catch((erro) => {

        //Erro na requisição à API
        setTipoMsgAlert("error");
        setMsgAlertError(erro.response.data);
        setOpenAlertError(true);
        setLoading(false)
      });
  };

  const handleChangeTechnician = (technician: ITecnico | null) => {
    setTecnicoSelecionado(technician)
  }

  useEffect(() => {
    setListaTecnicos([]);
    const getSeriais = getOSsByID(osselected).map((item: IOrdemServico) => item.numeroSerie || "")
    setSeriais(getSeriais)
  }, [])

  useEffect(() => {
    setAllSelected(getOSsByID(osselected).length === ossParaEncaminhar.length && ossParaEncaminhar.length > 0)
  }, [ossParaEncaminhar])

  return (
    <section className="EncaminharOS">
      <Header
        setMenuLateral={() => setMenuLateral(true)}
        title="Encaminhar OS"
      />
      <Breadcrumb>
        <BreadcrumbItem onClick={() => navigate("/FarolOS")}>Visualização de OS's</BreadcrumbItem>
        <BreadcrumbItem>Encaminhar OS</BreadcrumbItem>
      </Breadcrumb>

      {loading && <FullscreenLoading text="Isso pode levar alguns minutos..."/>}

      <div className="EncaminharOS-Autocomplete">
        
        <Autocompletetecnicos
          placeholder="Selecionar técnico"
          noOptionsText={"Não há técnicos ativos"}
          autocompleteValue={tecnicoSelecionado}
          onChangeValue={handleChangeTechnician}
          options={listaTecnicosByPrestadorTratada()}
          requestOptions={requestListaTecnicos}
          disabled={disableActions}
        />

        <Button
          id={"EncaminharOS-BotaoEncaminhar"}
          disabled={disableActions}
          onClick={handleClickEncaminhar}
          sx={{
            bgcolor: color.primary,
            color: "#FFF",
            "&:hover": {
              backgroundColor: color.primary,
              color: "#FFF",
            },
            borderRadius: "15px",
            minWidth: "126px",
            marginLeft: "10px",
            fontSize: "12px",
            fontFamily: "Open Sans",
            padding: "6px 8px",
          }}
        >
          Enviar a Campo
        </Button>
      </div>

      <Collapse onClose={setOpenAlertSuccess} in={openAlertSuccess}>
        <Alert
          severity='success'
          icon={<div />}
          variant="filled"
          sx={{
            mb: 2,
            bgcolor: "#178B319E",
            height: "auto",
            borderRadius: "15px",
            boxSizing: "border-box",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: "20px",
            marginBottom: 0,
          }}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="medium"
              onClick={() => setOpenAlertSuccess(false)}
            >
              <CloseIcon
                id={"ModalEncaminharOS-AlertCloseIcon"}
                fontSize="inherit"
                sx={{
                  color: "white",
                }}
              />
            </IconButton>
          }
        >
          <span
            style={{
              color: "white",
              fontSize: "16px",
            }}
            dangerouslySetInnerHTML={{__html: msgAlertSuccess}}
          />
        </Alert>
      </Collapse>
      <Collapse autoClose={false} onClose={setOpenAlertError} in={openAlertError}>
        <Alert
          severity='error'
          icon={<div />}
          variant="filled"
          sx={{
            mb: 2,
            bgcolor: "rgb(255, 35, 35, 0.7)",
            height: "auto",
            borderRadius: "15px",
            boxSizing: "border-box",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: "20px",
            marginBottom: 0,
          }}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="medium"
              onClick={() => setOpenAlertError(false)}
            >
              <CloseIcon
                id={"ModalEncaminharOS-AlertCloseIcon"}
                fontSize="inherit"
                sx={{
                  color: "white",
                }}
              />
            </IconButton>
          }
        >
          <span
            style={{
              color: "white",
              fontSize: "16px",
            }}
            dangerouslySetInnerHTML={{__html: msgAlertError}}
          />
        </Alert>
      </Collapse>

      <div className="EncaminharOS-ListaOS">
        {listaOSPaginada().map((item: IOrdemServico) => {
          return (
            <CardOSList
              isGroup={false}
              item={item}
              key={item.chamado}
              isosselected={!!ossParaEncaminhar.includes(item.chamado)}
              setOSSelected={handleSetOSSelectedLocal}
              disabled={disableActions}
              isListaOSPendente={false}
            />
          );
        })}
      </div>

      <Pagination
        className="pagination-bar"
        currentPage={currentPage}
        totalCount={osselected.length}
        pageSize={pageSize}
        setPageSize={setPageSize}
        onPageChange={(page: number) => setCurrentPage(page)}
      />
    </section>
  );
};

export default EncaminharOS;
