import axios, { AxiosError, AxiosResponse } from "axios";
import { authVerify } from "../middlewares/authVerify";
import { ResponseApi } from "../contexts/types/responsesHttp";
import _ from "lodash";

export const api = axios.create({
  baseURL: process.env.REACT_APP_API_IDENTIDADE,
  withCredentials: false,
});

const passAuthURLs = [
  "/Usuario/Login",
  "/Usuario/Verifiy2FA",
];


export const apiPost = async (url: string, params = {}, headers = {}) => {
  if (passAuthURLs.includes(url)) {
    return api.post(url, params, {
      headers
    });
  }

  try {
    let response = await api.post(url, params, {
      headers
    }) as AxiosResponse;
    return responseSucess(response);
  } catch (error: any) {
    verifyErrors(error)
    authVerify(error);
    return responseError(error);
  }
};

export const apiGet = async (url: string, params = {}, headers = {}) => {
  try {
    let response = await api.get(url, {
      params,
      headers
    }) as AxiosResponse;
    return responseSucess(response);
  } catch (error: any) {
    authVerify(error);
    return responseError(error);
  }
};

export const apiPut = async (url: string, params = {}, headers = {}) => {
  try {
    let response = await api.put(url, params, {
      headers
    }) as AxiosResponse;
    return responseSucess(response);
  } catch (error: any) {
    authVerify(error);
    return responseError(error);
  }
};

export const apiDelete = async (url: string, params = {}, headers = {}) => {
  try {
    let response = await api.delete(url, { data: params, headers }) as AxiosResponse;
    return responseSucess(response);
  } catch (error: any) {
    authVerify(error);
    return responseError(error);
  }
};

function responseSucess(response: AxiosResponse) {
  return new Promise((resolve, reject) => resolve(response));
}

function verifyErrors(error: AxiosError) {

  const responseApi = error.response?.data as ResponseApi<any>
  if (responseApi) {
    //alert(responseApi.errors.map((mp)=> mp.Message).toString())

  }
  //ResponseApi<any>
  // const msgError = error.errors.map((mp)=> {});

}

function responseError(error: AxiosError) {
  return new Promise((resolve, reject) => reject(error));
}

export function createSuccessMsg(data: AxiosResponse | any, optionalMsg?: string) {
  if (!!data && typeof data === 'string') return data;
  if (!!data.mensagem && typeof data.mensagem === 'string') return data.mensagem;
  if (!!data.message && typeof data.message === 'string') return data.message;
  if (!!optionalMsg) return optionalMsg;
  return '';
}

export function createErrorMsg(error: AxiosError | any, optionalMsg?: string) {
  const status = error?.response?.status;
  switch (status) {
    case 401:
      return 'Usuário não autenticado, realize o login novamente!';
    case 405:
      return 'Não permitido Status 405';
    case 500:
      return "Problemas no servidor. Status 500.";
  }

  const errors = error?.response?.data?.errors;
  const mensagem = error?.response?.data?.mensagem;
  const data = error?.response?.data;

  if (!!errors && _.isObject(errors)) return Object.values(errors).join("<br>");
  if (!!mensagem && typeof mensagem === 'string') return mensagem;
  if (!!data && typeof data === 'string') return data;
  if (status === 400) return 'Falha na requisição Status 400';
  if (!!optionalMsg) return optionalMsg;
  return '';
}
