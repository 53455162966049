import { apiGet, apiPost, apiPut } from "../../../services/api";
import { IGetContratanteServicoParametros, IPostContratanteServicoParametro, IPutContratanteServicoParametro } from "../types";

const baseUrlOrdemServico = process.env.REACT_APP_API_ORDEMSERVICO;

export const getContratanteServicoParametrosComFiltro = async (params: IGetContratanteServicoParametros) =>
    await apiGet(`${baseUrlOrdemServico}/ContratanteServicoParametros`, params)

export const postContratanteServicoParametro = async (params: IPostContratanteServicoParametro) => 
    await apiPost(`${baseUrlOrdemServico}/ContratanteServicoParametros/`, params)

export const putContratanteServicoParametro = async (params: IPutContratanteServicoParametro, idParametro: number | string) => 
    await apiPut(`${baseUrlOrdemServico}/ContratanteServicoParametros/${idParametro}`, params)

export const getParametros = async () =>
    await apiGet(`${baseUrlOrdemServico}/parametro`)