import { apiGet, apiPost, apiPut } from "../../services/api";
import { IGetPreset, IPostPreset, IPutPreset } from "./types";

const baseUrlIdentidade = process.env.REACT_APP_API_IDENTIDADE;

export const getPresets = async (params: IGetPreset) =>
  await apiGet(`${baseUrlIdentidade}/Preset`, params);

export const postPresets = async (params: IPostPreset) =>
  await apiPost(`${baseUrlIdentidade}/Preset`, params);

export const putPresets = async (idPreset: number, params: IPutPreset) =>
  await apiPut(`${baseUrlIdentidade}/Preset/${idPreset}`, params);
