import React, { useState, useEffect, useMemo } from 'react';
import { Container, Grid, GridItem, GridLabel, GridScroll, GridSeparationLine, TableSemDados } from './auditCustodiaModalStyles';
import Modal from '../../../components/modal';
import Input from '../../../components/Input/Input';
import { TextArea } from '../../../components/Input/Input';
import AuditCustodiaTable from './auditCustodiaTable';
import Pagination from "../../../components/pagination/paginacao";
import { IAuditoria, ICustodia, IGetAuditoriaResponse } from '../types';
import _ from 'lodash';
import { getAuditoria } from '../services/services';
import { AxiosResponse } from 'axios';
import { createErrorMsg } from '../../../services/api';
import FullscreenLoading from '../../../components/fullscreenLoading/fullscreenLoading';
import moment from 'moment';
import { isJson } from "../../../helpers/isJSON";

interface IAuditCustodiaModal {
  isModalOpen: boolean,
  handleCloseModal: () => void,
  custodia: ICustodia | null
}

interface IAuditoriaCustodia {
  IdTecnico: IAuditoriaCustodiaValue
  IdOrdemServico: IAuditoriaCustodiaValue
  IdCustodiaStatus: IAuditoriaCustodiaValue
  DataRecebimentoTecnico: IAuditoriaCustodiaValue
  DataBaixaOSEquipamento: IAuditoriaCustodiaValue
  DataEnvioTecnico: IAuditoriaCustodiaValue
  DataCorrecaoEnvio: IAuditoriaCustodiaValue
  NumeroSerie: IAuditoriaCustodiaValue
  ResponsavelConfirmacaoRecebimentoPA: IAuditoriaCustodiaValue
  ResponsavelEntregaEquipamentoAoTecnico: IAuditoriaCustodiaValue
  MotivoAlteracao: IAuditoriaCustodiaValue
  DataModificacao: IAuditoriaCustodiaValue
  IdUsuarioModificacao: IAuditoriaCustodiaValue
}
interface IAuditoriaCustodiaValue {
  ValorAnterior: number | string
  ValorPosterior: number | string
}

const AuditCustodiaModal = ({
  isModalOpen,
  handleCloseModal,
  custodia,
}: IAuditCustodiaModal) => {

  const [isOpen, setIsOpen] = useState(isModalOpen);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(20);
  const [listaOrdenarValor] = useState<any>({
    "ID": "id",
    "EditadoPor": "nomeUsuario",
    "DataDeEdicao": "dataAlteracao",
  });
  const [ordenacao, setOrdenacao] = useState<string>("DataDeEdicao");
  const [ordenacaoReverse, setOrdenacaoReverse] = useState(true);
  const [loading, setLoading] = useState(false);
  const [tipomsgAlert, setTipoMsgAlert] = useState<'success' | 'info' | 'warning' | 'error'>("success");
  const [msgAlert, setMsgAlert] = useState("");
  const [openAlert, setOpenAlert] = useState(false);
  const [listaAuditoria, setListaAuditoria] = useState<IGetAuditoriaResponse>([]);
  const [auditoriaSelecionada, setAuditoriaSelecionada] = useState<IAuditoria>({} as IAuditoria);

  const camposEditados: IAuditoriaCustodia = useMemo(() => {
    if (!!auditoriaSelecionada && !!auditoriaSelecionada?.alteracoes) {
      return isJson(auditoriaSelecionada?.alteracoes) ? JSON.parse(auditoriaSelecionada?.alteracoes) : {};
    } else {
      return {};
    }
  }, [auditoriaSelecionada]);

  const paramsLabel: any = {
    "IdCustodiaStatus": "ID Situação de custódia",
    "idCustodiaStatus": "ID Situação de custódia",
    "CustodiaStatus": "Situação de custódia",
    "custodiaStatus": "Situação de custódia",
    "Status": "Situação de custódia",
    "status": "Situação de custódia",
    "NumeroSerie": "Número de série",
    "IdTecnico": "ID Tecnico",
    "idTecnico": "ID Tecnico",
    "Tecnico": "Técnico",
    "tecnico": "Técnico",
    "DataRecebimentoTecnico": "Data de recebimento",
    "DataBaixaOSEquipamento": "Data de atendimento da OS",
    "IdOrdemServico": "Id da OS",
    "DataEnvioTecnico": "Data de envio ao técnico",
    "DataCorrecaoEnvio": "Data de correção",
    "ResponsavelEntregaEquipamentoAoTecnico": "Enviado por:",
    "ResponsavelConfirmacaoRecebimentoPA": "Devolução confirmada por:",
  };

  const camposDinamicosDePara = () => {
    if (!_.isEmpty(camposEditados)) {
      let listaReact: any[] = [];
      _.mapKeys(camposEditados, function (value: IAuditoriaCustodiaValue, key: string) {

        if (key === "MotivoAlteracao" || key === "IdUsuarioModificacao" || key === "DataModificacao") return;

        let { ValorAnterior, ValorPosterior } = value ?? {};

        if (!!key && typeof key === 'string' && key.includes("Data")) {
          ValorAnterior = moment(ValorAnterior, "YYYY-MM-DDTHH:mm:ss").isValid() ? moment(ValorAnterior, "YYYY-MM-DDTHH:mm:ss").format("DD/MM/YYYY HH:mm") : ValorAnterior;
          ValorPosterior = moment(ValorPosterior, "YYYY-MM-DDTHH:mm:ss").isValid() ? moment(ValorPosterior, "YYYY-MM-DDTHH:mm:ss").format("DD/MM/YYYY HH:mm") : ValorPosterior;
        }

        listaReact.push(
          <Grid className='three'>
            <Input
              dimension='sm'
              label='Campo Modificado:'
              value={paramsLabel[key] ? paramsLabel[key] : key}
              readOnly
            />
            <Input
              dimension='sm'
              label='De:'
              value={ValorAnterior ?? ""}
              readOnly
            />
            <Input
              dimension='sm'
              label='Para:'
              value={ValorPosterior ?? ""}
              readOnly
            />
          </Grid>
        );
      });

      return listaReact;
    } else {
      return [];
    }
  };

  const fetchAuditoria = async () => {
    setLoading(true);

    try {
      const { data }: { data: IGetAuditoriaResponse } = await getAuditoria({
        entidade: 'custodia',
        idEntidade: custodia?.id ?? 0
      }) as AxiosResponse;

      setLoading(false);

      let hitoryList = (data ?? []).map((item) => {
        return {
          ...item,
          dataAlteracao: (!item.dataAlteracao || item.dataAlteracao === "0001-01-01T00:00:00") ? 'N/A' : item.dataAlteracao,
        };
      });
      hitoryList = _.sortBy(hitoryList, ["dataAlteracao"]).reverse();
      setListaAuditoria(hitoryList);
      setAuditoriaSelecionada(hitoryList[0] ?? {});
      setMsgAlert("");

    } catch (error: any) {
      setListaAuditoria([]);
      setLoading(false);
      setMsgAlert(createErrorMsg(error, 'Nenhuma auditoria de custódia encontrada!'));
      setOpenAlert(true);
      setTipoMsgAlert('error');
    }
  };

  const paginacaoLocal = (dados: any[]) => {
    const firstPageIndex = (currentPage - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;
    return dados.slice(firstPageIndex, lastPageIndex);
  };

  const dadosFormatados = useMemo(() => {
    let dados: any[] = [...listaAuditoria];

    if (ordenacao !== "") {
      dados = _.sortBy(dados, [
        listaOrdenarValor[ordenacao],
      ]);
    }

    if (!!ordenacaoReverse) dados = dados.reverse();

    return paginacaoLocal(dados);
  }, [listaAuditoria, ordenacao, ordenacaoReverse, listaOrdenarValor, currentPage, pageSize]);


  const handleClickOrdenar = (sort: string) => {
    if (sort === ordenacao) {

      if (!ordenacaoReverse) {
        setOrdenacaoReverse(true);
      } else {
        setOrdenacao("");
        setOrdenacaoReverse(false);
      }
    } else {
      setOrdenacao(sort);
      setOrdenacaoReverse(false);
    }
  };

  const activeArrowUP = (sort: string) => {
    if (sort === ordenacao) {
      return ordenacaoReverse;
    } else {
      return false;
    }
  };

  const activeArrowDown = (sort: string) => {
    if (sort === ordenacao) {
      return !ordenacaoReverse;
    } else {
      return false;
    }
  };

  const handleClickAudit = (custodia: IAuditoria) => {
    setAuditoriaSelecionada(custodia);
  };

  useEffect(() => {
    setIsOpen(isModalOpen);
    fetchAuditoria();
  }, [isModalOpen]);

  return (
    <Modal
      handleCloseModal={handleCloseModal}
      title='Auditoria'
      isOpen={isOpen}
      sx={{ '.MuiDialog-paper': { maxWidth: 'unset' } }}
    >
      <Container>
        {loading && <FullscreenLoading />}

        <Grid>
          <GridItem>
            <GridLabel>Editado por: </GridLabel>
            <Input
              dimension='sm'
              label=''
              value={auditoriaSelecionada.nomeUsuario ?? ""}
              readOnly
            />
          </GridItem>

          <GridItem>
            <GridLabel>Data de edição: </GridLabel>
            <Input
              dimension='sm'
              label=''
              value={moment(auditoriaSelecionada.dataAlteracao, "YYYY-MM-DDTHH:mm:ss").isValid() ? moment(auditoriaSelecionada.dataAlteracao, "YYYY-MM-DDTHH:mm:ss").format("DD/MM/YYYY HH:mm") : ""}
              readOnly
            />
          </GridItem>
        </Grid>

        <GridSeparationLine />

        <GridScroll>
          {camposDinamicosDePara()}
        </GridScroll>

        {(!!camposEditados?.MotivoAlteracao) &&
          <Grid className='one'>
            <TextArea
              dimension='sm'
              label='Motivo:'
              placeholder=''
              value={camposEditados.MotivoAlteracao?.ValorPosterior ?? ""}
              readOnly
            />
          </Grid>
        }

        <GridSeparationLine />

        {!_.isEmpty(dadosFormatados) ?
          <>
            <AuditCustodiaTable
              handleClickOrdenar={handleClickOrdenar}
              activeArrowUP={activeArrowUP}
              activeArrowDown={activeArrowDown}
              ordenacao={ordenacao ?? ''}
              dataList={dadosFormatados}
              handleClickAudit={handleClickAudit}
              historicoSelecionado={auditoriaSelecionada}
            />

            <Pagination
              className="pagination-bar"
              currentPage={currentPage}
              totalCount={listaAuditoria.length}
              pageSize={pageSize}
              setPageSize={(value: string) => setPageSize(Number(value))}
              onPageChange={(page: number) => setCurrentPage(Number(page))}
              showLabel={false}
            />
          </>
          :
          <TableSemDados>
            Não há histórico de edição de custódia.
          </TableSemDados>
        }
      </Container>
    </Modal>
  );
};

export default AuditCustodiaModal;
