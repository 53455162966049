import React, { useContext, useState, useEffect } from 'react'
import './tipoEquipamento.scss'
import { useNavigate } from 'react-router-dom';
import { HomeContext } from '../../../contexts/homeContext';
import Header from '../../../components/header/header'
import Button from '../../../components/Button/styles';
import MultipleSelectCheckmarksId from '../../../components/seletorMultiploId/seletorMultiploId';
import { Breadcrumb, BreadcrumbItem } from '../../../components/breadcrumb/breadcrumb';
import { AxiosResponse } from 'axios';
import _ from 'lodash'
import FullscreenLoading from '../../../components/fullscreenLoading/fullscreenLoading';
import Input from '../../../components/Input/Input';
import { getFamilias } from '../../familia/services';
import { IFamilia } from '../../familia/types';
import ConsultarTipoEquipamentoTabela from './consultarTipoEquipamentoTabela'
import { IConsultarTipoEquipamentoTabela } from '../types';
import IosShareIcon from "@mui/icons-material/IosShare";
import { exportTipoEquipamento, getEquipamentoTipo } from '../services/services';
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import Collapse from '../../../components/collapse/collapse';
import CloseIcon from "@mui/icons-material/Close";

const TipoEquipamento = () => {
  const { setMenuLateral, familiaLista, setFamiliaLista, setTipoEquipamentoSelected } = useContext(HomeContext);

  const navigate = useNavigate()

  const [ equipamento, setEquipamento ] = useState([])
  const [ status, setStatus ] = useState<string[]>([])
  const [ familia, setFamilia ] = useState<string[]>([])
  const [ tableData, setTableData ] = useState<IConsultarTipoEquipamentoTabela[]>([])
  const [ descricao, setDescricao ] = useState('')

  const [tipomsgAlert, setTipoMsgAlert] = useState<'success' | 'info' | 'warning' | 'error'>("success");
  const [msgAlert, setMsgAlert] = useState("OS encaminhada com  sucesso!");
  const [openAlert, setOpenAlert] = useState(false);

  const [ loading, setLoading ] = useState(false)

  const statusLista = [
    {
      name: '--',
      id: ''
    }, 
    {
      name: 'Ativo',
      id: '1'
    }, 
    {
      name: 'Inativo',
      id: '0'
    }
  ]

  const handleGetFamilias = async () => {
    setLoading(true)
    try{

      const { data } = await getFamilias('', true) as AxiosResponse

      setFamiliaLista(data.map((item: IFamilia) => {
        return { name: item.descricao, id: item.idFamilia.toString() }
      }))

    } catch(e){
      setFamiliaLista([])
    }
    setLoading(false)
  }

  const handlePesquisar = async () => {
    setOpenAlert(false);
    setLoading(true)
    try{

      const { data } = await getEquipamentoTipo({
        ativo: !!status[0] ? Boolean(Number(status[0])) : '',
        descricao: descricao,
        idFamilia: Number(familia[0])
      }) as AxiosResponse

      setTableData(data)

    }catch (e){
      setTipoMsgAlert("error");
      setMsgAlert("Nenhum tipo de equipamento encontrado");
      setOpenAlert(true);
    }
    setLoading(false)
  }

  const handleExport = async () => {
    setLoading(true)
    try{
      const { data } = await exportTipoEquipamento() as AxiosResponse
  
      const url = window.URL.createObjectURL(new Blob([data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'Tipos de equipamento.csv')
      document.body.appendChild(link)
      link.click()
    } catch(e) {
      setOpenAlert(true)
      setTipoMsgAlert('error')
      setMsgAlert('Erro ao exportar tipo de equipamento')
    }
    setLoading(false)
  };

  useEffect(() => {
    handleGetFamilias()
    setMsgAlert("");
    setOpenAlert(false);
    setTableData([])
    setTipoEquipamentoSelected({} as IConsultarTipoEquipamentoTabela)
  }, [])

  return(
    <section className="ConsultarTipoEquipamento">
      <Header setMenuLateral={() => setMenuLateral(true)} title="Consultar Tipo de Equipamento" />
      <Breadcrumb>
        <BreadcrumbItem>Tipo de Equipamento</BreadcrumbItem>
      </Breadcrumb>

      {loading && <FullscreenLoading />}

      <div className="Container">
        <div className="ConsultarEquipamentoPesquisar">
          <span className='FiltroSpan'>Filtros</span>
          <MultipleSelectCheckmarksId
           nodata={"Nenhum status encontrado"}
           options={statusLista}
           value={status}
           onChange={(e: string[]) => setStatus(e)}
           placeholder={"Selecionar status"}
           id="multiple-checkbox-status"
           idOption="multiple-checkbox-option-status-"
           labelId="multiple-checkbox-label-status"
           multiple={false}
           className="ConsultarEquipamentoSelectStatus"
           dimension='sm'
          />
          <MultipleSelectCheckmarksId
           nodata={"Nenhum família encontrado"}
           options={familiaLista}
           value={familia}
           onChange={(e: string[]) => setFamilia(e)}
           placeholder={"Selecionar família"}
           id="multiple-checkbox-família"
           idOption="multiple-checkbox-option-família-"
           labelId="multiple-checkbox-label-família"
           multiple={false}
           className='ConsultarEquipamentoSelectFamilia'
           dimension='sm'
          />
          <Input
           placeholder="Descrição" 
           value={descricao}
           onChange={(e) => setDescricao(e.target.value)}
           dimension='sm'
          />
          <Button variant='primary' dimension='sm' onClick={handlePesquisar}>Pesquisar</Button>
          <Button variant='grey' dimension='sm' onClick={() => navigate('/IncluirTipoEquipamento')} >Novo</Button>
          <Button
           dimension='sm'
           className='ExportButton'
           variant='secondary'
           onClick={handleExport}
           style={{maxHeight: '44px'}}
          >
            <IosShareIcon />
            Exportar CSV
          </Button>
        </div>
        <Collapse onClose={setOpenAlert} in={openAlert}>
          <Alert
            severity={tipomsgAlert}
            icon={<div />}
            variant="filled"
            sx={{
              mb: 2,
              bgcolor: tipomsgAlert === "success" ? "#178B319E" : "rgb(255, 35, 35, 0.7)",
              height: "67px",
              borderRadius: "15px",
              boxSizing: "border-box",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginTop: "20px",
              marginBottom: 0,
            }}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="medium"
                onClick={() => setOpenAlert(false)}
              >
                <CloseIcon
                  id={"ModalEncaminharOS-AlertCloseIcon"}
                  fontSize="inherit"
                  sx={{
                    color: "white",
                  }}
                />
              </IconButton>
            }
          >
            <span
              style={{
                color: "white",
                fontSize: "16px",
              }}
              dangerouslySetInnerHTML={{__html: msgAlert}}
            />
          </Alert>
        </Collapse>
        {!_.isEmpty(tableData) && <ConsultarTipoEquipamentoTabela tableData={tableData}/>}
      </div>
    </section>
  )
}

export default TipoEquipamento