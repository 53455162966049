import styled from "styled-components";
import { siteTheme } from "../../styles/themeVariables";

const { color } = siteTheme;

export const CardOS = styled.div<ICardOS>`
  width: 100%;
  border-radius: 15px;
  margin-bottom: 6px;
  border-left: ${(props: ICardOS) => props.isGroup ? `4px solid ${color.primaryLight}` : ''};

  &:last-child {
    margin-bottom: 0;
  }
`;

interface ICardOS {
  isGroup: boolean
}

export const CardOSPorCliente = styled.div<ICardOS>`
  margin-bottom: 6px;
  max-width: fit-content;
  padding: 6px 19px;
  display: flex;
  align-items: center;
  gap: 8px;
  border: 1px solid #AFB1B1;
  border-radius: 8px;
  border-left: ${(props: ICardOS) => props.isGroup ? `4px solid ${color.primaryLight}` : ''};

  &:last-child {
    margin-bottom: 0;
  }
`;

interface ICardOSTopo {
  grid?: string
}

export const CardOSTopo = styled.div<ICardOSTopo>`
  display: grid;
  gap: 4px;
  grid-template-columns: ${props => props.grid ? props.grid : '60px 40px 0.6fr 0.7fr 0.7fr 0.7fr 1fr 1fr 2fr 1fr 1fr 0.8fr 60px'};
  height: 83px;
  min-height: 83px;
  box-sizing: border-box;
  padding: 5px 10px;
  border: 1px solid #afb1b1;
  border-radius: 15px;
`;

export const ColunaTopo = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  font-size: 15px;
  color: #797d8c;
  word-break: break-all;
  line-height: 17px;
  box-sizing: border-box;
  justify-content: center;
  &.Collumcenter {
    justify-content: center;
  }
  &.CollumBreak-word {
    word-break: break-word;
  }
  &.AlignCenter{
    display: flex;
    text-align: center
  }
`;

export const ColunaTopoTexto = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const SpanTopo = styled.span`
  margin-right: 5px;
  word-break: keep-all;
  font-weight: 600;
`;

export const CardOSBody = styled.div`
  width: 100%;
  height: 0;
  min-height: 0;
  padding: 0 0;
  transition: height 0.3s, padding 0.6s;
  overflow: hidden;
  display: flex;
  box-sizing: border-box;
  border-radius: 15px;

  &.cardOpen {
    align-items: center;
    padding: 12px 39px;
    height: 357px;
    border: 1px solid #afb1b1;
    margin-top: 4px;

    &.bestEquipment{
      height: 465px;
    }
  }
`;

export const InfoWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  & > .infowrapper-title {
    margin-top: 16px;
    font-weight: bold;
    color: ${color.textCinza};

    &:first-of-type {
      margin-top: 0;
    }
  }
`;

export const BodyInfo = styled.div`
  display: grid;
  grid-template-columns: 2fr repeat(3, 1.5fr);
  grid-template-rows: auto;
  align-items: center;
  width: 100%;
  max-width: 100%;
  height: 140px;
  border-bottom: 1px solid #AFB1B1;
  &:last-child {
    border-bottom: none;
  }
  &.midblock {
    grid-template-columns: 2fr repeat(3, 1.5fr) 1fr;
  }
`;

export const BodyInfoItem = styled.div`
  max-width: 100%;
  font-size: 14px;
  color: #797d8c;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const SpanInfo = styled.span`
  font-weight: 600;

  .sm{
    font-size: 12px;
  }
`;

export const BodyInfoItemEquipWrap = styled.div`
  /* max-width: 100%;
  display: flex;
  flex-direction: column; */

  max-width: 100%;
  max-height: 42px;
  display: flex;
  flex-direction: column;
  line-height: 1.2;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const BodyInfoItemEquip = styled.div`
  /* max-width: 100%;
  font-size: 11px;
  color: #797d8c;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical; */

  margin-bottom: 2px;
`;
