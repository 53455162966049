import { useContext, useState } from "react";
import { IAutocompleteValue } from "../../../components/autocompleteMultiple/types";
import { HomeContext } from "../../../contexts/homeContext";
import { AuthContext } from "../../../contexts/auth";
import { useNavigate } from "react-router-dom";
import { Body, ButtonsBox, NewUserButton, SearchButton, Section } from "./consultaEstados_style";
import Header from "../../../components/header/header";
import { Breadcrumb, BreadcrumbItem } from "../../../components/breadcrumb/breadcrumb";
import AutocompleteMultiple from "../../../components/autocompleteMultiple/autocompleteMultiple";
import Input from "../../../components/Input/Input";
import AlertMessage from "../../../components/AlertMessage/alertMessage";
import { CircularProgress } from "@mui/material";
import StickyTable from "../../../components/stickyTable/stickyTable";
import Pagination from "../../../components/pagination/paginacao";
import { IGetEstados, IGetResponseEstado, IGetResponseEstados } from "../types/types";
import { getListaEstadosComFiltro } from "../services/services";
import { AxiosResponse } from "axios";

const ConsultaEstados = () => {
    const { setMenuLateral, setEstadoSelecionado } = useContext(HomeContext);

    const { funcionalidadeDaTelaTemPermissao } = useContext(AuthContext);
    const profileHasPermission = (funcionalidade: string) => funcionalidadeDaTelaTemPermissao("Consultar Estados", funcionalidade);
    const navigate = useNavigate();

    const [listaStatus, setListaStatus] = useState<IAutocompleteValue>([
        { name: '--', id: '' },
        { name: 'Ativo', id: '0' },
        { name: 'Inativo', id: '1' },
    ]);

    const [filtroNome, setFiltroNome] = useState<string>("");
    const [filtroUF, setFiltroUF] = useState<string>("");
    const [filtroStatus, setFiltroStatus] = useState<IAutocompleteValue>([]);
    const [listaPaises, setListaPaises] = useState<IAutocompleteValue>([
        { name: 'Brasil', id: '' }
    ]);
    const [filtroPais, setFiltroPais] = useState<IAutocompleteValue>([{ name: 'Brasil', id: '' }]);


    const [tipomsgAlert, setTipoMsgAlert] = useState<'success' | 'info' | 'warning' | 'error'>("error");
    const [msgAlert, setMsgAlert] = useState("OS encaminhada com  sucesso!");
    const [openAlert, setOpenAlert] = useState(false);

    const [disableActions, setDisableActions] = useState(false);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);

    const [dadosConsultaEstado, setDadosConsultaEstado] = useState<any[]>([]);
    const [qtdeRegistros, setQtdeRegistros] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);

    const handleButtonSearch = () => {
        if (currentPage !== 1) {
            setCurrentPage(1);
        } else {
            handleSearch();
        }
    };

    const handleSearch = async () => {
        setOpenAlert(false);
        setLoading(true);
        setDisableActions(true);

        const params: IGetEstados = {
            ...(filtroNome !== '' ? { estado: filtroNome } : {}),
            ...(filtroUF !== '' ? { uf: filtroUF } : {})
        }

        try {
            const { data }: { data: IGetResponseEstados } = await getListaEstadosComFiltro(params) as AxiosResponse;
            setLoading(false);
            setDisableActions(false);
            setDadosConsultaEstado(data.estados ?? []);
            setQtdeRegistros(data?.estados?.length ?? 0);
        } catch (error) {
            setLoading(false);
            setDisableActions(false);
            setDadosConsultaEstado([]);
            setQtdeRegistros(0);
            setTipoMsgAlert("error");
            setMsgAlert("Nenhum dado encontrado");
            setOpenAlert(true);
        }
    }

    const getItemByID = (ID: number | string) => {
        let resultadoDados = [...dadosConsultaEstado];

        return resultadoDados.find(
            (item) => item.id === ID
        )!;
    };

    const paginacaoLocal = (dados: any[]) => {
        const firstPageIndex = (currentPage - 1) * pageSize;
        const lastPageIndex = firstPageIndex + pageSize;
        return dados.slice(firstPageIndex, lastPageIndex);
    };

    const dadosFormatados = () => {
        let dados = [...dadosConsultaEstado];

        /**Formata campos para tabela */
        dados = dados.map((item: any) => {
            return {
                id: item.id,
                pais: listaPaises.map(e => e.name),
                uf: item.uf ?? "",
                nome: item.nome,
            };
        });

        return paginacaoLocal(dados);
    };

    const handleClickItemEdit = (data: any) => {
        setEstadoSelecionado(getItemByID(data.id));
        navigate("/EditarEstado");
    };

    const objTraducaoHeader = {
        id: "Id do Registro",
        pais: "País",
        uf: "UF",
        nome: "Estado",
    } as any;

    return(
        <Section>
            <Header
                setMenuLateral={() => setMenuLateral(true)}
                title="Estados"
            />
                <Breadcrumb>
                <BreadcrumbItem>Buscar Estado</BreadcrumbItem>
            </Breadcrumb>

            <Body>
                <div className="grid1">
                    <AutocompleteMultiple
                        disableClearable
                        dimension="sm"
                        label={"País"}
                        placeholder={""}
                        noOptionsText="Nenhum país encontrado"
                        options={listaPaises}
                        value={filtroPais}
                        onChange={() => { }}
                        id="multiple-checkbox-pais"
                        multiple={false}
                        disabled={true}
                    />
                    <Input
                        className={"ConsultaUF-Input"}
                        dimension="sm"
                        id="input-UF"
                        label={"UF"}
                        value={filtroUF}
                        onChange={(e) => setFiltroUF(e.target.value)}
                        maxLength={255}
                        disabled={disableActions}
                    />
                    <Input
                        className={"ConsultaEstado-Input"}
                        dimension="sm"
                        id="input-NomeDoEstado"
                        label={"Estado"}
                        value={filtroNome}
                        onChange={(e) => setFiltroNome(e.target.value)}
                        maxLength={255}
                        disabled={disableActions}
                    />
                    <ButtonsBox>
                        <SearchButton
                            variant="contained"
                            size="small"
                            onClick={handleButtonSearch}
                            disabled={disableActions}
                            >
                            Buscar
                        </SearchButton>
                        {!!profileHasPermission("Novo Registro (Link) - Consultar Estados") ?
                            <NewUserButton
                                color="secondary"
                                variant="contained"
                                size="small"
                                onClick={() => navigate("/IncluirEstado")}
                                disabled={disableActions}
                            >
                                Incluir Estado
                            </NewUserButton>
                            :
                            <NewUserButton
                                color="secondary"
                                variant="contained"
                                size="small"
                                disabled={true}
                                className="bloqueado"
                            >
                                Incluir Estado
                            </NewUserButton>
                        }
                    </ButtonsBox>
                </div>
            </Body>

            <AlertMessage
                isOpenAlert={openAlert}
                setOpenAlert={setOpenAlert}
                alertType={tipomsgAlert}
                msgAlert={msgAlert}
            />

            {!!loading && <CircularProgress style={{ margin: '0px auto' }} />}

            {!!dadosConsultaEstado.length && (
                <>
                    <StickyTable
                        id={"TabelaResultadoConsultaEstado"}
                        data={dadosFormatados()}
                        handleClickEdit={handleClickItemEdit}
                        objTraducaoHeader={objTraducaoHeader}
                        allowEdit={!disableActions && !!profileHasPermission("Editar Registro")}
                        edit={true}
                        labelEdit={"Detalhes"}
                    />

                    <Pagination
                        className="pagination-bar"
                        currentPage={currentPage}
                        totalCount={qtdeRegistros}
                        pageSize={pageSize}
                        setPageSize={(value: string) => setPageSize(Number(value))}
                        onPageChange={(page: number) => setCurrentPage(Number(page))}
                        showLabel={false}
                    />
                </>
            )}
        </Section>
    )
    
}
export default ConsultaEstados;