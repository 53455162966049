import { apiGet, apiPost, apiPut } from "../../../services/api";
import { IPostEquipamentoTipo } from "../types";

const baseURL = process.env.REACT_APP_API_ESTOQUE

export const getEquipamentoTipo = (params?: { idFamilia?: number | '', descricao?: string, ativo?: boolean | ''}) => {
  const { idFamilia, descricao, ativo } = params!
  const familia = idFamilia || ''
  return apiGet(`${baseURL}/Equipamento/equipamento-tipo?IdFamilia=${familia}&Filtro.Descricao=${descricao}&Filtro.Ativo=${ativo}`)
}

export const postEquipamentoTipo = (params: IPostEquipamentoTipo) => {
  return apiPost(`${baseURL}/Equipamento/equipamento-tipo`, params)
}

export const putEquipamentoTipo = (params: {idTipo: number, descricao: string, idFamilia: number, status: boolean}) => {
  return apiPut(`${baseURL}/Equipamento/equipamento-tipo`, params)
}

export const exportTipoEquipamento = async () => 
  await apiGet(`${baseURL}/Equipamento/tipo/export`)