import React, { useState } from 'react'
import './geralTab.scss'
import MultipleSelectCheckmarksId from '../../../components/seletorMultiploId/seletorMultiploId'
import _ from 'lodash'
import FullscreenLoading from '../../../components/fullscreenLoading/fullscreenLoading';
import Button from '../../../components/Button/styles';
import { laboratorioList } from '../../../contexts/helpers/dadosPadrao';
import * as Styles from "../../../components/table/styles";
import { Table, TableRow } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import CloseIcon from "@mui/icons-material/Close";
import { siteTheme } from "../../../styles/themeVariables";

const { color } = siteTheme;

const LaboratorioTab = () => {

  const [ loading, setLoading ] = useState(false)
  const [ tipoLaboratorio, setTipoLaboratorio ] = useState<string[]>([])
  const [ tableData, setTableData ] = useState<string[]>([])

  const handleAdicionar = () => {
    setTableData([...tableData, tipoLaboratorio[0]])
  }

  const handleRemove = (item: string) => {
    setTableData(tableData.filter(tableItem => tableItem !== item))
  }

  return(
    <div className='GeralTabContainer'>
      <div className='Row'>
        <MultipleSelectCheckmarksId
        nodata={"Nenhum tipo de laboratório encontrado"}
        options={laboratorioList.filter(item => !tableData.includes(item.name))} 
        value={tipoLaboratorio}
        onChange={(e: string[]) => setTipoLaboratorio(e)}
        id="multiple-checkbox-tipoLaboratorio"
        idOption="multiple-checkbox-option-tipoLaboratorio-"
        labelId="multiple-checkbox-label-tipoLaboratorio"
        multiple={false}
        className="IncluirEditarModeloSelectTipoLaboratorio"
        dimension='sm'
        label="Laboratório - Tipo"
        />
        <Button
         variant="primary"
         dimension='sm'
         margin='0'
         onClick={handleAdicionar}
        >
          Adicionar
        </Button>
      </div>
      {!_.isEmpty(tableData) &&
      <TableContainer component={Styles.StyledPaper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              {['', 'Laboratório - Tipo']?.map((title) => 
                <Styles.StyledTableCell className={`${title} center`} key={title}>{title}</Styles.StyledTableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData?.map(item => (
              <TableRow key={item}>
                <Styles.StyledTableCell
                 component="th" 
                 scope="row" 
                 style={{
                  textAlign: "center", 
                  width: "10%",
                  cursor: 'pointer'
                 }}
                 onClick={() => handleRemove(item)}
                >
                  <CloseIcon sx={{color: color.osVencida}}/>
                </Styles.StyledTableCell>
                <Styles.StyledTableCell component="th" scope="row">
                  {item}
                </Styles.StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      }
    {loading && <FullscreenLoading />}
    </div>
  )
}

export default LaboratorioTab