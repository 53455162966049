import React, { useState, useEffect, useContext } from "react";
import {
  Section,
  SearchButton,
  Body,
  FiltrosLabel,
} from "./consultaFamilia_styles";
import "./consultaFamilia.scss";
import Header from "../../../components/header/header";
import {
  Breadcrumb,
  BreadcrumbItem,
} from "../../../components/breadcrumb/breadcrumb";
import Button from "../../../components/Button/styles";
import { HomeContext } from "../../../contexts/homeContext";
import { useNavigate } from "react-router-dom";
import MultipleSelectCheckmarksid from "../../../components/seletorMultiploId/seletorMultiploId";
import Input from "../../../components/Input/Input";
import { ReactComponent as Voltar } from "../../../assets/icons/Voltar.svg";
import { AxiosResponse } from "axios";
import StickyTable from "../../../components/stickyTable/stickyTable";
import { Alert, Button as MuiButton, CircularProgress, IconButton, Stack, Grid } from '@mui/material';
import Collapse from '../../../components/collapse/collapse';
import CloseIcon from "@mui/icons-material/Close";
import Pagination from '../../../components/pagination/paginacao';
import { getFamilias, exportFamilias } from "../services";
import moment from "moment";
import { removeSpecialCharSimple } from "../../../helpers/removeSpecialCharSimple";
import { ReactComponent as ExportIcon } from "../../../assets/icons/ExportIcon.svg";
import { AuthContext } from "../../../contexts/auth";

const ConsultaFamilia = () => {
  const { setMenuLateral } = useContext(HomeContext);

  const { getPermissoesDaTela } = useContext(AuthContext);
  const [permissoes, setPermissoes] = useState(getPermissoesDaTela("Consulta família"));

  const navigate = useNavigate();

  const [disableActions, setDisableActions] = useState(false);
  const [descricao, setDescricao] = useState<string>("");
  const [status, setStatus] = useState<string[]>(['0']);
  const [listaStatus, setListaStatus] = useState<{ name: string, id: string }[]>([
    { name: 'Ativo', id: '0' },
    { name: 'Inativo', id: '1' },
  ]);
  const [dadosConsultaFamilia, setDadosConsultaFamilia] = useState<any[]>([]);
  const [openAlertError, setOpenAlertError] = useState(false);
  const [msgAlertError, setMsgAlertError] = useState("Nenhum dado encontrado");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingCSV, setLoadingCSV] = useState<boolean>(false);

  const handleSearch = async () => {
    setOpenAlertError(false);
    setLoading(true);
    setDisableActions(true);

    const statusBool = status[0] === '0' ? true : false;

    try {
      let retorno = await getFamilias(removeSpecialCharSimple(descricao), statusBool) as AxiosResponse;
      setLoading(false);
      setDisableActions(false);
      setCurrentPage(1);
      setDadosConsultaFamilia(retorno?.data || []);

    } catch (error) {
      setLoading(false);
      setDisableActions(false);
      setCurrentPage(1);
      setDadosConsultaFamilia([]);
      setMsgAlertError("Nenhum dado encontrado");
      setOpenAlertError(true);
    }
  };

  const objTraducaoHeader = {
    ativo: "Status",
    status: "Status",
    idFamilia: "ID Família",
    descricao: "Descrição",
    dataCriacao: "Data de Criação",
    idUsuarioCriacao: "ID Usuário Criação",
    usuarioCriacao: "Criado Por",
    dataModificacao: "Data de Modificação",
    idUsuarioModificacao: "ID Usuário Modifição",
    usuarioModificacao: "Modificado Por",
  } as any;

  const getFamilyByID = (ID: string) => {
    let resultadoDados = [...dadosConsultaFamilia];

    return resultadoDados.find(
      (item) => item.idFamilia === ID
    )!;
  };

  const handleClickFamilyEdit = (data: any) => {
    navigate("/IncluirFamilia", {
      state: {
        dadosFamilia: getFamilyByID(data.idFamilia),
        rota: {
          url: window.location.pathname,
          name: "Consulta Família",
        },
      }
    });
  };

  const smoothScroll = (target: any) => {
    var scrollContainer = target;
    do { //find scroll container
      scrollContainer = scrollContainer?.parentNode;
      if (!scrollContainer) return;
      scrollContainer.scrollTop += 1;
    } while (scrollContainer.scrollTop == 0);

    var targetY = 0;
    do { //find the top of target relatively to the container
      if (target == scrollContainer) break;
      targetY += target.offsetTop;
    } while (target = target.offsetParent);

    const scroll = function (c: any, a: number, b: number, i: number) {
      i++; if (i > 30) return;
      c.scrollTop = a + (b - a) / 30 * i;
      setTimeout(function () { scroll(c, a, b, i); }, 20);
    }
    // start scrolling
    scroll(scrollContainer, scrollContainer.scrollTop, targetY, 0);
  };

  const dadosPaginadados = () => {
    let dados = [...dadosConsultaFamilia];

    /**Formata campos para tabela */
    dados = dados.map((item: any) => {
      return {
        ...item,
        ativo: !!item.ativo ? "Ativo" : "Inativo",
        dataCriacao: !!item.dataCriacao ? moment(item.dataCriacao, "YYYY-MM-DDTHH:mm:ss").format("DD/MM/YYYY HH:mm:ss") : "",
        dataModificacao: !!item.dataModificacao ? moment(item.dataModificacao, "YYYY-MM-DDTHH:mm:ss").format("DD/MM/YYYY HH:mm:ss") : "",
      };
    })

    /**Paginação */
    const firstPageIndex = (currentPage - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;
    return dados.slice(firstPageIndex, lastPageIndex);
  };

  const handleExport = async () => {
    setLoadingCSV(true);
    try {
      const { data } = await exportFamilias() as AxiosResponse;

      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Famílias.csv');
      document.body.appendChild(link);
      link.click();
    } catch (e) {
      setMsgAlertError("Erro ao exportar CSV");
      setOpenAlertError(true);
    }
    setLoadingCSV(false);
  };

  const funcionalidadeTemPermissao = (funcionalidade: string) => {
    let hasPermission = false;
    hasPermission = !![...permissoes].filter((f: { nome: string }) => removeSpecialCharSimple(f.nome).includes(removeSpecialCharSimple(funcionalidade))).length;
    return hasPermission;
  };

  useEffect(() => {
    if (!!dadosConsultaFamilia.length) smoothScroll(document.getElementById('TabelaResultadoConsultaFamilia'));
  }, [dadosConsultaFamilia])

  return (
    <Section id={"ConsultaFamilia"}>
      <Header
        setMenuLateral={() => setMenuLateral(true)}
        title="Consultar Família"
      />
      <Breadcrumb>
        <BreadcrumbItem>Consultar família</BreadcrumbItem>
      </Breadcrumb>

      <Stack direction="column" width="100%">
        <FiltrosLabel>Filtros</FiltrosLabel>

        <Grid container alignItems="flex-end" spacing={2}>

          <Grid item xs={3} xl={3}>
            <Input
              className={"ConsultaFamilia-Input"}
              dimension="sm"
              id="input-IdOM"
              label={"Descrição Família"}
              value={descricao}
              onChange={(e) => setDescricao(e.target.value)}
              maxLength={255}
              disabled={disableActions}
            />
          </Grid>

          <Grid item xs={3} xl={3}>
            <MultipleSelectCheckmarksid
              dimension="sm"
              label={"Status"}
              nodata={"Nenhum Status encontrado"}
              options={listaStatus}
              value={status}
              onChange={(value) => setStatus(value)}
              placeholder={""}
              id="multiple-checkbox-Status"
              idOption="multiple-checkbox-option-Status-"
              labelId="multiple-checkbox-label-Status"
              classes={{
                select: "ConsultaFamilia-Seletor error",
                primary: "Seletor-Option",
                group: "Seletor-OptionGroup",
              }}
              multiple={false}
              disabled={disableActions}
            />
          </Grid>


          <Grid item xs="auto">
            <Stack direction="row" alignItems="flex-end" width="100%" columnGap="1rem">
              <SearchButton
                id={"SearchButtonButton"}
                variant="contained"
                size="medium"

                fullWidth
                onClick={handleSearch}
                disabled={disableActions}

              >
                Pesquisar
              </SearchButton>

              <MuiButton
                variant="contained"
                sx={{
                  height: "44px",
                  borderRadius: "15px",
                  padding: "0 2rem",
                  lineHeight: "1",
                  ".MuiButton-startIcon": { marginRight: "10px !important" },
                }}
                fullWidth
                onClick={() => navigate("/IncluirFamilia")}
              >
                Nova Família
              </MuiButton>

              {!!funcionalidadeTemPermissao("Exportar CSV") ?

                <MuiButton
                  id={"ExportCSVButton"}
                  variant="outlined"
                  color="info"
                  startIcon={<ExportIcon />}
                  sx={{
                    height: "44px",
                    borderRadius: "15px",
                    padding: "0 10px",
                    lineHeight: "1",
                    ".MuiButton-startIcon": { marginRight: "10px !important" },
                  }}
                  fullWidth
                  onClick={handleExport}
                  disabled={disableActions || loadingCSV}
                >
                  {loadingCSV ? <CircularProgress color="inherit" size={20} /> : 'Exportar CSV'}
                </MuiButton>

                :
                <MuiButton
                  id={"ExportCSVButton"}
                  variant="outlined"
                  color="info"
                  startIcon={<ExportIcon />}
                  sx={{
                    cursor: 'not-allowed', opacity: 0.5,
                    height: "44px",
                    borderRadius: "15px",
                    padding: "0 10px",
                    lineHeight: "1",
                    ".MuiButton-startIcon": { marginRight: "10px !important" },
                  }}
                >
                  Exportar CSV
                </MuiButton>
              }
            </Stack>

          </Grid>



        </Grid>
      </Stack>

      {openAlertError &&
        <Collapse onClose={setOpenAlertError} in={openAlertError}>
          <Alert
            severity='error'
            icon={<div />}
            variant="filled"
            sx={{
              mb: 2,
              bgcolor: "rgb(255, 35, 35, 0.7)",
              borderRadius: "15px",
              boxSizing: "border-box",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: '50px',
            }}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="medium"
                onClick={() => setOpenAlertError(false)}
              >
                <CloseIcon
                  id={"ModalEncaminharOS-AlertCloseIcon"}
                  fontSize="inherit"
                  sx={{
                    color: "white",
                  }}
                />
              </IconButton>
            }
          >
            <span
              style={{
                color: "white",
                fontSize: "16px",
              }}
              dangerouslySetInnerHTML={{ __html: msgAlertError }}
            />
          </Alert>
        </Collapse>
      }

      {!!loading && <CircularProgress style={{ margin: '0px auto' }} />}

      {!!dadosConsultaFamilia.length && (
        <>
          <StickyTable
            id={"TabelaResultadoConsultaFamilia"}
            data={dadosPaginadados()}
            handleClickEdit={handleClickFamilyEdit}
            objTraducaoHeader={objTraducaoHeader}
            edit={true}
            allowEdit={!!funcionalidadeTemPermissao("Editar Registro")}
          />

          <Pagination
            className="pagination-bar"
            currentPage={currentPage}
            totalCount={dadosConsultaFamilia.length}
            pageSize={pageSize}
            setPageSize={(value: string) => setPageSize(Number(value))}
            onPageChange={(page: number) => setCurrentPage(Number(page))}
            showLabel={false}
          />
        </>
      )}
    </Section>
  );
};

export default ConsultaFamilia;
