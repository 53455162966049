import { apiGet, apiPost, apiPut} from "../../../services/api";
import { IPutUsuarioRequest } from "../types";
import _ from 'lodash'

const baseUrl = process.env.REACT_APP_API_IDENTIDADE

export const getPerfis = async (ativo?: boolean) => 
  await apiGet(`${baseUrl}/Usuario/Perfil`, {ativo: ativo})

export const postPerfil = async (params: {ativo: boolean, nome: string}) =>
  await apiPost(`${baseUrl}/Usuario/Perfil`, params)

export const getPerfilById = async (idPerfil: number) =>
  await apiGet(`${baseUrl}/Usuario/Perfil/${idPerfil}`)

export const putPerfilById = async (params: IPutUsuarioRequest, idPerfil: number) => 
  await apiPut(`${baseUrl}/Usuario/Perfil/${idPerfil}`, params)

export const getTelas = async () => await apiGet(`${baseUrl}/Usuario/Tela`)

export const postTela = async (params: {nome: string, idUsuarioCriacao: number}) =>
  await apiPost(`${baseUrl}/Usuario/Tela`, params)

export const getTelaById = async (idTela: number) =>
  await apiGet(`${baseUrl}/Usuario/Tela/${idTela}`)

export const putTelaById = async (params: {nome: string, idUsuarioCriacao: number}, idTela: number) =>
  await apiPut(`${baseUrl}/Usuario/Tela/${idTela}`, params)

export const putVincularPerfilComUsuario = async (
  idUsuario: number, 
  params: { idUsuarioModificacao: number, perfis: number[] }
) => await apiPut(`${baseUrl}/Usuario/VincularPerfis/${idUsuario}`, params)

export const getUsuarios = async () => 
  await apiGet(`${baseUrl}/Usuario`)

export const getModulos = async (params: {ativo: boolean}) => 
  await apiGet(`${baseUrl}/Modulo/Modulo`, params);

export const getTelaByIdModulo = async (idModulo: number) =>
  await apiGet(`${baseUrl}/Usuario/Tela/BuscarTelaByModulo/${idModulo}`);

export const getFuncionaliadesDoPerfil = async (idTela: number, idPerfil: number) =>
  await apiGet(`${baseUrl}/Funcionalidade/Funcionalidade/GetByTelaPerfil/${idTela}/${idPerfil}`);
