import { useState } from "react";
import { IGetResponseEstado } from "../pages/estados/types/types";


export const EstadoContext = () => {
  const [estadoSelecionado, setEstadoSelecionado] = useState({} as IGetResponseEstado);

  return {
    estadoSelecionado,
    setEstadoSelecionado
  }
}