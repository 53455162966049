import React from "react";
import { useLocation } from "react-router-dom";
import IncluirUsuario from "./incluirUsuario";
import EditarUsuario from "./editarUsuario";

const IncluirOuAlterarUsuario = () => {
  const location = useLocation();
  const dadosUsuario = location.state?.dadosUsuario || {};

  if (!!dadosUsuario.id) return <EditarUsuario/>;
  return <IncluirUsuario/>;
};

export default IncluirOuAlterarUsuario;
