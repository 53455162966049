import React, { useState, useEffect } from "react";
import Modal from "../../../components/modal";
import Button from "@mui/material/Button";
import {
  Container,
  InfoLabel,
} from "./farolModalAvisoOSCourierStyles";
import { IModalAvisoOSCourrier } from "../types";
import { siteTheme } from "../../../styles/themeVariables";

const { color } = siteTheme

const ModalAvisoOSCourier = ({ isModalOpen, handleClose }: IModalAvisoOSCourrier) => {
  const [isOpen, setIsOpen] = useState(isModalOpen);

  useEffect(() => {
    setIsOpen(isModalOpen)
  }, [isModalOpen])

  return (
    <Modal
      handleCloseModal={handleClose}
      title={" "}
      isOpen={isOpen}
      titleStyle={{
        bgcolor: "#FFF",
        color: "#797D8C",
        fontWeight: 700
      }}
    >
      <Container>
        <InfoLabel>
          Não é possível encaminhar OS's courier de forma massiva.
        </InfoLabel>

        <Button
          id={"ModalEncaminharOS-BotaoEncaminhar"}
          onClick={handleClose}
          sx={{
            bgcolor: color.primary,
            color: "#FFF",
            "&:hover": {
              backgroundColor: color.primary,
              color: "#FFF",
            },
            width: "180px",
            height: "44px",
            margin: "0px auto",
            borderRadius: "15px",
            fontSize: "12px",
            fontFamily: "Open Sans",
          }}
        >
          Ok
        </Button>
      </Container>
    </Modal>
  );
};

export default ModalAvisoOSCourier;
