import axios from "axios";
import { apiGet } from "../../../services/api";
import { IGetCidades, IGetEstados, IGetPrestadorFiltrado } from "../types";
import _ from 'lodash'

const baseUrl = process.env.REACT_APP_API_ATENDIMENTO;
const baseUrlOrdemServico = process.env.REACT_APP_API_ORDEMSERVICO;

const IBGEBaseURL = 'https://servicodados.ibge.gov.br/api/v1/localidades/estados'

const api = axios.create();

export const getBuscarPrestadoresPeloUsuario = async (usuarioId: string | number) =>
  await apiGet(`${baseUrl}/Prestador/BuscarPrestadores/${usuarioId}`)

export const getBuscarPrestadorFiltrado = async ({
  nomePrestador,
  tipo,
  codigoCt,
  codigoFornecedor,
  ativo,
  pageNumber,
  pageSize
}: IGetPrestadorFiltrado) => {
  let Filtro: string = ''
  if (nomePrestador) Filtro = `?NomePrestador=${nomePrestador}`
  if (tipo) Filtro = _.isEmpty(Filtro) ? `?Tipo=${tipo}` : `${Filtro}&Tipo=${tipo}`
  if (codigoCt) Filtro = _.isEmpty(Filtro) ? `?CodigoCT=${codigoCt}` : `${Filtro}&CodigoCT=${codigoCt}`
  if (codigoFornecedor) Filtro = _.isEmpty(Filtro) ? `?CodigoFornecedor=${codigoFornecedor}` : `${Filtro}&CodigoFornecedor=${codigoFornecedor}`
  if (!_.isNull(ativo) && !_.isUndefined(ativo)) Filtro = _.isEmpty(Filtro) ? `?Ativo=${ativo}` : `${Filtro}&Ativo=${ativo}`
  if (_.isEmpty(Filtro)) {
    Filtro = `?PageNumber=${pageNumber}&PageSize=${pageSize}`
  } else Filtro = `${Filtro}&PageNumber=${pageNumber}&PageSize=${pageSize}`

  return await apiGet(`${baseUrl}/Prestador/ConsultaPrestadores${Filtro}`)
}

export const buscaCEP = async (cep: string) =>
  await apiGet(`https://viacep.com.br/ws/${cep}/json/`)

export const buscaUFs = async () =>
  await api.get(`${IBGEBaseURL}`)

export const buscaCidades = async (idUF: string) =>
  await apiGet(`${IBGEBaseURL}/${idUF}/distritos`)

export const getEstados = async (params: IGetEstados) =>
  await apiGet(`${baseUrlOrdemServico}/estados`, params);

export const getCidades = async (params: IGetCidades) =>
  await apiGet(`${baseUrlOrdemServico}/cidades`, params);
