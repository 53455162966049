import { apiGet, apiPost } from "../../../services/api";
import { IPostVerifiy2FA } from "../types/types";

export const createSession = (params = {}) => {
  return apiPost("/Usuario/Login", params);
};

export const verifiy2FA = (params: IPostVerifiy2FA) => {
  return apiPost("/Usuario/Verifiy2FA", params);
};

export const getUser = (idUser: number | string) => {
  return apiGet(`/Usuario/${idUser}`, {});
};
