import moment from "moment";
import { IOrdemServico } from "../contexts/types/farolTypes";
import { removeSpecialCharSimple } from "./removeSpecialCharSimple";

export const createStatusAndPriorityOS = (item: IOrdemServico) => {
    if (condicaofiltroOS(item, "BaixadaDentroDoPrazo")) {
        item.statusFarol = "BaixadaDentroDoPrazo";
        item.prioridadeFarol = 4;
    }
    else if (condicaofiltroOS(item, "BaixadaForaDoPrazo")) {
        item.statusFarol = "BaixadaForaDoPrazo";
        item.prioridadeFarol = 5;
    }
    else if (condicaofiltroOS(item, "Cancelada")) {
        item.statusFarol = "Cancelada";
        item.prioridadeFarol = 6;
    }
    else if (condicaofiltroOS(item, "TotalOSEmergencial")) {
        item.statusFarol = "OSEmergencial";
        item.prioridadeFarol = 0;
    }
    else if (condicaofiltroOS(item, "TotalOSVencidas")) {
        item.statusFarol = "OSVencida";
        item.prioridadeFarol = 1;
    }
    else if (condicaofiltroOS(item, "TotalOSVencemHoje")) {
        item.statusFarol = "OSVenceHoje";
        item.prioridadeFarol = 2;
    }
    else if (condicaofiltroOS(item, "TotalOSAVencer")) {
        item.statusFarol = "OSAVencer";
        item.prioridadeFarol = 3;
    }

    return {
        ...item,
    };
};

const condicaofiltroOS = (item: IOrdemServico, filtro = "") => {
    const dataAtendimento = (item.dataAtendimento && item.dataAtendimento !== "0001-01-01T00:00:00") ?
        moment(item.dataAtendimento, "YYYY-MM-DDTHH:mm:ss") :
        moment(item.horarioCheckin, "YYYY-MM-DDTHH:mm:ss");
    const dataPrevisaoAtendimento = moment(item.previsaoAtendimento, "YYYY-MM-DDTHH:mm:ss");
    const dataHoje = moment(moment().format("YYYY-MM-DD HH:mm:ss"));

    const listaStatusBaixa: string[] = [
        "prebaixasucesso",
        "prebaixainsucesso",
        "baixada",
    ];

    if (filtro === "BaixadaDentroDoPrazo") {
        if (!listaStatusBaixa.includes(removeSpecialCharSimple(item.status))) return false;
        return dataAtendimento.isSameOrBefore(dataPrevisaoAtendimento, "second");
    }
    if (filtro === "BaixadaForaDoPrazo") {
        if (!listaStatusBaixa.includes(removeSpecialCharSimple(item.status))) return false;
        return dataAtendimento.isAfter(dataPrevisaoAtendimento, "second");
    }
    if (filtro === "Cancelada")
        return removeSpecialCharSimple(item.status) === "cancelada";
    if (filtro === "TotalOSEmergencial")
        return item.tipoOS === "Emergêncial";
    if (filtro === "TotalOSVencidas")
        return dataPrevisaoAtendimento.isBefore(dataHoje, "day");
    if (filtro === "TotalOSVencemHoje")
        return dataPrevisaoAtendimento.isSame(dataHoje, "day");
    if (filtro === "TotalOSAVencer")
        return dataPrevisaoAtendimento.isAfter(dataHoje, "day");

    return false;
};