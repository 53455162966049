import SectionCustom from "../../../components/section/section";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../contexts/auth";
import {
  getContratantes,
  getModelos,
  pesquisarModelosCompativeis,
} from "../services";
import {
  EquipamentoModeloPrincipalArray,
  HeaderTabela,
  IContratantesDTO,
  IModeloDTO,
} from "../types";
import { ResponseApi } from "../../../contexts/types/responsesHttp";
import MultipleSelectCheckmarksid from "../../../components/seletorMultiploId/seletorMultiploId";
import "./consultaAgrupamentoModelo.scss";
import { NewButton, SearchButton } from "./consultaAgrupamentoModelo_Styles";
import { AxiosResponse } from "axios";
import Collapse from "../../../components/collapse/collapse";
import {
  Alert,
  CircularProgress,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Pagination from "../../../components/pagination/paginacao";
import StickyTable from "../../../components/stickyTable/stickyTable";
import AutocompleteMultiple from "../../../components/autocompleteMultiple/autocompleteMultiple";
import { useNavigate } from "react-router-dom";
const ConsultarAgrupamentoModelo = () => {
  const { funcionalidadeDaTelaTemPermissao } = useContext(AuthContext);
  const navigate = useNavigate();
  const [listaContratante, setListaContratante] = useState<
    { name: string; id: string }[]
  >([]);
  const [listaModelo, setListaModelo] = useState<
    { name: string; id: string }[]
  >([]);
  const [contratante, setContratante] = useState<string[]>([]);
  const [modelo, setModelo] = useState<any[]>([]);
  const [msgAlertError, setMsgAlertError] = useState<string>(
    "Não foi encontrado nenhum Modelo compatível"
  );
  const [openAlertError, setOpenAlertError] = useState(false);
  const [disableActions, setDisableActions] = useState(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [qtdeRegistros, setQtdeRegistros] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [dadosConsulta, setDadosConsulta] =
    useState<EquipamentoModeloPrincipalArray | null>(null);
  const isIncluding = window.location.pathname.includes(
    "ConsultarAgrupamentoModelo"
  );
  const profileHasPermission = (funcionalidade: string) =>
    funcionalidadeDaTelaTemPermissao(isIncluding, funcionalidade);
  const formatarData = (data: string): string => {
    if (!data) return "";
    const dateObj = new Date(data);
    const dia = dateObj.getDate().toString().padStart(2, "0");
    const mes = (dateObj.getMonth() + 1).toString().padStart(2, "0");
    const ano = dateObj.getFullYear().toString();
    return `${dia}/${mes}/${ano}`;
  };
  const objTraducaoHeader = {
    id: "ID Modelo",
    nomeModelo: "Nome Modelo",
    contratante: "Contrante",
    criadoEm: "Data Criação",
    modificadoEm: "Data Modificação",
    editadoPor: "Modificado Por",
  } as HeaderTabela;
  const obterTermoPorId = (ID: number) => {
    let resultadoDados = [...(dadosConsulta || [])];
    return resultadoDados.find((item) => item.id === ID)!;
  };
  const handleClickItemEdit = (data: any) => {
    navigate("/EditarAgrupamentoModelos", {
      state: {
        dadosAgrupamento: obterTermoPorId(data.id),
        rota: {
          url: window.location.pathname,
          name: "Editar Agrupamento de Modelos",
        },
      },
    });
  };
  const handleClickNovoAgrupamento = () => {
    navigate("/IncluirAgrupamentoModelos", {
      state: {
        rota: {
          url: window.location.pathname,
          name: "Incluir Agrupamento de Modelos",
        },
      },
    });
  };
  const obterDadosParaTabela = () => {
    return dadosConsulta?.map((modelo: any) => ({
      id: modelo?.id,
      nomeModelo: modelo?.nome,
      contratante: modelo?.equipamentoModeloCompativels[0]?.contratante?.nome,
      criadoEm: formatarData(modelo.dataCriacao ?? ""),
      modificadoEm: formatarData(modelo.dataModificacao ?? ""),
      editadoPor: modelo?.UsuarioModificacaoNomeCompleto,
    })) as any[];
  };
  const pesquisarAgrupamento = async () => {
    setOpenAlertError(false);
    setDisableActions(true);
    setLoading(true);
    const codigoContratante = contratante[0];
    const idEquipamentoModeloPrincipal = modelo[0].id;
    if (
      codigoContratante === undefined ||
      codigoContratante === null ||
      codigoContratante.trim() === ""
    ) {
      setLoading(false);
      setOpenAlertError(true);
      setMsgAlertError("Informe um contratante para realizar a pesquisa");
      setDisableActions(false);
      return;
    } else if (
      idEquipamentoModeloPrincipal === undefined ||
      idEquipamentoModeloPrincipal === null ||
      idEquipamentoModeloPrincipal.trim() === ""
    ) {
      setLoading(false);
      setOpenAlertError(true);
      setMsgAlertError("Informe um modelo para realizar a pesquisa");
      setDisableActions(false);
      return;
    } else {
      try {
        const retorno = (await pesquisarModelosCompativeis(
          parseInt(codigoContratante),
          parseInt(idEquipamentoModeloPrincipal)
        )) as AxiosResponse;
        if (retorno.status === 200) {
          setDadosConsulta(retorno.data ?? null);
          setLoading(false);
          setDisableActions(false);
          setQtdeRegistros(retorno?.data?.totalRegistros || 0);
          return;
        } else {
          setDadosConsulta(null);
          setLoading(false);
          setQtdeRegistros(0);
          setDisableActions(false);
          setMsgAlertError("Não foram encontrados Modelos compatíveis.");
          return;
        }
      } catch (error: any) {
        setDadosConsulta(null);
        setLoading(false);
        setMsgAlertError(error?.response?.data!);
        setOpenAlertError(true);
        setQtdeRegistros(0);
        setDisableActions(false);
        return;
      }
    }
  };
  useEffect(() => {
    handleGetContratantes();
    handleGetModelo();
  }, []);
  const handleGetContratantes = async () => {
    await getContratantes().then(
      (res: any) => {
        const respSuccess = res?.data as ResponseApi<IContratantesDTO[]> | null;
        if (respSuccess) {
          const listaContratante =
            respSuccess.data?.map((item: any) => {
              return { name: item.nome, id: item.id.toString() };
            }) || [];
          setListaContratante([...[], ...listaContratante]);
        }
      },
      (err) => {
        const respErr = err?.response?.data as ResponseApi<IContratantesDTO[]>;
        if (respErr) {
          setMsgAlertError(respErr.errors[0].Message);
          setOpenAlertError(true);
          setListaContratante([]);
        }
      }
    );
  };
  const handleGetModelo = async () => {
    await getModelos().then(
      (res: any) => {
        const respSuccess = res?.data;
        if (respSuccess) {
          const listaModelo =
            respSuccess?.equipamentoModelos?.map((item: any) => ({
              name: item.nome,
              id: item.id.toString(),
            })) || [];
          setListaModelo([...[], ...listaModelo]);
        }
      },
      (err) => {
        const respErr = err?.response?.data as ResponseApi<IModeloDTO[]>;
        if (respErr) {
          setMsgAlertError(respErr.errors[0].Message);
          setOpenAlertError(true);
          setListaContratante([]);
        }
      }
    );
  };
  return (
    <SectionCustom
      id={"ConsultarAgrupamento"}
      className="ConsultarAgrupamento"
      titleHeader="Agrupamento de Modelos"
      titleBreadcrumbItem="Consultar Agrupamento de Modelos"
    >
      <div className="consultarAgrupamentoModelo-Container">
        <div className="filtro-Modelo">
          <AutocompleteMultiple
                dimension="sm"
                label={"Modelo"}
                placeholder="Selecione um Modelo"
                options={[{ name: "--", id: "" }, ...listaModelo]}
                value={modelo}
                onChange={(value:any) => setModelo(value)}
                fontSize={12}
                id="multiple-checkbox-Status"
                multiple={false}
                allSelected
                disabled={disableActions}
              />

          <MultipleSelectCheckmarksid
            dimension="sm"
            label={"Contratante"}
            nodata={"Nenhum contratante encontrado"}
            options={[{ name: "--", id: "" }, ...listaContratante]}
            value={contratante}
            onChange={(value) => setContratante(value)}
            placeholder={"Selecione um contratante"}
            id="multiple-checkbox-Contratante"
            idOption="multiple-checkbox-option-Contratante-"
            labelId="multiple-checkbox-label-Contratante"
            classes={{
              select: "ConsultaContratante-Seletor error",
              primary: "Seletor-Option",
              group: "Seletor-OptionGroup",
            }}
            multiple={false}
            disabled={disableActions}
          />
          <div className="botao-PesquisarModelo-container">
            <SearchButton
              variant="contained"
              size="small"
              onClick={pesquisarAgrupamento}
              disabled={disableActions}
            >
              Pesquisar
            </SearchButton>
            {!!profileHasPermission("Editar Registro") ? (
              <NewButton
                color="secondary"
                variant="contained"
                size="small"
                onClick={handleClickNovoAgrupamento}
              >
                Incluir
              </NewButton>
            ) : (
              <NewButton
                className={"bloqueado"}
                style={{ cursor: "not-allowed", opacity: 0.5 }}
                color="secondary"
                variant="contained"
                size="small"
              >
                Incluir
              </NewButton>
            )}
          </div>
        </div>
      </div>
      {openAlertError && (
        <div className="modalErro-Modelo">
          <Collapse onClose={setOpenAlertError} in={openAlertError}>
            <Alert
              severity="error"
              icon={<div />}
              variant="filled"
              sx={{
                mb: 2,
                bgcolor: "rgb(255, 35, 35, 0.7)",
                borderRadius: "15px",
                boxSizing: "border-box",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginBottom: "50px",
              }}
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="medium"
                  onClick={() => setOpenAlertError(false)}
                >
                  <CloseIcon
                    id={"ModalModeloCompativel-AlertCloseIcon"}
                    fontSize="inherit"
                    sx={{
                      color: "white",
                    }}
                  />
                </IconButton>
              }
            >
              <span
                style={{
                  color: "white",
                  fontSize: "16px",
                }}
                dangerouslySetInnerHTML={{ __html: msgAlertError }}
              />
            </Alert>
          </Collapse>
        </div>
      )}
      {!!loading && <CircularProgress style={{ margin: "0px auto" }} />}
      {!!dadosConsulta?.length && (
        <>
          <div className="tabelaModelo">
            <StickyTable
              id={"TabelaResultadoConsultaModelo"}
              data={obterDadosParaTabela()}
              objTraducaoHeader={objTraducaoHeader}
              allowEdit={!!profileHasPermission("Editar Registro")}
              edit={true}
              handleClickEdit={handleClickItemEdit}
            />
          </div>
          <Pagination
            className="pagination-bar"
            currentPage={currentPage}
            totalCount={qtdeRegistros}
            pageSize={pageSize}
            setPageSize={(value: string) => setPageSize(Number(value))}
            onPageChange={(page: number) => setCurrentPage(Number(page))}
            showLabel={false}
          />
        </>
      )}
    </SectionCustom>
  );
};
export default ConsultarAgrupamentoModelo;
