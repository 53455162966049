import React, { useContext, useState } from 'react'
import './editarPerfilTab.scss'
import { Table, TableRow } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import CloseIcon from "@mui/icons-material/Close";
import * as Styles from "../../../components/table/styles";
import _ from "lodash";
import { IUsuario } from '../types';

const EditarPerfilTab = ({
  listaUsuariosPerfil
}: {
  listaUsuariosPerfil: IUsuario[]
}) => {
  const [tableData, setTableData] = useState<IUsuario[]>(listaUsuariosPerfil);

  return(
    <div className='ContainerEditarPerfil'>
      {!_.isEmpty(tableData) &&
      <div className='TableContainerPage'>
        <TableContainer component={Styles.StyledPaper}>
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                <Styles.StyledTableCell className={`center`}>Usuário</Styles.StyledTableCell>
                {/* <Styles.StyledTableCell className={`center`}>Excluir</Styles.StyledTableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData.map(item => {
                return(
                  <TableRow key={item.id}>
                    <Styles.StyledTableCell component="th" scope="row">
                      {item.nomeCompleto}
                    </Styles.StyledTableCell>
                    {/* <Styles.StyledTableCell
                    component="th" 
                    scope="row" 
                    style={{
                      textAlign: "center", 
                      width: '15%'
                    }}
                    >
                      <CloseIcon onClick={() => setTableData(tableData.filter(filterItem => filterItem.id !== item.id))}/>
                    </Styles.StyledTableCell> */}
                  </TableRow>
              )})}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      }
    </div>
  )
}

export default EditarPerfilTab