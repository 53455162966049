import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import _ from 'lodash';
import moment from 'moment';
import { AuthContext } from "../../../contexts/auth";
import VisibilityIcon from '@mui/icons-material/Visibility';
import styled from "styled-components";
import { ReactComponent as EditIcon } from "../../../assets/icons/EditIcon.svg";

export const EditIconStyled = styled(EditIcon)``;

const headCells = [
  {
    id: 'Editar',
    cmpID: 'editar',
    numeric: true,
    disablePadding: false,
    label: 'Editar',
  },
  {
    id: 'ID',
    cmpID: 'id',
    numeric: true,
    disablePadding: false,
    label: 'ID',
  },
  {
    id: 'RegistroEditado',
    cmpID: 'registroEditado',
    numeric: true,
    disablePadding: false,
    label: 'Registro editado',
  },
  {
    id: 'Auditoria',
    cmpID: 'auditoria',
    numeric: true,
    disablePadding: false,
    label: 'Auditoria',
  },
  {
    id: 'Contratante',
    cmpID: 'contratante',
    numeric: true,
    disablePadding: false,
    label: 'Contratante',
  },
  {
    id: 'StatusDeCustodia',
    cmpID: 'statusDeCustodia',
    numeric: true,
    disablePadding: false,
    label: 'Status de custódia',
  },
  {
    id: 'Prestador',
    cmpID: 'prestador',
    numeric: true,
    disablePadding: false,
    label: 'Prestador',
  },
  {
    id: 'NomeDoTecnico',
    cmpID: 'nomeDoTecnico',
    numeric: true,
    disablePadding: false,
    label: 'Nome do Técnico',
  },
  {
    id: 'NumeroDeSerie',
    cmpID: 'numeroDeSerie',
    numeric: true,
    disablePadding: false,
    label: 'Número de série',
  },
  {
    id: 'ModeloDoEquipamento',
    cmpID: 'modeloDoEquipamento',
    numeric: true,
    disablePadding: false,
    label: 'Modelo do equipamento',
  },
  {
    id: 'ResponsavelPeloEnvioDoEquipamento',
    cmpID: 'responsavelPeloEnvioDoEquipamento',
    numeric: true,
    disablePadding: false,
    label: 'Responsável pelo envio do equipamento',
  },
  {
    id: 'DataEHoraDeEnvioParaOTecnico',
    cmpID: 'dataEHoraDeEnvioParaOTecnico',
    numeric: true,
    disablePadding: false,
    label: 'Data e hora de envio para o técnico',
  },
  {
    id: 'DataEHoraDeRecebimentoPeloTecnico',
    cmpID: 'dataEHoraDeRecebimentoPeloTecnico',
    numeric: true,
    disablePadding: false,
    label: 'Data e hora de recebimento pelo técnico',
  },
  {
    id: 'DataDeBaixaDaOSDoEquipamento',
    cmpID: 'dataDeBaixaDaOSDoEquipamento',
    numeric: true,
    disablePadding: false,
    label: 'Data de baixa da OS do equipamento',
  },
  {
    id: 'DataDeEntregaAoPA',
    cmpID: 'dataDeEntregaAoPA',
    numeric: true,
    disablePadding: false,
    label: 'Data de entrega ao P.A.',
  },
  {
    id: 'NomeDoResponsavelPelaConfirmacaoDeRecebimentoNoPA',
    cmpID: 'nomeDoResponsavelPelaConfirmacaoDeRecebimentoNoPA',
    numeric: true,
    disablePadding: false,
    label: 'Nome do responsável pela confirmação de recebimento no P.A.',
  },
];

interface EnhancedTableProps {
  handleClickOrdenar: (sort: string) => void;
  activeArrowUP: (sort: string) => boolean;
  activeArrowDown: (sort: string) => boolean;
  ordenacao: string;
  allowEdit?: boolean;
  allowSeeAudit?: boolean;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { activeArrowUP, activeArrowDown, ordenacao, handleClickOrdenar, allowEdit = false, allowSeeAudit = false } =
    props;
  const createSortHandler = (property: string) => (event: React.MouseEvent<unknown>) => {
    handleClickOrdenar(property);
  };

  const filteredHeadCells = () => {
    let data = [...headCells];
    if (!allowEdit) data = data.filter(h => (h.cmpID !== 'editar'));
    if (!allowSeeAudit) data = data.filter(h => (h.cmpID !== 'registroEditado' && h.cmpID !== 'auditoria'));
    return data;
  }

  return (
    <TableHead id={"customTableHead"}>
      <TableRow sx={{ minHeight: "70px", height: "70px", whiteSpace: "nowrap" }}>
        {filteredHeadCells().map((headCell) => (
          <TableCell
            key={headCell.id}
            align={'center'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={activeArrowUP(headCell.id) ? 'asc' : activeArrowDown(headCell.id) ? 'desc' : false}
            sx={{ backgroundColor: '#AFB1B1', color: '#FFF' }}
          >
            <TableSortLabel
              id={"customTableHeadSort:" + (headCell.cmpID)}
              active={ordenacao === headCell.id}
              direction={activeArrowUP(headCell.id) ? 'asc' : 'desc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function CustodiaTable({
  id = "customTable",
  handleClickOrdenar,
  activeArrowUP,
  activeArrowDown,
  ordenacao,
  dataList,
  allowEdit = false,
  handleClickEdit,
  allowSeeAudit = false,
  handleClickAudit,
}: {
  id?: string,
  handleClickOrdenar: (sort: string) => void,
  activeArrowUP: (sort: string) => boolean,
  activeArrowDown: (sort: string) => boolean,
  ordenacao: string,
  dataList: any[],
  allowEdit?: boolean,
  handleClickEdit?: (custodia: any) => void,
  allowSeeAudit?: boolean,
  handleClickAudit?: (custodia: any) => void,
}) {
  const [dense, setDense] = React.useState(true);
  const { windowInnerHeight } = React.useContext(AuthContext);

  const tamanhoTelaAntesDaTablePX = 470; /*Layout acima da tabela*/
  const tamanhoTelaDepoisDaTablePX = 70; /*Paginacao*/
  const minHeightTable = 400;
  const tableHeight = windowInnerHeight - (tamanhoTelaAntesDaTablePX + tamanhoTelaDepoisDaTablePX);
  const maxHeightTable = tableHeight < minHeightTable ? minHeightTable : tableHeight;

  const handleEdit = (custodia: any) => {
    if (!!handleClickEdit) handleClickEdit(custodia);
  };
  
  const handleAudit = (custodia: any) => {
    if (!!handleClickAudit) handleClickAudit(custodia);
  };

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden', marginTop: '20px' }}>
      <TableContainer sx={{ maxHeight: maxHeightTable }}>
        <Table
          id={id}
          stickyHeader
          aria-label="sticky table"
          sx={{ minWidth: 750 }}
          aria-labelledby="tableTitle"
          size={dense ? 'small' : 'medium'}
        >
          <EnhancedTableHead
            handleClickOrdenar={handleClickOrdenar}
            activeArrowUP={activeArrowUP}
            activeArrowDown={activeArrowDown}
            ordenacao={ordenacao}
            allowEdit={allowEdit}
            allowSeeAudit={allowSeeAudit}
          />
          <TableBody id={"customTableBody"}>
            {dataList.map((row, index) => {
              const isEdited = row.registroEditado === 'Sim';
              return (
                <TableRow
                  id={"customTableBodyRowOS:" + (row.numeroSerie) + (index)}
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={index}
                  sx={{ backgroundColor: ('#FFF'), minHeight: "42px", height: "42px", whiteSpace: "nowrap" }}
                >
                  {allowEdit && (
                    <TableCell align="center">{<EditIconStyled style={{ cursor: 'pointer' }} onClick={() => handleEdit(row)}/>}</TableCell>
                  )}
                  <TableCell align="center">{row.id}</TableCell>
                  {allowSeeAudit && (
                    <>
                      <TableCell align="center">{row.registroEditado}</TableCell>
                      <TableCell align="center">{isEdited && <VisibilityIcon sx={{ cursor: 'pointer' }} onClick={() => handleAudit(row)}/>}</TableCell>
                    </>
                  )}
                  <TableCell align="center">{row.contratante}</TableCell>
                  <TableCell align="center">{row.statusCustodia}</TableCell>
                  <TableCell align="center">{row.prestador}</TableCell>
                  <TableCell align="center">{row.tecnico}</TableCell>
                  <TableCell align="center">{row.numeroSerie}</TableCell>
                  <TableCell align="center">{row.modelo}</TableCell>
                  <TableCell align="center">{row.responsavelEnvio}</TableCell>
                  <TableCell align="center">{row.dataEnvio === 'N/A' ? row.dataEnvio : moment(row.dataEnvio, "YYYY-MM-DDTHH:mm:ss").format("DD/MM/YYYY HH:mm")}</TableCell>
                  <TableCell align="center">{row.dataRecebimento === 'N/A' ? row.dataRecebimento : moment(row.dataRecebimento, "YYYY-MM-DDTHH:mm:ss").format("DD/MM/YYYY HH:mm")}</TableCell>
                  <TableCell align="center">{row.dataBaixaOS === 'N/A' ? row.dataBaixaOS : moment(row.dataBaixaOS, "YYYY-MM-DDTHH:mm:ss").format("DD/MM/YYYY HH:mm")}</TableCell>
                  <TableCell align="center">{row.dataEntregaPA === 'N/A' ? row.dataEntregaPA : moment(row.dataEntregaPA, "YYYY-MM-DDTHH:mm:ss").format("DD/MM/YYYY HH:mm")}</TableCell>
                  <TableCell align="center">{row.nomeResponsavelRecebimentoPA}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};
