import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import './detalhesOS.scss';
import Button from '../../../components/Button/styles';
import Header from '../../../components/header/header';
import { Breadcrumb, BreadcrumbItem } from '../../../components/breadcrumb/breadcrumb';
import { HomeContext } from '../../../contexts/homeContext';
import { Divider } from '@mui/material';
import Collapse from '../../../components/collapse/collapse';
import FullscreenLoading from '../../../components/fullscreenLoading/fullscreenLoading';
import { TextArea } from '../../../components/Input/Input';
import { ReactComponent as ExpandIcon } from "../../../assets/icons/ExpandIcon.svg";
import { ReactComponent as ChecklistProdutoLupa } from "../../../assets/icons/ChecklistProdutoLupa.svg";
import { DetalhesOSMapa } from './detalhesOSMapa';
import Evidencias from './evidencias';
import StickyTable from '../../../components/stickyTable/stickyTable';
import { ReactComponent as Clipboard } from "../../../assets/icons/ClipboardIcon.svg";
import { ReactComponent as Printer } from "../../../assets/icons/PrinterIcon.svg";
import AnotacoesModal from './anotacoesModal';
import _ from 'lodash';
import moment from 'moment';
import MultipleSelectCheckmarksid from "../../../components/seletorMultiploId/seletorMultiploId";
import { IOrdemServico } from '../../../contexts/types/farolTypes';
import ModalEncaminharOS from '../../farol/view/farolModalEncaminharOS';
import { AuthContext } from "../../../contexts/auth";
import { useLocation } from "react-router-dom";
import { consultarOSs } from '../../consultaOS/services';
import { IPostConsultarOSs, IPostConsultarOSsResponse, IPostConsultarOSsResponseOS, ITipoProdutoConsultaOS } from '../../consultaOS/types';
import { AxiosResponse } from 'axios';
import { getCidades } from '../../prestador/services/services';
import { consultarDadosChip } from '../services';
import { IGetResponseCidades } from '../../prestador/types';
import PrintIcon from '@mui/icons-material/Print';
import { pdf } from '@react-pdf/renderer';
import { ContainerButtons } from '../../consultaOS/view/consultarOS_styles';
import TemplateDefault from '../../../components/ImpressaoOS/templateDefault';
import TemplateGetNet from '../../../components/ImpressaoOS/templateGetNet';
import { removeSpecialCharSimple } from '../../../helpers/removeSpecialCharSimple';
import SwitchMUI from "../../../components/switch/switch";
import CircularProgress from "@mui/material/CircularProgress";
import { updatevalidaPerimetro } from '../../farol/services';
import AlertMessage from '../../../components/AlertMessage/alertMessage';
import ModalChecklistProduto from './modalChecklistProduto';
import PageviewIcon from '@mui/icons-material/Pageview';
import { OrdemServicoStatus } from '../../../helpers/ordemServico';

const DetalhesOS = () => {
  const location = useLocation();
  const chamado = location.state?.chamado ?? "";

  const { funcionalidadeDaTelaTemPermissao } = useContext(AuthContext);
  const profileHasPermission = (funcionalidade: string) => funcionalidadeDaTelaTemPermissao("Detalhes OS", funcionalidade);

  const navigate = useNavigate();

  const { setMenuLateral } = useContext(HomeContext);

  const [tipomsgAlert, setTipoMsgAlert] = useState<'success' | 'info' | 'warning' | 'error'>("success");
  const [msgAlert, setMsgAlert] = useState("");
  const [openAlert, setOpenAlert] = useState(false);

  const [selectedProduto, setSelectedProduto] = useState<any | null>(null);
  const [checklistProdutoModal, setChecklistProdutoModal] = useState(false);

  const [listaCidadeObject, setListaCidadeObject] = useState<any>({});
  const [observacoes, setObservacoes] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingPerimetro, setLoadingPerimetro] = useState(false);
  const [openDadosCliente, setOpenDadosCliente] = useState(false);
  const [openDadosProduto, setOpenDadosProduto] = useState(false);
  const [openDadosChip, setOpenDadosChip] = useState(false);
  const [openDadosProdutoManutencao, setOpenDadosProdutoManutencao] = useState(false);
  const [openPeriodos, setOpenPeriodos] = useState(false);
  const [openDadosAtendimento, setOpenDadosAtendimento] = useState(false);
  const [openAtendimentosRealizados, setAtendimentosRealizados] = useState(false);
  const [openRastreamento, setOpenRastreamento] = useState(false);
  const [tableData, setTableData] = useState([{
    evento: '',
    descricao: '',
    status: '',
    falha: '',
    usuarioModificacao: '',
    dataCriacao: ''
  }]);

  const [ordemServico, setOrdemServico] = useState<IPostConsultarOSsResponseOS>({} as IPostConsultarOSsResponseOS);
  const [status, setStatus] = useState<string[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [anotacoesModal, setAnotacoesModal] = useState(false);
  const [marcacoes, setMarcacoes] = useState([
    '10/10/2024 - 10:05:20 - Protocolo técnico: P13331410202 - Data: 10/10/2023 13:33:14 - Motivo: ENCE0015',
    '18/10/2023 - 12:25:20 - Alteração a pedido de Amabile 18/10/2023',
    '20/10/2023 - 13:50:20 - Teste nova anotação'
  ].sort((a, b) =>
    (moment(a.slice(0, 18), 'DD/MM/YYYY HHmm').toDate() > moment(b.slice(0, 18), 'DD/MM/YYYY HHmm').toDate()) ?
      1 :
      ((moment(b.slice(0, 18), 'DD/MM/YYYY HHmm').toDate()) > moment(a.slice(0, 18), 'DD/MM/YYYY HHmm').toDate()) ? -1 : 0));

  const objTraducaoHeader = {
    evento: 'Nome do evento',
    descricao: 'Descrição',
    status: 'Status',
    falha: 'Falha',
    usuarioModificacao: 'Modificado por',
    dataCriacao: 'Data de criação'
  } as any;

  const listaStatusPermiteEnviarACampo: string[] = [
    "encaminhada",
    "reencaminhado",
  ];

  const statusLista: { name: string, id: string }[] =
    (listaStatusPermiteEnviarACampo.includes(removeSpecialCharSimple(ordemServico?.status || ""))) ?
      [{ name: 'Enviar a Campo', id: 'enviarCampo' }] :
      removeSpecialCharSimple(ordemServico?.status || "") === 'emcampo' ?
        [{ name: 'Retirar de Campo', id: 'retirarCampo' }] :
        [];

  const handleSalvar = () => {
    if (status[0] === 'enviarCampo') {

      setIsModalOpen(true)
    } else {
      console.log("Endpoint não definido")
    }
  };

  const handleAddObservations = () => {
    /**TODO: Endpoint para add observações */
    console.log("Observacoes: ", observacoes);
  };

  const createUrlToWhatsapp = (number: string) => {
    const newNumber = number.replace(/\D/g, "");
    const firtTwoDigitis = newNumber.slice(0, 2);

    if (firtTwoDigitis === '55') return `https://wa.me/${newNumber}`;

    return `https://wa.me/55${newNumber}`
  };

  const permissaoCampo = (func: string, label: string | number | null | undefined) => {
    return profileHasPermission(func) ? (label ?? "") : "Sem permissão para visualizar";
  };

  const handleGetDadosOS = async () => {
    setLoading(true);
    setOpenAlert(false);

    const filtros: IPostConsultarOSs = {
      chamado: chamado ? String(chamado) : "",
      "pageNumber": 1,
      "pageSize": 10,
    };

    try {
      const { data }: { data: IPostConsultarOSsResponse } = await consultarOSs(filtros) as AxiosResponse;

      setOrdemServico(data?.items[0] ?? {});
      setLoading(false);

    } catch (error: any) {
      setLoading(false);
      setMsgAlert((!!error?.response?.data && typeof error.response.data === 'string') ? error.response.data : (!!error?.response?.status ? `Dados da OS não foram encontrados. Status: ${error?.response?.status}` : "Dados da OS não foram encontrados"));
      setTipoMsgAlert("error");
    }
  };

  const handlePrint = async () => {
    const isTemplateGetNet = ordemServico.contratante === "GETNET";
    const osData = {
      ...ordemServico,
      enderecos: !ordemServico.enderecos ? [] : ordemServico.enderecos.map(e => {
        return {
          ...e,
          cidade: (!!listaCidadeObject[e?.idCidade] ? (listaCidadeObject[e?.idCidade]?.nome ?? "") : ""),
          uf: (!!listaCidadeObject[e?.idCidade] ? (listaCidadeObject[e?.idCidade]?.estado?.uf ?? "") : ""),
          estado: (!!listaCidadeObject[e?.idCidade] ? (listaCidadeObject[e?.idCidade]?.estado?.nome ?? "") : "")
        }
      })
    };

    const template = isTemplateGetNet ? <TemplateGetNet ordemServicos={[osData]} /> : <TemplateDefault ordemServicos={[osData]} />;

    const blob = await pdf(template).toBlob();
    const url = URL.createObjectURL(blob);
    window.open(url, '_blank');
  };

  const handleGetCidadesDeCadaEndereco = async () => {
    if (!ordemServico?.enderecos || _.isEmpty(ordemServico?.enderecos)) {
      setListaCidadeObject({});
      return;
    }

    let listaTemp: any[] = [];

    for (let i = 0; i < ordemServico?.enderecos?.length; i++) {
      const idCidade = ordemServico?.enderecos[i]?.idCidade;
      if (!!idCidade) {
        try {
          const { data }: { data: IGetResponseCidades } = await getCidades({ Id: idCidade }) as AxiosResponse;
          const cidades = data?.cidades ?? [];
          listaTemp.push(...cidades);
        } catch (e) { };
      }
    };

    const cidadesObject = listaTemp.reduce((inicial, item) => {
      if (!inicial[item.id]) inicial[item.id] = { ...item };
      return inicial;
    }, {});

    setListaCidadeObject(cidadesObject);
  };

  const handleShowInfoEntrega = () => {
    //TODO: Implementação da US 6362 - Será tratado posteriormente
  }
  const handleShowInfoRetirada = () => { }
  const handleShowInfoManutencao = () => { }

  const handleShowPerimeter = () => {
    return removeSpecialCharSimple(ordemServico?.status || "") === "emcampo";
  };

  const handleSwitchValidaPerimetro = async (value: boolean) => {
    setLoadingPerimetro(true);
    try {
      const { data } = await updatevalidaPerimetro({
        idOS: ordemServico?.chamado,
        validaPerimetro: value
      }) as AxiosResponse;

      setLoadingPerimetro(false);
      handleSetValueOS(value);
      setOpenAlert(true);
      setTipoMsgAlert('success');

      if (!!data && typeof data === 'string') {
        setMsgAlert(data);
      } else {
        setMsgAlert('Sucesso ao atualizar validação de perímetro.');
      }

    } catch (e: any) {
      setLoadingPerimetro(false);
      setOpenAlert(true);
      setTipoMsgAlert('error');

      if (!!e?.response?.data && typeof e?.response?.data === 'string') {
        setMsgAlert(e?.response?.data);
      } else if (e?.response?.data?.errors) {
        setMsgAlert(Object.values(e.response.data.errors).join("<br>"));
      } else {
        setMsgAlert("Erro ao atualizar validação de perímetro" + (e?.response?.status ? ` status: ${e?.response?.status}` : ""));
      }
    }
  };

  const handleSetValueOS = (value: boolean) => {
    const updatedOS = {
      ...ordemServico,
      validaPerimetro: value
    };

    setOrdemServico(updatedOS);
  };

  useEffect(() => {
    handleGetDadosOS();
  }, []);

  useEffect(() => {
    handleGetCidadesDeCadaEndereco();
    setObservacoes(ordemServico?.observacao ?? "");
  }, [ordemServico]);

  const mascararNumeroSerie = (numeroSerie: string | null | undefined, statusOS: string): string => {
    if (!numeroSerie) {
      return ''
    } else if (profileHasPermission("Número Série")) {
      return numeroSerie;
    } else if (statusOS === OrdemServicoStatus.BAIXADA) {
      return numeroSerie;
    } else {
    
    const numeroSemEspeciais = numeroSerie?.replace(/[^a-zA-Z0-9]/g, '');
    const length = numeroSemEspeciais.length;
    
    if (length > 6) {
      const mascarado = '*'.repeat(length - 6) + numeroSemEspeciais.slice(-6);
      return mascarado;
    }
  
    return numeroSemEspeciais;
    }
  };

  return (
    <section className="DetalhesOSContainer">
      {loading && <FullscreenLoading />}

      <Header setMenuLateral={() => setMenuLateral(true)} title="Detalhes da OS" />

      <Breadcrumb>
        {!!location?.state?.rota?.url ?
          <BreadcrumbItem onClick={() => navigate(location.state.rota.url)}>{location.state.rota.name}</BreadcrumbItem>
          :
          <BreadcrumbItem onClick={() => navigate('/ConsultaOS')}>Consulta de OS</BreadcrumbItem>
        }
        <BreadcrumbItem>OS {chamado ?? ordemServico?.chamado}</BreadcrumbItem>
      </Breadcrumb>

      {!ordemServico?.chamado ?
        <React.Fragment>
          {msgAlert}
        </React.Fragment>
        :
        <React.Fragment>
          <ContainerButtons style={{ justifyContent: 'flex-start' }}>
            <Button variant="secondary" onClick={handlePrint} dimension='sm'>
              <PrintIcon fontSize='medium' />
            </Button>
          </ContainerButtons>

          <AnotacoesModal
            text={marcacoes}
            setText={setMarcacoes}
            isModalOpen={anotacoesModal}
            handleCloseModal={() => setAnotacoesModal(false)}
          />
          <ModalEncaminharOS
            handleClose={() => setIsModalOpen(false)}
            isModalOpen={isModalOpen}
          // isCourier={false}
          />

          <ModalChecklistProduto
            isModalOpen={checklistProdutoModal}
            handleCloseModal={() => setChecklistProdutoModal(false)}
            produto={selectedProduto}
          />

          {/* {process.env.REACT_APP_AMBIENTE !== 'PROD' &&
            <div className='SubHeaderContainer'>
              <div className='IconsContainer'>
                <div className={`IconContainer ${!_.isEmpty(marcacoes) ? 'IconEnabled' : ''}`}>
                  <Clipboard onClick={() => setAnotacoesModal(true)} />
                </div>
                <div className='IconContainer'>
                  <Printer />
                </div>
              </div>
              <div className='MudarStatusContainer'>
                <MultipleSelectCheckmarksid
                  dimension='sm'
                  id='multiple-checkbox-status'
                  idOption='multiple-checkbox-option-status'
                  labelId='multiple-checkbox-label-status'
                  nodata="Nenhum status disponível"
                  value={status}
                  onChange={setStatus}
                  options={statusLista}
                  label='Status'
                  name='status'
                  multiple={false}
                />
                <Button onClick={handleSalvar} dimension='sm'>{!_.isEmpty(statusLista) ? statusLista[0].name : 'Salvar'}</Button>
              </div>
            </div>
          } */}

          <div className='DetalheOS'>
            <div className='CardsContainer'>
              <div className='Card'>
                <span className='font14'>N° Chamado</span>
                <p className='font32'>{ordemServico?.chamado}</p>
              </div>
              <div className='Card'>
                <span className='font14'>N° da Referência</span>
                <p className='font32'>{ordemServico?.referencia}</p>
              </div>
              <div className='Card'>
                <span className='font14'>Status</span>
                <p className='font32'>{ordemServico?.status}</p>
              </div>
            </div>
            <div className='Row'>
              <div className='RowItem'>
                <span className='font12'>Contratante</span>
                <p className='font22'>{ordemServico?.contratante}</p>
              </div>
              <div className='RowItem'>
                <span className='font12'>Grupo de Serviço</span>
                <p className='font22'>{ordemServico?.grupoServico}</p>
              </div>
              <div className='RowItem'>
                <span className='font12'>Serviço</span>
                <p className='font22'>{ordemServico?.servico}</p>
              </div>
              <div className='RowItem'>
                <span className='font12'>Tipo de atendimento</span>
                <p className='font22'>{ordemServico?.tipoOS}</p>
              </div>
              <div className='RowItem'>
                <span className='font12'>Visitas realizadas</span>
                <p className='font22'>{ordemServico?.numeroVisita}</p>
              </div>
            </div>

            <TextArea label='Observação' dimension='sm' value={observacoes} onChange={(e) => setObservacoes(e.target.value)} />

            <div className='ButtonContainer'>
              {handleShowPerimeter() ?
                <div className='PerimeterDiv' title={!profileHasPermission('Liberar atendimento fora do perímetro') ? "Sem permissão" : ""}>
                  Permite atender fora do perímetro

                  <SwitchMUI
                    //@ts-ignore
                    checked={!!ordemServico?.validaPerimetro}
                    setChecked={(value: boolean) => handleSwitchValidaPerimetro(value)}
                    type={"IOS"}
                    sx={(
                      !profileHasPermission('Liberar atendimento fora do perímetro') ?
                        { "&.Mui-disabled": { cursor: 'not-allowed', backgroundColor: 'transparent' } }
                        : {}
                    )}
                    disabled={loadingPerimetro || !profileHasPermission('Liberar atendimento fora do perímetro')}
                  />

                  {!!loadingPerimetro && <CircularProgress size={25} />}
                </div>
                :
                <div />
              }

              {profileHasPermission("Editar Registro") ?
                <Button
                  id='addInfoButton'
                  className='IncluirInfoButton'
                  onClick={handleAddObservations}
                >
                  Incluir Informações
                </Button>
                :
                <Button
                  id='addInfoButton'
                  disabled={true}
                  style={{ opacity: '0.5' }}
                  className='IncluirInfoButton'>
                  Incluir Informações
                </Button>
              }
            </div>
          </div>

          <AlertMessage
            isOpenAlert={openAlert}
            setOpenAlert={setOpenAlert}
            alertType={tipomsgAlert}
            msgAlert={msgAlert}
          />

          <div className='AccordionContainer'>
            <div className='AccordionHeader' onClick={() => setOpenDadosCliente(!openDadosCliente)}>
              <p className='font14'>Dados do Cliente</p>
              <ExpandIcon
                id={"IncluirTecnicoPrestadoresAccordion"}
                className={
                  "CardOS-ExpandIcon " + (!!openDadosCliente ? "cardOpen" : "cardClosed")
                }
                onClick={() => {
                  setOpenDadosCliente(!openDadosCliente);
                }}
              />
            </div>
            <Collapse in={openDadosCliente} onClose={setOpenDadosCliente} autoClose={false}>
              <div className={`DadosCliente ${openDadosCliente ? 'open' : 'close'}`}>
                <div className='grid1'>
                  <div className='gridItem'>
                    <span className='font12'>Nome Fantasia</span>
                    <span className='font12 thin'>{permissaoCampo("Nome Fantasia", ordemServico?.cliente?.nomeFantasia)}</span>
                  </div>
                  <div />
                  <div className='gridItem'>
                    <span className='font12'>Razão Social</span>
                    <span className='font12 thin'>{permissaoCampo("Razão Social", ordemServico?.cliente?.razaoSocial)}</span>
                  </div>
                  <div />
                  <div className='gridItem'>
                    <span className='font12'>Documento</span>
                    <span className='font12 thin'>{permissaoCampo("Documento", ordemServico?.cliente?.numeroDocumento)}</span>
                  </div>
                </div>
                {/* <Divider /> */}
                <div className='grid1'>
                  <div className='gridItem'>
                    <span className='font12'>Contato(s)</span>
                    <span className='font12 thin'>{permissaoCampo("Contato", ordemServico?.cliente?.contato)}</span>
                  </div>
                  <div />
                  <div className='gridItem'>
                    <span className='font12'>Código</span>
                    <span className='font12 thin'>{permissaoCampo("Código", ordemServico?.cliente?.codigoCliente)}</span>
                  </div>
                  <div />
                  <div className='gridItem'>
                    <span className='font12'>Telefone(s)</span>
                    <span className='font12 thin' onClick={(e) => e.stopPropagation()}>
                      {profileHasPermission("Telefone") ?
                        <>
                          {
                            ordemServico?.telefonesOS ? (
                              ordemServico.telefonesOS.map((telefone, index, array) => (
                                <React.Fragment key={telefone.numero}>
                                  <a
                                    id={telefone.numero}
                                    href={createUrlToWhatsapp(`${telefone.codigoArea}${telefone.numero}`)}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {telefone.codigoArea}
                                    {telefone.numero}
                                  </a>
                                  {index < array.length - 1 && " / "}
                                </React.Fragment>
                              ))
                            ) : (
                              ""
                            )
                          }
                        </>
                        : "Sem permissão para visualizar"
                      }
                    </span>
                  </div>
                </div>

                {!!ordemServico?.enderecos?.length && ordemServico.enderecos.map(endereco => {
                  return (
                    <div className='DetalheOS'>
                      <span className='title'>Endereço</span>
                      <div className='gridEnderecov2'>
                        <div className='gridItem'>
                          <span className='font12'>Estado</span>
                          <span className='font12 thin'>
                            {permissaoCampo("Estado",
                              (!!listaCidadeObject[endereco?.idCidade] ? (listaCidadeObject[endereco?.idCidade]?.estado?.nome ?? "") : "")
                            )}
                          </span>
                        </div>
                        <div className='gridItem'>
                          <span className='font12'>Cidade</span>
                          <span className='font12 thin'>
                            {permissaoCampo("Cidade",
                              (!!listaCidadeObject[endereco?.idCidade] ? (listaCidadeObject[endereco?.idCidade]?.nome ?? "") : "")
                            )}
                          </span>
                          {/* <span className='font12 thin'>{permissaoCampo("Cidade", endereco?.idCidade)}</span> */}
                        </div>
                        <div className='gridItem'>
                          <span className='font12'>Bairro</span>
                          <span className='font12 thin'>{permissaoCampo("Bairro", endereco?.bairro)}</span>
                        </div>
                        <div className='gridItem'>
                          <span className='font12'>Tipo</span>
                          {/* <span className='font12 thin'>{permissaoCampo("Tipo de Endereço", endereco?.logradouro?.split(' ')[0])}</span> */}
                          <span className='font12 thin'>{permissaoCampo("Tipo de Endereço", endereco?.tipo)}</span>
                        </div>
                        <div className='gridItem'>
                          <span className='font12'>Endereço</span>
                          <span className='font12 thin'>{permissaoCampo("Endereço", endereco?.logradouro)}</span>
                        </div>
                        <div className='gridItem'>
                          <span className='font12'>Nº</span>
                          <span className='font12 thin'>{permissaoCampo("Número", endereco?.numero)}</span>
                        </div>
                      </div>
                      <div className='gridEnderecov2'>
                        <div className='gridItem'>
                          <span className='font12'>Complemento</span>
                          <span className='font12 thin'>{permissaoCampo("Complemento", endereco?.complemento)}</span>
                        </div>
                        <div className='gridItem'>
                          <span className='font12'>Ponto de Referência</span>
                          <span className='font12 thin'>{permissaoCampo("Ponto de Referência", endereco?.pontoReferencia)}</span>
                        </div>
                        <div className='gridItem'>
                          <span className='font12'>Horário Comercial</span>
                          <span className='font12 thin'>{permissaoCampo("Horário Comercial", endereco?.horarioComercial)}</span>
                        </div>
                        <div className='gridItem'>
                          <span className='font12'>CEP</span>
                          <span className='font12 thin'>{permissaoCampo("Cep", endereco?.cep)}</span>
                        </div>
                      </div>
                    </div>
                  );
                })}

              </div>
            </Collapse>
          </div>

          <div className='AccordionContainer'>
            <div className='AccordionHeader' onClick={() => setOpenDadosProduto(!openDadosProduto)}>
              <p className='font14'>Dados do Produto</p>
              <ExpandIcon
                id={"IncluirTecnicoPrestadoresAccordion"}
                className={
                  "CardOS-ExpandIcon " + (!!openDadosProduto ? "cardOpen" : "cardClosed")
                }
                onClick={() => {
                  setOpenDadosProdutoManutencao(!openDadosProduto);
                }}
              />
            </div>
            <Collapse in={openDadosProduto} onClose={setOpenDadosProduto} autoClose={false}>
              {/**TODO: exibir todos produtos (map?) Ex: Instalação, Retirada, manutenção */}
              <div className='DadosProduto'>
                {!!ordemServico?.produtos?.filter((fl) => fl.idTipo == ITipoProdutoConsultaOS.RETIRADA)?.length && ordemServico.produtos.filter((fl) => fl.idTipo == ITipoProdutoConsultaOS.RETIRADA).map(produto => {
                  return (
                    <div className='DetalheOS'>
                      <h4>Retirada</h4>
                      {/* <span className='title'>Instalação</span> */}
                      {/* <span className='title'>{produto?.idTipo}</span> */}
                  <div className='gridEndereco'>
                    <div className="gridItem">
                        <PageviewIcon 
                          className='smallIcon' 
                          onClick={() => {
                            setSelectedProduto(produto)
                            setChecklistProdutoModal(true)
                          }} 
                        />
                      </div>
                    <div className='gridItem'>
                      <span className='font12'>Tipo de Equipamento</span>
                      <span className='font12 thin'>{produto?.tipoEquipamento}</span>
                    </div>
                    <div className='gridItem'>
                      <span className='font12'>Modelo</span>
                      <span className='font12 thin'>{produto?.modelo}</span>
                    </div>
                    <div className='gridItem'>
                      <span className='font12'>Número de série</span>
                      <span className='font12 thin'>
                        {mascararNumeroSerie(produto?.numeroSerie, ordemServico?.status)}
                      </span>
                    </div>
                    <div className='gridItem'>
                      <span className='font12'>ID Terminal</span>
                      <span className='font12 thin'>{produto?.numeroLogico}</span>
                    </div>
                  </div>
                </div>
                  );
                })}
              </div>
              <div className="DadosProduto">
              {!!ordemServico?.produtos?.filter((fl) => fl.idTipo == ITipoProdutoConsultaOS.ENTREGA)?.length && ordemServico.produtos.filter((fl) => fl.idTipo == ITipoProdutoConsultaOS.ENTREGA).map(produto => {
                return (
                  <div className="DetalheOS">
                    <h4>Entrega</h4>
                    {/* <span className='title'>Instalação</span> */}
                    {/* <span className='title'>{produto?.idTipo}</span> */}
                    <div className="gridEndereco">
                      <div className="gridItem">
                        <PageviewIcon 
                          className='smallIcon' 
                          onClick={() => {
                            setSelectedProduto(produto)
                            setChecklistProdutoModal(true)
                          }} 
                        />
                      </div>
                    <div className='gridItem'>
                          <span className='font12'>Tipo de Equipamento</span>
                          <span className='font12 thin'>{produto?.tipoEquipamento}</span>
                        </div>
                        <div className='gridItem'>
                          <span className='font12'>Modelo</span>
                          <span className='font12 thin'>{produto?.modelo}</span>
                        </div>
                        <div className='gridItem'>
                          <span className='font12'>Número de série</span>
                          <span className='font12 thin'>
                            {mascararNumeroSerie(produto?.numeroSerie, ordemServico?.status)}
                          </span>
                        </div>
                        <div className='gridItem'>
                          <span className='font12'>ID Terminal</span>
                          <span className='font12 thin'>{produto?.numeroLogico}</span>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="DadosProduto">
                {!!ordemServico?.produtos?.filter((fl) => fl.idTipo == ITipoProdutoConsultaOS.MANUTENCAO)?.length && ordemServico.produtos.filter((fl) => fl.idTipo == ITipoProdutoConsultaOS.MANUTENCAO).map(produto => {
                  return (
                    <div className="DetalheOS">
                      <h4>Manutenção</h4>
                      {/* <span className='title'>Instalação</span> */}
                      {/* <span className='title'>{produto?.idTipo}</span> */}
                      <div className="gridEndereco">
                        <div className="gridItem">
                          <PageviewIcon
                            className='smallIcon'
                            onClick={() => {
                              setSelectedProduto(produto)
                              setChecklistProdutoModal(true)
                            }}
                          />
                      </div>
                     <div className='gridItem'>
                        <span className='font12'>Tipo de Equipamento</span>
                        <span className='font12 thin'>{produto?.tipoEquipamento}</span>
                      </div>
                      <div className='gridItem'>
                        <span className='font12'>Modelo</span>
                        <span className='font12 thin'>{produto?.modelo}</span>
                      </div>
                      <div className='gridItem'>
                        <span className='font12'>Número de série</span>
                        <span className='font12 thin'>
                          {mascararNumeroSerie(produto?.numeroSerie, ordemServico?.status)}
                        </span>
                      </div>
                      <div className='gridItem'>
                        <span className='font12'>ID Terminal</span>
                        <span className='font12 thin'>{produto?.numeroLogico}</span>
                      </div>
                    </div>
                    </div>
                  );
                })}
              </div>
            </Collapse>
          </div>

          <div className='AccordionContainer'>
            <div className='AccordionHeader' onClick={() => setOpenDadosChip(!openDadosChip)}>
              <p className='font14'>Dados do Chip</p>
              <ExpandIcon
                id={"IncluirTecnicoPrestadoresAccordion"}
                className={
                  "CardOS-ExpandIcon " + (!!openDadosChip ? "cardOpen" : "cardClosed")
                }
                onClick={() => {
                  setOpenDadosChip(!openDadosChip);
                }}
              />
            </div>
            <Collapse in={openDadosChip} onClose={setOpenDadosChip} autoClose={false}>
              <div className="DadosProduto">
                {!!ordemServico?.atendimentos?.length && !!ordemServico?.atendimentos[0]?.chips.length && ordemServico?.atendimentos[0]?.chips?.map(chip => {
                  return (
                    <div className='DetalheOS'>
                      <h4>Retirada</h4>
                      <div className='gridChips'>
                        <div className='gridItem'>
                          <span className='font12'>Modelo do Chip Instalado</span>
                          <span className='font12 thin'>{chip.idModeloInstalado}</span>
                        </div>
                        <div className='gridItem'>
                          <span className='font12'>Modelo do Chip Retirado</span>
                          <span className='font12 thin'>{chip.idModeloRemovido}</span>
                        </div>
                        <div className='gridItem'>
                          <span className='font12'>Número do serial do chip instalado</span>
                          <span className='font12 thin'>{chip?.serialNumberInstalado}</span>
                        </div>
                        <div className='gridItem'>
                          <span className='font12'>Número do serial do chip retirado</span>
                          <span className='font12 thin'>{chip?.serialNumberRemovido}</span>
                        </div>
                        <div className='gridItem'>
                          <span className='font12'>Coletou Chip?</span>
                          <span className='font12 thin'>{chip?.coletouChip ? 'SIM' : chip?.coletouChip === false ? 'NÃO' : ''}</span>
                        </div>
                        <div className='gridItem'>
                          <span className='font12'>Chip legível?</span>
                          <span className='font12 thin'>{chip?.chipLegivel ? 'SIM' : chip?.chipLegivel === false ? 'NÃO' : ''}</span>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </Collapse>

          </div>

          <div className='AccordionContainer'>
            <div className='AccordionHeader' onClick={() => setOpenPeriodos(!openPeriodos)}>
              <p className='font14'>Períodos</p>
              <ExpandIcon
                id={"IncluirTecnicoPrestadoresAccordion"}
                className={
                  "CardOS-ExpandIcon " + (!!openPeriodos ? "cardOpen" : "cardClosed")
                }
                onClick={() => {
                  setOpenPeriodos(!openPeriodos);
                }}
              />
            </div>
            <Collapse in={openPeriodos} onClose={setOpenPeriodos} autoClose={false}>
              <div className={`Periodos ${openPeriodos ? 'open' : 'close'}`}>
                <div className='grid1'>
                  <div className='gridItem'>
                    <span className='font12'>Data de Abertura</span>
                    <span className='font12 thin'>
                      {!!ordemServico?.dataAbertura ? moment(ordemServico?.dataAbertura).format("DD/MM/YYYY HH:mm") : ""}
                    </span>
                  </div>
                  <div className='gridItem'>
                    <span className='font12'>Data Limite Prestador</span>
                    <span className='font12 thin'>
                      {!!ordemServico?.previsaoAtendimento ? moment(ordemServico?.previsaoAtendimento).format("DD/MM/YYYY HH:mm") : ""}
                    </span>
                  </div>
                  <div className='gridItem'>
                    <span className='font12'>Data Limite Cliente</span>
                    <span className='font12 thin'>
                      {!!ordemServico?.dataLimite ? moment(ordemServico?.dataLimite).format("DD/MM/YYYY HH:mm") : ""}
                    </span>
                  </div>
                  <div className='gridItem'>
                    <span className='font12'>Data Atendimento</span>
                    <span className='font12 thin'>
                      {!!ordemServico?.dataAtendimento ? moment(ordemServico?.dataAtendimento).format("DD/MM/YYYY HH:mm") : ""}
                    </span>
                  </div>
                </div>
              </div>
            </Collapse>
          </div>

          <div className='AccordionContainer'>
            <div className='AccordionHeader' onClick={() => setOpenDadosAtendimento(!openDadosAtendimento)}>
              <p className='font14'>Dados do Atendimento</p>
              <ExpandIcon
                id={"IncluirTecnicoPrestadoresAccordion"}
                className={
                  "CardOS-ExpandIcon " + (!!openDadosAtendimento ? "cardOpen" : "cardClosed")
                }
                onClick={() => {
                  setOpenDadosAtendimento(!openDadosAtendimento);
                }}
              />
            </div>
            <Collapse in={openDadosAtendimento} onClose={setOpenDadosAtendimento} autoClose={false}>
              <div className={`DadosAtendimento ${openDadosAtendimento ? 'open' : 'close'}`}>
                <div className='grid1'>
                  <div className='gridItem'>
                    <span className='font12'>Solução Apresentada</span>
                    <span className='font12 thin'>{ordemServico?.servico}</span>
                  </div>
                  <div className='gridItem'>
                    <span className='font12'>Centro de Trabalho</span>
                    <span className='font12 thin'>{ordemServico?.codigoCT}</span>
                  </div>
                  <div className='gridItem'>
                    <span className='font12'>Prestador</span>
                    <span className='font12 thin'>{ordemServico?.prestador}</span>
                  </div>
                  <div className='gridItem'>
                    <span className='font12'>Técnico</span>
                    <span className='font12 thin'>{ordemServico?.tecnico}</span>
                  </div>
                </div>
              </div>
            </Collapse>
          </div>

          {/**Bloco Histórico de Atendimentos comentado, dados virão de outra US 4811 */}
          {/* {process.env.REACT_APP_AMBIENTE !== 'PROD' &&
            <div className='AccordionContainer'>
              <div className='AccordionHeader' onClick={() => setAtendimentosRealizados(!openAtendimentosRealizados)}>
                <p className='font14'>Histórico de Atendimentos</p>
                <ExpandIcon
                  id={"IncluirTecnicoPrestadoresAccordion"}
                  className={
                    "CardOS-ExpandIcon " + (!!openAtendimentosRealizados ? "cardOpen" : "cardClosed")
                  }
                  onClick={() => {
                    setAtendimentosRealizados(!openAtendimentosRealizados);
                  }}
                />
              </div>
              <Collapse in={openAtendimentosRealizados} onClose={setAtendimentosRealizados} autoClose={false}>
                <div className='DadosProduto'>
                  <div className='DetalheOS'>
                    <span className='title'>Nº Visita: 1</span>
                    <div className='gridAtendimentosRealizados'>
                      <div className='linha'>
                        <div className='gridItem'>
                          <div className='gridItemLeft'>
                            <div className='column'>
                              <span className='font12'>Check-in</span>
                              <span className='font12 thin'>20/10/2023  10:45</span>
                            </div>
                            <div className='column'>
                              <span className='font12'>Pré-baixa</span>
                              <span className='font12 thin'>20/10/2023  10:45</span>
                            </div>
                            <div className='column'>
                              <span className='font12'>Finalizada app</span>
                              <span className='font12 thin'>Finalizada app</span>
                            </div>
                            <div className='column'>
                              <span className='font12'>Centro de trabalho</span>
                              <span className='font12 thin'>CT00032</span>
                            </div>
                            <div className='column'>
                              <span className='font12'>Prestador</span>
                              <span className='font12 thin'>MG - Filial BH</span>
                            </div>
                            <div className='column'>
                              <span className='font12'>Solução apresentada</span>
                              <span className='font12 thin'>Troca de chip</span>
                            </div>
                            <div className='column'>
                              <span className='font12'>Técnico</span>
                              <span className='font12 thin'>Luís Carlos</span>
                            </div>
                            <div className='column'>
                              <span className='font12'>Incidente</span>
                              <span className='font12 thin'>Incidente</span>
                            </div>
                          </div>

                          <div className='column' style={{ marginTop: 30 }}>
                            <span className='font12'>Obs</span>
                            <span className='font12 thin'>Cliente não estava no local, quem recebeu foi a esposa.</span>
                          </div>
                        </div>

                        <div className='gridItem'>
                          <div className='gridItem-inner'>
                            <span className='font12'>Mapa</span>
                            <DetalhesOSMapa
                              latitude={-46.82699757791212}
                              longitude={-23.498805516080434}
                            />
                          </div>
                          <div className='gridItem-inner'>
                            <span className='font12'>Evidências</span>
                            <Evidencias />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="AtendimentoRealizadoInsucesso">
                      <span className='title'>Insucesso - Protocolado</span>
                      <div className='Insucesso'>
                        <div className='firstGrid'>
                          <div className='gridItem'>
                            <span className='font12'>Nº protocolo técnico</span>
                            <span className='font12 thin'>P16363306202</span>
                          </div>
                          <div className='gridItem'>
                            <span className='font12'>Dt Ger. do Prot. Técnico</span>
                            <span className='font12 thin'>20/10/2023  10:45</span>
                          </div>
                          <div className='gridItem'>
                            <span className='font12'>Macro motivo protocolo</span>
                            <span className='font12 thin'>Tratativa de Reversão SEM SUCESSO</span>
                          </div>
                          <div className='gridItem'>
                            <span className='font12'>Motivo retenção</span>
                            <span className='font12 thin'>(2ª tentativa) Fechado no horário de funcionamento</span>
                          </div>
                        </div>
                        <div className='secondGrid'>
                          <div className='gridItem'>
                            <span className='font12'>Descrição motivo</span>
                            <span className='font12 thin'>P16363306202</span>
                          </div>
                          <div className='gridItem'>
                            <span className='font12'>Motivo sem sucesso</span>
                            <span className='font12 thin'>Fechado no horário de funcionamento</span>
                          </div>
                          <div className='gridItem'>
                            <span className='font12'>Data da ocorrência</span>
                            <span className='font12 thin'>20/10/2023  10:45</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Collapse>
            </div>
          } */}

          {/* <div className='AccordionContainer'>
        <div className='AccordionHeader' onClick={() => setOpenRastreamento(!openRastreamento)}>
          <p className='font14'>Rastreamento</p>
          <ExpandIcon
            id={"IncluirTecnicoPrestadoresAccordion"}
            className={
              "CardOS-ExpandIcon " + (!!openRastreamento ? "cardOpen" : "cardClosed")
            }
            onClick={() => {
              setOpenRastreamento(!openRastreamento);
            }}
          />
        </div>
        <Collapse in={openRastreamento} onClose={setOpenRastreamento} autoClose={false}>
          <div className='DetalheOS'>
            <div className='gridRastreamento'>
              <div className='gridItem'>
                <span className='font12'>Pedido de venda</span>
                <span className='font12 thin'>12345678</span>
              </div>
              <div className='gridItem'>
                <span className='font12'>Número rastreio</span>
                <span className='font12 thin'>12345678</span>
              </div>
              <div className='gridItem'>
                <span className='font12'>Nota fiscal</span>
                <span className='font12 thin'>12345678</span>
              </div>
              <div className='gridItem'>
                <span className='font12'>Data de transferência</span>
                <span className='font12 thin'>00/00/0000</span>
              </div>
              <div className='gridItem'>
                <span className='font12'>Data do recebimento</span>
                <span className='font12 thin'>00/00/0000</span>
              </div>
              <div className='gridItem'>
                <Button>Etiqueta</Button>
              </div>
            </div>

            <StickyTable
            id={"TabelaResultadoConsultaOS"}
            data={tableData}
            objTraducaoHeader={objTraducaoHeader}
            />
          </div>
        </Collapse>
      </div> */}
        </React.Fragment>
      }
    </section>
  );
};

export default DetalhesOS;
