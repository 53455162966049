import { useEffect, useState } from "react";
import _ from "lodash";

import { getListaPlacaMoto, getListaTecnicosOS, getListaTipoMoto } from "../pages/tecnico/services";
import { ISimpleObjectInput, Ordem } from "../core/types";
import { ITecnicoOS } from "../pages/tecnico/types";
import { IChecklistContext } from "./types/checklistTypes";
import { toSimpleObjectInput } from "../core/helpers/array";
import { IAutocompleteValue } from "../components/autocompleteMultiple/types";
import { getPerguntasByGrupos } from "../pages/checklist/services";
import { EPerguntaGrupo } from "../pages/checklist/types/constants";

export const ChecklistContext = () => {
  const [checklistFiltroDataInicial, setChecklistFiltroDataInicial] = useState<string>("");
  const [checklistFiltroDataFinal, setChecklistFiltroDataFinal] = useState<string>("");

  const [checklistTecnicoLista, setChecklistTecnicoLista] = useState<ISimpleObjectInput[]>([]);
  const [checklistFiltroTecnicosSelecionados, setChecklistFiltroTecnicoSelecionados] = useState<ISimpleObjectInput[]>([]);

  const [checklistFiltroPlacaMotoLista, setChecklistFiltroPlacaMotoLista] = useState<ISimpleObjectInput[]>([]);
  const [checklistFiltroPlacaMotoSelecionados, setChecklistFiltroPlacaMotoSelecionados] = useState<ISimpleObjectInput[]>([]);
  const [checklistFiltroTipoMotoLista, setChecklistFiltroTipoMotoLista] = useState<ISimpleObjectInput[]>([]);
  const [checklistFiltroTipoMotoSelecionados, setChecklistFiltroTipoMotoSelecionados] = useState<ISimpleObjectInput[]>([]);

  const [checklistFiltroKMInicial, setChecklistFiltroKMInicial] = useState<string>("");
  const [checklistFiltroKMFinal, setChecklistFiltroKMFinal] = useState<string>("");

  const [checklistStatusItemMotoLista, setChecklistStatusItemMotoLista] = useState<ISimpleObjectInput[]>([]);
  const [checklistFiltroStatusItemMoto, setChecklistFiltroStatusItemMoto] = useState<ISimpleObjectInput[]>([]);
  const [checklistItemMotoLista, setChecklistItemMotoLista] = useState<ISimpleObjectInput[]>([]);
  const [checklistFiltroItemMoto, setChecklistFiltroItemMoto] = useState<ISimpleObjectInput[]>([]);

  const [checklistStatusItemEPILista, setChecklistStatusItemEPILista] = useState<ISimpleObjectInput[]>([]);
  const [checklistFiltroStatusItemEPI, setChecklistFiltroStatusItemEPI] = useState<ISimpleObjectInput[]>([]);
  const [checklistItemEPILista, setChecklistItemEPILista] = useState<ISimpleObjectInput[]>([]);
  const [checklistFiltroItemEPI, setChecklistFiltroItemEPI] = useState<ISimpleObjectInput[]>([]);

  const [checklistOrdem, setChecklistOrdem] = useState<Ordem>('asc');
  const [checklistOrdenacaoPor, setChecklistOrdenacaoPor] = useState({} as IAutocompleteValue);

  const handleGetTecnicos = async () => {
    if (!_.isEmpty(checklistFiltroTecnicosSelecionados)) {
      setChecklistTecnicoLista([]);
    }

    try {
      const { tecnicos } = await getListaTecnicosOS({
        Ativo: true,
      });
      var tmp: ISimpleObjectInput[] = tecnicos.map((p: ITecnicoOS): ISimpleObjectInput => ({
        name: p.nome,
        id: p.idTecnico.toString(),
      }));
      setChecklistTecnicoLista(tmp);
    } catch (e) {
      setChecklistTecnicoLista([]);
    }
  };

  const handleGetTiposMoto = async () => {
    if (!_.isEmpty(checklistFiltroTipoMotoSelecionados)) {
      setChecklistFiltroTipoMotoLista([]);
    }

    try {
      const { tipos } = await getListaTipoMoto({
        Ativo: true,
      });
      var tmp: ISimpleObjectInput[] = toSimpleObjectInput(tipos, "nome");
      setChecklistFiltroTipoMotoLista(tmp);
    } catch (e) {
      setChecklistFiltroTipoMotoLista([]);
    }
  };

  const handleGetPlacasMoto = async () => {
    if (!_.isEmpty(checklistFiltroPlacaMotoSelecionados)) {
      setChecklistFiltroPlacaMotoLista([]);
    }

    try {
      const { placas } = await getListaPlacaMoto({
        Ativo: true,
      });
      var tmp: ISimpleObjectInput[] = toSimpleObjectInput(placas, "nome");
      setChecklistFiltroPlacaMotoLista(tmp);
    } catch (e) {
      setChecklistFiltroPlacaMotoLista([]);
    }
  };

  const handleGetItemDaMoto = async () => {
    try {
      const data = await getPerguntasByGrupos({ idGrupos: [EPerguntaGrupo.ITENS_MOTO], ehExportar: true });
      if (data) {
        var tmp: ISimpleObjectInput[] = toSimpleObjectInput(data, "nome", "identificador");
        setChecklistItemMotoLista(tmp);
      }
    } catch (e: any) {
      setChecklistItemMotoLista([] as any);
    }
  };

  const handleGetEPI = async () => {
    try {
      const data = await getPerguntasByGrupos({ idGrupos: [EPerguntaGrupo.EPIS], ehExportar: true });
      if (data) {
        var tmp: ISimpleObjectInput[] = toSimpleObjectInput(data, "nome", "identificador");
        setChecklistItemEPILista(tmp);
      }
    } catch (e: any) {
      setChecklistItemEPILista([] as any);
    }
  };

  useEffect(() => {
    setChecklistStatusItemMotoLista([
      { id: "OK", name: "OK" },
      { id: "NOK", name: "NOK" }
    ]);
    handleGetItemDaMoto();

    setChecklistStatusItemEPILista([
      { id: "OK", name: "Sim" },
      { id: "NOK", name: "Não" }
    ]);
    handleGetEPI();

    handleGetTecnicos();
    handleGetTiposMoto();
    handleGetPlacasMoto();
  }, []);

  var context = {
    checklistFiltroDataInicial,
    setChecklistFiltroDataInicial,
    checklistFiltroDataFinal,
    setChecklistFiltroDataFinal,

    checklistTecnicoLista,
    setChecklistTecnicoLista,
    checklistFiltroTecnicosSelecionados,
    setChecklistFiltroTecnicoSelecionados,

    checklistFiltroPlacaMotoLista,
    setChecklistFiltroPlacaMotoLista,
    checklistFiltroPlacaMotoSelecionados,
    setChecklistFiltroPlacaMotoSelecionados,

    checklistFiltroTipoMotoLista,
    setChecklistFiltroTipoMotoLista,
    checklistFiltroTipoMotoSelecionados,
    setChecklistFiltroTipoMotoSelecionados,

    checklistFiltroKMInicial,
    setChecklistFiltroKMInicial,
    checklistFiltroKMFinal,
    setChecklistFiltroKMFinal,

    checklistStatusItemMotoLista,
    checklistFiltroStatusItemMoto,
    setChecklistFiltroStatusItemMoto,
    checklistItemMotoLista,
    checklistFiltroItemMoto,
    setChecklistFiltroItemMoto,

    checklistItemEPILista,
    checklistFiltroItemEPI,
    setChecklistFiltroItemEPI,
    checklistStatusItemEPILista,
    checklistFiltroStatusItemEPI,
    setChecklistFiltroStatusItemEPI,

    checklistOrdem,
    setChecklistOrdem,

    checklistOrdenacaoPor,
    setChecklistOrdenacaoPor,

  } as unknown as IChecklistContext;

  return context;
};