import { useState } from "react"
import { IPrestadorResponsavel } from "../pages/prestadorResponsavel/types"

export const PrestadorResponsavelContext = () => {
  
  const [prestadorResponsavel, setPrestadorResponsavel] = useState<IPrestadorResponsavel>({} as IPrestadorResponsavel)

  return{
    prestadorResponsavel,
    setPrestadorResponsavel
  }
}