import { useState } from "react";
import { IGetResponseServico } from "../pages/servicos/types";

export const ServicoContext = () => {
  const [servicoSelecionado, setServicoSelecionado] = useState({} as IGetResponseServico);

  return {
    servicoSelecionado,
    setServicoSelecionado
  }
};
