import styled from "styled-components"
import { TableRow } from '@mui/material';

export const StyledTableRow =  styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: "#D9D9D92E",
  },
  '&.inactive': {
    '& .MuiTableCell-root': {
      color: 'rgba(196, 191, 191, 0.38)', // Cor mais clara para texto inativo
    },
    '& .EditIconStyled': {
      opacity: 0.5,
      cursor: 'not-allowed',
    },
  },
}));