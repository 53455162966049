import React, { useContext, useEffect, useState } from 'react'
import MultipleSelectCheckmarksid from "../../../components/seletorMultiploId/seletorMultiploId";
import Button from '../../../components/Button/styles';
import Header from '../../../components/header/header';
import { Breadcrumb, BreadcrumbItem } from '../../../components/breadcrumb/breadcrumb';
import { HomeContext } from '../../../contexts/homeContext';
import { Alert, IconButton, Table, TableRow } from '@mui/material';
import Collapse from "../../../components/collapse/collapse";
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import * as Styles from "../../../components/table/styles";
import SwitchMUI from '../../../components/switch/switch';
import _ from 'lodash';
import { StyledTableRow } from './parametroInsucessoStyles';
import { deleteRemoveVinculoContratante, getMotivosCancelamento, getMotivosCancelamentoVinculadosContratante, postVinculaContratanteComMotivoCancelamento } from '../services/services';
import { AxiosResponse } from 'axios';
import { IIncluiVinculoContratante, IMotivosCancelamento, IRemoveVinculoContratante } from '../types';
import { AuthContext } from '../../../contexts/auth';
import CloseIcon from "@mui/icons-material/Close";
import FullscreenLoading from '../../../components/fullscreenLoading/fullscreenLoading';

const ParametroInsucesso = () => {

  const { user } = useContext(AuthContext)

  const { setMenuLateral, listaContratante, localGetContratantes } = useContext(HomeContext)

  const [ contratanteSelecionado, setContratanteSelecionado ] = useState<string[]>([])
  const [ listaMotivos, setListaMotivos ] = useState<{id: number, nome: string, ativo: boolean}[]>([])
  const [ listaRemover, setListaRemover ] = useState<number[]>([])
  const [ listaVincular, setListaVincular ] = useState<number[]>([])

  const [msgAlertError, setMsgAlertError] = useState("OS encaminhada com  sucesso!");
  const [openAlertError, setOpenAlertError] = useState(false);

  const [msgAlertSuccess, setMsgAlertSuccess] = useState("OS encaminhada com  sucesso!");
  const [openAlertSuccess, setOpenAlertSuccess] = useState(false);

  const [ loading, setLoading ] = useState(false)

  const handleChangeSwitch = (row: {id: number, nome: string, ativo: boolean}) => {
    setListaMotivos(list => (
      list.map(item => (
        item.id === row.id ?
          {...item, ativo: !item.ativo} : item
      ))
    ))

    if(row.ativo){
      const temp = listaRemover.filter(item => item !== row.id)
      setListaRemover(arr => [...temp, row.id])
      setListaVincular(listaVincular.filter(item => item !== row.id))
    } else {
      const temp = listaVincular.filter(item => item !== row.id)
      setListaVincular(arr => [...temp, row.id])
      setListaRemover(listaRemover.filter(item => item !== row.id))
    }
    
  }

  const motivosCancelamento = async () => {
    setLoading(true)
    try{

      const { data } = await getMotivosCancelamento() as AxiosResponse

      handleSortListaMotivo(data?.map((item: IMotivosCancelamento) => {
        return {
          id: item?.id,
          nome: item?.descricao,
          ativo: item?.status
        }
      }))

    } catch(e){
      console.log(e)
    }
    setLoading(false)
  }

  const handleSubmit = async () => {
    setLoading(true)

    const paramsIncluir: IIncluiVinculoContratante = {
      idContratante: Number(contratanteSelecionado[0]),
      idsMotivoCancelamento: listaVincular,
      idUsuarioCriacao: user?.idUsuario,
      nomeUsuarioCriacao: user?.nome
    }

    const paramsDeletar: IRemoveVinculoContratante = {
      idContratante: Number(contratanteSelecionado[0]),
      idsMotivoCancelamento: listaRemover
    }

    if(!_.isEmpty(paramsDeletar.idsMotivoCancelamento)){
      try{

        const { data } = await deleteRemoveVinculoContratante(paramsDeletar) as AxiosResponse

        if(_.isEmpty(paramsIncluir.idsMotivoCancelamento)){
          setMsgAlertSuccess(data)
          setOpenAlertSuccess(true)
        }

      } catch (e){
        setMsgAlertError('Erro ao remover motivo de cancelamento')
        setOpenAlertError(true)
      }
    }

    if(!_.isEmpty(paramsIncluir.idsMotivoCancelamento)) {
      try{

        const { data } = await postVinculaContratanteComMotivoCancelamento(paramsIncluir) as AxiosResponse

        setMsgAlertSuccess(data)
        setOpenAlertSuccess(true)

      } catch (e){
        setMsgAlertError('Erro ao vincular motivo de cancelamento')
        setOpenAlertError(true)
      }
    }

    handleSortListaMotivo(listaMotivos);
    
    setListaRemover([])
    setListaVincular([])
    setLoading(false)
  }

  const handleChangeContratante = async (e: string[]) => {
    setLoading(true)
    setContratanteSelecionado(e)

    try{
      const { data } = await getMotivosCancelamentoVinculadosContratante(e[0]) as AxiosResponse

      const list = listaMotivos.map((item) => {
          if(data.some((someItem: IMotivosCancelamento) => someItem.id === item.id)){
            item.ativo = true
          } else {
            item.ativo = false
          }
        return item
      })
  
      handleSortListaMotivo(list)
    } catch (e){
      setMsgAlertError('Erro ao buscar motivos vinculados ao contratante')
      setOpenAlertError(true)
    }
    
    setLoading(false)
    
  }


  useEffect(() => {
    if(_.isEmpty(listaMotivos)) motivosCancelamento()
    localGetContratantes()
  }, [])

  const handleSortListaMotivo = (data: any[]) => {
    const tmp = data.sort((a, b) => {
      if (b.ativo !== a.ativo) {
        return Number(b.ativo) - Number(a.ativo);
      }
      return a.id - b.id;
    });
    setListaMotivos(tmp);
  }

  return (
    <section className="ParametroInsucesso">
      <Header setMenuLateral={() => setMenuLateral(true)} title="Parâmetro de Insucesso"/>
      <Breadcrumb>
        <BreadcrumbItem>Parâmetro de Insucesso</BreadcrumbItem>
      </Breadcrumb>

      {loading && <FullscreenLoading />}

      <div className='filter'>
        <MultipleSelectCheckmarksid
         nodata="Nenhum contratante encontrado"
         options={listaContratante}
         value={contratanteSelecionado}
         onChange={handleChangeContratante}
         label='Contratante'
         id="multiple-checkbox-contratante"
         idOption="multiple-checkbox-option-contratante-"
         labelId="multiple-checkbox-label-contratante"
         multiple={false}
         dimension='sm'
        />
      </div>
      {(!_.isEmpty(listaMotivos) && !_.isEmpty(contratanteSelecionado)) &&
      <>
      <TableContainer component={Styles.StyledPaper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              {['ID do motivo', 'Lista de motivos', 'Habilitar']?.map((title) => 
                <Styles.StyledTableCell className={`${title} center`} key={title}>{title}</Styles.StyledTableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {listaMotivos.map((row, index) => (
              <StyledTableRow key={`${row.id}`}>
                <Styles.StyledTableCell className='center' style={{textAlign: "center", width: '10%'}}>
                  {row.id}
                </Styles.StyledTableCell>
                <Styles.StyledTableCell component="th" scope="row">
                  {row.nome}
                </Styles.StyledTableCell>
                <Styles.StyledTableCell className='center' style={{textAlign: "center", width: '10%'}}>
                  <SwitchMUI
                    disabled={false}
                    checked={row.ativo}
                    setChecked={() => handleChangeSwitch(row)}
                    type={"IOS"}
                  />
                </Styles.StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Collapse onClose={setOpenAlertSuccess} in={openAlertSuccess}>
        <Alert
          severity='success'
          icon={<div />}
          variant="filled"
          sx={{
            mb: 2,
            bgcolor: "#178B319E",
            height: "67px",
            borderRadius: "15px",
            boxSizing: "border-box",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: "20px",
            marginBottom: 0,
          }}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="medium"
              onClick={() => setOpenAlertSuccess(false)}
            >
              <CloseIcon
                id={"ModalEncaminharOS-AlertCloseIcon"}
                fontSize="inherit"
                sx={{
                  color: "white",
                }}
              />
            </IconButton>
          }
        >
          <span
            style={{
              color: "white",
              fontSize: "16px",
            }}
            dangerouslySetInnerHTML={{__html: msgAlertSuccess}}
          />
        </Alert>
      </Collapse>
      <Collapse onClose={setOpenAlertError} in={openAlertError}>
        <Alert
          severity='error'
          icon={<div />}
          variant="filled"
          sx={{
            mb: 2,
            bgcolor: "rgb(255, 35, 35, 0.7)",
            height: "67px",
            borderRadius: "15px",
            boxSizing: "border-box",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: "20px",
            marginBottom: 0,
          }}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="medium"
              onClick={() => setOpenAlertError(false)}
            >
              <CloseIcon
                id={"ModalEncaminharOS-AlertCloseIcon"}
                fontSize="inherit"
                sx={{
                  color: "white",
                }}
              />
            </IconButton>
          }
        >
          <span
            style={{
              color: "white",
              fontSize: "16px",
            }}
            dangerouslySetInnerHTML={{__html: msgAlertError}}
          />
        </Alert>
      </Collapse>
      <div style={{width: '100%', justifyContent: 'flex-end', display: 'flex'}}>
        <Button onClick={handleSubmit}>Salvar lista</Button>
      </div>
      </>
      }
    </section>
  )
}

export default ParametroInsucesso