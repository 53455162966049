import React, { useState, useEffect, Fragment } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import './autocompleteTecnicos.scss';
import { IAutocompleteTecnico } from './types'

const Autocompletetecnico = ({
  id = "Autocompletetecnico",
  placeholder,
  fontSize = 12,
  options,
  noOptionsText = "Sem dados",
  autocompleteValue = {label: '', id: ''},
  onChangeValue,
  requestOptions,
  disabled = false,
  label,
  dimension = 'lg'
}: IAutocompleteTecnico) => {
  const [value, setValue] = useState<{label: string, id: string} | null>(autocompleteValue);
  const [inputValue, setInputValue] = useState("");
  const [open, setOpen] = useState(false);
  const [getDone, setGetDone] = useState(false);
  const loading = open && options.length === 0 && !getDone;

  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    if (!!requestOptions) {
      try{
        const res = requestOptions()
          if(!!res) setGetDone(true);
      } catch (e){
        setGetDone(true);
      }
    }

    return () => {
      active = false;
    };
  }, [loading]);

  return (
    <Autocomplete
      disabled={disabled}
      className="Autocomplete"
      fullWidth
      value={autocompleteValue === null ? null : value}
      onChange={(event, newValue) => {
        
        setValue(newValue);
        if (!!onChangeValue) onChangeValue(newValue);
      }}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      loading={loading}
      disablePortal
      id={id}
      options={options}
      noOptionsText={noOptionsText}
      classes={{noOptions: "noOptionsWrapperClass"}}
      renderInput={(params) => (
        <>
        {label && <span className={`label ${dimension}`}>{label}</span>}
        <TextField
          {...params}
          placeholder={placeholder}
          className={`Input-tecnico ${dimension}`}
          InputProps={{
            ...params.InputProps,
            style: { fontSize: fontSize, minHeight: '44px', borderRadius: '15px' },
            endAdornment: (
              <Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </Fragment>
            ),
          }}
        />
        </>
      )}
      renderOption={(params, option) => (
        <li
          {...params}
          key={option.id}
          id={`autocomplete-option-${option.id}`}
        >
          {option.label}
        </li>
      )}
    />
  );
};

export default Autocompletetecnico;
