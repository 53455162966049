import styled from "styled-components";
import { Button } from "@mui/material";
export const Section = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
`;

export const SearchButton = styled(Button)`
  font-size: 14px !important;
  height: 44px !important;
  margin-top: 35px !important;
  border-radius: 15px !important;
  padding: 0 20px 0 20px !important;
`;
export const NewButton = styled(Button)`
  background: #AFB1B1 !important;
  color: #FFF !important;
  font-size: 14px !important;
  height: 44px !important;
  border-radius: 15px !important;
  margin-top: 35px !important;
  padding: 0 40px 0 40px !important;
`;
export const ButtonsBox = styled.div`
  display: grid;
  grid-template-columns: 160px 190px;
  gap: 20px;
  -webkit-box-align: baseline;
  align-items: end;

  @media only screen and (max-width: 1000px) {
    grid-template-columns: 1fr;
    gap: 16px;
  }
`;