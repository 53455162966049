import React, { useContext, useEffect, useState } from 'react';
import Header from '../../../components/header/header';
import { HomeContext } from '../../../contexts/homeContext';
import { AuthContext } from "../../../contexts/auth";
import { Breadcrumb, BreadcrumbItem } from '../../../components/breadcrumb/breadcrumb';
import { useNavigate } from 'react-router';
import MultipleSelectCheckmarksId from '../../../components/seletorMultiploId/seletorMultiploId';
import Input from '../../../components/Input/Input';
import moment from 'moment';
import "./incluirEditarGrupoServico.scss";
import Button from '../../../components/Button/styles';
import _ from 'lodash';
import { AxiosResponse } from 'axios';
import { useFormik } from "formik";
import * as yup from "yup";
import { getGroup_Services, postGrupoServico, putGrupoServico } from '../services/services';
import AlertMessage from "../../../components/AlertMessage/alertMessage";
import CircularProgress from '@mui/material/CircularProgress';
import { textToUpperCaseNoSpaceNoSpecialChar, textToUpperCaseNoSpecialChar } from '../../../helpers/formatText';
import { ResponseApi } from '../../../contexts/types/responsesHttp';
import { removeSpecialCharSimple } from '../../../helpers/removeSpecialCharSimple';
import { IGetResponseGrupoServico } from '../types';

interface IInitialValues {
  ativo: string[],
  codGrupoServico: string,
  descricao: string,
  createdBy: string,
  modifiedBy: string,
  createdAt: string,
  modifiedAt: string,
};

const IncluirEditarGrupoServico = () => {
  const isIncluding = window.location.pathname.includes('IncluiGrupoServico');
  const { setMenuLateral, grupoServicoSelected } = useContext(HomeContext);
  const { funcionalidadeDaTelaTemPermissao } = useContext(AuthContext);
  const profileHasPermission = (funcionalidade: string) => funcionalidadeDaTelaTemPermissao(
    isIncluding ? "Inclusão Grupo de Serviço" : "Edição Grupo de Serviço",
    funcionalidade);
  const navigate = useNavigate();

  const [tipomsgAlert, setTipoMsgAlert] = useState<'success' | 'info' | 'warning' | 'error'>("success");
  const [msgAlert, setMsgAlert] = useState("");
  const [openAlert, setOpenAlert] = useState(false);
  const [disableActions, setDisableActions] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);

  const [listaGrupoServico, setListaGrupoServico] = useState<IGetResponseGrupoServico>([]);

  const validaNomeDuplicado = (newName: string | undefined, ctx: any) => {
    const cleanNewName = removeSpecialCharSimple(newName ?? "");

    /**No modo edição pode manter o nome inicial */
    if (!isIncluding) {
      // const { parent }: { parent: IInitialValues } = ctx;
      const initialName = removeSpecialCharSimple(grupoServicoSelected?.nome ?? '');
      const sameName = initialName === cleanNewName;

      if (sameName) return true;
    }

    return !listaGrupoServico.find((i: any) => removeSpecialCharSimple(i?.nome) === cleanNewName);
  };

  const validationSchema = yup.object({
    ativo: yup.array().max(1).min(1, 'Campo obrigatório'),
    descricao: yup.string().required("Campo obrigatório").test(
      'nomeJaExistente',
      'Já existe um registro com a mesma informação.',
      validaNomeDuplicado
    ),
  });

  const initialValuesIncluding: IInitialValues = {
    ativo: ['0'],
    codGrupoServico: '',
    descricao: '',
    createdBy: '',
    modifiedBy: '',
    createdAt: '',
    modifiedAt: '',
  };

  const initialValuesEditing: IInitialValues = {
    ativo: [Number(grupoServicoSelected.ativo).toString()],
    //@ts-ignore
    codGrupoServico: grupoServicoSelected?.codGrupoServico ?? "",
    descricao: grupoServicoSelected?.nome ?? "",
    createdBy: grupoServicoSelected?.nomeUsuarioCriacao ?? "",
    modifiedBy: grupoServicoSelected?.nomeUsuarioModificacao ?? "",
    createdAt: grupoServicoSelected?.dataCriacao ? moment(grupoServicoSelected.dataCriacao, "YYYY-MM-DDTHH:mm:ss").format("DD/MM/YYYY HH:mm") : "",
    modifiedAt: grupoServicoSelected?.dataModificacao ? moment(grupoServicoSelected.dataModificacao, "YYYY-MM-DDTHH:mm:ss").format("DD/MM/YYYY HH:mm") : "",
  };

  const formik = useFormik({
    initialValues: isIncluding ? initialValuesIncluding : initialValuesEditing,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async (values, actions) => {
      if (isIncluding) {
        if (!!profileHasPermission("Novo Registro")) return handleIncludeGrupoServico(values);
      } else {
        if (!!profileHasPermission("Editar Registro")) return handleEditGrupoServico(values);
      }
    }
  });

  const handleGetGroupServices = async () => {
    await getGroup_Services({ CarregarServicos: false }).then((resp: any) => {

      const respSuccess = resp?.data as ResponseApi<IGetResponseGrupoServico>;
      const grupos = respSuccess?.data as any[] ?? [];

      setListaGrupoServico(grupos);
    }, (err: any) => {
      const respErr = err?.response?.data as ResponseApi<any>;
    });
  };

  const handleIncludeGrupoServico = async (values: IInitialValues) => {
    setDisableActions(true);
    setLoading(true);

    try {
      const { data } = await postGrupoServico({
        ativo: values.ativo[0] === '1' ? true : false,
        // codGrupoServico: values.codGrupoServico,
        nome: values.descricao
      }) as AxiosResponse;

      setDisableActions(false);
      setLoading(false);
      setMsgAlert(`Grupo de serviço ${values.descricao} criado com sucesso!`);
      setTipoMsgAlert("success");
      setOpenAlert(true);

      formik.resetForm();

    } catch (e: any) {
      setDisableActions(false);
      setLoading(false);
      if (e?.response?.data?.errors && _.isObject(e?.response?.data?.errors)) {
        setMsgAlert(Object.values(e.response.data.errors).join("<br>"));
      } else {
        setMsgAlert("Erro ao criar grupo de serviço" + (e?.response?.status ? ` status: ${e?.response?.status}` : ""));
      }
      setTipoMsgAlert("error");
      setOpenAlert(true);
    };
  };

  const handleEditGrupoServico = async (values: IInitialValues) => {
    setDisableActions(true);
    setLoading(true);

    try {
      const { data } = await putGrupoServico(grupoServicoSelected.id, {
        ativo: values.ativo[0] === '1' ? true : false,
        // codGrupoServico: values.codGrupoServico,
        nome: values.descricao
      }) as AxiosResponse;

      setDisableActions(false);
      setLoading(false);
      setMsgAlert(`Grupo de serviço ${values.descricao} alterado com sucesso!`);
      setTipoMsgAlert("success");
      setOpenAlert(true);

    } catch (e: any) {
      setDisableActions(false);
      setLoading(false);
      if (e?.response?.data?.errors && _.isObject(e?.response?.data?.errors)) {
        setMsgAlert(Object.values(e.response.data.errors).join("<br>"));
      } else {
        setMsgAlert("Erro ao alterar grupo de serviço" + (e?.response?.status ? ` status: ${e?.response?.status}` : ""));
      }
      setTipoMsgAlert("error");
      setOpenAlert(true);
    };
  };

  const statusLista = [
    { name: 'Ativo', id: '1' },
    { name: 'Inativo', id: '0' }
  ];

  useEffect(() => {
    handleGetGroupServices();
  }, []);

  return (
    <section className='IncluirEditarGrupoServico'>
      <Header setMenuLateral={() => setMenuLateral(true)} title={isIncluding ? 'Cadastro de Grupos de Serviço' : 'Edição de Grupos de Serviço'} />

      <Breadcrumb>
        <BreadcrumbItem onClick={() => navigate("/ConsultaGrupoServico")}>Grupo de Serviço</BreadcrumbItem>
        <BreadcrumbItem>{isIncluding ? 'Cadastro de Grupos de Serviço' : 'Edição de Grupos de Serviço'}</BreadcrumbItem>
      </Breadcrumb>

      <form className="Container" onSubmit={formik.handleSubmit}>
        <div className="IncluirGrupoServicoGrid">
          <MultipleSelectCheckmarksId
            nodata={"Nenhum status encontrado"}
            options={statusLista}
            value={formik.values.ativo}
            onChange={(e) => formik.setFieldValue('ativo', e)}
            placeholder={"Selecionar status"}
            id="multiple-checkbox-status"
            idOption="multiple-checkbox-option-status"
            labelId="multiple-checkbox-label-status"
            multiple={false}
            label='Status'
            name="status"
            className='IncluirPerfilSelectStatus'
            dimension='sm'
            error={formik.touched.ativo && Boolean(formik.errors.ativo)}
            helperText={formik.touched.ativo && formik.errors.ativo}
            disabled={disableActions}
          />
          <Input
            label='Código Grupo de Serviços'
            id='codGrupoServico'
            value={formik.values.codGrupoServico}
            dimension='sm'
            name="codGrupoServico"
            error={formik.touched.codGrupoServico && Boolean(formik.errors.codGrupoServico)}
            helperText={formik.touched.codGrupoServico && formik.errors.codGrupoServico}
            maxLength={10}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => formik.setFieldValue(e.target.name, textToUpperCaseNoSpaceNoSpecialChar(e.target.value))}
            readOnly
            disabled={disableActions}
          />
          <Input
            label='Descrição'
            id='descricaoGrupoServico'
            dimension='sm'
            name='descricao'
            error={formik.touched.descricao && Boolean(formik.errors.descricao)}
            helperText={formik.touched.descricao && formik.errors.descricao}
            value={formik.values.descricao}
            maxLength={255}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => formik.setFieldValue(e.target.name, textToUpperCaseNoSpecialChar(e.target.value))}
            disabled={disableActions}
          />
        </div>

        <div className="IncluirGrupoServico">
          <Input
            id='createdBy'
            label='Criado Por'
            dimension='sm'
            readOnly
            value={formik.values.createdBy}
          />
          <Input
            id='modifiedBy'
            label='Editado Por'
            dimension='sm'
            readOnly
            value={formik.values.modifiedBy}
          />
          <Input
            id='createdAt'
            label='Criado em'
            readOnly
            dimension='sm'
            value={formik.values.createdAt}
          />
          <Input
            id='modifiedAt'
            label='Alterado em'
            readOnly
            dimension='sm'
            value={formik.values.modifiedAt}
          />
        </div>

        {!!loading && <CircularProgress style={{ margin: '0px auto' }} />}

        <AlertMessage
          isOpenAlert={openAlert}
          setOpenAlert={setOpenAlert}
          alertType={tipomsgAlert}
          msgAlert={msgAlert}
        />

        <div className='ButtonSalvarContainer'>
          {!!isIncluding &&
            <>
              {!!profileHasPermission("Novo Registro") ?
                <Button
                  type="submit"
                  variant='primary'
                  dimension='sm'
                  width='146px'
                >
                  Incluir
                </Button>
                :
                <Button
                  type="button"
                  variant='primary'
                  dimension='sm'
                  width='146px'
                  style={{ cursor: 'not-allowed', opacity: 0.5 }}
                  disabled={true}>
                  Incluir
                </Button>
              }
            </>
          }

          {!isIncluding &&
            <>
              {!!profileHasPermission("Editar Registro") ?
                <Button
                  type="submit"
                  variant='primary'
                  dimension='sm'
                  width='146px'
                >
                  Salvar
                </Button>
                :
                <Button
                  type="button"
                  variant='primary'
                  dimension='sm'
                  width='146px'
                  style={{ cursor: 'not-allowed', opacity: 0.5 }}
                  disabled={true}>
                  Salvar
                </Button>
              }
            </>
          }
        </div>
      </form>
    </section>
  )
};

export default IncluirEditarGrupoServico;
