
const themes = {
    green: {
        color: {
            primary: '#294033',
            primaryLight: '#535e58',
            primaryDark: '#1e2e25',
            secondary: '#ffffff',
            userImg: '#4FD1A3',
            osEmergencial: "#FF9900",
            osVencida: "#FF2323",
            osVenceHoje: "#FFE600",
            osAVencer: "#97A800",
            cinzaClaro: "#afb1b1",
            textCinza: "#797D8C"
        },
        font: {},
    },
    blue: {
        color: {
            primary: '#1e5bb6',
            primaryLight: '#4173C1',
            primaryDark: '#1A4C9B',
            secondary: '#ffffff',
            userImg: '#1e5bb6',
            osEmergencial: "#FF9900",
            osVencida: "#FF2323",
            osVenceHoje: "#FFE600",
            osAVencer: "#97A800",
            cinzaClaro: "#afb1b1",
        },
        font: {},
    },
};

const tema = 'green';

export const siteTheme = themes[tema];
