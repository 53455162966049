import React, { useState, useContext, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Alert, Button, IconButton, Stack, Typography } from "@mui/material";
import Collapse from '../../../components/collapse/collapse';
import "./farol.scss";
import "./farolListaCard.scss";
import { HomeContext } from "../../../contexts/homeContext";
import Header from "../../../components/header/header";
import Pagination from "../../../components/pagination/paginacao";
import FarolFiltroTotalOs from "../../../components/visualizacaoOs/farolFiltroTotalOs";
import ModalEncaminharOS from "./farolModalEncaminharOS";
import FarolOSPopoverFiltros from "./farolPopoverFiltros";
import FarolOSPopoverOrdenar from "./farolPopoverOrdenar";
import { FowardButton, FowardIcon } from "./farolModalEncaminharOSStyles";
import { ReactComponent as ExportIcon } from "../../../assets/icons/ExportIcon.svg";
import { ReactComponent as FiltroIcon } from "../../../assets/icons/FiltroIcon.svg";
import { ReactComponent as SortIcon } from "../../../assets/icons/SortIcon.svg";
import { ReactComponent as ModalCloseIcon } from "../../../assets/icons/CloseIcon.svg";
import { ReactComponent as OpenOSIcon } from "../../../assets/icons/OpenOSIcon.svg";
import { ReactComponent as SortAscIcon } from "../../../assets/icons/SortAscIcon.svg";
import { ReactComponent as SortDescIcon } from "../../../assets/icons/SortDescIcon.svg";
import { Breadcrumb, BreadcrumbItem } from "../../../components/breadcrumb/breadcrumb";
import CloseIcon from "@mui/icons-material/Close";
import CircularProgress from "@mui/material/CircularProgress";
import DoubleSortIcon from "../../../components/doubleSortIcon/doubleSortIcon";
import { exportFarolList } from "../services";
import { AxiosResponse } from "axios";
import _ from "lodash";
import { FarolMapaOS } from "./farolMapaOS";
import FullscreenLoading from "../../../components/fullscreenLoading/fullscreenLoading";
import FarolFiltrosGrid from "./farolFiltrosGrid";
import ErroEncaminharModal from "./farolErroEncaminharModal";
import ModalAvisoOSCourier from "./farolModalAvisoOSCourier";
import { IOrdemServico } from "../../../contexts/types/farolTypes";
import FarolFiltrosAplicados from "./farolFiltrosAplicados";
import FarolTable from "./farolTable";
import { ExportButton } from "./farol.styles";
import { FarolPagination } from "./farolPagination";
import { removeSpecialCharSimple } from "../../../helpers/removeSpecialCharSimple";
import { IPostGetListaOSFarol } from "../../home/services/services";
import { Contratante } from "../../../helpers/contratantes";

const FarolOS = () => {
  const {
    listaOSFiltradaFarol,
    getOSsByID,
    requestGetListaOSs,
    loadingFarol,
    setMenuLateral,
    totalCount,
    totalEmergencial,
    totalVencidas,
    totalVenceHoje,
    totalAVencer,
    osselected,
    handleSetOSSelected,
    ordenacaoReverse,
    setOrdenacaoReverse,
    temFiltroPopoverSelecionado,
    iDsOSsParaResumoEquipamentos,
    filtroStatus,
    filtroContratante,
    filtroPrestadores,
    filtroServicoById,
    filtroGrupoServico,
    ordenacao,
    setOrdenacao,
    filtroFarol,
    filtroBuscaRapida,
    tecnico,
    localGetContratantes,
    resetFiltrosToBuscaRapida,
    setResetFiltrosToBuscaRapida,
    ordenacaoFarol,
    getListaStatusFarolDone,
    filtroEstados,
    filtroCidades,
    filtroCepInicial,
    filtroCepFinal,
  } = useContext(HomeContext);

  const isInitialMount = useRef(true);

  const navigate = useNavigate();

  const [isMapOpen, setIsMapOpen] = useState(false);

  const [isModalEncaminharOSOpen, setIsModalEncaminharOSOpen] = useState(false);
  const [modalErroEncaminhar, setModalErroEncaminhar] = useState(false);
  const [modalAvisoOSCourierOpen, setModalAvisoOSCourierOpen] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(100);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const [openSort, setOpenSort] = useState(false);
  const [anchorElSort, setAnchorElSort] = useState(null);

  const [allSelected, setAllSelected] = useState(false)

  const [loadingExport, setLoadingExport] = useState(false);

  const [tipomsgAlert, setTipoMsgAlert] = useState<'success' | 'info' | 'warning' | 'error'>("error");
  const [msgAlert, setMsgAlert] = useState("OS encaminhada com  sucesso!");
  const [openAlert, setOpenAlert] = useState(false);

  const [erroEncaminharModalText, setErroEncaminharModalText] = useState('')

  const [isFiltroGrid, setIsFiltroGrid] = useState(false);
  const [isFieldBuscaRapida, setIsFieldBuscaRapida] = useState(false);

  const resetFiltros = (value: boolean) => setIsFiltroGrid(value);

  const handleExport = async () => {
    setLoadingExport(true)
    try {
      const { data } = await exportFarolList({
        chamado: osselected,
        contratante: filtroContratante,
        prestadores: filtroPrestadores,
        status: filtroStatus,
        grupoDeServicos: filtroGrupoServico,
        servico: filtroServicoById?.map(item => { return item.id }),
        tipoSLA: Number(filtroFarol),
        tecnico: tecnico?.map((item: any) => Number(item.id)) || [],
        estados: filtroEstados?.map((item: any) => Number(item.id)) || [],
        cidades: filtroCidades?.map((item: any) => Number(item.id)) || [],
      }) as AxiosResponse

      const url = window.URL.createObjectURL(new Blob([data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'Consulta_Os.csv')
      document.body.appendChild(link)
      link.click()
    } catch (e) {
      setOpenAlert(true)
      setTipoMsgAlert('error')
      setMsgAlert('Erro ao exportar OS')
    }
    setLoadingExport(false)
  };

  const handleOpenFiltros = (currentTarget: any) => {
    setAnchorEl(currentTarget);
    setOpen(!open);
  };

  const handleCloseFiltros = () => {
    setOpen(false);
  };

  const handleOpenOrdenar = (currentTarget: any) => {
    setAnchorElSort(currentTarget);
    setOpenSort(!open);
  };

  const handleCloseOrdenar = () => {
    setOpenSort(false);
  };

  const handleSelectAll = () => {
    if (listaOSFiltradaFarol?.length > osselected?.length) {
      setAllSelected(true)
      handleSetOSSelected(listaOSFiltradaFarol.map((item) => item.chamado))
    } else {
      setAllSelected(false)
      handleSetOSSelected([])
    }
  }

  const handleChangePage = (page: number) => {
    /**Ao trocar de página OSs antigas selecionadas não estão mais disponíveis*/
    setAllSelected(false);
    handleSetOSSelected([]);

    setCurrentPage(page);
  }

  const handleClickOrdenar = (sort: string) => {
    if (sort === ordenacao[0]) {

      if (!ordenacaoReverse) {
        setOrdenacaoReverse(true);
      } else {
        setOrdenacao(["Prioridade"]);
        setOrdenacaoReverse(false);
      }
    } else {
      setOrdenacao([sort]);
      setOrdenacaoReverse(false);
    }
  };

  const activeArrowUP = (sort: string) => {
    if (sort === ordenacao[0]) {
      return ordenacaoReverse;
    } else {
      return false;
    }
  };

  const activeArrowDown = (sort: string) => {
    if (sort === ordenacao[0]) {
      return !ordenacaoReverse;
    } else {
      return false;
    }
  };

  const handleTotalCount = () => {
    switch (filtroFarol) {
      case '1':
        return totalEmergencial
      case '2':
        return totalVencidas
      case '3':
        return totalVenceHoje
      case '4':
        return totalAVencer
      default:
        return totalCount
    }
  };

  const handleEncaminarOSsMassivo = () => {
    const osSelecionadas = listaOSFiltradaFarol.filter(item => osselected?.includes(item.chamado));

    if (!!osSelecionadas.find(item => !item.permiteEntregaLote)) {
      setModalAvisoOSCourierOpen(true);
    }
    else
      if (osSelecionadas.map(item => item.idPrestador)?.every((val, i, arr) => val === arr[0])) {
        navigate("/EncaminharOS");
      }
    const osPrestadorPagBank = osSelecionadas.filter(fl=> fl.contratante == Contratante.PAGBANK && !fl.numeroSerie)
    if (osPrestadorPagBank 
      && osPrestadorPagBank.length > 0){

        setErroEncaminharModalText(`OS PagBank não pode ser encaminhada de forma massiva`)
        setModalErroEncaminhar(true);
      } 
      else {
        setErroEncaminharModalText('Não é permitido encaminhar OS’s com mais de um prestador selecionado.')
        setModalErroEncaminhar(true);
      }
  }

  const validateStatus = () => {
    const oss: IOrdemServico[] = getOSsByID(osselected);
    let osEncaminhada: boolean = true;
    const listaStatusPermiteEnviarACampo: string[] = [
      "encaminhada",
      "reencaminhado",
    ];

    oss.map(item => {
      if (!listaStatusPermiteEnviarACampo.includes(removeSpecialCharSimple(item.status))) {
        osEncaminhada = false;
        return;
      };
    });
    if (osEncaminhada) {
      if (oss.length > 1) {
        handleEncaminarOSsMassivo();
      } else {
        setIsModalEncaminharOSOpen(true);
      };
    } else {
      setErroEncaminharModalText('Apenas OSs com status "encaminhada" e "reencaminhada" poderão ser enviadas a campo.');
      setModalErroEncaminhar(true);
    };
  };

  const handleSelectClient = (cliente: IOrdemServico[], listaCliente: number[]) => {
    if (!listaCliente?.every(v => osselected.includes(v))) {
      handleSetOSSelected(cliente.map(os => os.chamado))

    } else {
      handleSetOSSelected(osselected.filter(item => !listaCliente.includes(item)))
    }
  };

  const formatCEP = (cepInicial: string, cepFinal: string): [string, string] => {
    const cleanCepInicial = cepInicial?.replace(/\D/g, '') || '';
    const cleanCepFinal = cepFinal?.replace(/\D/g, '') || '';
  
    if (!cleanCepInicial && !cleanCepFinal) {
      return ['', ''];
    }
  
    if (cleanCepInicial === '000' && !cleanCepFinal) {
      return ['00000000', '00099999'];
    }
  
    if (cleanCepInicial === '000' && cleanCepFinal === '999') {
      return ['00000000', '99999999'];
    }
  
    if (cleanCepInicial && !cleanCepFinal) {
      return [cleanCepInicial.padEnd(8, '0'), cleanCepInicial.padEnd(8, '9')];
    }
  
    return [
      cleanCepInicial.padEnd(8, '0'),
      cleanCepFinal ? cleanCepFinal.padEnd(8, '9') : '',
    ];
  };

  const formatParamsToRequestFarol = () => {
    const [formattedCepInicial, formattedCepFinal] = formatCEP(filtroCepInicial, filtroCepFinal);

    const params: IPostGetListaOSFarol = {
      ...(!!filtroBuscaRapida ? { chamadoReferencia: filtroBuscaRapida } : {}),
      ...(formattedCepInicial ? { cepInicial: formattedCepInicial } : {}),
      ...(formattedCepFinal ? { cepFinal: formattedCepFinal } : {}),
      prestadores: !filtroPrestadores?.length ? [] : filtroPrestadores.map(Number),
      contratante: !filtroContratante?.length ? [] : filtroContratante.filter(c => c !== "all").map(Number),
      status: !filtroStatus?.length ? [] : filtroStatus.map(Number),
      grupoDeServicos: !filtroGrupoServico?.length ? [] : filtroGrupoServico.map(Number),
      servico: !filtroServicoById?.length ? [] : filtroServicoById.map((item: any) => Number(item.id)),
      tecnico: !tecnico?.length ? [] : tecnico.map((item: any) => Number(item.id)),
      estados: !filtroEstados?.length ? [] : filtroEstados.map((item: any) => Number(item.id)),
      cidades: !filtroCidades?.length ? [] : filtroCidades.map((item: any) => Number(item.id)),
      tipoSLA: Number(filtroFarol),
      pageNumber: currentPage,
      pageSize: pageSize,
    };

    return params;
  };

  const handleCloseModalEncaminharOS = async (requestEnviarACampoDone: boolean) => {
    handleSetOSSelected([]);
    setIsModalEncaminharOSOpen(false);
    if (requestEnviarACampoDone) {
      await requestGetListaOSs(formatParamsToRequestFarol());
    }
  };
  
  const handleChangeFiltroTipoDeOS = () => {
    setAllSelected(false);
    handleSetOSSelected([]);

    if (currentPage !== 1) {
      setCurrentPage(1);
    } else {
      requestGetListaOSs(formatParamsToRequestFarol());
    }
  };

  useEffect(() => {
    setAllSelected(listaOSFiltradaFarol?.length === osselected?.length && osselected?.length > 0);
  }, [osselected]);

  useEffect(() => {
    if (isInitialMount.current) {
      setIsModalEncaminharOSOpen(false);
      setAllSelected(false);
      handleSetOSSelected([]);
      isInitialMount.current = false;
    } else {
      handleChangeFiltroTipoDeOS();
    }
  }, [filtroFarol]);
  
  useEffect(() => {
    if (!isInitialMount.current) {
      if (!!resetFiltrosToBuscaRapida) return setResetFiltrosToBuscaRapida(false);

      if (getListaStatusFarolDone) requestGetListaOSs(formatParamsToRequestFarol());
    }
  }, [
    filtroBuscaRapida,
    filtroContratante,
    filtroPrestadores,
    filtroEstados,
    filtroCidades,
    filtroCepInicial,
    filtroCepFinal,
    tecnico,
    filtroStatus,
    filtroGrupoServico,
    filtroServicoById,
    pageSize,
    currentPage,
  ]);

  return (
    <section className="FarolOS">
      <Header setMenuLateral={() => setMenuLateral(true)} title="Farol de OS's" />
      <Breadcrumb>
        <BreadcrumbItem>Visualização de OS's</BreadcrumbItem>
      </Breadcrumb>
      {loadingFarol && <FullscreenLoading />}
      <FarolFiltroTotalOs />

      <div className={`FarolOS-BlocoBotoes`}>
        {/* <div className="FarolOS-BlocoBotoes-Link">
          <div className="FarolOS-BlocoBotoes-Left">
            <div
              id={"FarolOS-BtnLista"}
              className={
                "FarolOS-BlocoBotoes-Btn BtnLista " +
                (!isMapOpen ? "visaoSelecionada" : "")
              }
              onClick={() => setIsMapOpen(false)}
            >
              Lista de OS's
            </div>
            <div
              id={"FarolOS-BtnMapa"}
              className={
                "FarolOS-BlocoBotoes-Btn BtnMapa " +
                (isMapOpen ? "visaoSelecionada" : "")
              }
              onClick={() => setIsMapOpen(true)}
            >
              Mapa de OS's
            </div>
          </div>
          <div className="FarolOs-Link" onClick={() => navigate('/OsPendente')}>
            Lista OS Pendente
          </div>
        </div> */}

        <FarolFiltrosGrid resetFiltrosPopover={(e) => resetFiltros(e)} setIsBuscaRapida={() => setIsFieldBuscaRapida(true)} />

        <div className="FarolOS-BlocoBotoes-Right">
          <div className={"FarolOS-BlocoBotoes-RightBtn BtnOrdenar"}>
            <div
              id={"FarolOS-BtnOrdenar"}
              className={`FarolOS-BlocoBotoes-RightBtnBtnOrdenarLeft ${loadingExport ? ' disabled' : ''}`}
              onClick={(event) => !loadingExport && handleOpenOrdenar(event.currentTarget)}
            >
              <SortIcon className={"IconeBtnFarol SortIcon"} />
              ordenar
            </div>

            <div
              id={"FarolOS-BtnOrdenarAscDesc"}
              className={"FarolOS-BlocoBotoes-RightBtnBtnOrdenarRight"}
              onClick={() => !loadingExport && setOrdenacaoReverse(!ordenacaoReverse)}
            >
              {!ordenacaoReverse && (
                <SortAscIcon className={"SortAscIcon"} />
              )}

              {!!ordenacaoReverse && (
                <SortDescIcon className={"SortDescIcon"} />
              )}
            </div>
          </div>

          <FarolOSPopoverOrdenar
            id={"FarolOS-PopoverOrdenar"}
            open={openSort}
            onClose={handleCloseOrdenar}
            anchorEl={anchorElSort}
          />

          <div
            id={"FarolOS-BtnFiltro"}
            className={
              `FarolOS-BlocoBotoes-RightBtn BtnFiltro
              ${temFiltroPopoverSelecionado ? " BtnSelecionado" : ""}`
            }
            onClick={(event) => !loadingExport && handleOpenFiltros(event.currentTarget)}
          >
            <FiltroIcon className={"IconeBtnFarol FiltroIcon"} />
            Filtro
          </div>

          <FarolOSPopoverFiltros
            id={"FarolOS-PopoverFiltro"}
            open={open}
            onClose={handleCloseFiltros}
            anchorEl={anchorEl}
            setCurrentPage={setCurrentPage}
            isFilterFromGrid={isFiltroGrid}
            IsFieldBuscaRapida={isFieldBuscaRapida}
            resetFiltrosPopover={(e) => resetFiltros(e)}
          />

        </div>
      </div>

      <Collapse onClose={setOpenAlert} in={openAlert}>
        <Alert
          severity={tipomsgAlert}
          icon={<div />}
          variant="filled"
          sx={{
            mb: 2,
            bgcolor: tipomsgAlert === "success" ? "#178B319E" : "rgb(255, 35, 35, 0.7)",
            height: "67px",
            borderRadius: "15px",
            boxSizing: "border-box",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: "20px",
            marginBottom: 0,
          }}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="medium"
              onClick={() => setOpenAlert(false)}
            >
              <CloseIcon
                id={"ModalEncaminharOS-AlertCloseIcon"}
                fontSize="inherit"
                sx={{
                  color: "white",
                }}
              />
            </IconButton>
          }
        >
          <span
            style={{
              color: "white",
              fontSize: "16px",
            }}
            dangerouslySetInnerHTML={{ __html: msgAlert }}
          />
        </Alert>
      </Collapse>

      {isMapOpen ? (
        <FarolMapaOS />) : (
        <>


          <FarolFiltrosAplicados />

          <FarolTable
            allSelected={allSelected}
            handleSelectAll={handleSelectAll}
            //@ts-ignore
            handleSetOSSelected={handleSetOSSelected}
            handleClickOrdenar={handleClickOrdenar}
            activeArrowUP={activeArrowUP}
            activeArrowDown={activeArrowDown}
            ordenacao={ordenacao[0] ?? ''}
            osselected={osselected}
            listaOSFiltradaFarol={listaOSFiltradaFarol}
            loadingFarol={loadingFarol}
          />
        </>
      )}

      <ErroEncaminharModal text={erroEncaminharModalText} isModalOpen={modalErroEncaminhar} handleCloseModal={() => setModalErroEncaminhar(false)} />

      <ModalAvisoOSCourier isModalOpen={modalAvisoOSCourierOpen} handleClose={() => setModalAvisoOSCourierOpen(false)} />

      {!!osselected?.length && (
        <div
          className={
            "FarolOS-ListaOS-OSsSelecionadas "
            + (!!iDsOSsParaResumoEquipamentos.length ? "resumoEquipamentos" : "")
          }
        >
          <div className="FarolOS-ListaOS-OSsSelecionadas-left">
            <ModalCloseIcon
              id={"FarolOS-OSsSelecionadas-CloseIcon"}
              className={"CloseIcon"}
              onClick={() => handleSetOSSelected([])}
            />
            {osselected?.length} OS selecionadas
          </div>

          {osselected?.length === 1 && (
            <>
              <ModalEncaminharOS
                handleClose={handleCloseModalEncaminharOS}
                isModalOpen={isModalEncaminharOSOpen}
              />

              <FowardButton
                id={"FarolOS-OSsSelecionadas-BtnFoward"}
                onClick={validateStatus}
              >
                <FowardIcon />
                Enviar a Campo
              </FowardButton>
            </>
          )}

          {osselected?.length > 1 && (
            <div
              id={"FarolOS-OSsSelecionadas-BtnOpenOSMultiple"}
              className="FarolOS-ListaOS-OSsSelecionadas-right"
              onClick={validateStatus}
            >
              <OpenOSIcon className={"OpenOSIcon"} />
              Enviar OS´s para Campo
            </div>
          )}

          {!!iDsOSsParaResumoEquipamentos.length && (
            <div
              id={"FarolOS-OSsSelecionadas-BtnResumoEquipamentos"}
              className="FarolOS-ListaOS-OSsSelecionadas-right"
              onClick={() => {
                navigate("/ResumoEquipamentos");
              }}
            >
              Resumo de Equipamentos
            </div>
          )}
        </div>
      )}

      {/* {
        totalCount > 0 && (
          totalEmergencial > 0 ||
          totalVencidas > 0 ||
          totalVenceHoje > 0 ||
          totalAVencer > 0
        ) &&
        listaOSFiltradaFarol.length > 0 &&
        <Stack direction="row">
          <Pagination
            className="pagination-bar"
            currentPage={currentPage}
            totalCount={handleTotalCount()}
            pageSize={pageSize}
            setPageSize={setPageSize}
            onPageChange={(page: number) => handleChangePage(page)}
          />

          <Button
            variant="outlined"
            color="info"
            startIcon={<ExportIcon />}
            sx={{
              borderRadius: "15px",
              lineHeight: "1",
              ".MuiButton-startIcon": { marginRight: "16px !important" },
            }}
            onClick={handleExport}
            disabled={loadingExport}
          >
            {loadingExport ? <CircularProgress color="inherit" size={20} /> : 'Exportar'}
          </Button>
        </Stack>
      } */}

      <Stack direction="row" alignItems="flex-end" justifyContent="space-between">
        <FarolPagination
          className="pagination-bar"
          currentPage={currentPage}
          totalCount={handleTotalCount()}
          pageSize={pageSize}
          setPageSize={setPageSize}
          onPageChange={(page: number) => handleChangePage(page)}
          handleExport={handleExport}
          loadingExport={loadingExport}
          setLoadingExport={setLoadingExport}
        />


      </Stack>
    </section>
  );
};

export default FarolOS;
