import styled from "styled-components";
import { Button } from "@mui/material";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 26px 40px 38px 40px;
  box-sizing: border-box;
  row-gap: 30px;
  align-items: center;
`;

export const InfoLabel = styled.div`
  font-weight: 600;
  text-align: center;
`;

export const FlexButtons = styled.div`
  display: flex;
  gap: 10px;
`;
