import { useState } from "react"
import { IGetResponseRegiao } from "../pages/regioes/types"


export const RegiaoContext = () => {
    const [regiaoSelecionada, setRegiaoSelecionada] = useState({} as IGetResponseRegiao);

    return {
        regiaoSelecionada,
        setRegiaoSelecionada
    }
};