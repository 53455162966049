import { useContext, useEffect, useRef, useState } from "react";
import { AuthContext } from "../../../contexts/auth";
import { HomeContext } from "../../../contexts/homeContext";
import { useNavigate } from "react-router-dom";
import Header from "../../../components/header/header";
import { Breadcrumb, BreadcrumbItem } from "../../../components/breadcrumb/breadcrumb";
import { CircularProgress, useEventCallback } from "@mui/material";
import AlertMessage from "../../../components/AlertMessage/alertMessage";
import { useFormik } from "formik";
import moment from "moment";
import * as yup from "yup";
import { removeSpecialCharSimple } from "../../../helpers/removeSpecialCharSimple";
import { AxiosResponse } from "axios";
import { postEstado, putEstado } from "../services/services";
import { IGetResponseEstado } from "../types/types";
import AutocompleteMultiple from "../../../components/autocompleteMultiple/autocompleteMultiple";
import { IAutocompleteValue } from "../../../components/autocompleteMultiple/types";
import Input from "../../../components/Input/Input";
import Button from "../../../components/Button/styles";
import "./incluirEditarEstado.scss";

interface IInitialValues {
    idEstado: string,
    ativo: IAutocompleteValue,
    uf: string,
    nome: string,
    pais: IAutocompleteValue,
    usuarioCriacao: string | number,
    dataCriacao: string,
    usuarioModificacao: string | number,
    dataModificacao: string,
};

const IncluirEditarEstado = () => {
    const isIncluding = window.location.pathname.includes('IncluirEstado');
    const isInitialMount = useRef(true);

    const { funcionalidadeDaTelaTemPermissao, user } = useContext(AuthContext);

    const profileHasPermission = (funcionalidade: string) => funcionalidadeDaTelaTemPermissao(
        isIncluding ? "Incluir Estado" : "Editar Estado",
        funcionalidade);

    const [listaPaises, setListaPaises] = useState<IAutocompleteValue>([
        { name: 'Brasil', id: '' }
    ]);

    const [listaEstados, setListaEstados] = useState<IAutocompleteValue>([]);

    const [listaStatus, setListaStatus] = useState<IAutocompleteValue>([
        { name: 'Ativo', id: '0' },
        { name: 'Inativo', id: '1' },
    ]);

    const { setMenuLateral, estadoSelecionado, setEstadoSelecionado } = useContext(HomeContext);

    const [tipomsgAlert, setTipoMsgAlert] = useState<'success' | 'info' | 'warning' | 'error'>("success");
    const [msgAlert, setMsgAlert] = useState("OS encaminhada com  sucesso!");
    const [openAlert, setOpenAlert] = useState(false);
    const [disableActions, setDisableActions] = useState(false);
    const [loading, setLoading] = useState<boolean>(false);

    const navigate = useNavigate();

    const validaNomeEstado = (newName: string | undefined, ctx: any) => {
        const cleanNewName = removeSpecialCharSimple(newName ?? "");

        /**No modo edição pode manter o nome inicial */
        if (!isIncluding) {
            const { parent }: { parent: IInitialValues } = ctx;
            const initialName = removeSpecialCharSimple(estadoSelecionado?.nome ?? '');
            const sameEstate = parent?.idEstado === String(estadoSelecionado?.id);

            if (sameEstate) return true;
        }

        return !listaEstados.find((s) => removeSpecialCharSimple(s?.name) === cleanNewName);
    }

    const validationSchema = yup.object({
        ativo: yup.array().max(1).min(1, 'Campo obrigatório'),
        uf: yup.string().required('Campo obrigatório'),
        nome: yup.string().required("Campo obrigatório").test(
            'nomeDeCidadeJaExistente',
            'Este nome de estado já foi cadastrado anteriormente.',
            validaNomeEstado
        ),
    });

    
    const initialValuesIncluir: IInitialValues = {
        ativo: [listaStatus[1]],
        idEstado: '',
        uf: '',
        pais: [listaPaises[0]],
        nome: '',
        usuarioCriacao: '',
        dataCriacao: '',
        usuarioModificacao: '',
        dataModificacao: '',
    };

    const initialValuesEditar: IInitialValues = {
        ativo: [(estadoSelecionado?.ativo ? listaStatus[0] : listaStatus[1])],
        idEstado: estadoSelecionado.id?.toString() ?? '',
        uf: estadoSelecionado.uf ?? '',
        pais: [listaPaises[0]],
        nome: estadoSelecionado?.nome || '',
        usuarioCriacao: estadoSelecionado?.idUsuarioCriacao,
        dataCriacao: estadoSelecionado?.dataCriacao ? moment(estadoSelecionado?.dataCriacao, "YYYY/MM/DDTHH:mm:ss").format("DD/MM/YYYY HH:mm:ss") : '',
        usuarioModificacao: estadoSelecionado?.idUsuarioModificacao,
        dataModificacao: estadoSelecionado?.dataModificacao ? moment(estadoSelecionado?.dataModificacao, "YYYY/MM/DDTHH:mm:ss").format("DD/MM/YYYY HH:mm:ss") : '',
    };

    const formik = useFormik({
        initialValues: isIncluding ? initialValuesIncluir : initialValuesEditar,
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: async (values, actions) => {
            if (isIncluding) {
                if (!!profileHasPermission("Novo Registro")) return handleIncludeEstado(values);
            } else {
                if (!!profileHasPermission("Editar Registro")) return handleEditEstado(values);
            }
        }
    });

    const handleIncludeEstado = async (values: IInitialValues) => {
        setDisableActions(true);
        setOpenAlert(false);
        setLoading(true);

        try {
            const statusBool: boolean = (!!values.ativo[0] && values.ativo[0].id === '0') ? true : false;

            const { data } = await postEstado({
                nome: values.nome,
                ativo: statusBool,
                uf: values.uf,
            }) as AxiosResponse;

            setLoading(false);
            setMsgAlert(!!data && typeof data === "string" ? data : 'Estado cadastrada com sucesso');

            setTipoMsgAlert("success");
            setOpenAlert(true);
            setDisableActions(false);

            formik.resetForm();
        } catch (e: any) {
            setLoading(false);

            setMsgAlert(e?.response?.data?.errors ? Object.values(e.response.data.errors).join("<br>") : "Erro ao cadastrar estadio");

            setTipoMsgAlert("error");
            setOpenAlert(true);
            setDisableActions(false);
        }
    }

    const handleEditEstado = async (values: IInitialValues) =>{
        setDisableActions(true);
        setOpenAlert(false);
        setLoading(true);

        try {
            const statusBool: boolean = (!!values.ativo[0] && values.ativo[0].id === '0') ? true : false;

            const { data } = await putEstado({
                nome: values.nome,
                ativo: statusBool,
                uf: values.uf,
                
            }, estadoSelecionado.id) as AxiosResponse;

            const updatedEstado: IGetResponseEstado = {
                ...estadoSelecionado,
                ...data, /**<--- O endpoint está retornando os dados atualizados da cidade */
                idUsuarioModificacao: user?.idUsuario,
                dataModificacao: moment().format("YYYY-MM-DD HH:mm:ss"),
            };

            setEstadoSelecionado(updatedEstado);

            setLoading(false);
            
            setMsgAlert(!!data && typeof data === "string" ? data : "Estado alterado com sucesso!");
            
            setTipoMsgAlert("success");
            setOpenAlert(true);
            setDisableActions(false);
        } catch (e: any) {
            setLoading(false);
            setMsgAlert(e?.response?.data?.errors ? Object.values(e.response.data.errors).join("<br>") : "Erro ao editar estado");
            setTipoMsgAlert("error");
            setOpenAlert(true);
            setDisableActions(false);
        }
    }

    useEffect(() => {
        if (isInitialMount.current) {
          if (!isIncluding && !estadoSelecionado?.id) navigate("/IncluirEstado");
    
          isInitialMount.current = false;
        } else {
          if (isIncluding) formik.setValues(initialValuesIncluir);
        }
      }, [
        isIncluding
      ]);

    return (
        <section className="IncluirEditarEstado">
            <Header setMenuLateral={() => setMenuLateral(true)} title={isIncluding ? 'Inclusão de estado' : 'Edição de estado'} />
            <Breadcrumb>
                <BreadcrumbItem onClick={() => navigate("/ConsultarEstados")}>Consultar Estados</BreadcrumbItem>
                <BreadcrumbItem>{isIncluding ? 'Inclusão de estado' : 'Edição de estado'}</BreadcrumbItem>
            </Breadcrumb>

            <form className="Container" onSubmit={formik.handleSubmit}>
                <div className="IncluirEstado">
                    <div className="firstRow grid">
                        <AutocompleteMultiple
                            disableClearable
                            dimension="sm"
                            label={"País"}
                            placeholder={""}
                            noOptionsText="Nenhum país encontrado"
                            options={listaPaises}
                            value={formik.values.pais}
                            onChange={(e: any) => formik.setFieldValue('pais', e)}
                            id="multiple-checkbox-pais"
                            multiple={false}
                            error={formik.touched.pais && Boolean(formik.errors.pais)}
                            helperText={formik.touched.pais && formik.errors.pais}
                            disabled={disableActions}
                        />
                        <AutocompleteMultiple
                            disableClearable
                            dimension="sm"
                            label={"Status"}
                            placeholder={"Selecionar status"}
                            noOptionsText="Nenhum status encontrado"
                            options={listaStatus}
                            value={formik.values.ativo}
                            onChange={(e: any) => formik.setFieldValue('ativo', e)}
                            id="multiple-checkbox-Status"
                            multiple={false}
                            error={formik.touched.ativo && Boolean(formik.errors.ativo)}
                            helperText={formik.touched.ativo && formik.errors.ativo}
                            disabled={disableActions}
                        />

                        <Input
                            dimension="sm"
                            label='Nome da Estado'
                            name='nome'
                            id='nome'
                            value={formik.values.nome}
                            onChange={formik.handleChange}
                            error={formik.touched.nome && Boolean(formik.errors.nome)}
                            helperText={formik.touched.nome && formik.errors.nome}
                            maxLength={100}
                        />
                        <Input
                            dimension="sm"
                            label='UF'
                            name='uf'
                            id='uf'
                            value={formik.values.uf}
                            onChange={formik.handleChange}
                            error={formik.touched.uf && Boolean(formik.errors.uf)}
                            helperText={formik.touched.uf && formik.errors.uf}
                            maxLength={100}
                        />
                    </div>

                    <div className="secondRow grid">
                        <Input
                            dimension="sm"
                            label='Criado Por'
                            name='usuarioCriacao'
                            id='usuarioCriacao'
                            value={formik.values.usuarioCriacao}
                            onChange={formik.handleChange}
                            error={formik.touched.usuarioCriacao && Boolean(formik.errors.usuarioCriacao)}
                            helperText={formik.touched.usuarioCriacao && formik.errors.usuarioCriacao}
                            readOnly
                        />
                        <Input
                            dimension="sm"
                            label='Criado em'
                            id="dataCriacao"
                            value={formik.values.dataCriacao}
                            onChange={(e) => (formik.setFieldValue('dataCriacao', e))}
                            error={formik.touched.dataCriacao && Boolean(formik.errors.dataCriacao)}
                            helperText={(formik.touched.dataCriacao && formik.errors.dataCriacao) ? "Campo obrigatório" : ""}
                            readOnly
                        />
                        <Input
                            dimension="sm"
                            label='Editado por'
                            name='usuarioModificacao'
                            id='usuarioModificacao'
                            value={formik.values.usuarioModificacao}
                            onChange={formik.handleChange}
                            error={formik.touched.usuarioModificacao && Boolean(formik.errors.usuarioModificacao)}
                            helperText={formik.touched.usuarioModificacao && formik.errors.usuarioModificacao}
                            readOnly
                        />
                        <Input
                            dimension="sm"
                            label='Editado em'
                            id="dataModificacao"
                            value={formik.values.dataModificacao}
                            onChange={(e) => (formik.setFieldValue('dataModificacao', e))}
                            error={formik.touched.dataModificacao && Boolean(formik.errors.dataModificacao)}
                            helperText={(formik.touched.dataModificacao && formik.errors.dataModificacao) ? "Campo obrigatório" : ""}
                            readOnly
                        />
                    </div>
                    {!!isIncluding &&
                        <>
                            {!!profileHasPermission("Novo Registro") ?
                                <Button
                                    type="submit"
                                    variant='primary'
                                    dimension='sm'
                                    width='146px'
                                >
                                    Salvar
                                </Button>
                                :
                                <Button
                                    type="button"
                                    variant='primary'
                                    dimension='sm'
                                    width='146px'
                                    style={{ cursor: 'not-allowed', opacity: 0.5 }}
                                    disabled={true}>
                                    Salvar
                                </Button>
                            }
                        </>
                    }

                    {!isIncluding &&
                        <>
                            {!!profileHasPermission("Editar Registro") ?
                                <Button
                                    type="submit"
                                    variant='primary'
                                    dimension='sm'
                                    width='146px'
                                >
                                    Salvar
                                </Button>
                                :
                                <Button
                                    type="button"
                                    variant='primary'
                                    dimension='sm'
                                    width='146px'
                                    style={{ cursor: 'not-allowed', opacity: 0.5 }}
                                    disabled={true}>
                                    Salvar
                                </Button>
                            }
                        </>
                    }
                    
                </div>

                {!!loading && <CircularProgress style={{ margin: '0px auto' }} />}

                <AlertMessage
                    isOpenAlert={openAlert}
                    setOpenAlert={setOpenAlert}
                    alertType={tipomsgAlert}
                    msgAlert={msgAlert}
                />
            </form>
        </section>
    )
}

export default IncluirEditarEstado;