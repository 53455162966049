import { apiGet, apiPost, apiPut } from "../../../services/api";
import { IGetGroupServices, IPutGrupoServico, IPostGrupoServico } from "../types";

const baseUrl = process.env.REACT_APP_API_ORDEMSERVICO;

export const getGroup_Services = async (params?: IGetGroupServices) =>
  await apiGet(`${baseUrl}/GrupoServico_`, params);

export const getGrupoServicoPorId = (id: number | string) => 
  apiGet(`${baseUrl}/GrupoServico_/${id}`);

export const postGrupoServico = (params: IPostGrupoServico) =>
    apiPost(`${baseUrl}/GrupoServico_`, params);

export const putGrupoServico = (idGrupoServico: number | string, params: IPutGrupoServico) =>
  apiPut(`${baseUrl}/GrupoServico_/${idGrupoServico}`, params);
