import Typography from "@mui/material/Typography";

const PasswordCriteria = ({ password }: { password: string }) => {
  const isStrongPassword = (password: string) => {
    const hasMinLength = password.length >= 8;
    const hasUppercase = /[A-Z]/.test(password);
    const hasLowercase = /[a-z]/.test(password);
    const hasNumber = /\d/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

    return {
      hasMinLength,
      hasUppercase,
      hasLowercase,
      hasNumber,
      hasSpecialChar,
    };
  };

  const getPasswordColor = (condition: boolean) => {
    return condition ? "green" : "red";
  };

  const passwordConditions = isStrongPassword(password);

  return (
    <div>
      <Typography
        variant="body2"
        style={{
          fontWeight: "bold",
          marginTop: "10px",
        }}
      >
        Critérios da senha:
      </Typography>
      <ul style={{ marginLeft: "25px" }}>
        <li
          style={{
            listStyle: "initial",
            color: getPasswordColor(passwordConditions.hasMinLength),
          }}
        >
          Pelo menos 8 caracteres
        </li>
        <li
          style={{
            listStyle: "initial",
            color: getPasswordColor(passwordConditions.hasUppercase),
          }}
        >
          Letras maiúsculas
        </li>
        <li
          style={{
            listStyle: "initial",
            color: getPasswordColor(passwordConditions.hasLowercase),
          }}
        >
          Letras minúsculas
        </li>
        <li
          style={{
            listStyle: "initial",
            color: getPasswordColor(passwordConditions.hasNumber),
          }}
        >
          Números
        </li>
        <li
          style={{
            listStyle: "initial",
            color: getPasswordColor(passwordConditions.hasSpecialChar),
          }}
        >
          Caracteres especiais
        </li>
      </ul>
    </div>
  );
};

export default PasswordCriteria;
