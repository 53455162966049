import { useLocation, useNavigate } from "react-router-dom";
import IncluirAgrupamentoModelos from "./incluirAgrupamentoModelo";
import EditarAgrupamentoModelos from "./editarAgrupamentoModelo";


const IncluirEditarAgrupamento = () =>{
    const location = useLocation();
    const dadosTermoOs = location.state?.dadosAgrupamento || {};
    if (!!dadosTermoOs.id) return <EditarAgrupamentoModelos/>;
    return <IncluirAgrupamentoModelos/>;
}

export default IncluirEditarAgrupamento;