import React from 'react'
import './editarPerfilTab.scss'
import Button from '../../../components/Button/styles';
import { Table, TableRow } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import * as Styles from "../../../components/table/styles";
import _ from "lodash";
import { IEditarTelaTab } from '../types';
import SwitchMUI from '../../../components/switch/switch';
import AutocompleteMultiple from "../../../components/autocompleteMultiple/autocompleteMultiple";
import Autocompletetecnico from '../../../components/autocompleteTecnicos/autocompleteTecnicos';
import { Alert, CircularProgress, IconButton } from '@mui/material';
import Collapse from '../../../components/collapse/collapse';
import CloseIcon from "@mui/icons-material/Close";

const EditarTelaTab = ({
  modulosOptions,
  modulo,
  handleChangeModulo,
  telasOptions,
  tela,
  handleChangeTela,
  requestListaFuncionalidades,
  handleSwitchFuncionalidade,
  requestListaTelas,
  loadingDadosTabela,
  openAlertDadosTabelaError,
  setOpenAlertDadosTabelaError,
  msgAlertDadosTabelaError,
  dadosTabela,
}: IEditarTelaTab) => {

  return(
    <div className='ContainerEditarTela'>
      <div className='AdicionarTela'>
        <AutocompleteMultiple
          label={"Módulos"}
          placeholder={"Selecionar módulo"}
          noOptionsText={"Nenhum módulo encontrado"}
          id="multiple-checkbox-modulo"
          options={modulosOptions}
          value={modulo}
          onChange={handleChangeModulo}
          multiple={false}
          keepOptionInTheList={true}
          dimension='sm'
        />
        
        <Autocompletetecnico
          noOptionsText="Nenhuma tela encontrada"
          autocompleteValue={tela}
          onChangeValue={handleChangeTela}
          options={telasOptions}
          requestOptions={requestListaTelas}
          id="multiple-checkbox-tela"
          label="Telas"
          placeholder={"Selecionar tela"}
          fontSize={16}
          dimension='sm'
        />
        
        <Button
         variant='grey' 
         padding='12px 26px'
         margin='0' 
         onClick={() => requestListaFuncionalidades()}
        >
          Buscar
        </Button>
      </div>

      {!!loadingDadosTabela && <CircularProgress style={{ margin: '0px auto' }} />}

      {openAlertDadosTabelaError &&
        <Collapse onClose={setOpenAlertDadosTabelaError} in={openAlertDadosTabelaError}>
          <Alert
            severity='error'
            icon={<div />}
            variant="filled"
            sx={{
              mb: 2,
              bgcolor: "rgb(255, 35, 35, 0.7)",
              borderRadius: "15px",
              boxSizing: "border-box",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: '50px',
            }}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="medium"
                onClick={() => setOpenAlertDadosTabelaError(false)}
              >
                <CloseIcon
                  id={"ModalEncaminharOS-AlertCloseIcon"}
                  fontSize="inherit"
                  sx={{
                    color: "white",
                  }}
                />
              </IconButton>
            }
          >
            <span
              style={{
                color: "white",
                fontSize: "16px",
              }}
              dangerouslySetInnerHTML={{ __html: msgAlertDadosTabelaError }}
            />
          </Alert>
        </Collapse>
      }

      {!_.isEmpty(dadosTabela) &&
      <div className='TableContainerPage'>
        <TableContainer component={Styles.StyledPaper}>
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                <Styles.StyledTableCell className={`center`}>Funcionalidades</Styles.StyledTableCell>
                <Styles.StyledTableCell className={`center`}>Habilitar</Styles.StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dadosTabela.map(item => {
                return(
                  <TableRow key={item.id}>
                    <Styles.StyledTableCell component="th" scope="row">
                      {item.name}
                    </Styles.StyledTableCell>
                    <Styles.StyledTableCell
                    component="th" 
                    scope="row" 
                    style={{
                      textAlign: "center", 
                      width: '15%'
                    }}
                    >
                      <SwitchMUI checked={item.value}  setChecked={(value: boolean) => handleSwitchFuncionalidade(value, item.id)} type={"IOS"}/>
                    </Styles.StyledTableCell>
                  </TableRow>
              )})}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      }
    </div>
  )
}

export default EditarTelaTab