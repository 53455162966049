import { siteTheme } from "../styles/themeVariables";

const { color } = siteTheme;

export const createImageFromInitials = (size: number, name?: string) => {
  if (name == null) name = "Anon User";
  name=getInitials(name)

  const canvas = document.createElement('canvas')
  const context = canvas.getContext('2d')
  canvas.width = canvas.height=size

  if(!!context){
    context.fillStyle= color.userImg
    context.fillRect(0,0,size,size)
  
    context.fillStyle="#ffffff";
    context.textBaseline='middle'
    context.textAlign='center'
    context.font =`${size/2}px Roboto`
    context.fillText(name!,(size/2),(size/2))
  
    return canvas.toDataURL()
  }
};

export const getRandomColor = () => {
  var letters = '0123456789ABCDEF';
  var color = '#';
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

export const getInitials = (name: string) => {
  let initials;
  const nameSplit = name.split(" ");
  const nameLength = nameSplit.length;
  if (nameLength > 1) {
      initials =
          nameSplit[0].substring(0, 1) +
          nameSplit[nameLength - 1].substring(0, 1);
  } else if (nameLength === 1) {
      initials = nameSplit[0].substring(0, 1);
  } else return;

  return initials.toUpperCase();
};