import { useContext } from "react";
import "./menuUser.scss";
import { AuthContext } from "../../contexts/auth";
import NotificationSidebar, {
  Anchor,
} from "../NotificationSidebar/notificationSidebar";

const HomeMenuUser = () => {
  const { user } = useContext(AuthContext);
  const { foto, nome } = user!;

  return (
    <div className="Home-Menu-user">
      <NotificationSidebar
        activeNotifications={user?.data_user.notificacoesAtivas}
        anchor={Anchor.Right}
      />
      <div className="Home-Menu-user-box" id={"Menu-user-logged"}>
        <img
          src={foto}
          alt={"Imagem perfil"}
          className="Home-Menu-user-image"
        />
        <div className="Home-Menu-user-box-center">
          <div className="Home-Menu-user-box-nome">{nome}</div>
        </div>
      </div>
    </div>
  );
};

export default HomeMenuUser;
