import React, { useState } from 'react'
import Modal from '../../../components/modal'
import Button from '../../../components/Button/styles';
import { ContainerCSVModal, ButtonList } from './preencherManualmenteModalStyles';
import { useEffect } from 'react';
import FullscreenLoading from '../../../components/fullscreenLoading/fullscreenLoading';
import { postDownloadCSV, postUploadCSV } from '../services/services';
import { AxiosResponse } from 'axios';

interface ICSVModal {
  isModalOpen: boolean,
  msgModalAlert:(values: {openModal: boolean, mensagem: string, tipo: 'success' | 'info' | 'warning' | 'error'}) => void,
  handleCloseModal: () => void
}

interface IWarningModal {
  isModalOpen: boolean,
  handleCloseModal: () => void,
  confirmWarningModal: (value: boolean) => void
}

const CSVModal = ({
  isModalOpen,
  msgModalAlert,
  handleCloseModal,
}: ICSVModal) => {

  const [isOpen, setIsOpen] = useState(isModalOpen);
  const [loading, setLoading] = useState(false);
  const [openWarningModal, setOpenWarningModal] = useState(false);
  const [confirmWarningModal, setConfirmWarningModal] = useState(false);

  const inputFileRef = React.useRef(null);

  const onBtnClick = () => {
    setOpenWarningModal(true);
  };
  
  const onConfirmClick = (value: boolean) => {
    if (!!value) {
      //@ts-ignore
      inputFileRef.current.click();
    } else {
      setOpenWarningModal(false);
    }
  };

  const downloadCSV = async () => {
    setLoading(true)

    await postDownloadCSV({documentoAtualizado: false})
      .then((retorno: any) => {
        const { data } = retorno as AxiosResponse
        const url = window.URL.createObjectURL(new Blob([data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'exporta.csv')
        document.body.appendChild(link)
        link.click()
      }).catch((error) => {
        msgModalAlert({openModal: true, mensagem: "Erro ao exportar Metas'", tipo: "error"})
      })
    
    setLoading(false)
    handleCloseModal()
  }

  const handleUpload = async ( e: React.ChangeEvent<HTMLInputElement> ) => {
    setLoading(true);

    let arquivoSelecionado = undefined

    if (e.target.files && e.target.files.length > 0)
      arquivoSelecionado = e.target.files[0]

      var formData = new FormData()
      formData.append('documento', arquivoSelecionado!)
      formData.append('documentoAtualizado', String(true))

      await postUploadCSV(formData)
        .then((retorno: any) =>{
          msgModalAlert({openModal: true, mensagem: "Registros atualizados com sucesso.", tipo: "success"})
        }).catch((error) => {
        msgModalAlert({openModal: true, mensagem: `${error.response.data}`, tipo: "error"})
      });

    setLoading(false);
    handleCloseModal();
  };

  useEffect(() => {
    setIsOpen(isModalOpen)
  }, [isModalOpen])

  return (
    <Modal
      handleCloseModal={handleCloseModal}
      title=''
      isOpen={isOpen}
      titleStyle={{
        m: 0,
        p: 2,
        padding: "16px 22px",
        bgcolor: "#FFF",
        color: "#797D8C",
        boxSizing: "border-box",
        height: "67px",
        paddingLeft: '80px',
      }}
    >
      <ContainerCSVModal>
        {loading && <FullscreenLoading />}
        <h2 style={{textAlign: 'center'}}>
          Já possui o arquivo atualizado?
        </h2>
        <ButtonList>
          <Button onClick={onBtnClick}>Sim</Button>
          <Button variant='secondary' onClick={downloadCSV}>Não</Button>
        </ButtonList>
        <input
         type="file"
         ref={inputFileRef}
         onChangeCapture={handleUpload}
         style={{display: 'none'}}
         accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        />
        <ReplaceDataWarning 
          isModalOpen={openWarningModal} 
          handleCloseModal={() => setOpenWarningModal(false)}
          confirmWarningModal={onConfirmClick}
        />
      </ContainerCSVModal>
    </Modal>
  )
}

const ReplaceDataWarning = ({
  isModalOpen,
  handleCloseModal,
  confirmWarningModal,
}: IWarningModal) => {

  const [isOpen, setIsOpen] = useState(isModalOpen);

  const onBtnClick = () => {
    confirmWarningModal(true);
    setTimeout(() => handleCloseModal(), 1000);
  };

  const interHandleClose = () => {
    confirmWarningModal(false);
    handleCloseModal();
  };

  useEffect(() => {
    setIsOpen(isModalOpen)
  }, [isModalOpen]);

  return (
    <Modal
      handleCloseModal={interHandleClose}
      title=''
      isOpen={isOpen}
      titleStyle={{
        m: 0,
        p: 2,
        padding: "16px 22px",
        bgcolor: "#FFF",
        color: "#797D8C",
        boxSizing: "border-box",
        height: "67px",
        paddingLeft: '80px',
      }}
    >
      <ContainerCSVModal>
        <h2 style={{textAlign: 'center'}}>
          Todos os dados atuais serão substituídos.
        </h2>
        <ButtonList>
          <Button onClick={onBtnClick}>Continuar</Button>
          <Button variant='secondary' onClick={interHandleClose}>Cancelar</Button>
        </ButtonList>
      </ContainerCSVModal>
    </Modal>
  );
};

export default CSVModal;