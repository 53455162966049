import styled from "styled-components";
export const Section = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  row-gap: 2rem;
`;

export const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  row-gap: 1rem;
`
export const GoBack = styled.button`
  background: transparent;
  height: 27px;
  width: 27px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #797D8C;
  border-radius: 50%;
  color: #797D8C;
`