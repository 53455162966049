import React, { useState, useContext, useEffect } from "react";
import { HomeContext } from "../../../contexts/homeContext";
import { BoxFiltros, SearchButton } from "./farolFiltrosGrid_styles";
import Input from "../../../components/Input/Input";
import SearchIcon from "@mui/icons-material/Search";

interface IFarolFiltrosGrid {
  resetFiltrosPopover(e: any): any;
  setIsBuscaRapida: any;
}

const FarolFiltrosGrid = ({
  resetFiltrosPopover,
  setIsBuscaRapida,
}: IFarolFiltrosGrid) => {
  const { filtroBuscaRapida, setFiltroBuscaRapida } = useContext(HomeContext);
  const [filtroBuscaRapidaTemp, setFiltroBuscaRapidaTemp] = useState("");

  const handleSearch = () => {
    /**Aguarda o reset dos filtros
     * antes de fazer a busca rápida*/
    setTimeout(function () {
      setFiltroBuscaRapida(filtroBuscaRapidaTemp);
    }, 800);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      resetFiltrosPopover(true);
      handleSearch();
    }
  };

  useEffect(() => {
    if (!filtroBuscaRapida && !!filtroBuscaRapidaTemp) {
      setFiltroBuscaRapidaTemp(filtroBuscaRapida);
      setIsBuscaRapida();
    }
  }, [filtroBuscaRapida]);

  return (
    <BoxFiltros>
      <Input
        id="filtroBuscaRapida"
        placeholder="Buscar por OS ou Referência"
        name="chamado"
        value={filtroBuscaRapidaTemp}
        onChange={(e) => setFiltroBuscaRapidaTemp(e.target.value)}
        dimension="lg"
        style={{ maxHeight: "46px" }}
        onKeyDown={handleKeyDown}
      />

      <SearchButton
        id="filtroChamadoSearchButton"
        variant="contained"
        size="small"
        sx={{ maxHeight: "46px" }}
        onClick={() => {
          if (filtroBuscaRapidaTemp) resetFiltrosPopover(true);
          handleSearch();
        }}
        startIcon={<SearchIcon />}
      >
        Buscar
      </SearchButton>
    </BoxFiltros>
  );
};

export default FarolFiltrosGrid;
