import styled from "styled-components";
import { Button } from "@mui/material";
import { ReactComponent as FowardIconSVG } from "../../assets/icons/FowardIcon.svg";

export const FowardButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
`;

export const FowardIcon = styled(FowardIconSVG)`
  margin-right: 10px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #ffffff;
  padding: 26px 40px 38px 40px;
  box-sizing: border-box;
  & .MuiAlert-action {
    margin: unset;
  }
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  width: 572px;
`;

export const InfoLabel = styled.div`
  color: #797D8C;
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const ButtonGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 10px;
  -webkit-box-align: baseline;
  align-items: end;
  margin-bottom: 20px;
`;

export const ButtonMenu = styled(Button)`
  justify-content: start !important;
  &.isActive {
    background-color: #fff;
    color: #1f5ab6;
    fill: red;
    &:hover {
      background-color: #fff;
      color: #1f5ab6;
    }
    path {
      fill: #1f5ab6;
    }
  }
  .MuiButton-startIcon {
    margin-right: 32px;
  }
`;
