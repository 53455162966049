import { Text, View } from "@react-pdf/renderer";

const HeaderContent = ({title, style}: {title: string, style?: {}}) => {
    return(
        <View style={style}>
            <Text>{title}</Text>
        </View>
    )
}

export default HeaderContent;