import React from 'react'
import { Typography, Divider } from '@mui/material'
import { ReactComponent as BurguerMenuIcon } from "../../assets/icons/BurguerMenuIcon.svg";
import MenuUser from "../menuUser/menuUser";
import './header.scss'

interface IHeader {
  setMenuLateral: () => void,
  title: string
}

const Header = ({setMenuLateral, title}: IHeader) => {
  return (
    <div className='Header'>
      <header className="Header-Topo">
        <div className="Header-Topo-left">
          <BurguerMenuIcon className="Burguer-Menu-Icon" onClick={setMenuLateral}/>
          <Typography variant="h1" className="Header-Typography">{title}</Typography>
        </div>
        <div className="Header-Topo-right">
          <MenuUser />
        </div>
      </header>
      <Divider />
    </div>
  )
}

export default Header