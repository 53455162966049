import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0
  }
  *:focus {
    outline: 0;
  }
  :root {
    font-size: 16px;
  }
  body {
    font-size: .875rem;
    font-family: "Open Sans", "Noto Sans", sans-serif, 'Segoe UI', 'Roboto';
    -webkit-font-smoothing: antialiased;
    overflow-x: hidden;
    background-color: #fff;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    min-height: 100vh;
    max-width: 100vw;
  }
  body, input, button {
    font-family: "Open Sans", sans-serif, 'Segoe UI', 'Roboto';
  }
  a {
    text-decoration: none;
  }
  ul {
    list-style: none;
  }
  button {
    cursor: pointer;
  }
  p, h1, h2 {
    text-align: start;
  }
`;
