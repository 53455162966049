import { useState } from "react";
import { Link } from "react-router-dom";
import Alert from "@mui/material/Alert";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";

import { Formik, Form, Field, ErrorMessage, FormikHelpers } from "formik";
import * as yup from "yup";
import "./redefinirSenha.scss";
import RedefinirSenhaHeader from "./redefinirSenhaHeader";
import { AxiosResponse } from "axios";
import FullscreenLoading from "../../../components/fullscreenLoading/fullscreenLoading";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Button, Box } from "@mui/material";
import { redefinirSenha } from "../services/services";

export interface IRedefinirSenha {
  email: string;
}

const RedefinirSenha = () => {
  const [loading, setLoading] = useState(false);
  const [showAlertResult, setAlertResult] = useState<boolean | null>(null);
  const handleClickRedefinirSenha = async (
    values: IRedefinirSenha,
    { resetForm }: FormikHelpers<IRedefinirSenha>
  ) => {
    try {
      setLoading(true);
      const response = (await redefinirSenha(values)) as AxiosResponse;
      setAlertResult(response.status === 200);
    } catch (error) {
      console.error("Erro ao redefinir senha:", error);
      setAlertResult(false);
    } finally {
      setLoading(false);
      resetForm();
    }
  };

  const validationRedefinirSenha = yup.object().shape({
    email: yup
      .string()
      .required("Este campo é obrigatório")
      .email("Por favor, insira um endereço de e-mail válido"),
  });

  return (
    <div className="RedefinirSenha" id="RedefinirSenha">
      <RedefinirSenhaHeader />
      {loading && <FullscreenLoading />}
      {showAlertResult !== null &&
        (showAlertResult ? (
          <Alert
            icon={<CheckIcon fontSize="inherit" />}
            severity="success"
            action={
              <IconButton
                aria-label="Fechar"
                color="inherit"
                size="small"
                onClick={() => setAlertResult(null)}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            Ação realizada com sucesso! Verifique seu e-mail para seguir com o
            processo.
          </Alert>
        ) : (
          <Alert
            icon={<CheckIcon fontSize="inherit" />}
            severity="error"
            action={
              <IconButton
                aria-label="Fechar"
                color="inherit"
                size="small"
                onClick={() => setAlertResult(null)}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            Ocorreu um erro ao processar sua solicitação.
          </Alert>
        ))}

      <div className="RedefinirSenhaBox">
        <div className="RedefinirSenhaBoxWrapper">
          <div className="RedefinirSenhaBox-texto">Esqueceu sua senha?</div>
          <Formik
            initialValues={{
              email: "",
            }}
            onSubmit={handleClickRedefinirSenha}
            validationSchema={validationRedefinirSenha}
          >
            <Form className="redefinir-senha-form">
              <div className="redefinir-senha-form-group">
                <div className="redefinir-senha-form-group-texto">E-mail</div>
                <Field
                  id="redefinir-senha-form-input-email"
                  name="email"
                  type="email"
                  className="form-field"
                  placeholder="Informe seu e-mail"
                />
                <ErrorMessage
                  component="span"
                  name="email"
                  className="form-error"
                />
              </div>

              <button
                id="button-redefinir-senha"
                className="redefinir-senha-form-button"
                type="submit"
              >
                Redefinir Senha
              </button>
              <div className="redefinir-senha-opcoes">
                <Box display="flex" justifyContent="center">
                  <Link to="/Login">
                    <Button
                      align-items="center"
                      size="small"
                      variant="text"
                      startIcon={<ArrowBackIcon />}
                    >
                      Voltar ao Login
                    </Button>
                  </Link>
                </Box>
              </div>
            </Form>
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default RedefinirSenha;
