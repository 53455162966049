import styled from "styled-components";

export const Breadcrumb = styled('div')(({ theme }) => ({
  color: theme.palette.text.primary,
  fontWeight: 600,
  display: 'flex',
  gap: '4px',
  marginBottom: '16px',
  overflowWrap: 'break-word',
  overflow: 'auto',
  minHeight: '25px'
}));

export const BreadcrumbItem = styled.div`
  max-width: fit-content;
  white-space: nowrap;
  cursor: pointer;
  ::after {
    content: " > ";
  };
  :last-child {
    text-decoration: none;
    color: black;
    ::after {
      content: ''
    };
  }
`