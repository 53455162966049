import { useContext } from 'react';
import _ from "lodash";
import * as Yup from 'yup';
import Popover from "@mui/material/Popover";
import Button from "@mui/material/Button";
import AutocompleteMultiple from "../../../components/autocompleteMultiple/autocompleteMultiple";
import { IChecklistPopoverFiltros } from "../types";
import "./checklistPopoverFiltros.scss";
import { HomeContext } from '../../../contexts/homeContext';
import Input from '../../../components/Input/Input';
import { InputLabel } from '@mui/material';
import { Form, Formik } from 'formik';
import { ISimpleObjectInput } from '../../../core/types';
import DatePicker from '../../../components/datePicker/datePicker';

interface IInitialValues {
  checklistFiltroDataInicialFormik: any | null;
  checklistFiltroDataFinalFormik: any | null;
  checklistFiltroTecnicosSelecionadosFormik: ISimpleObjectInput[];
  checklistFiltroPlacaMotoSelecionadosFormik: ISimpleObjectInput[];
  checklistFiltroTipoMotoSelecionadosFormik: ISimpleObjectInput[];
  checklistFiltroKMInicialFormik: number;
  checklistFiltroKMFinalFormik: number;

  checklistFiltroStatusItemMotoFormik: ISimpleObjectInput[];
  checklistFiltroItemMotoFormik: ISimpleObjectInput[];
  checklistFiltroStatusItemEPIFormik: ISimpleObjectInput[];
  checklistFiltroItemEPIFormik: ISimpleObjectInput[];
};

const ChecklistPopoverFiltros = ({
  id = "Checklist-PopoverFiltro",
  open = false,
  onClose,
  setCurrentPage,
  resetFiltrosPopover,
  anchorEl = null,
  anchorOrigin = {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin = {
    vertical: "top",
    horizontal: "center",
  },
}: IChecklistPopoverFiltros) => {

  const {
    checklistFiltroDataInicial,
    setChecklistFiltroDataInicial,
    checklistFiltroDataFinal,
    setChecklistFiltroDataFinal,

    checklistTecnicoLista,
    checklistFiltroTecnicosSelecionados,
    setChecklistFiltroTecnicoSelecionados,

    checklistFiltroPlacaMotoLista,
    checklistFiltroPlacaMotoSelecionados,
    setChecklistFiltroPlacaMotoSelecionados,

    checklistFiltroTipoMotoLista,
    checklistFiltroTipoMotoSelecionados,
    setChecklistFiltroTipoMotoSelecionados,

    checklistFiltroKMInicial,
    setChecklistFiltroKMInicial,
    checklistFiltroKMFinal,
    setChecklistFiltroKMFinal,

    checklistStatusItemMotoLista,
    checklistFiltroStatusItemMoto,
    setChecklistFiltroStatusItemMoto,
    checklistItemMotoLista,
    checklistFiltroItemMoto,
    setChecklistFiltroItemMoto,

    checklistStatusItemEPILista,
    checklistFiltroStatusItemEPI,
    setChecklistFiltroStatusItemEPI,
    checklistItemEPILista,
    checklistFiltroItemEPI,
    setChecklistFiltroItemEPI,
  } = useContext(HomeContext);

  const handleClickLimpar = () => {
    setChecklistFiltroDataInicial("");
    setChecklistFiltroDataFinal("");
    setChecklistFiltroTecnicoSelecionados([]);
    setChecklistFiltroPlacaMotoSelecionados([]);
    setChecklistFiltroTipoMotoSelecionados([]);
    setChecklistFiltroKMInicial(0);
    setChecklistFiltroKMFinal(0);
    setChecklistFiltroStatusItemMoto([]);
    setChecklistFiltroItemMoto([]);
    setChecklistFiltroStatusItemEPI([]);
    setChecklistFiltroItemEPI([]);
  };

  const handleClickFiltrar = () => {
    resetFiltrosPopover(false);
    if (setCurrentPage) setCurrentPage(1);
    onClose();
  };

  const initialValues: IInitialValues = {
    checklistFiltroDataInicialFormik: checklistFiltroDataInicial || null,
    checklistFiltroDataFinalFormik: checklistFiltroDataFinal || null,
    checklistFiltroTecnicosSelecionadosFormik: checklistFiltroTecnicosSelecionados || [] as ISimpleObjectInput[],
    checklistFiltroPlacaMotoSelecionadosFormik: checklistFiltroPlacaMotoSelecionados || [] as ISimpleObjectInput[],
    checklistFiltroTipoMotoSelecionadosFormik: checklistFiltroTipoMotoSelecionados || [] as ISimpleObjectInput[],
    checklistFiltroKMInicialFormik: Number(checklistFiltroKMInicial) | 0,
    checklistFiltroKMFinalFormik: Number(checklistFiltroKMFinal) | 0,

    checklistFiltroStatusItemMotoFormik: checklistFiltroStatusItemMoto || [] as ISimpleObjectInput[],
    checklistFiltroItemMotoFormik: checklistFiltroItemMoto || [] as ISimpleObjectInput[],
    checklistFiltroStatusItemEPIFormik: checklistFiltroStatusItemEPI || [] as ISimpleObjectInput[],
    checklistFiltroItemEPIFormik: checklistFiltroItemEPI || [] as ISimpleObjectInput[],
  }

  const validationSchema = Yup.object().shape({
    checklistFiltroDataInicialFormik: Yup.date().nullable()
      .test('data-inicio', 'Data inicial não pode ser maior que a data final', function (value) {
        const { checklistFiltroDataFinalFormik } = this.parent;
        return !checklistFiltroDataFinalFormik || !value || value <= checklistFiltroDataFinalFormik;
      }),
    checklistFiltroDataFinalFormik: Yup.date().nullable()
      .test('data-final', 'Data final não pode ser menor que a data inicial', function (value) {
        const { checklistFiltroDataInicialFormik } = this.parent;
        return !checklistFiltroDataInicialFormik || !value || value >= checklistFiltroDataInicialFormik;
      }),
    // checklistFiltroStatusItemMotoFormik: Yup.array().of(Yup.string()),
    // checklistFiltroTecnicosSelecionadosFormik: Yup.array().of(Yup.string()),
    // checklistFiltroItemMotoFormik: Yup.array().of(Yup.string()),
    // checklistFiltroPlacaMotoSelecionadosFormik: Yup.array().of(Yup.string()),
    // checklistFiltroStatusItemEPIFormik: Yup.array().of(Yup.string()),
    // checklistFiltroTipoMotoSelecionadosFormik: Yup.array().of(Yup.string()),
    // checklistFiltroItemEPIFormik: Yup.array().of(Yup.string()),
    checklistFiltroKMInicialFormik: Yup.number().min(0, 'KM Inicial deve ser maior ou igual a 0')
      .test('km-inicial', 'KM Inicial não pode ser maior que KM Final', function (value) {
        const { checklistFiltroKMFinalFormik } = this.parent;
        return !checklistFiltroKMFinalFormik || Number(value) <= checklistFiltroKMFinalFormik;
      }),
    checklistFiltroKMFinalFormik: Yup.number().min(0, 'KM Final deve ser maior ou igual a 0')
      .test('km-final', 'KM Final não pode ser menor que KM Inicial', function (value) {
        const { checklistFiltroKMInicialFormik } = this.parent;
        return !checklistFiltroKMInicialFormik || Number(value) >= checklistFiltroKMInicialFormik;
      })
  });

  return (
    <Popover
      id={id}
      open={open}
      onClose={onClose}
      anchorEl={anchorEl}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      classes={{ paper: "Checklist-PopoverFiltro-box" }}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize={true}
        onSubmit={(values, actions) => {
          setChecklistFiltroDataInicial(values.checklistFiltroDataInicialFormik);
          setChecklistFiltroDataFinal(values.checklistFiltroDataFinalFormik);
          setChecklistFiltroTecnicoSelecionados(values.checklistFiltroTecnicosSelecionadosFormik);
          setChecklistFiltroPlacaMotoSelecionados(values.checklistFiltroPlacaMotoSelecionadosFormik);
          setChecklistFiltroTipoMotoSelecionados(values.checklistFiltroTipoMotoSelecionadosFormik);
          setChecklistFiltroKMInicial(values.checklistFiltroKMInicialFormik);
          setChecklistFiltroKMFinal(values.checklistFiltroKMFinalFormik);
          setChecklistFiltroStatusItemMoto(values.checklistFiltroStatusItemMotoFormik);
          setChecklistFiltroItemMoto(values.checklistFiltroItemMotoFormik);
          setChecklistFiltroStatusItemEPI(values.checklistFiltroStatusItemEPIFormik);
          setChecklistFiltroItemEPI(values.checklistFiltroItemEPIFormik);
        }}
      >
        {({ values, resetForm, setFieldValue, handleChange, isValid }) => (
          <Form>

            <div className="Checklist-PopoverFiltro">
              <div className="Checklist-PopoverFiltro-label">Filtros</div>
              <div className="Checklist-PopoverFiltro-options">
                <div>
                  <InputLabel className='label-custom'>Data</InputLabel>
                  <div className="Checklist-PopoverFiltro-options">
                    <DatePicker
                      dimension="sm"
                      id="checklistFiltroDataInicialFormik"
                      value={values.checklistFiltroDataInicialFormik}
                      onChange={(e: any) => setFieldValue('checklistFiltroDataInicialFormik', e)}
                      error={false}
                    />
                    <DatePicker
                      dimension="sm"
                      id="checklistFiltroDataFinalFormik"
                      value={values.checklistFiltroDataFinalFormik}
                      onChange={(e: any) => setFieldValue('checklistFiltroDataFinalFormik', e)}
                      error={false}
                    />
                  </div>
                </div>

                <AutocompleteMultiple
                  label={"Status do item da moto"}
                  noOptionsText={"Nenhum item encontrado"}
                  options={checklistStatusItemMotoLista}
                  value={values.checklistFiltroStatusItemMotoFormik}
                  onChange={(e: any) => setFieldValue('checklistFiltroStatusItemMotoFormik', e)}
                  multiple={true}
                  dimension='sm'
                />

                <AutocompleteMultiple
                  label={"Técnico"}
                  noOptionsText={"Nenhum técicno encontrado"}
                  options={checklistTecnicoLista}
                  value={values.checklistFiltroTecnicosSelecionadosFormik}
                  onChange={(e: any) => setFieldValue('checklistFiltroTecnicosSelecionadosFormik', e)}
                  multiple={true}
                  dimension='sm'
                />

                <AutocompleteMultiple
                  label={"Item da moto"}
                  noOptionsText={"Nenhum item encontrado"}
                  options={checklistItemMotoLista}
                  value={values.checklistFiltroItemMotoFormik}
                  onChange={(e: any) => setFieldValue('checklistFiltroItemMotoFormik', e)}
                  multiple={true}
                  dimension='sm'
                />

                <AutocompleteMultiple
                  label={"Placa da Moto"}
                  noOptionsText={"Nenhuma placa encontrada"}
                  options={checklistFiltroPlacaMotoLista}
                  value={values.checklistFiltroPlacaMotoSelecionadosFormik}
                  onChange={(e: any) => setFieldValue('checklistFiltroPlacaMotoSelecionadosFormik', e)}
                  multiple={true}
                  dimension='sm'
                />

                <AutocompleteMultiple
                  label={"Status do EPI"}
                  noOptionsText={"Nenhum item encontrado"}
                  options={checklistStatusItemEPILista}
                  value={values.checklistFiltroStatusItemEPIFormik}
                  onChange={(e: any) => setFieldValue('checklistFiltroStatusItemEPIFormik', e)}
                  multiple={true}
                  dimension='sm'
                />

                <AutocompleteMultiple
                  label={"Tipo de Moto"}
                  noOptionsText={"Nenhum tipo encontrado"}
                  options={checklistFiltroTipoMotoLista}
                  value={values.checklistFiltroTipoMotoSelecionadosFormik}
                  onChange={(e: any) => setFieldValue('checklistFiltroTipoMotoSelecionadosFormik', e)}
                  multiple={true}
                  dimension='sm'
                />

                <AutocompleteMultiple
                  label={"EPI"}
                  noOptionsText={"Nenhum item encontrado"}
                  options={checklistItemEPILista}
                  value={values.checklistFiltroItemEPIFormik}
                  onChange={(e: any) => setFieldValue('checklistFiltroItemEPIFormik', e)}
                  multiple={true}
                  dimension='sm'
                />

                <div>
                  <InputLabel className='label-custom'>Quilometragem</InputLabel>

                  <div className="Checklist-PopoverFiltro-Quilo">
                    <InputLabel className="Checklist-Label-Quilo">De</InputLabel>
                    <Input
                      value={values.checklistFiltroKMInicialFormik}
                      onChange={handleChange}
                      dimension='sm'
                      id='checklistFiltroKMInicialFormik'
                      className='ChecklistFiltroKMInicialFormik'
                      placeholder='KM Inicial'
                      type='number'
                      min={0}
                    />
                    <InputLabel className="Checklist-Label-Quilo">a</InputLabel>
                    <Input
                      value={values.checklistFiltroKMFinalFormik}
                      onChange={handleChange}
                      dimension='sm'
                      id='checklistFiltroKMFinalFormik'
                      className='ChecklistFiltroKMFinalFormik'
                      placeholder='KM Final'
                      type='number'
                      min={0}
                    />

                    <InputLabel className="Checklist-Label-Quilo">Km</InputLabel>

                  </div>
                </div>
              </div>

              <div className='Checklist-PopoverFiltro-Footer'>
                <Button
                  id={"Checklist-PopoverFiltro-BotaoLimpar"}
                  variant="outlined"
                  classes={{
                    root: "Checklist-PopoverFiltro-BotaoLimpar",
                  }}
                  onClick={() => {
                    resetFiltrosPopover(false);
                    handleClickLimpar();
                    resetForm();
                  }}
                >
                  Limpar
                </Button>
                <Button
                  id={"Checklist-PopoverFiltro-BotaoFiltrar"}
                  variant="contained"
                  classes={{
                    root: "Checklist-PopoverFiltro-BotaoFiltrar",
                  }}
                  onClick={handleClickFiltrar}
                  type="submit"
                  disabled={!isValid}
                >
                  Filtrar
                </Button>
              </div>
            </div>

          </Form>
        )}
      </Formik>

    </Popover>
  );
};

export default ChecklistPopoverFiltros;