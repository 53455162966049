import { apiGet, apiPost, apiPut } from "../../../services/api";
import { IGetUsuarios, IPostUsuario, IPutUsuario } from "../types";

const baseUrlIdentidade = process.env.REACT_APP_API_IDENTIDADE;

export const getUsuarios = async (params: IGetUsuarios, prestadores: number[] = []) =>
  await apiGet(
    `${baseUrlIdentidade}/Usuario${!!prestadores.length ? `?` + prestadores.map((n) => `Filtro.Prestadores=${n}`).join('&') : ''}`,
    params
  );

export const incluirUsuario = async (params: IPostUsuario) =>
  await apiPost(`${baseUrlIdentidade}/Usuario`, params);

export const editarUsuario = async (params: IPutUsuario) =>
  await apiPut(`${baseUrlIdentidade}/Usuario`, params);
