import React, { useContext, useEffect, useState } from "react";
import Header from "../../../components/header/header";
import { Breadcrumb, BreadcrumbItem } from "../../../components/breadcrumb/breadcrumb";
import { HomeContext } from "../../../contexts/homeContext";
import { AuthContext } from "../../../contexts/auth";
import './editarUsuario.scss';
import { useNavigate, useLocation } from "react-router-dom";
import Input from "../../../components/Input/Input";
import MultipleSelectCheckmarksid from "../../../components/seletorMultiploId/seletorMultiploId";
import { useFormik } from "formik";
import * as yup from "yup";
import Button from "../../../components/Button/styles";
import { AxiosResponse } from "axios";
import {
  Section,
} from "./editarUsuario_styles";
import { ReactComponent as Voltar } from "../../../assets/icons/Voltar.svg";
import moment from "moment";
import { Alert, CircularProgress, IconButton } from '@mui/material';
import Collapse from '../../../components/collapse/collapse';
import CloseIcon from "@mui/icons-material/Close";
import { editarUsuario } from "../services";
import { getPrestadores } from "../../enviarTecnico/services/services";
import AutocompleteMultiple from "../../../components/autocompleteMultiple/autocompleteMultiple";
import _ from "lodash";
import DateTimePicker from "../../../components/dateTimePicker/dateTimePicker";
import { IPutUsuario } from "../types";
import { getPerfis } from '../../perfil/services/services'
import { IGetPerfilResponse } from "../../perfil/types";
import SwitchMUI from '../../../components/switch/switch';
import FormControl from "@mui/material/FormControl";

enum Tipo2FA {
  Email = '1',
  SMS = '2'
}

const EditarUsuario = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const dadosUsuario = location.state?.dadosUsuario || {};
  const rota = location.state?.rota || {};

  const { setMenuLateral, listaContratante, setPerfilLista, perfilLista, localGetContratantes } = useContext(HomeContext);
  const { user, funcionalidadeDaTelaTemPermissao } = useContext(AuthContext);
  const profileHasPermission = (funcionalidade: string) => funcionalidadeDaTelaTemPermissao("Edição de Usuário", funcionalidade);

  const [prestadores, setPrestadores] = useState<any[]>([]);
  const [disableActions, setDisableActions] = useState(false);
  const [listaStatus, setListaStatus] = useState<{ name: string, id: string }[]>([
    { name: 'Ativo', id: '0' },
    { name: 'Inativo', id: '1' },
  ]);
  const [listaTipo2FA, setListaTipo2FA] = useState<{ name: string, id: string }[]>([
    { name: 'Email', id: Tipo2FA.Email },
    // { name: 'SMS', id: Tipo2FA.SMS },
  ]);
  const [openAlertError, setOpenAlertError] = useState(false);
  const [msgAlertError, setMsgAlertError] = useState("");
  const [openAlertSuccess, setOpenAlertSuccess] = useState(false);
  const [msgAlertSuccess, setMsgAlertSuccess] = useState("");
  const [loading, setLoading] = useState<boolean>(false);

  const handleEditarUsuario = async (values: any, actions: any) => {
    setOpenAlertError(false);
    setLoading(true);
    setDisableActions(true);

    const statusBool = values.status[0] === '0' ? true : false;

    let params: IPutUsuario = {
      ativo: statusBool,
      idUsuario: dadosUsuario.id,
      nomeCompleto: values.nome,
      dataExpiracao: values.dataExpiracao.format("YYYY-MM-DDTHH:mm:ss"),
      login: values.login,
      idUsuarioModificacao: user?.idUsuario || 0,
      prestadores: !!values.selectedPrestadores.length ? values.selectedPrestadores.map((p: any) => Number(p.id)) : [],
      ...(!!values.perfil.length && values.perfil[0] !== '' ? { idPerfil: Number(values.perfil[0]) } : {}),
      ...(!!values.password ? { senha: values.password } : {}),
      ...(!!values.contratante.length && values.contratante[0] !== '' ? { idContratante: Number(values.contratante[0]) } : {}),
      ...(!!user?.prestadorId ? { idPrestador: Number(user?.prestadorId) } : {}),
      habilitar2FA: values.habilitar2FA,
      ...(!!values.tipo2FA?.length ? { tipo2FA: Number(values.tipo2FA[0]) } : {}),
      ...(!!values.email ? { email: values.email } : {}),
    };

    try {
      let { data } = await editarUsuario(params) as AxiosResponse;

      setLoading(false);
      setDisableActions(false);

      if (!!data && typeof data === "string") {
        setMsgAlertSuccess(data);
      } else {
        setMsgAlertSuccess("Registro salvo com sucesso");
      }

      setOpenAlertSuccess(true);
      navigate(".", {
        state: {
          dadosUsuario: {
            ...dadosUsuario,
            ativo: params.ativo,
            idContratante: params.idContratante,
            idPerfil: params.idPerfil || '',
            nomeContratante: listaContratante.find((item: any) => Number(item.id) === Number(params.idContratante))?.name,
            prestadores: values.selectedPrestadores.map((p: any) => ({ id: Number(p.id), nome: p.name })),
            nomeCompleto: params.nomeCompleto,
            login: params.login,
            dataModificacao: moment().format("YYYY-MM-DDTHH:mm:ss"),
            usuarioModificacao: user?.nome,
            dataExpiracao: values.dataExpiracao.format("YYYY-MM-DDTHH:mm:ss"),
            habilitar2FA: params.habilitar2FA,
            tipo2FA: !!params.tipo2FA ? params.tipo2FA : dadosUsuario.tipo2FA,
            email: !!params.email ? params.email : dadosUsuario.email,
          },
          rota: {
            url: rota.url,
            name: rota.name,
          },
        }
      }); // <-- redirect to current path with updated state

    } catch (e: any) {
      setLoading(false);
      setDisableActions(false);

      if (e?.response?.data?.errors) {
        setMsgAlertError(Object.values(e.response.data.errors).join("<br>"));
      } else {
        setMsgAlertError("Erro ao salvar o registro" + (e?.response?.status ? ` status: ${e?.response?.status}` : ""));
      }

      setOpenAlertError(true);
    }
  };

  const validationSchema = yup.object({
    status: yup.array().required('Campo obrigatório'),
    nome: yup.string().required('Campo obrigatório'),
    login: yup.string().required('Campo obrigatório'),
    dataExpiracao: yup.date().required('Campo obrigatório'),
    perfil: yup.array().min(1, 'Campo obrigatório'),
    tipo2FA: yup.array().test(
      'tipo2FA',
      'Campo obrigatório',
      (val, ctx) => {
        if (!ctx.parent?.habilitar2FA) return true;
        return !!val?.length;
      }),
    email: yup
      .string()
      .test(
        'email',
        'Campo obrigatório',
        (val, ctx) => {
          if (!ctx.parent?.habilitar2FA || !ctx.parent?.tipo2FA?.length || ctx.parent?.tipo2FA[0] !== Tipo2FA.Email) return true;
          return !!val?.length;
        })
      .email("Por favor, insira um endereço de e-mail válido"),
  });

  const selectIDByLabel = (label: string, dados: any, defaultReturn?: string) => {
    let itemObj = dados.find((item: any) => item.name === label);

    if (!!itemObj) return [itemObj.id];

    return !!defaultReturn ? [defaultReturn] : [];
  };

  const selectObjByIDs = (IDs: [], dados: any) => {
    let objs: any = [];

    IDs.forEach((id: string) => {
      let itemObj = dados.find((item: any) => Number(item.id) === Number(id));

      if (!!itemObj) objs.push(itemObj);
    })

    return objs;
  };

  const initialValues: any = {
    status: !!dadosUsuario.ativo ? ["0"] : ["1"],
    idUser: dadosUsuario.id,
    contratante: selectIDByLabel(dadosUsuario.nomeContratante, listaContratante),
    selectedPrestadores: !!dadosUsuario.prestadores?.length ? selectObjByIDs(dadosUsuario.prestadores.map((p: any) => p.id), prestadores) : [],
    nome: dadosUsuario.nomeCompleto,
    login: dadosUsuario.login,
    password: "",
    dataExpiracao: !!dadosUsuario.dataExpiracao && moment(dadosUsuario.dataExpiracao, "YYYY-MM-DDTHH:mm:ss").isValid() ? moment(dadosUsuario.dataExpiracao, "YYYY-MM-DDTHH:mm:ss") : null,
    dataUltimoAcesso: !!dadosUsuario.ultimoAcesso && moment(dadosUsuario.ultimoAcesso, "YYYY-MM-DDTHH:mm:ss").isValid() ? moment(dadosUsuario.ultimoAcesso, "YYYY-MM-DDTHH:mm:ss").format("DD/MM/YYYY HH:mm:ss") : "",
    dataCriacao: !!dadosUsuario.dataCriacao && moment(dadosUsuario.dataCriacao, "YYYY-MM-DDTHH:mm:ss").isValid() ? moment(dadosUsuario.dataCriacao, "YYYY-MM-DDTHH:mm:ss").format("DD/MM/YYYY HH:mm:ss") : "",
    usuarioCriacao: dadosUsuario.usuarioCriacao,
    dataModificacao: !!dadosUsuario.dataModificacao && moment(dadosUsuario.dataModificacao, "YYYY-MM-DDTHH:mm:ss").isValid() ? moment(dadosUsuario.dataModificacao, "YYYY-MM-DDTHH:mm:ss").format("DD/MM/YYYY HH:mm:ss") : "",
    usuarioModificacao: dadosUsuario.usuarioModificacao,
    perfil: !!dadosUsuario.idPerfil && dadosUsuario.idPerfil !== 0 ? [String(dadosUsuario.idPerfil)] : [],
    habilitar2FA: dadosUsuario.habilitar2FA ?? false,
    tipo2FA: dadosUsuario.tipo2FA ? [String(dadosUsuario.tipo2FA)] : [Tipo2FA.Email],
    email: dadosUsuario.email ?? "",
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values, actions) => {
      if (!!profileHasPermission("Editar Registro")) return handleEditarUsuario(values, actions);
    }
  });

  const getProvider = async () => {
    try {
      const { data } = await getPrestadores() as AxiosResponse

      let prestadores: any = [];

      if (!!data && !!data.length) {
        prestadores = _.uniqBy(data, "id").filter((p: any) => !!p.ativo).map((m: any) => ({ ...m, name: m.nomePrestador, id: m.id?.toString() }))
          .sort((a: any, b: any) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
      }

      setPrestadores(prestadores);

    } catch (e) {
      setPrestadores([]);
    }
  }

  const getPerfilWithoutId = async () => {
    try {

      const { data } = await getPerfis(true) as AxiosResponse

      if (_.isEmpty(perfilLista)) {
        let perfis = data?.map((item: IGetPerfilResponse) => {
          return (
            { name: item.nome, id: item.id.toString() }
          )
        })

        setPerfilLista(perfis)
      }

      return data

    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    getPerfilWithoutId()
    localGetContratantes();
    getProvider();
  }, [])

  return (
    <Section id={"EditarUsuario"} className="EditarUsuario">
      <Header
        setMenuLateral={() => setMenuLateral(true)}
        title={`Alterar Usuário (${dadosUsuario.nomeCompleto || '???'})`}
      />
      <Breadcrumb>
        {!!rota && rota.name &&
          <BreadcrumbItem onClick={() => navigate(rota.url)}>{rota.name}</BreadcrumbItem>
        }
        <BreadcrumbItem>Alterar Usuário</BreadcrumbItem>
      </Breadcrumb>

      <form className="EditarUsuario-Form" onSubmit={formik.handleSubmit}>
        <div className="EditarUsuario-Container">
          <div className="InsideGrid">
            <MultipleSelectCheckmarksid
              dimension='sm'
              id='multiple-checkbox-status'
              idOption='multiple-checkbox-option-status'
              labelId='multiple-checkbox-label-status'
              nodata="Nenhum status encontrado"
              value={formik.values.status}
              onChange={(e) => (formik.setFieldValue('status', e))}
              options={listaStatus}
              label='Status'
              name='status'
              error={formik.touched.status && Boolean(formik.errors.status)}
              helperText={formik.touched.status && formik.errors.status}
              multiple={false}
              disabled={disableActions}
            />
            <Input
              type='number'
              dimension="sm"
              label='ID Usuário'
              name='idUser'
              id='idUser'
              value={formik.values.idUser}
              onChange={formik.handleChange}
              error={formik.touched.idUser && Boolean(formik.errors.idUser)}
              helperText={formik.touched.idUser && formik.errors.idUser}
              readOnly
            />
            <MultipleSelectCheckmarksid
              dimension="sm"
              label={"Contratante"}
              nodata={"Nenhum contratante encontrado"}
              options={[{ name: '--', id: '' }, ...listaContratante]}
              value={formik.values.contratante}
              onChange={(e) => (formik.setFieldValue('contratante', e))}
              placeholder={""}
              id="multiple-checkbox-Contratante"
              idOption="multiple-checkbox-option-Contratante-"
              labelId="multiple-checkbox-label-Contratante"
              classes={{
                select: "ConsultaUsuario-Seletor error",
                primary: "Seletor-Option",
                group: "Seletor-OptionGroup",
              }}
              multiple={false}
              error={formik.touched.contratante && Boolean(formik.errors.contratante)}
              helperText={formik.touched.contratante && formik.errors.contratante}
              disabled={disableActions}
            />
            <AutocompleteMultiple
              dimension="sm"
              label={"Prestadores"}
              placeholder={""}
              noOptionsText={"Nenhum prestador encontrado"}
              id="selectedPrestadores"
              name="multiple-checkbox-Prestadores"
              options={prestadores}
              value={formik.values.selectedPrestadores}
              defaultValue={formik.initialValues.selectedPrestadores}
              onChange={(e: any) => (formik.setFieldValue('selectedPrestadores', e))}
              fontSize={12}
              error={formik.touched.selectedPrestadores && Boolean(formik.errors.selectedPrestadores)}
              helperText={formik.touched.selectedPrestadores && formik.errors.selectedPrestadores}
              disabled={disableActions}
              allSelected
              limitTags={4}
            />
          </div>

          <div className="InsideGrid2">
            <Input
              dimension="sm"
              label='Nome'
              name='nome'
              id='nome'
              value={formik.values.nome}
              onChange={formik.handleChange}
              error={formik.touched.nome && Boolean(formik.errors.nome)}
              helperText={formik.touched.nome && formik.errors.nome}
              disabled={disableActions}
            />
            <Input
              dimension="sm"
              label='Login'
              name='login'
              id='login'
              value={formik.values.login}
              onChange={formik.handleChange}
              error={formik.touched.login && Boolean(formik.errors.login)}
              helperText={formik.touched.login && formik.errors.login}
              disabled={disableActions}
            />
            <Input
              dimension="sm"
              label='Senha'
              name='password'
              type="password"
              id='password'
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              disabled={disableActions}
            />
            <MultipleSelectCheckmarksid
              dimension="sm"
              label={"Perfil"}
              nodata={"Nenhum perfil encontrado"}
              options={perfilLista}
              value={formik.values.perfil}
              onChange={(e) => formik.setFieldValue('perfil', e)}
              placeholder={""}
              id="multiple-checkbox-Perfil"
              idOption="multiple-checkbox-option-Perfil-"
              labelId="multiple-checkbox-label-Perfil"
              classes={{
                select: "ConsultaUsuario-Seletor error",
                primary: "Seletor-Option",
                group: "Seletor-OptionGroup",
              }}
              multiple={false}
              error={formik.touched.perfil && Boolean(formik.errors.perfil)}
              helperText={formik.touched.perfil && formik.errors.perfil}
              disabled={disableActions}
            />
          </div>

          <div className="InsideGrid3">
            <DateTimePicker
              dimension="sm"
              label='Data de Expiração'
              id="dataExpiracao"
              value={formik.values.dataExpiracao}
              onChange={(e) => (formik.setFieldValue('dataExpiracao', e))}
              error={formik.touched.dataExpiracao && Boolean(formik.errors.dataExpiracao)}
              helperText={(formik.touched.dataExpiracao && formik.errors.dataExpiracao) ? "Campo obrigatório" : ""}
              disabled={disableActions || !profileHasPermission("Campo Data Expiração")}
            />

            <FormControl className="container" sx={{ m: 1, width: "100%" }}>
              <label className={`label-input sm`}>2FA (Autenticação dois fatores)</label>

              <SwitchMUI
                type={"IOS"}
                sx={{ margin: 'unset' }}
                checked={formik.values.habilitar2FA}
                setChecked={(value: boolean) => (formik.setFieldValue('habilitar2FA', value))}
              />

              <span className="errorMessage">{(formik.touched.habilitar2FA && formik.errors.habilitar2FA) ? "Campo obrigatório" : ""}</span>
            </FormControl>

            <MultipleSelectCheckmarksid
              dimension='sm'
              id='multiple-checkbox-tipo2FA'
              idOption='multiple-checkbox-option-tipo2FA'
              labelId='multiple-checkbox-label-tipo2FA'
              nodata="Nenhum tipo 2FA encontrado"
              value={formik.values.tipo2FA}
              onChange={(e) => (formik.setFieldValue('tipo2FA', e))}
              options={listaTipo2FA}
              label='Tipo de autenticação'
              name='tipo2FA'
              error={formik.touched.tipo2FA && Boolean(formik.errors.tipo2FA)}
              helperText={formik.touched.tipo2FA && formik.errors.tipo2FA}
              multiple={false}
              readOnly={!formik.values.habilitar2FA}
            />

            {(formik.values.tipo2FA[0] === Tipo2FA.Email) &&
              <Input
                dimension="sm"
                label='Email'
                name='email'
                id='email'
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            }
          </div>

          <div className="InsideGrid4">
            <Input
              dimension="sm"
              label='Data Último Acesso'
              id="dataUltimoAcesso"
              value={formik.values.dataUltimoAcesso}
              onChange={(e) => (formik.setFieldValue('dataUltimoAcesso', e))}
              error={formik.touched.dataUltimoAcesso && Boolean(formik.errors.dataUltimoAcesso)}
              helperText={(formik.touched.dataUltimoAcesso && formik.errors.dataUltimoAcesso) ? "Campo obrigatório" : ""}
              readOnly
            />
            <Input
              dimension="sm"
              label='Data de Criação'
              id="dataCriacao"
              value={formik.values.dataCriacao}
              onChange={(e) => (formik.setFieldValue('dataCriacao', e))}
              error={formik.touched.dataCriacao && Boolean(formik.errors.dataCriacao)}
              helperText={(formik.touched.dataCriacao && formik.errors.dataCriacao) ? "Campo obrigatório" : ""}
              readOnly
            />
            <Input
              dimension="sm"
              label='Criado Por'
              name='usuarioCriacao'
              id='usuarioCriacao'
              value={formik.values.usuarioCriacao}
              onChange={formik.handleChange}
              error={formik.touched.usuarioCriacao && Boolean(formik.errors.usuarioCriacao)}
              helperText={formik.touched.usuarioCriacao && formik.errors.usuarioCriacao}
              readOnly
            />
            <Input
              dimension="sm"
              label='Data de Modificaçao'
              id="dataModificacao"
              value={formik.values.dataModificacao}
              onChange={(e) => (formik.setFieldValue('dataModificacao', e))}
              error={formik.touched.dataModificacao && Boolean(formik.errors.dataModificacao)}
              helperText={(formik.touched.dataModificacao && formik.errors.dataModificacao) ? "Campo obrigatório" : ""}
              readOnly
            />
            <Input
              dimension="sm"
              label='Modificado Por'
              name='usuarioModificacao'
              id='usuarioModificacao'
              value={formik.values.usuarioModificacao}
              onChange={formik.handleChange}
              error={formik.touched.usuarioModificacao && Boolean(formik.errors.usuarioModificacao)}
              helperText={formik.touched.usuarioModificacao && formik.errors.usuarioModificacao}
              readOnly
            />
          </div>
        </div>

        <div className='button-list-right'>
          {!!profileHasPermission("Editar Registro") ?
            <Button variant='primary' type="submit" disabled={disableActions}>Salvar</Button>
            :
            <Button variant='primary' style={{ cursor: 'not-allowed', opacity: 0.5 }} type="button" disabled={true}>Salvar</Button>
          }
        </div>
      </form>

      {!!loading && <CircularProgress style={{ margin: '0px auto' }} />}

      {openAlertError &&
        <Collapse onClose={setOpenAlertError} in={openAlertError}>
          <Alert
            severity='error'
            icon={<div />}
            variant="filled"
            sx={{
              mb: 2,
              bgcolor: "rgb(255, 35, 35, 0.7)",
              borderRadius: "15px",
              boxSizing: "border-box",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: '50px',
            }}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="medium"
                onClick={() => setOpenAlertError(false)}
              >
                <CloseIcon
                  id={"ModalEncaminharOS-AlertCloseIcon"}
                  fontSize="inherit"
                  sx={{
                    color: "white",
                  }}
                />
              </IconButton>
            }
          >
            <span
              style={{
                color: "white",
                fontSize: "16px",
              }}
              dangerouslySetInnerHTML={{ __html: msgAlertError }}
            />
          </Alert>
        </Collapse>
      }

      {openAlertSuccess &&
        <Collapse onClose={setOpenAlertSuccess} in={openAlertSuccess}>
          <Alert
            severity='success'
            icon={<div />}
            variant="filled"
            sx={{
              mb: 2,
              bgcolor: "#178B319E",
              borderRadius: "15px",
              boxSizing: "border-box",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: '16px',
            }}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="medium"
                onClick={() => setOpenAlertSuccess(false)}
              >
                <CloseIcon
                  id={"ModalEncaminharOS-AlertCloseIcon"}
                  fontSize="inherit"
                  sx={{
                    color: "white",
                  }}
                />
              </IconButton>
            }
          >
            <span
              style={{
                color: "white",
                fontSize: "16px",
              }}
            >
              {msgAlertSuccess}
            </span>
          </Alert>
        </Collapse>
      }
    </Section>
  );
};

export default EditarUsuario;
