import axios from "axios";
import { apiGet, apiPost } from "../../../services/api";


const baseUrlOrdemServico = process.env.REACT_APP_API_ORDEMSERVICO;

const baseUrlAtendimento = process.env.REACT_APP_API_ATENDIMENTO;

const baseUrlIdentidade = process.env.REACT_APP_API_IDENTIDADE;

const token = process.env.REACT_APP_MAPA_TOKEN

export const api = axios.create();



export const getStatusOrdemServico = async () => 
  await apiGet(`${baseUrlOrdemServico}/statusOrdemServico`)