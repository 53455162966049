import { useState } from "react"
import { IConsultaModeloTabela } from "../pages/modelo/types"

export const ModeloContext = () => {
  
  const [modeloSelected, setModeloSelected] = useState<IConsultaModeloTabela>({} as IConsultaModeloTabela)

  return{
    modeloSelected,
    setModeloSelected
  }
}