import React, { useState, useContext, useEffect } from "react";
import _ from "lodash";
import Modal from "../../../components/modal";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import Collapse from '../../../components/collapse/collapse';
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import {
  Container,
  Body,
  Column,
  Row,
} from "./modalErrorsDetails_Styles";
import { AxiosResponse } from "axios";
import { siteTheme } from "../../../styles/themeVariables";
import { getLogsEncaminhamento } from "../services/services";
import { CircularProgress } from "@mui/material";

const { color } = siteTheme

interface IModalErrorsDetails {
  idOS: number,
  isModalOpen: boolean,
  handleClose: () => void
}

const ModalErrorsDetails = ({ idOS, isModalOpen, handleClose }: IModalErrorsDetails) => {
  const [isOpen, setIsOpen] = useState(isModalOpen)

  const [logsDeErro, setLogsDeErro] = useState<string[]>([]);
  const [tipomsgAlert, setTipoMsgAlert] = useState<'success' | 'info' | 'warning' | 'error'>("success");
  const [msgAlert, setMsgAlert] = useState("OS encaminhada com  sucesso!");
  const [openAlert, setOpenAlert] = useState(false);
  const [disableActions, setDisableActions] = useState(false);
  const [loading, setLoading] = useState(false);

  const requestLogsDeErro = async () => {
    setLoading(true);
    try {
      let { data } = await getLogsEncaminhamento(idOS) as AxiosResponse;
      setLogsDeErro(data)
      setLoading(false);

    } catch (e: any) {
      switch (e?.response?.status) {
        case 404:
          setLogsDeErro(['Nenhum log de erro encontrado para a OS - ' + idOS]);
          break;
        default:
          setLogsDeErro(['Erro na requisição de logs']);
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    setIsOpen(isModalOpen);
    requestLogsDeErro();
  }, [isModalOpen])

  return (
    <Modal
      handleCloseModal={handleClose}
      title={"Detalhes do erro"}
      isOpen={isOpen}
      titleStyle={{
        bgcolor: "#FFF",
        color: "#797D8C",
        fontWeight: 700
      }}
    >
      <Container>
        <Body>
          {!!loading ? (
            <CircularProgress style={{margin: "auto"}}/>
          )
            :
            <Column>
              {!!logsDeErro.length && logsDeErro.map((log: any, i) => {
                return (
                  <Row key={i}>
                    {log}
                  </Row>
                )
              })
              }
            </Column>
          }
        </Body>

        <Button
          id={"ModalEncaminharOS-BotaoEncaminhar"}
          disabled={disableActions}
          onClick={() => handleClose()}
          sx={{
            bgcolor: color.primary,
            color: "#FFF",
            "&:hover": {
              backgroundColor: color.primary,
              color: "#FFF",
            },
            borderRadius: "10px",
            minWidth: "126px",
            margin: "auto",
            fontSize: "12px",
            fontFamily: "Open Sans",
          }}
        >
          OK
        </Button>

        <Collapse
          onClose={setOpenAlert}
          in={openAlert}
          sx={{
            position: "absolute",
            left: 0,
            right: 0,
            bottom: 0,
          }}
        >
          <Alert
            severity={tipomsgAlert}
            icon={<div />}
            variant="filled"
            sx={{
              mb: 2,
              bgcolor: tipomsgAlert === "success" ? "#178B319E" : "#FF23239E",
              height: "67px",
              boxSizing: "border-box",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: 0,
              borderRadius: 0,
            }}
            action={
              <IconButton
                id={"ModalEncaminharOS-AlertCloseIcon"}
                aria-label="close"
                color="inherit"
                size="medium"
                onClick={() => setOpenAlert(false)}
              >
                <CloseIcon
                  fontSize="inherit"
                  sx={{
                    color: "white",
                  }}
                />
              </IconButton>
            }
          >
            <span
              style={{
                color: "white",
                fontSize: "14px",
              }}
              dangerouslySetInnerHTML={{ __html: msgAlert }}
            />
          </Alert>
        </Collapse>
      </Container>
    </Modal>
  );
};

export default ModalErrorsDetails;
