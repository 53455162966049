import { useContext, useEffect, useState } from "react";
import Header from "../../../components/header/header";
import { Form, Section } from "./styles";
import { HomeContext } from "../../../contexts/homeContext";
import MultipleSelectCheckmarks from '../../../components/seletorMultiploId/seletorMultiploId'
import Button from "../../../components/Button/styles";
import { CircularProgress, Grid, Stack } from "@mui/material";
import StickyTable from "../../../components/stickyTable/stickyTable";
import { useNavigate } from "react-router-dom";
import { FormikHelpers, useFormik } from "formik";
import * as yup from 'yup';
import { consultarStatusOS } from "../services";
import { AxiosResponse } from "axios";
import Pagination from "../../../components/pagination/paginacao";
import { IGetResponseOSStatus, IGetStatus } from "../types";
import { AuthContext } from "../../../contexts/auth";
import { smoothScroll } from "../../../helpers/smoothScroll";

const consultServiceOrderStatusSchema = yup.object({
    status: yup.string().required(),
    /*comportamento: yup.string().required()*/
})

type ConsultServiceOrderStatusValues = yup.InferType<typeof consultServiceOrderStatusSchema>
const CONSULT_SERVICE_ORDER_STATUS_INITIAL_VALUES: ConsultServiceOrderStatusValues = {
    status: '',
    /*comportamento: '',*/
}

export function ConsultServiceOrderStatusPage() {
    const { setMenuLateral, setOrdemServicoStatusSelecionado } = useContext(HomeContext);
    const [loading, setLoading] = useState(false);
    const [dadosConsultaStatusOS, setDadosConsultaStatusOS] = useState<any[]>([]);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);
    const [qtdeRegistros, setQtdeRegistros] = useState<number>(0);
    const [openAlertError, setOpenAlertError] = useState(false);
    const [msgAlertError, setMsgAlertError] = useState("Nenhum dado encontrado");
    const [disableActions, setDisableActions] = useState(false);

    const [listaStatus, setListaStatus] = useState<{ name: string, id: string }[]>([
        { name: '--', id: '' },
        { name: 'Ativo', id: '0' },
        { name: 'Inativo', id: '1' },
    ]);

    const [filtroStatus, setFiltroStatus] = useState<string[]>(['']);

    const navigate = useNavigate();

    const { funcionalidadeDaTelaTemPermissao } = useContext(AuthContext);
    const profileHasPermission = (funcionalidade: string) => funcionalidadeDaTelaTemPermissao("Consultar Status de OS", funcionalidade);

    useEffect(() => {
        setOrdemServicoStatusSelecionado({} as IGetResponseOSStatus)
    }, [])

    useEffect(() => {
        if (!!dadosConsultaStatusOS.length) smoothScroll(document.getElementById('check-status-os-table'));
    }, [dadosConsultaStatusOS])

    const handleData = async () => {
        (currentPage !== 1) ? setCurrentPage(1) : handleGetStatusOS();
    }

    const handleGetStatusOS = async () => {
        setLoading(true);
        setOpenAlertError(false);
        setDisableActions(true);

        const statusBool = filtroStatus[0] === '' ? '' : filtroStatus[0] === '0' ? true : false;

        const params: IGetStatus = {
            ...(statusBool !== '' ? { ativo: statusBool } : {}),
        }

        try {
            const { data }: { data: IGetResponseOSStatus } = await consultarStatusOS(params) as AxiosResponse
            setLoading(false)

            setQtdeRegistros(data.data?.length ?? 0)
            setDadosConsultaStatusOS(data.data ?? [])

        } catch (error: any) {
            setLoading(false);
            setDisableActions(false);
            setDadosConsultaStatusOS([])
            setQtdeRegistros(0);
            setMsgAlertError("Nenhum dado encontrado");
            setOpenAlertError(true);
        }
    }

    const paginacaoLocal = (dados: any[]) => {
        const firstPageIndex = (currentPage - 1) * pageSize;
        const lastPageIndex = firstPageIndex + pageSize;
        return dados.slice(firstPageIndex, lastPageIndex);
    };

    const formatarDados = (dados: any[]) => {
        let dataStatus = [...dados]
        dataStatus = dataStatus.map((item: any) => {
            return {
                idStatus: item.id,
                nome: item.nome,
                idOppay: item.idOppay,
                ativo: !!item.ativo ? "Ativo" : "Inativo",
            }
        })

        return paginacaoLocal(dataStatus);
    }

    const handleClickItemEdit = (data: any) => {
        setOrdemServicoStatusSelecionado(getItemByID(data.idStatus));
        navigate("/EditarStatusOS");
    };

    const getItemByID = (ID: number | string) => {
        let resultadoDados = [...dadosConsultaStatusOS];
        return resultadoDados.find(
            (item) => item.id === ID
        )!;
    };

    async function handleFormikSubmit(values: ConsultServiceOrderStatusValues, actions: FormikHelpers<ConsultServiceOrderStatusValues>) {
        await new Promise((resolve) => setTimeout(resolve, 5000))
    }

    const formik = useFormik({
        initialValues: CONSULT_SERVICE_ORDER_STATUS_INITIAL_VALUES,
        validationSchema: consultServiceOrderStatusSchema,
        onSubmit: handleFormikSubmit
    });

    return <Section id={"ConsultarStatusOS"}>
        <Header
            setMenuLateral={() => setMenuLateral(true)}
            title="Consultar Status de Ordem de Serviço"
        />
        <Stack width="100%" bgcolor="#F9F9F9" px="1.625rem" py="2.625rem" flexDirection="column" rowGap="1rem" borderRadius="1.75rem">
            <Form onSubmit={formik.handleSubmit}>
                <Grid container width="100%" spacing={4}>
                    <Grid item xs={12} md={4}>
                        <MultipleSelectCheckmarks
                            nodata={"Nenhum status encontrado"}
                            options={listaStatus}
                            value={filtroStatus}
                            onChange={(value) => setFiltroStatus(value)}
                            id="multiple-checkbox-status"
                            idOption="multiple-checkbox-option-Status"
                            labelId="multiple-checkbox-label-Status"
                            multiple={false}
                            dimension='sm'
                            name="status"
                            label='Status'
                            error={!!formik.errors.status && formik.touched.status}
                        />
                    </Grid>
                    {/*<Grid item xs={12} md={8}>*/}
                    {/*    <MultipleSelectCheckmarks*/}
                    {/*        nodata={"Nenhum comportamento encontrado"}*/}
                    {/*        options={STATUS_OPTIONS}*/}
                    {/*        value={[formik.values.comportamento]}*/}
                    {/*        onChange={(e) => formik.setFieldValue('comportamento', e[0])}*/}
                    {/*        id="multiple-checkbox-comportamento"*/}
                    {/*        idOption="multiple-checkbox-option-Comportamento"*/}
                    {/*        labelId="multiple-checkbox-label-Comportamento"*/}
                    {/*        multiple={false}*/}
                    {/*        dimension='sm'*/}
                    {/*        name="comportamento"*/}
                    {/*        label='Comportamento'*/}
                    {/*        error={!!formik.errors.comportamento && formik.touched.comportamento}*/}
                    {/*    />*/}
                    {/*</Grid>*/}
                </Grid>
                <Stack direction="row" columnGap="1.5rem">
                    <Button
                        type="button"
                        dimension='sm'
                        variant='grey'
                        margin='0'
                        onClick={() => navigate('/IncluirStatusOS')}
                    >
                        Incluir status
                    </Button>
                    <Button
                        type="button"
                        dimension='sm'
                        variant='primary'
                        margin='0'
                        onClick={handleData}
                    >
                        Buscar
                    </Button>
                </Stack>
            </Form>
        </Stack>

        {!!loading && <CircularProgress style={{ margin: '0px auto' }} />}

        {!!dadosConsultaStatusOS.length && (
            <>
                <StickyTable
                    id={"check-status-os-table"}
                    data={formatarDados(dadosConsultaStatusOS)}
                    handleClickEdit={handleClickItemEdit}
                    objTraducaoHeader={{
                        idStatus: 'ID Status',
                        status: 'Status',
                        nome: 'Nome',
                        behavior: 'Comportamento',
                        ativo: 'Ativo',
                        idOppay: 'IdOppay'
                    }}
                    edit
                    allowEdit={!!profileHasPermission("Editar registro")}
                />
                <Pagination
                    className="pagination-bar"
                    currentPage={currentPage}
                    totalCount={qtdeRegistros}
                    pageSize={pageSize}
                    setPageSize={(value: string) => setPageSize(Number(value))}
                    onPageChange={(page: number) => setCurrentPage(Number(page))}
                    showLabel={false}
                />
            </>
        )}
    </Section>
}