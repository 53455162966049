import { apiGet, apiPut } from "../../../services/api";
import _ from 'lodash'
import { IPutEquipamentoModelo } from "../types";

const baseUrl = process.env.REACT_APP_API_ESTOQUE

export const getEquipamentoModelo = (
  idFamilia: number | null, 
  idTipo: number | null, 
  descricao: string | null, 
  ativo: boolean | null,
  pageNumber: number,
  pageSize: number
) => {
  let Filtro: string = ''

  if(idFamilia) Filtro = `?IdFamilia=${idFamilia}`
  if(idTipo) Filtro = _.isEmpty(Filtro) ? `?IdTipo=${idTipo}` : `${Filtro}&IdTipo=${idTipo}`
  if(descricao) Filtro = _.isEmpty(Filtro) ? `?Filtro.Descricao=${descricao}` : `${Filtro}&Filtro.Descricao=${descricao}`
  if(!_.isNull(ativo)) Filtro = _.isEmpty(Filtro) ? `?Filtro.Ativo=${ativo}` : `${Filtro}&Filtro.Ativo=${ativo}`
  Filtro = _.isEmpty(Filtro) ? `?PageNumber=${pageNumber}&PageSize=${pageSize}` : `${Filtro}&PageNumber=${pageNumber}&PageSize=${pageSize}`
  return apiGet(`${baseUrl}/Equipamento/equipamento-modelo${Filtro}`)
}

export const putEquipamentoModelo = async (modelo: IPutEquipamentoModelo) =>
  await apiPut(`${baseUrl}/Equipamento/atualizar-detalhes-modelo`, modelo)