import styled from "styled-components";
import { Button } from "@mui/material";

export const Section = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
`;

export const SearchButton = styled(Button)`
  font-size: 14px !important;
  height: 44px !important;
  border-radius: 15px !important;
  padding: 0 40px 0 40px !important;
`;

export const ButtonsBox = styled.div`
  display: grid;
  grid-template-columns: 150px 150px 1fr;
  gap: 20px;
  -webkit-box-align: baseline;
  align-items: end;

  @media only screen and (max-width: 1000px) {
    grid-template-columns: 1fr;
    gap: 16px;
  }
`;

export const NewUserButton = styled(Button)`
  background: #AFB1B1 !important;
  color: #FFF !important;
  font-size: 14px !important;
  height: 44px !important;
  border-radius: 15px !important;

  &.bloqueado {
    cursor: not-allowed !important;
    pointer-events: auto !important;
    opacity: 0.5;
  }
`;

export const Body = styled.div`
  margin-top: 30px;

  .grid1 {
    display: grid;
    grid-template-columns:  1.5fr 2fr 2fr 1fr;
    gap: 20px;
    margin-bottom: 44px;
    -webkit-box-align: baseline;
    align-items: end;
  }

  @media only screen and (max-width: 1500px) {
    .grid1 {
      grid-template-columns:  1fr 1.5fr 1fr 1fr 1fr;
    }
  }

  @media only screen and (max-width: 1000px) {
    grid-template-columns: 1fr;

    .grid1, .grid2 {
      grid-template-columns: 1fr;
      gap: 16px;
    }
  }
`;