import { useState } from "react";
import { IGetResponseContratanteServicoParametro } from "../pages/parametro/types";

export const ContratanteServicoParametroContext = () => {
  const [contratanteServicoParametroSelecionado, setContratanteServicoParametroSelecionado] = useState({} as IGetResponseContratanteServicoParametro);

  return {
    contratanteServicoParametroSelecionado: contratanteServicoParametroSelecionado,
    setContratanteServicoParametroSelecionado: setContratanteServicoParametroSelecionado
  }
};