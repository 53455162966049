import Form, { IChangeEvent } from "@rjsf/core";
import React, { useState } from "react";
import { RJSFSchema, UiSchema } from "@rjsf/utils";
import "./dynamicForm.scss";
import { AxiosResponse } from "axios";
import validator from "@rjsf/validator-ajv8";
import {
  apiDelete,
  apiGet,
  apiPost,
  apiPut,
} from "../../services/api";
import FullscreenLoading from "../fullscreenLoading/fullscreenLoading";

export enum HttpMethod {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  DELETE = "DELETE",
}

interface DynamicFormProps {
  id?: string;
  schema?: RJSFSchema | null;
  uiSchema?: UiSchema | null;
  method: HttpMethod;
  endpoint: string;
  buttonSendName?: string | null;
}

interface ResponseForm {
  id?: string;
  response: AxiosResponse;
}

const DynamicForm: React.FC<
  DynamicFormProps & { onResponse?: (response: ResponseForm) => void }
> = ({
  id,
  schema,
  uiSchema,
  method = HttpMethod.POST,
  endpoint,
  buttonSendName = "Enviar",
  onResponse,
}) => {
  const [loading, setLoading] = useState(false);
  const log = (type: any) => console.log.bind(console, type);

  const onSubmit = async (data: IChangeEvent, event: React.FormEvent) => {
    event.preventDefault();

    const formData = data.formData;

    try {
      setLoading(true);

      let response;
      switch (method) {
        case HttpMethod.POST:
          response = (await apiPost(endpoint, formData)) as AxiosResponse;
          break;
        case HttpMethod.GET:
          response = (await apiGet(endpoint, formData)) as AxiosResponse;
          break;
        case HttpMethod.PUT:
          response = (await apiPut(endpoint, formData)) as AxiosResponse;
          break;
        case HttpMethod.DELETE:
          response = (await apiDelete(endpoint, formData)) as AxiosResponse;
          break;
        default:
          throw new Error(`Método HTTP não suportado: ${method}`);
      }

      if (onResponse) {
        onResponse({
          id,
          response,
        });
      }

      if (typeof response === "object" && response !== null) {
        if ("error" in response) {
          log("errors")(response.error);
        } else if ("data" in response) {
          log("submitted")(response.data);
        }
      } else {
        log("errors")("A resposta não é um objeto");
      }
    } catch (error) {
      log("errors")(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      {loading && <FullscreenLoading />}
      <Form
        schema={schema ?? {}}
        uiSchema={uiSchema ?? {}}
        validator={validator}
        onSubmit={onSubmit}
        onError={log("errors")}
      >
        <div className="buttons-form">
          <button className="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeSmall MuiButton-containedSizeSmall MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeSmall MuiButton-containedSizeSmall sc-bBqzUu fviexh css-kq7b3u-MuiButtonBase-root-MuiButton-root" type="submit">{buttonSendName}</button>
        </div>
      </Form>
    </div>
  );
};

export default DynamicForm;
