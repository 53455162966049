import { apiGet, apiPost, apiPut } from "../../../services/api";
import { IDevolveEquipamento, IPostCustodia, IGETStatusCustodia, IGETUsuarios, IPutCustodia, IGetCustodia, IGetAuditoria } from "../types";

const baseUrl = process.env.REACT_APP_API_ATENDIMENTO;
const baseUrlEstoque = process.env.REACT_APP_API_ESTOQUE;
const baseUrlOrdemServico = process.env.REACT_APP_API_ORDEMSERVICO;

export const getListaTecnicos = async () =>
    await apiGet(`${baseUrl}/Tecnico`, {});

export const getPrestadores = async () => 
  await apiGet(`${baseUrl}/Prestador`, {});

export const postDevolveEquipamento = async (params: IDevolveEquipamento) => 
  await apiPost(`${baseUrlEstoque}/Equipamento/devolve-equipamento`, params);

export const getDefeitos = async () =>
  await apiGet(`${baseUrlEstoque}/Equipamento/defeito`);

export const getEstoqueTecnico = async (params: {idPrestador: string, idTecnico: string, idContratante: string}) => 
  await apiGet(`${baseUrlEstoque}/Estoque/estoque-tecnico`, params);

export const getStatusCustodias = async (params?: IGETStatusCustodia) =>
  await apiGet(`${baseUrlEstoque}/custodia-status`, params);

export const getCustodias = async (params: IPostCustodia) =>
  await apiPost(`${baseUrlEstoque}/custodia/obter-custodias`, params);

export const exportCustodias = async (params: IPostCustodia) =>
  await apiPost(`${baseUrlEstoque}/custodia/extracao`, params);

export const getUsuariosOrdemServico = async (params?: IGETUsuarios) =>
  await apiGet(`${baseUrlOrdemServico}/usuarios`, params);

export const getCustodia = async (params: IGetCustodia) =>
  await apiGet(`${baseUrlEstoque}/custodia/${params.id}`, {});

export const putCustodias = async (params: IPutCustodia) =>
  await apiPut(`${baseUrlEstoque}/custodia/${params.id}`, params);

export const getAuditoria = async (params: IGetAuditoria) =>
  await apiGet(`${baseUrlEstoque}/auditoria`, params);