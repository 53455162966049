import React, { ReactComponentElement, ReactElement, useContext } from "react";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ArrowDown } from "./consultarOS_styles";

const AccordionCategoriaConsultarOS = ({
  id = 'AccordionCategoriaConsultarOS',
  title = "Title",
  children,
  defaultExpanded = false,
}: {
  id?: string
  title?: string
  children?: any
  defaultExpanded?: boolean
}) => {

  return (
    <Accordion
      defaultExpanded={defaultExpanded}
      id={id}
      sx={{
        background: '#FFF',
        borderRadius: '15px !important',
        border: '1px solid #AFB1B1',
        padding: '0 10px',
        marginBottom: '10px',
        '& .MuiAccordionSummary-root': {
          padding: '0 0 0 30px !important',
          minHeight: '50px',
          color: '#797D8C',
          fontWeight: 600,
          '& .MuiAccordionSummary-content': {
            margin: 0,
            '&.Mui-expanded': {
              margin: 0,
            },
          },
        },
        '& .MuiAccordionDetails-root': {
          padding: '20px 30px',
        }
      }}
    >
      <AccordionSummary
        expandIcon={<ArrowDown />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        {title}
      </AccordionSummary>
      <AccordionDetails>
        {children}
      </AccordionDetails>
    </Accordion>
  );
};

export default AccordionCategoriaConsultarOS;
