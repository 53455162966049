import React, { useContext, useEffect, useState } from "react";
import { Link as RouterLink } from 'react-router-dom';
import MobyanLogo from "../../assets/images/MobyanLogo.svg";
import { ReactComponent as DashboardIcon } from "../../assets/icons/DashboardIcon.svg";
import { ReactComponent as FarolIcon } from "../../assets/icons/FarolIcon.svg";
import { ReactComponent as TecnicoIcon } from "../../assets/icons/TecnicoIcon.svg";
import { ReactComponent as SairIcon } from "../../assets/icons/SairIcon.svg";
import { AuthContext } from "../../contexts/auth";
import { ReactComponent as Seta } from "../../assets/icons/Seta.svg";
import { ContainerMenu, Wrapper, Flexdiv, BoxLogo, StackStyled, ButtonMenu, LogoImage } from './styles';
import { HomeContext } from "../../contexts/homeContext";
import MenuLateralExpandOption from "./menuLateral_expandOption";
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import { ManageSearch } from "@mui/icons-material";
import { Stack } from "@mui/material";
import { title } from "process";


const HomeMenuLateral = () => {
  const { logout, getTelaTemAcesso } = useContext(AuthContext);
  const { menuLateral, setMenuLateral } = useContext(HomeContext);

  const [isMenuOpen, setIsMenuOpen] = useState(menuLateral);
  const [menu, setMenu] = useState([]);

  const handleLoggout = () => {
    logout();
  };

  const estruturaMenu = [
    {
      title: 'Dashboard',
      icon: <DashboardIcon />,
      link: '/Dashboard',
    },
    {
      title: "Farol de OS's",
      icon: <FarolIcon />,
      link: '/FarolOS',
    },
    {
      title: 'Técnico',
      icon: <TecnicoIcon />,
      subMenu: [
        {
          title: 'Consultar',
          icon: <Seta />,
          link: '/ConsultaTecnico',
        },
        {
          title: 'Custódias',
          icon: <Seta />,
          link: '/EstoqueTecnico',
        },
      ]
    },
    {
      title: 'Ordem de Serviço',
      icon: <ContentPasteSearchIcon />,
      subMenu: [
        {
          title: 'Consultar',
          icon: <Seta />,
          link: '/ConsultaOS',
        },
        {
          title: 'Consultar Status',
          icon: <Seta />,
          link: '/ConsultarStatusOS'
        }
      ]
    },
    {
      title: 'Gestão de Paramêtros',
      icon: <ManageSearch />,
      subMenu: [
        {
          title: 'Insucesso',
          icon: <Seta />,
          link: '/ParametroInsucesso',
        },
        // {
        //   title: 'Metas de Produtividade',
        //   icon: <Seta />,
        //   link: '/MetasProdutividade',
        // },
        /**Menu Metas de Produtividade deve levar para tela de consuta quando ela subir */
        {
          title: 'Metas de Produtividade',
          icon: <Seta />,
          link: '/IncluirMetasProdutividade',
        },
      ]
    },
    {
      title: 'Parâmetros do Contratante',
      icon: <ManageSearch />,
      subMenu: [
        {
          title: 'Termo de OS',
          icon: <Seta />,
          link: '/ConsultarTermoOs',
        },
      ]
    },
    {
      title: 'Cadastros',
      icon: <AppRegistrationIcon />,
      subMenu: [
        {
          title: "Equipamento",
          icon: <Seta />,
          link: '/Equipamento',
        },
        {
          title: 'Família',
          icon: <Seta />,
          link: '/ConsultaFamilia',
        },
        {
          title: 'Modelo',
          icon: <Seta />,
          link: '/Modelo',
        },
        {
          title: 'Perfil',
          icon: <Seta />,
          link: '/ConsultarPerfil',
        },
        {
          title: 'Prestador',
          icon: <Seta />,
          link: '/ConsultaPrestador',
        },
        {
          title: 'Prestador Responsável',
          icon: <Seta />,
          link: '/ConsultaPrestadorResponsavel',
        },
        {
          title: 'Usuário',
          icon: <Seta />,
          link: '/ConsultaUsuario',
        },
        {
          title: 'Grupo de Serviço',
          icon: <Seta />,
          link: '/ConsultaGrupoServico',
        },
        {
          title: 'Serviços',
          icon: <Seta />,
          link: '/Servicos',
        },
        // {
        //   title: "Justificativa de Abono",
        //   icon: <Seta />,
        //   link: '/ConsultarJustificativaAbono',
        // },
        {
          title: "Parâmetros",
          icon: <Seta />,
          link: '/ConsultarParametros',
        },
        {
          title:"Agrupamento de Modelos",
          icon: <Seta />,
          link: '/ConsultarAgrupamentoModelo'
        }
      ]
    },
    {
      title: 'Cadastros de Sistema',
      icon: <AppRegistrationIcon />,
      subMenu: [
        {
          title: 'Estados',
          icon: <Seta />,
          link: '/ConsultarEstados'
        },
        {
          title: 'Regiões',
          icon: <Seta />,
          link: '/ConsultarRegioes',
        },
        {
          title: 'Cidades',
          icon: <Seta />,
          link: '/ConsultarCidades',
        },
      ]
    },
    {
      title: 'Checklist',
      icon: <AppRegistrationIcon />,
      subMenu: [
        {
          title: 'Gestão de Checklist',
          icon: <Seta />,
          link: '/ConsultarChecklist'
        },
        {
          title: 'Parâmetro de Itens',
          icon: <Seta />,
          link: '/ConsultarParametroItem'
        },
      ]
    }
  ];

  const mountMenu = () => {
    let mountmenu: any = estruturaMenu.map((item: any) => {

      if (!item.subMenu) {
        /*Link Direto*/
        if (!!getTelaTemAcesso(item.link)) {
          return (
            { ...item }
          );
        }

        return false;
      } else {
        /*Grupo de links*/
        let grupotemalgumlinkcomacesso = item.subMenu.filter((subm: any) => !!getTelaTemAcesso(subm.link));

        if (!!grupotemalgumlinkcomacesso.length) {
          return (
            {
              ...item,
              subMenu: item.subMenu.map((sub: any) => {
                if (!!getTelaTemAcesso(sub.link)) {
                  return (
                    { ...sub }
                  );
                }

                return false;
              })
            }
          );
        }

        return false;
      }
    });

    setMenu(mountmenu);
  };

  useEffect(() => {
    setIsMenuOpen(menuLateral);
    mountMenu();
  }, [menuLateral])

  return (
    <ContainerMenu displaySidebar={isMenuOpen}>
      <Wrapper>
        <Flexdiv>
          <BoxLogo>
            <LogoImage src={MobyanLogo} />
          </BoxLogo>

          <StackStyled spacing={2}>
            {menu.map((item: any) => {
              if (!!item) {

                if (!!item.subMenu) {
                  let isActiveMenu = !!item.subMenu.find((sub: any) => sub.link === window.location.pathname);

                  return (
                    <MenuLateralExpandOption key={item.title} item={item} isActiveMenu={isActiveMenu} />
                  );
                }

                return (
                  <RouterLink id={item.link} key={item.link} to={item.link}>
                    <ButtonMenu
                      variant="contained"
                      startIcon={item.icon}
                      className={`${window.location.pathname === item.link ? "isActive" : ''}`}
                      onClick={() => setMenuLateral(false)}
                    >
                      {item.title}
                    </ButtonMenu>
                  </RouterLink>
                )
              }
            })}
          </StackStyled>

          <Stack width="100%" marginTop="auto">
            <ButtonMenu
              variant="contained"
              size="small"
              startIcon={<SairIcon />}
              sx={{ marginTop: 'auto' }}
              onClick={handleLoggout}
            >
              Sair
            </ButtonMenu>
          </Stack>
        </Flexdiv>
      </Wrapper>
    </ContainerMenu >
  );
};

export default HomeMenuLateral;
