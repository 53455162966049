import { apiGet, apiPost, apiPut } from "../../../services/api";
import { IExportCSVServicos, IGetServicos, IPostServico, IPutServico } from "../types";

const baseUrlOrdemServico = process.env.REACT_APP_API_ORDEMSERVICO;

export const getListaServicosComFiltro = async (params: IGetServicos) =>
  await apiGet(`${baseUrlOrdemServico}/Servico_`, params);

export const exportCSV = async (params: IExportCSVServicos) =>
  await apiPost(`${baseUrlOrdemServico}/Servico_/extracao/servicos`, params);

export const postServico = async (params: IPostServico) =>
  await apiPost(`${baseUrlOrdemServico}/Servico_`, params)

export const putServico = async (params: IPutServico, idServico: number | string) =>
  await apiPut(`${baseUrlOrdemServico}/Servico_/${idServico}`, params)
