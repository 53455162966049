import moment from "moment";

export const arrayToQueryString = (key: string, values: number[]): string => {
    const queryStringValues = values.map(value => encodeURIComponent(value.toString())).join(',');
    return `${key}=${queryStringValues}`;
}

export const arrayToQuery = (key: string, values: any[]): string => {
    return values.map((item) => `${encodeURIComponent(key)}=${encodeURIComponent(item)}`).join('&');
};

export interface IObjToQueryStringConfig {
    format?: string;
}

export const objToQueryString = (obj: Record<string, any>, config?: IObjToQueryStringConfig): string => {
    return Object.keys(obj)
        .map(key => {
            const value = obj[key];

            if (Array.isArray(value)) {
                return arrayToQuery(key, value);
            } else if (value instanceof Date) {
                const format = (config && config?.format) ? config?.format : "YYYY-MM-DDTHH:mm:ss";

                console.log(format, config);
                return `${encodeURIComponent(key)}=${encodeURIComponent(moment(value).format(format))}`;
            } else {
                return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
            }
        })
        .join('&');
};

export function arrayRemoveProp(arr: any, prop: string) {
    return arr.map(({ [prop]: _, ...rest }) => rest);
}
