import styled from "styled-components";
import AddIcon from '@mui/icons-material/Add';

export const AddIconStyled = styled(AddIcon)`
  margin: 0 auto;
  width: 35px !important;
  height: 35px !important;
  cursor: pointer;

  path {
      fill: #294033;
    }
`;

export const Container = styled.div`
  padding: 24px 48px;
  gap: 16px;
  display: flex;
  flex-direction: column;
  width: 80vw;
`;

export const GridScroll = styled.div`
  max-height: 280px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-right: -15px;
  padding-right: 7px;
  padding-bottom: 5px;
  scrollbar-gutter: stable;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-button:single-button {
    background-color: #AFB1B1;
    display: block;
    height: 18px;
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #AFB1B1;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;

  &.one {
    grid-template-columns: 1fr;
  }
  &.three {
    grid-template-columns: 1fr 70px 1fr 1fr;
    margin-bottom: 16px;
  }
`;

export const GridLabelTop = styled.div`
  font-size: 0.8rem;
  font-weight: 500;
  font-style: italic;
`;

export const GridLabel = styled.div`
  font-size: 1.25rem;
  font-weight: 600;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;
