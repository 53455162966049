import React, { useContext } from "react";
import {
  Section,
  Body,
  Label1,
  Label2,
  ButtonStyled,
} from "./acessoNegado_styles";
import Header from "../../../components/header/header";
import { HomeContext } from "../../../contexts/homeContext";
import { useNavigate } from "react-router-dom";

const AcessoNegado = () => {
  const { setMenuLateral } = useContext(HomeContext);
  const navigate = useNavigate();

  return (
    <Section id={"AcessoNegado"}>
      <Header
        setMenuLateral={() => setMenuLateral(true)}
        title=""
      />
      <Body>
        <Label1>Acesso negado</Label1>
        <Label2>Infelizmente você não tem acesso a essa página ou a página não existe.</Label2>
       
        <ButtonStyled
          id={"SearchButtonButton"}
          variant="contained"
          size="small"
          onClick={() => navigate("/")}
        >
          Página inicial
        </ButtonStyled>
      </Body>
    </Section>
  );
};

export default AcessoNegado;
