import React, { FormEventHandler, useState } from 'react'
import Modal from '../../../components/modal'
import Button from '../../../components/Button/styles';
import Input from '../../../components/Input/Input'
import { useEffect } from 'react';
import { BodyContainer, StyledTableRow } from './anotacoesModalStyles';
import * as Styles from "../../../components/table/styles";
import { Table, TableContainer } from '@mui/material';
import { Alert, IconButton } from '@mui/material';
import Collapse from '../../../components/collapse/collapse';
import CloseIcon from "@mui/icons-material/Close";
import moment from 'moment';

interface IAnotacoesModal {
  isModalOpen: boolean,
  handleCloseModal: () => void,
  text: string[]
  setText: Function
}

const AnotacoesModal = ({
  isModalOpen,
  handleCloseModal,
  text,
  setText
}: IAnotacoesModal) => {

  const [isOpen, setIsOpen] = useState(isModalOpen)
  const [marcacao, setMarcacao] = useState('')
  const [tipomsgAlert, setTipoMsgAlert] = useState<'success' | 'info' | 'warning' | 'error'>("success");
  const [msgAlert, setMsgAlert] = useState("OS encaminhada com  sucesso!");
  const [openAlert, setOpenAlert] = useState(false);

  const handleIncluir = (e: any) => {
    e.preventDefault()
    if (marcacao != '') {
      let ordMarcacao = [...text, `${moment().format('DD/MM/YYYY - HH:mm')} - ${marcacao}`]
        .sort((a, b) =>
          (moment(a.slice(0, 18), 'DD/MM/YYYY HHmm').toDate() > moment(b.slice(0, 18), 'DD/MM/YYYY HHmm').toDate()) ?
            1 :
            ((moment(b.slice(0, 18), 'DD/MM/YYYY HHmm').toDate()) > moment(a.slice(0, 18), 'DD/MM/YYYY HHmm').toDate()) ? -1 : 0)
      setText(ordMarcacao)
      setMarcacao('')
      setTipoMsgAlert('success')
      setMsgAlert('Anotação incluída com sucesso.')
      setOpenAlert(true)
      return
    }
  }

  useEffect(() => {
    setIsOpen(isModalOpen)
  }, [isModalOpen])

  return (
    <Modal
      handleCloseModal={handleCloseModal}
      title='Marcações'
      isOpen={isOpen}
      titleStyle={{
        m: 0,
        p: 2,
        padding: "16px 22px",
        boxSizing: "border-box",
        height: "67px",
      }}
    >
      <BodyContainer className='BodyContainer'>
        <span>
          Marcações anteriores
        </span>
        <TableContainer component={Styles.StyledPaper}>
          <Table aria-label="customized table">
            {text.map(row => {
              return (
                <StyledTableRow key={`${row}`}>
                  <Styles.StyledTableCell>
                    {row}
                  </Styles.StyledTableCell>
                </StyledTableRow>
              )
            })}
          </Table>
        </TableContainer>
        <form onSubmit={handleIncluir}>
          <Input
            dimension="sm"
            label='Incluir nova marcação'
            name='marcacao'
            id='marcacao'
            value={marcacao}
            onChange={e => setMarcacao(e.target.value)}
            maxLength={200}
          />
          <div className='ButtonContainer'>
            <Button type='submit'>Incluir</Button>
          </div>
        </form>
        <Collapse
          in={openAlert}
          onClose={setOpenAlert}
          sx={{
            position: "absolute",
            left: 0,
            right: 0,
            bottom: 0,
          }}
        >
          <Alert
            severity={tipomsgAlert}
            icon={<div />}
            variant="filled"
            sx={{
              mb: 2,
              bgcolor: tipomsgAlert === "success" ? "#178B319E" : "rgb(255, 35, 35, 0.7)",
              height: "fit-content",
              boxSizing: "border-box",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: 0,
              borderRadius: 0,
            }}

            action={
              <IconButton
                id={"ModalEncaminharOS-AlertCloseIcon"}
                aria-label="close"
                color="inherit"
                size="medium"
                onClick={() => setOpenAlert(false)}
              >
                <CloseIcon
                  fontSize="inherit"
                  sx={{
                    color: "white",
                  }}
                />
              </IconButton>
            }
          >
            <span
              style={{
                color: "white",
                fontSize: "16px",
              }}
            >
              {msgAlert}
            </span>
          </Alert>
        </Collapse>
      </BodyContainer>
    </Modal>
  )
}

export default AnotacoesModal