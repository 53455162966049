import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as yup from "yup";
import "./login.scss";
import LoginHeader from "./loginHeader";
import { AuthContext } from "../../../contexts/auth";
import { AxiosError, AxiosResponse } from "axios";
import FullscreenLoading from "../../../components/fullscreenLoading/fullscreenLoading";
import { Button, Box } from "@mui/material";
import { IPostUserLoginResponse } from "../types/types";
import { useNavigate } from "react-router-dom";

interface ILogin {
  login: string,
  password: string
}

const Login = () => {
  const { login, handleSetUserData } = useContext(AuthContext);
  const navigate = useNavigate();

  const [userNotFound, setUserNotFound] = useState('');
  const [passwordNotFound, setPasswordNotFound] = useState('');
  const [loading, setLoading] = useState(false);

  const handleClickLogin = async (values: ILogin) => {
    setLoading(true)
    await login(values.login, values.password)
      .then((response: { successo: AxiosResponse | null, erro: AxiosError | null }) => {
        const { successo, erro } = response;

        if (!!successo) {
          const { data }: { data: IPostUserLoginResponse } = successo;
          if (!!data.token2FA) {
            navigate("/Verify2FA");
          } else {
            handleSetUserData({ ...data, username: (values.login || "") });
          }
        }

        if (!!erro) {
          //@ts-ignore
          const mensagem = erro.response?.data?.mensagem ?? "Login ou senha inválidos.";
          setPasswordNotFound(mensagem);
        }
      });
    setLoading(false)
  };

  const validationLogin = yup.object().shape({
    login: yup.string().required("Este campo é obrigatório"),
    password: yup.string().required("Este campo é obrigatório"),
  });

  return (
    <div className="Login" id="Login">
      <LoginHeader />
      {loading && <FullscreenLoading />}

      <div className="LoginBox">
        <div className="LoginBoxWrapper">
          <div className="LoginBox-texto">Entrar</div>

          <Formik
            initialValues={{
              login: "",
              password: "",
            }}
            onSubmit={handleClickLogin}
            validationSchema={validationLogin}
          >
            <Form className="login-form">
              <div className="login-form-group">
                <div className="login-form-group-texto">Login</div>
                <Field
                  id="login-form-inputlogin"
                  name="login"
                  className="form-field"
                  placeholder=""
                  onFocus={() => setUserNotFound('')}
                />
                <ErrorMessage
                  component="span"
                  name="login"
                  className="form-error"
                />
                {!!userNotFound && (
                  <span
                    id="form-error-login-usernotfound"
                    className="form-error"
                  >
                    {userNotFound}
                  </span>
                )}
              </div>

              <div className="login-form-group">
                <div className="login-form-group-texto">Senha</div>

                <Field
                  id="login-form-inputpassword"
                  name="password"
                  type="password"
                  className="form-field"
                  placeholder=""
                  onFocus={() => setPasswordNotFound('')}
                />
                <ErrorMessage
                  component="span"
                  name="password"
                  className="form-error"
                />
                {!!passwordNotFound && (
                  <span
                    id="form-error-login-passwordNotFound"
                    className="form-error"
                  >
                    {passwordNotFound}
                  </span>
                )}
              </div>
              <div className="login-form-opcoes">
                <Box sx={{ width: 1 }} display="flex" justifyContent="flex-end">
                  <Link to="/RedefinirSenha">
                    <Button
                      size="small"
                      variant="text"
                    >
                      Esqueci minha senha
                    </Button>
                  </Link>
                </Box>
              </div>
              <button
                id="buttonlogin"
                className="login-form-button"
                type="submit"
              >
                Login
              </button>
            </Form>
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default Login;
