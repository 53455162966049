import _ from "lodash";
import moment from 'moment'
import { FormatDateToApi } from "../helpers/formatDate";
import { IOrdemServico } from "./types/farolTypes";
import { IDashboardContext } from './types/dashboardTypes'
import { removeSpecialCharSimple } from "../helpers/removeSpecialCharSimple";

export const DashboardContext = ( listaos: IOrdemServico[], filtroFarol: string ) => {
 
  const listaOSFiltradaDashboard = () => {
    let resultadoDados = listaos;

    if (filtroFarol === "TotalOSVencidas") {
      resultadoDados = resultadoDados.filter(
        (item) => FormatDateToApi() > FormatDateToApi(item.previsaoAtendimento)
      );
      
    }

    if (filtroFarol === "TotalOSVencemHoje") {
      resultadoDados = resultadoDados.filter(
        (item) =>  FormatDateToApi() === FormatDateToApi(item.previsaoAtendimento)
      );
    }

    if (filtroFarol === "TotalOSAVencer") {
      resultadoDados = resultadoDados.filter(
        (item) =>  FormatDateToApi() < FormatDateToApi(item.previsaoAtendimento)
      );
    }

    return _.sortBy(resultadoDados, ["previsaoAtendimento"]);
  };

  const dataGraficoBarra = listaOSFiltradaDashboard().reduce(
    (newArray: {name: string, value: number}[], item: IOrdemServico) => {
      const { status } = item;

      if (!newArray.find((item: {name: string, value: number}) => item.name === status)) {
        newArray.push({
          name: status,
          value: 1,
        });

        return newArray;
      }

      newArray = newArray.map((item: {name: string, value: number}) => {
        if (item.name === status) {
          return {
            ...item,
            value: item.value + 1,
          };
        }
        return item;
      });

      return newArray;
    },
    []
  );

  const getMediaAtendimento = () => {
    let totalHorasAtendimento = listaOSFiltradaDashboard().reduce(
      (media, item) => media + item.horasAtendimento!,
      0
    );

    return parseFloat(
      (totalHorasAtendimento / listaOSFiltradaDashboard().length).toFixed(2)
    );
  };

  const getTotalAtendimento = () => {
    let objTotalAtendimento = listaOSFiltradaDashboard().reduce(
      (total, item) => {
        if (removeSpecialCharSimple(item.status) === "prebaixasucesso") {
          total.value = total.value + 1;
        } else {
          total.restante = total.restante + 1;
        }
        return total;
      },
      {
        name: "Atendimentos Realizados",
        value: 0,
        restante: 0,
        total: listaOSFiltradaDashboard().length,
      }
    );

    return [objTotalAtendimento];
  };

  return {
    dataGraficoBarra,
    mediaAtendimento: getMediaAtendimento(),
    totalAtendimento: getTotalAtendimento,
  };
};
