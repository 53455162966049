import { apiGet, apiPost } from "../../../services/api";
import { IEncaminhamento } from "../types";

const baseUrl = process.env.REACT_APP_API_ATENDIMENTO;

export const getListaTecnicos = async (params = {}) => 
    await apiGet(`${baseUrl}/Tecnico`, params)

export const encaminharOSs = async (params: IEncaminhamento) =>
    await apiPost(`${baseUrl}/Encaminhamento`, params)

export const getListaTecnicosByPrestador = async (idPrestador: number) =>
    await apiGet(`${baseUrl}/Tecnico/Lista/${idPrestador}`)

export const getListaTecnicosByLotePrestador = async (idPrestador: number[]) =>    
    await apiPost(`${baseUrl}/Tecnico/Lista/EmLote`, idPrestador)

