import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../contexts/auth";
import SectionCustom from "../../../components/section/section";
import { getContratantes, getModelos, postModelo } from "../services";
import { ResponseApi } from "../../../contexts/types/responsesHttp";
import {
  HeaderTabelaIncluir,
  IContratantesDTO,
  IModeloDTO,
  Modelo,
} from "../types";
import MultipleSelectCheckmarksid from "../../../components/seletorMultiploId/seletorMultiploId";
import { NewButton, SearchButton } from "./consultaAgrupamentoModelo_Styles";
import Collapse from "../../../components/collapse/collapse";
import { Alert, CircularProgress, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import StickyTable from "../../../components/stickyTable/stickyTable";
import { AxiosResponse } from "axios";
import { useNavigate } from "react-router-dom";
import AutocompleteMultiple from "../../../components/autocompleteMultiple/autocompleteMultiple";
const IncluirAgrupamentoModelos = () => {
  const { funcionalidadeDaTelaTemPermissao } = useContext(AuthContext);
  const isIncluding = window.location.pathname.includes(
    "IncluirAgrupamentoModelos"
  );
  const profileHasPermission = (funcionalidade: string) =>
    funcionalidadeDaTelaTemPermissao(isIncluding, funcionalidade);
  const [listaContratante, setListaContratante] = useState<
    { name: string; id: string }[]
  >([]);
  const [listaModelo, setListaModelo] = useState<
    { name: string; id: string }[]
  >([]);
  const [contratante, setContratante] = useState<string[]>([]);
  const [modelo, setModelo] = useState<any[]>([]);
  const [modeloAdicionar, setModeloAdicionar] = useState<any[]>([]);
  const [idModelosCompativeis, setIdModelosCompativeis] = useState<number[]>(
    []
  );
  const [msgAlertError, setMsgAlertError] = useState<string>(
    "Não foi encontrado nenhum Modelo compatível"
  );
  const navigate = useNavigate();
  const [openAlertError, setOpenAlertError] = useState(false);
  const [disableActions, setDisableActions] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [openAlertSuccess, setOpenAlertSuccess] = useState(false);
  const [msgAlertSuccess, setMsgAlertSuccess] = useState("");
  useEffect(() => {
    handleGetContratantes();
    handleGetModelo();
  }, []);
  const handlerSalvar = async () => {
    const objSalvar = {
      idContratante: Number(contratante[0]),
      idEquipamentoModelo: Number(modelo[0].id),
      idEquipamentoModelosCompativeis: idModelosCompativeis,
    } as any;
    if (
      objSalvar.idContratante === undefined ||
      objSalvar.idContratante === null ||
      objSalvar.idEquipamentoModelo === undefined ||
      objSalvar.idEquipamentoModelo === null
    ) {
      setLoading(false);
      setOpenAlertError(true);
      setMsgAlertError("Informe um modelo e um contratante antes de salvar");
      setDisableActions(false);
      return;
    } else if (
      objSalvar.idEquipamentoModelosCompativeis === undefined ||
      objSalvar.idEquipamentoModelosCompativeis === null ||
      objSalvar.idEquipamentoModelosCompativeis.length == 0
    ) {
      setLoading(false);
      setOpenAlertError(true);
      setMsgAlertError(
        "Informe pelo menos um modelo compatível antes de salvar"
      );
      setDisableActions(false);
      return;
    } else {
      try {
        const retorno = (await postModelo(objSalvar)) as AxiosResponse;
        if (retorno.status === 200) {
          setMsgAlertSuccess("Modelo salvo com sucesso!!");
          setLoading(false);
          setDisableActions(false);
          setOpenAlertSuccess(true);
          navigate("/ConsultarAgrupamentoModelo");
        } else {
          setLoading(false);
          setDisableActions(false);
          setOpenAlertError(true);
          setMsgAlertError(retorno.data.errors[0].Message);
        }
      } catch (error: any) {
        setLoading(false);
        setDisableActions(false);
        setOpenAlertError(true);
        setMsgAlertError(error.response.data);
      }
    }
  };
  const adicionarnaTabela = () => {
    if (!idModelosCompativeis.includes(Number(modeloAdicionar[0].id)))
      setIdModelosCompativeis([
        ...idModelosCompativeis,
        Number(modeloAdicionar[0].id),
      ]);
    obterDadosParaTabela();
  };
  const obterDadosParaTabela = () => {
    const modelosFiltrados = obterModelosCompativeis(
      listaModelo,
      idModelosCompativeis
    );
    return modelosFiltrados ?? [];
  };
  const handleClickItemRemover = (data: any) => {
    const idModelosCompativeisAtualizado = idModelosCompativeis.filter(
      (id) => id !== Number(data.id)
    );
    setIdModelosCompativeis(idModelosCompativeisAtualizado);
  };
  const objTraducaoHeader = {
    id: "ID Modelo",
    name: "Nome Modelo",
  } as HeaderTabelaIncluir;
  const obterModelosCompativeis = (
    lista: Modelo[],
    idsCompativeis: number[]
  ) => {
    return lista.filter((modelo) => idsCompativeis.includes(Number(modelo.id)));
  };
  const handleGetContratantes = async () => {
    await getContratantes().then(
      (res: any) => {
        const respSuccess = res?.data as ResponseApi<IContratantesDTO[]> | null;
        if (respSuccess) {
          const listaContratante =
            respSuccess.data?.map((item: any) => {
              return { name: item.nome, id: item.id.toString() };
            }) || [];
          setListaContratante([...[], ...listaContratante]);
        }
      },
      (err) => {
        const respErr = err?.response?.data as ResponseApi<IContratantesDTO[]>;
        if (respErr) {
          setMsgAlertError(respErr.errors[0].Message);
          setOpenAlertError(true);
          setListaContratante([]);
        }
      }
    );
  };
  const handleGetModelo = async () => {
    await getModelos().then(
      (res: any) => {
        const respSuccess = res?.data;
        if (respSuccess) {
          const listaModelo =
            respSuccess?.equipamentoModelos?.map((item: any) => ({
              name: item.nome,
              id: item.id.toString(),
            })) || [];
          setListaModelo([...[], ...listaModelo]);
        }
      },
      (err) => {
        const respErr = err?.response?.data as ResponseApi<IModeloDTO[]>;
        if (respErr) {
          setMsgAlertError(respErr.errors[0].Message);
          setOpenAlertError(true);
          setListaContratante([]);
        }
      }
    );
  };
  return (
    <SectionCustom
      id={"IncluirAgrupamento"}
      className="IncluirAgrupamento"
      titleHeader="Incluir Agrupamento"
      titleBreadcrumbItem="Incluir Modelo Compativel"
      titleBreadcrumbItemBack="Consultar Agrupamento de Modelos"
      rotaVoltar="/ConsultarAgrupamentoModelo"
    >
      <div className="consultarAgrupamentoModelo-Container">
        <div className="filtro-Modelo">
          <AutocompleteMultiple
                dimension="sm"
                label={"Modelo"}
                placeholder="Selecione um Modelo"
                options={[{ name: "--", id: "" }, ...listaModelo]}
                value={modelo}
                onChange={(value:any) => setModelo(value)}
                fontSize={12}
                id="multiple-checkbox-Status"
                multiple={false}
                allSelected
                disabled={disableActions}
              />
          <MultipleSelectCheckmarksid
            dimension="sm"
            label={"Contratante"}
            nodata={"Nenhum contratante encontrado"}
            options={[{ name: "--", id: "" }, ...listaContratante]}
            value={contratante}
            onChange={(value) => setContratante(value)}
            placeholder={"Selecione um contratante"}
            id="multiple-checkbox-Contratante"
            idOption="multiple-checkbox-option-Contratante-"
            labelId="multiple-checkbox-label-Contratante"
            classes={{
              select: "ConsultaContratante-Seletor error",
              primary: "Seletor-Option",
              group: "Seletor-OptionGroup",
            }}
            multiple={false}
            disabled={disableActions}
          />
        </div>
      </div>
      <div className="consultarAgrupamentoModelo-Container">
        <div className="filtro-Modelo">
          <AutocompleteMultiple
                dimension="sm"
                label={"Modelo Compatível"}
                placeholder="Selecione um Modelo Compatível"
                options={[{ name: "--", id: "" }, ...listaModelo]}
                value={modeloAdicionar}
                onChange={(value:any) => setModeloAdicionar(value)}
                fontSize={12}
                id="multiple-checkbox-Status"
                multiple={false}
                allSelected
                disabled={disableActions}
              />
          <div className="botao-PesquisarModelo-container">
            <SearchButton
              variant="contained"
              size="small"
              onClick={adicionarnaTabela}
              disabled={disableActions || modeloAdicionar.length == 0}
            >
              Adicionar
            </SearchButton>
            {!!profileHasPermission("Incluir Registro") ? (
              <NewButton
                color="primary"
                variant="contained"
                size="small"
                onClick={handlerSalvar}
              >
                Salvar
              </NewButton>
            ) : (
              <NewButton
                className={"bloqueado"}
                style={{ cursor: "not-allowed", opacity: 0.5 }}
                color="secondary"
                variant="contained"
                size="small"
              >
                Salvar
              </NewButton>
            )}
          </div>
        </div>
      </div>
      {openAlertSuccess && (
        <Collapse onClose={setOpenAlertSuccess} in={openAlertSuccess}>
          <Alert
            severity="success"
            icon={<div />}
            variant="filled"
            sx={{
              mb: 2,
              bgcolor: "#178B319E",
              borderRadius: "15px",
              boxSizing: "border-box",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: "16px",
            }}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="medium"
                onClick={() => setOpenAlertSuccess(false)}
              >
                <CloseIcon
                  id={"ModalTermoOS-AlertCloseIcon"}
                  fontSize="inherit"
                  sx={{
                    color: "white",
                  }}
                />
              </IconButton>
            }
          >
            <span
              style={{
                color: "white",
                fontSize: "16px",
              }}
            >
              {msgAlertSuccess}
            </span>
          </Alert>
        </Collapse>
      )}
      {openAlertError && (
        <div className="modalErro-Modelo">
          <Collapse onClose={setOpenAlertError} in={openAlertError}>
            <Alert
              severity="error"
              icon={<div />}
              variant="filled"
              sx={{
                mb: 2,
                bgcolor: "rgb(255, 35, 35, 0.7)",
                borderRadius: "15px",
                boxSizing: "border-box",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginBottom: "50px",
              }}
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="medium"
                  onClick={() => setOpenAlertError(false)}
                >
                  <CloseIcon
                    id={"ModalModeloCompativel-AlertCloseIcon"}
                    fontSize="inherit"
                    sx={{
                      color: "white",
                    }}
                  />
                </IconButton>
              }
            >
              <span
                style={{
                  color: "white",
                  fontSize: "16px",
                }}
                dangerouslySetInnerHTML={{ __html: msgAlertError }}
              />
            </Alert>
          </Collapse>
        </div>
      )}
      {!!loading && <CircularProgress style={{ margin: "0px auto" }} />}
      {!!idModelosCompativeis.length && (
        <>
          <div className="tabelaModelo">
            <StickyTable
              id={"TabelaResultadoConsultaModelo"}
              data={obterDadosParaTabela()}
              objTraducaoHeader={objTraducaoHeader}
              allowEdit={!!profileHasPermission("Excluir Registro")}
              iconeRemover={true}
              edit={true}
              labelEdit="Excluir"
              handleClickEdit={handleClickItemRemover}
            />
          </div>
        </>
      )}
    </SectionCustom>
  );
};
export default IncluirAgrupamentoModelos;