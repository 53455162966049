import React, { useContext, useEffect, useState, useRef } from 'react';
import Header from '../../../components/header/header';
import { HomeContext } from '../../../contexts/homeContext';
import { AuthContext } from "../../../contexts/auth";
import { Breadcrumb, BreadcrumbItem } from '../../../components/breadcrumb/breadcrumb';
import { useNavigate } from 'react-router';
import MultipleSelectCheckmarksId from '../../../components/seletorMultiploId/seletorMultiploId';
import Input from '../../../components/Input/Input';
import moment from 'moment';
import Button from '../../../components/Button/styles';
import _ from 'lodash';
import { useFormik } from "formik";
import Collapse from '../../../components/collapse/collapse';
import CloseIcon from "@mui/icons-material/Close";
import * as yup from "yup";
import "./incluirEditarServico.scss";
import { AxiosResponse } from 'axios';
import { postServico, putServico } from '../services';
import { Grupo_Servico } from "../../../contexts/types/farolTypes";
import { getGrouped_Services } from "../../farol/services";
import { Alert, CircularProgress, IconButton } from '@mui/material';
import { removeSpecialCharSimple } from '../../../helpers/removeSpecialCharSimple';
import { ResponseApi } from '../../../contexts/types/responsesHttp';
import AutocompleteMultiple from '../../../components/autocompleteMultiple/autocompleteMultiple';

interface IInitialValues {
  ativo: string[],
  grupoServico: { name: string; id: string; }[],
  nomeServico: string,
  fluxoTrabalho: { name: string; id: string; }[],
  usuarioCriacao: string | number,
  dataCriacao: string,
  usuarioModificacao: string | number,
  dataModificacao: string,
};

const IncluirEditarServico = () => {

  const isIncluding = window.location.pathname.includes('IncluirServico');

  const isInitialMount = useRef(true);

  const { funcionalidadeDaTelaTemPermissao, user } = useContext(AuthContext);
  const profileHasPermission = (funcionalidade: string) => funcionalidadeDaTelaTemPermissao(
    isIncluding ? "Inclusão de Serviço" : "Edição de Serviço",
    funcionalidade);

  const { setMenuLateral, servicoSelecionado, setServicoSelecionado } = useContext(HomeContext);

  const navigate = useNavigate();

  const [listaStatus, setListaStatus] = useState<{ name: string, id: string }[]>([
    { name: 'Ativo', id: '0' },
    { name: 'Inativo', id: '1' },
  ]);
  const [listaGrupoServico, setListaGrupoServico] = useState<Grupo_Servico[]>([]);
  const [tipomsgAlert, setTipoMsgAlert] = useState<'success' | 'info' | 'warning' | 'error'>("success");
  const [msgAlert, setMsgAlert] = useState("OS encaminhada com  sucesso!");
  const [openAlert, setOpenAlert] = useState(false);
  const [disableActions, setDisableActions] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);

  const getGroupByID = (ID: number) => {
    let resultadoDados: any = _.isArray(listaGrupoServico) ? [...listaGrupoServico] : [];

    return resultadoDados.find(
      (item: any) => item.id === ID
    )!;
  };

  const validationSchema = yup.object({
    ativo: yup.array().max(1).min(1, 'Campo obrigatório'),
    grupoServico: yup.array().max(1).min(1, 'Campo obrigatório'),
    nomeServico: yup.string().required("Campo obrigatório").test('nomeDeServicoJaExistente', 'Já existe um registro com a mesma informação.',
      (val, ctx) => {
        /**Na edição permite o mesmo nome */
        if (!isIncluding) return true;

        const { parent, path, createError } = ctx;
        // return createError({ path, message: "errorMessage" });
        const grupoServico = listaGrupoServico?.find((g: any) => String(g?.id) === parent?.grupoServico[0]?.id ?? "");
        const servico = grupoServico?.servicos?.find((s: any) => removeSpecialCharSimple(s?.nome) === removeSpecialCharSimple(val ?? ""));

        if (!!servico) return false;
        return true;
      }),
  });

  const initialValuesIncluir: IInitialValues = {
    ativo: ['1'],
    grupoServico: [],
    nomeServico: '',
    fluxoTrabalho: [],
    usuarioCriacao: '',
    dataCriacao: '',
    usuarioModificacao: '',
    dataModificacao: '',
  };

  const initialValuesEditar: IInitialValues = {
    ativo: [(servicoSelecionado?.ativo ? '0' : '1')],
    grupoServico: servicoSelecionado?.idGrupoServico ? [{ name: getGroupByID(servicoSelecionado?.idGrupoServico)?.nome ?? "", id: String(servicoSelecionado?.idGrupoServico) }] : [],
    nomeServico: servicoSelecionado?.nome || '',
    fluxoTrabalho: [],
    usuarioCriacao: servicoSelecionado?.idUsuarioCriacao,
    dataCriacao: servicoSelecionado?.dataCriacao ? moment(servicoSelecionado?.dataCriacao, "YYYY/MM/DDTHH:mm:ss").format("DD/MM/YYYY HH:mm:ss") : '',
    usuarioModificacao: servicoSelecionado?.idUsuarioModificacao,
    dataModificacao: servicoSelecionado?.dataModificacao ? moment(servicoSelecionado?.dataModificacao, "YYYY/MM/DDTHH:mm:ss").format("DD/MM/YYYY HH:mm:ss") : '',
  };

  const formik = useFormik({
    initialValues: isIncluding ? initialValuesIncluir : initialValuesEditar,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async (values, actions) => {
      if (isIncluding) {
        if (!!profileHasPermission("Novo Registro")) return handleIncludeServico(values);
      } else {
        if (!!profileHasPermission("Editar Registro")) return handleEditServico(values);
      }
    }
  });

  const handleIncludeServico = async (values: IInitialValues) => {
    setDisableActions(true);
    setOpenAlert(false);
    setLoading(true);

    try {
      const statusBool: boolean = values.ativo[0] === '0' ? true : false;

      const { data } = await postServico({
        ativo: statusBool,
        idGrupoServico: Number(values.grupoServico[0].id),
        nome: values.nomeServico,
      }) as AxiosResponse;

      setLoading(false);
      if (!!data && typeof data === "string") {
        setMsgAlert(data);
      } else {
        setMsgAlert('Servico criado com sucesso');
      }
      setTipoMsgAlert("success");
      setOpenAlert(true);
      setDisableActions(false);

      formik.resetForm();

      handleGetGroupesServices();
    } catch (e: any) {
      setLoading(false);
      if (e?.response?.data?.errors) {
        setMsgAlert(Object.values(e.response.data.errors).join("<br>"));
      } else {
        setMsgAlert("Erro ao incluir serviço");
      }
      setTipoMsgAlert("error");
      setOpenAlert(true);
      setDisableActions(false);
    }
  }

  const handleEditServico = async (values: IInitialValues) => {
    setDisableActions(true);
    setOpenAlert(false);
    setLoading(true);

    try {
      const statusBool: boolean = values.ativo[0] === '0' ? true : false;

      const { data } = await putServico({
        ativo: statusBool,
        idGrupoServico: Number(values.grupoServico[0].id),
        nome: values.nomeServico,
      }, servicoSelecionado.id) as AxiosResponse;

      /**Atualiza dados do servico no contexto */
      setServicoSelecionado({
        ...servicoSelecionado,
        ativo: statusBool,
        idGrupoServico: Number(values.grupoServico[0].id),
        nome: values.nomeServico,
        idUsuarioModificacao: user?.idUsuario,
        dataModificacao: moment().format("YYYY-MM-DD HH:mm:ss"),
      });

      setLoading(false);
      if (!!data && typeof data === "string") {
        setMsgAlert(data);
      } else {
        setMsgAlert("Servico alterado com sucesso!");
      }
      setTipoMsgAlert("success");
      setOpenAlert(true);
      setDisableActions(false);

      handleGetGroupesServices();
    } catch (e: any) {
      setLoading(false);
      if (e?.response?.data?.errors) {
        setMsgAlert(Object.values(e.response.data.errors).join("<br>"));
      } else {
        setMsgAlert("Erro ao editar serviço");
      }
      setTipoMsgAlert("error");
      setOpenAlert(true);
      setDisableActions(false);
    }
  }

  const handleGetGroupesServices = async () => {
    await getGrouped_Services().then((resp: any) => {

      const respSuccess = resp?.data as ResponseApi<Grupo_Servico[]>;
      const grupos = respSuccess?.data as any[] ?? [];

      setListaGrupoServico(grupos);
    }, (err: any) => {
      const respErr = err?.response?.data as ResponseApi<any>;
    });
  };

  useEffect(() => {
    if (isInitialMount.current) {
      handleGetGroupesServices();
      if (!isIncluding && !servicoSelecionado?.id) navigate("/IncluirServico");

      isInitialMount.current = false;
    } else {
      if (isIncluding) formik.setValues(initialValuesIncluir);
    }
  }, [
    isIncluding
  ]);

  return (
    <section className='IncluirEditarServico'>
      <Header setMenuLateral={() => setMenuLateral(true)} title={isIncluding ? 'Inclusão de serviço' : 'Edição de serviço'} />
      <Breadcrumb>
        <BreadcrumbItem onClick={() => navigate("/Servicos")}>Consultar Serviços</BreadcrumbItem>
        <BreadcrumbItem>{isIncluding ? 'Inclusão de serviço' : 'Edição de serviço'}</BreadcrumbItem>
      </Breadcrumb>

      <form className="Container" onSubmit={formik.handleSubmit}>
        <div className="IncluirServico">
          <div className={'firstRow grid'}>
            <MultipleSelectCheckmarksId
              nodata={"Nenhum status encontrado"}
              options={listaStatus}
              value={formik.values.ativo}
              onChange={(e) => formik.setFieldValue('ativo', e)}
              placeholder={"Selecionar status"}
              id="multiple-checkbox-status"
              idOption="multiple-checkbox-option-status"
              labelId="multiple-checkbox-label-status"
              multiple={false}
              label='Status'
              name="status"
              className='IncluirServicoSelectStatus'
              dimension='sm'
              error={formik.touched.ativo && Boolean(formik.errors.ativo)}
              helperText={formik.touched.ativo && formik.errors.ativo}
              disabled={disableActions}
            />
          </div>

          <div className={'secondRow grid'}>
            <AutocompleteMultiple
              dimension="sm"
              label={"Grupo Serviços"}
              placeholder={""}
              noOptionsText={"Nenhum grupo de serviço encontrado"}
              options={listaGrupoServico?.map((item: any) => ({ name: item.nome, id: item.id.toString() }))!}
              value={formik.values.grupoServico}
              onChange={(e: any) => formik.setFieldValue('grupoServico', e)}
              id="multiple-checkbox-grupoServico"
              error={formik.touched.grupoServico && Boolean(formik.errors.grupoServico)}
              helperText={formik.touched.grupoServico && formik.errors.grupoServico}
              multiple={false}
              disabled={disableActions}
            />
            <Input
              label='Nome do Servico'
              id='nomeServico'
              dimension='sm'
              name='nomeServico'
              error={formik.touched.nomeServico && Boolean(formik.errors.nomeServico)}
              helperText={formik.touched.nomeServico && formik.errors.nomeServico}
              value={formik.values.nomeServico}
              maxLength={50}
              onChange={formik.handleChange}
              disabled={disableActions}
            />
            <AutocompleteMultiple
              dimension="sm"
              label={"Fluxo de Trabalho"}
              placeholder={""}
              noOptionsText={"Nenhum Fluxo de Trabalho encontrado"}
              options={[]}
              value={formik.values.fluxoTrabalho}
              onChange={(e: any) => formik.setFieldValue('fluxoTrabalho', e)}
              id="multiple-checkbox-fluxoTrabalho"
              error={formik.touched.fluxoTrabalho && Boolean(formik.errors.fluxoTrabalho)}
              helperText={formik.touched.fluxoTrabalho && formik.errors.fluxoTrabalho}
              multiple={false}
              disabled={true}
            />
          </div>
          <div className={'thirdRow grid'}>
            <Input
              dimension="sm"
              label='Criado Por'
              name='usuarioCriacao'
              id='usuarioCriacao'
              value={formik.values.usuarioCriacao}
              onChange={formik.handleChange}
              error={formik.touched.usuarioCriacao && Boolean(formik.errors.usuarioCriacao)}
              helperText={formik.touched.usuarioCriacao && formik.errors.usuarioCriacao}
              readOnly
            />
            <Input
              dimension="sm"
              label='Criado em'
              id="dataCriacao"
              value={formik.values.dataCriacao}
              onChange={(e) => (formik.setFieldValue('dataCriacao', e))}
              error={formik.touched.dataCriacao && Boolean(formik.errors.dataCriacao)}
              helperText={(formik.touched.dataCriacao && formik.errors.dataCriacao) ? "Campo obrigatório" : ""}
              readOnly
            />
            <Input
              dimension="sm"
              label='Editado por'
              name='usuarioModificacao'
              id='usuarioModificacao'
              value={formik.values.usuarioModificacao}
              onChange={formik.handleChange}
              error={formik.touched.usuarioModificacao && Boolean(formik.errors.usuarioModificacao)}
              helperText={formik.touched.usuarioModificacao && formik.errors.usuarioModificacao}
              readOnly
            />
            <Input
              dimension="sm"
              label='Editado em'
              id="dataModificacao"
              value={formik.values.dataModificacao}
              onChange={(e) => (formik.setFieldValue('dataModificacao', e))}
              error={formik.touched.dataModificacao && Boolean(formik.errors.dataModificacao)}
              helperText={(formik.touched.dataModificacao && formik.errors.dataModificacao) ? "Campo obrigatório" : ""}
              readOnly
            />
          </div>

          {!!isIncluding &&
            <>
              {!!profileHasPermission("Novo Registro") ?
                <Button
                  type="submit"
                  variant='primary'
                  dimension='sm'
                  width='146px'
                >
                  Salvar
                </Button>
                :
                <Button
                  type="button"
                  variant='primary'
                  dimension='sm'
                  width='146px'
                  style={{ cursor: 'not-allowed', opacity: 0.5 }}
                  disabled={true}>
                  Salvar
                </Button>
              }
            </>
          }

          {!isIncluding &&
            <>
              {!!profileHasPermission("Editar Registro") ?
                <Button
                  type="submit"
                  variant='primary'
                  dimension='sm'
                  width='146px'
                >
                  Salvar
                </Button>
                :
                <Button
                  type="button"
                  variant='primary'
                  dimension='sm'
                  width='146px'
                  style={{ cursor: 'not-allowed', opacity: 0.5 }}
                  disabled={true}>
                  Salvar
                </Button>
              }
            </>
          }
        </div>

        {!!loading && <CircularProgress style={{ margin: '0px auto' }} />}

        <Collapse onClose={setOpenAlert} in={openAlert}>
          <Alert
            severity={tipomsgAlert}
            icon={<div />}
            variant="filled"
            sx={{
              mb: 2,
              bgcolor: tipomsgAlert === "success" ? "#178B319E" : "rgb(255, 35, 35, 0.7)",
              minHeight: "67px",
              borderRadius: "15px",
              boxSizing: "border-box",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginTop: "20px",
              marginBottom: 0,
            }}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="medium"
                onClick={() => setOpenAlert(false)}
              >
                <CloseIcon
                  id={"ModalEncaminharOS-AlertCloseIcon"}
                  fontSize="inherit"
                  sx={{
                    color: "white",
                  }}
                />
              </IconButton>
            }
          >
            <span
              style={{
                color: "white",
                fontSize: "16px",
              }}
              dangerouslySetInnerHTML={{ __html: msgAlert }}
            />
          </Alert>
        </Collapse>
      </form>
    </section>
  );
};

export default IncluirEditarServico;
