import { useState } from "react"
import { ITecnicoConsulta } from "./types/tecnicoTypes"

export const TecnicoContext = () => {
  const [tecnicoSelecionado, setTecnicoSelecionado] = useState({} as ITecnicoConsulta)

  return {
    tecnicoSelecionado,
    setTecnicoSelecionado
  }
}