import { Document, Page, View, Text, Image } from '@react-pdf/renderer';
import { styles } from './documentTemplateStyles';
import { IPostConsultarOSsResponseOS } from '../../pages/consultaOS/types';
import { useEffect, useState } from 'react';
import JsBarcode from 'jsbarcode';
import HeaderContent from './HeaderContent';
import { IOrdemServico } from '../../contexts/types/farolTypes';
//@ts-ignore
import Logo from '../../assets/images/mobyan_logo.png';
import moment from 'moment';

const padZeros = (value: number) => {
    const valueString = value.toString();
    const paddedValue = valueString.padStart(15, '0');
    return paddedValue;
};

const Cabecalho = ({ ordemServico, barcodeValue }: { ordemServico: IPostConsultarOSsResponseOS | IOrdemServico, barcodeValue: string }) => {
    const source = {
        chamado: ordemServico?.chamado ?? "",
        referencia: ordemServico?.referencia ?? "",
    };

    return (
        <Document>
            <View style={styles.header}>
                <View style={{ ...styles.table, marginBottom: 10, border: 0 }}>
                    <View style={styles.tableRow}>
                        <View style={{ ...styles.tableCol, border: 0, width: '25%', alignItems: 'center' }}>
                            <Image style={{ ...styles.logo, border: 0, marginTop: 20, padding: '0 5px' }} src={Logo} />
                        </View>
                        <View style={{ ...styles.tableCol, border: 0, width: '100%' }}>
                            <View style={{ ...styles.table, border: 0 }}>
                                <View style={{ ...styles.tableRow, border: 0 }}>
                                    <View style={{ ...styles.tableCol, border: 0 }}>
                                        <View style={{ ...styles.tableRow, border: 0, height: 10 }} />
                                        <Text style={{ fontSize: 12, verticalAlign: 'sub', fontWeight: 'bold', textTransform: 'uppercase' }}>
                                            ordem de serviço de campo
                                        </Text>
                                    </View>
                                    <View style={{ ...styles.tableCol, border: 0 }}>
                                        <View style={{ ...styles.tableRow, border: 0, height: 10 }} />
                                        <View style={{ ...styles.tableRow, alignSelf: 'flex-end' }}>
                                            <Text style={{ fontSize: 12, fontWeight: 'bold', verticalAlign: 'sub' }}>
                                                #{padZeros(source.chamado)}
                                            </Text>
                                        </View>
                                    </View>
                                </View>

                                <View style={{ ...styles.tableRow, border: 0, height: 10 }} />
                                <View style={{ ...styles.tableRow, border: 0, height: 10 }} />
                                <View style={{ ...styles.tableRow, border: 0 }}>
                                    <Text>Nr. {source.referencia}</Text>
                                </View>
                            </View>
                        </View>
                        <View style={{ ...styles.tableCol, border: 0, width: '30%' }}>
                            <View style={{ ...styles.tableRow, alignSelf: 'flex-start', marginBottom: 0, paddingLeft: 5 }}>
                                <Text style={{ fontSize: 6 }}>{source.chamado}</Text>
                            </View>
                            <View style={{ ...styles.tableRow, alignSelf: 'center', marginTop: 0 }}>
                                <Image style={{ width: 100, height: 45 }} src={barcodeValue} />
                            </View>
                        </View>
                    </View>
                </View>
            </View>
        </Document>
    );
};

const Corpo = ({ ordemServico }: { ordemServico: IPostConsultarOSsResponseOS | IOrdemServico }) => {
    const source = {
        grupoServico: ordemServico?.grupoServico ?? "",
        servico: ordemServico?.servico ?? "",
        contratante: ordemServico?.contratante ?? "",
        codigoCliente: ('cliente' in ordemServico ? (ordemServico.cliente?.codigoCliente ?? "") : ('codigoCliente' in ordemServico ? (ordemServico.codigoCliente ?? "") : "")),
        razaoSocial: ('cliente' in ordemServico ? (ordemServico.cliente?.razaoSocial ?? "") : ('nomeFantasia' in ordemServico ? (ordemServico.nomeFantasia ?? "") : "")),
        logradouro: ('enderecos' in ordemServico ? (ordemServico.enderecos[0]?.logradouro ?? "") : ('logradouro' in ordemServico ? (ordemServico.logradouro ?? "") : "")),
        numero: ('enderecos' in ordemServico ? (ordemServico.enderecos[0]?.numero ?? "") : ('numero' in ordemServico ? (ordemServico.numero ?? "") : "")),
        bairro: ('enderecos' in ordemServico ? (ordemServico.enderecos[0]?.bairro ?? "") : ('bairro' in ordemServico ? (ordemServico?.bairro ?? "") : "")),
        complemento: ('enderecos' in ordemServico ? (ordemServico.enderecos[0]?.complemento ?? "") : ('complemento' in ordemServico ? (ordemServico?.complemento ?? "") : "")),
        cep: ('enderecos' in ordemServico ? (ordemServico.enderecos[0]?.cep ?? "") : ('cep' in ordemServico ? (ordemServico?.cep ?? "") : "")),
        //@ts-ignore
        cidade: ('enderecos' in ordemServico ? (ordemServico.enderecos[0]?.cidade ?? "") : ('cidade' in ordemServico ? (ordemServico?.cidade ?? "") : "")),
        //@ts-ignore
        uf: ('enderecos' in ordemServico ? (ordemServico.enderecos[0]?.uf ?? "") : ('uf' in ordemServico ? (ordemServico?.uf ?? "") : "")),
        contato: ('cliente' in ordemServico ? (ordemServico.cliente?.contato ?? "") : ('nomeContato' in ordemServico ? (ordemServico.nomeContato ?? "") : "")),
        telefones: ('telefonesOS' in ordemServico ? ordemServico.telefonesOS.map(t => (`${t.codigoArea} ${t.numero}`)) : ('telefone' in ordemServico ? [ordemServico?.telefone ?? ""] : [])),
        fax: "", /**??? */
        classe: "", /**??? */
        observacao: ordemServico?.observacao ?? "",
        dataAbertura: (ordemServico?.dataAbertura ? moment(ordemServico?.dataAbertura, 'YYYY-MM-DD HH:mm').format('DD/MM/YY HH:mm') : ""),
        previsaoAtendimento: (ordemServico?.previsaoAtendimento ? moment(ordemServico?.previsaoAtendimento, 'YYYY-MM-DD HH:mm').format('DD/MM/YY HH:mm') : ""),
        dataAtendimento: (ordemServico?.dataAtendimento ? moment(ordemServico?.dataAtendimento, 'YYYY-MM-DD HH:mm').format('DD/MM/YY HH:mm') : ""),
    };

    return (
        <Document>
            <View style={{ ...styles.rowHeader, fontSize: 10, textAlign: 'center', margin: '1 0', height: 18, paddingTop: 3, paddingBottom: 3 }}>
                <Text>Tipo do Serviço: {source.servico}</Text>
                <Text>Contratante: {source.contratante}</Text>
            </View>
            <HeaderContent title='DADOS DO ESTABELECIMENTO' style={{ ...styles.rowHeader, fontSize: 10, height: 18, paddingTop: 3, paddingBottom: 3 }} />
            <View style={{ ...styles.rowBorder, ...styles.textItems, ...styles.rowBorderItem }}>
                <Text>Nome: {source.razaoSocial}</Text>
                <Text>Código: {source.codigoCliente}</Text>
            </View>
            <View style={{ ...styles.rowBorder, ...styles.textItems, ...styles.rowBorderItem }}>
                <Text>End. {source.logradouro} {source.numero}</Text>
            </View>
            <View style={{ ...styles.rowBorder, ...styles.textItems, ...styles.rowBorderItem }}>
                <Text>Bairro: {source.bairro}</Text>
                <Text>Complemento: {source.complemento} </Text>
            </View>
            <View style={{ ...styles.rowBorder, ...styles.textItems, ...styles.rowBorderItem }}>
                <Text>CEP: {source.cep}</Text>
                <Text>Cidade: {source.cidade}</Text>
                <Text>UF: {source.uf}</Text>
            </View>
            <View style={{ ...styles.rowBorder, ...styles.textItems, ...styles.rowBorderItem }}>
                <Text>Contato: {source.contato}</Text>
                <Text>Telefone: {source.telefones.slice(0, 2).map((num, i) => (`${num}${(i !== source.telefones.slice(0, 2).length - 1) ? `, ` : ""}`))}</Text>
                <Text>FAX: {source.fax}</Text>
            </View>
            <HeaderContent title='SERVIÇO A EXECUTAR' style={{ ...styles.rowHeader, ...styles.rowBorderItem, paddingTop: 3, paddingBottom: 3 }} />
            <View style={{ ...styles.rowBorder, ...styles.textItems, fontSize: 10, height: 18 }}>
                <Text>{source.grupoServico}</Text>
                <Text>{source.servico}</Text>
                <Text>Classe: {source.classe}</Text>
            </View>
            <View style={{ ...styles.rowBorder, ...styles.textItems, margin: 1 }}>
                <Text>{source.observacao}</Text>
            </View>
            <HeaderContent title='DADOS DO EQUIPAMENTO (Caso haja alguma divergência nas informações, favor preencher o correto, abaixo)' style={{ ...styles.rowHeader, margin: 1, height: 18, paddingTop: 3, paddingBottom: 3 }} />
            <View style={{ ...styles.rowBorder, ...styles.textItems, ...styles.rowBorderItem }}>
                <Text>Marca</Text>
                <Text>Modelo</Text>
                <Text>Nr. Físico</Text>
                <Text>Núm. Lógico</Text>
                <Text>Versão Software</Text>
            </View>
            <View style={{ ...styles.rowBorder, ...styles.textItems, height: 18 }} />
            <HeaderContent title='COMPONENTES (Caso haja alguma divergência nas informações, favor preencher o correto, abaixo)' style={{ ...styles.rowHeader, margin: 1, height: 18, paddingTop: 3, paddingBottom: 3 }} />
            <View style={{ ...styles.rowBorder, ...styles.textItems, ...styles.rowBorderItem }}>
                <Text>Tipo</Text>
                <Text>Modelo</Text>
                <Text>Nr. Série</Text>
                <Text>Tipo</Text>
                <Text>Modelo</Text>
                <Text>Nr. Série</Text>
            </View>
            <View style={{ ...styles.rowBorder, ...styles.textItems, height: 18 }} />
            <View style={{ ...styles.rowBorder, ...styles.rowBorderItem, padding: '0 20', alignItems: 'center', border: 0 }}>
                <Text>COMPONENTES RETIRADOS: PIN-PAD: SIM ( ) NÃO ( ) FONTE: SIM ( ) NÃO ( ) CABOS: SIM ( ) NÃO ( )</Text>
            </View>
            <HeaderContent title='DIAGNÓSTICO' style={{ ...styles.rowHeader, margin: 1, height: 18, paddingTop: 3, paddingBottom: 3 }} />
            <View style={{ ...styles.rowBorder, ...styles.textItems, ...styles.rowBorderItem }}>
                <Text>Cód. Defeito</Text>
                <Text>Cód. Solução</Text>
                <Text>Cód. Causa</Text>
            </View>
            <View style={{ ...styles.rowBorder, ...styles.textItems, height: 18, margin: 1 }} />
            <HeaderContent title='PEÇAS UTILIZADAS' style={{ ...styles.rowHeader, height: 18, margin: 1, paddingTop: 3, paddingBottom: 3 }} />
            <View style={{ ...styles.rowBorder, ...styles.textItems, ...styles.rowBorderItem }}>
                <Text>Código</Text>
                <Text>Defeito</Text>
                <Text>Código</Text>
                <Text>Defeito</Text>
            </View>
            <HeaderContent title='Dados do atendimento' style={{ ...styles.rowHeader, height: 18, paddingTop: 3, paddingBottom: 3 }} />
            <View style={{ ...styles.rowBorder, ...styles.textItems, ...styles.rowBorderItem }}>
                <Text>Data de abertura</Text>
                <Text>Limite para Atendimento</Text>
                <Text>Atendimento</Text>
            </View>
            <View style={{ ...styles.rowBorder, ...styles.textItems, ...styles.rowBorderItem }}>
                <Text>{source.dataAbertura}</Text>
                <Text>{source.previsaoAtendimento}</Text>
                <Text>{source.dataAtendimento}</Text>
            </View>
            <View style={{ ...styles.rowBorder, ...styles.textItems, ...styles.rowBorderItem }}>
                <Text>TREINAMENTO: SIM ( ) NÃO ( ) Caso a resposta seja Sim, favor informar o(s) nome(s) abaixo</Text>
            </View>
            <View style={{ ...styles.table }}>
                <View style={{ ...styles.tableRow, padding: '0 20px' }}>
                    <View style={{ ...styles.tableCol, border: 0, width: '25%' }}>
                        <Text>Nome 1:</Text>
                    </View>
                    <View style={{ ...styles.tableCol, border: 0, width: '25%' }}>

                    </View>
                    <View style={{ ...styles.tableCol, border: 0, width: '25%' }}>
                        <Text>Nome 2:</Text>
                    </View>
                    <View style={{ ...styles.tableCol, border: 0, width: '25%' }}></View>
                </View>
            </View>
            <HeaderContent title='CÓDIGOS FORNECIDOS PELO CONTRATANTE' style={{ ...styles.rowHeader, height: 18, fontSize: 11, margin: 1, paddingTop: 3, paddingBottom: 3 }} />
            <View style={{ ...styles.rowBorder, ...styles.textItems, ...styles.rowBorderItem }}>
                <Text>Cód. de Ativação</Text>
                <Text>Cód. de Desativação</Text>
                <Text>Cód. do Cancelamento</Text>
                <Text>Cód. Contra Ordem</Text>
                <Text>Cód. Segurança</Text>
            </View>
            <View style={{ ...styles.rowBorder, margin: 1, height: 18 }} />
            <View style={{ ...styles.rowHeaderGray, margin: 2 }}>
                <Text style={{ fontSize: 9, textAlign: 'justify', padding: '0 20' }}>Declaramos que os serviços foram efetivamente realizados, bem como os componentes foram retirados com o "DE ACORDO" do cliente.</Text>
            </View>
        </Document>
    );
};

const Footer = () => (
    <Document>
        <View style={{ ...styles.table, fontSize: 10, border: 0 }}>
            <View style={styles.tableRow}>
                <View style={{ ...styles.tableCol, border: 0 }}>
                    <View style={{ ...styles.tableRow, border: 1, height: 18, padding: '0 15' }}>
                        <Text>Anexo comprovante</Text>
                    </View>
                    <View style={{ ...styles.tableRow, border: 1, height: 18, padding: '0 15' }}>
                        <View style={{ ...styles.tableCol, border: 0 }}>
                            <Text>Tipo</Text>
                        </View>
                        <View style={{ ...styles.tableCol, border: 0 }} />
                        <View style={{ ...styles.tableCol, border: 0 }}>
                            <Text>Número</Text>
                        </View>
                        <View style={{ ...styles.tableCol, border: 0 }} />
                    </View>
                    <View style={{ ...styles.tableRow, border: 1, height: 18 }}></View>
                    <View style={{ ...styles.tableRow, border: 1, height: 18 }}></View>
                    <View style={{ ...styles.tableRow, border: 1, height: 18 }}></View>
                </View>
                <View style={{ ...styles.tableCol, padding: 5 }}>
                    <View style={{ ...styles.tableRow, height: 18 }}>
                        <Text>Parceiro/Técnico</Text>
                    </View>
                    <View style={{ ...styles.tableRow, height: 18 }}>
                        <Text>Código:</Text>
                    </View>
                    <View style={{ ...styles.tableRow, height: 18 }}>
                        <Text>Nome:</Text>
                    </View>
                    <View style={{ height: 18 }} />
                    <View style={{ ...styles.tableRow, textAlign: 'center', justifyContent: 'center' }}>
                        <Text>Assinatura:</Text>
                    </View>
                </View>
                <View style={styles.tableCol}>
                    <View style={{ ...styles.tableRow, height: 18 }}>
                        <Text>Cliente</Text>
                    </View>
                    <View style={{ ...styles.tableRow, height: 18 }}>
                        <Text>Nome:</Text>
                    </View>
                    <View style={{ ...styles.tableRow, height: 18 }}>
                        <Text>RG:</Text>
                    </View>
                    <View style={{ height: 18 }} />
                    <View style={{ ...styles.tableRow, textAlign: 'center', justifyContent: 'center' }}>
                        <Text>Assinatura / Carimbo:</Text>
                    </View>
                </View>
            </View>
        </View>
        <View style={{ ...styles.textItems, padding: 0, marginTop: 10, fontSize: 10 }}>
            <Text>
                Baixa da OS Data e Hora: ___/___/______       ____:____              Atendido Por:________________________________
            </Text>
        </View>
    </Document>
);

const TemplateDefault = ({ ordemServicos }: { ordemServicos: (IPostConsultarOSsResponseOS | IOrdemServico)[] }) => {

    const [barcodes, setBarcodes] = useState<{ [key: string]: string }>({});

    useEffect(() => {
        const newBarcodes: { [key: string]: string } = {};
        ordemServicos.forEach((os) => {
            const canvas = document.createElement('canvas');
            JsBarcode(canvas, `${os.chamado}`, {
                displayValue: false
            });
            newBarcodes[os.chamado] = canvas.toDataURL();
        });
        setBarcodes(newBarcodes)
    }, [ordemServicos]);

    return (
        <Document>
            {ordemServicos.map((ordemServico, index) => (
                <Page size="A4" key={index} style={styles.page}>
                    <Cabecalho ordemServico={ordemServico} barcodeValue={barcodes[ordemServico.chamado]} />
                    <Corpo ordemServico={ordemServico} />
                    <Footer />
                </Page>
            ))}
        </Document>
    );
};

export default TemplateDefault;
