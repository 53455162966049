import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
    page: {
        padding: 20,
        fontSize: 10,
        fontFamily: 'Helvetica'
      },
      header: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center', 
      },
      logo: {
        width: 90,
        height: 20,
      },
      title: {
        textAlign: 'center',
        fontSize: 12,
        textTransform: 'uppercase',
        marginBottom: 10
      },
      section: {
        padding: 2,
      },
      row: {
        flexDirection: 'row',
        justifyContent: 'space-between'
      },
      rowHeader:{
        padding: '0 20px',
        flexDirection: 'row',
        justifyContent: 'space-between',
        backgroundColor: "lightgray"
      },
      rowHeaderGray:{
        flexDirection: 'row',
        justifyContent: 'center',
        backgroundColor: "#E9EAEA"
      },
      rowBorder:{
        height: '14px',
        flexDirection: 'row',
        justifyContent: 'space-between',
        border: 1,
        borderColor: '#000',
        alignItems: 'center'
      },
      rowBorderItem: {
        fontSize:10, 
        height: 18, 
        margin: 1
      },
      rowFooterTecnico:{
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: 3
      },
      rowFooterComponentes:{
        flexDirection: 'row',
        justifyContent: 'space-between',
        fontSize: '10px',
        padding: 2
      },
      rowBorderTermos:{
        flexDirection: 'row',
        //padding: '0 20px',
        border: 1,
        borderColor: '#000',
        fontSize: '7px',
        textAlign: 'justify'
      },
      table: {
        display: 'flex',
        width: 'auto',
        marginVertical: 0,
        borderWidth: 1,
        borderColor: '#000',
      },
      tableRow: {
        flexDirection: 'row'
      },
      tableSignature: {
        display: 'flex',
        width: 'auto',
        marginVertical: 2,
      },
      tableRowSignature: {
        flexDirection: 'row'
      },
      tableColHeader: {
        width: '100%',
        borderStyle: 'solid',
        borderColor: '#000',
        borderWidth: 1,
        padding: 5,
        fontWeight: 400,
      },
      tableCol: {
        width: '100%',
        borderStyle: 'solid',
        borderColor: '#000',
        borderWidth: 1,
        padding: 2
      },
      tableColItem: {
        width: '100%',
        borderStyle: 'solid',
        borderColor: '#000',
        borderWidth: 1,
      },
      tableColItemSignature: {
        width: '100%',
      },
      tableCellHeader: {
        textAlign: 'center',
        fontSize: 10
      },
      tableCell: {
        textAlign: 'center',
        fontSize: 10
      },
      tableClient: {
        display: 'flex',
        width: 'auto',
        marginVertical: 0,
        // borderWidth: 1,
        // borderColor: '#000',
      },
      tableRowClient: {
        flexDirection: 'row'
      },
      tableColItemClient: {
        width: '100%',
      },
      signature: {
        marginTop: 20,
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: '0 30px',
        border: '1px solid #000'
      },
      signatureItem: {
        textAlign: 'center'
      },
      textItems: {
        fontSize: 9, 
        padding: '0 20px',
        // verticalAlign: 'sub'
      },
      footer: {
        marginTop: 10,
        textAlign: 'left',
        fontSize: 8,
        borderBottom: '1px dashed #000',
        paddingTop: 5
      }
  });