import React, { useState, useContext, useEffect } from "react";
import _ from "lodash";
import { AuthContext } from "../../../contexts/auth";
import Modal from "../../../components/modal";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import Collapse from '../../../components/collapse/collapse';
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import {
  Container,
  Body,
  InfoLabel,
  ButtonGrid,
} from "./modalSalvarPreset_Styles";
import { AxiosResponse } from "axios";
import { siteTheme } from "../../../styles/themeVariables";
import Input from "../../../components/Input/Input";

const { color } = siteTheme

const ModalSalvarPreset = ({
  isModalOpen,
  handleClose,
  listaPresets = [],
  selectedPreset = null,
  presetAtual = {},
}: {
  isModalOpen: boolean,
  handleClose: Function,
  listaPresets: any[],
  selectedPreset?: { id: string, name: string, preset: any | null } | null,
  presetAtual: any,
}) => {
  const { user } = useContext(AuthContext);

  const [isOpen, setIsOpen] = useState(isModalOpen);

  const [existingPreset, setExistingPreset] = useState<any>(selectedPreset);
  const [nomePreset, setNomePreset] = useState<string>(!!existingPreset?.id ? existingPreset.name : "");
  const [updatePreset, setUpdatePreset] = useState(false);
  const [presetJaCadastrado, setPresetJaCadastrado] = useState(false);

  const [tipomsgAlert, setTipoMsgAlert] = useState<'success' | 'info' | 'warning' | 'error'>("success");
  const [msgAlert, setMsgAlert] = useState("OS encaminhada com  sucesso!");
  const [msgAlertSuccess, setMsgAlertSuccess] = useState("OS encaminhada com  sucesso!");
  const [msgAlertError, setMsgAlertError] = useState("OS encaminhada com  sucesso!");
  const [openAlert, setOpenAlert] = useState(false);
  const [openAlertSuccess, setOpenAlertSuccess] = useState(false);
  const [openAlertError, setOpenAlertError] = useState(false);
  const [disableActions, setDisableActions] = useState(false);

  const handleSalvarPreset = (updateExistingPreset?: boolean) => {
    if (!!existingPreset?.id || !!presetJaCadastrado) handleUpdatePreset(updateExistingPreset);
    else handleIncludePreset();
  };

  const handleIncludePreset = async () => {
    //Validações
    setDisableActions(true);

    if (!!openAlert) {
      setOpenAlert(false);
      setOpenAlertError(false);
      setOpenAlertSuccess(false);
      setMsgAlertSuccess('')
      setMsgAlertError('')
    }

    if (!nomePreset) {
      setTipoMsgAlert("error");
      setMsgAlertError("Insira um nome para o preset!");
      setOpenAlertError(true);
      setDisableActions(false);
      return;
    }

    const presetJaExiste = listaPresets.find(p => p.name === nomePreset) || null;

    if (presetJaExiste) {
      setDisableActions(false);
      return setPresetJaCadastrado(true);
    }

    // await salvarPreset({
    //   nome: nomePreset,
    //   preset: JSON.stringify(presetAtual),
    // })
    //   .then((retorno: any) => {
    //     const { data } = retorno as AxiosResponse

    setTipoMsgAlert("success");
    setMsgAlertSuccess('O preset foi salvo com sucesso!');
    setOpenAlertSuccess(true);
    //   })
    //   .catch((erro) => {
    //     //Erro na requisição à API
    //     setMsgAlertError("Não foi possível salvar o Preset");
    //     setOpenAlertError(true);
    //     setDisableActions(false);
    //   });
    setDisableActions(false);
  };

  const handleUpdatePreset = async (updateExistingPreset?: boolean) => {
    //Validações
    setDisableActions(true);

    if (!!openAlert) {
      setOpenAlert(false);
      setOpenAlertError(false);
      setOpenAlertSuccess(false);
      setMsgAlertSuccess('')
      setMsgAlertError('')
    }

    if (!nomePreset) {
      setTipoMsgAlert("error");
      setMsgAlertError("Insira um nome para o preset!");
      setOpenAlertError(true);
      setDisableActions(false);
      return;
    }

    // await atualizarPreset({
    //   id: !!updateExistingPreset ? listaPresets.find(p => p.name === nomePreset)?.id : existingPreset?.id,
    //   nome: nomePreset,
    //   preset: JSON.stringify(presetAtual),
    // })
    //   .then((retorno: any) => {
    //     const { data } = retorno as AxiosResponse

    setTipoMsgAlert("success");
    setMsgAlertSuccess('O preset atualizado com sucesso!');
    setOpenAlertSuccess(true);
    //   })
    //   .catch((erro) => {
    //     //Erro na requisição à API
    //     setMsgAlertError("Não foi possível atualizar o Preset");
    //     setOpenAlertError(true);
    //     setDisableActions(false);
    //   });
    setDisableActions(false);
  };

  useEffect(() => {
    setIsOpen(isModalOpen)
  }, [isModalOpen])

  return (
    <Modal
      handleCloseModal={handleClose}
      title={(!!existingPreset?.id || !!presetJaCadastrado) ? "Salvar Preset" : "Salvar Novo Preset"}
      isOpen={isOpen}
      sx={{
        '& .MuiDialog-paper': {
          maxWidth: '650px',
        }
      }}
    >
      <Container>
        {(!existingPreset?.id && !presetJaCadastrado) &&
          <Body>
            <InfoLabel>
              Atribua um nome para essa configuração
            </InfoLabel>

            <Input
              className={"ConsultaOS-Input"}
              dimension="sm"
              id="input-nomePreset"
              label={""}
              placeholder={"Nome do Preset"}
              value={nomePreset}
              onChange={(e) => setNomePreset(e.target.value)}
              maxLength={255}
              disabled={disableActions}
            />

            <ButtonGrid>
              <Button
                id={"ModalSalvarPreset-BotaoSalvar"}
                disabled={disableActions}
                onClick={() => handleSalvarPreset()}
                sx={{
                  borderRadius: '15px',
                  bgcolor: color.primary,
                  color: "#FFF",
                  "&:hover": {
                    backgroundColor: color.primary,
                    color: "#FFF",
                  },
                  fontFamily: "Open Sans",
                }}
              >
                Salvar
              </Button>

              {!openAlertSuccess &&
                <Button
                  id={"ModalSalvarPreset-BotaoCancelar"}
                  disabled={disableActions}
                  onClick={() => handleClose()}
                  sx={{
                    border: `1px solid ${color.primary}`,
                    borderRadius: '15px',
                    fontFamily: "Open Sans",
                  }}
                >
                  Cancelar
                </Button>
              }
            </ButtonGrid>
          </Body>
        }

        {(!updatePreset && !!existingPreset?.id) &&
          <Body>
            <InfoLabel>
              Deseja atualizar essa configuração?
            </InfoLabel>

            <Input
              className={"ConsultaOS-Input"}
              dimension="sm"
              id="input-nomePreset"
              label={""}
              placeholder={"Nome do Preset"}
              value={nomePreset}
              onChange={(e) => setNomePreset(e.target.value)}
              maxLength={255}
              disabled={true}
            />

            <ButtonGrid>
              <Button
                id={"ModalSalvarPreset-BotaoSalvar"}
                disabled={disableActions}
                onClick={() => handleSalvarPreset()}
                sx={{
                  borderRadius: '15px',
                  bgcolor: color.primary,
                  color: "#FFF",
                  "&:hover": {
                    backgroundColor: color.primary,
                    color: "#FFF",
                  },
                  fontFamily: "Open Sans",
                }}
              >
                Sim
              </Button>

              {!openAlertSuccess &&
                <Button
                  id={"ModalSalvarPreset-BotaoCancelar"}
                  disabled={disableActions}
                  onClick={() => setUpdatePreset(true)}
                  sx={{
                    border: `1px solid ${color.primary}`,
                    borderRadius: '15px',
                    fontFamily: "Open Sans",
                  }}
                >
                  Não
                </Button>
              }
            </ButtonGrid>
          </Body>
        }

        {(!!updatePreset && !presetJaCadastrado) &&
          <Body>
            <InfoLabel>
              Escolha um novo nome para este preset
            </InfoLabel>

            <Input
              className={"ConsultaOS-Input"}
              dimension="sm"
              id="input-nomePreset"
              label={""}
              placeholder={"Nome do Preset"}
              value={nomePreset}
              onChange={(e) => setNomePreset(e.target.value)}
              maxLength={255}
              disabled={disableActions}
            />

            <ButtonGrid>
              <Button
                id={"ModalSalvarPreset-BotaoSalvar"}
                disabled={disableActions}
                onClick={handleIncludePreset}
                sx={{
                  borderRadius: '15px',
                  bgcolor: color.primary,
                  color: "#FFF",
                  "&:hover": {
                    backgroundColor: color.primary,
                    color: "#FFF",
                  },
                  fontFamily: "Open Sans",
                }}
              >
                Salvar
              </Button>
            </ButtonGrid>
          </Body>
        }

        {!!presetJaCadastrado &&
          <Body>
            <InfoLabel>
              Este nome já foi utilizado anteriormente. Deseja atualizar?
            </InfoLabel>

            <ButtonGrid>
              <Button
                id={"ModalSalvarPreset-BotaoSalvar"}
                disabled={disableActions}
                onClick={() => handleSalvarPreset(true)}
                sx={{
                  borderRadius: '15px',
                  bgcolor: color.primary,
                  color: "#FFF",
                  "&:hover": {
                    backgroundColor: color.primary,
                    color: "#FFF",
                  },
                  fontFamily: "Open Sans",
                }}
              >
                Sim
              </Button>

              {!openAlertSuccess &&
                <Button
                  id={"ModalSalvarPreset-BotaoCancelar"}
                  disabled={disableActions}
                  onClick={() => {
                    if (!existingPreset?.id) {
                      setUpdatePreset(false);
                      setPresetJaCadastrado(false);
                    } else {
                      setPresetJaCadastrado(false);
                    }
                  }}
                  sx={{
                    border: `1px solid ${color.primary}`,
                    borderRadius: '15px',
                    fontFamily: "Open Sans",
                  }}
                >
                  Não
                </Button>
              }
            </ButtonGrid>
          </Body>
        }

        <Collapse
          onClose={setOpenAlertSuccess}
          in={openAlertSuccess}
          sx={{
            position: "absolute",
            left: 0,
            right: 0,
            bottom: 0,
          }}
        >
          <Alert
            severity='success'
            icon={<div />}
            variant="filled"
            sx={{
              mb: 2,
              bgcolor: "#178B319E",
              height: "67px",
              boxSizing: "border-box",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: 0,
              borderRadius: 0,
            }}
            action={
              <IconButton
                id={"ModalEncaminharOS-AlertCloseIcon"}
                aria-label="close"
                color="inherit"
                size="medium"
                onClick={() => setOpenAlertSuccess(false)}
              >
                <CloseIcon
                  fontSize="inherit"
                  sx={{
                    color: "white",
                  }}
                />
              </IconButton>
            }
          >
            <span
              style={{
                color: "white",
                fontSize: "14px",
              }}
              dangerouslySetInnerHTML={{ __html: msgAlertSuccess }}
            />
          </Alert>
        </Collapse>

        <Collapse
          onClose={setOpenAlertError}
          in={openAlertError}
          sx={{
            position: "absolute",
            left: 0,
            right: 0,
            bottom: 0,
          }}
        >
          <Alert
            severity='error'
            icon={<div />}
            variant="filled"
            sx={{
              mb: 2,
              bgcolor: "#FF23239E",
              height: "67px",
              boxSizing: "border-box",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: 0,
              borderRadius: 0,
            }}
            action={
              <IconButton
                id={"ModalEncaminharOS-AlertCloseIcon"}
                aria-label="close"
                color="inherit"
                size="medium"
                onClick={() => setOpenAlertError(false)}
              >
                <CloseIcon
                  fontSize="inherit"
                  sx={{
                    color: "white",
                  }}
                />
              </IconButton>
            }
          >
            <span
              style={{
                color: "white",
                fontSize: "14px",
              }}
              dangerouslySetInnerHTML={{ __html: msgAlertError }}
            />
          </Alert>
        </Collapse>
      </Container>
    </Modal>
  );
};

export default ModalSalvarPreset;
