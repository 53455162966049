import styled from "styled-components";
import { siteTheme } from "../../../styles/themeVariables";

const { color } = siteTheme;

export const Section = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
`;

export const SubHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 25px 0 35px;
`;

export const SubHeaderTitle = styled.div`
    font-family: Noto Sans;
    font-size: 2rem;
    font-weight: 700;
    color: #797d8c;
    letter-spacing: 0.9px;
`;

export const Body = styled.div`
  margin-top: 30px;
  display: flex;
`;
