import { AxiosResponse } from "axios";
import { authVerify } from "../middlewares/authVerify";
import { api } from "./api";

const passAuthURLs = [
  "/Usuario/Login",
  "/Usuario/Verifiy2FA",
];

export const apiPostGeneric = async <T>(url: string, params = {}, headers = {}): Promise<T> => {
  if (passAuthURLs.includes(url)) {
    return api.post<T>(url, params, { headers }).then(response => response.data);
  }

  try {
    let response = await api.post(url, params, { headers }) as AxiosResponse<T>;
    return responseSucess(response);
  } catch (error: any) {
    verifyErrors(error);
    authVerify(error);
    return responseError(error);
  }
};

export const apiGetGeneric = async <T>(url: string, params = {}, headers = {}) => {
  try {
    let response = await api.get<T>(url, { params, headers, }) as AxiosResponse<T>;
    return responseSucess(response);
  } catch (error: any) {
    authVerify(error);
    return responseError(error);
  }
};













const responseSucess = <T>(response: AxiosResponse<T>): T => {
  return response.data;
};

const verifyErrors = (error: any) => {
};

const responseError = (error: any): any => {
  return new Promise((resolve, reject) => reject(error));
};
