import React, { useContext, useState, useEffect } from 'react'
import './consultarPerfil.scss'
import { useNavigate } from 'react-router-dom';
import ConsultarPerfilTabela from './consultarPerfilTabela';
import { HomeContext } from '../../../contexts/homeContext';
import Header from '../../../components/header/header'
import Button from '../../../components/Button/styles';
import MultipleSelectCheckmarksId from '../../../components/seletorMultiploId/seletorMultiploId';
import { Breadcrumb, BreadcrumbItem } from '../../../components/breadcrumb/breadcrumb';
import { getPerfilById, getPerfis } from '../services/services';
import { AxiosResponse } from 'axios';
import { IGetPerfilResponse } from '../types';
import _ from 'lodash'
import FullscreenLoading from '../../../components/fullscreenLoading/fullscreenLoading';
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import Collapse from '../../../components/collapse/collapse';
import CloseIcon from "@mui/icons-material/Close";
import { AuthContext } from '../../../contexts/auth';
import { removeSpecialCharSimple } from '../../../helpers/removeSpecialCharSimple';

const ConsultarPerfil = () => {
  const { setMenuLateral, setSelectedPerfil, setPerfilLista, perfilLista } = useContext(HomeContext);

  const { getPermissoesDaTela } = useContext(AuthContext);
  const [ permissoes, setPermissoes] = useState(getPermissoesDaTela("Consultar perfil"));
  
  const navigate = useNavigate()

  const [ perfil, setPerfil ] = useState([]);
  const [ status, setStatus ] = useState([]);
  const [ tableData, setTableData ] = useState<IGetPerfilResponse[]>([] as IGetPerfilResponse[]);
  const [tipomsgAlert, setTipoMsgAlert] = useState<'success' | 'info' | 'warning' | 'error'>("success");
  const [msgAlert, setMsgAlert] = useState("OS encaminhada com  sucesso!");
  const [openAlert, setOpenAlert] = useState(false);
  const [ loading, setLoading ] = useState(false);
  

  const statusLista = [
    {
      name: '--',
      id: ''
    }, 
    {
      name: 'Ativo',
      id: '1'
    }, 
    {
      name: 'Inativo',
      id: '0'
    }
  ]

  const handleSearch = async () => {
    const data: IGetPerfilResponse[] = await handleGetPerfis()
    if(data){
      setTableData(_.isArray(data) ? data : [data])
    }
  }

  const handleGetPerfis = async () => {
    setLoading(true)
    let data: any
    if(perfil[0]){
      data = await getPerfilWithId()
    } else {
      data = await getPerfilWithoutId()
    }

    setLoading(false)

    return data
  }

  const getPerfilWithId = async () => {
    try{
      const { data } = await getPerfilById(Number(perfil[0])) as AxiosResponse

      return data
      
    } catch(e){
      setTipoMsgAlert("error");
      setMsgAlert("Erro ao pesquisar perfil");
      setOpenAlert(true);
      setTableData([])
      setLoading(false)
    }
  }

  const getPerfilWithoutId = async (showAlert = true) => {
    try{

      const isActive = !!status[0] ? Boolean(Number(status[0])) : undefined

      const { data } = await getPerfis(isActive) as AxiosResponse

      if(_.isEmpty(perfilLista)){
        let perfis = data?.map((item: IGetPerfilResponse) => {
          return(
            {name: item.nome, id: item.id.toString()}
          )
        })
  
        perfis.unshift({name: '--', id: ''})
  
        setPerfilLista(perfis)
      }

      return data

    } catch(e){
      if (showAlert) {
        setTipoMsgAlert("error");
        setMsgAlert("Erro ao pesquisar perfil");
        setOpenAlert(true);
      }
      setTableData([])
      setLoading(false)
    }
  }

  const handleNovoPerfil = () => {
    setSelectedPerfil({} as IGetPerfilResponse)
    navigate('/IncluirPerfil')

  }

  const funcionalidadeTemPermissao = (funcionalidade: string) => {
    let hasPermission = false;
    hasPermission = !![...permissoes].filter((f: {nome: string}) => removeSpecialCharSimple(f.nome).includes(removeSpecialCharSimple(funcionalidade))).length;
    return hasPermission;
  };

  useEffect(() => {
    getPerfilWithoutId(false);
  }, []);
  
  return(
    <section className="ConsultarPerfil">
      <Header setMenuLateral={() => setMenuLateral(true)} title="Consultar Perfil" />
      <Breadcrumb>
        <BreadcrumbItem>Perfil</BreadcrumbItem>
      </Breadcrumb>

      {loading && <FullscreenLoading />}

      <div className="Container">
        <div className="ConsultarPerfilPesquisar">
          <span className='FiltroSpan'>Filtros</span>
          <MultipleSelectCheckmarksId
           nodata={"Nenhum perfil encontrado"}
           options={perfilLista}
           value={perfil}
           onChange={(e: any) => setPerfil(e)}
           placeholder={"Selecionar perfil"}
           id="multiple-checkbox-perfil"
           idOption="multiple-checkbox-option-perfil-"
           labelId="multiple-checkbox-label-perfil"
           multiple={false}
           className='ConsultarPerfilSelectPerfil'
           dimension='sm'
          />
          <MultipleSelectCheckmarksId
           nodata={"Nenhum status encontrado"}
           options={statusLista}
           value={status}
           onChange={(e: any) => setStatus(e)}
           placeholder={"Selecionar status"}
           id="multiple-checkbox-status"
           idOption="multiple-checkbox-option-status-"
           labelId="multiple-checkbox-label-status"
           multiple={false}
           className="ConsultarPerfilSelectStatus"
           dimension='sm'
          />
          <Button dimension='sm' variant='primary' onClick={handleSearch}>Pesquisar</Button>

          {!!funcionalidadeTemPermissao("Novo Registro") ?
            <Button dimension='sm' variant='grey' onClick={handleNovoPerfil}>Novo Perfil</Button>
            :
            <Button style={{cursor: 'not-allowed', opacity: 0.5}} dimension='sm' variant='grey' disabled={true}>Novo Perfil</Button>
          }
        </div>

        <Collapse onClose={setOpenAlert} in={openAlert}>
          <Alert
            severity={tipomsgAlert}
            icon={<div />}
            variant="filled"
            sx={{
              mb: 2,
              bgcolor: tipomsgAlert === "success" ? "#178B319E" : "rgb(255, 35, 35, 0.7)",
              height: "67px",
              borderRadius: "15px",
              boxSizing: "border-box",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginTop: "20px",
              marginBottom: 0,
            }}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="medium"
                onClick={() => setOpenAlert(false)}
              >
                <CloseIcon
                  id={"ModalEncaminharOS-AlertCloseIcon"}
                  fontSize="inherit"
                  sx={{
                    color: "white",
                  }}
                />
              </IconButton>
            }
          >
            <span
              style={{
                color: "white",
                fontSize: "16px",
              }}
              dangerouslySetInnerHTML={{__html: msgAlert}}
            />
          </Alert>
        </Collapse>
        {!_.isEmpty(tableData) && <ConsultarPerfilTabela tableData={tableData} editar={!!funcionalidadeTemPermissao("Editar Registro")}/>}
      </div>
    </section>
  )
}

export default ConsultarPerfil