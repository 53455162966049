import styled from "styled-components";
import { Button } from "@mui/material";
import { siteTheme } from "../../../styles/themeVariables";

const { color } = siteTheme;

export const BoxFiltros = styled.div`
  width: 100%;
  display: flex;

  /* height: 60px;
  border: 1px solid red; */
`;

export const SearchButton = styled(Button)`
  font-size: 14px !important;
  height: 56px !important;
  width: 151px !important;
  margin-left: 6px !important;
  border-radius: 15px !important;
  padding: 0 40px 0 40px !important;
`;