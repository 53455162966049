import React, { useContext } from 'react'
import { Table, TableRow } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import * as Styles from "../../../components/table/styles";
import {ReactComponent as EditIcon} from "../../../assets/icons/EditIcon.svg";
import moment from 'moment';
import { HomeContext } from '../../../contexts/homeContext';
import { useNavigate } from 'react-router-dom';
import { IGetPrestadoresResponsaveisResponse, IPrestadorResponsavel } from '../types';

interface IConsultarPerfilTable {
  tableData: IPrestadorResponsavel[]
  allowEdit?: boolean
}

const ConsultaPrestadorResponsavelTabela = ({tableData, allowEdit = true}: IConsultarPerfilTable) => {

  const { setPrestadorResponsavel } = useContext(HomeContext)

  const navigate = useNavigate()

  const header = [
    'Status',
    'ID Mobyan',
    'ID Oppay',
    'Prestador Responsável',
    'Editar'
  ]

  const handleEditPrestador = (prestadorResponsavel: IPrestadorResponsavel) => {
    setPrestadorResponsavel(prestadorResponsavel)
    navigate('/EditaPrestadorResponsavel')
  }

  return(
    <TableContainer component={Styles.StyledPaper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            {header?.map((title) => 
              <Styles.StyledTableCell className={`${title} center`} key={title}>{title}</Styles.StyledTableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {tableData?.map(item => (
            <TableRow key={item?.id}>
              <Styles.StyledTableCell component="th" scope="row">
                {item?.ativo ? 'Ativo' : 'Inativo'}
              </Styles.StyledTableCell>
              <Styles.StyledTableCell component="th" scope="row">
                {item?.id}
              </Styles.StyledTableCell>
              <Styles.StyledTableCell component="th" scope="row">
                {item?.idOppay}
              </Styles.StyledTableCell>
              <Styles.StyledTableCell component="th" scope="row">
                {item?.descricao}
              </Styles.StyledTableCell>
              <Styles.StyledTableCell className='center' component="th" scope="row">
                {!!allowEdit ?
                  <EditIcon onClick={() => handleEditPrestador(item)} style={{ cursor: 'pointer' }} />
                  :
                  <EditIcon style={{ cursor: 'not-allowed', opacity: 0.5 }} />
                }
              </Styles.StyledTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default ConsultaPrestadorResponsavelTabela