import React, { useState, useContext, useEffect } from "react";
import "./osPendente.scss";
import { HomeContext } from "../../../contexts/homeContext";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import Collapse from '../../../components/collapse/collapse';
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import Header from "../../../components/header/header";
import Pagination from "../../../components/pagination/paginacao";
import { Breadcrumb, BreadcrumbItem } from "../../../components/breadcrumb/breadcrumb";
import { CircularProgress } from "@mui/material";
import { IOrdemServico } from "../../../contexts/types/farolTypes";
import { siteTheme } from "../../../styles/themeVariables";
import { useNavigate } from "react-router-dom";
import DoubleSortIcon from "../../../components/doubleSortIcon/doubleSortIcon";
import CardOSPendentes from "../../../components/cardOSPendentes/cardOSPendentes";
import ModalErrorsDetails from "./modalErrorsDetails";

const { color } = siteTheme;

const OsPendente = () => {
  const {
    setMenuLateral,
    osselected,
    getOSsByID,
    getOSPendente,
    osPendente,
    reenviarOSPendente,
    loadingOSPendente,
    ordenacaoOSPendente,
    setOrdenacaoOSPendente,
    ordenacaoReverseOSPendente,
    setOrdenacaoReverseOSPendente,
  } = useContext(HomeContext);

  const navigate = useNavigate()

  const [ossParaEncaminhar, setOssParaEncaminhar] = useState(osselected);
  const [tipomsgAlert, setTipoMsgAlert] = useState<'success' | 'info' | 'warning' | 'error'>("success");
  const [msgAlert, setMsgAlert] = useState("OS encaminhada com  sucesso!");
  const [openAlert, setOpenAlert] = useState(false);
  const [disableActions, setDisableActions] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [idOSModal, setIdOSModal] = useState<number | null>(null);


  const handleOpenModal = (idOS: number) => {
    setIdOSModal(idOS);
    setIsModalOpen(true);
  };
  
  const handleCloseModal = () => {
    setIdOSModal(null);
    setIsModalOpen(false);
  };

  const listaOSPaginada = () => {
    const firstPageIndex = (currentPage - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;
    return osPendente.slice(firstPageIndex, lastPageIndex);
  };

  const handleClickRecarregar = async () => {
    getOSPendente()
  };

  const handleEnviarOSPendente = async (item: IOrdemServico) => {
    try {
      await reenviarOSPendente(item)

      setTipoMsgAlert('success')
      setMsgAlert('OS reencaminhada com sucesso')
      setOpenAlert(true)

      getOSPendente()

    } catch (e) {
      setTipoMsgAlert('error')
      setMsgAlert('Erro ao reencaminhar OS')
      setOpenAlert(true)
    }

  }

  const handleClickOrdenar = (sort: string) => {
    if (sort === ordenacaoOSPendente[0]) {

      if (!ordenacaoReverseOSPendente) {
        setOrdenacaoReverseOSPendente(true);
      } else {
        setOrdenacaoOSPendente([]);
        setOrdenacaoReverseOSPendente(false);
      }
    } else {
      setOrdenacaoOSPendente([sort]);
      setOrdenacaoReverseOSPendente(false);
    }
  };

  const activeArrowUP = (sort: string) => {
    if (sort === ordenacaoOSPendente[0]) {
      return ordenacaoReverseOSPendente;
    } else {
      return false;
    }
  };

  const activeArrowDown = (sort: string) => {
    if (sort === ordenacaoOSPendente[0]) {
      return !ordenacaoReverseOSPendente;
    } else {
      return false;
    }
  };

  useEffect(() => {
    getOSPendente()
  }, [])

  return (
    <section className="OsPendente">
      <Header setMenuLateral={() => setMenuLateral(true)} title="OS's Pendentes" />

      <Breadcrumb>
        <BreadcrumbItem onClick={() => navigate("/FarolOS")}>Visualização de OS's</BreadcrumbItem>
        <BreadcrumbItem>OS's Pendentes</BreadcrumbItem>
      </Breadcrumb>

      <div className="OsPendente-Autocomplete">
        <Button
          id={"OsPendente-BotaoEncaminhar"}
          disabled={disableActions}
          onClick={handleClickRecarregar}
          sx={{
            bgcolor: color.primary,
            color: "#FFF",
            "&:hover": {
              backgroundColor: color.primary,
              color: "#FFF",
            },
            borderRadius: "15px",
            minWidth: "126px",
            fontSize: "12px",
            fontFamily: "Open Sans",
          }}
        >
          Recarregar
        </Button>
      </div>

      <Collapse onClose={setOpenAlert} in={openAlert}>
        <Alert
          severity={tipomsgAlert}
          icon={<div />}
          variant="filled"
          sx={{
            mb: 2,
            bgcolor: tipomsgAlert === "success" ? "#178B319E" : "rgb(255, 35, 35, 0.7)",
            height: "67px",
            borderRadius: "15px",
            boxSizing: "border-box",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "20px",
          }}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="medium"
              onClick={() => setOpenAlert(false)}
            >
              <CloseIcon
                id={"ModalOsPendente-AlertCloseIcon"}
                fontSize="inherit"
                sx={{ color: 'white' }}
              />
            </IconButton>
          }
        >
          <span
            style={{
              color: "white",
              fontSize: "16px",
            }}
          >
            {msgAlert}
          </span>
        </Alert>
      </Collapse>

      <div className={`OSPendente-ListaOS`}>
        <div className="OSPendente-CardOS" >
          <div className="OSPendente-CardOS-Topo Cabecalho">

            <div />

            <div className="OSPendente-CardOS-Collum Collumcenter">
              <span className="OSPendente-CardOS-Label LabelBold">OS</span>
              <DoubleSortIcon
                activeArrowUp={activeArrowUP("OS")}
                activeArrowDown={activeArrowDown("OS")}
                onClick={() => handleClickOrdenar("OS")} />
            </div>

            <div className="OSPendente-CardOS-Collum Collumcenter" >
              <span className="OSPendente-CardOS-Label LabelBold">Referência</span>
              <DoubleSortIcon
                activeArrowUp={activeArrowUP("Referência")}
                activeArrowDown={activeArrowDown("Referência")}
                onClick={() => handleClickOrdenar("Referência")} />
            </div>

            <div
              className="OSPendente-CardOS-Collum Collumcenter CollumBreak-word"
            >
              <span className="OSPendente-CardOS-Label LabelBold">Status</span>
              <DoubleSortIcon
                activeArrowUp={activeArrowUP("Status")}
                activeArrowDown={activeArrowDown("Status")}
                onClick={() => handleClickOrdenar("Status")} />
            </div>

            <div className="OSPendente-CardOS-Collum CollumBreak-word Collumcenter">
              <span className="OSPendente-CardOS-Label LabelBold">Serviço</span>
              <DoubleSortIcon
                activeArrowUp={activeArrowUP("Serviço")}
                activeArrowDown={activeArrowDown("Serviço")}
                onClick={() => handleClickOrdenar("Serviço")} />
            </div>

            <div className="OSPendente-CardOS-Collum Collumcenter">
              <span className="OSPendente-CardOS-Label LabelBold">Cidade</span>
              <DoubleSortIcon
                activeArrowUp={activeArrowUP("Cidade")}
                activeArrowDown={activeArrowDown("Cidade")}
                onClick={() => handleClickOrdenar("Cidade")} />
            </div>

            <div className="OSPendente-CardOS-Collum Collumcenter">
              <span className="OSPendente-CardOS-Label LabelBold">Cep</span>
              <DoubleSortIcon
                activeArrowUp={activeArrowUP("CEP")}
                activeArrowDown={activeArrowDown("CEP")}
                onClick={() => handleClickOrdenar("CEP")} />
            </div>

            <div className="OSPendente-CardOS-Collum Collumcenter">
              <span className="OSPendente-CardOS-Label LabelBold" style={{marginRight: "30px"}}>Erro</span>
            </div>

            <div className="OSPendente-CardOS-Collum Collumcenter">
              <span className="OSPendente-CardOS-Label LabelBold">Reencaminhar</span>
            </div>
          </div>
        </div>
      </div>

      {(!!isModalOpen && !!idOSModal) &&
        <ModalErrorsDetails
          handleClose={handleCloseModal}
          isModalOpen={isModalOpen}
          idOS={idOSModal}
        />
      }

      <div className={`OsPendente-ListaOS ${loadingOSPendente && 'Loading'}`}>
        {
          loadingOSPendente ? (
            <CircularProgress />
          ) :
            listaOSPaginada().length > 0 ?
              listaOSPaginada()?.map((item: IOrdemServico) => {
                return (
                  <CardOSPendentes
                    item={item}
                    key={item.chamado}
                    handleEnviarOSPendente={() => handleEnviarOSPendente(item)}
                    handleOpenModal={() => handleOpenModal(item.chamado)}
                  />
                );
              }) : (
                <div className="NenhumOs">
                  <p>Nenhuma OS pendente</p>
                </div>
              )
        }
      </div>

      <Pagination
        className="pagination-bar"
        currentPage={currentPage}
        totalCount={osPendente.length}
        pageSize={pageSize}
        setPageSize={setPageSize}
        onPageChange={(page: number) => setCurrentPage(page)}
      />
    </section>
  );
};

export default OsPendente;
