import styled from "styled-components";
import { Button } from "@mui/material";
import { siteTheme } from "../../../styles/themeVariables";
import { ReactComponent as EditIcon } from "../../../assets/icons/EditIcon.svg";

const { color } = siteTheme;

export const NewButton = styled(Button)`
  background: ${color.primary} !important;
  color: #FFF !important;
  font-size: 14px !important;
  height: 44px !important;
  border-radius: 15px !important;
  padding: 0 40px 0 40px !important;

  &.bloqueado {
    cursor: 'not-allowed' !important;
    opacity: 0.5;
  }
`;
