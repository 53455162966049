import { apiGet, apiPost, apiPut } from "../../../services/api";
import { IPostRegiao, IPutRegiao } from "../types";


const baseUrlOrdemServico = process.env.REACT_APP_API_ORDEMSERVICO;

export const getListaRegioesComFiltro = async (params: any) =>
    await apiGet(`${baseUrlOrdemServico}/regioes`, params)

export const postRegiao = async (params: IPostRegiao) =>
    await apiPost(`${baseUrlOrdemServico}/regioes`, params)
  
  export const putRegiao = async (params: IPutRegiao, idRegiao: number | string) =>
    await apiPut(`${baseUrlOrdemServico}/regioes/${idRegiao}`, params)