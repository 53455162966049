import React, { useContext } from "react";
import "./farolFiltroTotalOs.scss";
import PieChartSimple from "../pieChartSimple/pieChartSimple";
import { HomeContext } from "../../contexts/homeContext";
import { siteTheme } from "../../styles/themeVariables";

const {color} = siteTheme;

const COLORS1 = [color.primary, color.cinzaClaro];
const COLORS2 = [color.osVencida, color.cinzaClaro];
const COLORS3 = [color.osVenceHoje, color.cinzaClaro];
const COLORS4 = [color.osAVencer, color.cinzaClaro];
const COLORS5 = [color.osEmergencial, color.cinzaClaro];

const FarolfiltrototalOS = () => {
  const {
    totalOS,
    totalOSEmergencial,
    totalOSVencidas,
    totalOSVencemHoje,
    totalOSAVencer,
    filtroFarol,
    setFiltroFarol,
    totalCount,
    totalEmergencial,
    totalVencidas,
    totalVenceHoje,
    totalAVencer,
  } = useContext(HomeContext);

  const handleClickFiltroFarol = (filtro: string) => setFiltroFarol(filtro);

  const percentage = (partialValue = 100, totalValue = 0) => {
    if (totalValue === 0) return 100;

    let percentage = (100 * partialValue) / totalValue;

    return Math.round(percentage);
  };

  return (
    <div className="FarolOSFiltroTopo-TotalOSs">
      <div
        id={"FiltroFarolOSTotalOS"}
        className={
          "FarolOSFiltroTopo-TotalOSs-Bloco " +
          (filtroFarol === "0" ? "filtroSelecionado" : "")
        }
        onClick={() => handleClickFiltroFarol("0")}
      >
        <div className="FarolOSFiltroTopo-TotalOSs-Bloco-left">
          <div className="FarolOSFiltroTopo-TotalOSs-Bloco-title">Total de OS's</div>
          <div className="FarolOSFiltroTopo-TotalOSs-Bloco-number">
            {totalCount || 0}
          </div>
        </div>
        <div className="FarolOSFiltroTopo-TotalOSs-Bloco-right">
          <div className="divPieWrapperr">
            <PieChartSimple
              id={"PieChartSimpleTotal"}
              labelValue={percentage(totalCount, totalCount)}
              labelText={"%"}
              data={totalOS()}
              colors={COLORS1}
              positionX={20}
              positionY={20}
              innerRadius={20}
              outerRadius={25}
              labelFontSize={12}
            />
          </div>
        </div>
      </div>

      <div
        id={"FiltroFarolOSTotalOSEmergencial"}
        className={
          "FarolOSFiltroTopo-TotalOSs-Bloco borderColorOrange " +
          (filtroFarol === "1" ? "filtroSelecionado" : "")
        }
        onClick={() => handleClickFiltroFarol("1")}
      >
        <div className="FarolOSFiltroTopo-TotalOSs-Bloco-left">
          <div className="FarolOSFiltroTopo-TotalOSs-Bloco-title">
            Total de OS's emergencial
          </div>
          <div className="FarolOSFiltroTopo-TotalOSs-Bloco-number">
            {totalEmergencial || 0}
          </div>
        </div>
        <div className="FarolOSFiltroTopo-TotalOSs-Bloco-right">
          <div className="divPieWrapperr">
            <PieChartSimple
              id={"PieChartSimpleEmergencial"}
              labelValue={percentage(
                totalEmergencial,
                totalCount
              )}
              labelText={"%"}
              data={totalOSEmergencial()}
              colors={COLORS5}
              positionX={20}
              positionY={20}
              innerRadius={20}
              outerRadius={25}
              labelFontSize={12}
            />
          </div>
        </div>
      </div>

      <div
        id={"FiltroFarolOSTotalOSVencidas"}
        className={
          "FarolOSFiltroTopo-TotalOSs-Bloco borderColorRed " +
          (filtroFarol === "2" ? "filtroSelecionado" : "")
        }
        onClick={() => handleClickFiltroFarol("2")}
      >
        <div className="FarolOSFiltroTopo-TotalOSs-Bloco-left">
          <div className="FarolOSFiltroTopo-TotalOSs-Bloco-title">
            Total de OS's vencidas
          </div>
          <div className="FarolOSFiltroTopo-TotalOSs-Bloco-number">
            {totalVencidas || 0}
          </div>
        </div>
        <div className="FarolOSFiltroTopo-TotalOSs-Bloco-right">
          <div className="divPieWrapperr">
            <PieChartSimple
              id={"PieChartSimpleVencidas"}
              labelValue={percentage(
                totalVencidas,
                totalCount
              )}
              labelText={"%"}
              data={totalOSVencidas()}
              colors={COLORS2}
              positionX={20}
              positionY={20}
              innerRadius={20}
              outerRadius={25}
              labelFontSize={12}
            />
          </div>
        </div>
      </div>

      <div
        id={"FiltroFarolOSTotalOSVencemHoje"}
        className={
          "FarolOSFiltroTopo-TotalOSs-Bloco borderColorYellow " +
          (filtroFarol === "3" ? "filtroSelecionado" : "")
        }
        onClick={() => handleClickFiltroFarol("3")}
      >
        <div className="FarolOSFiltroTopo-TotalOSs-Bloco-left">
          <div className="FarolOSFiltroTopo-TotalOSs-Bloco-title">
            Total de OS's vencem hoje
          </div>
          <div className="FarolOSFiltroTopo-TotalOSs-Bloco-number">
            {totalVenceHoje || 0}
          </div>
        </div>
        <div className="FarolOSFiltroTopo-TotalOSs-Bloco-right">
          <div className="divPieWrapperr">
            <PieChartSimple
              id={"PieChartSimpleVencemHoje"}
              labelValue={percentage(
                totalVenceHoje,
                totalCount
              )}
              labelText={"%"}
              data={totalOSVencemHoje()}
              colors={COLORS3}
              positionX={20}
              positionY={20}
              innerRadius={20}
              outerRadius={25}
              labelFontSize={12}
            />
          </div>
        </div>
      </div>

      <div
        id={"FiltroFarolOSTotalOSAVencer"}
        className={
          "FarolOSFiltroTopo-TotalOSs-Bloco borderColorGreen " +
          (filtroFarol === "4" ? "filtroSelecionado" : "")
        }
        onClick={() => handleClickFiltroFarol("4")}
      >
        <div className="FarolOSFiltroTopo-TotalOSs-Bloco-left">
          <div className="FarolOSFiltroTopo-TotalOSs-Bloco-title">
            Total de OS's à vencer
          </div>
          <div className="FarolOSFiltroTopo-TotalOSs-Bloco-number">
            {totalAVencer || 0}
          </div>
        </div>
        <div className="FarolOSFiltroTopo-TotalOSs-Bloco-right">
          <div className="divPieWrapperr">
            <PieChartSimple
              id={"PieChartSimpleAVencer"}
              labelValue={percentage(
                totalAVencer,
                totalCount
              )}
              labelText={"%"}
              data={totalOSAVencer()}
              colors={COLORS4}
              positionX={20}
              positionY={20}
              innerRadius={20}
              outerRadius={25}
              labelFontSize={12}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FarolfiltrototalOS;
