import React, { useContext } from 'react';
import { Table, TableRow } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import * as Styles from "../../../components/table/styles";
import {ReactComponent as EditIcon} from "../../../assets/icons/EditIcon.svg";
import { HomeContext } from '../../../contexts/homeContext';
import { useNavigate } from 'react-router-dom';
import { IGetResponseGrupoServico, GrupoServico } from '../types';

interface IConsultarPerfilTable {
  tableData: IGetResponseGrupoServico
  allowEdit?: boolean
}

const ConsultaGrupoServicoTabela = ({tableData, allowEdit = true}: IConsultarPerfilTable) => {

  const { setGrupoServicoSelected } = useContext(HomeContext);

  const navigate = useNavigate();

  const header = [
    'ID',
    'Status',
    // 'Código',
    'ID Oppay',
    'Descrição',
    'Editar'
  ];

  const handleEditGrupoServico = (grupoServico: GrupoServico) => {
    setGrupoServicoSelected(grupoServico);
    navigate('/EditaGrupoServico');
  };

  return(
    <TableContainer component={Styles.StyledPaper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            {header?.map((title) => 
              <Styles.StyledTableCell className={`${title} center`} key={title}>{title}</Styles.StyledTableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {tableData?.map(item => (
            <TableRow key={item?.id}>
              
              <Styles.StyledTableCell component="th" scope="row">
                {item?.id}
              </Styles.StyledTableCell>
              <Styles.StyledTableCell component="th" scope="row">
                {item?.ativo ? "Ativo" : "Inativo"}
              </Styles.StyledTableCell>
              <Styles.StyledTableCell component="th" scope="row">
                {item?.idOppay}
              </Styles.StyledTableCell>
              <Styles.StyledTableCell component="th" scope="row">
                {item?.nome}
              </Styles.StyledTableCell>
              <Styles.StyledTableCell className='center' component="th" scope="row">
                {!!allowEdit ?
                  <EditIcon onClick={() => handleEditGrupoServico(item)} style={{ cursor: 'pointer' }} />
                  :
                  <EditIcon style={{ cursor: 'not-allowed', opacity: 0.5 }} />
                }
              </Styles.StyledTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ConsultaGrupoServicoTabela;
