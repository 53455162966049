import { useState } from "react";
import _ from "lodash";
import { IDadosConsultaOM } from "./types/consultaOMTypes";

export const ConsultaOMContext = () => {
  const [dadosConsultaOM, setDadosConsultaOM] = useState<IDadosConsultaOM[]> ([]);

  const listaOSFiltradaConsultaOM = () => {
    let resultadoDados: IDadosConsultaOM[] = dadosConsultaOM;

    return resultadoDados;
  };

  return {
    dadosConsultaOM: listaOSFiltradaConsultaOM(),
    setDadosConsultaOM
  }
}