import React, { useEffect, useState } from 'react';
import { Document, Page, View, Text, Image } from '@react-pdf/renderer';
import { IPostConsultarOSsResponseOS } from '../../pages/consultaOS/types';
//@ts-ignore
import Logo from '../../assets/images/mobyan_logo.png';
import JsBarcode from 'jsbarcode';
import moment from 'moment';
import { styles } from './documentTemplateStyles';
import { IOrdemServico } from '../../contexts/types/farolTypes';

const Cabecalho = ({ ordemServico, barcodeValue }: { ordemServico: IPostConsultarOSsResponseOS | IOrdemServico, barcodeValue: string }) => {
  const source = {
    grupoServico: ordemServico?.grupoServico ?? "",
    servico: ordemServico?.servico ?? "",
    chamado: ordemServico?.chamado ?? "",
    prestador: ('prestador' in ordemServico ? ordemServico?.prestador : ""),
    previsaoAtendimento: (ordemServico?.previsaoAtendimento ? moment(ordemServico?.previsaoAtendimento, 'YYYY-MM-DD HH:mm').format('DD/MM/YY HH:mm') : ""),
    ramo: ('cliente' in ordemServico ? (ordemServico.cliente?.ramo ?? "") : ""),
    subRamo: ('cliente' in ordemServico ? (ordemServico.cliente?.subRamo ?? "") : ""),
  };

  return (
    <Document>
      <View style={styles.header}>
        <View style={{ ...styles.table, border: 0, marginBottom: 10 }}>
          <View style={styles.tableRow}>
            <View style={{ ...styles.tableCol, border: 0, width: '25%', alignItems: 'center' }}>
              <Image style={{ ...styles.logo, border: 0, marginTop: 12, padding: '0 5px' }} src={Logo} />
            </View>
            <View style={{ ...styles.tableCol, border: 0, width: '100%' }}>
              <View style={{ ...styles.table, border: 0 }}>
                <View style={styles.tableRow}>
                  <View style={{ ...styles.rowBorder, border: 0 }}>
                    <Text style={{ fontSize: 12, fontWeight: 'extrabold', verticalAlign: 'sub' }}>{source.grupoServico} - {source.servico}</Text>
                  </View>
                </View>
                <View style={styles.tableRow}>
                  <View style={{ ...styles.tableCol, border: 0 }}>
                    <Text>Nº. da OS: {source.chamado}</Text>
                  </View>
                  <View style={{ ...styles.tableCol, border: 0 }}>
                    <Text>EPS: {source.prestador}</Text>
                  </View>
                  <View style={{ ...styles.tableCol, border: 0 }}>
                  </View>
                </View>
                <View style={styles.tableRow}>
                  <View style={{ ...styles.tableCol, border: 0 }}>
                    <Text>Venc. OS: {source.previsaoAtendimento}</Text>
                  </View>
                  <View style={{ ...styles.tableCol, border: 0 }}>
                    <Text>Ramo: {source.ramo}</Text>
                  </View>
                  <View style={{ ...styles.tableCol, border: 0 }}>
                    <Text>Sub Ramo: {source.subRamo}</Text>
                  </View>
                </View>
              </View>
            </View>
            <View style={{ ...styles.tableCol, border: 0, width: '28%' }}>
              <View>
                <Image style={{ width: 100, height: 40 }} src={barcodeValue} />
              </View>
            </View>
          </View>
        </View>
      </View>
    </Document>
  );
};

const Corpo = ({ ordemServico }: { ordemServico: IPostConsultarOSsResponseOS | IOrdemServico }) => {
  const horariosFuncionamento = ordemServico.horariosFuncionamento ?? [];
  function filtrarHorariosPorNome(nome: string): string {
    for (const horarioFunc of horariosFuncionamento) {
        const horario = horarioFunc.horarios.find(h => h.nome === nome);
        if (horario) {
            return `${horario.inicio} - ${horario.fim}`;
        }
    }
    return "";
  }

  const horariosAtendimento:string[] = [
    filtrarHorariosPorNome("segunda"),
    filtrarHorariosPorNome("terça"),
    filtrarHorariosPorNome("quarta"),
    filtrarHorariosPorNome("quinta"),
    filtrarHorariosPorNome("sexta"),
    filtrarHorariosPorNome("sabado"),
    filtrarHorariosPorNome("domingo"),
    filtrarHorariosPorNome("almoco"),
  ];

  console.log("ordemServico",ordemServico.horariosFuncionamento);
  const operadora1 = 'operadora1' in ordemServico ? ordemServico.operadora1 : "";
  const operadora2 = 'operadora2' in ordemServico ? ordemServico.operadora2 : "";

  const source = {
    grupoServico: ordemServico?.grupoServico ?? "",
    tipoOS: ordemServico?.tipoOS ?? "",
    codigoCliente: ('cliente' in ordemServico ? (ordemServico.cliente?.codigoCliente ?? "") : ('codigoCliente' in ordemServico ? (ordemServico.codigoCliente ?? "") : "")),
    razaoSocial: ('cliente' in ordemServico ? (ordemServico.cliente?.razaoSocial ?? "") : ('nomeFantasia' in ordemServico ? (ordemServico.nomeFantasia ?? "") : "")),
    clienteVip: "", /**??? */
    superDigital: "", /**??? */
    nomeFantasia: ('cliente' in ordemServico ? (ordemServico.cliente?.nomeFantasia ?? "") : ('nomeFantasia' in ordemServico ? (ordemServico.nomeFantasia ?? "") : "")),
    contato: ('cliente' in ordemServico ? (ordemServico.cliente?.contato ?? "") : ('nomeContato' in ordemServico ? (ordemServico.nomeContato ?? "") : "")),
    logradouro: ('enderecos' in ordemServico ? (ordemServico.enderecos[0]?.logradouro ?? "") : ('logradouro' in ordemServico ? (ordemServico.logradouro ?? "") : "")),
    numero: ('enderecos' in ordemServico ? (ordemServico.enderecos[0]?.numero ?? "") : ('numero' in ordemServico ? (ordemServico.numero ?? "") : "")),
    complemento: ('enderecos' in ordemServico ? (ordemServico.enderecos[0]?.complemento ?? "") : ('complemento' in ordemServico ? (ordemServico?.complemento ?? "") : "")),
    bairro: ('enderecos' in ordemServico ? (ordemServico.enderecos[0]?.bairro ?? "") : ('bairro' in ordemServico ? (ordemServico?.bairro ?? "") : "")),
    //@ts-ignore
    cidade: ('enderecos' in ordemServico ? (ordemServico.enderecos[0]?.cidade ?? "") : ('cidade' in ordemServico ? (ordemServico?.cidade ?? "") : "")),
    cep: ('enderecos' in ordemServico ? (ordemServico.enderecos[0]?.cep ?? "") : ('cep' in ordemServico ? (ordemServico?.cep ?? "") : "")),
    //@ts-ignore
    uf: ('enderecos' in ordemServico ? (ordemServico.enderecos[0]?.uf ?? "") : ('uf' in ordemServico ? (ordemServico?.uf ?? "") : "")),
    pontoReferencia: ('enderecos' in ordemServico ? (ordemServico.enderecos[0]?.pontoReferencia ?? "") : ('pontoReferencia' in ordemServico ? (ordemServico?.pontoReferencia ?? "") : "")),
    telefones: ('telefonesOS' in ordemServico ? ordemServico.telefonesOS.map(t => (`${t.codigoArea} ${t.numero}`)) : ('telefone' in ordemServico ? [ordemServico?.telefone ?? ""] : [])),
    horariosAtendimento: horariosAtendimento,
    operadorasEC: `${operadora1} ${operadora2}`,
    contratante: ordemServico?.contratante ?? "",
    operadora1: operadora1,
    operadora2: operadora2,
    //@ts-ignore
    modelo: ('produtos' in ordemServico ? ordemServico.produtos.map(p => (p.nomeModelo ?? p.idModelo)) : ('modelo' in ordemServico ? [ordemServico?.modelo ?? ""] : [])),
    numeroSerie: ('produtos' in ordemServico ? ordemServico.produtos.map(p => p.numeroSerie) : ('numeroSerie' in ordemServico ? [ordemServico?.numeroSerie ?? ""] : [])),
    qtdKit: ('produtos' in ordemServico ? ordemServico.produtos.map(p => p.quantidade) : ('qtdKit' in ordemServico ? [ordemServico?.qtdKit ?? ""] : [])),
    melhorEquipamento: 'melhorEquipamento' in ordemServico ? (ordemServico.melhorEquipamento ?? []) : [],
    tecnologia: "",
    status: ordemServico?.status ?? "",
    observacao: ordemServico?.observacao ?? "",
    referencia: ordemServico?.referencia ?? "",
  };

  return (
    <View style={styles.section}>
      <View style={styles.rowHeader}>
        <Text>Categoria: {source.grupoServico}</Text>
        <Text>Tipo: {source.tipoOS}</Text>
        <Text>Item: N/A</Text>
      </View>
      <View style={{ ...styles.rowBorder, ...styles.textItems }}>
        <Text>Cod. Estab: {source.codigoCliente}</Text>
        <Text>Razão Social: {source.razaoSocial}</Text>
        <Text>Cliente VIP: {source.clienteVip}</Text>
        <Text>Super Digital: {source.superDigital}</Text>
      </View>
      <View style={{ ...styles.rowBorder, ...styles.textItems }}>
        <Text>Fantasia: {source.nomeFantasia}</Text>
        <Text>Contato: {source.contato}</Text>
      </View>
      <View style={{ ...styles.rowBorder, ...styles.textItems }}>
        <Text>Endereço: {source.logradouro}</Text>
        <Text>Nº: {source.numero}</Text>
        <Text>Complemento: {source.complemento}</Text>
      </View>
      <View style={{ ...styles.rowBorder, ...styles.textItems }}>
        <Text>Bairro: {source.bairro}</Text>
        <Text>Município: {source.cidade}</Text>
        <Text>CEP: {source.cep}</Text>
        <Text>UF: {source.uf}</Text>
      </View>
      <View style={{ ...styles.rowBorder, ...styles.textItems }}>
        <Text>Ponto de Referência: {source.pontoReferencia}</Text>
      </View>
      <View style={{ ...styles.rowBorder, ...styles.textItems }}>
        {source.telefones.slice(0, 5).map((tel, i) => (<Text>{`Tel0${(i + 1)}: ${tel}`}</Text>))}
      </View>
      <View style={{ ...styles.rowHeader }}>
        <Text>Horário de Funcionamento do EC</Text>
      </View>
      <View style={{ ...styles.rowBorder, ...styles.textItems }}>
        <Text>Seg:</Text>
        <Text>Ter:</Text>
        <Text>Qua:</Text>
        <Text>Qui:</Text>
        <Text>Sex:</Text>
        <Text>Sab:</Text>
        <Text>Dom:</Text>
        <Text>Almoço:</Text>
      </View>
      <View style={{ ...styles.rowBorder, ...styles.textItems }}>
        {source.horariosAtendimento.map((h, i) => (<Text>{h}</Text>))}
      </View>
      <View style={{ ...styles.rowBorder, ...styles.textItems }}>
        <Text>Operadora(s) do EC: {source.operadorasEC}</Text>
        <Text>Canal Credenciador EC: {source.contratante}</Text>
        <Text>Operadora Principal: {source.operadora1}</Text>
      </View>
      <View style={{ ...styles.rowBorder, ...styles.textItems }}>
        <Text>Cobertura GPRS: Indicação: 1ª. Opção {source.operadora1}</Text>
        <Text>Indicação: 2ª. Opção {source.operadora2}</Text>
        <Text>Indicação EC: Opção {source.operadora1}</Text>
      </View>
      <View style={{ ...styles.rowBorder, ...styles.textItems }}>
        <Text>Tecnologias Instaladas: {source.modelo.map((num, i) => (`${num}${(i !== source.modelo.length - 1) ? `, ` : ""}`))}</Text>
        <Text>Qtd. Kit: {source.qtdKit.map((num, i) => (`${num}${(i !== source.qtdKit.length - 1) ? `, ` : ""}`))}</Text>
      </View>
      <View style={{ ...styles.rowBorder, ...styles.textItems }}>
        <Text>Numero de Série: {source.numeroSerie.map((num, i) => (`${num}${(i !== source.numeroSerie.length - 1) ? `, ` : ""}`))}</Text>
      </View>
      <View style={styles.rowHeader}>
        <Text>Melhor Equipamento:</Text>
      </View>
      {source.melhorEquipamento.map((e, i) => {
        return (
          <View style={{ ...styles.rowBorder, ...styles.textItems }}>
            <Text>{i + 1}: {e.descricao}</Text>
            <Text>Sit.: {e.situacao}</Text>
          </View>
        );
      })}
      <View style={{ ...styles.rowBorder, ...styles.textItems }}>
        {/**@ts-ignore */}
        <Text>Substituição / Habilitação - Tecnologia: {source.melhorEquipamento.map((num, i) => (`${num.tecnologia ?? ""}${(i !== source.melhorEquipamento.length - 1) ? `, ` : ""}`))}</Text>
        {/**@ts-ignore */}
        <Text>Modelo: {source.melhorEquipamento.map((num, i) => (`${num.nomeModelo ?? ""}${(i !== source.melhorEquipamento.length - 1) ? `, ` : ""}`))}</Text>
      </View>
      <View style={{ ...styles.rowBorder, ...styles.textItems }}>
        <Text>Cobertura GPRS: Indicação: 1ª. Opção {source.operadora1}</Text>
        <Text>Indicação: 2ª.Opção {source.operadora2}</Text>
        <Text>Indicação EC: Opção {source.operadora1}</Text>
      </View>
      <View style={{ ...styles.rowBorder, ...styles.textItems }}>
        <Text>{source.observacao}</Text>
      </View>
      <View style={{ ...styles.rowBorder, ...styles.textItems }}>
        <Text>Descrição do Atendimento: {source.status}</Text>
      </View>
      <View style={{ ...styles.table, border: 0 }}>
        <View style={styles.tableRow}>
          <View style={{ ...styles.tableCol, border: 0, padding: '0 20', backgroundColor: '#E9EAEA' }}>
            <Text>Equipamentos / Periféricos (Instalados)</Text>
          </View>
          <View style={{ ...styles.tableCol, border: 0, padding: '0 20', backgroundColor: '#E9EAEA' }}>
            <Text>Componentes (Instalados)</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={{ ...styles.tableColItem, border: 0 }}>
            <View style={{ ...styles.rowBorder, ...styles.textItems, padding: '0 70px' }}>
              <Text>Modelo</Text>
              <Text>Número Físico</Text>
            </View>
            <View style={{ ...styles.rowBorder, ...styles.textItems }}>
              <Text>POS:</Text>
            </View>
            <View style={{ ...styles.rowBorder, ...styles.textItems }}>
              <Text>Pinpad:</Text>
            </View>
            <View style={{ ...styles.rowBorder, ...styles.textItems, backgroundColor: '#E9EAEA' }}>
              <Text>Equipamentos / Periféricos (Retirados)</Text>
            </View>
            <View style={{ ...styles.rowBorder, ...styles.textItems, padding: '0 70px' }}>
              <Text>Modelo</Text>
              <Text>Número Físico</Text>
            </View>
            <View style={{ ...styles.rowBorder, ...styles.textItems }}>
              <Text>POS:</Text>
            </View>
            <View style={{ ...styles.rowBorder, ...styles.textItems }}>
              <Text>Pinpad:</Text>
            </View>
            <View style={{ ...styles.rowBorder, ...styles.textItems }}>
              <Text>Pessoa(s) Treinada(s):</Text>
            </View>
            <View style={{ ...styles.rowBorder, ...styles.textItems }}>
              <Text>Responsável:</Text>
            </View>
            <View style={{ ...styles.rowBorder, ...styles.textItems }}>
              <Text>CPF:</Text>
            </View>
            <View style={{ ...styles.rowBorder, ...styles.textItems }}>
              <Text>Protocolo:</Text>
            </View>
          </View>
          <View style={{ ...styles.tableColItem, border: 0 }}>
            <View style={{ ...styles.rowBorder, ...styles.textItems }}>
              <Text>Cabo Tel: ( )</Text>
              <Text>Cabo Conj. ( )</Text>
              <Text>Base: ( )</Text>
            </View>
            <View style={{ ...styles.rowBorder, ...styles.textItems }}>
              <Text>Fonte: ( )</Text>
              <Text>Bateria: ( )</Text>
            </View>
            <View style={{ ...styles.rowBorder, ...styles.textItems }}>
              <Text>Chip: ( )</Text>
              <Text>Algar: ( )</Text>
              <Text>Vivo: ( )</Text>
              <Text>Claro: ( )</Text>
              <Text>Vivo: ( )</Text>
            </View>
            <View style={{ ...styles.rowBorder, ...styles.textItems, backgroundColor: '#E9EAEA' }}>
              <Text>Componentes (Retirados): </Text>
            </View>
            <View style={{ ...styles.rowBorder, ...styles.textItems }}>
              <Text>Cabo Tel: ( )</Text>
              <Text>Cabo Conj. ( )</Text>
              <Text>Base: ( )</Text>
            </View>
            <View style={{ ...styles.rowBorder, ...styles.textItems }}>
              <Text>Fonte: ( )</Text>
              <Text>Bateria: ( )</Text>
            </View>
            <View style={{ ...styles.rowBorder, ...styles.textItems }}>
              <Text>Chip: ( )</Text>
              <Text>Algar: ( )</Text>
              <Text>Vivo: ( )</Text>
              <Text>Claro: ( )</Text>
              <Text>Vivo: ( )</Text>
            </View>
            <View style={{ ...styles.rowBorder, ...styles.textItems }}>
              <Text>Horas e Data do Atendimento: </Text>
            </View>
            <View style={{ ...styles.rowBorder, ...styles.textItems }}>
              <Text>Técnico: </Text>
            </View>
            <View style={{ ...styles.rowBorder, ...styles.textItems }}>
              <Text>CPF: </Text>
            </View>
            <View style={{ ...styles.rowBorder, ...styles.textItems }}>
              <Text>Contra Ordem: </Text>
            </View>
          </View>
        </View>
      </View>
      <View style={styles.rowHeaderGray}>
        <Text style={{ textAlign: 'center', padding: '2px 5px' }}>CLIENTE, ANTES DE ASSINAR ESTA ORDEM DE SERVIÇO, LEIA ATENTAMENTE O SEU CONTEÚDO!</Text>
      </View>
      <View style={styles.rowBorderTermos}>
        <Text style={{ padding: '3px 15px' }}>Declaro para os devidos fins que aceito e compreendo todos os produtos e/ ou serviços aderindo ao sistema de captura e transmição e manutenção de rede e equipamento de captura e outros avenças. cuja o teor tenho conhecimento e esta disponibilizado integralmente no site: www.getnet.com.br além de encontrar-se registrado no Cartório de Registros de Titulos e Documentos de Campo Bom/RS, concordo com todas as cláusulas e consições, obrigando-me a cumpri-lo fielmente responsabilizando-me ainda pelas informações fornecidas e aqui contidas através da habilitação do presente campo</Text>
      </View>
      <View style={{ ...styles.rowBorder, ...styles.textItems }}>
        <Text>Sinistro: (Descrição do ocorrido com detalhamento(descrever como aconteceu o dano no equipamento))</Text>
      </View>
      <View style={{ ...styles.tableSignature, marginTop: '10px' }}>
        <View style={styles.tableRowSignature}>
          <View style={styles.tableColItemSignature}>
            <Text style={{ textAlign: 'center' }}>______________________________</Text>
            <Text style={{ textAlign: 'center' }}>Assinatura e Carimbo do Estabelecimento</Text>
          </View>
          <View style={styles.tableColItemSignature}>
            <Text style={{ textAlign: 'center' }}>___________________________________</Text>
            <Text style={{ textAlign: 'center' }}>Assinatura e Carimbo do EPS</Text>
          </View>
        </View>
      </View>
      <View style={styles.footer}>
        <Text style={{ padding: '0 20px', marginBottom: 2 }}>Telefone de atendimento ao cliente: 4002-4000 e 4003-4000</Text>
      </View>
      <View style={styles.tableClient}>
        <View style={styles.tableRowClient}>
          <View style={{ ...styles.tableColItemClient, width: '25%', alignItems: 'center' }}>
            <Image style={{ ...styles.logo, width: '100%', marginTop: 25, padding: '0 10px' }} src={Logo} />
          </View>
          <View style={styles.tableColItemClient}>
            <Text style={{ fontSize: 9, marginTop: 20, marginBottom: 20, textAlign: 'justify' }}>
              Franqueada declara para os devidos fins que reference à OS {source.referencia} determinada ao E.C. {source.codigoCliente} com a Razão Social: {source.razaoSocial} e o terminal nº _________________________ foi devidamente ( ) instalado.
            </Text>
          </View>
        </View>
      </View>
    </View>
  );
};

const Footer = () => (
  <Document>
    <View style={styles.rowFooterTecnico}>
      <Text style={{ fontSize: '9px' }}>
        A Franqueada através do técnico determinado a este atendimento, torna-se responsável pelo ativo e peças retiradas.
      </Text>
    </View>
    <View style={{ ...styles.table, border: 0 }}>
      <View style={{ ...styles.tableCol, fontSize: 9, border: 0 }}>
        <View style={styles.tableRow}>
          <Text>
            ( ) TAMPA DA IMPRESSORA ( ) BATERIA ( ) CABO DE FORÇA ( ) CABO EXTENSOR ( ) CHIP ( ) TAMPA TRASEIRA ( ) TAMPA SUPERIOR
          </Text>
        </View>
        <View style={styles.tableRow}>
          <Text>
            ( ) TAMPA INFERIOR ( ) CARREGADOR ( ) NENHUM ITEM RETIRADO ( ) CABO USB ( ) FONT
          </Text>
        </View>
      </View>
    </View>
    <View style={{ padding: 3, marginTop: 20 }}>
      <Text>
        Técnico ________________________________ RG _____________________Data e Hora: ___/___/______  ____ : ____
      </Text>
    </View>
  </Document>
);

const TemplateGetNet = ({ ordemServicos }: { ordemServicos: (IPostConsultarOSsResponseOS | IOrdemServico)[] }) => {

  const [barcodes, setBarcodes] = useState<{ [key: string]: string }>({});

  useEffect(() => {
    const newBarcodes: { [key: string]: string } = {};
    ordemServicos.forEach((os) => {
      const canvas = document.createElement('canvas');
      JsBarcode(canvas, `${os.chamado}`);
      newBarcodes[os.chamado] = canvas.toDataURL();
    });
    setBarcodes(newBarcodes)

  }, [ordemServicos]);

  return (
    <Document>
      {ordemServicos.map((ordemServico, index) => (
        <Page size="A4" key={index} style={styles.page}>
          <Cabecalho ordemServico={ordemServico} barcodeValue={barcodes[ordemServico.chamado]} />
          <Corpo ordemServico={ordemServico} />
          <Footer />
        </Page>
      ))}
    </Document>
  );
};

export default TemplateGetNet;
