import styled from "styled-components"
import { TableRow } from '@mui/material';

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: "#D9D9D92E",
  },
}));

export const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 14px 40px;
  gap: 16px;

  .ButtonContainer {
    display: flex;
    justify-content: flex-end;

    button{
      max-width: 126px
    }
  }
`