import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../contexts/auth";
import { HomeContext } from "../../../contexts/homeContext";
import { useNavigate } from "react-router-dom";
import { Body, ButtonsBox, NewUserButton, SearchButton, Section } from "./consultaCidades_styles";
import Header from "../../../components/header/header";
import { Breadcrumb, BreadcrumbItem } from "../../../components/breadcrumb/breadcrumb";
import AutocompleteMultiple from "../../../components/autocompleteMultiple/autocompleteMultiple";
import { CircularProgress } from "@mui/material";
import { smoothScroll } from "../../../helpers/smoothScroll";
import Pagination from "../../../components/pagination/paginacao";
import StickyTable from "../../../components/stickyTable/stickyTable";
import { IGetCidades, IGetEstados, IGetResponseCidade, IGetResponseCidades, IGetRegioes } from "../types";
import { getListaCidadesComFiltro, getListaEstados, getListaRegioes } from "../services";
import { AxiosResponse } from "axios";
import AlertMessage from "../../../components/AlertMessage/alertMessage";
import _ from "lodash";
import { IAutocompleteMultiple, IAutocompleteValue }  from "../../../components/autocompleteMultiple/types";

const ConsultaCidades = () => {
    const { setMenuLateral, setCidadeSelecionada } = useContext(HomeContext);

    const { funcionalidadeDaTelaTemPermissao } = useContext(AuthContext);
    const profileHasPermission = (funcionalidade: string) => funcionalidadeDaTelaTemPermissao("Consultar Cidades", funcionalidade);
    const navigate = useNavigate();

    const [listaStatus, setListaStatus] = useState<IAutocompleteValue>([
        { name: '--', id: '' },
        { name: 'Ativo', id: '0' },
        { name: 'Inativo', id: '1' },
    ]);
    const [filtroStatus, setFiltroStatus] = useState<IAutocompleteValue>([]);
    const [listaPaises, setListaPaises] = useState<IAutocompleteValue>([
        { name: 'Brasil', id: '' }
    ]);
    const [filtroPais, setFiltroPais] = useState<IAutocompleteValue>([{ name: 'Brasil', id: '' }]);
    const [listaEstados, setListaEstados] = useState<IGetEstados[]>([]);
    const [filtroEstado, setFiltroEstado] = useState<IAutocompleteValue>([]);
    const [listaRegioes, setListaRegioes] = useState<IAutocompleteValue>([]);
    const [filtroRegiao, setFiltroRegiao] = useState<IAutocompleteValue>([]);
    const [listaCidades, setListaCidades] = useState<IAutocompleteValue>([]);
    const [filtroCidades, setFiltroCidades] = useState<IAutocompleteValue>([]);

    const [tipomsgAlert, setTipoMsgAlert] = useState<'success' | 'info' | 'warning' | 'error'>("error");
    const [msgAlert, setMsgAlert] = useState("OS encaminhada com  sucesso!");
    const [openAlert, setOpenAlert] = useState(false);

    const [disableActions, setDisableActions] = useState(false);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);

    const [dadosConsultaCidade, setDadosConsultaCidade] = useState<any[]>([]);
    const [qtdeRegistros, setQtdeRegistros] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);

    const handleButtonSearch = () => {
        if (currentPage !== 1) {
            setCurrentPage(1);
        } else {
            handleSearch();
        }
    };

    const getEstados = async () => {
        try {
            const estados = await getListaEstados({}) as AxiosResponse;
            setListaEstados(estados?.data?.estados ?? []);

        } catch (e) {
            setListaEstados([]);
        }
    }

    const getRegioes = async () => {
        try {
            const regioes = await getListaRegioes({}) as AxiosResponse;
            setListaRegioes(regioes?.data ?? []);

        } catch (e) {
            setListaRegioes([]);
        }
    };

    const handleGetCidades = async () => {
        if (_.isEmpty(filtroEstado)) {
            setListaCidades([]);
            return;
        }

        try {
            const { data }: { data: IGetResponseCidades } = await getListaCidadesComFiltro({
                Ativo: true,
                IdEstado: Number(filtroEstado[0].id),
            }) as AxiosResponse;
            const cidades = data?.cidades ?? [];

            setListaCidades(cidades.map(item => ({ name: item.nome, id: item.id.toString() })).sort(
                (a: { name: string, id: string }, b: { name: string, id: string }) =>
                    (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)
            ))

        } catch (e) {
            setListaCidades([]);
        }
    }

    const handleSearch = async () => {
        setOpenAlert(false);
        setLoading(true);
        setDisableActions(true);

        const statusBool = (!filtroStatus[0] || filtroStatus[0].id === '') ? '' : filtroStatus[0].id === '0' ? true : false;

        const params: IGetCidades = {
            ...(statusBool !== '' ? { Ativo: statusBool } : {}),
            ...(!!filtroEstado.length && !!filtroEstado[0]?.id ? { 'IdEstado': Number(filtroEstado[0].id) } : {}),
            ...(!!filtroRegiao.length && !!filtroRegiao[0]?.id ? { 'IdRegiao': Number(filtroRegiao[0].id) } : {}),
            ...(!!filtroCidades.length && !!filtroCidades[0]?.id ? { 'Id': Number(filtroCidades[0].id) } : {}),
        };

        try {
            const { data }: { data: IGetResponseCidades } = await getListaCidadesComFiltro(params) as AxiosResponse;
            setLoading(false);
            setDisableActions(false);
            setDadosConsultaCidade(data?.cidades ?? []);
            setQtdeRegistros(data?.cidades?.length ?? 0);
        } catch (error) {
            setLoading(false);
            setDisableActions(false);
            setDadosConsultaCidade([]);
            setQtdeRegistros(0);
            setTipoMsgAlert("error");
            setMsgAlert("Nenhum dado encontrado");
            setOpenAlert(true);
        };
    };

    const getItemByID = (ID: number | string) => {
        let resultadoDados = [...dadosConsultaCidade];

        return resultadoDados.find(
            (item) => item.id === ID
        )!;
    };

    const paginacaoLocal = (dados: any[]) => {
        const firstPageIndex = (currentPage - 1) * pageSize;
        const lastPageIndex = firstPageIndex + pageSize;
        return dados.slice(firstPageIndex, lastPageIndex);
    };

    const dadosFormatados = () => {
        let dados = [...dadosConsultaCidade];

        /**Formata campos para tabela */
        dados = dados.map((item: any) => {
            return {
                id: item.id,
                ativo: !!item.ativo ? "Ativo" : "Inativo",
                estado: item.estado?.nome ?? "",
                nome: item.nome,
                regiao: item.regiao?.nome ?? ""
            };
        });

        return paginacaoLocal(dados);
    };

    const handleClickItemEdit = (data: any) => {
        setCidadeSelecionada(getItemByID(data.id));
        navigate("/EditarCidade");
    };

    const handleSetFiltroEstado = (value: any) => {
        setFiltroEstado(value);
        setFiltroCidades([]);
    };

    useEffect(() => {
        getEstados();
        getRegioes();
        setCidadeSelecionada({} as IGetResponseCidade);
    }, []);

    useEffect(() => {
        handleGetCidades();
    }, [filtroEstado]);

    useEffect(() => {
        if (!!dadosConsultaCidade.length) smoothScroll(document.getElementById('TabelaResultadoConsultaCidade'));
    }, [dadosConsultaCidade]);

    const objTraducaoHeader = {
        id: "Id do registro",
        ativo: "Status",
        estado: "Estado",
        nome: "Cidade",
        regiao: "Região",
    } as any;

    return (
        <Section>
            <Header
                setMenuLateral={() => setMenuLateral(true)}
                title="Cidades"
            />
            <Breadcrumb>
                <BreadcrumbItem>Consultar Cidades</BreadcrumbItem>
            </Breadcrumb>

            <Body>
                <div className="grid1">
                    <AutocompleteMultiple
                        dimension="sm"
                        label={"Status"}
                        placeholder={""}
                        noOptionsText="Nenhum status encontrado"
                        options={listaStatus}
                        value={filtroStatus}
                        onChange={setFiltroStatus}
                        id="multiple-checkbox-Status"
                        multiple={false}
                        disabled={disableActions}
                    />
                    <AutocompleteMultiple
                        disableClearable
                        dimension="sm"
                        label={"País"}
                        placeholder={""}
                        noOptionsText="Nenhum país encontrado"
                        options={listaPaises}
                        value={filtroPais}
                        onChange={() => { }}
                        id="multiple-checkbox-pais"
                        multiple={false}
                        disabled={disableActions}
                    />
                    <AutocompleteMultiple
                        dimension="sm"
                        label={"Estado"}
                        placeholder={""}
                        noOptionsText="Nenhum estado encontrado"
                        options={listaEstados?.map((item: any) => ({ name: item.nome, id: item.id.toString() }))!}
                        value={filtroEstado}
                        onChange={handleSetFiltroEstado}
                        id="multiple-checkbox-estado"
                        multiple={false}
                        disabled={disableActions}
                    />
                    <AutocompleteMultiple
                        dimension="sm"
                        label={"Região"}
                        placeholder={""}
                        noOptionsText="Nenhuma região encontrada"
                        options={listaRegioes?.map((item: any) => ({ name: item.nome, id: item.id.toString() }))!}
                        value={filtroRegiao}
                        onChange={setFiltroRegiao}
                        id="multiple-checkbox-regiao"
                        multiple={false}
                        disabled={disableActions}
                    />
                    <AutocompleteMultiple
                        dimension="sm"
                        label={"Cidade"}
                        placeholder={""}
                        noOptionsText="Nenhuma cidade encontrada"
                        options={listaCidades}
                        value={filtroCidades}
                        onChange={setFiltroCidades}
                        id="multiple-checkbox-Cidades"
                        multiple={false}
                        disabled={disableActions || filtroEstado.length === 0}
                    />
                    <ButtonsBox>
                        <SearchButton
                            variant="contained"
                            size="small"
                            onClick={handleButtonSearch}
                            disabled={disableActions}
                        >
                            Buscar
                        </SearchButton>
                        {!!profileHasPermission("Novo Registro") ?
                            <NewUserButton
                                color="secondary"
                                variant="contained"
                                size="small"
                                onClick={() => navigate("/IncluirCidade")}
                                disabled={disableActions}
                            >
                                Incluir Cidade
                            </NewUserButton>
                            :
                            <NewUserButton
                                color="secondary"
                                variant="contained"
                                size="small"
                                disabled={true}
                                className="bloqueado"
                            >
                                Incluir Cidade
                            </NewUserButton>
                        }
                    </ButtonsBox>
                </div>
            </Body>

            <AlertMessage
                isOpenAlert={openAlert}
                setOpenAlert={setOpenAlert}
                alertType={tipomsgAlert}
                msgAlert={msgAlert}
            />

            {!!loading && <CircularProgress style={{ margin: '0px auto' }} />}

            {!!dadosConsultaCidade.length && (
                <>
                    <StickyTable
                        id={"TabelaResultadoConsultaCidade"}
                        data={dadosFormatados()}
                        handleClickEdit={handleClickItemEdit}
                        objTraducaoHeader={objTraducaoHeader}
                        allowEdit={!disableActions && !!profileHasPermission("Editar registro")}
                        edit={true}
                        labelEdit={"Detalhes"}
                    />

                    <Pagination
                        className="pagination-bar"
                        currentPage={currentPage}
                        totalCount={qtdeRegistros}
                        pageSize={pageSize}
                        setPageSize={(value: string) => setPageSize(Number(value))}
                        onPageChange={(page: number) => setCurrentPage(Number(page))}
                        showLabel={false}
                    />
                </>
            )}
        </Section>
    );
}

export default ConsultaCidades;
