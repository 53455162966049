import React, { useContext, useEffect, useRef, useState } from 'react';
import Header from '../../../components/header/header';
import { Breadcrumb, BreadcrumbItem } from '../../../components/breadcrumb/breadcrumb';
import { HomeContext } from '../../../contexts/homeContext';
import { AuthContext } from '../../../contexts/auth';
import MultipleSelectCheckmarksId from '../../../components/seletorMultiploId/seletorMultiploId';
import Button from '../../../components/Button/styles';
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import Collapse from '../../../components/collapse/collapse';
import CloseIcon from "@mui/icons-material/Close";
import "./consultaGrupoServico.scss";
import { AxiosResponse } from 'axios';
import _ from 'lodash';
import Pagination from '../../../components/pagination/paginacao';
import FullscreenLoading from '../../../components/fullscreenLoading/fullscreenLoading';
import { useNavigate } from 'react-router-dom';
import { NewButton } from './consultaGrupoServico_styles';
import ConsultaGrupoServicoTabela from './consultaGrupoServicoTable';
import { getGrouped_Services } from '../../farol/services';
import { ResponseApi } from '../../../contexts/types/responsesHttp';
import { IGetResponseGrupoServico } from '../types';

const ConsultaGrupoServico = () => {

  const { setMenuLateral } = useContext(HomeContext);

  const { funcionalidadeDaTelaTemPermissao } = useContext(AuthContext);
  const profileHasPermission = (funcionalidade: string) => funcionalidadeDaTelaTemPermissao("Consultar Grupo de Serviço", funcionalidade);

  const navigate = useNavigate();

  const [status, setStatus] = useState<string[]>(['']);

  const [tableData, setTableData] = useState<IGetResponseGrupoServico>([]);

  const [loading, setLoading] = useState(false);

  const [tipomsgAlert, setTipoMsgAlert] = useState<'success' | 'info' | 'warning' | 'error'>("success");
  const [msgAlert, setMsgAlert] = useState("");
  const [openAlert, setOpenAlert] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const statusLista = [
    { name: '--', id: '' },
    { name: 'Ativo', id: '0' },
    { name: 'Inativo', id: '1' },
  ];

  const handleSearch = async () => {
    setOpenAlert(false);
    setLoading(true);

    try {
      const statusBool = status[0] === '' ? '' : status[0] === '0' ? true : false;

      const { data }: { data: ResponseApi<IGetResponseGrupoServico> } = await getGrouped_Services({
        ...(statusBool !== '' ? { Ativo: statusBool } : {}),
      }) as AxiosResponse;

      setTableData(data?.data as IGetResponseGrupoServico ?? []);
      setLoading(false);

    } catch (e: any) {
      setLoading(false);
      setTableData([]);
      setTipoMsgAlert('error');

      const erros = e?.response?.data?.errors;
      if (!!erros && _.isArray(erros)) {
        const objErros = erros.reduce((obj, item, i) => {
          if (!!item.message) {
            obj[i] = item.message;
          }
          return obj;
        }, {});
        setMsgAlert(Object.values(objErros).join("<br>"));
      } else {
        setMsgAlert("Erro ao buscar grupos de serviço");
      }
      setOpenAlert(true);
    };
  };

  const beforeSubmit = () => {
    if (currentPage !== 1) setCurrentPage(1)
    else handleSearch()
  };

  const dadosPaginadados = () => {
    let dados = [...tableData];

    /**Paginação */
    const firstPageIndex = (currentPage - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;
    return dados.slice(firstPageIndex, lastPageIndex);
  };

  const firstUpdate = useRef(true);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    handleSearch();
  }, [currentPage, pageSize]);

  return (
    <section className="ConsultaGrupoServico">
      <Header setMenuLateral={() => setMenuLateral(true)} title="Grupo de Serviço" />
      <Breadcrumb>
        <BreadcrumbItem>Grupo de Serviço</BreadcrumbItem>
      </Breadcrumb>

      {loading && <FullscreenLoading />}

      <div className="Container">
        <span className='FiltroSpan'>Filtros</span>
        <div className="ConsultarGrupoServicoPesquisar">
          <MultipleSelectCheckmarksId
            nodata={"Nenhum status encontrado"}
            options={statusLista}
            value={status}
            onChange={(e: string[]) => setStatus(e)}
            id="multiple-checkbox-status"
            idOption="multiple-checkbox-option-status-"
            labelId="multiple-checkbox-label-status"
            multiple={false}
            className="ConsultarGrupoServicoSelectStatus"
            dimension='sm'
            label="Status"
          />

          <Button dimension='sm' variant='primary' margin='0' onClick={() => beforeSubmit()}>Pesquisar</Button>

          {!!profileHasPermission("Novo Registro") ?
            <NewButton variant="contained" size="small" onClick={() => navigate('/IncluiGrupoServico')}>Incluir</NewButton>
            :
            <NewButton
              className={"bloqueado"}
              style={{ cursor: 'not-allowed', opacity: 0.5 }}
              variant="contained"
              size="small"
            >
              Incluir
            </NewButton>
          }

          {/* <Button
            dimension='sm'
            className='ExportButton'
            variant='secondary'
            onClick={() => console.log("Export")}
            style={{ maxHeight: '44px', margin: 0 }}
          >
            <IosShareIcon />
            Exportar CSV
          </Button> */}
        </div>

        <Collapse onClose={setOpenAlert} in={openAlert}>
          <Alert
            severity={tipomsgAlert}
            icon={<div />}
            variant="filled"
            sx={{
              mb: 2,
              bgcolor: tipomsgAlert === "success" ? "#178B319E" : "rgb(255, 35, 35, 0.7)",
              height: "67px",
              borderRadius: "15px",
              boxSizing: "border-box",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginTop: "20px",
              marginBottom: 0,
            }}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="medium"
                onClick={() => setOpenAlert(false)}
              >
                <CloseIcon
                  id={"ModalEncaminharOS-AlertCloseIcon"}
                  fontSize="inherit"
                  sx={{
                    color: "white",
                  }}
                />
              </IconButton>
            }
          >
            <span
              style={{
                color: "white",
                fontSize: "16px",
              }}
              dangerouslySetInnerHTML={{ __html: msgAlert }}
            />
          </Alert>
        </Collapse>

        {!_.isEmpty(tableData) &&
          <>
            <ConsultaGrupoServicoTabela tableData={dadosPaginadados()} />

            <Pagination
              className="pagination-bar"
              currentPage={currentPage}
              totalCount={tableData?.length}
              pageSize={pageSize}
              setPageSize={(value: string) => setPageSize(Number(value))}
              onPageChange={(page: number) => setCurrentPage(Number(page))}
              showLabel={false}
            />
          </>
        }
      </div>
    </section>
  );
};

export default ConsultaGrupoServico;
