import styled from 'styled-components';
import { Button } from "@mui/material";
import Stack from '@mui/material/Stack';

interface IContainerMenu {
    displaySidebar: boolean
}

export const ContainerMenu = styled.aside<IContainerMenu>`
    display: flex;
    width: 300px;
    background-color: #1f5ab6;
    position: fixed;
    flex-direction: column;
    transition: all 0.5s ease;
    z-index: 999;
    transform: ${({ displaySidebar }: IContainerMenu) => !displaySidebar ? 'translateX(-100%)' : 'translateX(0%)'};
    
`;

export const LogoImage = styled.img`
    width: 7rem;
`

export const Wrapper = styled('div')(({ theme }) => ({
    boxSizing: 'border-box',
    width: 'inherit',
    height: '100vh',
    position: 'fixed',
    padding: '2rem 22px',
    background: theme.palette.primary.main,
    "& ::before": {
        content: '',
        display: 'flex',
        width: '30px',
        height: '100%',
        background: theme.palette.secondary.secondary,
        position: 'absolute',
        top: '0',
        left: '100%',
    },
    "&::after": {
        content: '',
        display: 'flex',
        width: '31px',
        height: '100%',
        background: theme.palette.primary.contrastText,
        position: 'absolute',
        top: '0',
        left: '100%',
        borderRadius: '40px 0 0 40px',
    },
    "::-webkit-scrollbar": {
        display: 'none',
    }
}));

export const Flexdiv = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

export const BoxLogo = styled.div`
    display: flex;

    padding-bottom: 1.5rem;
    margin-bottom: 1rem;
    border-bottom: 1px solid rgba(255,255,255,.2);
    justify-content: center;
`;

export const StackStyled = styled(Stack)`
    max-height: calc(100% - 147px); /* 100% - espaço da logo e botão sair*/
    min-height: 220px;
    overflow-y: auto;
    overflow-x: hidden;
    margin-right: -15px;
    padding-right: 7px;
    padding-bottom: 5px;
    scrollbar-gutter: stable;

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-button:single-button {
      background-color: #FFF;
      display: block;
      height: 18px;
      width: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #FFF;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
`;

export const ButtonMenu = styled(Button)(({ theme }) => ({
    justifyContent: 'start !important',
    // paddingRight: '22px !important',
    padding: '1rem !important',
    width: '100%',
    textAlign: 'start',
    fontSize: '0.875rem !important',
    "&.isActive": {
        backgroundColor: theme.palette.primary.contrastText,
        color: theme.palette.secondary.contrastText,
        fill: 'red',
        "&:hover": {
            backgroundColor: theme.palette.primary.contrastText,
            color: theme.palette.secondary.contrastText,
        },
        "path": {
            fill: theme.palette.secondary.contrastText,
        },
    },
    ".MuiButton-startIcon": {
        marginRight: '1rem',
    }
}));

export const ArrowLeftContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    "& > svg": {
        cursor: 'pointer',
        width: '20px',
        height: '20px',
        "& > path": {
            fill: theme.palette.primary.contrastText,
        }
    }
}));