import FormControl from "@mui/material/FormControl"
import "./editarTermoOs.scss";

interface ITextAreaTermoOs extends  React.InputHTMLAttributes<HTMLTextAreaElement> {
    label: string,
    onChange?: (e: any) => void,
    dimension?: 'sm' | 'lg',
    error?: boolean,
    helperText?: any,
    maxLength?: number,
    row?: number,
  }

export const TextAreaTermo = ({ label, onChange, value, className = '', readOnly, disabled, dimension, name, id, error, helperText, maxLength, row = 10}: ITextAreaTermoOs) => {
    return (
      <FormControl className="container" sx={{ m: 1, width: "100%", justifyContent: 'flex-start'}}>
        {label && <label htmlFor={id} className={`textarea-label ${dimension}`}>{label}</label>}
        <textarea
         className="responsive-textarea" 
         onChange={onChange} 
         value={value}
         readOnly={readOnly}
         disabled={disabled}
         name={name}
         rows={row}
         id={id}
         maxLength={maxLength}
        />
        <span className="errorMessage">{helperText}</span>
      </FormControl>
    )
  }