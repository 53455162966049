import { apiGet, apiPost, apiPut } from "../../../services/api";
import { IGetEstados } from "../types/types";


const baseUrl = process.env.REACT_APP_API_ORDEMSERVICO

export const getGrupoServicoPorId = (id: number | string) => 
  apiGet(`${baseUrl}/GrupoServico/${id}`)

export const putGrupoServico = (idGrupoServico: number | string, params: any) =>
  apiPut(`${baseUrl}/GrupoServico/${idGrupoServico}`, params)

export const postGrupoServico = (params: any) =>
  apiPost(`${baseUrl}/GrupoServico`, params)

export const postEstado = async (params: any) =>
  await apiPost(`${baseUrl}/estados`, params)

export const putEstado = async (params: any, idEstado: number | string) =>
  await apiPut(`${baseUrl}/estados/${idEstado}`, params)

export const getListaEstadosComFiltro = async (params: IGetEstados) => 
  await apiGet(`${baseUrl}/estados`, params)