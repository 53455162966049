import React from "react";
import { useLocation } from "react-router-dom";
import IncluirFamilia from "./incluirFamilia";
import EditarFamilia from "./editarFamilia";

const IncluirOuAlterarFamilia = () => {
  const location = useLocation();
  const dadosFamilia = location.state?.dadosFamilia || {};

  if (!!dadosFamilia.idFamilia) return <EditarFamilia/>;
  return <IncluirFamilia/>;
};

export default IncluirOuAlterarFamilia;
