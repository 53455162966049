import _ from "lodash";
import moment from 'moment'
import { FormatDateToApi } from "../helpers/formatDate";
import { IOrdemServico } from "./types/farolTypes";
import { IVisualizacaoOSContext } from './types/visualizacaoOsTypes'


export const VisualizacaoOSContext = (
  listaos: IOrdemServico[],
  totalCount: number,
  totalEmergencial: number,
  totalVencidas: number,
  totalVenceHoje: number,
  totalAVencer: number,
) => {
  const totalOS = () => {
    return [
      {
        name: "TotalOS",
        value: totalCount,
        total: totalCount,
      },
      { name: "RestanteOS", value: 0 },
    ];
  };

  const totalOSEmergencial = () => {
    let restanteOS = totalCount-totalEmergencial;

    return [
      { name: "TotalOS", value: totalEmergencial, total: totalCount },
      { name: "RestanteOS", value: restanteOS },
    ];
  };
  
  const totalOSVencidas = () => {
    let restanteOS = totalCount-totalVencidas;

    return [
      { name: "TotalOS", value: totalVencidas, total: totalCount },
      { name: "RestanteOS", value: restanteOS },
    ];
  };

  const totalOSVencemHoje = () => {
    let restanteOS = totalCount-totalVenceHoje;

    return [
      {
        name: "TotalOS",
        value: totalVenceHoje,
        total: totalCount,
      },
      { name: "RestanteOS", value: restanteOS },
    ];
  };

  const totalOSAVencer = () => {
    let restanteOS = totalCount-totalAVencer;

    return [
      { name: "TotalOS", value: totalAVencer, total: totalCount },
      { name: "RestanteOS", value: restanteOS },
    ];
  };

  return {
    totalOS,
    totalOSEmergencial,
    totalOSVencidas,
    totalOSVencemHoje,
    totalOSAVencer,
  };
};