import { apiGet, apiPost, apiPut } from "../../../services/api";
import { IGetStatus, IGetStatusOS, IPostStatusOS, IPutStatusOS } from "../types";

const baseUrl = process.env.REACT_APP_API_ORDEMSERVICO;

export const consultarStatusOS = async (params: IGetStatus) =>
    await apiGet(`${baseUrl}/statusOrdemServico`, params)

export const obterStatusOS = async (idStatus: number) =>
    await apiGet(`${baseUrl}/statusOrdemServico`, { idStatus })

export const postStatus = async (params: IPostStatusOS) =>
    await apiPost(`${baseUrl}/statusOrdemServico`, params)

export const putStatus = async (params: IPutStatusOS, id?: number | string) =>
    await apiPut(`${baseUrl}/statusOrdemServico/${id}`, params)
