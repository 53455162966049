import styled from "styled-components";
import { siteTheme } from "../../styles/themeVariables";

const {color} = siteTheme;

interface IButton {
  fontSize?: string,
  variant?: string,
  padding?: string,
  borderRadius?: string,
  margin?: string
  width?: string
  dimension?: 'sm' | 'lg'
}

const Button = styled.button<IButton>`
  font-size: ${props => props.fontSize || '14px' };
  background: ${props => props.variant === 'secondary' ? 
    color.secondary : props.variant === 'grey' ? color.cinzaClaro : color.primary};
  color: ${props => props.variant === 'secondary' ? 
    color.textCinza : color.secondary};
  opacity: ${props => props.disabled === true ? 
    '0.5' : '1'};
  cursor: ${props => props.disabled === true ? 
    'default' : 'pointer'};
  border: ${props => props.variant === 'secondary' ? 
    `1px solid ${color.cinzaClaro}` : 'none'};
  padding: ${props => props.padding || props.dimension === 'sm' ? '12.5px 33px' : '18.5px 43px'};
  border-radius: ${props => props.borderRadius || '15px'};
  box-sizing: border-box;
  transition: 0.5s;
  margin: ${props => props.margin || '8px 0'};
  width: ${props => props.width || 'auto'};
  min-width: ${props => props.width || 'auto'};
`
export default Button