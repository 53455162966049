import { useState, useEffect } from "react";
import { Link, useSearchParams } from "react-router-dom";
import Alert from "@mui/material/Alert";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";

import "./validarEmail.scss";
import ValidarEmailHeader from "./validarEmailHeader";
import { AxiosResponse } from "axios";
import FullscreenLoading from "../../../components/fullscreenLoading/fullscreenLoading";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Button, Box } from "@mui/material";
import {
  atualizaEmail,
  validarEmailToken,
} from "../services/services";

export interface IAtualizaEmail {
  token: string;
}

const ValidarEmail: React.FC = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("Token") as string;
  const [loading, setLoading] = useState(false);
  const [showAlertResult, setAlertResult] = useState<boolean | null>(null);

  useEffect(() => {
    validaEmailToken();
  },[]);

  async function validaEmailToken() {
    try {
      setLoading(true);
      const responseVerificacaoToken = (await validarEmailToken({
        token: token ?? "",
      })) as AxiosResponse;

      if (responseVerificacaoToken.status === 200) {
        const responseAtualizacaoEmail = (await atualizaEmail({
          token: token ?? "",
        })) as AxiosResponse;

        setAlertResult(responseAtualizacaoEmail.status === 200);
      } else {
        setAlertResult(false);
      }
    } catch (error) {
      console.error("Erro ao redefinir senha:", error);
      setAlertResult(false);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="ValidarEmail" id="ValidarEmail">
      <ValidarEmailHeader />
      {loading && <FullscreenLoading />}
      {showAlertResult !== null &&
        (showAlertResult ? (
          <Alert
            icon={<CheckIcon fontSize="inherit" />}
            severity="success"
            action={
              <IconButton
                aria-label="Fechar"
                color="inherit"
                size="small"
                onClick={() => setAlertResult(null)}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            Ação realizada com sucesso!
          </Alert>
        ) : (
          <Alert
            icon={<CheckIcon fontSize="inherit" />}
            severity="error"
            action={
              <IconButton
                aria-label="Fechar"
                color="inherit"
                size="small"
                onClick={() => setAlertResult(null)}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            Ocorreu um erro ao processar sua solicitação.
          </Alert>
        ))}

      <div className="ValidarEmailBox">
        <div className="ValidarEmailBoxWrapper">
          <div className="ValidarEmailBox-texto">Validação de E-mail</div>
          <div className="validar-email-form-group-texto">
            {showAlertResult === null && (
              <p>Aguarde enquanto estamos validando...</p>
            )}

            {showAlertResult !== null &&
              (showAlertResult ? (
                <p>Pronto tudo certo, seu e-mail esta validado.</p>
              ) : (
                <p>
                  Aconteceu alguma coisa errada, tente novamente mais tarde,
                  caso o erro persiste contacte o administrador do sistema.
                </p>
              ))}
          </div>
          <Box display="flex" justifyContent="center">
            <Link to="/Login">
              <Button
                align-items="center"
                size="small"
                variant="text"
                startIcon={<ArrowBackIcon />}
              >
                Voltar ao Login
              </Button>
            </Link>
          </Box>
        </div>
      </div>
    </div>
  );
};

export default ValidarEmail;
