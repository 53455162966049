import styled from "styled-components";
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Table from '@mui/material/Table';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 650px;
  background-color: #ffffff;
  padding: 0px 40px 30px 40px;
  box-sizing: border-box;
  & .MuiAlert-action {
    margin: unset;
  }
`;

export const StyledTable = styled(Table)`
  width: 650px;
`;

export const StyledTableCell = styled(TableCell)`
  && {
    font-size: 14px;
    background-color: ${(props) => (props.component === "th" ? "#AFB1B1" : "#ffffff")};
    color: ${(props) => (props.component === "th" ? "#ffffff" : "#797D8C")};
    border: 1px solid #E0E0E0;
  }
`;

export const StyledTableRow = styled(TableRow)`
  &&:nth-of-type(odd) {
    background-color: #fafafa;
  }
`;

export const StyledTableHead = styled.thead`
  && {
    background-color: #AFB1B1; 
    & th {
      color: #ffffff
      font-weight: bold;
    }
  }
`;

export const StyledTableBody = styled.tbody`
  && {
    background-color: #FFFFFF; 
  }
`;