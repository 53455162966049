import React, { useState, useEffect } from "react";
import Modal from "../../components/modal";
import Button from "../../components/Button/styles";
import {
  Container,
  InfoLabel,
  FlexButtons,
} from "./yesOrNoModal_Styles";

export interface IModalProps {
  isModalOpen: boolean,
  handleClose: () => void,
  handleClickYes: Function,
  title?: string | any,
}

const YesOrNoModal = ({ 
  isModalOpen, 
  handleClose, 
  handleClickYes, 
  title = "Deseja processeguir?", 
}: IModalProps) => {
  const [isOpen, setIsOpen] = useState(isModalOpen);

  useEffect(() => {
    setIsOpen(isModalOpen);
  }, [isModalOpen]);

  return (
    <Modal
      handleCloseModal={handleClose}
      title={" "}
      isOpen={isOpen}
      titleStyle={{
        bgcolor: "#FFF",
        color: "#797D8C",
        fontWeight: 700
      }}
    >
      <Container>
        <InfoLabel>
          {title}
        </InfoLabel>

        <FlexButtons>
          <Button
            id={"YesOrNoModal-BotaoNao"}
            variant='secondary'
            dimension='sm'
            width='150px'
            onClick={handleClose}
          >
            Não
          </Button>

          <Button
            id={"YesOrNoModal-BotaoYes"}
            variant='primary'
            dimension='sm'
            width='150px'
            onClick={() => handleClickYes()}
          >
            Sim
          </Button>
        </FlexButtons>
      </Container>
    </Modal>
  );
};

export default YesOrNoModal;
