import React, { ReactNode, ComponentType, useContext } from "react";
import styled from "styled-components";
import { Button } from "@mui/material";
import Header from "../header/header";
import { HomeContext } from "../../contexts/homeContext";
import { Breadcrumb, BreadcrumbItem } from "../breadcrumb/breadcrumb";
import { useNavigate } from "react-router-dom";
interface SectionCustomProps {
  children: ReactNode;
  titleHeader: string;
  titleBreadcrumbItem:string;
  titleBreadcrumbItemBack?:string;
  id:string;
  className:string;
  rotaVoltar?:string;
}

export const Section = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
`;

const SectionCustom: React.FC<SectionCustomProps> = ({ children,titleHeader,titleBreadcrumbItem,titleBreadcrumbItemBack,id,className,rotaVoltar }) => {
  const { setMenuLateral } = useContext(HomeContext);
  const navigate = useNavigate();
  return (
    <Section className={className} id={id}>
      <Header setMenuLateral={() => setMenuLateral(true)} title={titleHeader} />
      <Breadcrumb>
      {titleBreadcrumbItemBack && (
          rotaVoltar !== null ? (
            <BreadcrumbItem onClick={() => navigate(rotaVoltar!)}>
              {titleBreadcrumbItemBack}
            </BreadcrumbItem>
          ) : (
            <BreadcrumbItem>{titleBreadcrumbItemBack}</BreadcrumbItem>
          )
        )}
        <BreadcrumbItem>{titleBreadcrumbItem}</BreadcrumbItem>
      </Breadcrumb>
      {children}
    </Section>
  );
};

export default SectionCustom;