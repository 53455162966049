import React from 'react';
import './checkbox.scss';
import { ICheckbox } from './types'

const Checkbox = (props: ICheckbox) => {

  const { disabled, id, name, label, value, onChange, boxclassName, inputclassName, labelclassName } = props
  
  return (
    <label id={id ? id : "checkbox"} className={("Checkboxclass " + (boxclassName ? boxclassName : ""))}>
      <input disabled={disabled} id={id ? id : "checkboxinput"} name={name ? name : 'checkbox'} type="checkbox" checked={value} onChange={onChange} className={("Checkboxinputclass " + (inputclassName ? inputclassName : ""))}/>
      <div className={("Checkboxlabelclass " + (labelclassName ? labelclassName : ""))}>{label}</div>
    </label>
  );
};

export default Checkbox;
