import React, { useState, useEffect, useMemo, useCallback, memo } from "react";
import { Autocomplete, TextField, CircularProgress, FormControl, Chip } from "@mui/material";
import { IAutocompleteMultiple } from "./types";
import "./autocompleteMultiple.scss";

const CustomCheckbox = memo(({ checked }: { checked: boolean }) => (
  <div
    style={{
      margin: 4,
      marginRight: 10,
      width: 18,
      height: 18,
      borderRadius: 4,
      border: "2px solid rgba(0, 0, 0, 0.23)",
      backgroundColor: checked ? "rgb(41, 64, 51)" : "#fff",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    {checked && (
      <svg
        width="15"
        height="15"
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20 6L9 17L4 12"
          stroke="white"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    )}
  </div>
), (prevProps, nextProps) => prevProps.checked === nextProps.checked);

const AutocompleteMultiple = ({
  id = "Autocomplete-multiple",
  disableClearable = false,
  clearText = "Limpar",
  limitTags = 5,
  options,
  label,
  name,
  fontSize,
  loading,
  value,
  defaultValue,
  onChange,
  noOptionsText = "Nenhuma opção encontrada",
  placeholder,
  dimension,
  error,
  helperText,
  disabled,
  showCheckbox = false,
  multiple = true,
  readOnly,
  keepOptionInTheList = false,
  allSelected,
  borderRadius,
  styleInput,
  disableCloseOnSelect = true
}: IAutocompleteMultiple & { styleInput?: React.CSSProperties }) => {
  const [localValue, setLocalValue] = useState<any[]>(value!);

  const styleError = error ? { border: "1px solid #ff2323" } : {};

  useEffect(() => {
    setLocalValue(value!);
  }, [value]);

  const getOptions = useMemo(() => {
    let opcoes = [...options];
    if (allSelected && opcoes[0]?.id !== "all" && multiple && opcoes.filter(item => item.id !== "all").length !== 0) {
      opcoes = [
        { name: "Todos", id: "all" },
        ...opcoes
      ];
    }
    return showCheckbox || keepOptionInTheList
      ? opcoes
      : opcoes?.filter(el => !localValue?.some(f => f.id === el.id));
  }, [allSelected, options, multiple, showCheckbox, keepOptionInTheList, localValue]);

  const getValue = useMemo(() => {
    return multiple ? localValue : localValue?.length ? localValue[0] : null;
  }, [multiple, localValue]);

  const handleChangeValue = useCallback((event: React.ChangeEvent<{}>, newValue: any) => {
    if (multiple) {
      const hasAllSelected = newValue.some((item: any) => item.id === "all");
      const filteredOptions = options?.filter(item => item.id !== "all");

      if (hasAllSelected) {
        const newLocalValue = filteredOptions.length === newValue.filter((item: any) => item.id !== "all").length ? [] : filteredOptions;
        setLocalValue(newLocalValue);
        onChange?.(newLocalValue);
      } else {
        setLocalValue(newValue);
        onChange?.(newValue);
      }
    } else {
      const value = newValue ? [newValue] : [];
      setLocalValue(value);
      onChange?.(value);
    }
  }, [multiple, options, onChange]);

  return (
    <FormControl sx={{ m: 1, width: "100%", margin: 0 }}>
      <Autocomplete
        sx={readOnly ? {} : { "& .MuiInputBase-root": { backgroundColor: '#fff' } }}
        disableClearable={disableClearable}
        clearText={clearText}
        limitTags={limitTags}
        noOptionsText={noOptionsText}
        className={`Autocomplete ${dimension}`}
        classes={{ noOptions: "noOptionsWrapperClass" }}
        disableCloseOnSelect={disableCloseOnSelect}
        multiple={multiple}
        placeholder={placeholder}
        id={id}
        options={getOptions}
        getOptionLabel={(option) => option.name}
        value={getValue}
        defaultValue={defaultValue}
        onChange={handleChangeValue}
        disabled={disabled || readOnly}
        isOptionEqualToValue={(option, value) => (String(option?.id) === String(value?.id))}
        renderInput={(params) => (
          <>
            {label && <span className={`label ${dimension}`}>{label}</span>}
            <TextField
              {...params}
              placeholder={localValue?.length === 0 ? placeholder : ""}
              name={name}
              className="Input-service"
              sx={{
                "& .MuiInputBase-root": {
                  borderRadius,
                  "& .MuiAutocomplete-input": dimension === "xs" ? { padding: "0px 10px" } : {}
                },
                ...styleInput
              }}
              InputProps={{
                ...params.InputProps,
                style: dimension === "sm"
                  ? { ...styleError, fontSize, minHeight: "44px", borderRadius: "15px", padding: "3px" }
                  : dimension === "lg"
                    ? { ...styleError, fontSize, minHeight: "56px", borderRadius: "15px" }
                    : dimension === "xs"
                      ? { ...styleError, fontSize, minHeight: "32px", height: "32px", borderRadius: "15px", padding: "0px 10px" }
                      : { ...styleError },
                endAdornment: (
                  <>
                    {loading && <CircularProgress color="inherit" size={20} />}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          </>
        )}
        renderOption={(props, option, { selected }) => (
          <li {...props} key={option.id}>
            {showCheckbox && (
              <CustomCheckbox checked={(option.id === "all" && options?.length === getValue?.length) || selected} />
            )}
            {option.name}
          </li>
        )}
        renderTags={(value, getTagProps) => (
          <>
            {value.slice(0, limitTags).map((option, index) => (
              <Chip
                size="small"
                style={{ maxWidth: "10rem" }}
                label={option.name}
                {...getTagProps({ index })}
                key={index}
              />
            ))}
            {value.length > limitTags && ` +${value.length - limitTags}`}
          </>
        )}
      />
      {helperText && <span className="errorMessage">{helperText}</span>}
    </FormControl>
  );
};

export default AutocompleteMultiple;
