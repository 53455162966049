import { useState } from "react"
import { IGetPerfilResponse } from '../pages/perfil/types'


export const PerfilContext = () => {
  const [selectedPerfil, setSelectedPerfil] = useState<IGetPerfilResponse>({} as IGetPerfilResponse)

  const [perfilLista, setPerfilLista] = useState<{name: string, id: string}[]>([])

  const [selectedTelas, setSelectedTelas] = useState<number[]>([])

  return {
    selectedPerfil,
    setSelectedPerfil,
    perfilLista,
    setPerfilLista,
    selectedTelas,
    setSelectedTelas
  }
}