import { Button } from "@mui/material";
import Modal from "../../../components/modal";
import { Container } from "./farolModalAvisoOSCourierStyles";
import { InfoLabel } from "./farolModalAvisoOSCourierStyles";
import { siteTheme } from "../../../styles/themeVariables";

const { color } = siteTheme

const FarolErroImprimirModal = ({open, onClose}: {open: boolean, onClose: () => void}) => {
    return (
        <Modal
            handleCloseModal={onClose}
            title=""
            isOpen={open}
            titleStyle={{
                bgcolor: "#FFF",
                color: "#797D8C",
                fontWeight: 700
              }}
            >
            <Container>
                <InfoLabel>Você só poderá imprimir OS do mesmo contratante.</InfoLabel>
                <Button  
                    onClick={onClose}
                    sx={{
                        bgcolor: color.primary,
                        color: "#FFF",
                        "&:hover": {
                            backgroundColor: color.primary,
                            color: "#FFF",
                        },
                        width: "180px",
                        height: "44px",
                        margin: "0px auto",
                        borderRadius: "15px",
                        fontSize: "12px",
                        fontFamily: "Open Sans",
                        }}
                    >
                    OK
                </Button>
            </Container>
        </Modal>
    )
}

export default FarolErroImprimirModal;