import React, { useContext, useEffect, useState } from "react";
import Header from "../../../components/header/header";
import { Breadcrumb, BreadcrumbItem } from "../../../components/breadcrumb/breadcrumb";
import { HomeContext } from "../../../contexts/homeContext";
import { AuthContext } from "../../../contexts/auth";
import './editarFamilia.scss';
import { useNavigate, useLocation } from "react-router-dom";
import Input from "../../../components/Input/Input";
import MultipleSelectCheckmarksid from "../../../components/seletorMultiploId/seletorMultiploId";
import { useFormik } from "formik";
import * as yup from "yup";
import Button from "../../../components/Button/styles";
import { AxiosResponse } from "axios";
import {
  Section,
} from "./editarFamilia_styles";
import { ReactComponent as Voltar } from "../../../assets/icons/Voltar.svg";
import moment from "moment";
import { Alert, CircularProgress, IconButton } from '@mui/material';
import Collapse from '../../../components/collapse/collapse';
import CloseIcon from "@mui/icons-material/Close";
import { editarFamilia } from "../services";

const EditarFamilia = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const dadosFamilia = location.state?.dadosFamilia || {};
  const rota = location.state?.rota || {};

  const { setMenuLateral } = useContext(HomeContext);
  const { user, funcionalidadeDaTelaTemPermissao } = useContext(AuthContext);
  const profileHasPermission = (funcionalidade: string) => funcionalidadeDaTelaTemPermissao("Inclusão familia", funcionalidade);

  const [disableActions, setDisableActions] = useState(false);
  const [listaStatus, setListaStatus] = useState<{ name: string, id: string }[]>([
    { name: 'Ativo', id: '0' },
    { name: 'Inativo', id: '1' },
  ]);
  const [openAlertError, setOpenAlertError] = useState(false);
  const [msgAlertError, setMsgAlertError] = useState("");
  const [openAlertSuccess, setOpenAlertSuccess] = useState(false);
  const [msgAlertSuccess, setMsgAlertSuccess] = useState("");
  const [loading, setLoading] = useState<boolean>(false);

  const handleEditarFamilia = async (values: any, actions: any) => {
    setOpenAlertError(false);
    setLoading(true);
    setDisableActions(true);

    const statusBool = values.status[0] === '0' ? true : false;

    const params = {
      status: statusBool,
      // ativo: statusBool,
      idFamilia: dadosFamilia.idFamilia,
      descricao: values.descricao,
      // usuarioCriacao: values.usuarioCriacao,
      // dataCriacao: dadosFamilia.dataCriacao,
      // dataModificacao: moment().format("YYYY-MM-DDTHH:mm:ss"),
      // usuarioModificacao: user?.nome,
    };

    try {
      let retorno = await editarFamilia(params) as AxiosResponse;

      setLoading(false);
      setDisableActions(false);
      setMsgAlertSuccess("Registro salvo com sucesso");
      setOpenAlertSuccess(true);
      navigate(".", {
        state: {
          dadosFamilia: {
            ...dadosFamilia,
            ...params,
            dataModificacao: moment().format("YYYY-MM-DDTHH:mm:ss"),
            usuarioModificacao: user?.nome,
          },
          rota: {
            url: rota.url,
            name: rota.name,
          },
        }
      }); // <-- redirect to current path with update state

    } catch (error) {
      setLoading(false);
      setDisableActions(false);
      setMsgAlertError("Erro ao salvar o registro");
      setOpenAlertError(true);
    }
  };

  const validationSchema = yup.object({
    status: yup.array().required('Campo obrigatório'),
    descricao: yup.string().required('Campo obrigatório'),
  });

  const initialValues: any = {
    status: !!dadosFamilia.ativo ? ["0"] : ["1"],
    idFamilia: dadosFamilia.idFamilia,
    descricao: dadosFamilia.descricao,
    usuarioCriacao: dadosFamilia.usuarioCriacao,
    dataCriacao: !!dadosFamilia.dataCriacao ? moment(dadosFamilia.dataCriacao, "YYYY-MM-DDTHH:mm:ss").format("DD/MM/YYYY HH:mm:ss") : "",
    dataModificacao: !!dadosFamilia.dataModificacao ? moment(dadosFamilia.dataModificacao, "YYYY-MM-DDTHH:mm:ss").format("DD/MM/YYYY HH:mm:ss") : "",
    usuarioModificacao: dadosFamilia.usuarioModificacao,
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values, actions) => {
      if (!!profileHasPermission("Editar Registro")) return handleEditarFamilia(values, actions);
    }
  });

  return (
    <Section id={"EditarFamilia"} className="EditarFamilia">
      <Header
        setMenuLateral={() => setMenuLateral(true)}
        title="Alteração de familia"
      />
      <Breadcrumb>
        {!!rota && rota.name &&
          <BreadcrumbItem onClick={() => navigate(rota.url)}>{rota.name}</BreadcrumbItem>
        }
        <BreadcrumbItem>Alteração de familia</BreadcrumbItem>
      </Breadcrumb>

      <form className="EditarFamilia-Form" onSubmit={formik.handleSubmit}>
        <div className="EditarFamilia-Container">
          <MultipleSelectCheckmarksid
            dimension='sm'
            id='multiple-checkbox-status'
            idOption='multiple-checkbox-option-status'
            labelId='multiple-checkbox-label-status'
            nodata="Nenhum status encontrado"
            value={formik.values.status}
            onChange={(e) => (formik.setFieldValue('status', e))}
            options={listaStatus}
            label='Status'
            name='status'
            error={formik.touched.status && Boolean(formik.errors.status)}
            helperText={formik.touched.status && formik.errors.status}
            multiple={false}
            disabled={disableActions}
          />
          <div className="InsideGrid">
            <Input
              type='number'
              dimension="sm"
              label='Id Família'
              name='idFamilia'
              id='idFamilia'
              value={formik.values.idFamilia}
              onChange={formik.handleChange}
              error={formik.touched.idFamilia && Boolean(formik.errors.idFamilia)}
              helperText={formik.touched.idFamilia && formik.errors.idFamilia}
              readOnly
            />
            <Input
              dimension="sm"
              label='Descrição'
              name='descricao'
              id='descricao'
              value={formik.values.descricao}
              onChange={formik.handleChange}
              error={formik.touched.descricao && Boolean(formik.errors.descricao)}
              helperText={formik.touched.descricao && formik.errors.descricao}
              disabled={disableActions}
            />
          </div>
          <Input
            dimension="sm"
            label='Criado Por'
            name='usuarioCriacao'
            id='usuarioCriacao'
            value={formik.values.usuarioCriacao}
            onChange={formik.handleChange}
            error={formik.touched.usuarioCriacao && Boolean(formik.errors.usuarioCriacao)}
            helperText={formik.touched.usuarioCriacao && formik.errors.usuarioCriacao}
            readOnly
          />

          <div className="InsideGrid2">
            <Input
              dimension="sm"
              label='Data de Criação'
              id="dataCriacao"
              value={formik.values.dataCriacao}
              readOnly
              onChange={(e) => (formik.setFieldValue('dataCriacao', e))}
              error={formik.touched.dataCriacao && Boolean(formik.errors.dataCriacao)}
              helperText={(formik.touched.dataCriacao && formik.errors.dataCriacao) ? "Campo obrigatório" : ""}
            />
            <Input
              dimension="sm"
              label='Data de Modificaçao'
              id="dataModificacao"
              value={formik.values.dataModificacao}
              readOnly
              onChange={(e) => (formik.setFieldValue('dataModificacao', e))}
              error={formik.touched.dataModificacao && Boolean(formik.errors.dataModificacao)}
              helperText={(formik.touched.dataModificacao && formik.errors.dataModificacao) ? "Campo obrigatório" : ""}
            />
            <Input
              dimension="sm"
              label='Modificado Por'
              name='usuarioModificacao'
              id='usuarioModificacao'
              value={formik.values.usuarioModificacao}
              onChange={formik.handleChange}
              error={formik.touched.usuarioModificacao && Boolean(formik.errors.usuarioModificacao)}
              helperText={formik.touched.usuarioModificacao && formik.errors.usuarioModificacao}
              readOnly
            />
          </div>
        </div>

        <div className='button-list-right'>
          {!!profileHasPermission("Editar Registro") ?
            <Button variant='primary' type="submit" disabled={disableActions}>Salvar</Button>
            :
            <Button variant='primary' style={{ cursor: 'not-allowed', opacity: 0.5 }} type="button" disabled={true}>Salvar</Button>
          }
        </div>
      </form>

      {!!loading && <CircularProgress style={{ margin: '0px auto' }} />}

      {openAlertError &&
        <Collapse onClose={setOpenAlertError} in={openAlertError}>
          <Alert
            severity='error'
            icon={<div />}
            variant="filled"
            sx={{
              mb: 2,
              bgcolor: "rgb(255, 35, 35, 0.7)",
              borderRadius: "15px",
              boxSizing: "border-box",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: '50px',
            }}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="medium"
                onClick={() => setOpenAlertError(false)}
              >
                <CloseIcon
                  id={"ModalEncaminharOS-AlertCloseIcon"}
                  fontSize="inherit"
                  sx={{
                    color: "white",
                  }}
                />
              </IconButton>
            }
          >
            <span
              style={{
                color: "white",
                fontSize: "16px",
              }}
              dangerouslySetInnerHTML={{ __html: msgAlertError }}
            />
          </Alert>
        </Collapse>
      }

      {openAlertSuccess &&
        <Collapse onClose={setOpenAlertSuccess} in={openAlertSuccess}>
          <Alert
            severity='success'
            icon={<div />}
            variant="filled"
            sx={{
              mb: 2,
              bgcolor: "#178B319E",
              borderRadius: "15px",
              boxSizing: "border-box",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: '16px',
            }}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="medium"
                onClick={() => setOpenAlertSuccess(false)}
              >
                <CloseIcon
                  id={"ModalEncaminharOS-AlertCloseIcon"}
                  fontSize="inherit"
                  sx={{
                    color: "white",
                  }}
                />
              </IconButton>
            }
          >
            <span
              style={{
                color: "white",
                fontSize: "16px",
              }}
            >
              {msgAlertSuccess}
            </span>
          </Alert>
        </Collapse>
      }
    </Section>
  );
};

export default EditarFamilia;
