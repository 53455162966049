import React, { useContext, useEffect, useState } from 'react'
import Header from '../../../components/header/header'
import { HomeContext } from '../../../contexts/homeContext';
import { AuthContext } from "../../../contexts/auth";
import { Breadcrumb, BreadcrumbItem } from '../../../components/breadcrumb/breadcrumb';
import { useNavigate } from 'react-router';
import MultipleSelectCheckmarksId from '../../../components/seletorMultiploId/seletorMultiploId';
import AutocompleteMultiple from "../../../components/autocompleteMultiple/autocompleteMultiple";
import Input, { MaskedInput } from '../../../components/Input/Input';
import moment from 'moment';
import Button from '../../../components/Button/styles';
import _ from 'lodash'
import { Form, Formik, useFormik } from "formik";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import Collapse from '../../../components/collapse/collapse';
import CloseIcon from "@mui/icons-material/Close";
import * as yup from "yup";
import "./incluirEditarTecnico.scss"
import DatePicker from '../../../components/datePicker/datePicker';
import { AxiosResponse } from 'axios';
import { getPrestadores } from '../../estoqueTecnico/services/services';
import { IConsultaPrestadorTabela } from '../../prestador/types';
import { getAtividadesTecnico, postAtividadesTecnicoExportar, postTecnico, postVinculaTecnicoPrestador, putEditaVinculoTecnicoPrestador, putTecnico } from '../services';
import StickyTable from '../../../components/stickyTable/stickyTable';
import { getPrestadoresResponsaveis } from '../../prestadorResponsavel/services/services';
import { IPrestadorResponsavel } from '../../prestadorResponsavel/types';
import { ReactComponent as ExpandIcon } from "../../../assets/icons/ExpandIcon.svg";
import { CircularProgress, Table, TableRow, Typography } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import * as Styles from "../../../components/table/styles";
import SwitchMUI from '../../../components/switch/switch';
import { styled } from '@mui/material/styles';
import { IAtividadesTecnicoDados, IAtividadesTecnicoDadosFiltro, IAtividadesTecnicoDadosExport } from '../types';
import { IosShare } from '@mui/icons-material';
import { arrayRemoveProp } from '../../../helpers/util';

interface IInitialValues {
  ativo: string[],
  idTecnico: string,
  nomeTecnico: string,
  provedor: {name: string, id: string}[]
  cpf: string
  celular: string
  date: string | Date | null
  primeiroAcesso: boolean,
  atendeForaPerimetro: boolean,
  checklistSeguranca: boolean,
  prestadores: {
    ativo: boolean,
    idPrestador: number | string,
    idTecnicoOppay: number,
    nome: string
  }[]
  permiteVisualizarIndicadores: boolean;
}

interface IInitialValuesAtividadesTecnico {
  dataInicialFormik: any;
  dataFinalFormik: any;
}

const IncluirEditarTecnico = () => {

  const isIncluding = window.location.pathname.includes('IncluirTecnico')

  const { funcionalidadeDaTelaTemPermissao, user } = useContext(AuthContext);
  const profileHasPermission = (funcionalidade: string) => funcionalidadeDaTelaTemPermissao(
    isIncluding ? "Inclusão de Técnico" : "Edição Técnico",
    funcionalidade);

  const { setMenuLateral, tecnicoSelecionado, setTecnicoSelecionado } = useContext(HomeContext);

  const navigate = useNavigate()

  const [prestadoresLista, setPrestadoresLista] = useState<{name: string, id: string}[]>([])
  const [prestadoresLocal, setPrestadoresLocal] = useState<{name: string, id: string}[]>(
    tecnicoSelecionado?.prestadores?.map(item => ({name: item.nome, id: item.idPrestador.toString(), ativo: item.ativo})).filter(item => item.ativo)
  )
  const [tipomsgAlert, setTipoMsgAlert] = useState<'success' | 'info' | 'warning' | 'error'>("success");
  const [msgAlert, setMsgAlert] = useState("OS encaminhada com  sucesso!");
  const [openAlert, setOpenAlert] = useState(false);
  const [selectedPrestadores, setSelectedPrestadores] = useState<any[]>([])
  const [prestadoresResponsaveisLista, setPrestadoresResponsaveisLista] = useState<{ name: string; id: string; }[]>([])
  const [prestadoresAccordion, setPrestadoresAccordion] = useState(false)
  const [configuracaoAccordion, setConfiguracaoAccordion] = useState(false)
  const [tableData, setTableData] = useState([])

  const validationSchema = isIncluding && yup.object({
    ativo: yup.array().max(1).min(1, 'Campo obrigatório'),
    nomeTecnico: yup.string().required("Campo obrigatório"),
    cpf: yup.string().required("Campo obrigatório")
  })

  const initialValues: IInitialValues = {
    ativo: [(tecnicoSelecionado?.ativo || isIncluding) ? '1' : '0'],
    idTecnico: tecnicoSelecionado?.id?.toString() || '',
    nomeTecnico: tecnicoSelecionado?.nomeCompleto?.toString() || '',
    provedor: [{id: tecnicoSelecionado?.idPrestador?.toString(), name: prestadoresLista.find(item => item.id === tecnicoSelecionado?.idPrestador?.toString())?.name || '' }],
    cpf: tecnicoSelecionado?.cpf?.toString() || '',
    celular: tecnicoSelecionado?.celular || '',
    date: null,
    primeiroAcesso: isIncluding ? true : tecnicoSelecionado?.primeiroAcesso,
    atendeForaPerimetro: isIncluding ? false : !!tecnicoSelecionado?.atendeForaPerimetro,
    permiteVisualizarIndicadores: isIncluding ? false : !!tecnicoSelecionado?.permiteVisualizarIndicadores,
    checklistSeguranca: false,
    prestadores: tecnicoSelecionado?.prestadores?.filter(item => item.ativo)
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values, actions) => {
      if (isIncluding) {
        if (!!profileHasPermission("Criar novo registro")) return handleIncludeTecnico(values);
      } else {
        if (!!profileHasPermission("Editar Registro")) return handleEditTecnico(values);
      }
    }
  })

  const handleIncludeTecnico = async (values: IInitialValues) => {
    let idNovoTecnico: number = 0
    try{
      const { data } = await postTecnico({
        idPrestador: values.provedor[0]?.id,
        nomeCompleto: values.nomeTecnico,
        cpf: values.cpf.replace(/\D/g, ''),
        celular: values.celular.replace(/\D/g, ''),
        primeiroAcesso: true,
        createdFromOppay: false,
        atendeForaPerimetro: values.atendeForaPerimetro,
        visualizarIndicadores: values.permiteVisualizarIndicadores,
        idPrestadorResponsavel: 0,
      }) as AxiosResponse;

      setMsgAlert('Técnico criado com sucesso')
      setTipoMsgAlert("success")
      setOpenAlert(true)

      idNovoTecnico = data.id

      formik.resetForm()

    } catch(e: any) {
      if (e?.response?.data?.errors) {
        setMsgAlert(Object.values(e.response.data.errors).join("<br>"));
      } else {
        setMsgAlert("Erro ao incluir técnico");
      }
      setTipoMsgAlert("error");
      setOpenAlert(true);
    }

    if(!_.isEmpty(values.prestadores) && idNovoTecnico !== 0){
      try{

        await postVinculaTecnicoPrestador(values.prestadores, idNovoTecnico) as AxiosResponse

      } catch (e: any){
        setMsgAlert(Object.values(e.response.data.errors).join("<br>"))
        setTipoMsgAlert("error")
        setOpenAlert(true)
      }
    }
  }

  const handleEditTecnico = async (values: IInitialValues) => {
    if(!_.isEmpty(values.prestadores)){
      const idsPrestadoresTecnico = tecnicoSelecionado.prestadores.filter(item => item.ativo).map(item => item.idPrestador.toString())
      const idsPrestadoresSelecionados = values.prestadores.map(item => item.idPrestador)

      const idsParaVincular = values.prestadores.filter(
        item => (!idsPrestadoresTecnico.includes(item.idPrestador.toString()))
      )

      const idsParaDesvincular = tecnicoSelecionado.prestadores.filter(
        item => (!idsPrestadoresSelecionados.includes(item.idPrestador.toString()))
      )

      if(!_.isEmpty(idsParaVincular) || !_.isEmpty(idsParaDesvincular)){
        try{
  
          const tecnicoIdOppayVincular = idsParaVincular.map(item => ({
            idTecnicoOppay: tecnicoSelecionado?.prestadores[0].idTecnicoOppay,
            ativo: true,
            idPrestador: item.idPrestador
          }))

          const tecnicoIdOppayDesvincular = idsParaDesvincular.map(item => ({
            idTecnicoOppay: item.idTecnicoOppay,
            ativo: false,
            idPrestador: item.idPrestador,
          }))

          const tecnicoIdOppay = [...tecnicoIdOppayVincular, ...tecnicoIdOppayDesvincular]

          await putEditaVinculoTecnicoPrestador(tecnicoIdOppay, tecnicoSelecionado.id)
  
        } catch(e: any){
          setMsgAlert(Object.values(e.response.data.errors).join("<br>"))
          setTipoMsgAlert("error")
          setOpenAlert(true)
        }
      }
    }

    try{
      await putTecnico({
        idPrestador: values.provedor[0]?.id,
        nomeCompleto: values.nomeTecnico,
        cpf: values.cpf.replace(/\D/g, ''),
        primeiroAcesso: values.primeiroAcesso,
        status: values.ativo[0] === '1' ? true : false,
        atendeForaPerimetro: values.atendeForaPerimetro,
        visualizarIndicadores: values.permiteVisualizarIndicadores,
        idPrestadorResponsavel: 0,
        celular: values.celular.replace(/\D/g, ''),
      }, values.idTecnico) as AxiosResponse;

      /**Atualiza dados do tecnico selecionado já em tela ao dar sucesso no endpoint */
      setTecnicoSelecionado({
        ...tecnicoSelecionado,
        idPrestador: values.provedor[0]?.id,
        nomeCompleto: values.nomeTecnico,
        cpf: values.cpf.replace(/\D/g, ''),
        primeiroAcesso: values.primeiroAcesso,
        ativo: values.ativo[0] === '1' ? true : false,
        atendeForaPerimetro: values.atendeForaPerimetro,
        permiteVisualizarIndicadores: values.permiteVisualizarIndicadores,
        celular: values.celular
      });

      setMsgAlert("Técnico alterado com sucesso");
      setTipoMsgAlert("success");
      setOpenAlert(true);

    } catch(e) {
      setMsgAlert("Erro ao editar técnico");
      setTipoMsgAlert("error");
      setOpenAlert(true);
    }
  }

  const buscaPrestadorResponsavel = async () => {
    try{
      const { data } = await getPrestadoresResponsaveis() as AxiosResponse

      const lista: {name: string, id: string}[] = data?.map((item: IPrestadorResponsavel) => {
        return { name: item.descricao, id: item.id.toString() }
      }).sort((a: {name: string, id: string},b: {name: string, id: string}) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))

      setPrestadoresResponsaveisLista(lista)

    } catch (e){
      return
    }
  }

  const filtraPrestadoresDoUsuario = (prestadores: any) => {
    let options: any[] = [...prestadores];

    if (!!user?.prestadores && !!user.prestadores.length) {
      options = options.filter((o: any) => user.prestadores.includes(Number(o.id)));

      /**Auto preenche o filtro prestadores */
      if (user.prestadores.length === 1) {
        setPrestadoresLista(options);
      }
    } 

    return options;
  }

  const buscaPrestador = async () => {
    try{
      const { data } = await getPrestadores() as AxiosResponse

      const lista: {name: string, id: string}[] = filtraPrestadoresDoUsuario(
        data?.map((item: IConsultaPrestadorTabela) => {
        return { name: item.nomePrestador, id: item.id.toString() }
      }).sort((a: {name: string, id: string},b: {name: string, id: string}) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)))

      setPrestadoresLista(lista)

    } catch (e){
      return
    }
  }

  const handleChangePrestador = (prestadores: {name: string, id: string}[]) => {
    const prestadorTemp = prestadores.map(item => ({idPrestador: item.id, ativo: true}))
    setPrestadoresLocal(prestadores)
    formik.setFieldValue('prestadores', prestadorTemp)
  }

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(even)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  const statusLista = [
    {
      name: 'Ativo',
      id: '1'
    }, 
    {
      name: 'Inativo',
      id: '0'
    }
  ]

  const objTraducaoHeader = {
    idTecnicoOppay: 'ID técnico Oppay',
    nome: 'Prestador',
    ativo: 'Status',
    idPrestador: 'ID Prestador'
  }

  useEffect(() => {
    buscaPrestador()
    buscaPrestadorResponsavel()
    !_.isEmpty(tecnicoSelecionado?.prestadores) &&
      setTableData(tecnicoSelecionado?.prestadores?.map(item => {
        return Object.assign({}, item, {ativo: item.ativo ? 'Ativo' : 'Inativo'} )
      }))
  }, [])

  useEffect(() => {
    if(formik.values.primeiroAcesso){
      formik.setFieldValue("celular", '')
    } 
  }, [formik.values.primeiroAcesso])

  //#region Atividades Técnico

  const [atividadeAccordion, setAtividadeAccordion] = useState(false)

  const [atividadesTecnico, setAtividadesTecnico] = useState<IAtividadesTecnicoDados[]>([]);
  const [filtroAtividadesTecnico, setFiltroAtividadesTecnico] = useState<IAtividadesTecnicoDadosFiltro | undefined>(undefined);

  const initialValuesAtividadesTecnico: IInitialValuesAtividadesTecnico = {
    dataInicialFormik: (filtroAtividadesTecnico && moment(filtroAtividadesTecnico?.dataInicio)) || moment(),
    dataFinalFormik: (filtroAtividadesTecnico && moment(filtroAtividadesTecnico?.dataFim)) || moment(),
  }

  const objTraducaoHeaderAtividadesTecnico = {
    ultimaData: 'Data/hora do atendimento',
    latLong: 'Lat/Long',
    atividade: 'Atividade'
  }

  const handleGetAtividades = async (filtro: IAtividadesTecnicoDadosFiltro) => {
    try {
      const response = await getAtividadesTecnico(filtro) as AxiosResponse;
      setAtividadesTecnico(response.data);
    } catch (e) {
      setAtividadesTecnico([]);
    }
  };

  useEffect(() => {
    let f = {
      idTecnico: Number(tecnicoSelecionado.id),
      dataInicio: moment().toDate(),
      dataFim: moment().toDate(),
    } as IAtividadesTecnicoDadosFiltro;
    handleGetAtividades(f);
  }, []);

  useEffect(() => {
    let f = filtroAtividadesTecnico || {
      idTecnico: Number(tecnicoSelecionado.id),
      dataInicio: moment().toDate(),
      dataFim: moment().toDate(),
    } as IAtividadesTecnicoDadosFiltro;
    handleGetAtividades(f);
  }, [filtroAtividadesTecnico]);

  const [loadingExport, setLoadingExport] = useState(false);

  const handleExport = async () => {
    setLoadingExport(true)
    try {
      const params = {
        idTecnico: tecnicoSelecionado.id,
        dataInicio: filtroAtividadesTecnico?.dataInicio,
        dataFim: filtroAtividadesTecnico?.dataFim,
      } as IAtividadesTecnicoDadosExport;
      const { data } = await postAtividadesTecnicoExportar(params) as AxiosResponse
      const url = window.URL.createObjectURL(new Blob([data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'atividadesTecnico.csv')
      document.body.appendChild(link)
      link.click()
    } catch (e) {
      setOpenAlert(true)
      setTipoMsgAlert('error')
      setMsgAlert('Erro ao exportar')
    }
    setLoadingExport(false)
  };
  //#endregion
  
  return(
    <section className='IncluirEditarTecnico'>
      <Header setMenuLateral={() => setMenuLateral(true)} title={isIncluding ? 'Incluir Técnico' : 'Editar Técnico'} />
      <Breadcrumb>
        <BreadcrumbItem onClick={() => navigate("/ConsultaTecnico")}>Consultar Técnico</BreadcrumbItem>
        <BreadcrumbItem>{isIncluding ? 'Incluir Técnico' : 'Editar Técnico'}</BreadcrumbItem>
      </Breadcrumb>
      <form className="Container" onSubmit={formik.handleSubmit}>
        <div className="IncluirTecnico">
          <div className={'firstRow' + (isIncluding ? ' IncluirTecnicoRow' : '')}>
            <MultipleSelectCheckmarksId
            nodata={"Nenhum status encontrado"}
            options={statusLista}
            value={formik.values.ativo}
            onChange={(e) => formik.setFieldValue('ativo', e)}
            placeholder={"Selecionar status"}
            id="multiple-checkbox-status"
            idOption="multiple-checkbox-option-status"
            labelId="multiple-checkbox-label-status"
            multiple={false}
            label='Status'
            name="status"
            className='IncluirTecnicoSelectStatus'
            dimension='sm'
            error={formik.touched.ativo && Boolean(formik.errors.ativo)}
            helperText={formik.touched.ativo && formik.errors.ativo}
            readOnly={!isIncluding}
            />
            {!isIncluding &&
              <Input
              label='Id Mobyan'
              id='idTecnico'
              value={formik.values.idTecnico}
              onChange={formik.handleChange}
              dimension='sm'
              name="idTecnico"
              error={formik.touched.idTecnico && Boolean(formik.errors.idTecnico)}
              helperText={formik.touched.idTecnico && formik.errors.idTecnico}
              readOnly={!isIncluding}
              />
            }
            <Input
            label='Nome do Técnico'
            id='nomeTecnico'
            dimension='sm'
            name='nomeTecnico'
            error={formik.touched.nomeTecnico && Boolean(formik.errors.nomeTecnico)}
            helperText={formik.touched.nomeTecnico && formik.errors.nomeTecnico}
            value={formik.values.nomeTecnico}
            maxLength={255}
            onChange={formik.handleChange}
            readOnly={!isIncluding}
            />
          </div>
          <div className={'thirdRow' + (!isIncluding ? ' EditarTecnico' : '')}>
            <MaskedInput
            label='CPF'
            id='cpf'
            dimension='sm'
            name='cpf'
            error={formik.touched.cpf && Boolean(formik.errors.cpf)}
            helperText={formik.touched.cpf && formik.errors.cpf}
            value={formik.values.cpf}
            onChange={formik.handleChange}
            mask='999.999.999-99'
            maskChar={null}
            readOnly={!isIncluding}
            />
            <MaskedInput
            label='Número de celular'
            id='celular'
            dimension='sm'
            name='celular'
            error={formik.touched.celular && Boolean(formik.errors.celular)}
            helperText={formik.touched.celular && formik.errors.celular}
            value={formik.values.celular}
            onChange={formik.handleChange}
            mask='(99) 99999-9999'
            maskChar={null}
            readOnly={!isIncluding}
            />

            <AutocompleteMultiple
              label={"Prestador responsável"}
              placeholder={""}
              noOptionsText={"Nenhum Prestador responsável encontrado"}
              id="multiple-checkbox-Prestadores-Edicao-Tecnico"
              options={prestadoresResponsaveisLista}
              value={selectedPrestadores}
              onChange={setSelectedPrestadores}
              disabled
              multiple={false}
              dimension='sm'
            />
            {/* <AutocompleteMultiple
              label={"Prestadores"}
              placeholder={""}
              noOptionsText={"Nenhum Prestador responsável encontrado"}
              id="multiple-checkbox-Prestadores-Edicao-Tecnico"
              options={prestadoresLista}
              value={prestadoresLocal}
              onChange={handleChangePrestador}
              dimension='sm'
            /> */}
            
          </div>

          <div className="ConfiguracaoAccordionContainer">
            <div className='AccordionHeader' onClick={() => setConfiguracaoAccordion(!configuracaoAccordion)}>
              <span>Configuração do técnico</span>
              <ExpandIcon
                id={"IncluirTecnicoConfiguracaoAccordion"}
                className={
                  "CardOS-ExpandIcon " + (!!configuracaoAccordion ? "cardOpen" : "cardClosed")
                }
                onClick={() => {
                  setConfiguracaoAccordion(!configuracaoAccordion);
                }}
              />
            </div>
            <Collapse onClose={setConfiguracaoAccordion} in={configuracaoAccordion} autoClose={false}>
              <TableContainer component={Styles.StyledPaper}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                  <TableBody>
                    {!isIncluding && (
                      <StyledTableRow key={`Primeiro-acesso-row`}>
                        <Styles.StyledTableCell key={`Primeiro-acesso-text`} component="th" scope="row">
                          Primeiro acesso
                        </Styles.StyledTableCell>
                        <Styles.StyledTableCell key={`Primeiro-acesso-switch`} component="th" scope="row">
                          <SwitchMUI
                            disabled={!!tecnicoSelecionado.primeiroAcesso}
                            checked={formik.values.primeiroAcesso}
                            setChecked={() => (formik.setFieldValue('primeiroAcesso', !formik.values.primeiroAcesso))}
                            type={"IOS"}
                          />
                        </Styles.StyledTableCell>
                      </StyledTableRow>
                    )}
                    <StyledTableRow key={`Primeiro-acesso-row`}>
                      <Styles.StyledTableCell key={`Primeiro-acesso-text`} component="th" scope="row">
                        Atendo fora perímetro
                      </Styles.StyledTableCell>
                      <Styles.StyledTableCell key={`Primeiro-acesso-switch`} component="th" scope="row">
                        <SwitchMUI
                          disabled={false}
                          checked={formik.values.atendeForaPerimetro}
                          setChecked={() => (formik.setFieldValue('atendeForaPerimetro', !formik.values.atendeForaPerimetro))}
                          type={"IOS"}
                        />
                      </Styles.StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow key={`Visualizar-indicadores-row`}>
                      <Styles.StyledTableCell key={`Visualizar-indicadores-text`} component="th" scope="row">
                        Visualizar indicadores
                      </Styles.StyledTableCell>
                      <Styles.StyledTableCell key={`Visualizar-indicadores-switch`} component="th" scope="row">
                        <SwitchMUI
                          disabled={false}
                          checked={formik.values.permiteVisualizarIndicadores}
                          setChecked={() => (formik.setFieldValue('permiteVisualizarIndicadores', !formik.values.permiteVisualizarIndicadores))}
                          type={"IOS"}
                        />
                      </Styles.StyledTableCell>
                    </StyledTableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Collapse>
          </div>

          {(!isIncluding && !_.isEmpty(tecnicoSelecionado?.prestadores)) && 
            <div className="PrestadoresAccordionContainer">
              <div className='AccordionHeader' onClick={() => setPrestadoresAccordion(!prestadoresAccordion)}>
                <span>Prestadores cadastrados</span>
                <ExpandIcon
                  id={"IncluirTecnicoPrestadoresAccordion"}
                  className={
                    "CardOS-ExpandIcon " + (!!prestadoresAccordion ? "cardOpen" : "cardClosed")
                  }
                  onClick={() => {
                    setPrestadoresAccordion(!prestadoresAccordion);
                  }}
                />
              </div>
              <Collapse onClose={setPrestadoresAccordion} in={prestadoresAccordion} autoClose={false}>
                <StickyTable
                  id={"TabelaResultadoConsultaFamilia"}
                  data={tableData}
                  objTraducaoHeader={objTraducaoHeader}
                />
              </Collapse>
            </div>
            }

          {!isIncluding &&
            <div className="AtividadesAccordionContainer">
              <div className='AccordionHeader'>
                <span>Atividades</span>
                <ExpandIcon
                  id={"IncluirTecnicoAtividadesAccordion"}
                  className={
                    "CardOS-ExpandIcon " + (!!atividadeAccordion ? "cardOpen" : "cardClosed")
                  }
                  onClick={() => {
                    setAtividadeAccordion(!atividadeAccordion);
                  }}
                />
              </div>
              <Collapse onClose={setAtividadeAccordion} in={atividadeAccordion} autoClose={false}>
                <Formik
                  initialValues={initialValuesAtividadesTecnico}
                  enableReinitialize={true}
                  onSubmit={(values) => {
                    setFiltroAtividadesTecnico({
                      idTecnico: Number(tecnicoSelecionado.id),
                      dataInicio: moment(values.dataInicialFormik, "YYYY-MM-DD HH:mm:ss").toDate(),
                      dataFim: moment(values.dataFinalFormik, "YYYY-MM-DD HH:mm:ss").toDate(),
                    });
                  }}
                >
                  {({ values, setFieldValue, submitForm }) => (
                    <Form>
                      <div className='AtividadesRow'>
                        <DatePicker
                          dimension="sm"
                          id="dataInicialFormik"
                          value={values.dataInicialFormik}
                          onChange={(e: any) => setFieldValue('dataInicialFormik', e)}
                        />
                        <DatePicker
                          dimension="sm"
                          id="dataFinalFormik"
                          value={values.dataFinalFormik}
                          onChange={(e: any) => setFieldValue('dataFinalFormik', e)}
                        />

                        <div
                          id={"BtnFiltro"}
                          className={`BlocoBotoes-RightBtn BtnFiltro`}
                          onClick={submitForm}
                        >
                          {/* <FiltroIcon className={"IconeBtnFarol FiltroIcon"} /> */}
                          Filtro
                        </div>

                        <div
                          id={"BtnExportar"}
                          className={`BlocoBotoes-RightBtn BtnExportar`}
                          onClick={(event) => !loadingExport && handleExport()}
                        >
                          {loadingExport
                            ? <CircularProgress color="inherit" size={14} />
                            : <>
                              <IosShare
                                id={"IosShare"}
                                fontSize="small"
                                sx={{
                                  color: "white",
                                }}
                                className='ExportIcon'
                              />
                              <Typography fontSize="0.875rem"> Exportar</Typography>
                            </>}
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>

                <StickyTable
                  id={"TabelaAtividadesTecnico"}
                  data={arrayRemoveProp(atividadesTecnico, 'idTecnico')}
                  objTraducaoHeader={objTraducaoHeaderAtividadesTecnico}
                />
              </Collapse>
            </div>
          }
          

          {!!isIncluding &&
            <>
              {!!profileHasPermission("Criar novo registro") ?
                <Button
                  type="submit"
                  variant='primary'
                  dimension='sm'
                  width='146px'
                >
                  Cadastrar
                </Button>
                :
                <Button
                  type="button"
                  variant='primary'
                  dimension='sm'
                  width='146px'
                  style={{ cursor: 'not-allowed', opacity: 0.5 }}
                  disabled={true}>
                  Cadastrar
                </Button>
              }
            </>
          }

          {!isIncluding &&
            <>
              {!!profileHasPermission("Editar Registro") ?
                <Button
                  type="submit"
                  variant='primary'
                  dimension='sm'
                  width='146px'
                >
                  Salvar
                </Button>
                :
                <Button
                  type="button"
                  variant='primary'
                  dimension='sm'
                  width='146px'
                  style={{ cursor: 'not-allowed', opacity: 0.5 }}
                  disabled={true}>
                  Salvar
                </Button>
              }
            </>
          }     
        </div>
        
        <Collapse onClose={setOpenAlert} in={openAlert}>
          <Alert
            severity={tipomsgAlert}
            icon={<div />}
            variant="filled"
            sx={{
              mb: 2,
              bgcolor: tipomsgAlert === "success" ? "#178B319E" : "rgb(255, 35, 35, 0.7)",
              minHeight: "67px",
              borderRadius: "15px",
              boxSizing: "border-box",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginTop: "20px",
              marginBottom: 0,
            }}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="medium"
                onClick={() => setOpenAlert(false)}
              >
                <CloseIcon
                  id={"ModalEncaminharOS-AlertCloseIcon"}
                  fontSize="inherit"
                  sx={{
                    color: "white",
                  }}
                />
              </IconButton>
            }
          >
            <span
              style={{
                color: "white",
                fontSize: "16px",
              }}
              dangerouslySetInnerHTML={{__html: msgAlert}}
            />
          </Alert>
        </Collapse>
      </form>
    </section>
  )
}

export default IncluirEditarTecnico