import { useState } from "react";
import { IGetResponseStatus } from "../pages/controleStatus/types";

export const OrdemServicoStatusContext = () => {
    const [ordemServicoStatusSelecionado, setOrdemServicoStatusSelecionado] = useState({} as IGetResponseStatus);

    return {
        ordemServicoStatusSelecionado,
        setOrdemServicoStatusSelecionado
    }
}