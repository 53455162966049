import React from 'react';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import FormControl from "@mui/material/FormControl";
import './datePicker.scss';

export interface IDatePicker {
    id?: string,
    label?: string,
    className?: string,
    dimension?: 'sm' | 'lg',
    value: any,
    minDate?: any,
    maxDate?: any,
    onChange: (e: any) => void,
    disabled?: boolean,
    mask?: string,
    inputFormat?: string,
    placeholder?: string,
    disableMaskedInput?: boolean,
    error?: boolean,
    helperText?: any,
}

export default function DatePicker({
    id = "DatePicker",
    label = "",
    className,
    dimension,
    value,
    minDate = null,
    maxDate = null,
    onChange,
    disabled = false,
    mask = "__/__/____",
    inputFormat = "DD/MM/YYYY",
    placeholder = "DD/MM/AAAA",
    disableMaskedInput = false,
    error,
    helperText,
}: IDatePicker) {

    return (
        <FormControl className="container" sx={{ m: 1, width: "100%" }}>
            {label && <label htmlFor={id} className={`label-input ${dimension}`}>{label}</label>}
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <DesktopDatePicker
                    className={`InputComponentDate ${className} ${dimension} ${!!error ? "error" : ""}`}
                    value={value}
                    minDate={minDate}
                    maxDate={maxDate}
                    onChange={(newValue) => onChange(newValue)}
                    disabled={disabled}
                    //@ts-ignore
                    mask={mask}
                    inputFormat={inputFormat}
                    disableMaskedInput={disableMaskedInput}
                    renderInput={(params: any) =>
                    (<TextField
                        {...params}
                        id={id}
                        placeholder={placeholder}
                        error={error}
                        helperText={helperText}
                    />)
                    }
                />
            </LocalizationProvider>
            <span className="errorMessage">{helperText}</span>
        </FormControl>
    );
}
