import { useContext, useRef, useState } from "react";
import { HomeContext } from "../../../contexts/homeContext";
import { AuthContext } from "../../../contexts/auth";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { useFormik } from "formik";
import moment from "moment";
import Header from "../../../components/header/header";
import {
  Breadcrumb,
  BreadcrumbItem,
} from "../../../components/breadcrumb/breadcrumb";
import { CircularProgress } from "@mui/material";
import Button from "../../../components/Button/styles";
import MultipleSelectCheckmarksId from "../../../components/seletorMultiploId/seletorMultiploId";
import Input from "../../../components/Input/Input";
import { AxiosResponse } from "axios";
import { postRegiao, putRegiao } from "../services";
import "./incluirEditarRegiao.scss";
import AlertMessage from "../../../components/AlertMessage/alertMessage";

interface IInitialValues {
  ativo: string[];
  nomeRegiao: string;
  usuarioCriacao: string | number;
  dataCriacao: string;
  usuarioModificacao: string | number;
  dataModificacao: string;
}

const IncluirEditarRegiao = () => {
  const isIncluding = window.location.pathname.includes("IncluirRegiao");

  const isInitialMount = useRef(true);

  const { funcionalidadeDaTelaTemPermissao, user } = useContext(AuthContext);
  const profileHasPermission = (funcionalidade: string) =>
    funcionalidadeDaTelaTemPermissao(
      isIncluding ? "Incluir Região" : "Editar Região",
      funcionalidade
    );

  const { setMenuLateral, regiaoSelecionada, setRegiaoSelecionada } =
    useContext(HomeContext);

  const navigate = useNavigate();

  const [listaStatus, setListaStatus] = useState<
    { name: string; id: string }[]
  >([
    { name: "Ativo", id: "0" },
    { name: "Inativo", id: "1" },
  ]);

  const [tipomsgAlert, setTipoMsgAlert] = useState<
    "success" | "info" | "warning" | "error"
  >("success");
  const [msgAlertError, setMsgAlert] = useState("Região encaminhada com sucesso!");
  const [openAlertError, setOpenAlertError] = useState(false);
  const [disableActions, setDisableActions] = useState(false);
  const [loading, setLoading] = useState(false);

  const validationSchema = yup.object({
    ativo: yup.array().max(1).min(1, "Campo obrigatório"),
    nomeRegiao: yup.string().required("Campo obrigatório"),
  });

  const initialValuesIncluir: IInitialValues = {
    ativo: ["1"],
    nomeRegiao: "",
    usuarioCriacao: "",
    dataCriacao: "",
    usuarioModificacao: "",
    dataModificacao: "",
  };

  const initialValuesEditar: IInitialValues = {
    ativo: [regiaoSelecionada?.ativo ? "0" : "1"],
    nomeRegiao: regiaoSelecionada?.nome || "",
    usuarioCriacao: regiaoSelecionada?.idUsuarioCriacao,
    dataCriacao: regiaoSelecionada?.dataCriacao
      ? moment(regiaoSelecionada?.dataCriacao, "YYYY/MM/DDTHH:mm:ss").format(
          "DD/MM/YYYY HH:mm:ss"
        )
      : "",
    usuarioModificacao: regiaoSelecionada?.idUsuarioModificacao,
    dataModificacao: regiaoSelecionada?.dataModificacao
      ? moment(
          regiaoSelecionada?.dataModificacao,
          "YYYY/MM/DDTHH:mm:ss"
        ).format("DD/MM/YYYY HH:mm:ss")
      : "",
  };

  const formik = useFormik({
    initialValues: isIncluding ? initialValuesIncluir : initialValuesEditar,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async (values, actions) => {
      if (isIncluding) {
        if (!!profileHasPermission("Novo Registro"))
          return handleIncludeRegiao(values);
      } else {
        if (!!profileHasPermission("Editar Registro"))
          return handleEditRegiao(values);
      }
    },
  });

  const handleIncludeRegiao = async (values: IInitialValues) => {
    setDisableActions(true);
    setOpenAlertError(false);
    setLoading(true);

    try {
      const statusBool: boolean = values.ativo[0] === "0" ? true : false;

      const { data } = (await postRegiao({
        ativo: statusBool,
        nome: values.nomeRegiao,
      })) as AxiosResponse;

      setLoading(false);
      setMsgAlert(
        !!data && typeof data === "string" ? data : "Região criado com sucesso"
      );
      setTipoMsgAlert("success");
      setOpenAlertError(true);
      setDisableActions(false);

      formik.resetForm();
    } catch (e: any) {
      setLoading(false);
      setMsgAlert(
        e?.response?.data?.errors
          ? Object.values(e.response.data.errors).join("<br>")
          : "Erro ao incluir região"
      );
      setTipoMsgAlert("error");
      setOpenAlertError(true);
      setDisableActions(false);
    }
  };

  const handleEditRegiao = async (values: IInitialValues) => {
    setDisableActions(true);
    setOpenAlertError(false);
    setLoading(true);

    try {
      const statusBool: boolean = values.ativo[0] === "0" ? true : false;

      const { data } = (await putRegiao(
        {
          ativo: statusBool,
          nome: values.nomeRegiao,
        },
        regiaoSelecionada.id
      )) as AxiosResponse;

      /**Atualiza dados da região no contexto */
      setRegiaoSelecionada({
        ...regiaoSelecionada,
        ativo: statusBool,
        nome: values.nomeRegiao,
        idUsuarioModificacao: user?.idUsuario,
        dataModificacao: moment().format("YYYY-MM-DD HH:mm:ss"),
      });

      setLoading(false);
      setMsgAlert(
        !!data && typeof data === "string"
          ? data
          : "Região alterada com sucesso!"
      );
      setTipoMsgAlert("success");
      setOpenAlertError(true);
      setDisableActions(false);
    } catch (e: any) {
      setLoading(false);
      setMsgAlert(
        e?.response?.data?.errors
          ? Object.values(e.response.data.errors).join("<br>")
          : "Erro ao editar região"
      );
      setTipoMsgAlert("error");
      setOpenAlertError(true);
      setDisableActions(false);
    }
  };

  return (
    <section className="IncluirEditarRegiao">
      <Header
        setMenuLateral={() => setMenuLateral(true)}
        title={isIncluding ? "Inclusão de região" : "Edição de região"}
      />
      <Breadcrumb>
        <BreadcrumbItem onClick={() => navigate("/ConsultarRegioes")}>
          Consultar Regiões
        </BreadcrumbItem>
        <BreadcrumbItem>
          {isIncluding ? "Inclusão de região" : "Edição de região"}
        </BreadcrumbItem>
      </Breadcrumb>

      <form className="Container" onSubmit={formik.handleSubmit}>
        <div className="IncluirRegiao">
          <div className={"firstRow grid"}>
            <MultipleSelectCheckmarksId
              nodata={"Nenhum status encontrado"}
              options={listaStatus}
              value={formik.values.ativo}
              onChange={(e) => formik.setFieldValue("ativo", e)}
              placeholder={"Selecionar status"}
              id="multiple-checkbox-status"
              idOption="multiple-checkbox-option-status"
              labelId="multiple-checkbox-label-status"
              multiple={false}
              label="Status"
              name="status"
              className="IncluirRegiaoSelectStatus"
              dimension="sm"
              error={formik.touched.ativo && Boolean(formik.errors.ativo)}
              helperText={formik.touched.ativo && formik.errors.ativo}
              disabled={disableActions}
            />

            <Input
              label="Nome da Região"
              id="nomeRegiao"
              dimension="sm"
              name="nomeRegiao"
              error={
                formik.touched.nomeRegiao && Boolean(formik.errors.nomeRegiao)
              }
              helperText={formik.touched.nomeRegiao && formik.errors.nomeRegiao}
              value={formik.values.nomeRegiao}
              maxLength={100}
              onChange={formik.handleChange}
              disabled={disableActions}
            />
          </div>

          

          <div className={"secondRow grid"}>
            <Input
              dimension="sm"
              label="Criado Por"
              name="usuarioCriacao"
              id="usuarioCriacao"
              value={formik.values.usuarioCriacao}
              onChange={formik.handleChange}
              error={
                formik.touched.usuarioCriacao &&
                Boolean(formik.errors.usuarioCriacao)
              }
              helperText={
                formik.touched.usuarioCriacao && formik.errors.usuarioCriacao
              }
              readOnly
            />
            <Input
              dimension="sm"
              label="Criado em"
              id="dataCriacao"
              value={formik.values.dataCriacao}
              onChange={(e) => formik.setFieldValue("dataCriacao", e)}
              error={
                formik.touched.dataCriacao && Boolean(formik.errors.dataCriacao)
              }
              helperText={
                formik.touched.dataCriacao && formik.errors.dataCriacao
                  ? "Campo obrigatório"
                  : ""
              }
              readOnly
            />
            <Input
              dimension="sm"
              label="Editado por"
              name="usuarioModificacao"
              id="usuarioModificacao"
              value={formik.values.usuarioModificacao}
              onChange={formik.handleChange}
              error={
                formik.touched.usuarioModificacao &&
                Boolean(formik.errors.usuarioModificacao)
              }
              helperText={
                formik.touched.usuarioModificacao &&
                formik.errors.usuarioModificacao
              }
              readOnly
            />
            <Input
              dimension="sm"
              label="Editado em"
              id="dataModificacao"
              value={formik.values.dataModificacao}
              onChange={(e) => formik.setFieldValue("dataModificacao", e)}
              error={
                formik.touched.dataModificacao &&
                Boolean(formik.errors.dataModificacao)
              }
              helperText={
                formik.touched.dataModificacao && formik.errors.dataModificacao
                  ? "Campo obrigatório"
                  : ""
              }
              readOnly
            />
          </div>

          {!!isIncluding && (
            <>
              {!!profileHasPermission("Novo Registro") ? (
                <Button
                  type="submit"
                  variant="primary"
                  dimension="sm"
                  width="146px"
                >
                  Salvar
                </Button>
              ) : (
                <Button
                  type="button"
                  variant="primary"
                  dimension="sm"
                  width="146px"
                  style={{ cursor: "not-allowed", opacity: 0.5 }}
                  disabled={true}
                >
                  Salvar
                </Button>
              )}
            </>
          )}

          {!isIncluding && (
            <>
              {!!profileHasPermission("Editar Registro") ? (
                <Button
                  type="submit"
                  variant="primary"
                  dimension="sm"
                  width="146px"
                >
                  Salvar
                </Button>
              ) : (
                <Button
                  type="button"
                  variant="primary"
                  dimension="sm"
                  width="146px"
                  style={{ cursor: "not-allowed", opacity: 0.5 }}
                  disabled={true}
                >
                  Salvar
                </Button>
              )}
            </>
          )}
        </div>

        {!!loading && <CircularProgress style={{ margin: "0px auto" }} />}

        <AlertMessage
          isOpenAlert={openAlertError}
          setOpenAlert={setOpenAlertError}
          alertType={tipomsgAlert}
          msgAlert={msgAlertError}
        />
      </form>
    </section>
  );
};

export default IncluirEditarRegiao;
