import "./validarResetSenhaHeader.scss";
import { ReactComponent as MobyanLogo } from "../../../assets/images/MobyanLogo.svg";

const ValidarResetSenhaHeader = () => {
  return (
    <div className="ValidarResetSenhaHeader">
      <MobyanLogo className={"Logo"} />
      {process.env.REACT_APP_AMBIENTE !== "PROD" && (
        <div className="Ambiente">{process.env.REACT_APP_AMBIENTE}</div>
      )}
    </div>
  );
};

export default ValidarResetSenhaHeader;
