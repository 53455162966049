import { useLocation, useNavigate } from "react-router-dom";
import EditarTermoOs from "./editarTermoOs";
import IncluirTermoOs from "./incluirTermoOs";

const IncluirEditarTermoOs = () => {
    const location = useLocation();
    const dadosTermoOs = location.state?.dadosTermoOs || {};
    if (!!dadosTermoOs.id) return <EditarTermoOs/>;
    return <IncluirTermoOs/>;
};

export default IncluirEditarTermoOs;
