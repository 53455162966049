import React, { useState, useContext } from "react";
import "./cardOSList.scss";
import _ from 'lodash'
import CheckboxMUI from "../checkboxMUI/checkboxMUI";
import { ReactComponent as OSVencidaIscon } from "../../assets/icons/OSVencidaIscon.svg";
import { ReactComponent as OSVenceHojeIscon } from "../../assets/icons/OSVenceHojeIscon.svg";
import { ReactComponent as OSAVencerIscon } from "../../assets/icons/OSAVencerIscon.svg";
import { ReactComponent as ExpandIcon } from "../../assets/icons/ExpandIcon.svg";
import moment from "moment";
import { FormatDateAndHour, FormatDateToApi } from "../../helpers/formatDate";
import {
  CardOS,
  CardOSTopo,
  ColunaTopo,
  ColunaTopoTexto,
  CardOSBody,
  InfoWrapper,
  BodyInfo,
  BodyInfoItem,
  SpanInfo,
  BodyInfoItemEquip,
  BodyInfoItemEquipWrap,
  CardOSPorCliente,
} from "./style";
import { ICardOSList } from './types'
import { IMelhorEquipamento, IOrdemServico } from "../../contexts/types/farolTypes";
import { renderStatusOS } from "../../helpers/statusOS";
import { removeSpecialCharSimple } from "../../helpers/removeSpecialCharSimple";

const CardOSList = ({
  item,
  isosselected,
  setOSSelected,
  isListaOSPendente,
  disabled = false,
  checkbox = true,
  rightIcon,
  grid,
  isGroup
}: ICardOSList) => {
  const {
    chamado,
    referencia,
    previsaoAtendimento,
    dataAbertura,
    cep,
    tipoOS,

    grupoServico,

    status,
    statusFarol,
    contratante,
    codigoCliente,
    cpfCnpj,
    nomeFantasia,
    nomePrestador,

    operadora1,
    operadora2,
    logradouro,
    numero,
    bairro,
    cidade,

    dataGeracaoProtocolo,
    categoriaAgrupada,
    servico,

    tecnico,
    modelo,

    numeroSerie,
    numeroSerieRemover,
    melhorEquipamento,

    idContratante,
    idTerminal,
    aplicacaoEquipamento,
    dataAgendamento,
    linkLaudo,
  } = item;

  const [cardOpen, setCardOpen] = useState(false);

  const renderDataProtocoloTecnico = (date: any) => {
    if (!date) return " ";

    const dataProtocolo = moment(date, "YYYY-MM-DDTHH:mm:ss").format(
      "DD/MM/YYYY"
    );

    if (date === "0001-01-01T00:00:00" || dataProtocolo === "01/01/0001") return " ";

    return dataProtocolo;
  };

  const osPossuiContratanteID = (id: number) => {
    // [
    //   {"id": 1, "descricao": "GETNET"},
    //   {"id": 30, "descricao": "VENDAS"},
    //   {"id": 39, "descricao": "PUNTO"},
    //   {"id": 47, "descricao": "LABORATORIO"},
    //   {"id": 76, "descricao": "SANTANDER"},
    //   {"id": 77, "descricao": "BLU"},
    //   {"id": 78, "descricao": "CAR10"}
    // ];

    return idContratante === id;
  };

  return (
    <CardOS id={chamado?.toString()} isGroup={isGroup}>
      <CardOSTopo grid={grid}>
        {checkbox &&
          <ColunaTopo className="Collumcenter">
            <CheckboxMUI
              disabled={disabled}
              id={"CheckboxMUIID:" + chamado}
              value={isosselected!}
              onChange={() => setOSSelected!(item)}
              checkboxSX={{ '& .MuiSvgIcon-root': { fontSize: 30 } }}
            />
          </ColunaTopo>
        }

        <ColunaTopo>{renderStatusOS(statusFarol)?.icon}</ColunaTopo>

        <ColunaTopo className="Collumcenter" title={chamado.toString()}>
          <ColunaTopoTexto>
            {chamado}
          </ColunaTopoTexto>
        </ColunaTopo>

        <ColunaTopo className="Collumcenter" title={referencia}>
          <ColunaTopoTexto>
            {referencia}
          </ColunaTopoTexto>
        </ColunaTopo>

        <ColunaTopo
          className="Collumcenter AlignCenter"
          title={!!dataAbertura ? moment(dataAbertura, "YYYY-MM-DDTHH:mm:ss").format(
            "DD/MM/YYYY HH:mm"
          ) : ""}
        >
          <ColunaTopoTexto>
            {!!dataAbertura ? moment(dataAbertura, "YYYY-MM-DDTHH:mm:ss").format(
              "DD/MM/YYYY HH:mm"
            ) : ""}
          </ColunaTopoTexto>
        </ColunaTopo>

        <ColunaTopo
          className="Collumcenter AlignCenter CollumBreak-word"
          title={!!previsaoAtendimento ? moment(previsaoAtendimento, "YYYY-MM-DDTHH:mm:ss").format(
            "DD/MM/YYYY HH:mm"
          ) : ""}
        >
          <ColunaTopoTexto>
            {!!previsaoAtendimento ? moment(previsaoAtendimento, "YYYY-MM-DDTHH:mm:ss").format(
              "DD/MM/YYYY HH:mm"
            ) : ""}
          </ColunaTopoTexto>
        </ColunaTopo>

        <ColunaTopo className="Collumcenter AlignCenter CollumBreak-word" title={status}>
          <ColunaTopoTexto>
            {status}
          </ColunaTopoTexto>
        </ColunaTopo>

        <ColunaTopo className="Collumcenter CollumBreak-word AlignCenter" title={servico}>
          <ColunaTopoTexto>
            {servico}
          </ColunaTopoTexto>
        </ColunaTopo>

        <ColunaTopo className="Collumcenter CollumBreak-word AlignCenter">
          {!!melhorEquipamento && !_.isEmpty(melhorEquipamento) && (
            <BodyInfoItemEquipWrap style={{fontSize: melhorEquipamento.length > 1 ? "11px" : "14px"}}>
              {melhorEquipamento.map((equipamento: IMelhorEquipamento) => {
                return (
                  <BodyInfoItemEquip key={equipamento.id}>
                    {equipamento.descricao} - {equipamento.situacao}
                  </BodyInfoItemEquip>
                )
              })}
            </BodyInfoItemEquipWrap>
          )}
        </ColunaTopo>

        <ColunaTopo className="Collumcenter CollumBreak-word AlignCenter" title={cidade}>
          <ColunaTopoTexto>
            {cidade}
          </ColunaTopoTexto>
        </ColunaTopo>

        <ColunaTopo className="Collumcenter CollumBreak-word AlignCenter" title={bairro}>
          <ColunaTopoTexto>
            {bairro}
          </ColunaTopoTexto>
        </ColunaTopo>

        <ColunaTopo className="Collumcenter" title={cep}>
          <ColunaTopoTexto>
            {cep}
          </ColunaTopoTexto>
        </ColunaTopo>

        {isListaOSPendente ? 
        <ColunaTopo className="Collumcenter" title={tecnico}>
          <ColunaTopoTexto>
            {tecnico}
          </ColunaTopoTexto>
        </ColunaTopo> : null}

        <ColunaTopo>
          {rightIcon ? rightIcon :
            <ExpandIcon
              id={"CardOS-ExpandIcon:" + chamado}
              className={
                "CardOS-ExpandIcon " + (!!cardOpen ? "cardOpen" : "cardClosed")
              }
              onClick={() => {
                if (!!disabled) return;
                setCardOpen(!cardOpen);
              }}
            />
          }
        </ColunaTopo>

      </CardOSTopo>

      <CardOSBody className={`${!!cardOpen ? "cardOpen" : "cardClosed"} ${!_.isEmpty(melhorEquipamento) ? 'bestEquipment' : ''}`}>
        <InfoWrapper>
          <span className="infowrapper-title">Dados do Cliente</span>
          <BodyInfo>
            <BodyInfoItem>
              <SpanInfo>Cod. Cliente:</SpanInfo> {codigoCliente}
            </BodyInfoItem>
            <BodyInfoItem>
              <SpanInfo>Nome Cliente</SpanInfo> {nomeFantasia}
            </BodyInfoItem>
            <BodyInfoItem>
              <SpanInfo>Contratante</SpanInfo> {contratante}
            </BodyInfoItem>
            <BodyInfoItem>
              <SpanInfo>Prestador</SpanInfo> {nomePrestador}
            </BodyInfoItem>
            <BodyInfoItem>
              <SpanInfo>CPF/CNPJ: </SpanInfo>{cpfCnpj}
            </BodyInfoItem>
            <BodyInfoItem>
              <SpanInfo>Endereço </SpanInfo>{`${logradouro}, ${numero} ${bairro}, ${cidade} , ${cep}`}
            </BodyInfoItem>
          </BodyInfo>

          <span className="infowrapper-title">Dados da OS</span>
          <BodyInfo className={"midblock"}>
            <BodyInfoItem>
              <SpanInfo>Dt Ger. do Prot. Técnico</SpanInfo>{" "}
              {renderDataProtocoloTecnico(dataGeracaoProtocolo)}
            </BodyInfoItem>
            <BodyInfoItem>
              <SpanInfo>Grupo Serviço</SpanInfo> {grupoServico}
            </BodyInfoItem>
            <BodyInfoItem>
              <SpanInfo>Serviço</SpanInfo> {servico}
            </BodyInfoItem>
            <BodyInfoItem>
              <SpanInfo>Tipo Atendimento</SpanInfo> {tipoOS}
            </BodyInfoItem>
            <BodyInfoItem>
              <SpanInfo>Técnico</SpanInfo> {tecnico || ""}
            </BodyInfoItem>
            <BodyInfoItem>
              <SpanInfo>Modelo</SpanInfo> {modelo}
            </BodyInfoItem>
            {!!osPossuiContratanteID(39) &&
              <>
                <BodyInfoItem title={idTerminal}>
                  <SpanInfo>ID Terminal</SpanInfo> {idTerminal}
                </BodyInfoItem>
                <BodyInfoItem>
                  <SpanInfo>Aplicação Equipamento</SpanInfo> {aplicacaoEquipamento}
                </BodyInfoItem>
              </>
            }
            <BodyInfoItem>
              <SpanInfo>Série Ins</SpanInfo> {numeroSerie}
            </BodyInfoItem>
            <BodyInfoItem>
              <SpanInfo>Série Ret</SpanInfo> {numeroSerieRemover}
            </BodyInfoItem>
            <BodyInfoItem>
              <SpanInfo>Operadora 1</SpanInfo> {operadora1}
            </BodyInfoItem>
            <BodyInfoItem>
              <SpanInfo>Operadora 2</SpanInfo> {operadora2}
            </BodyInfoItem>

            <BodyInfoItem>
              <SpanInfo>Data Agendamento</SpanInfo> {!!dataAgendamento ? moment(dataAgendamento, "YYYY-MM-DDTHH:mm:ss").format("DD/MM/YYYY HH:mm") : ""}
            </BodyInfoItem>
            {!!linkLaudo &&
            <BodyInfoItem>
              <SpanInfo><a id={"linkLaudoOS:" + (chamado)} href={linkLaudo} target="_blank">Link Laudo</a></SpanInfo>
            </BodyInfoItem>
            }
          </BodyInfo>
          {!_.isEmpty(melhorEquipamento) &&
            <>
              <span className="infowrapper-title">Melhor Equipamento</span>
              <BodyInfo className={"bottomblock"}>
                {melhorEquipamento?.map((equipamento: IMelhorEquipamento) => {
                  return (
                    <BodyInfoItem key={equipamento.id}>
                      <SpanInfo>Modelo</SpanInfo> 
                          {equipamento.descricao} 
                          {equipamento?.situacao ? ` - ${equipamento.situacao}` : ''}
                    </BodyInfoItem>
                  )
                })}

              </BodyInfo>
            </>
          }
        </InfoWrapper>
      </CardOSBody>
    </CardOS>
  );
};

export default CardOSList;

interface ICardListaCliente {
  nomeCliente: string,
  codigoCliente: string | number
  setClientSelected: () => void
  listaOSs: IOrdemServico[]
  isSelected: boolean
  isGroup: boolean
}

export const CardListaCliente = ({nomeCliente, setClientSelected, isSelected, codigoCliente, isGroup}: ICardListaCliente) => {
  return(
    <CardOSPorCliente isGroup={isGroup} id={codigoCliente.toString()}>
      <ColunaTopo className="Collumcenter">
        <CheckboxMUI
          id={"CheckboxMUIID:" + codigoCliente}
          value={isSelected}
          onChange={setClientSelected}
          checkboxSX={{ '& .MuiSvgIcon-root': { fontSize: 30 } }}
        />
      </ColunaTopo>
      <BodyInfoItem>
        <SpanInfo>Cliente: </SpanInfo> {nomeCliente}
      </BodyInfoItem>
    </CardOSPorCliente>
  )
}
