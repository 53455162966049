import styled from "styled-components";
import { siteTheme } from "../../styles/themeVariables";

const { color } = siteTheme;

export const CardOS = styled.div`
  width: 100%;
  border-radius: 15px;
  margin-bottom: 6px;

  &:last-child {
    margin-bottom: 0;
  }
`;

interface ICardOSTopo {
  grid?: string
}

export const CardOSTopo = styled.div<ICardOSTopo>`
  display: grid;
  gap: 4px;
  grid-template-columns: 60px 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  height: 83px;
  min-height: 83px;
  box-sizing: border-box;
  padding: 5px 10px;
  border: 1px solid #afb1b1;
  border-radius: 15px;
`;

export const ColunaTopo = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  font-size: 15px;
  color: #797d8c;
  word-break: break-all;
  line-height: 17px;
  box-sizing: border-box;
  justify-content: center;
  &.Collumcenter {
    justify-content: center;
  }
  &.CollumBreak-word {
    word-break: break-word;
  }
  &.AlignCenter{
    display: flex;
    text-align: center
  }
`;

export const ColunaTopoTexto = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const SpanTopo = styled.span`
  margin-right: 5px;
  word-break: keep-all;
  font-weight: 600;
`;
