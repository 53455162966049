import React, { useState, useContext, useEffect, useRef } from "react";
import _ from "lodash";
import { HomeContext } from "../../../contexts/homeContext";
import { AxiosResponse } from "axios";
import { getEnderecoCoord } from "../services";
import { siteTheme } from "../../../styles/themeVariables";
import { renderStatusOS } from "../../../helpers/statusOS";
import { FormatDateToApi } from "../../../helpers/formatDate";
import tt from '@tomtom-international/web-sdk-maps';
import { IOrdemServico } from "../../../contexts/types/farolTypes";
import './farolMapaOS.scss'

const { color } = siteTheme;

export const FarolMapaOS = () => {

  const { listaos, handleSetOSSelected, osselected } = useContext(HomeContext);

  const [address, setAddress] = useState<IOrdemServico[]>([])

  const [osToSelect, setOsToSelect] = useState<number[]>(osselected)

  const requestEnderecos = async (OS: IOrdemServico) => {

    const { data } = await getEnderecoCoord(`${OS.logradouro}, ${OS.numero} ${OS.bairro}, ${OS.cidade} , ${OS.cep}`) as AxiosResponse
    OS.geolocalizacao = [data.results[0].position.lon,data.results[0].position.lat]

    setAddress(arr => [...arr, OS])
  }

  const generateHTML = (os: IOrdemServico) => {
    const farolStatus = renderStatusOS(os?.statusFarol)
    const html = `<div class="popup-content-info-container">
      <div class="popup-content-container-items">
        <span class="popup-chamado">OS</span>
        <span>${os?.chamado}</span>
        <span class="popup-chamado">Farol </span>
        <span class="farol-status"><img src='${farolStatus?.iconSrc}' /><span>${farolStatus?.label}</span></span>
        <span class="popup-chamado">Prestador</span>
        <span>${os?.nomePrestador}</span>
        <span class="popup-chamado">Status</span>
        <span>${os?.status}</span>
        <span class="popup-chamado">CEP</span>
        <span>${os?.cep}</span>
      </div>
      <div class="popup-content-container-items">
        <span class="popup-chamado">Endereço</span>
        <span>${os?.logradouro}, ${os?.numero}</span>
        <span class="popup-chamado">Bairro</span>
        <span>${os?.bairro}</span>
        <span class="popup-chamado">Modelo</span>
        <span>${os?.modelo}</span>
      </div>
    </div>`

    return html
  }

  const selectedOS = (os: IOrdemServico, markers: any, id: string) => {
    setOsToSelect(arr => [...arr, os.chamado])
    var element = document.createElement("div")
    element.id = id
    const marker: any = new tt.Marker({
      color: color.primary,
      element: element
    })
    .setLngLat(os?.geolocalizacao!)
    .addTo(map);

    const popUp = new tt.Popup({
      closeOnMove: true
    })
    .setHTML(generateHTML(os))
    .on('close', () => removeOS(os, marker, 'marker'))

    marker.setPopup(popUp)

    return () => markers.remove()
  }

  const removeOS = (os: IOrdemServico, markers: any, id: string) => {
    setOsToSelect(osToSelect.filter(item => item !== os.chamado))
    var element = document.createElement("div")
    element.id = id
    const marker: any = new tt.Marker({
      color: color.primary,
      element: element
    })
    .setLngLat(os?.geolocalizacao!)
    .addTo(map);

    const popUp = new tt.Popup({
      closeOnMove: true
    })
    .setHTML(generateHTML(os))
    .on('close', () => selectedOS(os, marker, 'selected'))

    marker.setPopup(popUp)

    return () => markers.remove()
  }

  useEffect(() => {
    if(osToSelect){
      handleSetOSSelected(osToSelect)
    }
  }, [osToSelect])

  const handleAddressChange = () => {
    if(!map || address.length > listaos.length || address.length === 0) return;
    address.map((os: IOrdemServico) => {
    if(os?.geolocalizacao){
      var element = document.createElement("div")
      element.id = osToSelect.includes(os.chamado) ? "selected" : "marker"
      const marker: any = new tt.Marker({
        color: color.primary,
        element: element
      })
      .setLngLat(os?.geolocalizacao!)
      .addTo(map);

      const popUp = new tt.Popup({
        closeOnMove: true
      })
      .setHTML(generateHTML(os))
      .on('close', () => osToSelect.includes(os.chamado) 
       ? removeOS(os, marker, 'marker') 
       : selectedOS(os, marker, 'selected'))

      marker.setPopup(popUp)

      return () => marker.remove()
    }
    })

    if(map.getCenter().lng === 0 && map.getCenter().lat === 0 ){
      map.setCenter(address[0].geolocalizacao!)
    }
  }

  useEffect(() => {
    handleAddressChange()
  }, [address, osselected])

  useEffect(() => {
    if( _.isEmpty(listaos)) return;

    let map = tt.map({
      //@ts-ignore
      key: process.env.REACT_APP_MAPA_TOKEN,
      container: mapElement.current,
      zoom: mapZoom
    });

    listaos.map(item => {
      requestEnderecos(item)
    })

    map.resize()
    setMap(map);
    return () => map.remove();

  }, [listaos]);


  const mapElement = useRef<any>();

  const [mapZoom, setMapZoom] = useState(13);
  const [map, setMap] = useState<any>();

  return(
    <div ref={mapElement} className="map-container"></div>
  )
}