import React, { useContext, useState } from 'react'
import MultipleSelectCheckmarksId from '../../../components/seletorMultiploId/seletorMultiploId'
import './incluirEditarPrestador.scss'
import { HomeContext } from '../../../contexts/homeContext'
import { AuthContext } from "../../../contexts/auth";
import { useNavigate } from 'react-router-dom'
import Header from '../../../components/header/header'
import { Breadcrumb, BreadcrumbItem } from '../../../components/breadcrumb/breadcrumb';
import Input from '../../../components/Input/Input'
import GeralTab from './geralTab'
import { Tab, Tabs } from '@mui/material';
import ConfiguracaoTab from './configuracaoTab'
import LaboratorioTab from './laboratorioTab'
import Button from '../../../components/Button/styles'
import _ from 'lodash'

const IncluirEditarPrestador = () => {

  const { setMenuLateral, prestadorSelected } = useContext(HomeContext);
  const { funcionalidadeDaTelaTemPermissao } = useContext(AuthContext);
  const profileHasPermission = (funcionalidade: string) => funcionalidadeDaTelaTemPermissao(
    isIncluding ? "Incluir Prestador" : "Editar Prestador",
    funcionalidade);

  const localStatus = !_.isEmpty(prestadorSelected) 
  ? [Number(prestadorSelected?.ativo)?.toString()]
  : ['1'] 
  const isIncluding = window.location.pathname.includes('IncluiPrestador')
  
  const [ status, setStatus ] = useState<string[]>(localStatus)
  const [ razaoSocial, setRazaoSocial ] = useState(isIncluding ? '' : prestadorSelected?.nomePrestador)
  const [ nomeFantasia, setNomeFantasia ] = useState(isIncluding ? '' : prestadorSelected?.nomePrestador)
  const [ tabValue, setTabValue ] = useState(0)

  const navigate = useNavigate()

  const handlerNovoPrestador = () => {
    alert('novo')

  }

  const handlerAtualizarPrestador = () => {
      alert('atualizar')
  }

  const statusLista = [
    {
      name: '--',
      id: ''
    }, 
    {
      name: 'Ativo',
      id: '1'
    }, 
    {
      name: 'Inativo',
      id: '0'
    }
  ]

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  return(
    <section className='IncluirEditarPrestador'>
      <Header setMenuLateral={() => setMenuLateral(true)} title={isIncluding ? 'Incluir Prestador' : 'Editar Prestador'} />
      <Breadcrumb>
        <BreadcrumbItem onClick={() => navigate("/ConsultaPrestador")}>Prestador</BreadcrumbItem>
        <BreadcrumbItem>{isIncluding ? 'Incluir Prestador' : 'Editar Prestador'}</BreadcrumbItem>
      </Breadcrumb>
      <div className='Container'>
        <div className='Status'>
          <MultipleSelectCheckmarksId
          nodata={"Nenhum status encontrado"}
          options={statusLista} 
          value={status}
          onChange={(e: string[]) => setStatus(e)}
          id="multiple-checkbox-status"
          idOption="multiple-checkbox-option-status-"
          labelId="multiple-checkbox-label-status"
          multiple={false}
          className="ConsultarEquipamentoSelectStatus"
          dimension='sm'
          label="Status"
          selectAll
          />
        </div>
        <div className='InputPrestador'>
          <Input
           value={razaoSocial} 
           onChange={(e) => setRazaoSocial(e.target.value)} 
           label='Razão Social' 
           dimension='sm'
          />
          <Input
           value={nomeFantasia}
           onChange={(e) => setNomeFantasia(e.target.value)}
           label='Nome Fantasia' 
           dimension='sm'
          />
        </div>
        <Tabs variant="fullWidth" className='TabsIncluirEditarPrestador' value={tabValue} onChange={handleChangeTab} aria-label="basic tabs example">
          <Tab key="tab1" label="Geral"  />
          <Tab key="tab2" label="Configurações" />
          <Tab key="tab3" label="Laboratórios" />

          {window.innerWidth > 1000 &&
          [
            <Tab key="tab4" className='FillingButton' disabled/>,
            <Tab key="tab5" className='FillingButton' disabled/>,
            <Tab key="tab6" className='FillingButton' disabled/>
          ]
          }
        </Tabs>
        <div style={{display: tabValue === 0 ? 'block' : 'none'}}>
          <GeralTab isIncluding={isIncluding} />
        </div>
        <div style={{display: tabValue === 1 ? 'block' : 'none'}}>
          <ConfiguracaoTab isIncluding={isIncluding} />
        </div>
        <div style={{display: tabValue === 2 ? 'block' : 'none'}}>
          <LaboratorioTab />
        </div>
        <div className='ButtonContainer'>
          {!!isIncluding &&
            <>
              {!!profileHasPermission("Criar novo registro") ?
                <Button
                  type="submit"
                  variant='primary'
                  dimension='sm'
                  width='146px'
                  onClick={handlerNovoPrestador}
                >
                  Salvar
                </Button>
                :
                <Button
                  type="button"
                  variant='primary'
                  dimension='sm'
                  width='146px'
                  style={{ cursor: 'not-allowed', opacity: 0.5 }}
                  disabled={true}>
                  Salvar
                </Button>
              }
            </>
          }

          {!isIncluding &&
            <>
              {!!profileHasPermission("Salvar Registro") ?
                <Button
                  type="submit"
                  variant='primary'
                  dimension='sm'
                  width='146px'
                  onClick={handlerAtualizarPrestador}
                >
                  Salvar
                </Button>
                :
                <Button
                  type="button"
                  variant='primary'
                  dimension='sm'
                  width='146px'
                  style={{ cursor: 'not-allowed', opacity: 0.5 }}
                  disabled={true}>
                  Salvar
                </Button>
              }
            </>
          }     
        </div>
      </div>
    </section>
  )
}

export default IncluirEditarPrestador