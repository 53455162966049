import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";
import Badge from "@mui/material/Badge";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Box from "@mui/material/Box";

import { AxiosResponse } from "axios";

import DynamicForm, { HttpMethod } from "../DynamicForm/dynamicForm";
import { RJSFSchema, UiSchema } from "@rjsf/utils";

import { apiGet } from "../../services/api";

import "./notificationSidebar.scss";
import FullscreenLoading from "../fullscreenLoading/fullscreenLoading";

export enum Anchor {
  Top = "top",
  Left = "left",
  Bottom = "bottom",
  Right = "right",
}

interface DrawerItemDynamicForm {
  endpoint: string | null;
  method: string | null;
  schema: RJSFSchema;
  uiSchema: UiSchema;
}

interface DrawerItem {
  id: number;
  title: string;
  icon: "info" | "action";
  date: string;
  readed: boolean;
  dynamicForm?: DrawerItemDynamicForm | null;
}

enum NotificationType {
  Info = "info",
  Action = "action",
}

interface DrawerProps {
  anchor?: Anchor;
  activeNotifications?: boolean;
}

export default function NotificationSidebar({
  anchor = Anchor.Left,
  activeNotifications = false,
}: DrawerProps) {
  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
    borderBottom: "1px solid #afb1b1",
  }));

  const [invisibleBadgeNotifications, setInvisibleBadgeNotifications] =
    React.useState(false);
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = React.useState(false);
  const [drawerItems, setDrawerItems] = useState<DrawerItem[]>([]);
  const [envioBemSucedido, setEnvioBemSucedido] = useState<boolean | null>(
    null
  );

  useEffect(() => {
    setInvisibleBadgeNotifications(!activeNotifications);
  }, []);

  const toggleDrawer =
    (open: boolean) =>
    async (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setIsOpen(open);

      if (open) {
        try {
          setLoading(true);
          setDrawerItems([]);
          setEnvioBemSucedido(null);
          let response = (await apiGet(
            "Usuario/notifications"
          )) as AxiosResponse;
          setDrawerItems(response.data);
          setInvisibleBadgeNotifications(!(response.data.length > 0));
          setLoading(false);
        } catch (error) {
          setLoading(false);
          setInvisibleBadgeNotifications(true);
          console.error("Erro ao buscar dados da API", error);
        }
      }
    };

  return (
    <div id="notification-area">
      {loading && <FullscreenLoading />}
      <Box
        sx={{
          color: "action.active",
          display: "flex",
          flexDirection: "column",
          "& > *": {
            marginBottom: 0,
          },
          "& .MuiBadge-root": {
            marginRight: 2,
          },
        }}
      >
        <Badge
          color="secondary"
          overlap="circular"
          badgeContent=" "
          variant="dot"
          invisible={invisibleBadgeNotifications}
        >
          <NotificationsIcon onClick={toggleDrawer(true)} />
        </Badge>
      </Box>
      <Drawer anchor={anchor} open={isOpen} onClose={toggleDrawer(false)}>
        <DrawerHeader>
          <h2>Área de Notificações</h2>
          <IconButton onClick={toggleDrawer(false)}>
            {anchor === Anchor.Left ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <div id="list-notifications">
          <ul style={{ listStyleType: "none", padding: "0" }}>
            {drawerItems.map((item, index) => (
              <li
                className="notification-item"
                id={`notification-item-${item.id}`}
                key={index}
              >
                <div>
                  <div className="icon-notification">
                    {item.icon === NotificationType.Info ? (
                      <InfoIcon />
                    ) : (
                      <PendingActionsIcon />
                    )}
                  </div>
                  <h3>{item.title}</h3>
                </div>
                <div>
                  <div className="content-notification">
                    <div>
                      {envioBemSucedido === null && (
                        <DynamicForm
                          id={item.id.toString()}
                          schema={item.dynamicForm?.schema}
                          uiSchema={item.dynamicForm?.uiSchema}
                          method={
                            HttpMethod[
                              item.dynamicForm?.method?.toUpperCase() as keyof typeof HttpMethod
                            ]
                          }
                          endpoint={item.dynamicForm?.endpoint ?? ""}
                          onResponse={(response) => {
                            if (response.response.status === 200) {
                              setEnvioBemSucedido(true);
                            } else {
                              setEnvioBemSucedido(false);
                            }
                          }}
                        />
                      )}
                    </div>
                    <span className={`notification-item-message-${item.id}`}>
                      {envioBemSucedido !== null &&
                        (envioBemSucedido
                          ? "Envio bem-sucedido!"
                          : "Erro no envio.")}
                    </span>
                    <p className="notification-item-date">
                      <span>Data da notificação: {`${item.date}`}</span>
                    </p>
                  </div>
                </div>
              </li>
            ))}
            {drawerItems.length === 0 && (
              <li className="no-content">
                <p>Nenhuma notificação ativa.</p>
              </li>
            )}
          </ul>
        </div>
      </Drawer>
    </div>
  );
}
