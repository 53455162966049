import { apiPost } from "../../../services/api";
import { IMetaImportacaoExportacaoCSV, IPostMetricas } from "../types";

const baseUrl = process.env.REACT_APP_API_ATENDIMENTO;

export const postDownloadCSV = async (params: IMetaImportacaoExportacaoCSV) =>
    await apiPost(`${baseUrl}/Metrica/importar-exportar`, params);

export const postUploadCSV = async (params: FormData) =>
    await apiPost(`${baseUrl}/Metrica/importar-exportar`, params, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });

export const postMetricas = async (params: IPostMetricas) =>
    await apiPost(`${baseUrl}/Metrica`, params)