import styled from "styled-components";
import { Button } from "@mui/material";

export const Section = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  flex: 1;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 44px 20px;
  padding: 0px 35px;
  flex: 1;
  margin-bottom: 150px;
`;

export const Label1 = styled.div`
  font-size: 32px;
  color: #797D8C;
  font-weight: 700;
`;

export const Label2 = styled.div`
  font-size: 20px;
  color: #797D8C;
  font-weight: 400;
`;

export const ButtonStyled = styled(Button)`
  font-size: 14px !important;
  height: 44px !important;
  border-radius: 15px !important;
  padding: 0 40px 0 40px !important;
`;