import React, { useState, useEffect, useContext } from 'react';
import { Container, CheckboxContainer, ButtonList, ErrorMessage } from './estoqueTecnicoModalStyles';
import './estoqueTecnico.scss';
import Modal from '../../../components/modal';
import Button from '../../../components/Button/styles';
import CheckboxMUI from '../../../components/checkboxMUI/checkboxMUI';
import Input, { TextArea } from '../../../components/Input/Input';
import MultipleSelectCheckmarksId from '../../../components/seletorMultiploId/seletorMultiploId';
import { AuthContext } from '../../../contexts/auth';
import { AxiosResponse } from 'axios';
import _ from 'lodash';
import AutocompleteMultiple from '../../../components/autocompleteMultiple/autocompleteMultiple';
import { postDevolveEquipamento } from '../services/services';
import AlertMessage from '../../../components/AlertMessage/alertMessage';
import { createErrorMsg } from '../../../services/api';
import { IListaDevolucaoResponse } from '../types';
import { getListaTecnicosByLotePrestador } from '../../encaminharOS/services/services';
import { ITecnicos } from '../../../contexts/types/encaminharTypes';
import { smoothScroll } from "../../../helpers/smoothScroll";

interface IEstoqueTecnicoModal {
  isModalOpen: boolean,
  handleCloseModal: () => void
  setLoading: (value: boolean) => void
  listPrestadores: any[],
  prestadoresSelecionados: any[],
  listTecnicos: any[],
  tecnicosSelecionado: any[]
  defeitoList: any[]
}

const EstoqueTecnicoModal = ({
  isModalOpen,
  handleCloseModal,
  setLoading,
  listPrestadores,
  prestadoresSelecionados,
  listTecnicos,
  tecnicosSelecionado,
  defeitoList
}: IEstoqueTecnicoModal) => {
  const { user } = useContext(AuthContext);
  const [counter, setCounter] = useState<number>(0);

  const [isOpen, setIsOpen] = useState(isModalOpen);
  const [error, setError] = useState(false);
  const [hasDuplcates, setHasDuplicates] = useState<boolean>(false);

  const [doa, setDoa] = useState<boolean>(false);
  const [defeito, setDefeito] = useState<any[]>([]);
  const [localizacao, setLocalizacao] = useState<string>('');
  const [serialNumber, setSerialNumber] = useState<string>('');

  const [listaPrestadores, setListaPrestadores] = useState<any[]>([]);
  const [selectedPrestadores, setSelectedPrestadores] = useState<any[]>([]);
  const [listaTecnicos, setListaTecnicos] = useState<any[]>([]);
  const [tecnicoSelecionado, setTecnicoSelecionado] = useState<any[]>([]);

  const [tipomsgAlert, setTipoMsgAlert] = useState<'success' | 'info' | 'warning' | 'error'>("success");
  const [msgAlert, setMsgAlert] = useState("");
  const [openAlert, setOpenAlert] = useState(false);

  const handleChangeProvider = (prestadores: any[]) => {
    setSelectedPrestadores(prestadores);
  };
  
  const handleSubmit = () => {
    if (_.isEmpty(selectedPrestadores)) {
      setError(true);
      return;
    }
    if (_.isEmpty(tecnicoSelecionado)) {
      setError(true);
      return;
    }
    if (_.isEmpty(serialNumber)) {
      setError(true);
      return
    }

    handleDevolverEquipamento();
  }

  const handleDevolverEquipamento = async () => {
    setLoading(true);

    try {
      const equips =
        [{
          serial: serialNumber,
          doa: doa,
          defeito: !!defeito.length ? Number(defeito[0].id) : 0,
          localizacao: localizacao
        }];

      const { data } = await postDevolveEquipamento({
        idUsuario: user?.idUsuario || 0,
        idTecnico: Number(tecnicoSelecionado[0].id),
        idPrestador: Number(selectedPrestadores[0].id),
        equipamentos: equips
      }) as AxiosResponse;

      const listaSuccess = data?.listaDevolucaoResponse?.filter(
        (item: IListaDevolucaoResponse) => !!item?.sucesso) ?? [];

      const listaError = data?.listaDevolucaoResponse?.filter(
        (item: IListaDevolucaoResponse) => !item?.sucesso) ?? [];

      setLoading(false);

      if (!_.isEmpty(listaSuccess)) {
        const msgSuccess = listaSuccess.map(
          (item: IListaDevolucaoResponse) =>
            item.mensagem !== '' ?
              `${item.serial}: ${item.mensagem}` :
              `Serial ${item.serial} devolvido com sucesso`
        )
          .toString()
          .replaceAll(',', '</br>');

        setOpenAlert(true);
        setMsgAlert(msgSuccess);
        setTipoMsgAlert('success');

      } else if (!_.isEmpty(listaError)) {
        const msgError = listaError.map(
          (item: IListaDevolucaoResponse) =>
            item.mensagem !== '' ?
              `${item.serial}: ${item.mensagem}` :
              `Situação do Serial ${item.serial} inválida para o devolução.`
        ).toString()
          .replaceAll(',', '</br>');

        setOpenAlert(true);
        setTipoMsgAlert('error');
        setMsgAlert(msgError);
      }

    } catch (error: any) {
      setLoading(false);
      const msg = createErrorMsg(error);
      setMsgAlert(msg ? msg : 'Erro ao devolver equipamentos');
      setOpenAlert(true);
      setTipoMsgAlert('error');
    };
  };

  const handleChangeTechnician = (e: any[]) => {
    setTecnicoSelecionado(e);
    setError(false);
  }

  const handleClose = () => {
    setError(false)
    handleCloseModal()
  }

  const handleUpdateSerialNumber = (e: any) => {
    setSerialNumber(e.target.value);
  }

  const handleSetDoa = () => {
    setDefeito([]);
    setDoa(!doa);
  };

  const requestListaTecnicosByLotePrestador = async () => {
    let listAllPrestadores: number[] = [];

    if (!!selectedPrestadores.length) {
      listAllPrestadores = selectedPrestadores.map(p => parseInt(p.id));
    }

    const tecnicos = await requestTecnicosByLotePrestador(listAllPrestadores) as ITecnicos[];
    setListaTecnicos(tecnicos.map(p => ({ name: p?.nome ?? "", id: p?.idTecnico?.toString() ?? "" })));
  };

  const requestTecnicosByLotePrestador = async (ids: number[]) => {
    try {
      if (!!ids.length) {
        let retorno = await getListaTecnicosByLotePrestador(ids) as AxiosResponse;
        return retorno?.data ?? [];
      } else {
        return [];
      }
    } catch (e) {
      return [];
    }
  };

  useEffect(() => {
    if (counter === 0) {
      setListaPrestadores(listPrestadores.filter(item => item.id !== "all"));
      setListaTecnicos(listTecnicos);
      setSelectedPrestadores(prestadoresSelecionados.length === 1 ? [prestadoresSelecionados[0]] : []);
      setTecnicoSelecionado(tecnicosSelecionado.length === 1 ? [tecnicosSelecionado[0]] : []);
      setCounter(counter + 1);
    } else if (counter > 1) {
      requestListaTecnicosByLotePrestador();
      setTecnicoSelecionado([]);
    } else {
      setCounter(counter + 1);
    }
  }, [selectedPrestadores]);
  
  useEffect(() => {
    setIsOpen(isModalOpen);
  }, [isModalOpen]);

  useEffect(() => {
    if (!!openAlert) smoothScroll(document.getElementById('divToScroll'));
  }, [openAlert]);

  return (
    <Modal
      handleCloseModal={handleCloseModal}
      title='Devolver equipamento'
      isOpen={isOpen}
      dialogModalClasses={{ paper: "TechnicianStockModal-Paper" }}
    >
      <Container>
        <CheckboxContainer>
          <CheckboxMUI
            label='DOA'
            value={doa}
            onChange={handleSetDoa}
            checkboxSX={{ '& .MuiSvgIcon-root': { fontSize: 26 } }}
            formControlLabelSX={{
              marginLeft: '0',
              marginRight: '0',
              '& .MuiCheckbox-root': { padding: '0' },
              '& .MuiTypography-root': { color: '#797d8c', fontSize: '12px', marginLeft: '5px' }
            }} />
        </CheckboxContainer>

        <AutocompleteMultiple
          label={"Defeito"}
          placeholder={""}
          noOptionsText={"Nenhum defeito encontrado"}
          id="multiple-checkbox-defeito-Estoque-modalDevolucao"
          options={defeitoList}
          value={defeito}
          onChange={(defeito: any) => setDefeito(defeito)}
          disabled={!doa}
          multiple={false}
          dimension='sm'
          limitTags={2}
          showCheckbox
        />

        <div>
          <AutocompleteMultiple
            label={"Prestador"}
            placeholder={""}
            noOptionsText={"Nenhum prestador encontrado"}
            id="multiple-checkbox-Prestadores-Estoque-modalDevolucao"
            options={listaPrestadores}
            value={selectedPrestadores}
            onChange={handleChangeProvider}
            disabled={(!!user?.prestadores && user.prestadores.length === 1)}
            multiple={false}
            dimension='sm'
            limitTags={2}
            showCheckbox
          />

          {(error && _.isEmpty(selectedPrestadores)) && <ErrorMessage>Campo obrigatório</ErrorMessage>}
        </div>
        
        <div>
          <AutocompleteMultiple
            label={"Técnico"}
            placeholder={""}
            noOptionsText={!!selectedPrestadores.length ? "Nenhum técnico encontrado" :"Selecione um prestador"}
            id="multiple-checkbox-tecnico-Estoque-modalDevolucao"
            options={listaTecnicos}
            value={tecnicoSelecionado}
            onChange={handleChangeTechnician}
            disabled={false}
            multiple={false}
            dimension='sm'
            limitTags={2}
            showCheckbox
          />

          {(error && _.isEmpty(tecnicoSelecionado)) && <ErrorMessage>Campo obrigatório</ErrorMessage>}
        </div>

        <div>
          <TextArea
            label='Número de série'
            value={serialNumber}
            onChange={handleUpdateSerialNumber}
            dimension='sm'
          />
          {(error && _.isEmpty(serialNumber)) && <ErrorMessage>Campo obrigatório</ErrorMessage>}
          {(hasDuplcates && !_.isEmpty(serialNumber)) && <ErrorMessage>Existem números de série repetidos</ErrorMessage>}
        </div>

        <Input
          maxLength={100}
          dimension='sm'
          label='Localização'
          value={localizacao}
          onChange={(e: any) => setLocalizacao(e.target.value)}
        />

        <ButtonList>
          <Button dimension='sm' variant='primary' onClick={handleSubmit}>Salvar</Button>
          <Button dimension='sm' variant='secondary' onClick={handleClose}>Cancelar</Button>
        </ButtonList>

        <AlertMessage
          isOpenAlert={openAlert}
          setOpenAlert={setOpenAlert}
          alertType={tipomsgAlert}
          msgAlert={msgAlert}
        />

        <div id={"divToScroll"}/>
      </Container>
    </Modal>
  );
};

export default EstoqueTecnicoModal;
