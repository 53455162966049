import React from "react";
import AppRoutes from "./AppRoutes";
import moment from "moment";
import 'moment/min/locales.min';
import 'mapbox-gl/dist/mapbox-gl.css';
import '@tomtom-international/web-sdk-maps/dist/maps.css'
import theme from './styles/theme';
import GlobalStyles from './styles/global';
import { ThemeProvider } from "@mui/material/styles";

const momentLang = {
  english: "en",
  portuguesBrasil: "pt-br",
  espanhol: "es",
};

/*Seta a lingua da biblioteca moment.js globalmente*/
moment.locale(momentLang.portuguesBrasil);

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <AppRoutes />
    </ThemeProvider>
  );
};

export default App;
