import styled from "styled-components";
import { siteTheme } from "../../../styles/themeVariables";

const { color } = siteTheme;

export const titleStyle = {
  bgcolor: "#FFF",
  color: "#797D8C",
  height: "40px"
};

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #ffffff;
  padding: 0px 40px 30px 40px;
  box-sizing: border-box;
  & .MuiAlert-action {
    margin: unset;
  }
`;

export const CardOSBody = styled.div`
  width: 100%;
  margin: 8px 0 0 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  
  background-color: #EEEDED;
  padding: 12px;
  border: 1px;
  border-radius: 8px;
  gap: 8px;
  opacity: 0px;
`;

export const InfoWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  & > .infowrapper-title {
    margin-top: 16px;
    font-weight: bold;
    color: ${color.textCinza};

    &:first-of-type {
      margin-top: 0;
    }
  }
`;

export const BlockTitle = styled.span`
  font-weight: 600;
  font-size: 19px;
  margin-top: 10px;
`;

export const BodyInfo = styled.div`
  width: 100%;
  max-width: 100%;
  gap: 16px 10px;
  padding: 16px 0px;

  &:last-child {
    border-bottom: none;
  }
  &.bottomblock {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const ImageGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 8px;

  padding: 8px;
  background-color: #DEDEDE;
  border-radius: 8px;
`;

export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
  background-color: #DEDEDE;
  box-sizing: border-box;
`;

export const Img = styled.img`
  max-width: 100%;
  max-height: 100%;
  height: 150px;

  border-radius: 4px;
`;


export const BodyInfoItem = styled.div`
  max-width: 100%;
  font-size: 14px;
  color: #797d8c;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const SpanInfo = styled.span`
  font-weight: 600;

  .sm{
    font-size: 12px;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 100%;
`;

export const PerimeterDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  color: #797d8c;
`;

export const buttonStyle = {
  bgcolor: color.primary,
  color: "#FFF",
  "&:hover": {
    backgroundColor: color.primary,
    color: "#FFF",
  },
  minWidth: "146px",
  height: "50px",
  fontSize: "14px",
  fontFamily: "Open Sans",
  padding: "6px 8px",
  borderRadius: "15px",
  "&.Mui-disabled": {
    color: "#FFF !important",
    opacity: 0.5
  },
};
