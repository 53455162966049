import { useState } from "react"
import { IConsultaPrestadorTabela } from "../pages/prestador/types"
import { tipoPrestadorLista } from "./helpers/dadosPadrao"

export const PrestadorContext = () => {

  //ESTADOS USADOS NA ABA GERAL
  
  const [prestadorSelected, setPrestadorSelected] = useState<IConsultaPrestadorTabela>({} as IConsultaPrestadorTabela)
  const [ pessoa, setPessoa ] = useState('')
  const [ documento, setDocumento ] = useState('')
  const [ inscricaoMunicipal, setInscricaoMunicipal ] = useState('')
  const [ tipoPrestador, setTipoPrestador ] = useState<string[]>([])
  const [ prestadorRetorno, setPrestadorRetorno ] = useState<string[]>([])
  const [ prestadorPai, setPrestadorPai ] = useState<string[]>([])
  const [ contato, setContato ] = useState('')
  const [ email, setEmail ] = useState('')
  const [ telefone, setTelefone ] = useState('')
  const [ tipoEndereco, setTipoEndereco ] = useState<string[]>([])
  const [ endereco, setEndereco ] = useState('')
  const [ numero, setNumero ] = useState('')
  const [ complemento, setComplemento ] = useState('')
  const [ bairro, setBairro ] = useState('')
  const [ CEP, setCEP ] = useState('')
  const [ pais, setPais ] = useState('')
  const [ estadoLista, setEstadoLista ] = useState<{name: string, id: string, sigla: string}[]>([])
  const [ estado, setEstado ] = useState<string[]>([])
  const [ cidadeLista, setCidadeLista ] = useState<{name: string, id: string}[]>([])
  const [ cidade, setCidade ] = useState<string[]>([])
  const [ nomeCidade, setNomeCidade ] = useState('')
  const [ transportadora, setTransportadora ] = useState('')
  const [ tipoLaboratorio, setTipoLaboratorio ] = useState<string[]>([])
  const [ diasEntrega, setDiasEntrega ] = useState('')

  const [ emailError, setEmailError ] = useState(false)

  //ESTADOS USADOS NA ABA CONFIGURAÇÕES

  const [ codSAP, setCodSAP ] = useState('')
  const [ codFornecedorSAP, setCodFornecedorSAP ] = useState('')
  const [ codSAPCliente, setCodSAPCliente ] = useState('')
  const [ idFilial, setIdFilial ] = useState('')
  const [ deposito, setDeposito ] = useState('')
  const [ codFornecedor, setCodFornecedor ] = useState('')

  const [ integracaoGetnetSAP, setIntegracaoGetnetSAP ] = useState(false)
  const [ naoRecebimento, setNaoRecebimento ] = useState(false)
  const [ bloqTransfGOOD, setBloqTransfGOOD ] = useState(false)
  const [ integracaoLogStone, setIntegracaoLogStone ] = useState(false)
  const [ criaTriagem, setCriaTriagem ] = useState(false)
  const [ bloqReversa, setBloqReversa ] = useState(false)
  const [ integracaoLogPagSeguro, setIntegracaoLogPagSeguro ] = useState(false)
  const [ baseContratante, setBaseContratante ] = useState(false)
  const [ criaTriagemAut, setCriaTriagemAut ] = useState(false)
  const [ transportePadrao, setTransportePadrao ] = useState(false)
  const [ conferenciaCega, setConferenciaCega ] = useState(false)
  const [ FIFO, setFIFO ] = useState(false)
  const [ QRCodeAvanco, setQRCodeAvanco ] = useState(false)
  const [ integracaoGetnet, setIntegracaoGetnet ] = useState(false) 
  const [ validPatrimonio, setValidPatrimonio ] = useState(false) 
  const [ QRCodeRecebimento, setQRCodeRecebimento ] = useState(false)
  const [ triagemTravaDestino, setTriagemTravaDestino ] = useState(false)
  const [ OMPopUp, setOMPopUp ] = useState(false)
  const [ QRCodeKit, setQRCodeKit ] = useState(false)
  const [ desligaIntStone, setDesligaIntStone ] = useState(false)


  return{
    prestadorSelected, 
    setPrestadorSelected,
    pessoa,
    setPessoa,
    documento, 
    setDocumento,
    inscricaoMunicipal,
    setInscricaoMunicipal,
    tipoPrestador, 
    setTipoPrestador,
    prestadorRetorno, 
    setPrestadorRetorno,
    prestadorPai, 
    setPrestadorPai,
    contato, 
    setContato,
    email,
    setEmail,
    telefone,
    setTelefone,
    tipoEndereco,
    setTipoEndereco,
    endereco,
    setEndereco,
    numero,
    setNumero,
    complemento,
    setComplemento,
    bairro,
    setBairro,
    CEP,
    setCEP,
    pais,
    setPais,
    estadoLista,
    setEstadoLista,
    estado,
    setEstado,
    cidadeLista,
    setCidadeLista,
    cidade,
    setCidade,
    nomeCidade,
    setNomeCidade,
    transportadora,
    setTransportadora,
    tipoLaboratorio,
    setTipoLaboratorio,
    diasEntrega,
    setDiasEntrega,
    emailError, 
    setEmailError,

    codSAP,
    setCodSAP,
    codFornecedorSAP,
    setCodFornecedorSAP,
    codSAPCliente,
    setCodSAPCliente,
    idFilial,
    setIdFilial,
    deposito,
    setDeposito,
    codFornecedor,
    setCodFornecedor,

    integracaoGetnetSAP,
    setIntegracaoGetnetSAP,
    naoRecebimento,
    setNaoRecebimento,
    bloqTransfGOOD,
    setBloqTransfGOOD,
    integracaoLogStone,
    setIntegracaoLogStone,
    criaTriagem,
    setCriaTriagem,
    bloqReversa,
    setBloqReversa,
    integracaoLogPagSeguro,
    setIntegracaoLogPagSeguro,
    baseContratante,
    setBaseContratante,
    criaTriagemAut,
    setCriaTriagemAut,
    transportePadrao,
    setTransportePadrao,
    conferenciaCega,
    setConferenciaCega,
    FIFO,
    setFIFO,
    QRCodeAvanco,
    setQRCodeAvanco,
    integracaoGetnet,
    setIntegracaoGetnet, 
    validPatrimonio,
    setValidPatrimonio, 
    QRCodeRecebimento,
    setQRCodeRecebimento,
    triagemTravaDestino,
    setTriagemTravaDestino,
    OMPopUp,
    setOMPopUp,
    QRCodeKit,
    setQRCodeKit,
    desligaIntStone,
    setDesligaIntStone,
  }
}