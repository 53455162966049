import { apiGet, apiPost } from "../../../services/api";

const baseUrl = process.env.REACT_APP_API_ORDEMSERVICO;
const baseUrlAtendimento = process.env.REACT_APP_API_ATENDIMENTO;

export const getListaOSPendente = async (params = {}) => 
    await apiGet(`${baseUrl}/OrdemServico_/ordens-encaminhadas`, params);

export const postReencaminharOS = async (params = {}) => 
    await apiPost(`${baseUrlAtendimento}/Encaminhamento/reencaminharOS`, params);

export const getLogsEncaminhamento = async (idOS: number) => 
    await apiGet(`${baseUrlAtendimento}/Encaminhamento/BuscaLogsEncaminhamento/${idOS}`, {});