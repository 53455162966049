import { apiGet, apiPost, apiPut } from "../../../services/api";

const baseUrlEstoque = process.env.REACT_APP_API_ESTOQUE;

export const getFamilias = async (descricao: string, status?: boolean) => 
  await apiPost(`${baseUrlEstoque}/Equipamento/equipamento-familia/descricao`, {descricao, status});

export const incluirFamilia = async (params: {}) => 
  await apiPost(`${baseUrlEstoque}/Equipamento/criar-familia`, params);

export const editarFamilia = async (params: any) => 
  await apiPut(`${baseUrlEstoque}/Equipamento/atualizar-familia/${params.idFamilia}`, params);

export const exportFamilias = async () => 
  await apiPost(`${baseUrlEstoque}/Equipamento/familia/export`);
