import * as React from "react";
import './seletorMultiplo.scss'
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { IMultipleSelectCheckmarks } from './types'

const ITEM_HEIGHT = 45;
const ITEM_PADDING_TOP = 5;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 5.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

/*Este seletor recebe opcoes como array strings*/

const MultipleSelectCheckmarks = ({
  id = "multiple-checkbox",
  idOption = "multiple-checkbox-option-",
  label,
  labelId = "multiple-checkbox-label",
  classes = {
    select: "FarolOS-PopoverFiltro-Seletor",
    primary: "Seletor-Option",
  },
  multiple = true,
  value,
  placeholder,
  onChange,
  options = [],
  disabled,
  onClose,
  readOnly,
  dimension,
  selectAll
}: IMultipleSelectCheckmarks) => {

  const [ allSelected, setAllSelected ] = React.useState(false)
  
  const handleChange = (event: SelectChangeEvent) => {
    const value = handleSelectAll((event.target as HTMLInputElement).value)

    if (!!onChange) {

      onChange(value);
    }
  };

  const handleSelectAll = (value: string[] | string) => {
    value = typeof value === "string" ? value.split(",") : value
    
    const all = value.some(item => item === 'all')

    if(all && value.length < options.length){
      return options.filter(item => item !== 'all')
    } else if(value.filter(item => item !== 'all').length === options.length - 1) {
      return []
    }
    return value
  }

  return (
    <FormControl sx={{ m: 1, width: "100%", margin: 0}}>
      {label && <span className={`label ${dimension}`}>{label}</span>}
      <Select
        id={id}
        labelId={labelId}
        classes={classes}
        className={`select ${dimension}`}
        multiple={multiple}
        displayEmpty
        onClose={onClose}
        //@ts-ignore
        value={value}
        disabled={disabled || readOnly}
        renderValue={(selected) => {
          if (!!placeholder && selected.length === 0) {
            return <span className="Seletor-Placeholder">{placeholder}</span>;
          }
          //@ts-ignore
          return selected.join(", ");
        }}
        onChange={handleChange}
        MenuProps={MenuProps}
      >
        {!options.length && (
          <MenuItem key={"nodata"} id={idOption + "nodata"} value={"nodata"} disabled>
            <span className={classes.primary}>Sem dados</span>
          </MenuItem>
        )}

        {(selectAll && multiple) && (
          <MenuItem key='all' value='all' style={{backgroundColor: 'transparent!important'}}>
            {!!multiple && <Checkbox checked={allSelected} onChange={() => setAllSelected(!allSelected)} />}
            <span className={classes.primary}>Todos</span>
          </MenuItem>
        )}

        {options.map((item, index) => (
          <MenuItem key={`${item}-${index}`} id={idOption + (index + 1)} value={item}>
            {!!multiple && <Checkbox checked={value.indexOf(item) > -1} />}
            <span className={classes.primary}>{item}</span>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default MultipleSelectCheckmarks;
