import React, { useContext, useEffect, useRef, useState } from "react";
import { AuthContext } from "../../../contexts/auth";
import { useNavigate } from "react-router-dom";
import { HomeContext } from "../../../contexts/homeContext";
import moment from "moment";
import _ from 'lodash';
import { useFormik } from "formik";
import * as yup from "yup";
import { removeSpecialCharSimple } from "../../../helpers/removeSpecialCharSimple";
import { postCidade, putCidade } from "../services";
import { AxiosResponse } from "axios";
import { Estado_Cidade, Regiao_Cidade } from "../../../contexts/types/cidadeTypes";
import Header from "../../../components/header/header";
import { Breadcrumb, BreadcrumbItem } from "../../../components/breadcrumb/breadcrumb";
import Input from "../../../components/Input/Input";
import Button from "../../../components/Button/styles";
import { CircularProgress } from "@mui/material";
import AlertMessage from "../../../components/AlertMessage/alertMessage";
import "./incluirEditarCidade.scss";
import { getListaCidadesComFiltro, getListaEstados, getListaRegioes } from "../services";
import AutocompleteMultiple from "../../../components/autocompleteMultiple/autocompleteMultiple";
import { IAutocompleteValue } from "../../../components/autocompleteMultiple/types";
import { IGetResponseCidade, IGetResponseCidades } from "../types";

interface IInitialValues {
    ativo: IAutocompleteValue,
    idCidade: string,
    pais: IAutocompleteValue,
    estado: IAutocompleteValue,
    regiao: IAutocompleteValue,
    nome: string,
    usuarioCriacao: string | number,
    dataCriacao: string,
    usuarioModificacao: string | number,
    dataModificacao: string,
};

const IncluirEditarCidade = () => {
    const isIncluding = window.location.pathname.includes('IncluirCidade');
    const isInitialMount = useRef(true);

    const { funcionalidadeDaTelaTemPermissao, user } = useContext(AuthContext);

    const profileHasPermission = (funcionalidade: string) => funcionalidadeDaTelaTemPermissao(
        isIncluding ? "Incluir Cidade" : "Editar Cidade",
        funcionalidade);

    const { setMenuLateral, cidadeSelecionada, setCidadeSelecionada } = useContext(HomeContext);

    const navigate = useNavigate();

    const [listaStatus, setListaStatus] = useState<IAutocompleteValue>([
        { name: 'Ativo', id: '0' },
        { name: 'Inativo', id: '1' },
    ]);
    const [listaPaises, setListaPaises] = useState<IAutocompleteValue>([
        { name: 'Brasil', id: '' }
    ]);
    const [listaEstados, setListaEstados] = useState<IAutocompleteValue>([]);
    const [listaRegioes, setListaRegioes] = useState<IAutocompleteValue>([]);
    const [listaCidades, setListaCidades] = useState<IAutocompleteValue>([]);
    const [tipomsgAlert, setTipoMsgAlert] = useState<'success' | 'info' | 'warning' | 'error'>("success");
    const [msgAlert, setMsgAlert] = useState("OS encaminhada com  sucesso!");
    const [openAlert, setOpenAlert] = useState(false);
    const [disableActions, setDisableActions] = useState(false);
    const [loading, setLoading] = useState<boolean>(false);

    const getItemByID = (ID: string, itemList: IAutocompleteValue) => {
        let resultadoDados: IAutocompleteValue = [...itemList];
        return resultadoDados.find((item) => item.id === ID)!;
    };

    const validaNomeCidade = (newName: string | undefined, ctx: any) => {
        const cleanNewName = removeSpecialCharSimple(newName ?? "");

        /**No modo edição pode manter o nome inicial */
        if (!isIncluding) {
            const { parent }: { parent: IInitialValues } = ctx;
            const initialName = removeSpecialCharSimple(cidadeSelecionada?.nome ?? '');
            const sameEstate = parent?.estado[0]?.id === String(cidadeSelecionada?.estado?.id);
            const sameCityName = initialName === cleanNewName;

            if (sameEstate && sameCityName) return true;
        }

        return !listaCidades.find((s) => removeSpecialCharSimple(s?.name) === cleanNewName);
    }

    const validationSchema = yup.object({
        ativo: yup.array().max(1).min(1, 'Campo obrigatório'),
        estado: yup.array().max(1).min(1, 'Campo obrigatório'),
        regiao: yup.array().max(1).min(1, 'Campo obrigatório'),
        nome: yup.string().required("Campo obrigatório").test(
            'nomeDeCidadeJaExistente',
            'Este nome de cidade já foi cadastrado anteriormente para esse Estado.',
            validaNomeCidade
        ),
    });

    const initialValuesIncluir: IInitialValues = {
        ativo: [listaStatus[1]],
        idCidade: '',
        pais: [listaPaises[0]],
        estado: [],
        regiao: [],
        nome: '',
        usuarioCriacao: '',
        dataCriacao: '',
        usuarioModificacao: '',
        dataModificacao: '',
    };

    const initialValuesEditar: IInitialValues = {
        ativo: [(cidadeSelecionada?.ativo ? listaStatus[0] : listaStatus[1])],
        idCidade: cidadeSelecionada.id?.toString() ?? '',
        pais: [listaPaises[0]],
        estado: cidadeSelecionada?.estado?.id && !!getItemByID(String(cidadeSelecionada?.estado?.id), listaEstados) ? [getItemByID(String(cidadeSelecionada?.estado?.id), listaEstados)] : [],
        regiao: cidadeSelecionada?.regiao?.id && !!getItemByID(String(cidadeSelecionada?.regiao?.id), listaRegioes) ? [getItemByID(String(cidadeSelecionada?.regiao?.id), listaRegioes)] : [],
        nome: cidadeSelecionada?.nome || '',
        usuarioCriacao: cidadeSelecionada?.idUsuarioCriacao,
        dataCriacao: cidadeSelecionada?.dataCriacao ? moment(cidadeSelecionada?.dataCriacao, "YYYY/MM/DDTHH:mm:ss").format("DD/MM/YYYY HH:mm:ss") : '',
        usuarioModificacao: cidadeSelecionada?.idUsuarioModificacao,
        dataModificacao: cidadeSelecionada?.dataModificacao ? moment(cidadeSelecionada?.dataModificacao, "YYYY/MM/DDTHH:mm:ss").format("DD/MM/YYYY HH:mm:ss") : '',
    };

    const formik = useFormik({
        initialValues: isIncluding ? initialValuesIncluir : initialValuesEditar,
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: async (values, actions) => {
            if (isIncluding) {
                if (!!profileHasPermission("Novo Registro")) return handleIncludeCidade(values);
            } else {
                if (!!profileHasPermission("Alterar registro")) return handleEditCidade(values);
            }
        }
    });

    const handleIncludeCidade = async (values: IInitialValues) => {
        setDisableActions(true);
        setOpenAlert(false);
        setLoading(true);

        try {
            const statusBool: boolean = (!!values.ativo[0] && values.ativo[0].id === '0') ? true : false;

            const { data } = await postCidade({
                nome: values.nome,
                ativo: statusBool,
                idEstado: Number(values.estado[0]?.id),
                idRegiao: Number(values.regiao[0]?.id),
            }) as AxiosResponse;

            setLoading(false);
            setMsgAlert(!!data && typeof data === "string" ? data : 'Cidade cadastrada com sucesso');

            setTipoMsgAlert("success");
            setOpenAlert(true);
            setDisableActions(false);

            formik.resetForm();
        } catch (e: any) {
            setLoading(false);

            setMsgAlert(e?.response?.data?.errors ? Object.values(e.response.data.errors).join("<br>") : "Erro ao cadastrar cidade");

            setTipoMsgAlert("error");
            setOpenAlert(true);
            setDisableActions(false);
        }
    }

    const handleEditCidade = async (values: IInitialValues) => {
        setDisableActions(true);
        setOpenAlert(false);
        setLoading(true);

        try {
            const statusBool: boolean = (!!values.ativo[0] && values.ativo[0].id === '0') ? true : false;

            const { data } = await putCidade({
                cidade: values.nome,
                ativo: statusBool,
                idRegiao: Number(values.regiao[0]?.id),
                idEstado: Number(values.estado[0]?.id),
            }, cidadeSelecionada.id) as AxiosResponse;

            const updatedCity: IGetResponseCidade = {
                ...cidadeSelecionada,
                ...data, /**<--- O endpoint está retornando os dados atualizados da cidade */
                idUsuarioModificacao: user?.idUsuario,
                dataModificacao: moment().format("YYYY-MM-DD HH:mm:ss"),
            };

            setCidadeSelecionada(updatedCity);

            setLoading(false);
            if (!!data && typeof data === "string") {
                setMsgAlert(data);
            } else {
                setMsgAlert("Cidade alterada com sucesso!");
            }
            setTipoMsgAlert("success");
            setOpenAlert(true);
            setDisableActions(false);
        } catch (e: any) {
            setLoading(false);
            setMsgAlert(e?.response?.data?.errors ? Object.values(e.response.data.errors).join("<br>") : "Erro ao editar cidade");

            setTipoMsgAlert("error");
            setOpenAlert(true);
            setDisableActions(false);
        }
    }

    const getEstados = async () => {
        try {
            const estados = await getListaEstados({}) as AxiosResponse;
            const listaUF = (estados?.data?.estados ?? []).map((item: Estado_Cidade) => ({ name: item.nome, id: item.id.toString() }));
            setListaEstados(listaUF);

        } catch (e) {
            setListaEstados([]);
        }
    }

    const getRegioes = async () => {
        try {
            const regioes = await getListaRegioes({}) as AxiosResponse;
            const listaRegion = (regioes?.data ?? []).map((item: Regiao_Cidade) => ({ name: item.nome, id: item.id.toString() }));
            setListaRegioes(listaRegion);

        } catch (e) {
            setListaRegioes([]);
        }
    };

    const handleGetCidades = async () => {
        if (_.isEmpty(formik.values.estado)) {
            setListaCidades([]);
            return;
        }

        try {
            const { data }: { data: IGetResponseCidades } = await getListaCidadesComFiltro({
                Ativo: true,
                IdEstado: Number(formik.values.estado[0].id),
            }) as AxiosResponse;
            const cidades = data?.cidades ?? [];

            setListaCidades(cidades.map(item => ({ name: item.nome, id: item.id.toString() })).sort(
                (a: { name: string, id: string }, b: { name: string, id: string }) =>
                    (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)
            ))

        } catch (e) {
            setListaCidades([]);
        }
    };

    const handleChangeEstado = (e: any) => formik.setFieldValue('estado', e);

    useEffect(() => {
        handleGetCidades();
    }, [formik.values.estado]);

    useEffect(() => {
        if (isInitialMount.current) {
            getEstados();
            getRegioes();
            if (!isIncluding && !cidadeSelecionada?.id) navigate("/IncluirCidade");

            isInitialMount.current = false;
        } else {
            if (isIncluding) formik.setValues(initialValuesIncluir);
        }
    }, [isIncluding]);

    return (
        <section className="IncluirEditarCidade">
            <Header setMenuLateral={() => setMenuLateral(true)} title={isIncluding ? 'Inclusão de cidade' : 'Edição de cidade'} />
            <Breadcrumb>
                <BreadcrumbItem onClick={() => navigate("/ConsultarCidades")}>Consultar Cidades</BreadcrumbItem>
                <BreadcrumbItem>{isIncluding ? 'Inclusão de cidade' : 'Edição de cidade'}</BreadcrumbItem>
            </Breadcrumb>

            <form className="Container" onSubmit={formik.handleSubmit}>
                <div className="IncluirCidade">
                    <div className="firstRow grid">
                        <AutocompleteMultiple
                            disableClearable
                            dimension="sm"
                            label={"Status"}
                            placeholder={"Selecionar status"}
                            noOptionsText="Nenhum status encontrado"
                            options={listaStatus}
                            value={formik.values.ativo}
                            onChange={(e: any) => formik.setFieldValue('ativo', e)}
                            id="multiple-checkbox-Status"
                            multiple={false}
                            error={formik.touched.ativo && Boolean(formik.errors.ativo)}
                            helperText={formik.touched.ativo && formik.errors.ativo}
                            disabled={disableActions}
                        />

                        <Input
                            dimension="sm"
                            label='Id'
                            name='idCidade'
                            id='idCidade'
                            value={formik.values.idCidade}
                            onChange={formik.handleChange}
                            error={formik.touched.idCidade && Boolean(formik.errors.idCidade)}
                            helperText={formik.touched.idCidade && formik.errors.idCidade}
                            readOnly
                        />

                        <AutocompleteMultiple
                            disableClearable
                            dimension="sm"
                            label={"País"}
                            placeholder={""}
                            noOptionsText="Nenhum país encontrado"
                            options={listaPaises}
                            value={formik.values.pais}
                            onChange={(e: any) => formik.setFieldValue('pais', e)}
                            id="multiple-checkbox-pais"
                            multiple={false}
                            error={formik.touched.pais && Boolean(formik.errors.pais)}
                            helperText={formik.touched.pais && formik.errors.pais}
                            disabled={disableActions}
                        />
                    </div>

                    <div className="secondRow grid">
                        <AutocompleteMultiple
                            dimension="sm"
                            label={"Estado"}
                            placeholder={""}
                            noOptionsText={"Nenhum estado encontrado"}
                            options={listaEstados}
                            value={formik.values.estado}
                            onChange={handleChangeEstado}
                            id="multiple-checkbox-grupoServico"
                            error={formik.touched.estado && Boolean(formik.errors.estado)}
                            helperText={formik.touched.estado && formik.errors.estado}
                            multiple={false}
                            disabled={disableActions}
                        />

                        <AutocompleteMultiple
                            dimension="sm"
                            label={"Região"}
                            placeholder={""}
                            noOptionsText={"Nenhuma região encontrada"}
                            options={listaRegioes}
                            value={formik.values.regiao}
                            onChange={(e: any) => formik.setFieldValue('regiao', e)}
                            id="multiple-checkbox-grupoServico"
                            error={formik.touched.regiao && Boolean(formik.errors.regiao)}
                            helperText={formik.touched.regiao && formik.errors.regiao}
                            multiple={false}
                            disabled={disableActions}
                        />

                        <Input
                            dimension="sm"
                            label='Nome da Cidade'
                            name='nome'
                            id='nome'
                            value={formik.values.nome}
                            onChange={formik.handleChange}
                            error={formik.touched.nome && Boolean(formik.errors.nome)}
                            helperText={formik.touched.nome && formik.errors.nome}
                            maxLength={100}
                        />
                    </div>

                    <div className={'thirdRow grid'}>
                        <Input
                            dimension="sm"
                            label='Criado Por'
                            name='usuarioCriacao'
                            id='usuarioCriacao'
                            value={formik.values.usuarioCriacao}
                            onChange={formik.handleChange}
                            error={formik.touched.usuarioCriacao && Boolean(formik.errors.usuarioCriacao)}
                            helperText={formik.touched.usuarioCriacao && formik.errors.usuarioCriacao}
                            readOnly
                        />
                        <Input
                            dimension="sm"
                            label='Criado em'
                            id="dataCriacao"
                            value={formik.values.dataCriacao}
                            onChange={(e) => (formik.setFieldValue('dataCriacao', e))}
                            error={formik.touched.dataCriacao && Boolean(formik.errors.dataCriacao)}
                            helperText={(formik.touched.dataCriacao && formik.errors.dataCriacao) ? "Campo obrigatório" : ""}
                            readOnly
                        />
                        <Input
                            dimension="sm"
                            label='Editado por'
                            name='usuarioModificacao'
                            id='usuarioModificacao'
                            value={formik.values.usuarioModificacao}
                            onChange={formik.handleChange}
                            error={formik.touched.usuarioModificacao && Boolean(formik.errors.usuarioModificacao)}
                            helperText={formik.touched.usuarioModificacao && formik.errors.usuarioModificacao}
                            readOnly
                        />
                        <Input
                            dimension="sm"
                            label='Editado em'
                            id="dataModificacao"
                            value={formik.values.dataModificacao}
                            onChange={(e) => (formik.setFieldValue('dataModificacao', e))}
                            error={formik.touched.dataModificacao && Boolean(formik.errors.dataModificacao)}
                            helperText={(formik.touched.dataModificacao && formik.errors.dataModificacao) ? "Campo obrigatório" : ""}
                            readOnly
                        />
                    </div>

                    {!!isIncluding &&
                        <React.Fragment>
                            {!!profileHasPermission("Novo Registro") ?
                                <Button
                                    type="submit"
                                    variant='primary'
                                    dimension='sm'
                                    width='146px'
                                >
                                    Salvar
                                </Button>
                                :
                                <Button
                                    type="button"
                                    variant='primary'
                                    dimension='sm'
                                    width='146px'
                                    style={{ cursor: 'not-allowed', opacity: 0.5 }}
                                    disabled={true}>
                                    Salvar
                                </Button>
                            }
                        </React.Fragment>
                    }

                    {!isIncluding &&
                        <React.Fragment>
                            {!!profileHasPermission("Alterar registro") ?
                                <Button
                                    type="submit"
                                    variant='primary'
                                    dimension='sm'
                                    width='146px'
                                >
                                    Salvar
                                </Button>
                                :
                                <Button
                                    type="button"
                                    variant='primary'
                                    dimension='sm'
                                    width='146px'
                                    style={{ cursor: 'not-allowed', opacity: 0.5 }}
                                    disabled={true}>
                                    Salvar
                                </Button>
                            }
                        </React.Fragment>
                    }
                </div>

                {!!loading && <CircularProgress style={{ margin: '0px auto' }} />}

                <AlertMessage
                    isOpenAlert={openAlert}
                    setOpenAlert={setOpenAlert}
                    alertType={tipomsgAlert}
                    msgAlert={msgAlert}
                />
            </form>
        </section>
    );
}

export default IncluirEditarCidade;
