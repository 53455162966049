import styled from "styled-components";
import { Button } from "@mui/material";
import { siteTheme } from "../../../styles/themeVariables";

const { color } = siteTheme;

export const Section = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
`;

export const Body = styled.div`
  margin-top: 30px;

  .grid1, .grid2 {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    -webkit-box-align: baseline;
    align-items: end;
  }
  
  .grid2 {
    margin-top: 20px;
    margin-bottom: 44px;
  }

  @media only screen and (max-width: 1000px) {
    grid-template-columns: 1fr;

    .grid1, .grid2 {
      grid-template-columns: 1fr;
      gap: 16px;
    }
  }
`;

export const ButtonsBox = styled.div`
  display: grid;
  grid-template-columns: 1fr 200px 250px 200px;
  gap: 20px;
  -webkit-box-align: baseline;
  align-items: end;

  @media only screen and (max-width: 1000px) {
    grid-template-columns: 1fr;
    gap: 16px;
  }
`;

export const SearchButton = styled(Button)`
  font-size: 14px !important;
  height: 44px !important;
  border-radius: 15px !important;
  padding: 0 40px 0 40px !important;
`;

export const NewParametroButton = styled(Button)`
  background: #AFB1B1 !important;
  color: #FFF !important;
  font-size: 14px !important;
  height: 44px !important;
  border-radius: 15px !important;

  &.bloqueado {
    cursor: not-allowed !important;
    pointer-events: auto !important;
    opacity: 0.5;
  }
`;