import React, { useState } from 'react'
import Modal from '../../../components/modal'
import Button from '../../../components/Button/styles';
import Input from '../../../components/Input/Input'
import { ContainerErroModal, ButtonList } from './farolModalEncaminharOSStyles';
import { useEffect } from 'react';

interface IErroEncaminharModal {
  isModalOpen: boolean,
  handleCloseModal: () => void,
  text: string
}

const ErroEncaminharModal = ({
  isModalOpen,
  handleCloseModal,
  text,
}: IErroEncaminharModal) => {

  const [isOpen, setIsOpen] = useState(isModalOpen)
  useEffect(() => {
    setIsOpen(isModalOpen)
  }, [isModalOpen])

  return (
    <Modal
      handleCloseModal={handleCloseModal}
      title=''
      isOpen={isOpen}
      titleStyle={{
        m: 0,
        p: 2,
        padding: "16px 22px",
        bgcolor: "#FFF",
        color: "#797D8C",
        boxSizing: "border-box",
        height: "67px",
        paddingLeft: '80px',
      }}
    >
      <ContainerErroModal>
        <h2 style={{textAlign: 'center'}}>
          {text}
        </h2>
        <Button onClick={handleCloseModal} width='50%'>Ok</Button>
      </ContainerErroModal>
    </Modal>
  )
}

export default ErroEncaminharModal