import { ISimpleObjectInput } from "../types";

// Uso:
// const obj = [
//     { nome: "Paulo", idTeste: 1 },
//     { nome: "Maria", idTeste: 2 },
//   ];
//const simpleObjects = toSimpleObjectInput(tecnicos, "nome", "idTeste");
export const toSimpleObjectInput = (
  obj: any[],
  nameProp: string,
  idProp: string = "id",
): ISimpleObjectInput[] => {
  return obj.map((p: any): ISimpleObjectInput => ({
    name: p[nameProp],
    id: p[idProp].toString(),
  }));
};

export const toSimpleObjectInputFromStrings = (arr: string[]): ISimpleObjectInput[] => {
  return arr.map((item, index) => ({
    name: item,
    id: index.toString(),
  }));
};

export function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getComparator<Key extends keyof any>(
  order: 'asc' | 'desc',
  orderBy: Key,
): (a: any, b: any) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Função para garantir estabilidade na ordenação
export function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}