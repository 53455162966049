import { apiPost } from "../../../services/api";
import { 
  IPercentualOSRequest, 
  ITempoMedioAtendimentoRequest, 
  ITotalOSAtrasadasRequest,
  IIndicadorSLA
} from "../types";

const baseUrl = process.env.REACT_APP_API_ORDEMSERVICO;

export const percentualOs = async (params: IPercentualOSRequest) => 
  await apiPost(`${baseUrl}/Dashboard_/percentual-os`, params)

export const totalOSAtrasadas = async (params: ITotalOSAtrasadasRequest) => 
  await apiPost(`${baseUrl}/Dashboard_/total-os-atrasadas-em-dias`, params)

export const tempoMedioAtendimento = async (params: ITempoMedioAtendimentoRequest) => 
  await apiPost(`${baseUrl}/Dashboard/tempo-medio-atendimento`, params)

export const indicadorSLA = async (params: IIndicadorSLA) => 
  await apiPost(`${baseUrl}/Dashboard/indicador-sla`, params)