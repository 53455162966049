import styled from "styled-components";

export const ContainerCSVModal = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  padding: 26px 40px 38px 40px;
  box-sizing: border-box;
  gap: 21px;
  align-items: center;
  & .MuiAlert-action {
    margin: unset;
  }
`

export const ButtonList = styled.div`
  display: flex;
  gap: 8px;
`