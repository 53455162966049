import React, { useState, useEffect, useContext } from 'react'
import './geralTab.scss'
import Input from '../../../components/Input/Input';
import _ from 'lodash'
import Checkbox from '../../../components/checkbox/checkbox';
import { HomeContext } from '../../../contexts/homeContext';

const ConfiguracaoTab = (params: {isIncluding: boolean}) => {

  const { isIncluding } = params

  const {
    codSAP,
    setCodSAP,
    codFornecedorSAP,
    setCodFornecedorSAP,
    codSAPCliente,
    setCodSAPCliente,
    idFilial,
    setIdFilial,
    deposito,
    setDeposito,
    codFornecedor,
    setCodFornecedor,

    integracaoGetnetSAP,
    setIntegracaoGetnetSAP,
    naoRecebimento,
    setNaoRecebimento,
    bloqTransfGOOD,
    setBloqTransfGOOD,
    integracaoLogStone,
    setIntegracaoLogStone,
    criaTriagem,
    setCriaTriagem,
    bloqReversa,
    setBloqReversa,
    integracaoLogPagSeguro,
    setIntegracaoLogPagSeguro,
    baseContratante,
    setBaseContratante,
    criaTriagemAut,
    setCriaTriagemAut,
    transportePadrao,
    setTransportePadrao,
    conferenciaCega,
    setConferenciaCega,
    FIFO,
    setFIFO,
    QRCodeAvanco,
    setQRCodeAvanco,
    integracaoGetnet,
    setIntegracaoGetnet, 
    validPatrimonio,
    setValidPatrimonio, 
    QRCodeRecebimento,
    setQRCodeRecebimento,
    triagemTravaDestino,
    setTriagemTravaDestino,
    OMPopUp,
    setOMPopUp,
    QRCodeKit,
    setQRCodeKit,
    desligaIntStone,
    setDesligaIntStone,
    prestadorSelected
  } = useContext(HomeContext)

  useEffect(() => {
    if(!isIncluding){
      setCodSAP(prestadorSelected?.configuracao?.codigoCTSAP || "")
      setCodFornecedorSAP(prestadorSelected?.configuracao?.codigoFornecedorSAP || "")
      setCodSAPCliente(prestadorSelected?.configuracao?.sapB1CodigoCliente || "")
      setIdFilial(prestadorSelected?.configuracao?.sapB1IdFilial || "")
      setDeposito(prestadorSelected?.configuracao?.sapB1DepositoEnvioDireto || "")
      setCodFornecedor(prestadorSelected?.configuracao?.sapB1CodigoFornecedor || "")
      setIntegracaoGetnetSAP(prestadorSelected?.configuracao?.integracaoGetNetSAP || "")
      setNaoRecebimento(prestadorSelected?.configuracao?.recebimento || "")
      setBloqTransfGOOD(prestadorSelected?.configuracao?.bloqueiaTransferenciaGood || "")
      // setIntegracaoLogStone(prestadorSelected?.configuracao?.abc || "")
      setCriaTriagem(prestadorSelected?.configuracao?.criaTriagem || "")
      setCriaTriagemAut(prestadorSelected?.configuracao?.criaTriagemAutomatico || "")
      setBaseContratante(prestadorSelected?.configuracao?.baseContratante || "")
      // setIntegracaoLogPagSeguro(prestadorSelected?.configuracao?.abc || "")
      // setTransportePadrao(prestadorSelected?.configuracao?.abc || "")
      // setConferenciaCega(prestadorSelected?.configuracao?.abc || "")
      // setFIFO(prestadorSelected?.configuracao?.abc || "")
      setQRCodeAvanco(prestadorSelected?.configuracao?.avanco || "")
      setIntegracaoGetnet(prestadorSelected?.configuracao?.integracaoGetNetCT || "")
      // setValidPatrimonio(prestadorSelected?.configuracao?.abc || "")
      setQRCodeRecebimento(prestadorSelected?.configuracao?.recebimento || "")
      // setTriagemTravaDestino(prestadorSelected?.configuracao?.abc || "")
      setOMPopUp(prestadorSelected?.configuracao?.omPopUpRecebimento || "")
      setQRCodeKit(prestadorSelected?.configuracao?.popUpMontagemKit || "")
      // setDesligaIntStone(prestadorSelected?.configuracao?.stone || "")
    }
  }, [])
  
  return(
    <div className='ConfiguracaoTabContainer'>
      <div className='NormalGrid'>
        <Input
         value={codSAP}
         onChange={(e) => setCodSAP(e.target.value)}
         label='Código SAP' 
         dimension='sm'
         id='CodigoSAP'
        />
        <Input
         value={codFornecedorSAP}
         onChange={(e) => setCodFornecedorSAP(e.target.value)}
         label='Código Fornecedor SAP' 
         dimension='sm'
         id='CodigoFornecedorSAP'
        />
        <Input
         value={codSAPCliente}
         onChange={(e) => setCodSAPCliente(e.target.value)}
         label='SAP B1 - Código Cliente' 
         dimension='sm'
         id='CodigoCliente'
        />
        <Input
         value={idFilial}
         onChange={(e) => setIdFilial(e.target.value)}
         label='SAP B1 - ID Filial' 
         dimension='sm'
         id='IDFilial'
        />
        <Input
         value={deposito}
         onChange={(e) => setDeposito(e.target.value)}
         label='SAP B1 - Depósito Envio Direto' 
         dimension='sm'
         id='DepositoEnvioDireto'
        />
        <Input
         value={codFornecedor}
         onChange={(e) => setCodFornecedor(e.target.value)}
         label='SAP B1 - Código Fornecedor' 
         dimension='sm'
         id='CodigoFornecedor'
        />
      </div>
      <div className='CheckboxGrid'>
        <Checkbox
         id={"IntegracaoGetnetSAP"}
         boxclassName={"IntegracaoGetnetSAP"}
         label="Integração Getnet SAP"
         value={integracaoGetnetSAP}
         onChange={() => setIntegracaoGetnetSAP(!integracaoGetnetSAP)}
        />
        <Checkbox
         id={"NaoFazRecebimentoProduto"}
         boxclassName={"NaoFazRecebimentoProduto"}
         label="Não faz recebimento de produto"
         value={naoRecebimento}
         onChange={() => setNaoRecebimento(!naoRecebimento)}
        />
        <Checkbox
         id={"BloqueiaTransferenciaGOOD"}
         boxclassName={"BloqueiaTransferenciaGOOD"}
         label="Bloqueia Transferência GOOD"
         value={bloqTransfGOOD}
         onChange={() => setBloqTransfGOOD(!bloqTransfGOOD)}
        />
        <Checkbox
         id={"IntegracaoLogiticaStone"}
         boxclassName={"IntegracaoLogiticaStone"}
         label="Integração Logítica Stone"
         value={integracaoLogStone}
         onChange={() => setIntegracaoLogStone(!integracaoLogStone)}
        />
        <Checkbox
         id={"CriaTriagem"}
         boxclassName={"CriaTriagem"}
         label="Cria Triagem"
         value={criaTriagem}
         onChange={() => setCriaTriagem(!criaTriagem)}
        />
        <Checkbox
         id={"BloqueiaReversaNaoKit"}
         boxclassName={"BloqueiaReversaNaoKit"}
         label="Bloqueia Reversa Não Kit"
         value={bloqReversa}
         onChange={() => setBloqReversa(!bloqReversa)}
        />
        <Checkbox
         id={"IntegracaoPagSeguroLogistica"}
         boxclassName={"IntegracaoPagSeguroLogistica"}
         label="Integração PagSeguro Logística"
         value={integracaoLogPagSeguro}
         onChange={() => setIntegracaoLogPagSeguro(!integracaoLogPagSeguro)}
        />
        
        <Checkbox
         id={"BaseContratante"}
         boxclassName={"BaseContratante"}
         label="Base Contratante"
         value={baseContratante}
         onChange={() => setBaseContratante(!baseContratante)}
        />
        <Checkbox
         id={"CriaTriagemAutomatico"}
         boxclassName={"CriaTriagemAutomatico"}
         label="Cria Triagem Automático"
         value={criaTriagemAut}
         onChange={() => setCriaTriagemAut(!criaTriagemAut)}
        />
        <Checkbox
         id={"TransportadoraPadrao"}
         boxclassName={"TransportadoraPadrao"}
         label="Transportadora Padrão"
         value={transportePadrao}
         onChange={() => setTransportePadrao(!transportePadrao)}
        />
        <Checkbox
         id={"ConferenciaCega"}
         boxclassName={"ConferenciaCega"}
         label="Conferência Cega"
         value={conferenciaCega}
         onChange={() => setConferenciaCega(!conferenciaCega)}
        />
        <Checkbox
         id={"FIFO"}
         boxclassName={"FIFO"}
         label="FIFO"
         value={FIFO}
         onChange={() => setFIFO(!FIFO)}
        />
        <Checkbox
         id={"QRCODEAvanco"}
         boxclassName={"QRCODEAvanco"}
         label="QR CODE Avanço"
         value={QRCodeAvanco}
         onChange={() => setQRCodeAvanco(!QRCodeAvanco)}
        />
        
        <Checkbox
         id={"IntegracaoGetnet"}
         boxclassName={"IntegracaoGetnet"}
         label="Integração Getnet"
         value={integracaoGetnet}
         onChange={() => setIntegracaoGetnet(!integracaoGetnet)}
        />
        <Checkbox
         id={"ValidaPatrimonioRecebimento"}
         boxclassName={"ValidaPatrimonioRecebimento"}
         label="Valida Patrimônio no Recebimento"
         value={validPatrimonio}
         onChange={() => setValidPatrimonio(!validPatrimonio)}
        />
        <Checkbox
         id={"QRCODERecebimento"}
         boxclassName={"QRCODERecebimento"}
         label="QR CODE Recebimento"
         value={QRCodeRecebimento}
         onChange={() => setQRCodeRecebimento(!QRCodeRecebimento)}
        />
        <Checkbox
         id={"TriagemTravaDestino"}
         boxclassName={"TriagemTravaDestino"}
         label="Triagem - Trava Destino"
         value={triagemTravaDestino}
         onChange={() => setTriagemTravaDestino(!triagemTravaDestino)}
        />
        <Checkbox
         id={"OMPopupRecebimento"}
         boxclassName={"OMPopupRecebimento"}
         label="OM - Popup Recebimento"
         value={OMPopUp}
         onChange={() => setOMPopUp(!OMPopUp)}
        />
        <Checkbox
         id={"QRCODEPopupMontagemKit"}
         boxclassName={"QRCODEPopupMontagemKit"}
         label="QR CODE Popup Montagem Kit"
         value={QRCodeKit}
         onChange={() => setQRCodeKit(!QRCodeKit)}
        />
        <Checkbox
         id={"DesligaIntegraçãoStoneBaseCDDestino"}
         boxclassName={"DesligaIntegraçãoStoneBaseCDDestino"}
         label="Desliga Integração Stone: Base/CD - Destino"
         value={desligaIntStone}
         onChange={() => setDesligaIntStone(!desligaIntStone)}
        />
      </div>
    </div>
  )
}

export default ConfiguracaoTab