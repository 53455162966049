import React from 'react'
import { Table, TableRow } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import CloseIcon from "@mui/icons-material/Close";
import * as Styles from "../../../components/table/styles";
import './tipoEquipamentoTab.scss'

const TipoMascaraTab = () => {

  const header = ['','Tipo de máscara', 'Contratante', 'Máscara']

  return(
    <div className='TableContainerTipoEquipamento'>
      <TableContainer component={Styles.StyledPaper}>
        <Table aria-label="customized table">
          <TableHead>
            <TableRow>
              {header.map(title => {
                return(
                  <Styles.StyledTableCell className={`center`}>{title}</Styles.StyledTableCell>
                )
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {Array(5).fill({id: '1', tipoMascara:"Serial", contratante: '', mascara: '$$$$$PP$$$$'}).map(item => {
              return(
                <TableRow key={item.id}>
                  <Styles.StyledTableCell
                  component="th" 
                  scope="row" 
                  style={{
                    textAlign: "center", 
                    width: '3%'
                  }}
                  >
                    <CloseIcon />
                  </Styles.StyledTableCell>
                  <Styles.StyledTableCell component="th" scope="row">
                    {item.tipoMascara}
                  </Styles.StyledTableCell>
                  <Styles.StyledTableCell component="th" scope="row">
                    {item.contratante}
                  </Styles.StyledTableCell>
                  <Styles.StyledTableCell component="th" scope="row">
                    {item.mascara}
                  </Styles.StyledTableCell>
                </TableRow>
            )})}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

export default TipoMascaraTab