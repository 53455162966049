import { apiDelete, apiGet, apiPost } from "../../../services/api";
import { IIncluiVinculoContratante, IRemoveVinculoContratante } from "../types";

const baseUrl = process.env.REACT_APP_API_ORDEMSERVICO;

export const getMotivosCancelamento = async () => 
  await apiGet(`${baseUrl}/MotivosCancelamento`)

export const getMotivosCancelamentoVinculadosContratante = async (idContratante: number | string) =>
  await apiGet(`${baseUrl}/MotivosCancelamento/MotivosCancelamentoVinculadosAoContratante?idContratante=${idContratante}`)

export const postVinculaContratanteComMotivoCancelamento = async (params: IIncluiVinculoContratante) =>
  await apiPost(`${baseUrl}/MotivosCancelamento/IncluiVinculoContratanteMotivoCancelamento`, params)

export const deleteRemoveVinculoContratante = async (params: IRemoveVinculoContratante) => {
  const { idsMotivoCancelamento, idContratante } = params

  let idsMotivoCancelamentoLocal: string = ''
  
  for(var i=0; i<idsMotivoCancelamento.length; i++){
    idsMotivoCancelamentoLocal = idsMotivoCancelamentoLocal + `&idsMotivoCancelamento=${idsMotivoCancelamento[i]}`
  }


  return await apiDelete(`${baseUrl}/MotivosCancelamento/RemoveVinculoContratanteMotivoCancelamento?idContratante=${idContratante}${idsMotivoCancelamentoLocal}`)

}