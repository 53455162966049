import React, { useContext, useEffect, useState } from 'react'
import Header from '../../../components/header/header'
import { HomeContext } from '../../../contexts/homeContext';
import { Breadcrumb, BreadcrumbItem } from '../../../components/breadcrumb/breadcrumb';
import { useNavigate } from 'react-router';
import MultipleSelectCheckmarksId from '../../../components/seletorMultiploId/seletorMultiploId';
import Input from '../../../components/Input/Input';
import moment from 'moment';
import "./incluirEditarTipoEquipamento.scss"
import Button from '../../../components/Button/styles';
import _ from 'lodash'
import { getFamilias } from '../../familia/services';
import { AxiosResponse } from 'axios';
import { IFamilia } from '../../familia/types';
import { useFormik } from "formik";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import Collapse from '../../../components/collapse/collapse';
import CloseIcon from "@mui/icons-material/Close";
import * as yup from "yup";
import { postEquipamentoTipo, putEquipamentoTipo } from '../services/services';

interface IInitialValues {
  ativo: string[],
  idTipo: string,
  descricao: string,
  familia: string[]
}

const IncluirEditarTipoEquipamento = () => {

  const isIncluding = window.location.pathname.includes('IncluirTipoEquipamento')

  const { setMenuLateral, familiaLista, setFamiliaLista, tipoEquipamentoSelected } = useContext(HomeContext)

  const navigate = useNavigate()

  const [tipomsgAlert, setTipoMsgAlert] = useState<'success' | 'info' | 'warning' | 'error'>("success");
  const [msgAlert, setMsgAlert] = useState("OS encaminhada com  sucesso!");
  const [openAlert, setOpenAlert] = useState(false);

  const validationSchema = yup.object({
    ativo: yup.array().max(1).min(1, 'Campo obrigatório'),
    idTipo: yup.string().required("Campo obrigatório"),
    descricao: yup.string().required("Campo obrigatório"),
    familia: yup.array().min(1, 'Campo obrigatório').max(1)
  })

  const initialValueFamilia = () => {
    if (!!isIncluding || !tipoEquipamentoSelected?.idFamilia) return [];

    const familiaSelecionada = familiaLista?.find(familia => familia?.id === tipoEquipamentoSelected?.idFamilia?.toString());

    if(!!familiaSelecionada?.id) return [familiaSelecionada?.id];

    return [];
  };

  const initialValues: IInitialValues = {
    ativo: !!isIncluding ? ['1'] : [!_.isNil(tipoEquipamentoSelected?.ativo) ? Number(tipoEquipamentoSelected?.ativo).toString() : '1'],
    idTipo: !!isIncluding ? '' : (tipoEquipamentoSelected?.id?.toString() || ''),
    descricao: !!isIncluding ? '' : (tipoEquipamentoSelected?.descricao || ''),
    familia: initialValueFamilia(),
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async (values, actions) => {
      isIncluding ? 
        await handleIncludeTipoEquipamento(values) :
        await handleEditTipoEquipamento(values)

    }
  })

  const handleIncludeTipoEquipamento = async (values: IInitialValues) => {
    try{
      const { data } = await postEquipamentoTipo({
        idTipo: Number(values.idTipo),
        descricao: values.descricao,
        idFamilia: Number(values.familia[0]),
        status: true,
      }) as AxiosResponse

      setMsgAlert("Tipo de equipamento criado com sucesso")
      setTipoMsgAlert("success")
      setOpenAlert(true)

      formik.resetForm()

    } catch(e) {
      setMsgAlert("Erro ao criar tipo de equipamento")
      setTipoMsgAlert("error")
      setOpenAlert(true)
    }
  }

  const handleEditTipoEquipamento = async (values: IInitialValues) => {
    try{
      const { data } = await putEquipamentoTipo({
        idTipo: Number(values.idTipo),
        descricao: values.descricao,
        idFamilia: Number(values.familia[0]),
        status: !!values.ativo[0] ? Boolean(Number(values.ativo[0])) : false,
      }) as AxiosResponse

      setMsgAlert("Tipo de equipamento alterado com sucesso")
      setTipoMsgAlert("success")
      setOpenAlert(true)

    } catch(e) {
      setMsgAlert("Erro ao editar tipo de equipamento")
      setTipoMsgAlert("error")
      setOpenAlert(true)
    }
  }

  const statusLista = [
    {
      name: 'Ativo',
      id: '1'
    }, 
    {
      name: 'Inativo',
      id: '0'
    }
  ]

  const handleGetFamilias = async () => {
    try{

      const { data } = await getFamilias('', true) as AxiosResponse

      setFamiliaLista(data.map((item: IFamilia) => {
        return { name: item.descricao, id: item.idFamilia.toString() }
      }))

    } catch(e){
      setFamiliaLista([])
    }
  }

  useEffect(() => {
    if(_.isEmpty(familiaLista)) handleGetFamilias()

    if(_.isEmpty(tipoEquipamentoSelected) && !isIncluding) navigate('/Equipamento')
  }, [])

  return(
    <section className='IncluirEditarTipoEquipamento'>
      <Header setMenuLateral={() => setMenuLateral(true)} title={isIncluding ? 'Incluir Tipo de Equipamento' : 'Edição Tipo de Equipamento'} />
      <Breadcrumb>
        <BreadcrumbItem onClick={() => navigate("/Equipamento")}>Tipo de Equipamento</BreadcrumbItem>
        <BreadcrumbItem>{isIncluding ? 'Incluir Tipo de Equipamento' : 'Editar Tipo de Equipamento'}</BreadcrumbItem>
      </Breadcrumb>
      <form className="Container" onSubmit={formik.handleSubmit}>
        <div className="IncluirTipoEquipamento">
          <MultipleSelectCheckmarksId
           nodata={"Nenhum status encontrado"}
           options={statusLista}
           value={formik.values.ativo}
           onChange={(e) => formik.setFieldValue('ativo', e)}
           placeholder={"Selecionar status"}
           id="multiple-checkbox-status"
           idOption="multiple-checkbox-option-status"
           labelId="multiple-checkbox-label-status"
           multiple={false}
           label='Status'
           name="status"
           className='IncluirPerfilSelectStatus'
           dimension='sm'
           error={formik.touched.ativo && Boolean(formik.errors.ativo)}
           helperText={formik.touched.ativo && formik.errors.ativo}
           disabled={isIncluding}
          />
          <Input
           label='Id Tipo Equipamento'
           id='idTipoEquipamento'
           value={formik.values.idTipo}
           onChange={formik.handleChange}
           dimension='sm'
           name="idTipo"
           error={formik.touched.idTipo && Boolean(formik.errors.idTipo)}
           helperText={formik.touched.idTipo && formik.errors.idTipo}
           readOnly={!isIncluding}
          />
          <Input
           label='Descrição'
           id='descricaoTipoEquipamento'
           dimension='sm'
           name='descricao'
           error={formik.touched.descricao && Boolean(formik.errors.descricao)}
           helperText={formik.touched.descricao && formik.errors.descricao}
           value={formik.values.descricao}
           maxLength={255}
           onChange={formik.handleChange}
          />
          <MultipleSelectCheckmarksId
           nodata={"Nenhum família encontrado"}
           options={familiaLista}
           value={formik.values.familia}
           onChange={(e) => formik.setFieldValue('familia', e)}
           placeholder={"Selecionar família"}
           id="multiple-checkbox-família"
           idOption="multiple-checkbox-option-família-"
           labelId="multiple-checkbox-label-família"
           multiple={false}
           name='familia'
           className='ConsultarEquipamentoSelectFamilia'
           dimension='sm'
           error={formik.touched.familia && Boolean(formik.errors.familia)}
           helperText={formik.touched.familia && formik.errors.familia}
           label='Família'
          />
        </div>
        {!isIncluding &&
          <div className="IncluirTipoEquipamento">
            <Input
             id='createdAt' 
             label='Data de Criação' 
             value={moment().format('DD/MM/YYYY - HH:hh:ss')} 
             readOnly 
             dimension='sm'
            />
            <Input
             id='createdBy' 
             label='Criado Por' 
             value={tipoEquipamentoSelected?.usuarioCriacao}
             dimension='sm' 
             readOnly
            />
            <Input 
             id='modifiedAt' 
             label='Data de Modificaçao' 
             value={tipoEquipamentoSelected?.dataModificacao && 
              moment(tipoEquipamentoSelected?.dataModificacao)?.format('DD/MM/YYYY - HH:mm:ss')} 
             readOnly 
             dimension='sm'
            />
            <Input
             id='modifiedBy' 
             label='Modificado Por' 
             dimension='sm'
             value={tipoEquipamentoSelected?.usuarioModificacao} 
             readOnly
            />
          </div>
        }
        <Collapse onClose={setOpenAlert} in={openAlert}>
          <Alert
            severity={tipomsgAlert}
            icon={<div />}
            variant="filled"
            sx={{
              mb: 2,
              bgcolor: tipomsgAlert === "success" ? "#178B319E" : "rgb(255, 35, 35, 0.7)",
              height: "67px",
              borderRadius: "15px",
              boxSizing: "border-box",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginTop: "20px",
              marginBottom: 0,
            }}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="medium"
                onClick={() => setOpenAlert(false)}
              >
                <CloseIcon
                  id={"ModalEncaminharOS-AlertCloseIcon"}
                  fontSize="inherit"
                  sx={{
                    color: "white",
                  }}
                />
              </IconButton>
            }
          >
            <span
              style={{
                color: "white",
                fontSize: "16px",
              }}
              dangerouslySetInnerHTML={{__html: msgAlert}}
            />
          </Alert>
        </Collapse>
        <div className='ButtonSalvarContainer'>
          <Button type="submit" variant='primary'>Salvar</Button>
        </div>
      </form>
    </section>
  )
}

export default IncluirEditarTipoEquipamento