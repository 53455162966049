import React, { useState, useEffect, useContext } from "react";
import { PresetsContainer } from "./presets_styles";
import AutocompleteMultiple from "../../components/autocompleteMultiple/autocompleteMultiple";
import { AxiosResponse } from "axios";
import { Button } from "@mui/material";
import { getPresets } from "./services";
import _ from "lodash";
import { IPresetsCmp, IGetPresetResponse } from "./types";
import StarIcon from "@mui/icons-material/Star";
import ModalSalvarPreset from "./modalSalvarPreset";
import { AuthContext } from "../../contexts/auth";

const Presets = ({
  rotaTela,
  newObjectToPreset,
  setPreset,
  clearPreset,
  disabledButton = false,
  disabledInput = false,
  style,
  placeholder = "Presets",
}: IPresetsCmp) => {
  const listaPresetsInicial = [{ nome: "--" }];

  const { user, getTela } = useContext(AuthContext);
  const [isModalSavePresetOpen, setIsModalSavePresetOpen] = useState(false);
  const [listaPresets, setListaPresets] = useState<IGetPresetResponse[]>(listaPresetsInicial);
  const [presetSelecionado, setPresetSelecionado] = useState<{ name: string; id: string; }[] | null>(null);

  const handleCloseModalSavePreset = () => {
    setIsModalSavePresetOpen(false);
  };

  const handleSetPresetSelecionado = (value: { name: string; id: string; }[]) => {
    setPresetSelecionado(value);
    setPreset(listaPresets.find((p) => p.id === Number(value[0]?.id)) ?? null);
  };

  const handleGetPresets = async () => {
    await getPresets({
      IdTela: getTela(rotaTela)?.id,
      IdUsuario: user?.idUsuario ?? 0
    })
      .then((retorno: any) => {
        const { data } = retorno as AxiosResponse;

        if (!!data && _.isArray(data)) {
          setListaPresets([
            ...listaPresetsInicial,
            ...data
          ]);
        }
      })
      .catch((e: any) => {
        setListaPresets(listaPresetsInicial);
      });
  };

  const validateSelectedPreset = (): IGetPresetResponse | null | undefined => !!presetSelecionado ? (listaPresets.find((p) => p.id === Number(presetSelecionado[0]?.id)) ?? null) : null;

  useEffect(() => {
    handleGetPresets();
  }, []);

  useEffect(() => {
    setPresetSelecionado(null);
  }, [clearPreset]);

  return (
    <>
      {!!isModalSavePresetOpen && (
        <ModalSalvarPreset
          handleGetPresets={handleGetPresets}
          handleClose={handleCloseModalSavePreset}
          isModalOpen={isModalSavePresetOpen}
          listaPresets={listaPresets}
          selectedPreset={validateSelectedPreset()}
          newObjectToPreset={newObjectToPreset}
          rotaTela={rotaTela}
        />
      )}

      <PresetsContainer style={style}>
        <Button
          variant="outlined"
          color="info"
          startIcon={<StarIcon />}
          sx={{
            height: '44px',
            borderRadius: "15px",
            lineHeight: "1",
            ".MuiButton-startIcon": { marginRight: "16px !important" },
          }}
          onClick={() => setIsModalSavePresetOpen(true)}
          disabled={disabledButton}
        >
          Adicionar
        </Button>

        <AutocompleteMultiple
          dimension="sm"
          label={""}
          placeholder={placeholder}
          noOptionsText={"Nenhum preset encontrado"}
          id="multiple-checkbox-Presets"
          options={listaPresets.map(p => ({ name: p?.nome ?? "", id: p?.id?.toString() ?? "" }))}
          value={presetSelecionado}
          onChange={(value: any) => handleSetPresetSelecionado(value)}
          multiple={false}
          disabled={disabledInput}
        />
      </PresetsContainer>
    </>
  );
};

export default Presets;
