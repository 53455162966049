import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import {
  TableWrapper,
  StyledTableContainer,
  StyledTableRowHeader,
  StyledTableCellHeader,
  StyledTableRowBody,
  StyledTableRowCellBody,
  EditIconStyled,
} from "./stickyTable_styles";
import { StyledComponent } from "@emotion/styled";
import { DeleteOutline } from "@mui/icons-material";

interface IStickyTable {
  id: string;
  data: object[];
  handleClickTableRow?: Function;
  stickyHeader?: boolean;
  ariaLabel?: string;
  tableContainerStyle?: object;
  tableCellHeaderStyle?: object;
  tableCellBodyStyle?: object;
  objTraducaoHeader?: any;
  edit?: boolean;
  allowEdit?: boolean;
  labelEdit?: string;
  iconeRemover?: boolean;
  handleClickEdit?: Function;
}

const StickyTable = ({
  id = "StickyTable",
  data = [],
  handleClickTableRow,
  stickyHeader = true,
  ariaLabel = "sticky table",
  tableContainerStyle = {},
  tableCellHeaderStyle = {},
  tableCellBodyStyle = {},
  objTraducaoHeader = {
    /*ex:
    {
      idOM: "ID OM",
      referencia: "Referência",
    }
    */
  },
  edit = false,
  allowEdit = true,
  labelEdit = "Editar",
  handleClickEdit,
  iconeRemover = false,
}: IStickyTable) => {
  const clickTableRow = (data: any) => {
    if (!!handleClickTableRow) handleClickTableRow(data);
  };

  const clickEdit = (data: any) => {
    if (!!handleClickEdit) handleClickEdit(data);
  };

  return (
    <TableWrapper id={id}>
      <StyledTableContainer
        style={{
          maxWidth: "100%",
          borderRadius: "15px",
          ...tableContainerStyle,
        }}
      >
        <Table stickyHeader={stickyHeader} aria-label={ariaLabel}>
          <TableHead>
            <StyledTableRowHeader>
              {!!data &&
                !!data[0] &&
                Object.keys(data[0]).map((column, columnkey) => {
                  return (
                    <StyledTableCellHeader
                      key={columnkey}
                      align={"center"}
                      style={{
                        minWidth: 100,
                        width: 150,
                        boxSizing: "border-box",
                        padding: "0px 10px",
                        ...tableCellHeaderStyle,
                      }}
                    >
                      <span>
                        {!!objTraducaoHeader[column]
                          ? objTraducaoHeader[column]
                          : column}
                      </span>
                    </StyledTableCellHeader>
                  );
                })}

              {!!edit && (
                <StyledTableCellHeader
                  key={`edithead_0`}
                  align={"center"}
                  style={{
                    minWidth: 100,
                    width: 150,
                    boxSizing: "border-box",
                    padding: "0px 10px",
                    ...tableCellHeaderStyle,
                  }}
                >
                  <span>{labelEdit}</span>
                </StyledTableCellHeader>
              )}
            </StyledTableRowHeader>
          </TableHead>

          <TableBody>
            {!!data &&
              !!data.length &&
              data.map((row, rowkey) => {
                return (
                  <StyledTableRowBody
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={rowkey}
                    onClick={() => clickTableRow(row)}
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    {Object.values(row).map((column, columnkey) => {
                      return (
                        <StyledTableRowCellBody
                          key={columnkey}
                          align={"center"}
                          style={{
                            minWidth: 100,
                            width: 150,
                            boxSizing: "border-box",
                            padding: "0px 10px",
                            ...tableCellBodyStyle,
                          }}
                        >
                          <span title={column || ""}>{column || ""}</span>
                        </StyledTableRowCellBody>
                      );
                    })}

                    {!!edit && (
                      <StyledTableRowCellBody
                        key={`editrow_${rowkey}`}
                        align={"center"}
                        style={{
                          minWidth: 100,
                          width: 150,
                          boxSizing: "border-box",
                          padding: "0px 10px",
                          ...tableCellBodyStyle,
                        }}
                      >
                        {iconeRemover ? (
                          !!allowEdit ? (
                            <DeleteOutline
                              onClick={() => clickEdit(row)}
                            />
                          ) : (
                            <DeleteOutline
                              style={{ cursor: "not-allowed", opacity: 0.5 }}
                            />
                          )
                        ) : !!allowEdit ? (
                          <EditIconStyled onClick={() => clickEdit(row)} />
                        ) : (
                          <EditIconStyled
                            style={{ cursor: "not-allowed", opacity: 0.5 }}
                          />
                        )}
                      </StyledTableRowCellBody>
                    )}
                  </StyledTableRowBody>
                );
              })}
          </TableBody>
        </Table>
      </StyledTableContainer>
    </TableWrapper>
  );
};

export default StickyTable;
