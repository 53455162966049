import React, { useState, useEffect, createContext } from "react";
import { useNavigate } from "react-router-dom";
import { api } from "../services/api";
import { createSession } from "../pages/login/services/services";
import { eventBus } from "../helpers/eventBus";
import moment from "moment";
import { createImageFromInitials } from '../helpers/initialsImg'
import { AxiosError, AxiosResponse } from "axios";
import { IAuthContext, IAuthProvider, IUser } from './types/authTypes'
import { removeSpecialCharSimple } from "../helpers/removeSpecialCharSimple";
import CryptoJS from "crypto-js";
import { IPostUserLoginResponse } from "../pages/login/types/types";

export const AuthContext = createContext<IAuthContext>( {} as IAuthContext );

export const AuthProvider = ( { children }:IAuthProvider ) => {
  const navigate = useNavigate();
  const [loginData, setLoginData] = useState<IPostUserLoginResponse>({} as IPostUserLoginResponse);
  const [user, setUser] = useState<IUser | null>(null);
  const [loading, setLoading] = useState(true);

  const [windowInnerWidth, setWindowInnerWidth] = useState(1440);
  const [windowInnerHeight, setWindowInnerHeight] = useState(1024);
  const [windowOuterWidth, setWindowOuterWidth] = useState(1440);
  const [windowOuterHeight, setWindowOuterHeight] = useState(1024);

  useEffect(() => {
    recoverUser();
    updateDimensions();

    /**Eventos */
    window.addEventListener("resize", updateDimensions);
    eventBus.on("logout", () => {
      logout();
    });

    setLoading(false);

    return () => {
      window.removeEventListener("resize", updateDimensions);
      eventBus.remove("logout");
    };
  }, []);

  const updateDimensions = () => {
    const windowInnerWidth = window.innerWidth;
    const windowInnerHeight = window.innerHeight;
    const windowOuterWidth = window.outerWidth;
    const windowOuterHeight = window.outerHeight;
    
    setWindowInnerWidth(windowInnerWidth);
    setWindowInnerHeight(windowInnerHeight);
    setWindowOuterWidth(windowOuterWidth);
    setWindowOuterHeight(windowOuterHeight);
  };

  const passCode: string = process.env.REACT_APP_API_KEY || ''
  
  const recoverUser = () => {
    const recoveredUserJSON = localStorage.getItem("user");

    if (recoveredUserJSON) {
      let recoveredUser;
      
      try {
        const bytes = CryptoJS.AES.decrypt(recoveredUserJSON, passCode);
        recoveredUser = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      } catch (erro) {
        alert("Sua sessão se encerrou, realize o login novamente!");
        return logout();
      }

      setUser(recoveredUser);
      api.defaults.headers.Authorization = recoveredUser.access_token;

      let dExpToken = moment(recoveredUser.dExpToken, "YYYY-MM-DD HH:mm:ss");
      let dAtual = moment();

      if (dAtual.isSameOrAfter(dExpToken)) {
        /**Token expirou*/
        alert("Sua sessão se encerrou, realize o login novamente!");
        logout();

        //   // Caso Token expirou e tenha auto login
        //   if (!!recoveredUser.keepconnected) {
        //     //Usuario pediu manter conectado
        //     console.log("relogando");
        //     login(
        //       recoveredUser.login,
        //       recoveredUser.password,
        //       recoveredUser.keepconnected,
        //       window.location.pathname
        //     );
        //   } else {
        //     alert("Sua sessão se encerrou, realize o login novamente!");
        //     logout();
        //   }
      }
    }
  }

  const login = async (login: string, password: string) => {
    let successo: AxiosResponse | null = null;
    let erro: AxiosError | null = null;

    try {
      const response = await createSession({ login, senha: password }) as AxiosResponse;
      const { data }: { data: IPostUserLoginResponse } = response || { data: {} };

      setLoginData({ ...data, username: login });

      successo = response;
    } catch (error: any) {
      erro = error;
    }

    return {
      successo,
      erro,
    };
  };

  const handleSetUserData = (data?: IPostUserLoginResponse) => {
    try {
      const { access_token, data_user, expires_in, username } = (data || loginData);

      /**Sets default Bearer token */
      api.defaults.headers.Authorization = access_token;

      const menuUser: IUser = {
        access_token,
        data_user,
        expires_in,
        dExpToken: moment().add(expires_in, "s").format("YYYY-MM-DD HH:mm:ss"),
        keepconnected: false,

        username: username || "",
        nome: data_user.nome,
        foto: createImageFromInitials(256, data_user.nome),
        prestadorId: data_user.prestador,
        idUsuario: data_user.id,
        contratante: data_user.contratante,
        contratanteOrdemServico: data_user.contratanteOrdemServico,
        prestadores: data_user.prestadores,
        prestadorOrdemServicoId: data_user.prestadorOrdemServico,
        prestadoresOrdemServico: data_user.prestadoresOrdemServico,
        funcionalidades: data_user.funcionalidades || [],
        telasComAcesso: data_user.telasComAcesso || [],
        primeiroAcesso: data_user.primeiroAcesso || false,
        notificacoesAtivas: data_user.notificacoesAtivas || false,
      };

      /**Encrypted data for localStorage */
      const encryptDataUser = CryptoJS.AES.encrypt(
        JSON.stringify(menuUser),
        passCode
      ).toString();

      localStorage.setItem("user", encryptDataUser);
      setUser(menuUser);
      navigate("/");

    } catch (error: any) {
      console.log(error);
    }
  };

  const logout = () => {
    api.defaults.headers.Authorization = null;
    localStorage.removeItem("user");
    setUser(null);
    navigate("/Login");
  };
  
  const getTela = (rota: string) => {
    const telasComAcesso = [...(user?.telasComAcesso || [])];
    const objTela: { id: number, nome: string } = telasComAcesso.find((t: { rota: string }) => removeSpecialCharSimple(t.rota) === removeSpecialCharSimple(rota)) ?? { id: 0, nome: "" };
    return objTela;
  };

  const getTelaTemAcesso = (rota: string) => {
    const rotasPadraoSempreComAcesso = [
      "/ChangePassword",
      "/AcessoNegado",
      "/NotFound",
      "/ResumoApp"
    ];

    if (rotasPadraoSempreComAcesso.includes(rota)) return true;

    const telasComAcesso = [...(user?.telasComAcesso || [])];
    let hasAccess = false;
    hasAccess = !!telasComAcesso.filter((f: {rota: string}) => removeSpecialCharSimple(f.rota) === removeSpecialCharSimple(rota)).length;

    return hasAccess;
  };

  const getPaginaInicial = () => {
    /*A função verifica dentre as páginas que o usuário tem acesso
    * se ele possue o FarolOS ou o Dashboard para setar como página inicial,
    * caso não tenha nenhuma delas a primeira página encontrada que ele tenha
    * acesso se torna sua página inicial.
    */

    const telasComAcesso = [...(user?.telasComAcesso || [])];
    let hasAccess = false;

    let rotaInicial = "/FarolOS";
    hasAccess = !!telasComAcesso.filter((f: {rota: string}) => removeSpecialCharSimple(f.rota) === removeSpecialCharSimple(rotaInicial)).length;

    if (!!hasAccess) {
      return rotaInicial;
    }

    rotaInicial = "/Dashboard";
    hasAccess = !!telasComAcesso.filter((f: {rota: string}) => removeSpecialCharSimple(f.rota) === removeSpecialCharSimple(rotaInicial)).length;

    if (!!hasAccess) {
      return rotaInicial;
    }

    return !!telasComAcesso[0]?.rota ? telasComAcesso[0].rota : "/NotFound";
  };

  const getPermissoesDaTela = (tela: string) => {
    let permissoes = [];

    if (!!user && !!user?.funcionalidades) {
      permissoes = user.funcionalidades.filter((funcionalidade: {nome: string}) => {
        return removeSpecialCharSimple(funcionalidade.nome).includes(removeSpecialCharSimple(tela));
      });
    }

    return permissoes;
  };

  const funcionalidadeDaTelaTemPermissao = (tela: string, funcionalidade: string) => {
    let hasPermission = false;
    hasPermission = !![...getPermissoesDaTela(tela)].filter((f: {nome: string}) => removeSpecialCharSimple(f.nome).includes(removeSpecialCharSimple(funcionalidade))).length;
    return hasPermission;
  };

  return (
    <AuthContext.Provider
      value={{
        windowInnerWidth,
        windowInnerHeight,
        windowOuterWidth,
        windowOuterHeight,
        authenticated: !!user,
        user,
        loading,
        loginData,
        handleSetUserData,
        login,
        logout,
        getPermissoesDaTela,
        getPaginaInicial,
        getTela,
        getTelaTemAcesso,
        funcionalidadeDaTelaTemPermissao,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
