import React, { useContext } from "react";
import _ from "lodash";
import "./farolFiltrosAplicados.scss";
import { HomeContext } from "../../../contexts/homeContext";
import { AuthContext } from "../../../contexts/auth";
import { ReactComponent as CloseIcon } from "../../../assets/icons/CloseIcon.svg";

const FarolFiltrosAplicados = () => {
  const {
    filtroBuscaRapida,
    setFiltroBuscaRapida,
    listaContratante,
    filtroContratante,
    setFiltroContratante,
    filtroPrestadores,
    setFiltroPrestadores,
    filtroEstados,
    setFiltroEstados,
    filtroCidades,
    setFiltroCidades,
    tecnico,
    setTecnico,
    filtroStatus,
    setFiltroStatus,
    filtroGrupoServico,
    setFiltroGrupoServico,
    filtroServicoById,
    setFiltroServicoById,
    filtroCepInicial,
    setFiltroCepInicial,
    filtroCepFinal,
    setFiltroCepFinal
  } = useContext(HomeContext);

  const { user } = useContext(AuthContext);

  const renderLabelFiltros = () => {
    if (
      _.isEmpty(filtroBuscaRapida) &&
      _.isEmpty(filtroContratante) &&
      _.isEmpty(filtroPrestadores) &&
      _.isEmpty(filtroEstados) &&
      _.isEmpty(filtroCidades) &&
      _.isEmpty(tecnico) &&
      _.isEmpty(filtroStatus) &&
      _.isEmpty(filtroGrupoServico) &&
      _.isEmpty(filtroServicoById) &&
      _.isEmpty(filtroCepInicial) &&
      _.isEmpty(filtroCepFinal)
    ) {
      return "Nenhum filtro aplicado"
    } else return "Filtros aplicados:"
  }

  return (
    <div className="FarolFiltrosAplicados">
      <div className="FarolFiltrosAplicados-label">{renderLabelFiltros()}</div>
      {!_.isEmpty(filtroBuscaRapida)
        &&
        <FiltroAplicado nomeFiltro={"Busca rápida"} setFiltro={() => setFiltroBuscaRapida("")} />
      }

      {!_.isEmpty(filtroContratante)
        &&
        <FiltroAplicado
          disableCloseIcon={!!user?.contratanteOrdemServico || (!user?.contratanteOrdemServico && _.isEqual(listaContratante.map(item => item.id), filtroContratante))}
          nomeFiltro={"Contratante"}
          setFiltro={() => setFiltroContratante(!!user?.contratanteOrdemServico ? [user?.contratanteOrdemServico.toString()] : [])}
        />
      }

      {!_.isEmpty(filtroPrestadores)
        &&
        <FiltroAplicado
          disableCloseIcon={!!user?.prestadores && !_.isEmpty(user.prestadores) && _.isEqual(user.prestadores, filtroPrestadores)}
          nomeFiltro={"Prestadores"}
          setFiltro={() => setFiltroPrestadores(!!user?.prestadores  ? user?.prestadores  : [])}
        />
      }

      {!_.isEmpty(filtroEstados)
        &&
        <FiltroAplicado nomeFiltro={"Estados"} setFiltro={() => setFiltroEstados([])} />
      }

      {!_.isEmpty(filtroCidades)
        &&
        <FiltroAplicado nomeFiltro={"Cidades"} setFiltro={() => setFiltroCidades([])} />
      }

      {!_.isEmpty(tecnico)
        &&
        <FiltroAplicado nomeFiltro={"Técnicos"} setFiltro={() => setTecnico([])} />
      }

      {!_.isEmpty(filtroStatus)
        &&
        <FiltroAplicado nomeFiltro={"Status"} setFiltro={() => setFiltroStatus([])} />
      }

      {!_.isEmpty(filtroGrupoServico)
        &&
        <FiltroAplicado nomeFiltro={"Grupo de serviços"} setFiltro={() => setFiltroGrupoServico([])} />
      }

      {!_.isEmpty(filtroServicoById)
        &&
        <FiltroAplicado nomeFiltro={"Serviços"} setFiltro={() => setFiltroServicoById([])} />
      }

      {!_.isEmpty(filtroCepInicial)
        &&
        <FiltroAplicado nomeFiltro={"CEP inicial"} setFiltro={() => setFiltroCepInicial("")} />
      }

      {!_.isEmpty(filtroCepFinal)
        &&
        <FiltroAplicado nomeFiltro={"CEP final"} setFiltro={() => setFiltroCepFinal("")} />
      }
    </div>
  );
};

const FiltroAplicado = ({ nomeFiltro, setFiltro, disableCloseIcon = false }: { nomeFiltro: string, setFiltro: () => void, disableCloseIcon?: boolean }) => {
  return (
    <div className={"FarolFiltrosAplicados-filtro" + (disableCloseIcon ? " disableCloseIcon" : "")}>
      {nomeFiltro}

      <CloseIcon
        id={"FiltroCloseIcon"}
        className={"FiltroCloseIcon"}
        onClick={!disableCloseIcon ? setFiltro : () => { }}
      />
    </div>
  );
};

export default FarolFiltrosAplicados;
