import React from 'react';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DesktopDateTimePicker } from '@mui/x-date-pickers/DesktopDateTimePicker';
import FormControl from "@mui/material/FormControl";
import './dateTimePicker.scss';
import { SxProps } from '@mui/material';
import { Theme } from '@mui/system';

export interface IDateTimePicker {
    id?: string,
    label?: string,
    className?: string,
    dimension?: 'sm' | 'lg',
    value: any,
    minDate?: any,
    maxDate?: any,
    onChange: (e: any) => void,
    disabled?: boolean,
    mask?: string,
    inputFormat?: string,
    placeholder?: string,
    disableMaskedInput?: boolean,
    error?: boolean,
    helperText?: any,
    readOnly?: boolean,
    sx?: SxProps<Theme>
}

export default function DateTimePicker({
    id = "DateTimePicker",
    label = "",
    className,
    dimension,
    value,
    minDate = null,
    maxDate = null,
    onChange,
    disabled = false,
    mask = "__/__/____",
    inputFormat = "DD/MM/YYYY HH:mm:ss",
    placeholder = "DD/MM/AAAA HH:mm:ss",
    disableMaskedInput = false,
    error,
    helperText,
    readOnly,
    sx
}: IDateTimePicker) {

    return (
        <FormControl className="container" sx={{ m: 1, width: "100%" }}>
            {label && <label htmlFor={id} className={`label-input ${dimension}`}>{label}</label>}
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <DesktopDateTimePicker
                    className={`InputComponentDate ${className} ${dimension} ${!!error ? "error" : ""}`}
                    value={value}
                    sx={sx}
                    minDate={minDate}
                    maxDate={maxDate}
                    onChange={(newValue) => onChange(newValue)}
                    disabled={disabled || readOnly}
                    //@ts-ignore
                    mask={mask}
                    inputFormat={inputFormat}
                    disableMaskedInput={disableMaskedInput}
                    renderInput={(params: any) =>
                    (<TextField
                        {...params}
                        id={id}
                        inputProps={{
                            ...params.inputProps,
                            placeholder: placeholder,
                        }} />)
                    }
                />
            </LocalizationProvider>
            <span className="errorMessage">{helperText}</span>
        </FormControl>
    );
}
