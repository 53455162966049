import React, { useState, useContext } from "react";
import _ from "lodash";
import "./dashboardPopoverFiltroData.scss";
import Popover from "@mui/material/Popover";
import { IDashboardPopoverFiltroData } from "../types";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import DatePicker from "../../../components/datePicker/datePicker";
import AutocompletetecnicoId from "../../../components/autocompleteTecnicos/autocompleteTecnicosId";
import MultipleSelectCheckmarksid from "../../../components/seletorMultiploId/seletorMultiploId";
import { HomeContext } from "../../../contexts/homeContext";
import { getListaTecnicosByPrestador } from '../../encaminharOS/services/services'
import { AuthContext } from "../../../contexts/auth";
import { AxiosResponse } from "axios";
import { Grupo_Servico, I_Servico } from "../../../contexts/types/farolTypes";

const DashboardPopoverFiltroData = ({
  id = "Dashboard-PopoverFiltro",
  open = false,
  onClose,
  anchorEl = null,
  anchorOrigin = {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin = {
    vertical: "top",
    horizontal: "center",
  },
  initialMonth,
  setInitialMonth,
  finalMonth,
  setFinalMonth,
  tecnicoSelecionado,
  handleChangeTechnician,
  filtroGrupoServicoTemp,
  setFiltroGrupoServicoTemp,
  filtroServicoTemp,
  setFiltroServicoTemp,
}: IDashboardPopoverFiltroData) => {

  const { 
    setListaTecnicos,
    listaTecnicosByPrestadorTratada,
    listaGrupo_Servico,
    filtroGrupoServico,
    filtroServico
  } = useContext(HomeContext);

  const { user } = useContext(AuthContext);

  const { prestadorId, prestadores } = user!

  const criaListaServico = () => {
    let myServiceList: {name: string, id: string, grupo: string}[] = [];
    if(listaGrupo_Servico){
      listaGrupo_Servico?.map((grupo) => {
        if(filtroGrupoServicoTemp.includes(grupo.id.toString())){
          grupo.servicos.map((item: I_Servico) => (
            myServiceList.push({
              name: item.nome,
              id: item.id.toString(),
              grupo: grupo.id.toString(),
            })
          ))
        }
        
      })
    }
    const temp = myServiceList.map(item => {
      return filtroServicoTemp.includes(item.id)
    })
    if(!temp.some(item => item === true ) && !_.isEmpty(filtroServicoTemp)){
      setFiltroServicoTemp([])
    }
    return myServiceList;
  };

  const requestListaTecnicos = async () => {
    if(_.isEmpty(prestadores)){
      try {
        let retorno = await getListaTecnicosByPrestador(prestadorId) as AxiosResponse;
        setListaTecnicos(retorno?.data || []);
        return true;
      } catch (error) {
        setListaTecnicos([])
        return true;
      }
    } else {
      let tecnicos: any [] = []
      for(var i = 0; i < prestadores.length; i++){
        try {
          let retorno = await getListaTecnicosByPrestador(prestadores[i]) as AxiosResponse;
          tecnicos = [...retorno?.data]
        } catch (error) {
          setListaTecnicos([])
        }
      }
      setListaTecnicos(tecnicos)
      return true
    }
  };

  const handleListaTecnicos = () => {
    const listaTecnicos = listaTecnicosByPrestadorTratada().filter(tecnico => 
      !tecnicoSelecionado.find(insideTecnico => insideTecnico.id === tecnico.id)
    )

    return listaTecnicos
  }

  return (
    <Popover
     id={id}
     open={open}
     onClose={onClose}
     anchorEl={anchorEl}
     anchorOrigin={anchorOrigin}
     transformOrigin={transformOrigin}
     classes={{ paper: "Dashboard-PopoverFiltro-box" }}
    >
      <div className="Dashboard-PopoverFiltro">
        <div className="RangeDatePicker">
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
             value={initialMonth}
             onChange={(e: any) => setInitialMonth(e)}
            />
            <DatePicker
             value={finalMonth}
             onChange={(e: any) => setFinalMonth(e)}
            />
          </LocalizationProvider>
        </div>
        <AutocompletetecnicoId
         noOptionsText="Não há técnicos ativos"
         autocompleteValueId={tecnicoSelecionado}
         onChangeValue={handleChangeTechnician}
         options={handleListaTecnicos()}
         requestOptions={requestListaTecnicos}
         disabled={false}
         fontSize={14}
         placeholder='Filtrar técnico'
        />
        <MultipleSelectCheckmarksid
         nodata={"Nenhum grupo de serviço encontrado"}
         options={listaGrupo_Servico?.map((item: Grupo_Servico) => ({name: item.nome, id: item.id.toString()}))!}
         value={filtroGrupoServicoTemp}
         onChange={setFiltroGrupoServicoTemp}
         placeholder={"Grupo de serviço"}
         id="multiple-checkbox-grupoServico"
         idOption="multiple-checkbox-option-grupoServico-"
         labelId="multiple-checkbox-label-grupoServico"
        />
        <MultipleSelectCheckmarksid
         nodata={"Selecione um grupo de serviço"}
         options={criaListaServico()}
         value={filtroServicoTemp}
         onChange={setFiltroServicoTemp}
         placeholder={"Serviço"}
         id="multiple-checkbox-servico"
         idOption="multiple-checkbox-option-servico-"
         labelId="multiple-checkbox-label-servico"
         multiple={false}
        />
        {/* <MultipleSelectCheckmarksid
         multiple={false}
         nodata={"Nenhum grupo de serviço encontrado"}
         options={tiposAtendimento}
         value={tipoAtendimento}
         onChange={setTipoAtendimento}
         placeholder={"Tipo de atendimento"}
         id="multiple-checkbox-grupoServico"
         idOption="multiple-checkbox-option-grupoServico-"
         labelId="multiple-checkbox-label-grupoServico"
        /> */}
      </div>
    </Popover>
  );
};

export default DashboardPopoverFiltroData;
