import moment from "moment";
import { ISeletorMultiploId } from "../../pages/farol/types";

export const listaStatusPadrao = [
  { name: 'NOVA', id: '1' },
  { name: 'ENCAMINHADA', id: '2' },
  { name: 'EM CAMPO', id: '3' },
  { name: 'HD NIVEL 1', id: '4' },
  { name: 'CANCELADA', id: '5' },
  { name: 'BAIXADA', id: '6' },
  { name: 'SIT ANORMAL', id: '7' },
  { name: 'RETIDO', id: '9' },
  { name: 'PRE BAIXA SUCESSO', id: '38' },
  { name: 'PRE BAIXA INSUCESSO', id: '39' },
  { name: 'EM ANALISE', id: '40' },
  { name: 'EXPEDICAO', id: '41' },
  { name: 'EM TRANSFERENCIA', id: '42' },
  { name: 'RECIBO PA', id: '43' },
  { name: 'PEND GET VISITA', id: '45' },
  { name: 'PEND GET TRIAGEM', id: '46' },
  { name: 'DIVERGENTE', id: '48' },
  { name: 'ERRO BAIXA SUCESSO', id: '51' },
  { name: 'ERRO BAIXA INSUCESSO', id: '52' },
  { name: 'AGUARDANDO DOCA', id: '53' },
  { name: 'REENCAMINHADO', id: '54' },
  { name: 'FORA DO PLANO', id: '55' },
  { name: 'ROLLBACK', id: '56' },
  { name: 'AGUARDANDO ESTOQUE', id: '57' },
  { name: 'TRATATIVA OLM', id: '58' },
  { name: 'TOM TICKET', id: '59' },
  { name: 'PEND. ATIVACAO', id: '60' },
  { name: 'RETIDO NIVEL 2', id: '61' },
  { name: 'FALTA DE CHIP', id: '62' },
  { name: 'AGENDAMENTO DE COLETA', id: '63' },
  { name: 'SEM RETORNO CONTATO', id: '64' },
  { name: 'ERRO', id: '8888' },
  { name: 'EM PROCESSAMENTO', id: '9999' },
];

export const filtroStatusPadrao = ["5"];

export const laboratorioList = [
  {
    name: 'Reparo fabricante',
    id: 'Reparo fabricante'
  },
  {
    name: 'Reparo estético',
    id: 'Reparo estético'
  },
  {
    name: 'Reparo estético e reparo fabricante',
    id: 'Reparo estético e reparo fabricante'
  },
  {
    name: 'Reparo mau uso',
    id: 'Reparo mau uso'
  },
  {
    name: 'Reparo gabinete',
    id: 'Reparo gabinete'
  },
  {
    name: 'Reparo gabinete e funcional',
    id: 'Reparo gabinete e funcional'
  },
]

export const booleanList = [
  {
    name: 'Sim',
    id: '1'
  },
  {
    name: 'Não',
    id: '0'
  }
]


export const listaContratantePadrao: ISeletorMultiploId[] = [
  { name: 'GETNET', id: '1' },
  { name: 'SYSDATA', id: '39' },
  { name: 'SANTANDER', id: '76' },
  { name: 'CAR10', id: '78' },
  { name: "SEM PARAR", id: '81' }
];

export const listaGrupoServicoPadrao = [
  "Alteração de Tecnologia",
  "Entrega de Insumo",
  "Instalação",
  "Manutenção",
  "Retirada",
];

/*id = idOppayLegacy*/
export const listaServicoPadrao = {
  "Alteração de Tecnologia": [
    { name: "ALTERACAO DE TECNOLOGIA", id: 53 },
    { name: "MANUTENCAO", id: 65 },
    { name: "ALTERAÇÃO DE TECNOLOGIA", id: 87 },
    { name: "ANOMALIA SUBSTITUICAO AJUSTE OS", id: 186 },
  ],
  "Entrega de Insumo": [
    { name: "ENVIO DE CARTAO", id: 123 },
    { name: "ENVIO DE BOBINAS", id: 129 },
    { name: "ENVIO DE ADESIVOS", id: 132 },
  ],
  "Instalação": [
    { name: "INSTALACAO", id: 52 },
    { name: "HABILITAÇÃO", id: 61 },
    { name: "INSTALAÇÃO TEF", id: 62 },
    { name: "SINISTRO", id: 63 },
    { name: "INSTALAÇÃO AVANTE", id: 66 },
    { name: "INSTALACAO SP STONE", id: 69 },
    { name: "INSTALACAO RJ STONE", id: 71 },
    { name: "ENTREGA DE BOBINA", id: 76 },
    { name: "AUTOMACAO", id: 77 },
    { name: "TROCA ELAVON", id: 82 },
    { name: "INSTALAÇÃO", id: 86 },
    { name: "Instalação Técnico", id: 102 },
    { name: "Instalação Courier", id: 103 },
    { name: "Instalação Emergencial", id: 104 },
    { name: "INSTALAÇÃO RPC", id: 114 },
    { name: "INSTALAÇÃO CORBAN", id: 115 },
    { name: "Entrega De Pinpad", id: 116 },
    { name: "VENDA DE EQUIPAMENTO", id: 117 },
    { name: "ENTREGA MEI C/ CARTAO", id: 122 },
    { name: "ENVIO DE CHIP", id: 124 },
    { name: "ENTREGA MEI", id: 125 },
    { name: "INSTALACAO COMODATO", id: 127 },
    { name: "INSTALAÇAO PAG", id: 128 },
    { name: "REENVIO", id: 161 },
    { name: "ENTREGA VENDA", id: 164 },
    { name: "ENTREGA DE CHIP", id: 166 },
    { name: "ENTREGA COURIER", id: 168 },
    { name: "REPOSICAO COURIER", id: 170 },
    { name: "ENTREGA CELULAR", id: 172 },
    { name: "ENTREGA KIT SINALIZACAO", id: 174 },
    { name: "ANOMALIA INSTALACAO AJUSTE OS", id: 175 },
    { name: "ENTREGA VENDA POR COURIER", id: 180 },
    { name: "ENTREGA POR COURIER", id: 183 },
    { name: "ENVIO DE BOBINA", id: 184 },
  ],
  "Manutenção": [
    { name: "MANUTENCAO", id: 49 },
    { name: "ENTREGA DE KIT", id: 60 },
    { name: "MANUTENÇÃO BANESTES", id: 64 },
    { name: "MANUTENCAO AVANTE", id: 67 },
    { name: "MANUTENCAO RJ STONE", id: 72 },
    { name: "MANUTENCAO SP STONE", id: 75 },
    { name: "MANUTENCAO TEF", id: 78 },
    { name: "TROCA DE EQUIPAMENTO", id: 80 },
    { name: "TROCA DE CHIP", id: 81 },
    { name: "MANUTENÇÃO", id: 85 },
    { name: "TROCA DIRETA", id: 92 },
    { name: "TROCA DE CHIP DIRETA", id: 94 },
    { name: "TROCA DE PINPAD", id: 96 },
    { name: "ROUBO", id: 97 },
    { name: "PERDA", id: 99 },
    { name: "Troca Técnico", id: 105 },
    { name: "Troca Courier", id: 106 },
    { name: "Troca Emergencial", id: 107 },
    { name: "ATENDIMENTO EXPRESSO", id: 108 },
    { name: "MANUTENÇÃO RPC", id: 112 },
    { name: "MANUTENÇÃO CORBAN", id: 113 },
    { name: "TROCA MEI", id: 121 },
    { name: "ATENDIMENTO EXPRESSO PAG", id: 130 },
    { name: "MANUTENCAO PAG", id: 131 },
    { name: "VISITA TECNICA", id: 133 },
    { name: "ANOMALIA", id: 135 },
    { name: "TROCA DE CABO", id: 139 },
    { name: "TROCA DE FONTE", id: 140 },
    { name: "ATUALIZACAO DE SOFTWARE", id: 141 },
    { name: "TROCA DIRETA S", id: 145 },
    { name: "TROCA DE LEITORA", id: 147 },
    { name: "TROCA DE POS", id: 148 },
    { name: "ALTERACAO DE TECNOLOGIA", id: 149 },
    { name: "ENTREGA AGENCIA", id: 162 },
    { name: "TROCA COURIER", id: 169 },
    { name: "SUBSTITUICAO COURIER", id: 173 },
    { name: "MANUTENCAO VENDA GARANTIA", id: 177 },
    { name: "MANUTENÇÃO VENDA", id: 178 },
    { name: "TROCA CHIP VENDA", id: 181 },
    { name: "ANOMALIA MANUTENCAO AJUSTE OS", id: 185 },
    { name: "TROCA CHIP", id: 189 },
    { name: "TROCA DE CARREGADOR", id: 190 },
    { name: "TROCA DE BATERIA", id: 191 },
  ],
  "Retirada": [
    { name: "DESINSTALACAO", id: 34 },
    { name: "DESINSTALACAO AVANTE", id: 68 },
    { name: "DESINSTALACAO RJ STONE", id: 73 },
    { name: "DESINSTALACAO SP STONE", id: 74 },
    { name: "RETIRADA TEF", id: 79 },
    { name: "DESINSTALAÇÃO", id: 84 },
    { name: "RETIRADA", id: 91 },
    { name: "DESINSTALAÇÃO PINPAD", id: 95 },
    { name: "Desinstalação Técnico", id: 100 },
    { name: "Desinstalação Courier ", id: 101 },
    { name: "DESINSTALACAO TECNICO", id: 109 },
    { name: "DESINSTALAÇÃO RPC", id: 110 },
    { name: "DESINSTALAÇÃO CORBAN", id: 111 },
    { name: "CANCELAMENTO MEI", id: 119 },
    { name: "DESINSTALAÇÃO MEI", id: 120 },
    { name: "DESCREDENCIAMENTO", id: 134 },
    { name: "DESINSTACAO PAG", id: 136 },
    { name: "RETIRADA PORTE PAGO", id: 137 },
    { name: "ANOMALIA RETIRADA", id: 146 },
    { name: "CAÇA POS", id: 150 },
    { name: "RETIRADA CAÇA POS", id: 165 },
    { name: "RETIRADA COURIER", id: 171 },
    { name: "ANOMALIA RETIRADA AJUSTE OS", id: 176 },
    { name: "RETIRADA EM AGENCIAS", id: 179 },
    { name: "DEVOLUÇÃO PORTE PAGO", id: 187 },
    { name: "DEVOLUÇÃO VENDA", id: 188 },
  ],
};

export const ordemMovimentacaoTipo: ISeletorMultiploId[] = [
  { name: 'DOC FISCAL', id: '1' },
  { name: 'CD/CD', id: '2' },
  { name: 'CD/BASE', id: '3' },
  { name: 'BASE/CD', id: '4' },
  { name: 'BASE/BASE', id: '5' },
  { name: 'CD/LAB', id: '6' },
  { name: 'LAB/CD', id: '7' },
  { name: 'LAB/LAB', id: '8' },
  { name: 'CD/AGENCIA', id: '9' },
  { name: 'AGENCIA/CD', id: '10' },
  { name: 'AGENCIA/BASE', id: '11' },
  { name: 'CD/PICKING', id: '12' },
  { name: 'SAPB1-DEVCOMODATO', id: '13' },
]

export const ordemMovimentacaoTipoTransf: ISeletorMultiploId[] = [
  { name: 'GOOD', id: '1' },
  { name: 'BAD', id: '2' },
  { name: 'DOA', id: '3' },
]

export const ordemMovimentacaoStatus: ISeletorMultiploId[] = [
  { name: 'NOVA', id: '1' },
  { name: 'AGUARDANDO RECEBIMENTO', id: '2' },
  { name: 'AGUARDANDO CQ', id: '3' },
  { name: 'APROVADO CQ', id: '4' },
  { name: 'APROVADO DIVERGENCIA', id: '5' },
  { name: 'REJEITADA CQ', id: '6' },
  { name: 'VALIDACAO PENDENTE', id: '7' },
  { name: 'VALIDA RECEBIMENTO', id: '8' },
  { name: 'VALIDACAO OK', id: '9' },
  { name: 'EM TRANSITO', id: '10' },
  { name: 'DIVERGENTE', id: '11' },
  { name: 'DIVERGENCIA RECEBIMENTO', id: '12' },
  { name: 'FECHADA C DIVERGENCIA', id: '13' },
  { name: 'FECHADA', id: '14' },
  { name: 'REJEITADA', id: '15' },
  { name: 'FALHA INTEGRACAO', id: '16' },
  { name: 'DIVERGENCIA CONTRATANTE', id: '17' },
  { name: 'DIVERGENCIA CONTRATANTE RECEBIMENTO', id: '18' },
  { name: 'AGUARDANDO COLETA', id: '19' },
  { name: 'PRE RECEBIMENTO', id: '20' },
  { name: 'AGUARDANDO NF', id: '21' },
  { name: 'NF GERADA', id: '22' },
  { name: 'DIVERGENCIA SAPB1', id: '23' },
]

export const ordemMovimentacaoMotivoMovimentacao: ISeletorMultiploId[] = [
  { name: 'Prevenção do Stock Out de Chips', id: '1' },
  { name: 'Prevenção do Stock Out de Baixo Giro (PINPAD/ETHERNET)', id: '2' },
  { name: 'Prevenção do Stock Out de Alto Giro (GPRS-WIFI)', id: '3' },
  { name: 'Movimentação de Estoque (Supplier)', id: '4' },
  { name: 'Remanejamento de Estoque', id: '5' },
  { name: 'Triangulação Lab Fabricante', id: '6' },
  { name: 'Triangulação Lab Restauração', id: '7' },
  { name: 'Mau Uso', id: '8' },
  { name: 'Funcional', id: '9' },
  { name: 'Restauração/Gabinete', id: '10' },
  { name: 'Restauração/Funcional', id: '11' },
  { name: 'Troca de Gabinete/Mau Uso', id: '12' },
]

export const ordemMovimentacaoModalidade: ISeletorMultiploId[] = [
  { name: 'Convencional', id: '50' },
  { name: 'Emergencial', id: '51' },
  { name: 'Rodoviário Expresso', id: '52' },
]

export const tipoEnderecoLista = [
  "Aeroporto",
  "Alameda",
  "Area",
  "Avenida",
  "Campo",
  "Chácara",
  "Colônia",
  "Condomínio",
  "Conjunto",
  "Distrito",
  "Esplanada",
  "Estação",
  "Estrada",
  "Favela",
  "Fazenda",
  "Feira",
  "Jardim",
  "Ladeira",
  "Lago",
  "Lagoa",
  "Largo",
  "Loteamento",
  "Morro",
  "Núcleo",
  "Parque",
  "Passarela",
  "Pátio",
  "Praça",
  "Quadra",
  "Recanto",
  "Residencial",
  "Rodovia",
  "Rua",
  "Setor",
  "Sítio",
  "Travessa",
  "Trecho",
  "Trevo",
  "Vale",
  "Vereda",
  "Via",
  "Viaduto",
  "Viela",
  "Vila"
]

export const tipoPrestadorLista = [
  'Agência Bancária',
  'Base MEI',
  'Centro de Distribuição',
  'Credenciada',
  'Estoque',
  'Fabricante',
  'Instalador',
  'Laboratório',
  'Laboratório alternativo',
  'Transportadora',
  'Venda'
]

/*Labels para o seletor de ordenação*/
export const listaOrdenarPadrao = [
  "Prioridade",
  "Técnico",
  "Grupo de Serviço",
  "Dt Ger. do Prot. Técnico",
  "Contratante",
];

export const mesesDoAno = [
  {
    name: 'Janeiro',
    id: '1',
    date: moment().month(0).format("YYYY/MM"),
    dateName: moment().month(0).format("MMMM / YYYY"),
  },
  {
    name: 'Fevereiro',
    id: '2',
    date: moment().month(1).format("YYYY/MM"),
    dateName: moment().month(1).format("MMMM / YYYY"),
  },
  {
    name: 'Março',
    id: '3',
    date: moment().month(2).format("YYYY/MM"),
    dateName: moment().month(2).format("MMMM / YYYY"),
  },
  {
    name: 'Abril',
    id: '4',
    date: moment().month(3).format("YYYY/MM"),
    dateName: moment().month(3).format("MMMM / YYYY"),
  },
  {
    name: 'Maio',
    id: '5',
    date: moment().month(4).format("YYYY/MM"),
    dateName: moment().month(4).format("MMMM / YYYY"),
  },
  {
    name: 'Junho',
    id: '6',
    date: moment().month(5).format("YYYY/MM"),
    dateName: moment().month(5).format("MMMM / YYYY"),
  },
  {
    name: 'Julho',
    id: '7',
    date: moment().month(6).format("YYYY/MM"),
    dateName: moment().month(6).format("MMMM / YYYY"),
  },
  {
    name: 'Agosto',
    id: '8',
    date: moment().month(7).format("YYYY/MM"),
    dateName: moment().month(7).format("MMMM / YYYY"),
  },
  {
    name: 'Setembro',
    id: '9',
    date: moment().month(8).format("YYYY/MM"),
    dateName: moment().month(8).format("MMMM / YYYY"),
  },
  {
    name: 'Outubro',
    id: '10',
    date: moment().month(9).format("YYYY/MM"),
    dateName: moment().month(9).format("MMMM / YYYY"),
  },
  {
    name: 'Novembro',
    id: '11',
    date: moment().month(10).format("YYYY/MM"),
    dateName: moment().month(10).format("MMMM / YYYY"),
  },
  {
    name: 'Dezembro',
    id: '12',
    date: moment().month(11).format("YYYY/MM"),
    dateName: moment().month(11).format("MMMM / YYYY"),
  },

]

/*Campo da OS para cada label de ordenação*/

export interface IListaOrdenarValorPadrao {
  "Prioridade": string,
  "OS": string,
  "Referência": string,
  "NomeCliente": string,
  "Data Abertura": string,
  "SLA": string,
  Status: string,
  "Serviço": string,
  "Melhor Equipamento": string,
  Cidade: string,
  Bairro: string,
  CEP: string,
  "Série": string,
  Técnico: string,
  "Chamado": string,
  "Grupo de Serviço": string,
  "Dt Ger. do Prot. Técnico": string,
  "Contratante": string,
}

export const listaOrdenarValorPadrao = {
  "Prioridade": "prioridadeFarol",
  "OS": "chamado",
  "Referência": "referencia",
  "NomeCliente": "nomeFantasia",
  "Data Abertura": "dataAbertura",
  "SLA": "previsaoAtendimento",
  Status: "status",
  "Serviço": "servico",
  "Melhor Equipamento": "melhorEquipamento",
  Cidade: "cidade",
  Bairro: "bairro",
  CEP: "cep",
  "Série": "serie",
  Técnico: "tecnico",
  "Chamado": "chamado",
  "Grupo de Serviço": "grupoServico",
  "Dt Ger. do Prot. Técnico": "dataGeracaoProtocolo",
  "Contratante": "contratante",
};

/*Ordenação selecionada */
export const ordenacaoPadrao = ["Prioridade"];

export const checklistOrdenacaoPorLista = [
  { id: 'dataEnvio', name: "Data/Horário do Envio" },
  { id: 'nomeTecnico', name: "Técnico" },
  { id: 'placaMotoTecnico', name: "Placa da Moto" },
  { id: 'tipoMotoTecnico', name: "Tipo de Moto" },
  { id: 'B9625349-A63C-40FC-842D-1E7D29679BFA', name: "Quilometragem" },
];