import styled from "styled-components";
import { Button } from "@mui/material";
import { siteTheme } from "../../../styles/themeVariables";
import Paper from '@mui/material/Paper';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';

const {color} = siteTheme;

export const Section = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
`;

export const TabelaEquipamentos = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 64px;
  width: 100%;
  border: 1px solid #afb1b1;
  border-radius: 15px;
  overflow: hidden;
`;

export const PaperX = styled(Paper)`
  width: '100%';
  overflow: 'hidden';
`;

export const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
`;

export const TableContainerX = styled(TableContainer)`
  max-height: 553px;

  @media only screen and (max-width: 1366px) {
    max-height: 520px;
  }

  @media (min-width: 1300px){
    max-height: 70vh;
  }
`;

export const TableRowHeaderX = styled(TableRow)`
  display: flex;
  flex: 1;
  min-height: 64px;
  height: 64px;
  background-color: #afb1b1;
`;

export const TableCellHeaderX = styled(TableCell)`
  color: #ffffff !important;
  background-color: inherit !important;
  border-right: 1px solid #ffffff !important;
  /* word-break: break-word !important; */
  height: 64px !important;
  padding: 5px !important;
  min-width: 100px;

  & span {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  &:last-child {
    border-right: none !important;
  }
`;

export const TableRowX = styled(TableRow)`
  min-height: 90px !important;
  height: 90px !important;
`;

export const TabelaRowCol = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  color: ${color.textCinza};
  font-size: 14px;
  border-right: 1px solid #afb1b1;
  word-break: break-word;
`

export const TableRowCellX = styled(TableCell)`
  color: #797D8C !important;
  border-right: 1px solid #afb1b1 !important;
  border-bottom: 1px solid #afb1b1 !important;
  /* word-break: break-word !important; */
  text-align: center;
  padding: 5px;
  min-width: 100px;

  & span {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    padding-right: 8px;

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-button:single-button {
      background-color: #afb1b1;
      display: block;
      height: 18px;
      width: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #afb1b1;
    }

    &::-webkit-scrollbar-track {
      background-color: #f8f8f8;
    }
  }

  &:first-child {
    border-left: 1px solid #afb1b1 !important;
    /* border-right: none !important; */
  }
`;

export const TabelaSemDados = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 250px;
  width: 100%;
`;

export const ButtonWrapper = styled.div`
  margin-top: 27px;
  display: flex;
  justify-content: flex-end;
`;

export const PrintButton = styled(Button)`
  background: ${color.primary} !important;
  font-size: 14px !important;
  height: 46px !important;
  border-radius: 15px !important;

  & .MuiButton-startIcon {
    margin-top: -4px;
    margin-right: 14px;
  }

  & .MuiButton-startIcon {
    font-size: 22px;
  }
`;
