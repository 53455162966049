import React, { useState, Fragment, useEffect } from "react";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { DialogModal } from "./styles";
import { SettingsPowerRounded } from "@mui/icons-material";
import { IModalDialogTitle, IModal } from './types'
import { siteTheme } from "../../styles/themeVariables";

const { color } = siteTheme;

function ModalDialogTitle({ children, onClose, titleStyle, ...other }: IModalDialogTitle) {
  return (
    <DialogTitle sx={titleStyle} {...other}>
      {children}
      {!!onClose && (
        <IconButton
          id={"customized-close-dialogmodal"}
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: titleStyle.color,
          }}
        >
          <CloseIcon />
        </IconButton>
      )}
    </DialogTitle>
  );
}

const Modal = ({
  dialogModalClasses = { paper: "" },
  idModal = "customized-dialogmodal",
  title = "Titulo do modal",
  titleStyle = {},
  sx,
  children,
  isOpen = false,
  handleCloseModal
}: IModal) => {
  const [open, setOpen] = useState(isOpen);

  const handleClose = () => {
    handleCloseModal!()
  };

  useEffect(() => {
    setOpen(isOpen)
  }, [isOpen])

  return (
    <>
      <DialogModal
        classes={dialogModalClasses}
        id={idModal}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        sx={sx}
      >
        <ModalDialogTitle onClose={handleClose} titleStyle={{
          m: 0,
          p: 2,
          padding: "16px 22px",
          bgcolor: color.primary,
          color: "#FFFFFF",
          boxSizing: "border-box",
          height: "67px",
          ...titleStyle,
        }}>
          <>{title}</>
        </ModalDialogTitle>

        {!!children && (
          children
        )}
      </DialogModal>
    </>
  );
};

export default Modal;