import styled from "styled-components";
import { siteTheme } from "../../styles/themeVariables";
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { ReactComponent as EditIcon } from "../../assets/icons/EditIcon.svg";

const { color } = siteTheme;

export const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
`;

export const StyledTableContainer = styled(TableContainer)`
  max-height: 553px;

  @media only screen and (max-width: 1366px) {
    max-height: 520px;
  }

  @media (min-width: 1300px){
    max-height: 70vh;
  }
`;

export const StyledTableRowHeader = styled(TableRow)`
  display: flex;
  flex: 1;
  min-height: 64px;
  height: 64px;
  background-color: ${color.cinzaClaro};
`;

export const StyledTableCellHeader = styled(TableCell)`
  color: #ffffff !important;
  background-color: inherit !important;
  border-right: 1px solid #ffffff !important;
  height: 60px !important;
  padding: 5px !important;
  min-width: 100px;

  & span {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  &:last-child {
    border-right: none !important;
  }
`;

export const StyledTableRowBody = styled(TableRow)`
  min-height: 60px !important;
  height: 60px !important;

  &:last-child {
    > :first-child {
      border-radius: 0 0 0 15px;
    }
    > :last-child {
      border-radius: 0 0 15px 0;
    }
  }
`;

export const StyledTableRowCellBody = styled(TableCell)`
  color: ${color.textCinza} !important;
  border-right: 1px solid ${color.cinzaClaro} !important;
  border-bottom: 1px solid ${color.cinzaClaro} !important;
  text-align: center;
  padding: 5px;
  min-width: 100px;

  & span {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  &:first-child {
    border-left: 1px solid ${color.cinzaClaro} !important;
  }
`;

export const EditIconStyled = styled(EditIcon)`
  &:hover {
    height: 30px;
    width: 30px;
  }
`;