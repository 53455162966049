import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

export const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.common.white,
    color: '#797D8C',
    boxShadow: theme.shadows[2],
    fontSize: 14,
    height: '60px',
    borderRadius: '15px',
    padding: '5px 15px'
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white,
    [`&:before`]: {
      boxShadow: theme.shadows[2],
      fontSize: 11,
    },
  },
}));