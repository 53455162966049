import React, { useState, useEffect, useContext, useRef } from "react";
import {
  Section,
  SearchButton,
  Body,
  NewParametroButton,
} from "./consultaParametro_styles";
import "./consultaParametro.scss";
import Header from "../../../components/header/header";
import {
  Breadcrumb,
  BreadcrumbItem,
} from "../../../components/breadcrumb/breadcrumb";
import { HomeContext } from "../../../contexts/homeContext";
import { useNavigate } from "react-router-dom";
import StickyTable from "../../../components/stickyTable/stickyTable";
import { CircularProgress } from '@mui/material';
import Pagination from '../../../components/pagination/paginacao';
import { AuthContext } from "../../../contexts/auth";
import { getContratantesNovo } from "../../farol/services";
import { ResponseApi } from "../../../contexts/types/responsesHttp";
import { Grupo_Servico, IServico } from "../../../contexts/types/farolTypes";
import { getGroup_Services } from "../../grupoServico/services/services";
import { getListaServicosComFiltro } from "../../servicos/services";
import { IContratantesDTO } from "../../contratante/types";
import AutocompleteMultiple from "../../../components/autocompleteMultiple/autocompleteMultiple";
import { IGetContratanteServicoParametros, IGetResponseContratanteServicoParametro, IGetResponseContratanteServicoParametros, IGetResponseParametro } from "../types";
import { IAutocompleteValue } from "../../../components/autocompleteMultiple/types";
import { getContratanteServicoParametrosComFiltro, getParametros } from "../services/services";
import { AxiosResponse } from "axios";
import { smoothScroll } from "../../../helpers/smoothScroll";
import { getPrestadoresAtivos } from '../../farol/services';
import { IGetResponsePrestadoresOrdemServico } from "../../farol/types";
import _ from "lodash";

const ConsultaParametro = () => {
  const { setMenuLateral, setContratanteServicoParametroSelecionado: setContratanteServicoParametroSelecionado } = useContext(HomeContext);

  const { user, funcionalidadeDaTelaTemPermissao } = useContext(AuthContext);
  const profileHasPermission = (funcionalidade: string) => funcionalidadeDaTelaTemPermissao("Consultar Parâmetros", funcionalidade);

  const navigate = useNavigate();

  const [disableActions, setDisableActions] = useState(false);

  const [listaContratantes, setListaContratantes] = useState<any[]>([]);
  const [listaPrestadores, setListaPrestadores] = useState<any[]>([]);
  const [listaGrupoServico, setListaGrupoServico] = useState<Grupo_Servico[]>([]);
  const [listaServicos, setListaServicos] = useState<IServico[]>([]);
  const [listaParametros, setListaParametros] = useState<IAutocompleteValue>([]);

  const [filtroContratante, setFiltroContratante] = useState<any[]>([]);
  const [filtroPrestadores, setFiltroPrestadores] = useState<any[]>([]);
  const [filtroGrupoServico, setFiltroGrupoServico] = useState<any[]>([]);
  const [filtroServico, setFiltroServico] = useState<any[]>([]);
  const [filtroParametro, setFiltroParametro] = useState<IAutocompleteValue>([]);

  const [dadosConsultaParametro, setDadosConsultaParametro] = useState<any[]>([]);

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [loading, setLoading] = useState<boolean>(false);
  const [qtdeRegistros, setQtdeRegistros] = useState<number>(0);

  const [tipomsgAlert, setTipoMsgAlert] = useState<'success' | 'info' | 'warning' | 'error'>("error");
  const [msgAlert, setMsgAlert] = useState("OS encaminhada com  sucesso!");
  const [openAlert, setOpenAlert] = useState(false);

  const handleButtonSearch = () => {
    currentPage !== 1 ? setCurrentPage(1) : handleSearch();
  };

  const handleSearch = async () => {
    setLoading(true);
    setDisableActions(true);

    const params: IGetContratanteServicoParametros = {
      ...(!!filtroContratante.length && !!filtroContratante[0]?.id ? { 'IdContratante': Number(filtroContratante[0].id) } : {}),
      ...(!!filtroPrestadores.length && !!filtroPrestadores[0]?.id ? { 'IdPrestador': Number(filtroPrestadores[0].id) } : {}),
      ...(!!filtroGrupoServico.length && !!filtroGrupoServico[0]?.id ? { 'IdGrupoServico': Number(filtroGrupoServico[0].id) } : {}),
      ...(!!filtroServico.length && !!filtroServico[0]?.id ? { 'IdServico': Number(filtroServico[0].id) } : {}),
      ...(!!filtroParametro.length && !!filtroParametro[0]?.id ? { 'IdParametro': Number(filtroParametro[0].id) } : {}),
      PageNumber: currentPage,
      PageSize: pageSize
    };

    try {
      const { data }: { data: IGetResponseContratanteServicoParametros } = await getContratanteServicoParametrosComFiltro(params) as AxiosResponse;
      setLoading(false);
      setDisableActions(false);
      setDadosConsultaParametro(data?.data?.items ?? []);
      setQtdeRegistros(data?.data?.totalCount ?? 0);
    } catch (error) {
      setLoading(false);
      setDisableActions(false);
      setDadosConsultaParametro([]);
      setQtdeRegistros(0);
      setTipoMsgAlert("error");
      setMsgAlert("Nenhum dado encontrado");
      setOpenAlert(true);
    }
  };

  const getItemByID = (ID: number | string) => {
    let resultadoDados = [...dadosConsultaParametro];

    return resultadoDados.find(
      (item) => item.id === ID
    )!;
  };

  const handleSetFiltroGrupoServico = (value: any) => {
    setFiltroGrupoServico(value);
    setFiltroServico([]);
    handleGetServicosPorGrupo(Number(value[0]?.id));
  }

  const handleSetFiltroServico = (value: any) => {
    setFiltroServico(value);
  }

  const handleClickItemEdit = (data: IGetResponseContratanteServicoParametro) => {
    setContratanteServicoParametroSelecionado(getItemByID(data.id));
    navigate("/EditarParametro");
  };

  const objTraducaoHeader = {
    id: "ID",
    contratante: "Contratante",
    prestador: "Prestador",
    grupoServico: "Grupo de Serviço",
    servico: "Serviço",
    parametro: "Parâmetro",
    valor: "Valor",
    observacao: "Observação",
    mensagemErro: "Mensagem de erro",
    ativo: "Status"
  } as any;

  const dadosFormatados = () => {
    let dados = [...dadosConsultaParametro];

    dados = dados.map((item: any) => {
      return {
        id: item.id,
        ativo: !!item.ativo ? "Ativo" : "Inativo",
        contratante: item?.contratante || "",
        prestador: item?.prestador || "",
        grupoServico: item?.grupoServico || "",
        servico: item?.servico || "",
        parametro: item?.parametro || "",
        valor: item?.valor || "",
        observacao: item?.observacao || "",
        mensagemErro: item?.mensagemErro || "",
      };
    });

    return dados;
  };

  const paginacaoLocal = (dados: any[]) => {
    const firstPageIndex = (currentPage - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;
    return dados.slice(firstPageIndex, lastPageIndex);
  };

  const localGetContratantes = async () => {
    return await getContratantesNovo()
      .then((res: any) => {
        const respSuccess = res?.data as ResponseApi<IContratantesDTO[]> | null;

        if (respSuccess) {
          const listaContratante = respSuccess.data?.map((item: any) => { return { name: item.nome, id: item.id?.toString() ?? "", idOppay: item.id_Oppay?.toString() ?? "" } }) || [];
          setListaContratantes(filtraContratantesDoUsuario(listaContratante));
        };

      }, err => {
        const respErr = err?.response?.data as ResponseApi<IContratantesDTO[]>;
        if (respErr) setListaContratantes([]);
      });
  };

  const filtraContratantesDoUsuario = (contratantes: IAutocompleteValue) => {
    let options = [...contratantes];

    if (!!user?.contratanteOrdemServico) {
      options = options.filter((o: any) => Number(o.idOppay) === user.contratanteOrdemServico);

      setFiltroContratante(options);
    }

    return options;
  };

  const handleGetGruposServicos = async () => {
    await getGroup_Services({
      Ativo: true,
    }).then((resp: any) => {
      const respSuccess = resp?.data as ResponseApi<Grupo_Servico[]>;
      const grupos = respSuccess?.data as any[] ?? [];
      setListaGrupoServico(grupos);
    }, err => {
      const respErr = err?.response?.data as ResponseApi<any>;
      // Implementar tratamento de erro
    });
  };

  const handleGetServicosPorGrupo = async (idGrupoServico: number) => {

    await getListaServicosComFiltro({ IdGrupoServico: idGrupoServico })
      .then((resp: any) => {
        const respSuccess = resp?.data as ResponseApi<IServico[]>;
        const servicos = respSuccess?.data as any[] ?? [];
        setListaServicos(servicos);
      }, err => {
        const respErr = err?.response?.data as ResponseApi<any>;
        // Implementar tratamento de erro
      });
  };

  const getProvider = async () => {
    try {
      const { data } : { data: IGetResponsePrestadoresOrdemServico } = await getPrestadoresAtivos() as AxiosResponse;

      let prestadores: IAutocompleteValue = [];

      if (!!data?.success && !_.isEmpty(data?.data?.prestadores)) {
        prestadores = data?.data?.prestadores.map((m) => ({ ...m, name: m.nomePrestador, id: m.id?.toString() }))
          .sort((a: any, b: any) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
      };

      setListaPrestadores(filtraPrestadoresDoUsuario(prestadores));

    } catch (e) {
      setListaPrestadores([]);
    }
  };

  const filtraPrestadoresDoUsuario = (prestadores: any) => {
    let options: any[] = [...prestadores];

    if (!!user?.prestadores && !!user.prestadores.length) {
      options = options.filter((o: any) => user.prestadores.includes(Number(o.id)));

      /**Auto preenche o filtro prestadores */
      if (user.prestadores.length === 1) {
        setFiltroPrestadores(options);
      }
    }

    return options;
  };

  const handleParametros = async () => {
    try {

      await getParametros().then((resp: any) => {
        const respSuccess = resp?.data as ResponseApi<IGetResponseParametro[]> | null;

        if (respSuccess) {
          const parametros = respSuccess.data?.map((item: any) => { return { name: item.nome, id: item.id?.toString() ?? "" } }) || [];
          setListaParametros(parametros);
        };
      });
    } catch (e) {
      setListaParametros([])
    }
  }

  const firstUpdate = useRef(true);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    handleSearch();
  }, [currentPage, pageSize]);

  useEffect(() => {
    localGetContratantes();
    getProvider();
    handleGetGruposServicos();
    handleParametros();
    setContratanteServicoParametroSelecionado({} as IGetResponseContratanteServicoParametro)
  }, []);

  useEffect(() => {
    if (!!dadosConsultaParametro.length) smoothScroll(document.getElementById("TabelaResultadoConsultaParametro"));
  }, [dadosConsultaParametro]);

  return (
    <Section id={"ConsultaParametro"}>
      <Header setMenuLateral={() => setMenuLateral(true)} title="Parâmetro" />
      <Breadcrumb>
        <BreadcrumbItem>Parâmetro</BreadcrumbItem>
      </Breadcrumb>

      <Body>
        <div className="grid1">
          <AutocompleteMultiple
            dimension="sm"
            label={"Contratante"}
            placeholder={""}
            noOptionsText="Nenhum contrato encontrado"
            options={listaContratantes}
            value={filtroContratante}
            onChange={setFiltroContratante}
            id="autocomplete-contrato"
            multiple={false}
            disabled={!!user?.contratanteOrdemServico}
          />

          <AutocompleteMultiple
            label={"Prestador"}
            placeholder={""}
            noOptionsText={"Nenhum prestador encontrado"}
            id="autocomplete-prestador"
            options={listaPrestadores}
            value={filtroPrestadores}
            onChange={setFiltroPrestadores}
            disabled={(!!user?.prestadores && user.prestadores.length === 1)}
            multiple={false}
            dimension='sm'
            limitTags={2}
          />

          <AutocompleteMultiple
            dimension="sm"
            label={"Grupo de serviço"}
            placeholder={""}
            noOptionsText="Nenhum grupo de serviço encontrado"
            options={listaGrupoServico?.map((item: any) => ({
              name: item.nome,
              id: item.id.toString(),
            }))!}
            value={filtroGrupoServico}
            onChange={handleSetFiltroGrupoServico}
            id="autocomplete-grupoServico"
            multiple={false}
          />

          <AutocompleteMultiple
            dimension="sm"
            label={"Serviço"}
            placeholder={""}
            noOptionsText="Nenhum serviço encontrado"
            options={listaServicos?.map((item: any) => ({
              name: item.nome,
              id: item.id.toString(),
            }))!}
            value={filtroServico}
            onChange={handleSetFiltroServico}
            id="autocomplete-servico"
            multiple={false}
            disabled={disableActions || filtroGrupoServico.length === 0}
          />
        </div>

        <div className="grid2">
          <AutocompleteMultiple
            dimension="sm"
            label={"Parâmetro"}
            placeholder={""}
            noOptionsText={"Nenhum Parâmetro encontrado"}
            options={listaParametros}
            value={filtroParametro}
            onChange={setFiltroParametro}
            id="autocomplete-parametro"
            multiple={false}
            disabled={disableActions}
          />

          <SearchButton
            variant="contained"
            size="small"
            onClick={handleButtonSearch}
            disabled={disableActions}
          >
            Buscar
          </SearchButton>

          {!!profileHasPermission("Novo Registro") ?
            <NewParametroButton
              color="secondary"
              variant="contained"
              size="small"
              onClick={() => navigate("/IncluirParametro")}
              disabled={disableActions}
            >
              Incluir
            </NewParametroButton>
            :
            <NewParametroButton
              color="secondary"
              variant="contained"
              size="small"
              disabled={true}
              className="bloqueado"
            >
              Incluir
            </NewParametroButton>
          }
        </div>
      </Body>

      {!!loading && <CircularProgress style={{ margin: "0px auto" }} />}

      {!!dadosConsultaParametro.length && (
        <>
          <StickyTable
            id={"TabelaResultadoConsultaParametro"}
            data={dadosFormatados()}
            handleClickEdit={handleClickItemEdit}
            objTraducaoHeader={objTraducaoHeader}
            allowEdit={!!profileHasPermission("Editar Registro")}
            edit={true}
            labelEdit={"Detalhes"}
          />

          <Pagination
            className="pagination-bar"
            currentPage={currentPage}
            totalCount={qtdeRegistros}
            pageSize={pageSize}
            setPageSize={(value: string) => setPageSize(Number(value))}
            onPageChange={(page: number) => setCurrentPage(Number(page))}
            showLabel={false}
          />
        </>
      )}
    </Section>
  );
};

export default ConsultaParametro;
