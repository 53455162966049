import React, { useState, useEffect, useContext } from 'react'
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import './geralTab.scss'
import Input, { MaskedInput } from '../../../components/Input/Input';
import MultipleSelectCheckmarksId from '../../../components/seletorMultiploId/seletorMultiploId'
import { maskTelefone } from '../../../middlewares/masks';
import { buscaCEP, buscaCidades, buscaUFs } from '../services/services';
import { AxiosResponse } from 'axios';
import _ from 'lodash'
import { tipoEnderecoLista, tipoPrestadorLista } from '../../../contexts/helpers/dadosPadrao'
import MultipleSelectCheckmarks from '../../../components/seletorMultiplo/seletorMultiplo'
import FullscreenLoading from '../../../components/fullscreenLoading/fullscreenLoading';
import { HomeContext } from '../../../contexts/homeContext';

const GeralTab = (params: {isIncluding: boolean}) => {

  const { isIncluding } = params

  const { 
    prestadorSelected,
    pessoa,
    setPessoa,
    documento, 
    setDocumento,
    inscricaoMunicipal,
    setInscricaoMunicipal,
    tipoPrestador, 
    setTipoPrestador,
    prestadorRetorno, 
    setPrestadorRetorno,
    prestadorPai, 
    setPrestadorPai,
    contato, 
    setContato,
    email,
    setEmail,
    telefone,
    setTelefone,
    tipoEndereco,
    setTipoEndereco,
    endereco,
    setEndereco,
    numero,
    setNumero,
    complemento,
    setComplemento,
    bairro,
    setBairro,
    CEP,
    setCEP,
    pais,
    setPais,
    estadoLista,
    setEstadoLista,
    estado,
    setEstado,
    cidadeLista,
    setCidadeLista,
    cidade,
    setCidade,
    nomeCidade,
    setNomeCidade,
    transportadora,
    setTransportadora,
    tipoLaboratorio,
    setTipoLaboratorio,
    diasEntrega,
    setDiasEntrega,
    emailError,
    setEmailError
   } = useContext(HomeContext)  

  const [ loading, setLoading ] = useState(false)

  const tipoPrestadorLocal = !isIncluding ?
  [tipoPrestadorLista.find(item => item?.toLowerCase() === prestadorSelected?.tipo?.toLowerCase())] :
  []

  const handleChangeCEP = async (e: any) => {
    setLoading(true)
    setCEP(e.target.value.replace(/\D/g, ""))
    if(e.target.value.replace(/\D/g, "").length === 8){
      try{
        const { data } = await buscaCEP(e.target.value.replace(/\D/g, "")) as AxiosResponse
        
        setBairro(data?.bairro)
        setTipoEndereco([tipoEnderecoList.find(
            item => item.id === data?.logradouro.substring(0, data?.logradouro.indexOf(' ')))?.id!])
        setEndereco(data?.logradouro.substring(data?.logradouro.indexOf(' ') + 1))

        const estadoId = estadoLista.find(estado => 
          estado.sigla.toLowerCase() === data?.uf.toLowerCase())?.id

        setEstado([estadoId!])
        setPais('Brasil')
        setNomeCidade(data?.localidade.toLowerCase())

      } catch(e){
        console.log(e)
      }
    }
    setLoading(false)
  }

  const handleGetUFs = async () => {
    try {
      const { data } = await buscaUFs() as AxiosResponse
      
      setEstadoLista(data.map((item: {id: number, nome: string, sigla: string}) => {
        return { name: item.nome, id: item.id.toString(), sigla: item.sigla.toLowerCase() }
      }).sort(
        (a: {name: string, id: string},b: {name: string, id: string}) => 
          (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)
      ))
    } catch (e){
      console.log(e)
    }
  }

  const handleGetCidades = async () => {
    try{
      const { data } = await buscaCidades(estado[0]) as AxiosResponse

      setCidadeLista(data.map((item: {id: number, nome: string}) => {
        return { name: item.nome, id: item.id.toString() }
      }).sort(
        (a: {name: string, id: string},b: {name: string, id: string}) => 
          (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)
      ))

    } catch (e){
      console.log(e)
    }
  }

  const handleBlurEmail = (e: any) => {
    const isValid = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(e.target.value)

    if(!isValid && e.target.value.length !== 0){
      setEmailError(true)
    } else {
      setEmailError(false)
    }
  }

  const laboratorioList = [
    {
      name: 'Reparo fabricante',
      id: 'Reparo fabricante'
    },
    {
      name: 'Reparo estético',
      id: 'Reparo estético'
    },
    {
      name: 'Reparo estético e reparo fabricante',
      id: 'Reparo estético e reparo fabricante'
    },
    {
      name: 'Reparo mau uso',
      id: 'Reparo mau uso'
    },
    {
      name: 'Reparo gabinete',
      id: 'Reparo gabinete'
    },
    {
      name: 'Reparo gabinete e funcional',
      id: 'Reparo gabinete e funcional'
    },
  ]

  const docLabel = () => {
    if(_.isEmpty(pessoa)) return "CPF / CNPJ"
    if(pessoa === 'pj') return 'CNPJ'
    return "CPF"
  }

  const tipoEnderecoList = tipoEnderecoLista.map(item => {return {name: item, id: item}})

  useEffect(() => {
    setTipoPrestador(tipoPrestadorLocal!)
    handleGetUFs()
    if(!isIncluding){
      setPessoa(prestadorSelected?.tipoPessoa === "JURÍDICA" ? 'pj' : 'pf')
      setDocumento(prestadorSelected?.documento?.replace(/\D/g, "") || "")
      setInscricaoMunicipal(prestadorSelected?.inscricaoEstadual || "")
      setContato(prestadorSelected?.contato || "")
      setEmail(prestadorSelected?.email || "")
      setTelefone(prestadorSelected?.telefone?.replace(/\D/g, "") || "")
      setCEP(prestadorSelected?.cep || "")
      setTipoEndereco(tipoEnderecoList?.find(item => item.name === prestadorSelected.tipoEndereco) || [])
      setEndereco(prestadorSelected.endereco || "")
      setNumero(prestadorSelected.numero || "")
      setComplemento(prestadorSelected.complemento || "")
      setBairro(prestadorSelected.bairro || "")
      setPais(prestadorSelected.pais || "")
      const cidadeId = cidadeLista.find(cidade => 
        cidade.name.toLowerCase() === nomeCidade.toLowerCase())?.id
      setCidade([cidadeId!])
      setTransportadora(prestadorSelected.transportadora || "")
      // setTipoLaboratorio() 
      // setDiasEntrega()
    }
  }, [])

  useEffect(() => {
    setCidade([])
    if(!_.isEmpty(estado)) handleGetCidades()

  }, [estado])

  useEffect(() => {
    if(!!nomeCidade){
      const cidadeId = cidadeLista.find(cidade => 
        cidade.name.toLowerCase() === nomeCidade.toLowerCase())?.id
      setCidade([cidadeId!])
    }
  }, [cidadeLista])

  return(
    <div className='GeralTabContainer'>
      <div className='NormalGrid'>
        <FormControl>
          <RadioGroup
            row
            name="radio-buttons-pessoa"
            value={pessoa}
            onChange={(e) => setPessoa(e.target.value)}
          >
            <FormControlLabel value="pf" control={<Radio />} label="Pessoa Física" />
            <FormControlLabel value="pj" control={<Radio />} label="Pessoa Júridica" />
          </RadioGroup>
        </FormControl>
        <MaskedInput
         value={documento} 
         onChange={(e) => setDocumento(e.target.value)} 
         label={docLabel()}
         dimension='sm' 
         mask={pessoa === 'pf' ? '999.999.999-99' : ' 99.999.999/9999-99'}
         id='CPFCNPJInput'
         readOnly={!pessoa}
         maskChar={''}
        />

        <Input
         value={inscricaoMunicipal} 
         onChange={(e) => setInscricaoMunicipal(e.target.value.replace(/\D/g, ""))} 
         label='Inscrição Estadual' 
         dimension='sm'
         id='InscricaoEstadual'
        />
        <MultipleSelectCheckmarks
        options={tipoPrestadorLista} 
        value={tipoPrestador}
        onChange={(e: string[]) => setTipoPrestador(e)}
        id="multiple-checkbox-tipo-prestador"
        idOption="multiple-checkbox-option-tipo-prestador-"
        labelId="multiple-checkbox-label-tipo-prestador"
        multiple={false}
        dimension='sm'
        label="Tipo Prestador"
        />
        <MultipleSelectCheckmarksId
        nodata={"Nenhum prestador de retorno encontrado"}
        options={[]} 
        value={prestadorRetorno}
        onChange={(e: string[]) => setPrestadorRetorno(e)}
        id="multiple-checkbox-prestador-retorno"
        idOption="multiple-checkbox-option-prestador-retorno-"
        labelId="multiple-checkbox-label-prestador-retorno"
        multiple={false}
        className="IncluirEditarModeloSelectPrestadorRetorno"
        dimension='sm'
        label="Prestador de Retorno"
        />
        <MultipleSelectCheckmarksId
        nodata={"Nenhum prestador pai encontrado"}
        options={[]} 
        value={prestadorPai}
        onChange={(e: string[]) => setPrestadorPai(e)}
        id="multiple-checkbox-prestador-pai"
        idOption="multiple-checkbox-option-prestador-pai-"
        labelId="multiple-checkbox-label-prestador-pai"
        multiple={false}
        className="IncluirEditarModeloSelectPrestadorPai"
        dimension='sm'
        label="Prestador Pai"
        />

        <Input 
         value={contato}
         onChange={(e) => setContato(e.target.value)}
         label='Contato' 
         dimension='sm'
         id='Contato'
        />
        <Input
         value={email}
         onChange={(e) => setEmail(e.target.value)}
         onBlur={handleBlurEmail}
         label='Email' 
         dimension='sm'
         error={emailError}
         helperText={emailError && "Email inválido"}
         onFocus={() => setEmailError(false)}
         id="Email"
        />
        <Input
         value={telefone} 
         onChange={(e) => setTelefone(maskTelefone(e.target.value))}
         label='Número Telefone' 
         dimension='sm' 
         id='telefoneInput'
         maxLength={15}
        />
      </div>
      <div className='EnderecoGrid'>
        <MaskedInput
         value={CEP} 
         onChange={handleChangeCEP} 
         label='CEP' 
         dimension='sm' 
         mask='99999-999'
         id='EnderecoCEP'
         maskChar={''}
        />
        <MultipleSelectCheckmarksId
        nodata={"Nenhum tipo de endereço encontrado"}
        options={tipoEnderecoList} 
        value={tipoEndereco}
        onChange={(e: string[]) => setTipoEndereco(e)}
        id="multiple-checkbox-tipo-endereco"
        idOption="multiple-checkbox-option-tipo-endereco-"
        labelId="multiple-checkbox-label-tipo-endereco"
        multiple={false}
        className="IncluirEditarModeloSelectTipoEndereco"
        dimension='sm'
        label="Tipo de Endereço"
        />
        <Input
         value={endereco}
         onChange={(e) => setEndereco(e.target.value)}
         label='Endereço' 
         dimension='sm'
         id='Endereco'
        />
        
      </div>
      <div className='NormalGrid'>
        <Input
         value={numero}
         onChange={(e) => setNumero(e.target.value.replace(/\D/g, ""))}
         label='Número' 
         dimension='sm'
         id='EnderecoNumero'
        />
        <Input
         value={complemento}
         onChange={(e) => setComplemento(e.target.value)}
         label='Complemento' 
         dimension='sm'
         id='EnderecoComplemento'
        />
        <Input
         value={bairro}
         onChange={(e) => setBairro(e.target.value)}
         label='Bairro'
         dimension='sm'
         id='EnderecoBairro'
        />
        

        <Input
         value={pais}
         onChange={(e) => setPais(e.target.value)}
         label='País' 
         dimension='sm'
         id='EnderecoPais'
        />
        <MultipleSelectCheckmarksId
        nodata={"Nenhum tipo de endereço encontrado"}
        options={estadoLista} 
        value={estado}
        onChange={(e: string[]) => setEstado(e)}
        id="multiple-checkbox-tipo-endereco"
        idOption="multiple-checkbox-option-tipo-endereco-"
        labelId="multiple-checkbox-label-tipo-endereco"
        multiple={false}
        className="IncluirEditarModeloSelectTipoEndereco"
        dimension='sm'
        label="Estado"
        />
        <MultipleSelectCheckmarksId
        nodata={"Nenhum tipo de endereço encontrado"}
        options={cidadeLista} 
        value={cidade}
        onChange={(e: string[]) => setCidade(e)}
        id="multiple-checkbox-tipo-endereco"
        idOption="multiple-checkbox-option-tipo-endereco-"
        labelId="multiple-checkbox-label-tipo-endereco"
        multiple={false}
        className="IncluirEditarModeloSelectTipoEndereco"
        dimension='sm'
        label="Cidade"
        readOnly={_.isEmpty(cidadeLista)}
        />

        <Input
         value={transportadora}
         onChange={(e) => setTransportadora(e.target.value)}
         label='Transportadora' 
         dimension='sm'
         id='Transportadora'
        />
        <MultipleSelectCheckmarksId
        nodata={"Nenhum tipo de endereço encontrado"}
        options={laboratorioList} 
        value={tipoLaboratorio}
        onChange={(e: string[]) => setTipoLaboratorio(e)}
        id="multiple-checkbox-tipo-endereco"
        idOption="multiple-checkbox-option-tipo-endereco-"
        labelId="multiple-checkbox-label-tipo-endereco"
        multiple={false}
        className="IncluirEditarModeloSelectTipoEndereco"
        dimension='sm'
        label="Tipo de Laboratório"
        />
        <Input
         value={diasEntrega}
         onChange={(e) => setDiasEntrega(e.target.value)}
         label='Dias de Entrega' 
         dimension='sm'
         id='DiasEntrega'
        />
      </div>
      {loading && <FullscreenLoading />}
    </div>
  )
}

export default GeralTab