import React, { useState, useContext, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as yup from "yup";
import "./verify2FA.scss";
import LoginHeader from "./loginHeader";
import { AuthContext } from "../../../contexts/auth";
import { AxiosResponse } from "axios";
import FullscreenLoading from "../../../components/fullscreenLoading/fullscreenLoading";
import { verifiy2FA } from "../services/services";
import AlertMessage from "../../../components/AlertMessage/alertMessage";
import { Button, Box } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import _ from "lodash";
import { IPostUserLoginResponse } from "../types/types";

interface ICodeVerify {
    code: string
}

const Verify2FA = () => {
    const navigate = useNavigate();

    const { handleSetUserData, loginData } = useContext(AuthContext);
    const [loading, setLoading] = useState(false);
    const [tipomsgAlert, setTipoMsgAlert] = useState<'success' | 'info' | 'warning' | 'error'>("error");
    const [msgAlert, setMsgAlert] = useState("OS encaminhada com  sucesso!");
    const [openAlert, setOpenAlert] = useState(false);
    const [disableActions, setDisableActions] = useState(false);

    const handleClickCodeVerify = async (values: ICodeVerify) => {
        setLoading(true);
        setDisableActions(true);
        setOpenAlert(false);

        try {
            const { data }: { data: IPostUserLoginResponse } = await verifiy2FA({ username: (loginData.username || ""), code: values.code }) as AxiosResponse;

            setLoading(false);
            setDisableActions(false);
            setMsgAlert("Código verificado com sucesso!");
            setTipoMsgAlert("success");
            setOpenAlert(true);

            setTimeout(() => {
                handleSetUserData({ ...data, username: (loginData.username || "") });
            }, 2000);

        } catch (error: any) {
            if (!!error?.response?.data && typeof error?.response?.data === "string") {
                setMsgAlert(error?.response?.data);
            } else {
                setMsgAlert("Erro ao verificar o código, tente novamente em alguns instantes.");
            }
            setLoading(false);
            setDisableActions(false);
            setTipoMsgAlert("error");
            setOpenAlert(true);
        }
    };

    const validationCodeVerify = yup.object().shape({
        code: yup.string().required("Este campo é obrigatório"),
    });

    const switchLabelTipo2FA = (tipo2FA: number) => {
        switch (tipo2FA) {
            case 1:
                return "Insira o código de autenticação enviado por Email";
            case 2:
                return "Insira o código de autenticação enviado por SMS";
            default:
                return "Insira o código de autenticação que lhe foi enviado";
        }
    };

    useEffect(() => {
        if (_.isEmpty(loginData)) navigate("/Login");
    }, []);

    return (
        <div className="Verify2FA" id="Verify2FA">
            <LoginHeader />
            {loading && <FullscreenLoading />}

            <div className="Verify2FABox">
                <div className="Verify2FABoxWrapper">
                    <div className="Verify2FABox-texto">Validar autenticação</div>

                    <Formik
                        initialValues={{ code: "" }}
                        onSubmit={handleClickCodeVerify}
                        validationSchema={validationCodeVerify}
                    >
                        <Form className="Verify2FA-form">
                            <div className="Verify2FA-form-group">
                                <div className="Verify2FA-form-group-texto">
                                    {switchLabelTipo2FA(loginData.tipo2FA)}
                                </div>
                                <Field
                                    id="code-form-inputcode"
                                    name="code"
                                    className="form-field"
                                    placeholder=""
                                    disabled={disableActions}
                                />
                                <ErrorMessage
                                    component="span"
                                    name="code"
                                    className="form-error"
                                />
                            </div>

                            <button
                                id="buttoncode"
                                className="Verify2FA-form-button"
                                type="submit"
                                disabled={disableActions}
                            >
                                Verificar código
                            </button>

                            <Box display="flex" justifyContent="center">
                                <Link to="/Login">
                                    <Button
                                        align-items="center"
                                        size="small"
                                        variant="text"
                                        startIcon={<ArrowBackIcon />}
                                    >
                                        Voltar ao Login
                                    </Button>
                                </Link>
                            </Box>
                        </Form>
                    </Formik>
                </div>

                <AlertMessage
                    isOpenAlert={openAlert}
                    setOpenAlert={setOpenAlert}
                    alertType={tipomsgAlert}
                    msgAlert={msgAlert}
                />
            </div>
        </div>
    );
};

export default Verify2FA;
