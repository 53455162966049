import React, { useState, useContext, useEffect } from "react";
import _ from "lodash";
import "./farolPopoverFiltros.scss";
import { getPrestadores } from "../../enviarTecnico/services/services";
import Popover from "@mui/material/Popover";
import Button from "@mui/material/Button";
import { HomeContext } from "../../../contexts/homeContext";
import { IFarolOSPopoverFiltros } from "../types";
import { getGrouped_Services } from "../services";
import { AxiosResponse } from "axios";
import { GrupoServico, Grupo_Servico, IServico } from "../../../contexts/types/farolTypes";
import { filtroStatusPadrao } from "../../../contexts/helpers/dadosPadrao";
import AutocompleteMultiple from "../../../components/autocompleteMultiple/autocompleteMultiple";
import { AuthContext } from "../../../contexts/auth";
import { getPrestadoresAtivos } from '../../farol/services'
import { getListaTecnicosByPrestador, getListaTecnicosByLotePrestador } from "../../encaminharOS/services/services";
import { ITecnicos } from "../../../contexts/types/encaminharTypes";
import { getEstados, getCidades } from "../../prestador/services/services";
import { IGetResponseEstados, IGetResponseCidades } from "../../prestador/types";
import { ResponseApi } from "../../../contexts/types/responsesHttp";
import Presets from "../../../components/presets/presets";
import { isJson } from "../../../helpers/isJSON";
import { removeSpecialCharSimple } from "../../../helpers/removeSpecialCharSimple";
import { MaskedInput } from "../../../components/Input/Input";

const FarolOSPopoverFiltros = ({
  id = "FarolOS-PopoverFiltro",
  open = false,
  onClose,
  setCurrentPage,
  isFilterFromGrid,
  IsFieldBuscaRapida,
  resetFiltrosPopover,
  anchorEl = null,
  anchorOrigin = {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin = {
    vertical: "top",
    horizontal: "center",
  },
}: IFarolOSPopoverFiltros) => {
  const {
    listaStatus,
    filtroStatus,
    setFiltroStatus,
    listaContratante,
    filtroContratante,
    setFiltroContratante,
    filtroPrestadores,
    
    setFiltroPrestadores,
    listaGrupoServico,
    setFiltroGrupoServico,
    filtroServicoById,
    setFiltroServicoById,
    setListaGrupoServico,
    handleSetOSSelected,
    setFiltroBuscaRapida,
    listaTecnicosByPrestadorTratada,
    setListaTecnicos,
    tecnico,
    setTecnico,
    setResetFiltrosToBuscaRapida,
    filtroEstados,
    setFiltroEstados,
    filtroCidades,
    setFiltroCidades,
    filtroCepInicial,
    setFiltroCepInicial,
    filtroCepFinal,
    setFiltroCepFinal
  } = useContext(HomeContext);

  const { user } = useContext(AuthContext);

  const [clearPreset, setClearPreset] = useState<number>(0);
  const [filtroStatusTemp, setFiltroStatusTemp] = useState<{ name: string; id: string; }[]>(listaStatus.filter(s => filtroStatus.includes(s.id)));
  const [filtroContratanteTemp, setFiltroContratanteTemp] = useState<{ id: string, name: string }[]>([]);
  const [filtroGrupoServicoTemp, setFiltroGrupoServicoTemp] = useState<{ id: string, name: string }[]>([]);
  const [filtroServicoTemp, setFiltroServicoTemp] = useState(filtroServicoById);
  const [listaPrestadores, setListaPrestadores] = useState<{ name: string; id: string; }[]>([]);
  const [filtroPrestadoresTemp, setFiltroPrestadoresTemp] = useState<{ name: string; id: string; }[]>([]);
  const [estadoLista, setEstadoLista] = useState<{ id: string, name: string }[]>([]);
  const [estadosSelecionados, setEstadosSelecionados] = useState<{ id: string, name: string }[]>(filtroEstados);
  const [cidadeLista, setCidadeLista] = useState<{ id: string, name: string }[]>([]);
  const [cidadesSelecionados, setCidadesSelecionados] = useState<{ id: string, name: string }[]>(filtroCidades);
  const [tecnicoSelecionado, setTecnicoSelecionado] = useState<{ name: string; id: string; }[] | undefined>(tecnico);

  const [filtroCEPInicialTemp, setFiltroCEPInicialTemp] = useState<string>(filtroCepInicial);
  const [filtroCEPFinalTemp, setFiltroCEPFinalTemp] = useState<string>(filtroCepFinal);

  const handleClearPreset = () => setClearPreset((prev: number) => prev + 1);

  function listAutoCompleteContratatesUsuario() {
    return listaContratante.filter((o: any) => Number(o.id) === user?.contratanteOrdemServico);
  }

  const handleClickFiltrar = () => {
    handleSetOSSelected([])
    setFiltroStatus(filtroStatusTemp.map(d => d.id));
    setFiltroContratante(filtroContratanteTemp.map(d => d.id));
    setFiltroPrestadores(!_.isEmpty(filtroPrestadoresTemp) ? filtroPrestadoresTemp.map((p: any) => Number(p.id)) : [...(user?.prestadores ?? [])]);
    setFiltroGrupoServico(filtroGrupoServicoTemp.map(d => d.id));
    setFiltroServicoById(filtroServicoTemp);
    setTecnico(tecnicoSelecionado)
    resetFiltrosPopover(false);
    setFiltroEstados(estadosSelecionados);
    setFiltroCidades(cidadesSelecionados);
    setFiltroCepInicial(filtroCEPInicialTemp);
    setFiltroCepFinal(filtroCEPFinalTemp);

    if (setCurrentPage) setCurrentPage(1);
    onClose();
  };

  const handleFiltrosFromFiltroBuscaRapida = () => {
    setFiltroStatusTemp([]);
    setFiltroStatus([]);
    setFiltroContratanteTemp(!!user?.contratanteOrdemServico ? listAutoCompleteContratatesUsuario() : []);
    setFiltroContratante(!!user?.contratanteOrdemServico ? [user?.contratanteOrdemServico.toString()] : []);
    setFiltroPrestadores(!!user?.prestadores ? user?.prestadores : []);
    setFiltroGrupoServicoTemp([]);
    setFiltroGrupoServico([]);
    setFiltroServicoTemp([]);
    setFiltroServicoById([]);
    setTecnicoSelecionado([]);
    setTecnico([]);
    setEstadosSelecionados([]);
    setFiltroEstados([]);
    setCidadesSelecionados([]);
    setFiltroCidades([]);
    setFiltroCEPInicialTemp("");
    setFiltroCEPFinalTemp("");
    setFiltroCepInicial("");
    setFiltroCepFinal("");
    resetFiltrosPopover(false);
    setResetFiltrosToBuscaRapida(true);
  };

  const handleClickLimpar = () => {
    setFiltroStatusTemp([]);
    setFiltroStatus([]);
    setFiltroContratanteTemp(!!user?.contratanteOrdemServico ? listAutoCompleteContratatesUsuario() : []);
    setFiltroContratante(!!user?.contratanteOrdemServico ? [user?.contratanteOrdemServico.toString()] : []);
    setFiltroPrestadores(!!user?.prestadores ? [...user?.prestadores] : []);
    setFiltroGrupoServicoTemp([]);
    setFiltroGrupoServico([]);
    setFiltroServicoTemp([]);
    setFiltroServicoById([]);
    setFiltroBuscaRapida("");
    setTecnicoSelecionado([]);
    setTecnico([]);
    setEstadosSelecionados([]);
    setFiltroEstados([]);
    setCidadesSelecionados([]);
    setFiltroCidades([]);
    setFiltroCepInicial("");
    setFiltroCepFinal("");
    setFiltroCEPInicialTemp("");
    setFiltroCEPFinalTemp("");
  };

  const resetToInitialFilters = () => {
    const fdDefault = listaStatus.find((fd) => removeSpecialCharSimple(fd.name) == 'encaminhada');

    setFiltroStatusTemp(!!fdDefault ? [fdDefault] : []);
    setFiltroStatus(!!fdDefault ? [fdDefault.id] : filtroStatusPadrao);
    setFiltroContratanteTemp(!!user?.contratanteOrdemServico ? listAutoCompleteContratatesUsuario() : []);
    setFiltroContratante(!!user?.contratanteOrdemServico ? [user?.contratanteOrdemServico.toString()] : []);
    
    setFiltroPrestadores(!!user?.prestadores ? [...user?.prestadores] : []);
    setFiltroGrupoServicoTemp([]);
    setFiltroGrupoServico([]);
    setFiltroServicoTemp([]);
    setFiltroServicoById([]);
    setFiltroBuscaRapida("");
    setTecnicoSelecionado([]);
    setTecnico([]);
    setEstadosSelecionados([]);
    setFiltroEstados([]);
    setCidadesSelecionados([]);
    setFiltroCidades([]);
    setFiltroCEPInicialTemp("");
    setFiltroCEPFinalTemp("");
    setFiltroCepInicial("");
    setFiltroCepFinal("");
  };

  const handleGetEstados = async () => {
    try {
      const { data }: { data: IGetResponseEstados } = await getEstados({ Ativo: true }) as AxiosResponse;
      const estados = data?.estados ?? [];

      setEstadoLista(estados.map(item => ({ name: item.nome, id: item.id.toString(), uf: item.uf })).sort(
        (a: { name: string, id: string }, b: { name: string, id: string }) =>
          (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)
      ))
    } catch (e) {
      setEstadoLista([]);
    }
  };

  const handleGetCidades = async () => {
    if (_.isEmpty(estadosSelecionados)) {
      setCidadeLista([]);
      return;
    }

    try {
      const { data }: { data: IGetResponseCidades } = await getCidades({
        Ativo: true,
        IdEstado: Number(estadosSelecionados[0].id),
      }) as AxiosResponse;
      const cidades = data?.cidades ?? [];

      setCidadeLista(cidades.map(item => ({ name: item.nome, id: item.id.toString() })).sort(
        (a: { name: string, id: string }, b: { name: string, id: string }) =>
          (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)
      ))

    } catch (e) {
      setCidadeLista([]);
    }
  };

  const criaListaServico = () => {
    let myServiceList: { name: string, id: string, grupo: string }[] = [];

    if (listaGrupoServico) {
      listaGrupoServico?.map(grupo => {
        if (filtroGrupoServicoTemp.map(s => s.id).includes(grupo.id.toString())) {
          grupo.servicos.map((item: IServico) => (
            myServiceList.push({
              name: item.titulo,
              id: item.id,
              grupo: grupo.id.toString(),
            })
          ))
        }
      })
    }

    return myServiceList;
  };

  const getServices = async () => {
    await getGrouped_Services().then((resp: any) => {
      const respSuccess = resp?.data as ResponseApi<Grupo_Servico[]>;

      const dataMaped = respSuccess.data.map((mp) => ({
        titulo: mp.nome,
        ativo: true,
        servicos: mp.servicos.map((ser) => ({
          ativo: true,
          descricao: '',
          fluxoTrabalho: '',
          id: ser.id.toString(),
          idGrupoServico: mp.id,
          titulo: ser.nome,
        })),
        codigoGrupoServico: '',
        codigoComportamento: null,
        id: mp.id
      })) as GrupoServico[];

      setListaGrupoServico(dataMaped);
    }, err => {
      const respErr = err?.response?.data as ResponseApi<any>;
    });
  };

  const compare = (a: { name: string, id: string }, b: { name: string, id: string }) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  };

  const handleChangeService = (e: any) => {
    setFiltroServicoTemp(e.map((item: any) => { return { name: item.name, id: item.id } }))
  };

  const getProvider = async () => {
    try {
      const { data } = await getPrestadores() as AxiosResponse


          let prestadores = [];
          if (!!data && !!data.length) {
            prestadores = data.filter((p: any) => !!p.ativo).map((m: any) => ({ ...m, name: m.nomePrestador, id: m.id?.toString() }))
              .sort((a: any, b: any) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
          }
          
          setListaPrestadores(filtraPrestadoresDoUsuario(prestadores));
        }
       catch (e) {
      
      setListaPrestadores([]);
    }
  };

  const filtraPrestadoresDoUsuario = (prestadores: any) => {
    let options: any[] = [...prestadores];

    if (!!user?.prestadores && !!user.prestadores.length) {
      options = options.filter((o: any) => user.prestadores.includes(Number(o.id)));
      /**Auto preenche o filtro prestadores */
      if (user.prestadores.length) {
      
        setFiltroPrestadoresTemp(options.filter(item => item.id !== 'all'));
      }
    }

    return options;
  };

  //Método depreciado, causa sobrecarga para n Prestadores;
  const requestListaTecnicos = async (idPrestador?: string) => {
    let listaTemp: ITecnicos[] = []
    for (let i = 0; i < filtroPrestadoresTemp.length; i++) {
      const id = idPrestador || filtroPrestadoresTemp[i].id;
      const tecnicos = await requestTecnicosPorPrestador(id) as ITecnicos[]
      listaTemp.push(...tecnicos)
    }
    setListaTecnicos(listaTemp || []);
    return true;
  };

  //Método depreciado, causa sobrecarga para n Prestadores;
  const requestTecnicosPorPrestador = async (id: string) => {
    try {
      let retorno = await getListaTecnicosByPrestador(Number(id)) as AxiosResponse;
      return retorno?.data
    } catch (e) {
      return []
    }
  };

  const requestListaTecnicosByLotePrestador = async (idPrestador?: string) => {
    let listaTemp: ITecnicos[] = []
    let listAllPrestadores: number[] = [] 
    
    
    for (let i = 0; i < filtroPrestadoresTemp.length; i++) {
      listAllPrestadores.push(parseInt(filtroPrestadoresTemp[i].id));      
    }

    const ids = idPrestador || listAllPrestadores;

    if (idPrestador && idPrestador.length > 0)
      listAllPrestadores.push(parseInt(idPrestador));

    console.log(listAllPrestadores)
    const tecnicos = await requestTecnicosByLotePrestador(listAllPrestadores) as ITecnicos[]
    listaTemp.push(...tecnicos)
    setListaTecnicos(listaTemp || []);
    return true;
  };

  const requestTecnicosByLotePrestador = async (ids: number[]) => {
    try {
      if (ids.length > 0) {
        let retorno = await getListaTecnicosByLotePrestador(ids) as AxiosResponse;
        return retorno?.data
      }else 
        return []  
    } catch (e) {
      console.log(e)
      return []
    }
  };

  const setPresetSelecionadoCmp = (preset: any) => {
    if (!!preset && !!preset?.filtro) {
      const jsonToObjectPreset = isJson(preset.filtro) ? JSON.parse(preset.filtro) : {};

      _.mapKeys(jsonToObjectPreset, function (value: any, key: string) {
        const chaveExiste = createPresetFromFilters()?.hasOwnProperty(key);
        if (!chaveExiste) return key;
        switchSetStateFilters(key, value);
        return key;
      });
    }
  };

  const switchSetStateFilters = (key: string, value: any) => {
    switch (key) {
      case 'filtroContratanteTemp':
        setFiltroContratanteTemp(value);
        break;
      case 'filtroPrestadoresTemp':
        setFiltroPrestadoresTemp(value);
        break;
      case 'filtroEstadosTemp':
        setEstadosSelecionados(value);
        break;
      case 'filtroCidadesTemp':
        setCidadesSelecionados(value);
        break;
      case 'filtroTecnicosTemp':
        setTecnicoSelecionado(value);
        break;
      case 'filtroStatusTemp':
        setFiltroStatusTemp(value);
        break;
      case 'filtroGrupoServicoTemp':
        setFiltroGrupoServicoTemp(value);
        break;
      case 'filtroServicoTemp':
        setFiltroServicoTemp(value);
        break;
      case 'filtroCEPInicialTemp':
        setFiltroCEPInicialTemp(value);
        break;
      case 'filtroCEPFinalTemp':
        setFiltroCEPFinalTemp(value);
        break;
      default:
        break;
    }
  };

  const createPresetFromFilters = () => {
    return {
      filtroContratanteTemp: filtroContratanteTemp,
      filtroPrestadoresTemp: filtroPrestadoresTemp,
      filtroEstadosTemp: estadosSelecionados,
      filtroCidadesTemp: cidadesSelecionados,
      filtroTecnicosTemp: tecnicoSelecionado,
      filtroStatusTemp: filtroStatusTemp,
      filtroGrupoServicoTemp: filtroGrupoServicoTemp,
      filtroServicoTemp: filtroServicoTemp,
      filtroCEPInicialTemp: filtroCEPInicialTemp,
      filtroCEPFinalTemp: filtroCEPFinalTemp
    };
  };

  const updateContratantesFilterTemp = () => {
    let contratantesTemp: any[] = [];

    if (!!user?.contratanteOrdemServico) {
      contratantesTemp = listAutoCompleteContratatesUsuario();
    } else {
      contratantesTemp = listaContratante.filter((o: any) => filtroContratante.includes(o.id));
    }

    setFiltroContratanteTemp(contratantesTemp);
  };

  const test1CepInicial = () => filtroCEPInicialTemp !== "" && filtroCEPInicialTemp.length <= 2;
  const validateErrorCepInicial = () => test1CepInicial();
  const helperTextCepInicial = () => {
    if (!validateErrorCepInicial()) return "";
    if (!!test1CepInicial()) return "Digite pelo menos 3 números";
  };

  const test1CepFinal = () => filtroCEPInicialTemp.length >= 3 && (filtroCEPFinalTemp !== "" && filtroCEPFinalTemp.length <= 2);
  const test2CepFinal = () => {
    let cleanCepInicial = filtroCEPInicialTemp?.replace(/\D/g, '') || '';
    let cleanCepFinal = filtroCEPFinalTemp?.replace(/\D/g, '') || '';

    if (!cleanCepInicial || !cleanCepFinal) return false;

    cleanCepInicial = cleanCepInicial.padEnd(8, '0');
    cleanCepFinal = cleanCepFinal ? cleanCepFinal.padEnd(8, '9') : '';

    if (Number(cleanCepFinal) < Number(cleanCepInicial)) return true;
    return false;
  };
  const validateErrorCepFinal = () => test1CepFinal() || test2CepFinal();
  const helperTextCepFinal = () => {
    if (!validateErrorCepFinal()) return "";
    if (!!test1CepFinal()) return "Digite pelo menos 3 números";
    if (!!test2CepFinal()) return "CEP final deve ser maior que CEP inicial";
  };

  const validateFieldsErros = () => {
    return (!!validateErrorCepInicial() || !!validateErrorCepFinal());
  };

  useEffect(() => {
    getServices();
    getProvider();
    handleGetEstados();

    return () => {
      resetToInitialFilters();
    }
  }, []);

  useEffect(() => {
    setFiltroStatusTemp(listaStatus.filter(s => filtroStatus.includes(s.id)));
  }, [filtroStatus]);

  useEffect(() => {
    updateContratantesFilterTemp();
  }, [listaContratante, filtroContratante]);

  useEffect(() => {
    handleGetCidades();
  }, [estadosSelecionados]);

  useEffect(() => {
    setFiltroPrestadoresTemp(listaPrestadores.filter((o: any) => filtroPrestadores.includes(Number(o.id))));
  }, [filtroPrestadores]);
    
  useEffect(() => {
    requestListaTecnicosByLotePrestador();
  }, [filtroPrestadoresTemp]);

  useEffect(() => {
    if (isFilterFromGrid || IsFieldBuscaRapida) handleFiltrosFromFiltroBuscaRapida();
  }, [isFilterFromGrid]);

  return (
    <Popover
      id={id}
      open={open}
      onClose={onClose}
      anchorEl={anchorEl}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      classes={{ paper: "FarolOS-PopoverFiltro-box" }}
    >
      <div className="FarolOS-PopoverFiltro">
        <div className="FarolOS-PopoverFiltro-label">Presets</div>

        <Presets
          style={{ display: "flex", flexDirection: "row-reverse" }}
          placeholder="Selecione um Preset"
          rotaTela={"/FarolOS"}
          newObjectToPreset={createPresetFromFilters()}
          setPreset={setPresetSelecionadoCmp}
          clearPreset={clearPreset}
        />

        <div className="FarolOS-PopoverFiltro-label">Filtros</div>

        <div className="FarolOS-PopoverFiltro-options">
          <AutocompleteMultiple
            noOptionsText="Nenhum contratante encontrado"
            options={listaContratante}
            value={filtroContratanteTemp}
            onChange={setFiltroContratanteTemp}
            placeholder={"Contratante"}
            id="multiple-checkbox-contratante"
            disabled={!!user?.contratanteOrdemServico}
            limitTags={1}
            allSelected
          />

          <AutocompleteMultiple
            label={""}
            placeholder={"Prestadores"}
            noOptionsText={"Nenhum prestador encontrado"}
            id="multiple-checkbox-Prestadores-Farol"
            options={listaPrestadores}
            
            value={filtroPrestadoresTemp}
            onChange={setFiltroPrestadoresTemp}
            limitTags={1}
            fontSize={12}
            disabled={(!!user?.prestadores && user.prestadores.length === 1)}
            allSelected
          />

          <AutocompleteMultiple
            noOptionsText="Erro ao buscar estados"
            options={estadoLista}
            value={estadosSelecionados}
            onChange={setEstadosSelecionados}
            fontSize={12}
            placeholder="Estado"
            allSelected
            limitTags={1}
          />

          <AutocompleteMultiple
            noOptionsText="Selecione uma cidade"
            options={cidadeLista}
            value={cidadesSelecionados}
            onChange={setCidadesSelecionados}
            fontSize={12}
            placeholder="Cidade"
            allSelected
            limitTags={1}
          />

          <MaskedInput
            value={filtroCEPInicialTemp} 
            onChange={(e) => setFiltroCEPInicialTemp(e.currentTarget.value)} 
            dimension='lg' 
            mask='99999-999'
            id='EnderecoCEPInicial'
            placeholder="CEP inicial"
            maskChar={''}
            error={validateErrorCepInicial()}
            helperText={helperTextCepInicial()}
          />

          <MaskedInput
            value={filtroCEPFinalTemp} 
            onChange={(e) => setFiltroCEPFinalTemp(e.currentTarget.value)} 
            dimension='lg' 
            mask='99999-999'
            id='EnderecoCEPFinal'
            placeholder="CEP final"
            maskChar={''}
            disabled={filtroCEPInicialTemp.length < 3}
            error={validateErrorCepFinal()}
            helperText={helperTextCepFinal()}
          />

          <AutocompleteMultiple
            noOptionsText="Selecione um prestador"
            options={listaTecnicosByPrestadorTratada().map(p => ({ name: p?.label ?? "", id: p?.id?.toString() ?? "" }))}
            value={tecnicoSelecionado}
            onChange={setTecnicoSelecionado}
            fontSize={12}
            placeholder="Técnico"
            allSelected
            limitTags={1}
          />

          <AutocompleteMultiple
            noOptionsText={"Nenhum status encontrado"}
            options={listaStatus}
            value={filtroStatusTemp}
            onChange={setFiltroStatusTemp}
            placeholder={"Status"}
            id="multiple-checkbox-status"
            limitTags={1}
            allSelected
          />

          <AutocompleteMultiple
            noOptionsText={"Nenhum grupo de serviço encontrado"}
            options={listaGrupoServico?.map((item: GrupoServico) => ({ name: item.titulo, id: item.id.toString() }))!}
            value={filtroGrupoServicoTemp}
            onChange={setFiltroGrupoServicoTemp}
            placeholder={"Grupo de serviço"}
            id="multiple-checkbox-grupoServico"
            limitTags={1}
            allSelected
          />

          <AutocompleteMultiple
            noOptionsText="Selecione um grupo de serviço"
            options={criaListaServico().sort(compare)}
            value={filtroServicoTemp}
            onChange={handleChangeService}
            fontSize={12}
            placeholder="Serviço"
            limitTags={1}
            allSelected
          />

          <Button
            id={"FarolOS-PopoverFiltro-BotaoLimpar"}
            variant="outlined"
            classes={{
              root: "FarolOS-PopoverFiltro-BotaoLimpar",
            }}
            onClick={() => {
              resetFiltrosPopover(false);
              handleClickLimpar();
              handleClearPreset();
            }}
          >
            Limpar
          </Button>
          <Button
            id={"FarolOS-PopoverFiltro-BotaoFiltrar"}
            variant="contained"
            classes={{
              root: "FarolOS-PopoverFiltro-BotaoFiltrar",
            }}
            onClick={handleClickFiltrar}
            disabled={validateFieldsErros()}
          >
            Filtrar
          </Button>
        </div>
      </div>
    </Popover>
  );
};

export default FarolOSPopoverFiltros;