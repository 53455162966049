import React, { useState } from 'react';
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { IMultipleSelectCheckmarks } from './types';
import _ from 'lodash';
import './seletorMultiploid.scss';

const ITEM_HEIGHT = 45;
const ITEM_PADDING_TOP = 5;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 5.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

/*Este seletor recebe opcoes como array de objetos com name e id*/

const MultipleSelectCheckmarks = ({
  label,
  id = "multiple-checkbox",
  idOption = "multiple-checkbox-option-",
  labelId = "multiple-checkbox-label",
  classes = {
    select: "FarolOS-PopoverFiltro-Seletor",
    primary: "Seletor-Option",
    group: "Seletor-OptionGroup",
  },
  nodata = "Sem dados",
  multiple = true,
  value,
  placeholder,
  onChange,
  options = [],
  disabled,
  onBlur,
  dimension,
  name,
  error,
  helperText,
  className,
  readOnly,
  selectAll
}: IMultipleSelectCheckmarks) => {

  (selectAll && options[0]?.id !== 'all') && options.unshift({name: 'Todos', id: 'all'})
  const optionsGrouped = _.groupBy(options, 'grupo')

  const handleChange = (event: SelectChangeEvent) => {
    const value = handleSelectAll((event.target as HTMLInputElement).value)

    if (!!onChange) {

      onChange(value);
    }
  };

  const handleSelectAll = (value: string[] | string) => {
    value = typeof value === "string" ? value.split(",") : value
    
    const all = value.some(item => item === 'all')

    if(all && value.length < options.length){
      return options.filter(item => item.id !== 'all').map(item => item.id)
    } else if(all && value.filter(item => item !== 'all').length === options.length - 1) {
      return []
    }
    return value
  }

  return (
    <FormControl className={className} sx={{ m: 1, width: "100%", margin: 0, justifyContent: 'flex-start'}}>
      {label && <span className={`label ${dimension}`}>{label}</span>}
      <Select
        name={name}
        readOnly={readOnly}
        id={`multiple-checkbox ${id}`}
        labelId={`multiple-checkbox-label ${labelId}`}
        className={`select ${dimension}`}
        classes={classes}
        multiple={multiple}
        displayEmpty
        disabled={disabled}
        inputProps={{
          className: 'Multiple-Checkbox-Input'
        }}
        onBlur={onBlur}
        error={error}
        //@ts-ignore
        value={value}
        renderValue={(selected) => {
          if (!!placeholder && selected.length === 0) {
            return <span className="Seletor-Placeholder">{placeholder}</span>;
          }
          //@ts-ignore
          return selected.reduce((string, item) => {
            if (string === "") {
              const temp = options.find(option => option.id === item)
              if(temp){
                string = temp?.name;
              }
            } else {
              const temp = options.find(option => option.id === item)
              if(temp){
                string = string + ', ' + temp?.name;
              }
            }

            return string;
          }, "");
        }}
        onChange={handleChange}
        MenuProps={MenuProps}
      >
        {!options.length && (
          <MenuItem
            key={"nodata"}
            id={idOption + "nodata multiple-checkbox-option-"}
            value={"nodata"}
            disabled
          >
            <span className={classes.primary}>{nodata}</span>
          </MenuItem>
        )}
        {Object.keys(optionsGrouped).map((item, index) => {
          
          return optionsGrouped[item].map(option => {
            return(
              <MenuItem key={option.id}  value={option.id} style={{backgroundColor: 'transparent!important'}}>
                {(!!multiple && option.id !== 'all') && <Checkbox checked={value.indexOf(option.id) > -1} />}
                <span className={classes.primary}>{option.name}</span>
              </MenuItem>
            )
          })
        })}
      </Select>
      <span className="errorMessage">{helperText}</span>
    </FormControl>
  );
};

export default MultipleSelectCheckmarks;
