import { useContext, useEffect, useState } from "react";
import { HomeContext } from "../../../contexts/homeContext";
import { AuthContext } from "../../../contexts/auth";
import { useNavigate } from "react-router-dom";
import { NewButton, SearchButton, Section } from "./consultarTermoOs_styles";
import Header from "../../../components/header/header";
import MultipleSelectCheckmarksid from "../../../components/seletorMultiploId/seletorMultiploId";
import {
  Breadcrumb,
  BreadcrumbItem,
} from "../../../components/breadcrumb/breadcrumb";

import { getContratantesNovo } from "../../farol/services";
import { IContratantesDTO } from "../../contratante/types";
import CloseIcon from "@mui/icons-material/Close";
import "./consultaTermoOs.scss";
import { Alert, CircularProgress, IconButton } from "@mui/material";
import StickyTable from "../../../components/stickyTable/stickyTable";
import Collapse from "../../../components/collapse/collapse";
import { consultarTermos } from "../services";
import { AxiosResponse } from "axios";
import { ApiResponse, HeaderTabela } from "../types";
import { ResponseApi } from "../../../contexts/types/responsesHttp";
import Pagination from "../../../components/pagination/paginacao";

const ConsultarTermoOs = () => {
  const { setMenuLateral } = useContext(HomeContext);
  const { funcionalidadeDaTelaTemPermissao } = useContext(AuthContext);
  const profileHasPermission = (funcionalidade: string) =>
    funcionalidadeDaTelaTemPermissao("Buscar Termo de OS", funcionalidade);
  const navigate = useNavigate();

  const [listaContratante, setListaContratante] = useState<
    { name: string; id: string }[]
  >([]);
  const [contratante, setContratante] = useState<string[]>([]);
  const [openAlertError, setOpenAlertError] = useState(false);
  const [disableActions, setDisableActions] = useState(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [qtdeRegistros, setQtdeRegistros] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [dadosConsulta, setDadosConsulta] = useState<ApiResponse | null>(null);
  const [msgAlertError, setMsgAlertError] = useState<string>(
    "Não foram encontrados termos de OS."
  );

  useEffect(() => {
    handleGetContratantes();
  }, []);

  const handleGetContratantes = async () => {
    await getContratantesNovo().then(
      (res: any) => {
        const respSuccess = res?.data as ResponseApi<IContratantesDTO[]> | null;
        if (respSuccess) {
          const listaContratante =
            respSuccess.data?.map((item: any) => {
              return { name: item.nome, id: item.id.toString() };
            }) || [];
          setListaContratante([...[], ...listaContratante]);
        }
      },
      (err) => {
        const respErr = err?.response?.data as ResponseApi<IContratantesDTO[]>;
        if (respErr) {
          setMsgAlertError(respErr.errors[0].Message);
          setOpenAlertError(true);
          setListaContratante([]);
        }
      }
    );
  };
  useEffect(() => {
    if (contratante.length) {
      handlePesquisar();
    }
  }, [contratante]);

  const handlePesquisar = async () => {
    setOpenAlertError(false);
    setDisableActions(true);
    const codigo = contratante[0];
    setLoading(true);
    if (codigo === undefined || codigo === null || codigo.trim() === "") {
      setLoading(false);
      setOpenAlertError(true);
      setMsgAlertError("Informe um contratante para realizar a pesquisa");
      return;
    } else {
      try {
        const retorno = (await consultarTermos(
          parseInt(codigo)
        )) as AxiosResponse;

        if (retorno.status === 200) {
          setDadosConsulta(retorno?.data ?? null);

          setLoading(false);
          setDisableActions(false);
          setQtdeRegistros(retorno?.data?.totalRegistros || 0);
          return;
        } else {
          setDadosConsulta(null);
          setLoading(false);
          setQtdeRegistros(0);
          setDisableActions(true);
          setMsgAlertError("Não foram encontrados termos de OS.");
          return;
        }
      } catch (error: any) {
        setDadosConsulta(null);
        setLoading(false);
        setMsgAlertError(error?.response?.data!);
        setOpenAlertError(true);
        setQtdeRegistros(0);
        setDisableActions(false);
        return;
      }
    }
  };
  const objTraducaoHeader = {
    id: "ID Termo",
    status: "Status",
    contratanteNome: "Contratante",
    grupoServicoNome: "Grupo de Serviço",
    servicoNome: "Serviço",
    termo: "Termo de OS",
    versao: "Versão",
    ultimaAtualizacao: "Última Atualização",
  } as HeaderTabela;

  const formatarData = (data: string): string => {
    const dateObj = new Date(data);
    const dia = dateObj.getDate().toString().padStart(2, "0");
    const mes = (dateObj.getMonth() + 1).toString().padStart(2, "0");
    const ano = dateObj.getFullYear().toString();

    return `${dia}/${mes}/${ano}`;
  };

  const obterDadosParaTabela = () => {
    return dadosConsulta?.termosOS?.map((ordem) => ({
      id: ordem.id,
      status: ordem.ativo ? "Ativo" : "Inativo",
      contratanteNome: ordem.contratante.nome,
      grupoServicoNome:
        ordem?.grupoServico?.nome,
      servicoNome: ordem?.servicos?.length > 0 ?  ordem.servicos.map((item:any)=> item.nome).join(', ') : "",
      termo: ordem.termo,
      versao: ordem.versao,
      ultimaAtualizacao: formatarData(ordem.ultimaAtualizacao),
    })) as any[];
  };

  const obterTermoPorId = (ID: number) => {
    let resultadoDados = [...(dadosConsulta?.termosOS || [])];
    return resultadoDados.find((item) => item.id === ID)!;
  };

  const handleClickItemEdit = (data: any) => {
    navigate("/EditarTermoOs", {
      state: {
        dadosTermoOs: obterTermoPorId(data.id),
        rota: {
          url: window.location.pathname,
          name: "Buscar Termo de OS",
        },
      },
    });
  };
  const handleClickNovoTermo = () => {
    navigate("/IncluirTermoOs", {
      state: {
        rota: {
          url: window.location.pathname,
          name: "Buscar Termo de OS",
        },
      },
    });
  };

  return (
    <Section id={"termoOS-page"}>
      <Header setMenuLateral={() => setMenuLateral(true)} title="Termo de OS" />
      <Breadcrumb>
        <BreadcrumbItem>Consultar Termo de OS</BreadcrumbItem>
      </Breadcrumb>
      <div className="termoOS-container">
        <div className="filtro">
          <MultipleSelectCheckmarksid
            dimension="sm"
            label={"Contratante"}
            nodata={"Nenhum contratante encontrado"}
            options={[{ name: "--", id: "" }, ...listaContratante]}
            value={contratante}
            onChange={(value) => setContratante(value)}
            placeholder={""}
            id="multiple-checkbox-Contratante"
            idOption="multiple-checkbox-option-Contratante-"
            labelId="multiple-checkbox-label-Contratante"
            classes={{
              select: "ConsultaUsuario-Seletor error",
              primary: "Seletor-Option",
              group: "Seletor-OptionGroup",
            }}
            multiple={false}
            disabled={disableActions}
          />
          <div className="botao-container">
            <SearchButton
              variant="contained"
              size="small"
              onClick={handlePesquisar}
              disabled={disableActions}
            >
              Pesquisar
            </SearchButton>
            {!!profileHasPermission("Novo Registro") ? (
              <NewButton
                color="secondary"
                variant="contained"
                size="small"
                onClick={handleClickNovoTermo}
              >
                Incluir
              </NewButton>
            ) : (
              <NewButton
                className={"bloqueado"}
                style={{ cursor: "not-allowed", opacity: 0.5 }}
                color="secondary"
                variant="contained"
                size="small"
              >
                Incluir
              </NewButton>
            )}
          </div>
        </div>
      </div>
      {openAlertError && (
        <div className="modalErro">
          <Collapse onClose={setOpenAlertError} in={openAlertError}>
            <Alert
              severity="error"
              icon={<div />}
              variant="filled"
              sx={{
                mb: 2,
                bgcolor: "rgb(255, 35, 35, 0.7)",
                borderRadius: "15px",
                boxSizing: "border-box",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginBottom: "50px",
              }}
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="medium"
                  onClick={() => setOpenAlertError(false)}
                >
                  <CloseIcon
                    id={"ModalTermoOs-AlertCloseIcon"}
                    fontSize="inherit"
                    sx={{
                      color: "white",
                    }}
                  />
                </IconButton>
              }
            >
              <span
                style={{
                  color: "white",
                  fontSize: "16px",
                }}
                dangerouslySetInnerHTML={{ __html: msgAlertError }}
              />
            </Alert>
          </Collapse>
        </div>
      )}
      {!!loading && <CircularProgress style={{ margin: "0px auto" }} />}
      {!!dadosConsulta?.termosOS?.length && (
        <>
          <div className="tabelaTermo">
            <StickyTable
              id={"TabelaResultadoConsultaTermos"}
              data={obterDadosParaTabela()}
              objTraducaoHeader={objTraducaoHeader}
              allowEdit={!!profileHasPermission("Editar Registro")}
              edit={true}
              handleClickEdit={handleClickItemEdit}
            />
          </div>
          <Pagination
            className="pagination-bar"
            currentPage={currentPage}
            totalCount={qtdeRegistros}
            pageSize={pageSize}
            setPageSize={(value: string) => setPageSize(Number(value))}
            onPageChange={(page: number) => setCurrentPage(Number(page))}
            showLabel={false}
          />
        </>
      )}
    </Section>
  );
};

export default ConsultarTermoOs;
