import { apiGet, apiPost, apiPut } from "../../../services/api";

const baseUrlOrdemServico = process.env.REACT_APP_API_ORDEMSERVICO;
export const getContratantes = async () => 
    await apiGet(`${baseUrlOrdemServico}/contratante_`);

export const getModelos = async () => 
    await apiGet(`${baseUrlOrdemServico}/equipamento-modelos`);
export const pesquisarModelosCompativeis = async (idContratante:number, idEquipamentoModeloPrincipal:number) => 
    await apiGet(`${baseUrlOrdemServico}/equipamento-modelos-compativeis?IdContratante=${idContratante}&IdEquipamentoModeloPrincipal=${idEquipamentoModeloPrincipal}`);

export const postModelo = async (params: any) =>
    await apiPost(`${baseUrlOrdemServico}/equipamento-modelos-compativeis`, params)


export const atualizarModelo = async (params: any) =>
    await apiPut(`${baseUrlOrdemServico}/equipamento-modelos-compativeis`, params)