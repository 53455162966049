import { useEffect, useState } from "react";
import _ from "lodash";
import { getStatusOrdemServico } from '../pages/statusOrdemServico/services';
import { getContratantesNovo } from '../pages/farol/services';
import {
  listaServicoPadrao,
  listaOrdenarPadrao,
  listaOrdenarValorPadrao,
  ordenacaoPadrao,
  IListaOrdenarValorPadrao
} from "./helpers/dadosPadrao";
import { FormatDateToApi } from "../helpers/formatDate";
import { IOrdemServico, GrupoServico, Grupo_Servico } from './types/farolTypes';
import { ISeletorMultiploId } from "../pages/farol/types";
import { AxiosError, AxiosResponse } from "axios";
import { getContratantes } from "../pages/farol/services";
import { ResponseApi } from "./types/responsesHttp";
import { IStatusOSDto } from "../pages/statusOrdemServico/types";
import { IContratantesDTO } from "../pages/contratante/types";
import { removeSpecialCharSimple } from "../helpers/removeSpecialCharSimple";

export const FarolContext = (listaos: IOrdemServico[], filtroFarol: string, user: any) => {
  const [visaoFarol, setVisaoFarol] = useState("Lista");

  const [listaContratante, setListaContratante] = useState<ISeletorMultiploId[]>([]);
  const [listaStatus, setListaStatus] = useState<{ name: string; id: string; }[]>([]);
  const [listaGrupoServico, setListaGrupoServico] = useState<GrupoServico[]>();
  const [listaGrupo_Servico, setListaGrupo_Servico] = useState<Grupo_Servico[]>();
  const [listaServico, setListaServico] = useState(listaServicoPadrao);

  const [filtroBuscaRapida, setFiltroBuscaRapida] = useState("");
  const [filtroContratante, setFiltroContratante] = useState<string[]>(!!user?.contratanteOrdemServico ? [user?.contratanteOrdemServico.toString()] : []);
  const [filtroPrestadores, setFiltroPrestadores] = useState<any[]>(!!user?.prestadores ? user?.prestadores : []);
  const [filtroEstados, setFiltroEstados] = useState<{ id: string, name: string }[]>([]);
  const [filtroCidades, setFiltroCidades] = useState<{ id: string, name: string }[]>([]);
  const [tecnico, setTecnico] = useState<{ name: string; id: string; }[] | undefined>([]);
  const [filtroStatus, setFiltroStatus] = useState<string[]>([]);
  const [filtroGrupoServico, setFiltroGrupoServico] = useState<string[]>([] as string[]);
  const [filtroServico, setFiltroServico] = useState<string[]>([] as string[]);
  const [filtroServicoById, setFiltroServicoById] = useState<{ name: string, id: string }[]>([]);
  const [filtroCepInicial, setFiltroCepInicial] = useState<string>("");
  const [filtroCepFinal, setFiltroCepFinal] = useState<string>("");

  const [osselected, setOSSelected] = useState<number[]>([] as number[]);
  const [listaOrdenar, setListaOrdenar] = useState(listaOrdenarPadrao);
  const [listaOrdenarValor, setListaOrdenarValor] = useState(listaOrdenarValorPadrao);
  const [ordenacao, setOrdenacao] = useState<string[]>(ordenacaoPadrao);
  const [ordenacaoReverse, setOrdenacaoReverse] = useState(false);

  const [resetFiltrosToBuscaRapida, setResetFiltrosToBuscaRapida] = useState(false);

  const [getListaStatusFarolDone, setGetListaStatusFarolDone] = useState(false);

  const localGetContratantes = async () => {
    return await getContratantesNovo()
      .then((res: any) => {
        const respSuccess = res?.data as ResponseApi<IContratantesDTO[]> | null;

        if (respSuccess) {
          const listaContratante = respSuccess.data?.map((item: any) => { return { name: item.nome, id: item.id.toString(), idOppay: item.id_Oppay.toString() } }) || [];
          setListaContratante(filtraContratantesDoUsuario([...[], ...listaContratante]));
        };

      }, err => {
        const respErr = err?.response?.data as ResponseApi<IContratantesDTO[]>;
        if (respErr) setListaContratante([]);
      });
  };

  const filtraContratantesDoUsuario = (contratantes: { name: string; id: string; idOppay: string; }[]) => {
    let options = [...contratantes];

    if (!!user?.contratanteOrdemServico) {
      options = options.filter((o: any) => Number(o.id) === user.contratanteOrdemServico);
    }

    return options;
  };

  const obterStatusFarolAsync = async () => {
    return await getStatusOrdemServico().then((res: any) => {
      const respSuccess = res?.data as ResponseApi<IStatusOSDto[]>;

      if (respSuccess?.data) {
        const listaStatusTratada = respSuccess.data?.map((item: IStatusOSDto) => { return { name: item.nome, id: item.id.toString() } }) || [];
        setListaStatus(listaStatusTratada);
      };

      setGetListaStatusFarolDone(true);
    }, err => {
      const respErr = err?.response?.data as ResponseApi<any>;
      if (respErr) {
        setListaStatus([]);
      }
      setGetListaStatusFarolDone(true);
    });
  };

  const handleSetFiltroStatusPadrao = async () => {
    const fdDefault = listaStatus.find((fd) => removeSpecialCharSimple(fd.name) == 'encaminhada');
    if (fdDefault) {
      const arrayAdd: string[] = [fdDefault?.id?.toString()];
      setFiltroStatus([...[], ...arrayAdd]);
    } else {
      setFiltroStatus([...[]]);
    };
  };

  const obterFiltrosFarolAsync = async () => {
    await obterStatusFarolAsync();
    await localGetContratantes();
  };

  const listaOSFiltradaFarol = () => {
    let resultadoDados: IOrdemServico[] = addGrupoServicoToOS(listaos);

    if (listaOrdenarValor[ordenacao[0] as keyof IListaOrdenarValorPadrao] === "prioridadeFarol") {
      /*Ordena pela prioridade da os e pela data*/

      resultadoDados = _.sortBy(resultadoDados, [
        listaOrdenarValor[ordenacao[0] as keyof IListaOrdenarValorPadrao],
        "previsaoAtendimento",
      ]) as IOrdemServico[];
    } else {
      /*Ordena pelo valor do seletor de ordenação*/

      resultadoDados = _.sortBy(resultadoDados, [
        listaOrdenarValor[ordenacao[0] as keyof IListaOrdenarValorPadrao],
      ]) as IOrdemServico[];
    }

    if (!!ordenacaoReverse) return resultadoDados.reverse();

    return resultadoDados;
  };

  const agrupamentoPorCliente = () => {
    let resultadoDados: IOrdemServico[] = addGrupoServicoToOS(listaos);

    return resultadoDados.reduce((result: any, item: IOrdemServico) => {
      const { codigoCliente, chamado, tipoOS } = item;

      if (_.isNil(codigoCliente)) {
        result.clientescomapenasumaos.push(item);
      } else {
        let hasMoreOSSameClient = listaos.find(o => (o.codigoCliente === codigoCliente && o.chamado !== chamado));

        if (!hasMoreOSSameClient) {

          if (!result["clientescomapenasumaos"]) {
            result["clientescomapenasumaos"] = [item];
          } else {
            result["clientescomapenasumaos"].push(item);
          }

        } else {

          if (!result[codigoCliente]) {
            result[codigoCliente] = [item];
          } else {
            result[codigoCliente].push(item);
          }
        }

      };

      return result;
    }, { "clientescomapenasumaos": [] });
  };

  const ordenacaoFarol = () => {
    let newresultadoDados = agrupamentoPorCliente();

    return _.mapValues(newresultadoDados, (resultadoDados) => {

      if (listaOrdenarValor[ordenacao[0] as keyof IListaOrdenarValorPadrao] === "prioridadeFarol") {
        /*Ordena pela prioridade da os e pela data*/

        resultadoDados = _.sortBy(resultadoDados, [
          listaOrdenarValor[ordenacao[0] as keyof IListaOrdenarValorPadrao],
          "previsaoAtendimento",
        ]) as IOrdemServico[];
      } else {
        /*Ordena pelo valor do seletor de ordenação*/

        resultadoDados = _.sortBy(resultadoDados, [
          listaOrdenarValor[ordenacao[0] as keyof IListaOrdenarValorPadrao],
        ]) as IOrdemServico[];
      }

      if (!!ordenacaoReverse) resultadoDados = resultadoDados.reverse();

      return resultadoDados;
    });

  };

  const addGrupoServicoToOS = (listaOS: any[]) => {

    if (!listaGrupoServico?.length) return listaOS;

    return listaOS.map((item) => {
      return {
        ...item,
        grupoServico: listaGrupoServico.reduce((string, grupoServico) => {
          if (!!grupoServico.servicos?.length && grupoServico.servicos.find(servico => String(servico.id) === String(item.idServico))) {
            string = grupoServico.titulo || "";
          }
          return string;
        }, "")
      };
    });
  };

  const handleSetOSSelected = (id: number[] | IOrdemServico) => {
    if (_.isArray(id)) return setOSSelected(id);
    let ostoselect: number[] = []
    if (osselected) {
      ostoselect = [...osselected];
    }

    if (ostoselect?.includes(id.chamado)) {
      ostoselect = ostoselect.filter((idselected: number) => idselected !== id.chamado);
    } else {
      ostoselect.push(id.chamado);
    }

    setOSSelected(ostoselect);
  };

  const temFiltroPopoverSelecionado = () => {
    return (
      filtroContratante?.length ||
      filtroStatus?.length ||
      filtroGrupoServico?.length ||
      filtroServico?.length
    );
  };

  const getOSsByIDsFarol = (IDs: number[] = []) => {
    let resultadoDados = listaos;
    let newResults: IOrdemServico[] = [];

    IDs.forEach((id) => {
      let newItem: IOrdemServico = resultadoDados.find((item) => item.chamado === id)!;

      if (!!newItem) newResults.push(newItem);
    });

    return newResults;
  };

  const iDsOSsParaResumoEquipamentos = () => {
    let resultadoDados = getOSsByIDsFarol(osselected);
    let idsServicosParaNaoUtilizar = listaServico.Retirada?.map(servico => servico.id) || [];

    /**Regra: Quando a OS forem dos serviços Retirada ou Desinstalação, não deverá exibir a opção "Resumo de Equipamento" */

    resultadoDados = resultadoDados.filter(item => {

      //TODO: Verificar condição após requisitar OSs da API

      return !idsServicosParaNaoUtilizar.includes(item.idServico);
    });

    return resultadoDados.map(item => item.chamado);
  };

  useEffect(() => {
    obterFiltrosFarolAsync();
  }, []);

  useEffect(() => {
    if (getListaStatusFarolDone) handleSetFiltroStatusPadrao();
  }, [getListaStatusFarolDone]);

  return {
    ordenacaoFarol: ordenacaoFarol(),
    filtroBuscaRapida,
    setFiltroBuscaRapida,
    listaOSFiltradaFarol: listaOSFiltradaFarol(),
    getOSsByIDsFarol,
    visaoFarol,
    setVisaoFarol,
    osselected,
    handleSetOSSelected,
    listaOrdenar,
    ordenacao,
    setOrdenacao,
    ordenacaoReverse,
    setOrdenacaoReverse,
    listaStatus,
    filtroStatus,
    setFiltroStatus,
    listaContratante,
    filtroContratante,
    setFiltroContratante,
    filtroPrestadores,
    setFiltroPrestadores,
    listaGrupoServico,
    filtroGrupoServico,
    setFiltroGrupoServico,
    listaServico,
    setListaGrupo_Servico,
    listaGrupo_Servico,
    filtroServico,
    setFiltroServico,
    filtroServicoById,
    setFiltroServicoById,
    temFiltroPopoverSelecionado: temFiltroPopoverSelecionado(),
    iDsOSsParaResumoEquipamentos: iDsOSsParaResumoEquipamentos(),
    setListaGrupoServico,
    tecnico,
    setTecnico,
    localGetContratantes,
    resetFiltrosToBuscaRapida,
    setResetFiltrosToBuscaRapida,
    filtroEstados,
    setFiltroEstados,
    filtroCidades,
    setFiltroCidades,
    getListaStatusFarolDone,
    setGetListaStatusFarolDone,
    filtroCepInicial,
    setFiltroCepInicial,
    filtroCepFinal,
    setFiltroCepFinal
  };
};
