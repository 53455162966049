import { useContext } from "react";
import _ from "lodash";
import "./checklistFiltrosAplicados.scss";
import { HomeContext } from "../../../contexts/homeContext";
import { ReactComponent as CloseIcon } from "../../../assets/icons/CloseIcon.svg";

const ChecklistFiltrosAplicados = () => {
  const {
    checklistFiltroTecnicosSelecionados,
    checklistFiltroPlacaMotoSelecionados,
    checklistFiltroTipoMotoSelecionados,
    checklistFiltroDataInicial,
    checklistFiltroDataFinal,
    checklistFiltroKMInicial,
    checklistFiltroKMFinal,
    checklistFiltroStatusItemMoto,
    checklistFiltroItemMoto,
    checklistFiltroStatusItemEPI,
    checklistFiltroItemEPI,

    setChecklistFiltroDataInicial,
    setChecklistFiltroDataFinal,
    setChecklistFiltroTecnicoSelecionados,
    setChecklistFiltroPlacaMotoSelecionados,
    setChecklistFiltroTipoMotoSelecionados,
    setChecklistFiltroKMInicial,
    setChecklistFiltroKMFinal,
    setChecklistFiltroStatusItemMoto,
    setChecklistFiltroItemMoto,
    setChecklistFiltroStatusItemEPI,
    setChecklistFiltroItemEPI
  } = useContext(HomeContext);

  const renderLabelFiltros = () => {
    if (
      _.isEmpty(checklistFiltroDataInicial) &&
      _.isEmpty(checklistFiltroDataFinal) &&
      _.isEmpty(checklistFiltroTecnicosSelecionados) &&
      _.isEmpty(checklistFiltroPlacaMotoSelecionados) &&
      _.isEmpty(checklistFiltroTipoMotoSelecionados) &&
      _.isEmpty(checklistFiltroKMInicial) &&
      _.isEmpty(checklistFiltroKMFinal) &&
      _.isEmpty(checklistFiltroItemMoto) &&
      _.isEmpty(checklistFiltroStatusItemMoto) &&
      _.isEmpty(checklistFiltroStatusItemEPI) &&
      _.isEmpty(checklistFiltroItemEPI)
    ) {
      return ""
    } else return "Filtros aplicados:"
  }

  return (
    <div className="ChecklistFiltrosAplicados">
      <div className="ChecklistFiltrosAplicados-label">{renderLabelFiltros()}</div>

      {!_.isEmpty(checklistFiltroDataFinal)
        &&
        <FiltroAplicado
          nomeFiltro={"Data"}
          setFiltro={() => {
            setChecklistFiltroDataInicial("");
            setChecklistFiltroDataFinal("");
          }}
        />
      }

      {!_.isEmpty(checklistFiltroTecnicosSelecionados)
        &&
        <FiltroAplicado nomeFiltro={"Técnico"} setFiltro={() => setChecklistFiltroTecnicoSelecionados([])} />
      }

      {!_.isEmpty(checklistFiltroPlacaMotoSelecionados)
        &&
        <FiltroAplicado
          nomeFiltro={"Placa da Moto"}
          setFiltro={() => setChecklistFiltroPlacaMotoSelecionados([])}
        />
      }

      {!_.isEmpty(checklistFiltroTipoMotoSelecionados)
        &&
        <FiltroAplicado nomeFiltro={"Tipo de Moto"} setFiltro={() => setChecklistFiltroTipoMotoSelecionados([])} />
      }

      {(checklistFiltroKMFinal != null
        && checklistFiltroKMFinal != undefined
        && Number(checklistFiltroKMFinal) !== 0)
        &&
        <FiltroAplicado
          nomeFiltro={"Quilometragem"}
          setFiltro={() => {
            setChecklistFiltroKMInicial(0);
            setChecklistFiltroKMFinal(0);
          }}
        />
      }

      {!_.isEmpty(checklistFiltroStatusItemMoto)
        &&
        <FiltroAplicado nomeFiltro={"Status Item da Moto"} setFiltro={() => setChecklistFiltroStatusItemMoto([])} />
      }

      {!_.isEmpty(checklistFiltroItemMoto)
        &&
        <FiltroAplicado nomeFiltro={"Item da Moto"} setFiltro={() => setChecklistFiltroItemMoto([])} />
      }

      {!_.isEmpty(checklistFiltroStatusItemEPI)
        &&
        <FiltroAplicado nomeFiltro={"Status do EPI"} setFiltro={() => setChecklistFiltroStatusItemEPI([])} />
      }

      {!_.isEmpty(checklistFiltroItemEPI)
        &&
        <FiltroAplicado nomeFiltro={"EPI"} setFiltro={() => setChecklistFiltroItemEPI([])} />
      }
    </div>
  );
};

const FiltroAplicado = ({ nomeFiltro, setFiltro, disableCloseIcon = false }: { nomeFiltro: string, setFiltro: () => void, disableCloseIcon?: boolean }) => {
  return (
    <div className={"ChecklistFiltrosAplicados-filtro" + (disableCloseIcon ? " disableCloseIcon" : "")}>
      {nomeFiltro}

      <CloseIcon
        id={"FiltroCloseIcon"}
        className={"FiltroCloseIcon"}
        onClick={!disableCloseIcon ? setFiltro : () => { }}
      />
    </div>
  );
};

export default ChecklistFiltrosAplicados;
