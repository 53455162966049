import React, { ReactNode, useEffect, useState } from 'react'
import { Collapse as MUICollapse, CollapseProps } from '@mui/material'

interface ICollapse extends CollapseProps {
  children: ReactNode
  onClose: Function
  autoClose?: boolean
}

const Collapse = ({children, onClose, autoClose = true, ...props}: ICollapse) => {

  const [open, setOpen] = useState(props.in)

  useEffect(() => {
    setOpen(props.in)

    if(props.in && autoClose){
      setTimeout(() => {
        setOpen(false);
        onClose(false)
      }, 3000);
    }
  }, [props.in])

  return(
    <MUICollapse in={open}>
      {children}
    </MUICollapse>
  )
}

export default Collapse