import styled from "styled-components";
import { Button } from "@mui/material";
import { ReactComponent as FowardIconSVG } from "../../../assets/icons/FowardIcon.svg";

export const FowardButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
`;

export const FowardIcon = styled(FowardIconSVG)`
  margin-right: 10px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 450px;
  background-color: #ffffff;
  padding: 26px 40px 38px 40px;
  box-sizing: border-box;
  & .MuiAlert-action {
    margin: unset;
  }

  @media only screen and (max-width: 450px) {
    min-width: unset;
  }
`;

export const ContainerErroModal = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  padding: 26px 40px 38px 40px;
  box-sizing: border-box;
  gap: 21px;
  align-items: center;
  & .MuiAlert-action {
    margin: unset;
  }
`

export const ButtonList = styled.div`
  display: flex;
  width: 50%;
  gap: 8px;
`

export const Body = styled.div`
  margin-top: 10px;
  margin-bottom: 30px;
  width: 100%;
  display: flex;
  border-radius: 15px;
  overflow: hidden;
`;

export const Column = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Row = styled.div`
  height: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 14px;

  &:last-child {
    border-bottom: none;
  }

  & span {
    font-weight: 600;
    margin-right: 10px;
  }
`;

export const ButtonMenu = styled(Button)`
  justify-content: start !important;
  &.isActive {
    background-color: #fff;
    color: #1f5ab6;
    fill: red;
    &:hover {
      background-color: #fff;
      color: #1f5ab6;
    }
    path {
      fill: #1f5ab6;
    }
  }
  .MuiButton-startIcon {
    margin-right: 32px;
  }
`;
