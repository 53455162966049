import { apiGet, apiPost, apiPut } from "../../../services/api";
import { IGetCidades, IPostCidade, IPutCidade } from "../types";

const baseUrlOrdemServico = process.env.REACT_APP_API_ORDEMSERVICO;

export const postCidade = async (params: IPostCidade) =>
    await apiPost(`${baseUrlOrdemServico}/cidades`, params)
  
export const putCidade = async (params: IPutCidade, idCidade: number | string) =>
    await apiPut(`${baseUrlOrdemServico}/cidades/${idCidade}`, params)

export const getListaCidadesComFiltro = async (params: IGetCidades) =>
    await apiGet(`${baseUrlOrdemServico}/cidades`, params);

export const getListaEstados = async (params: any) =>
    await apiGet(`${baseUrlOrdemServico}/estados`, params);

export const getListaRegioes = async (params: any) =>
    await apiGet(`${baseUrlOrdemServico}/regioes`, params);