import React, { useContext } from 'react'
import { Table, TableRow } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import * as Styles from "../../../components/table/styles";
import {ReactComponent as EditIcon} from "../../../assets/icons/EditIcon.svg";
import { IConsultarTipoEquipamentoTabela } from '../types'
import moment from 'moment';
import { HomeContext } from '../../../contexts/homeContext';
import { useNavigate } from 'react-router-dom';

interface IConsultarPerfilTable {
  tableData: IConsultarTipoEquipamentoTabela[]
}

const ConsultarTipoEquipamentoTabela = ({tableData}: IConsultarPerfilTable) => {

  const { setTipoEquipamentoSelected, familiaLista } = useContext(HomeContext)

  const navigate = useNavigate()

  const header = [
    'Status',
    'Id',
    'Descrição',
    'Família',
    'Data de Criação',
    'Criado Por',
    'Data de Modificação',
    'Modificado Por',
    'Editar'
  ]

  const handleEditTipoEquipamento = (equipamento: IConsultarTipoEquipamentoTabela) => {
    setTipoEquipamentoSelected(equipamento)
    navigate('/EditarTipoEquipamento')
  }

  return(
    <TableContainer component={Styles.StyledPaper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            {header?.map((title) => 
              <Styles.StyledTableCell className={`${title} center`} key={title}>{title}</Styles.StyledTableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {tableData?.map(item => {
            const familia = familiaLista.find(familia => familia.id === item.idFamilia.toString())
            return (
            <TableRow key={item.id}>
              <Styles.StyledTableCell component="th" scope="row">
                {item.ativo ? 'Ativo' : 'Inativo'}
              </Styles.StyledTableCell>
              <Styles.StyledTableCell component="th" scope="row">
                {item.id}
              </Styles.StyledTableCell>
              <Styles.StyledTableCell component="th" scope="row">
                {item.descricao}
              </Styles.StyledTableCell>
              <Styles.StyledTableCell component="th" scope="row">
                {familia?.name}
              </Styles.StyledTableCell>
              <Styles.StyledTableCell component="th" scope="row">
                {moment(item.dataCriacao).format('DD/MM/YYYY - HH:mm:ss')} 
              </Styles.StyledTableCell>
              <Styles.StyledTableCell component="th" scope="row">
                {item.usuarioCriacao}
              </Styles.StyledTableCell>
              <Styles.StyledTableCell component="th" scope="row">
                { item.dataModificacao && 
                    moment(item.dataModificacao)?.format('DD/MM/YYYY - HH:mm:ss')} 
              </Styles.StyledTableCell>
              <Styles.StyledTableCell component="th" scope="row">
                {item.usuarioModificacao}
              </Styles.StyledTableCell>
              <Styles.StyledTableCell className='center' component="th" scope="row">
                <EditIcon onClick={() => handleEditTipoEquipamento(item)} style={{cursor: 'pointer'}}/>
              </Styles.StyledTableCell>
            </TableRow>
          )})}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default ConsultarTipoEquipamentoTabela