import { useState } from "react"
import { IConsultarTipoEquipamentoTabela } from "../pages/tipoEquipamento/types"

export const TipoEquipamentoContext = () => {
  
  const [tipoEquipamentoSelected, setTipoEquipamentoSelected] = useState<IConsultarTipoEquipamentoTabela>({} as IConsultarTipoEquipamentoTabela)
 
  const [tipoEquipamentoLista, setTipoEquipamentoLista] = useState<any[]>([])

  return{
    tipoEquipamentoSelected,
    setTipoEquipamentoSelected,
    tipoEquipamentoLista,
    setTipoEquipamentoLista
  }
}