import { IPaginationList, IResponse } from "../../../core/types";
import { arrayToQuery } from "../../../helpers/util";
import { apiPost } from "../../../services/api";
import { apiGetGeneric, apiPostGeneric } from "../../../services/apiGeneric";
import { IItemTabelaChecklist, IPergunta, IPostFiltroChecklist } from "../types";

const orderServicoUrl = process.env.REACT_APP_API_ORDEMSERVICO;

export const postFiltroChecklist = async (params: IPostFiltroChecklist) => {
    return apiPostGeneric<IResponse<IPaginationList<IItemTabelaChecklist[]>>>(`${orderServicoUrl}/PerguntaRespostaTecnico/filtro`, params);
};

export interface IGetPerguntaGrupos {
    idGrupos: string[];
    ehExportar?: boolean;
}
export const getPerguntasByGrupos = async (params: IGetPerguntaGrupos): Promise<IPergunta[]> => {
    var q = arrayToQuery("idGrupos", params.idGrupos);
    q += "&ehExportar=" + (params.ehExportar ?? false);

    return apiGetGeneric<IPergunta[]>(`${orderServicoUrl}/Pergunta/grupos?${q}`);
};

export const exportCSV = async (params: IPostFiltroChecklist) =>
    await apiPost(`${orderServicoUrl}/PerguntaRespostaTecnico/extracao`, params);

