import React, { useContext, useEffect, useState } from 'react'
import _ from 'lodash'
import { AxiosResponse } from 'axios'
import moment, { Moment } from 'moment'
import CropSquareIcon from '@mui/icons-material/CropSquare';
import { 
  ResponsiveContainer, 
  Bar, 
  BarChart, 
  XAxis, 
  LabelList, 
  Pie, 
  PieChart, 
  Cell,LineChart, 
  Line,
  YAxis,
  Tooltip
} from 'recharts'
import './dashboard.scss'
import DashboardPopoverFiltroData from './dashboardPopoverFiltroData'
import { percentualOs, tempoMedioAtendimento, totalOSAtrasadas, indicadorSLA } from '../services/services'
import { ITecnico } from '../../encaminharOS/types'
import { getGrouped_Services } from '../../farol/services';
import { ReactComponent as FiltroIcon } from "../../../assets/icons/FiltroIcon.svg";
import { siteTheme } from "../../../styles/themeVariables";
import Header from '../../../components/header/header'
import { Breadcrumb, BreadcrumbItem } from '../../../components/breadcrumb/breadcrumb'
import FullscreenLoading from '../../../components/fullscreenLoading/fullscreenLoading';
import { HomeContext } from '../../../contexts/homeContext'
import { AuthContext } from '../../../contexts/auth'

const { color } = siteTheme;

const Dashboard = () => {

  const { 
    setMenuLateral, 
    filtroGrupoServico,
    setListaGrupoServico,
    setListaGrupo_Servico,
    filtroServico
  } = useContext(HomeContext);

  const { user } = useContext(AuthContext);

  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const [initialMonth, setInitialMonth] = useState<Moment>(moment().startOf('month'))
  const [finalMonth, setFinalMonth] = useState<Moment>(moment().endOf('month'))

  const [sla, setSla] = useState<{name: string, value: number}[]>([])

  const [tecnicoSelecionado, setTecnicoSelecionado] = useState<ITecnico[]>([]);

  const [osAtrasoDia, setOSAtrasoDia] = useState<{name: string, value: number}[]>([])

  const [ tempMedio, setTempMedio ] = useState<{name: string, value: number}[]>([])

  const [ osAtraso, setOsAtraso] = useState<{name: string, value: number}[]>([])
  const [ totalOSPreBaixa, setTotalOSPreBaixa ] = useState<{name: string, value: number}[]>([])

  const [filtroGrupoServicoTemp, setFiltroGrupoServicoTemp] =
    useState(filtroGrupoServico.map((item: any) => item.titulo));

  const [filtroServicoTemp, setFiltroServicoTemp] = useState(filtroServico);

  const [loading, setLoading] = useState(false)

  const handlePercentualOs = async () => {
    try{
      const { data } = await percentualOs({
        prestadores: !!user?.prestadores ? user?.prestadores : [],
        dataInicial: initialMonth.format("YYYY-MM-DDTHH:mm:ss"),
        dataFinal: finalMonth.format("YYYY-MM-DDTHH:mm:ss"),
        idTecnico: tecnicoSelecionado?.map((tecnico: ITecnico) => Number(tecnico.id))!,
        idGrupoServico: filtroGrupoServicoTemp,
      }) as AxiosResponse

      setOsAtraso([{
          name: "OS's vencidas",
          value: data.totalOSVencidas
        },
        {
          name: "OS's não vencidas",
          value: 100-data.totalOSVencidas
        }])
      
      let localTotalOSPreBaixa: {name: string, value: number}[] = []
      Object.entries(data).map(([key, val]) => {
        if(key !== 'totalOSVencidas'){
          localTotalOSPreBaixa.push({name: key, value: val as number})
        }
      })

      setTotalOSPreBaixa(localTotalOSPreBaixa)

    }catch(e: any){
      setTotalOSPreBaixa([])
    }
  }

  const getGroupServices = async () => {
    try{
      const { data } = await getGrouped_Services() as AxiosResponse
      setListaGrupo_Servico(data.data)
    } catch (e: any){
      setListaGrupo_Servico([])
    }
  }

  const handleSLALabel = (label: string) => {
    switch(label){
      case 'totalOS':
        return 'Total'
      case 'totalVencido1Dia':
        return '1 dia'
      case 'totalVencido2A3Dias':
        return '2 a 3 dias'
      case 'totalVencido4A5Dias':
        return '4 a 5 dias'
      case 'totalVencidoAcima5Dias':
        return 'Acima de 5 dias'
      default:
        return ''
    }
  }

  const handleOSAtrasadaPorDia = async () => {
    try{
      const { data } = await totalOSAtrasadas({
        prestadores: !!user?.prestadores ? user?.prestadores : [],
        dataInicial: initialMonth.format("YYYY-MM-DDTHH:mm:ss"),
        dataFinal: finalMonth.format("YYYY-MM-DDTHH:mm:ss"),
      }) as AxiosResponse

      let localSlaData: {name: string, value: number}[] = []
      Object.entries(data).map(([key, val]) => {
        localSlaData.push({name: handleSLALabel(key), value: val as number})
      })

      setOSAtrasoDia(localSlaData.reverse())
    } catch (e: any) {
      setOSAtrasoDia([])
    }
   
  }

  const handleTempoMedioAtendimento = async () => {
    try{
      const { data } = await tempoMedioAtendimento({
        tecnicos: tecnicoSelecionado?.map((tecnico: ITecnico) => Number(tecnico.id))!,
        tipoServico: Number(filtroServicoTemp[0]),
        dataInicial: initialMonth.format("YYYY-MM-DDTHH:mm:ss"),
        dataFinal: finalMonth.format("YYYY-MM-DDTHH:mm:ss")
      }) as AxiosResponse

      let localTempMedio: {name: string, value: number}[] = []
      let tempMedioLength = Object.keys(data?.relacaoTecnicos).length
      Object.entries(data?.relacaoTecnicos).map(([key, value]) => {
        const valor = value as {nome: string, media: number}

        const truncate = (name: string, length: number) => 
          (name.length > length) ? name.slice(0, length-1) + '...' : name; 

        if(tempMedioLength >= 5){
          localTempMedio.push({name: truncate(valor.nome, 5), value: valor.media})
        } else if(tempMedioLength > 3) {
          localTempMedio.push({name: truncate(valor.nome, 10), value: valor.media})
        } else {
          localTempMedio.push({name: truncate(valor.nome, 20), value: valor.media})
        }
      })

      setTempMedio(localTempMedio)
      
    } catch(e){
      setTempMedio([])
    }
  }

  const handleIndicadorSLA = async () => {
    try{
      const { data } = await indicadorSLA({
        prestadores: !!user?.prestadores ? user?.prestadores : [],
        tipoOS: filtroGrupoServicoTemp.map(item => Number(item)),
        dataInicial: initialMonth.format("YYYY-MM-DDTHH:mm:ss"),
        dataFinal: finalMonth.format("YYYY-MM-DDTHH:mm:ss")
      }) as AxiosResponse

      const slaLocal: {name: string, value: number}[] = 
        Object.entries(data).map(([key, value]) => ({name: key.replace('Indicador ', ''), value: Number(value)}))
      setSla(slaLocal)

    } catch(e){
      setSla([])
    }
  }

  const handleChangeTechnician = (technician: ITecnico[]) => {
    const tecnicos = technician.map(tecnico => ({label: tecnico.label, id: tecnico.id.toString()}))
    setTecnicoSelecionado(tecnicos)
  }

  const handleOpenFiltros = (currentTarget: any) => {
    setAnchorEl(currentTarget);
    setOpen(!open);
  };

  const handleCloseFiltros = () => {
    setOpen(false);
  };

  const renderCustomizedLabel = (props: any) => {
    const RADIAN = Math.PI / 180;
    const {cx, cy, midAngle, innerRadius, outerRadius, value} = props
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text x={x} y={y} fill={color.secondary} textAnchor="middle" dominantBaseline="central">
        {`${value}%`}
      </text>
    );
  }


  const handleChartsData = async () => {
    setLoading(true)
    await handlePercentualOs()
    await handleOSAtrasadaPorDia()
    if(!_.isEmpty(filtroGrupoServicoTemp)){
      await handleIndicadorSLA()
    }
    if(!_.isEmpty(tecnicoSelecionado) && !_.isEmpty(filtroServicoTemp)){
      await handleTempoMedioAtendimento()
    } else {
      setTempMedio([])
    }
    setLoading(false)

  }

  useEffect(() => {
    getGroupServices()
  }, 
  [])

  useEffect(() => {
    if(!open && initialMonth && finalMonth){
      handleChartsData()
    }
  }, [open])

  const colors = [color.primary, color.cinzaClaro]

  return(
    <section className='Dashboard'>
      {loading && <FullscreenLoading />}
      <Header setMenuLateral={() => setMenuLateral(true)} title="Dashboard" />
      <div className='Breadcrumb-Btn-Container'>
        <Breadcrumb style={{alignItems: 'center', marginBottom: 0}}>
          <BreadcrumbItem>Visualização de KPI's</BreadcrumbItem>
        </Breadcrumb>
        <div className='Dashboard-BtnList-Container'>
          <div
           className='Dashboard-BtnItem' 
           onClick={(event) => handleOpenFiltros(event.currentTarget)}
          >
            <FiltroIcon className={"IconeBtnFarol FiltroIcon"} />
            Filtros
          </div>

          <DashboardPopoverFiltroData 
           id={"Dashboard-PopoverFiltroData"}
           open={open}
           onClose={handleCloseFiltros}
           anchorEl={anchorEl}
           initialMonth={initialMonth}
           setInitialMonth={setInitialMonth}
           finalMonth={finalMonth}
           setFinalMonth={setFinalMonth}
           tecnicoSelecionado={tecnicoSelecionado}
           handleChangeTechnician={handleChangeTechnician}
           filtroGrupoServicoTemp={filtroGrupoServicoTemp}
           setFiltroGrupoServicoTemp={setFiltroGrupoServicoTemp}
           filtroServicoTemp={filtroServicoTemp}
           setFiltroServicoTemp={setFiltroServicoTemp}
          />
        </div>
      </div>
      <div className='Dashboard-body-container'>
        <div className='Row'>
          <div className='Barchart-container'>
            <h3>SLA</h3>
            {!_.isEmpty(sla) ? (
            <ResponsiveContainer width='100%' height={425}>
              <BarChart data={sla}>
                <XAxis axisLine={false} tickLine={false} dataKey="name" />
                <YAxis padding={{ top: 15 }} hide/>
                <Bar
                 radius={[10, 10, 0, 0]} 
                 dataKey="value" 
                 fill={color.primary} 
                 isAnimationActive={false}
                 barSize={100}
                >
                  <LabelList
                   dataKey="value" 
                   position="top" 
                   fontSize={16} 
                   fontWeight={600} 
                   color={color.textCinza}
                  />
                </Bar>
              </BarChart>
            </ResponsiveContainer>
            ) : (
              <div className='NoData'>
                <h3>SLA não encontrada</h3>
              </div>
            )}
          </div>
          <div className='Piechart-container'>
            <h3>OS's em atraso</h3>
            <div className='Piechart-subtitle-container'>
              <div className='Piechart-subtitle-item'>
                <CropSquareIcon 
                 sx={{ 
                  color: colors[1], 
                  backgroundColor: colors[1], 
                  borderRadius: '5px'
                 }}
                />
                <span>OS dentro do prazo</span>
              </div>
              <div className='Piechart-subtitle-item'>
                <CropSquareIcon
                 sx={{ 
                  color: colors[0], 
                  backgroundColor: colors[0], 
                  borderRadius: '5px' 
                 }}
                />
                <span>OS em atraso</span>
              </div>
            </div>
            <ResponsiveContainer width='100%' height={350}>
              <PieChart width={250} height={250}>
                <Pie
                 data={osAtraso} 
                 dataKey="value" 
                 nameKey="name" 
                 cx="50%" 
                 cy="50%" 
                 innerRadius={window.innerWidth > 1100 ? 100 : window.innerWidth > 800 ? 75 : 50} 
                 outerRadius={window.innerWidth > 1100 ? 150 : window.innerWidth > 800 ? 112.5 : 75} 
                 label={renderCustomizedLabel}
                 labelLine={false}
                >
                {osAtraso.map(
                  (entry, index) => 
                    <Cell key={`${entry.value}-${index}`} fill={colors[index % colors.length]}/>
                )}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          </div>
        </div>
        <div className='Row'>
          <div className='Barchart-container OS-Atrasada'>
            <h3>Tempo de atraso das OS's</h3>
            {!_.isEmpty(osAtrasoDia) ? (
            <ResponsiveContainer width='100%' height={425}>
              <BarChart data={osAtrasoDia}>
                <XAxis axisLine={false} tickLine={false} dataKey="name" />
                <YAxis padding={{ top: 15 }} hide/>
                <Bar
                 radius={[10, 10, 0, 0]} 
                 dataKey="value" 
                 fill={color.primary} 
                 isAnimationActive={false}
                 barSize={100}
                >
                  <LabelList
                   dataKey="value" 
                   position="top" 
                   fontSize={16} 
                   fontWeight={600} 
                   color={color.textCinza}
                  />
                </Bar>
              </BarChart>
            </ResponsiveContainer>
            ) : (
              <div className='NoData'>
                <h3>SLA não encontrada</h3>
              </div>
            )}
          </div>
          <div className='Piechart-container'>
            <h3>OS Pré Baixa</h3>
            <div className='Piechart-subtitle-container'>
              <div className='Piechart-subtitle-item'>
                <CropSquareIcon
                 sx={{ 
                   color: colors[1], 
                   backgroundColor: colors[1], 
                   borderRadius: '5px'
                  }}
                  />
                <span>OS Pré Baixa Sucesso</span>
              </div>
              <div className='Piechart-subtitle-item'>
                <CropSquareIcon
                 sx={{ 
                   color: colors[0], 
                   backgroundColor: colors[0], 
                   borderRadius: '5px' 
                  }}
                  />
                <span>OS Pré Baixa Insucesso</span>
              </div>
            </div>
            {(totalOSPreBaixa[0]?.value !== 0 || totalOSPreBaixa[1]?.value !== 0) ? (
            <ResponsiveContainer width='100%' height={350}>
              <PieChart width={250} height={250}>
                <Pie
                 data={totalOSPreBaixa} 
                 dataKey="value" 
                 nameKey="name" 
                 cx="50%" 
                 cy="50%" 
                 innerRadius={window.innerWidth > 1100 ? 100 : window.innerWidth > 800 ? 75 : 50} 
                 outerRadius={window.innerWidth > 1100 ? 150 : window.innerWidth > 800 ? 112.5 : 75} 
                 label={renderCustomizedLabel}
                 labelLine={false}
                >
                {totalOSPreBaixa.map(
                  (entry, index) => 
                    <Cell key={`${entry.value}-${index}`} fill={colors[index % colors.length]}/>
                )}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
            ) : (
              <div className='NoData'>
                <h3>OS's Pré Baixa não encontrada</h3>
              </div>
            )}
          </div>
        </div>
        <div className='Row'>
          <div className='Dashboard-tempo-medio'>
            <h3>Tempo Médio de Atendimento por Técnico</h3>
            {!_.isEmpty(tempMedio) ? (
            <ResponsiveContainer width='100%' height={425}>
              <BarChart data={tempMedio}>
                <XAxis axisLine={false} tickLine={false} dataKey="name" />
                <YAxis padding={{ top: 15 }} hide/>
                <Bar
                  radius={[10, 10, 0, 0]} 
                  dataKey="value" 
                  fill={color.primary} 
                  isAnimationActive={false}
                  barSize={100}
                >
                  <LabelList
                    dataKey="value" 
                    position="top" 
                    fontSize={16} 
                    fontWeight={600} 
                    color={color.textCinza}
                  />
                </Bar>
              </BarChart>
            </ResponsiveContainer>
            ) : (
              <div className='NoData'>
                <h3>Selecionar técnico e serviço.</h3>
              </div>
            )}
          </div>
        </div>
        {/* <div className='Row'>
          
          <div className='Dashboard-footer-vertical-bar-container'>
            <h3>Produtividade média do técnico</h3>
            {!_.isEmpty(data03) ? (
            <ResponsiveContainer width="95%" height={425} >
              <LineChart data={data03}>
                <YAxis type='number' axisLine={false} tickLine={false} domain={['dataMin - 5', 'dataMax']}/>
                <XAxis dataKey="name" />
                <Tooltip />
                <Line type="linear" dataKey="teste" stroke={color.primary}  strokeWidth={5}/>
              </LineChart>
            </ResponsiveContainer>
            ) : (
              <div className='NoData'>
                <h3>Produtividade média do técnico não encontrada</h3>
              </div>
            )}
          </div>
        </div> */}
      </div>
    </section>
  )
}

export default Dashboard