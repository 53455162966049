import React, { useState, useContext, useEffect } from "react";
import { ReactComponent as ExpandIcon } from "../../assets/icons/ExpandIcon.svg";
import styled from "styled-components";
import { siteTheme } from "../../styles/themeVariables";

const {color} = siteTheme;

export interface IDoubleSortIcon { 
    onClick: () => void;
    activeArrowUp: boolean;
    activeArrowDown: boolean;
  }

const DoubleSortIcon = (props: IDoubleSortIcon) => {
    return (
        <Container {...props}>
            <ArrowUp className={!!props.activeArrowUp ? "active" : ""} />
            <ArrowDown className={!!props.activeArrowDown ? "active" : ""}/>
        </Container>
    );
};

export default DoubleSortIcon;



const Container = styled.div`
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 25px;
    height: 30px;
    background-color: inherit;
    padding: 5px;
    box-sizing: border-box;
`;

const ArrowDown = styled(ExpandIcon)`
    width: 10px;
    &.active {
        path {
        fill: ${color.primary};
    }
    }
`;

const ArrowUp = styled(ArrowDown)`
    transform: rotate(180deg);
    margin-bottom: 1px;
`;
