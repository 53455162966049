import React, { useState, useContext } from "react";
import "./cardOSPendentes.scss";
import _ from 'lodash'
import { FormatDateToApi } from "../../helpers/formatDate";
import {
  CardOS,
  CardOSTopo,
  ColunaTopo,
  ColunaTopoTexto,
} from "./style";
import { ICardOSPendentes } from './types'
import { renderStatusOS } from "../../helpers/statusOS";
import { ReactComponent as InfoIcon } from "../../assets/icons/InfoIcon.svg";
import { ReactComponent as Encaminhar } from "../../assets/icons/Encaminhar.svg";

const CardOSPendentes = ({
  item,
  handleEnviarOSPendente,
  handleOpenModal,
}: ICardOSPendentes) => {
  const {
    chamado,
    referencia,
    cep,
    status,
    cidade,
    servico,
    statusFarol,
  } = item;

  return (
    <CardOS id={chamado?.toString()}>
      <CardOSTopo>
        <ColunaTopo>{renderStatusOS(statusFarol)?.icon}</ColunaTopo>

        <ColunaTopo className="Collumcenter" title={chamado.toString()}>
          <ColunaTopoTexto>
            {chamado}
          </ColunaTopoTexto>
        </ColunaTopo>

        <ColunaTopo className="Collumcenter" title={referencia}>
          <ColunaTopoTexto>
            {referencia}
          </ColunaTopoTexto>
        </ColunaTopo>

        <ColunaTopo className="Collumcenter AlignCenter CollumBreak-word" title={status}>
          <ColunaTopoTexto>
            {status}
          </ColunaTopoTexto>
        </ColunaTopo>

        <ColunaTopo className="Collumcenter CollumBreak-word AlignCenter" title={servico}>
          <ColunaTopoTexto>
            {servico}
          </ColunaTopoTexto>
        </ColunaTopo>

        <ColunaTopo className="Collumcenter CollumBreak-word AlignCenter" title={cidade}>
          <ColunaTopoTexto>
            {cidade}
          </ColunaTopoTexto>
        </ColunaTopo>

        <ColunaTopo className="Collumcenter" title={cep}>
          <ColunaTopoTexto>
            {cep}
          </ColunaTopoTexto>
        </ColunaTopo>

        <ColunaTopo className="Collumcenter" title={"Detalhes do erro"}>
          <ColunaTopoTexto>
            <InfoIcon
              className='OSPendente-InfoIcon'
              onClick={() => !!handleOpenModal && handleOpenModal()}
            />
          </ColunaTopoTexto>
        </ColunaTopo>

        <ColunaTopo className="Collumcenter" title={"Reencaminhar"}>
          {status.toLowerCase() === 'erro' &&
            <Encaminhar
              className='OSPendente-Icon'
              onClick={handleEnviarOSPendente}
            />
          }
        </ColunaTopo>
      </CardOSTopo>
    </CardOS>
  );
};

export default CardOSPendentes;
