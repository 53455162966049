import React, { useState, useContext } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as yup from "yup";
import "./changePassword.scss";
import ChangePasswordHeader from "./changePasswordHeader";
import { AuthContext } from "../../../contexts/auth";
import { AxiosError, AxiosResponse } from "axios";
import FullscreenLoading from "../../../components/fullscreenLoading/fullscreenLoading";
import WarningModal from "./warningModal";
import { firstAccessPasswordChange } from "../services/services";

interface IChangePassword {
  antigaSenha: string,
  novaSenha: string,
  confirmaNovaSenha: string,
}

const ChangePassword = () => {
  const { user, logout } = useContext(AuthContext);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [warningText, setWarningText] = useState('');
  const [successChangePassword, setSuccessChangePassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClickChangePassword = async (values: IChangePassword) => {
    setSuccessChangePassword(false);

    if (!validations(values)) {
      setIsModalOpen(true);
    } else {
      setLoading(true);
      try {
        const { data } = await firstAccessPasswordChange({
          idUsuario: user?.idUsuario,
          antigaSenha: values.antigaSenha,
          novaSenha: values.novaSenha
        }) as AxiosResponse;

        setLoading(false);

        if (!!data && typeof data === "string") {
          setWarningText(data);
        } else {
          setWarningText("Senha cadastrada com sucesso!");
        }

        setSuccessChangePassword(true);
        setIsModalOpen(true);

      } catch (e: any) {
        setLoading(false);
        if (e?.response?.data?.errors) {
          setWarningText(Object.values(e.response.data.errors).join("."));
        } else {
          setWarningText("Erro ao alterar senha" + (e?.response?.status ? ` status: ${e?.response?.status}` : ""));
        }
        setIsModalOpen(true);
      }
    }
  };

  const validations = (values: IChangePassword) => {
    return true;
    const { antigaSenha, novaSenha, confirmaNovaSenha } = values;

    let passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[$*&@#])[0-9a-zA-Z$*&@#]{6,}$/;

    if (novaSenha.length <= 1 || confirmaNovaSenha.length <= 1) {
      setWarningText("Não é permitido cadastrar senha com apenas um caractere.");
      return false;
    }

    if (antigaSenha === novaSenha) {
      setWarningText("Nova senha não deve ser igual a atual.");
      return false;
    }

    if (novaSenha !== confirmaNovaSenha) {
      setWarningText("Campos de nova senha não estão iguais.");
      return false;
    }

    if (!passwordRegex.test(novaSenha)) {
      setWarningText("A senha deve conter no mínimo 6 caracteres. 1 letra maiúscula. 1 minúscula. 1 número. 1 caractere especial.");
      return false;
    }

    return true;
  };

  const validationChangePassword = yup.object().shape({
    antigaSenha: yup.string().required("Este campo é obrigatório"),
    novaSenha: yup.string().required("Este campo é obrigatório"),
    confirmaNovaSenha: yup.string().required("Este campo é obrigatório"),
  });

  const handleCloseModal = () => {
    setIsModalOpen(false);
    if (successChangePassword) logout();
  };

  return (
    <div className="ChangePassword" id="ChangePassword">
      <ChangePasswordHeader />
      {loading && <FullscreenLoading />}

      <WarningModal
        isModalOpen={isModalOpen}
        handleCloseModal={handleCloseModal}
        text={warningText}
      />

      <div className="ChangePasswordBox">
        <div className="ChangePasswordBoxWrapper">
          <div className="ChangePasswordBox-texto">Cadastrar nova senha</div>

          <Formik
            initialValues={{
              antigaSenha: "",
              novaSenha: "",
              confirmaNovaSenha: "",
            }}
            onSubmit={handleClickChangePassword}
            validationSchema={validationChangePassword}
          >
            <Form className="ChangePassword-form">
              <div className="ChangePassword-form-group">
                <div className="ChangePassword-form-group-texto">Insira a senha recebida</div>
                <Field
                  id="ChangePassword-form-inputantigaSenha"
                  name="antigaSenha"
                  type="password"
                  className="form-field"
                  placeholder=""
                />
                <ErrorMessage
                  component="span"
                  name="antigaSenha"
                  className="form-error"
                />
              </div>

              <div className="ChangePassword-form-group">
                <div className="ChangePassword-form-group-texto">Cadastrar nova senha</div>
                <Field
                  id="ChangePassword-form-inputnovaSenha"
                  name="novaSenha"
                  type="password"
                  className="form-field"
                  placeholder=""
                />
                <ErrorMessage
                  component="span"
                  name="novaSenha"
                  className="form-error"
                />
              </div>

              <div className="ChangePassword-form-group">
                <div className="ChangePassword-form-group-texto">Confirmar nova senha</div>

                <Field
                  id="ChangePassword-form-inputconfirmaNovaSenha"
                  name="confirmaNovaSenha"
                  type="password"
                  className="form-field"
                  placeholder=""
                />
                <ErrorMessage
                  component="span"
                  name="confirmaNovaSenha"
                  className="form-error"
                />
              </div>

              <button
                id="buttonChangePassword"
                className="ChangePassword-form-button"
                type="submit"
              >
                Cadastrar senha
              </button>
            </Form>
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
