import React, { useState, useContext } from "react";
import "./farolPopoverOrdenar.scss";
import Popover from "@mui/material/Popover";
import Button from "@mui/material/Button";
import MultipleSelectCheckmarks from "../../../components/seletorMultiplo/seletorMultiplo";
import { HomeContext } from "../../../contexts/homeContext";
import { IFarolOSPopoverOrdenar } from "../types";

const FarolOSPopoverOrdenar = ({
  id = "FarolOS-PopoverOrdenar",
  open = false,
  onClose,
  anchorEl = null,
  anchorOrigin = {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin = {
    vertical: "top",
    horizontal: "center",
  },
}: IFarolOSPopoverOrdenar) => {
  const {
    listaOrdenar,
    ordenacao,
    setOrdenacao,
  } = useContext(HomeContext);

  const [ordenacaoTemp, setOrdenacaoTemp] = useState(ordenacao);

  const handleClickOrdenar = () => {
    setOrdenacao(ordenacaoTemp);
    onClose();
  };

  return (
    <Popover
      id={id}
      open={open}
      onClose={onClose}
      anchorEl={anchorEl}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      classes={{ paper: "FarolOS-PopoverOrdenar-box" }}
    >
      <div className="FarolOS-PopoverOrdenar">
        <div className="FarolOS-PopoverOrdenar-label">Ordenar por</div>
        <MultipleSelectCheckmarks
          classes={{
            select: "FarolOS-PopoverOrdenar-Seletor",
            primary: "SeletorOrdenar-Option",
          }}
          multiple={false}
          options={listaOrdenar}
          value={ordenacaoTemp}
          onChange={setOrdenacaoTemp}
          placeholder={"Ordenar"}
          id="multiple-checkbox-ordenar"
          idOption="multiple-checkbox-option-ordenar-"
          labelId="multiple-checkbox-label-ordenar"
        />

        <Button
          id="FarolOS-PopoverOrdenar-BotaoOrdenar"
          variant="contained"
          classes={{
            root: "FarolOS-PopoverOrdenar-BotaoOrdenar",
          }}
          onClick={handleClickOrdenar}
        >
          Ordenar
        </Button>
      </div>
    </Popover>
  );
};

export default FarolOSPopoverOrdenar;
