import React, { useState, useEffect, Fragment } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import './autocompleteTecnicos.scss';
import { IAutocompleteTecnico } from './types'
import _ from 'lodash'
import { Chip } from "@mui/material";

const AutocompletetecnicoId = ({
  id = "Autocompletetecnico",
  placeholder,
  fontSize = 12,
  options,
  noOptionsText = "Sem dados",
  autocompleteValueId = [],
  onChangeValue,
  requestOptions,
  disabled = false,
  label,
  dimension,
  error,
  limitTags = 2
}: IAutocompleteTecnico) => {
  const [value, setValue] = useState<{ label: string, id: string }[]>(autocompleteValueId!);
  const [inputValue, setInputValue] = useState("");
  const [open, setOpen] = useState(false);
  const [getDone, setGetDone] = useState(false);
  const loading = open && options.filter(item => item.id !== 'all').length === 0 && !getDone;

  if(options[0]?.id !== 'all' && !loading && options?.filter(item => item.id !== 'all')?.length !== 0){
    options.unshift({label: 'Todos', id: 'all'})
  }

  const styleError = !!error ? { border: '1px solid #ff2323' } : {};

  const handleListaTecnicos = () => {
    const listaTecnicos = options.filter(tecnico => 
      !value.find(insideTecnico => insideTecnico.id === tecnico.id)
    )

    return listaTecnicos
  }

  const handleChangeValue = (event: any, newValue: any) => {
    let hasAllSelected = false
    hasAllSelected = newValue.some((item: any) => item.id === 'all')
    if(hasAllSelected){
      if(options.filter(item => item.id !== 'all')?.length === newValue.length){
        setValue([])
      } else {
        setValue(options.filter(item => item.id !== 'all'))
      }
    }
    else {
      setValue(newValue);
    }
    if (!!onChangeValue) {
      if(hasAllSelected){
        onChangeValue(options.filter(item => item.id !== 'all'));
      } else {
        onChangeValue(newValue);
      }
    } 
  } 

  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    if (!!requestOptions) requestOptions().then((res: boolean) => {
      if(!!res) setGetDone(true);
    });

    return () => {
      active = false;
    };
  }, [loading]);

  return (
    <Autocomplete
      limitTags={limitTags}
      disableCloseOnSelect
      multiple
      disabled={disabled}
      className="Autocomplete"
      fullWidth
      value={value}
      noOptionsText={noOptionsText}
      classes={{noOptions: "noOptionsWrapperClass"}}
      options={handleListaTecnicos()}
      onChange={handleChangeValue}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      loading={loading}
      disablePortal
      id={id}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            label={option.label}
            size="small"
            style={{ maxWidth: '11.5rem' }}
            {...getTagProps({ index })}
          />
        ))
      }
      renderInput={(params) => (
        <>
        {label && <span className={`label ${dimension}`}>{label}</span>}
        <TextField
          {...params}
          placeholder={_.isEmpty(value) ? placeholder : ''}
          className={`Input-tecnico ${dimension}`}
          InputProps={{
            ...params.InputProps,
            style: !!dimension && dimension === "sm" ?
                  { ...styleError, fontSize: fontSize, minHeight: '44px', borderRadius: '15px' }
                  :
                  { ...styleError, fontSize: fontSize, minHeight: '56px', borderRadius: '15px' },
            endAdornment: (
              <Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </Fragment>
            ),
          }}
        />
        </>
      )}
      renderOption={(params, option) => (
        <li
          {...params}
          key={option.id}
          id={`autocomplete-option-${option.id}`}
        >
          {option.label}
        </li>
      )}
    />
  );
};

export default AutocompletetecnicoId;
