import { apiGet, apiPost, apiPut } from "../../../services/api";
import { IGetPrestadoresResponsaveisParams, IPostPrestadorResponsavel, IPutPrestadorResponsavel } from "../types";

const baseUrl = process.env.REACT_APP_API_ATENDIMENTO

export const getConsultaPrestadoresResponsaveisComFiltro = async ({
  ativo, 
  pageNumber, 
  pageSize
}: IGetPrestadoresResponsaveisParams) => {
  
  let params = `?PageNumber=${pageNumber}&PageSize=${pageSize}`

  params += !!ativo ? `&Ativo=${Boolean(Number(ativo))}` : ''

  return apiGet(`${baseUrl}/PrestadorResponsavel/ConsultaPrestadoresResponsaveis${params}`)
}

export const putPrestadorResponsavel = async (
  idPrestadorResponsavel: number | string, 
  params: IPutPrestadorResponsavel
) =>
  await apiPut(`${baseUrl}/PrestadorResponsavel/${idPrestadorResponsavel}`, params)

export const postPrestadorResponsavel = async (params: IPostPrestadorResponsavel) => 
  await apiPost(`${baseUrl}/PrestadorResponsavel`, params)

export const getPrestadoresResponsaveis = async () =>
  await apiGet(`${baseUrl}/PrestadorResponsavel`)