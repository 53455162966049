import { CircularProgress } from '@mui/material'
import './fullscreenLoading.scss'
import React from 'react'

interface IFullscreenLoading {
  text?: string
}

const FullscreenLoading = ({ text }: IFullscreenLoading) => {
  return(
    <div className='loading'>
      <CircularProgress color='primary'/><br />
      {!!text &&
      <span
        style={{
          color: "white",
          fontSize: "16px",
        }}
        dangerouslySetInnerHTML={{__html: text}}
      />
      }
    </div>
  )
}

export default FullscreenLoading