import React, { useState, useEffect, useContext, useRef } from "react";
import {
  Section,
  SearchButton,
  Body,
  FiltrosLabel,
  NewUserButton,
  ExportarButton,
} from "./consultaTecnico_styles";
import "./consultaTecnico.scss";
import Header from "../../../components/header/header";
import {
  Breadcrumb,
  BreadcrumbItem,
} from "../../../components/breadcrumb/breadcrumb";
import { HomeContext } from "../../../contexts/homeContext";
import { useNavigate } from "react-router-dom";
import MultipleSelectCheckmarksid from "../../../components/seletorMultiploId/seletorMultiploId";
import { ReactComponent as Voltar } from "../../../assets/icons/Voltar.svg";
import { AxiosResponse } from "axios";
import StickyTable from "../../../components/stickyTable/stickyTable";
import { Alert, CircularProgress, IconButton } from '@mui/material';
import Collapse from '../../../components/collapse/collapse';
import CloseIcon from "@mui/icons-material/Close";
import Pagination from '../../../components/pagination/paginacao';
import { getPrestadores } from "../../enviarTecnico/services/services";
import AutocompleteMultiple from "../../../components/autocompleteMultiple/autocompleteMultiple";
import _ from "lodash";
import { IGetTecnicos } from "../types";
import { AuthContext } from "../../../contexts/auth";
import { getListaTecnicosComFiltro } from "../services";
import { ITecnicoConsulta } from "../../../contexts/types/tecnicoTypes";
import { removeSpecialCharSimple } from "../../../helpers/removeSpecialCharSimple";
import { ReactComponent as ExportIcon } from "../../../assets/icons/ExportIcon.svg";
import Input, { MaskedInput } from "../../../components/Input/Input";

const ConsultaTecnico = () => {
  const { setMenuLateral, setTecnicoSelecionado } = useContext(HomeContext);

  const { user, getPermissoesDaTela } = useContext(AuthContext);
  const [ permissoes, setPermissoes] = useState(getPermissoesDaTela("Consultar Técnico"));

  const navigate = useNavigate();

  const isInitialMount = useRef(true);

  const [disableActions, setDisableActions] = useState(false);
  const [status, setStatus] = useState<string[]>(['']);
  const [listaStatus, setListaStatus] = useState<{ name: string, id: string }[]>([
    { name: '--', id: '' },
    { name: 'Ativo', id: '0' },
    { name: 'Inativo', id: '1' },
  ]);
  const [prestadoresDefault, setPrestadoresDefault] = useState<any[]>([]);
  const [prestadores, setPrestadores] = useState<any[]>([]);
  const [selectedPrestadores, setSelectedPrestadores] = useState<any[]>([]);
  const [dadosConsultaTecnico, setDadosConsultaTecnico] = useState<any[]>([]);
  const [nomeTecnico, setNomeTecnico] = useState('')
  const [CPF, setCPF] = useState('')

  const [openAlertError, setOpenAlertError] = useState(false);
  const [msgAlertError, setMsgAlertError] = useState("Nenhum dado encontrado");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [loading, setLoading] = useState<boolean>(false);
  const [qtdeRegistros, setQtdeRegistros] = useState<number>(0);

  const handleButtonSearch = () => {
    if (currentPage !== 1) {
      setCurrentPage(1);
    } else {
      handleSearch();
    }
  };

  const handleSearch = async () => {
    setOpenAlertError(false);
    setLoading(true);
    setDisableActions(true);

    const statusBool = status[0] === '' ? '' : status[0] === '0' ? true : false;

    const params: IGetTecnicos = {
      ...(statusBool !== '' ? { Status: statusBool } : {}),
      IdUsuario: user?.idUsuario,
      PageNumber: currentPage,
      PageSize: pageSize,
      Nome: nomeTecnico,
      cpf: removeSpecialCharSimple(CPF)
    }

    try {
      let retorno = await getListaTecnicosComFiltro(params, selectedPrestadores.map((p: any) => Number(p.id))) as AxiosResponse;
      setLoading(false);
      setDisableActions(false);
      setDadosConsultaTecnico(retorno?.data?.items || []);
      setQtdeRegistros(retorno?.data?.totalCount || 0);

    } catch (error) {
      setLoading(false);
      setDisableActions(false);
      setDadosConsultaTecnico([]);
      setQtdeRegistros(0);
      setMsgAlertError("Nenhum dado encontrado");
      setOpenAlertError(true);
    }
  };

  const objTraducaoHeader = {
    ativo: "Status",
    id: "ID Técnico",
    nomeCompleto: "Nome de Técnico",
    cpf: "CPF",
    celular: "Celular",
    // prestador: "Prestador",
    // idPrestador: "ID Prestador",
    primeiroAcesso: "Primeiro Acesso",
  } as any;

  const getItemByID = (ID: number | string) => {
    let resultadoDados = [...dadosConsultaTecnico];

    return resultadoDados.find(
      (item) => item.id === ID
    )!;
  };

  const handleClickItemEdit = (data: ITecnicoConsulta) => {
    setTecnicoSelecionado(getItemByID(data.id));
    navigate("/EditarTecnico");
  };

  const handleClickEstoque = () => {
    navigate("/EstoqueTecnico", {
      state: {
        rota: {
          url: window.location.pathname,
          name: "Consultar Técnico",
        },
      }
    });
  };

  const smoothScroll = (target: any) => {
    var scrollContainer = target;
    do { //find scroll container
      scrollContainer = scrollContainer?.parentNode;
      if (!scrollContainer) return;
      scrollContainer.scrollTop += 1;
    } while (scrollContainer.scrollTop == 0);

    var targetY = 0;
    do { //find the top of target relatively to the container
      if (target == scrollContainer) break;
      targetY += target.offsetTop;
    } while (target = target.offsetParent);

    const scroll = function (c: any, a: number, b: number, i: number) {
      i++; if (i > 30) return;
      c.scrollTop = a + (b - a) / 30 * i;
      setTimeout(function () { scroll(c, a, b, i); }, 20);
    }
    // start scrolling
    scroll(scrollContainer, scrollContainer.scrollTop, targetY, 0);
  };

  const dadosFormatados = () => {
    let dados = [...dadosConsultaTecnico];

    /**Formata campos para tabela */
    dados = dados.map((item: any) => {
      return {
        ativo: !!item.ativo ? "Ativo" : "Inativo",
        id: item.id,
        nomeCompleto: item.nomeCompleto,
        cpf: item.cpf,
        celular: item.celular,
        // prestador: getProviderByID(item.idPrestador),
        // idPrestador: item.idPrestador, 
        primeiroAcesso: !!item.primeiroAcesso ? "Sim" : "Não",
      };
    });

    return dados;
  };

  const getProvider = async () => {
    try {
      const { data } = await getPrestadores() as AxiosResponse

      let prestadores = [];

      if (!!data && !!data.length) {
        prestadores = data.filter((p: any) => !!p.ativo).map((m: any) => ({ ...m, name: m.nomePrestador, id: m.id?.toString() }))
          .sort((a: any, b: any) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
      }

      setPrestadoresDefault(prestadores);

      if (!_.isEmpty(user?.prestadores)) {
        prestadores = prestadores.filter((prestador: any) => user?.prestadores.includes(Number(prestador.id)))
      }

      setPrestadores(prestadores);

    } catch (e) {
      setPrestadores([]);
    }
  }

  const getProviderByID = (ID: number) => {
    let resultadoDados = [...prestadoresDefault];

    let provider = resultadoDados.find(
      (item) => String(item.id) === String(ID)
    );

    return provider ? provider.name : ID;
  };

  const funcionalidadeTemPermissao = (funcionalidade: string) => {
    let hasPermission = false;
    hasPermission = !![...permissoes].filter((f: {nome: string}) => removeSpecialCharSimple(f.nome).includes(removeSpecialCharSimple(funcionalidade))).length;
    return hasPermission;
  };

  useEffect(() => {
    setTecnicoSelecionado({} as ITecnicoConsulta)
    getProvider();
  }, []);

  useEffect(() => {
    if (!!dadosConsultaTecnico.length) smoothScroll(document.getElementById('TabelaResultadoConsultaTecnico'));
  }, [dadosConsultaTecnico]);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      handleSearch();
    }
  }, [
    currentPage,
    pageSize
  ]);

  return (
    <Section id="ConsultaTecnico">
      <Header
        setMenuLateral={() => setMenuLateral(true)}
        title="Consultar Técnico"
      />
      <Breadcrumb>
        <BreadcrumbItem>Consultar Técnico</BreadcrumbItem>
      </Breadcrumb>

      <Body>
        <FiltrosLabel>Filtros</FiltrosLabel>
        <div className="grid1">
          <MultipleSelectCheckmarksid
            dimension="sm"
            label={"Status"}
            nodata={"Nenhum status encontrado"}
            options={listaStatus}
            value={status}
            onChange={(value) => setStatus(value)}
            placeholder={""}
            id="multiple-checkbox-Status"
            idOption="multiple-checkbox-option-Status-"
            labelId="multiple-checkbox-label-Status"
            classes={{
              select: "ConsultaTecnico-Seletor error",
              primary: "Seletor-Option",
              group: "Seletor-OptionGroup",
            }}
            multiple={false}
            disabled={disableActions}
          />

          <AutocompleteMultiple
            dimension="sm"
            label={"Prestadores"}
            placeholder={""}
            noOptionsText={"Nenhum prestador encontrado"}
            id="multiple-checkbox-Prestadores"
            options={prestadores}
            value={selectedPrestadores}
            onChange={setSelectedPrestadores}
            fontSize={12}
            disabled={disableActions}
            allSelected
          />

          <Input
           label="Nome" 
           value={nomeTecnico} 
           onChange={(e) => setNomeTecnico(e.target.value)}
           dimension="sm"
          />

          <MaskedInput
            value={CPF} 
            onChange={(e) => setCPF(e.target.value)} 
            label={'CPF'}
            dimension='sm' 
            mask='999.999.999-99' 
            id='CPFCNPJInput'
            maskChar={''}
          />

          <SearchButton
            variant="contained"
            size="small"
            onClick={handleButtonSearch}
            disabled={disableActions}
          >
            Pesquisar
          </SearchButton>    
        </div>

        <div className="grid2">
          <NewUserButton
            color="secondary"
            variant="contained"
            size="small"
            onClick={handleClickEstoque}
            disabled={disableActions}
          >
            Consultar Custódia
          </NewUserButton>

          <NewUserButton
            color="secondary"
            variant="contained"
            size="small"
            onClick={() => navigate("/IncluirTecnico")}
            disabled={disableActions}
          >
            Cadastrar Técnico
          </NewUserButton>
        </div>
      </Body>

      {openAlertError &&
        <Collapse onClose={setOpenAlertError} in={openAlertError}>
          <Alert
            severity='error'
            icon={<div />}
            variant="filled"
            sx={{
              mb: 2,
              bgcolor: "rgb(255, 35, 35, 0.7)",
              borderRadius: "15px",
              boxSizing: "border-box",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: '50px',
            }}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="medium"
                onClick={() => setOpenAlertError(false)}
              >
                <CloseIcon
                  id={"ModalEncaminharOS-AlertCloseIcon"}
                  fontSize="inherit"
                  sx={{
                    color: "white",
                  }}
                />
              </IconButton>
            }
          >
            <span
              style={{
                color: "white",
                fontSize: "16px",
              }}
              dangerouslySetInnerHTML={{ __html: msgAlertError }}
            />
          </Alert>
        </Collapse>
      }

      {!!loading && <CircularProgress style={{ margin: '0px auto' }} />}

      {!!dadosConsultaTecnico.length && (
        <>
          <StickyTable
            id={"TabelaResultadoConsultaTecnico"}
            data={dadosFormatados()}
            handleClickEdit={handleClickItemEdit}
            objTraducaoHeader={objTraducaoHeader}
            allowEdit={!!funcionalidadeTemPermissao("Editar Registro")}
            edit={true}
            labelEdit={"Detalhes"}
          />

          <Pagination
            className="pagination-bar"
            currentPage={currentPage}
            totalCount={qtdeRegistros}
            pageSize={pageSize}
            setPageSize={(value: string) => setPageSize(Number(value))}
            onPageChange={(page: number) => setCurrentPage(Number(page))}
            showLabel={false}
          />
        </>
      )}
    </Section>
  );
};

export default ConsultaTecnico;
