import { useState, useEffect } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { Formik, Form, Field, FormikHelpers } from "formik";
import * as yup from "yup";
import "./resetSenha.scss";
import ResetSenhaHeader from "./resetSenhaHeader";
import FullscreenLoading from "../../../components/fullscreenLoading/fullscreenLoading";
import {
  Button,
  Box,
  InputAdornment,
  IconButton,
  TextField,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { atualizaSenha } from "../services/services";
import { AxiosResponse } from "axios";

import Alert from "@mui/material/Alert";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import PasswordCriteria from "../../../components/PasswordCriteria/passwordCriteria";

export interface IResetSenha {
  token: string;
  newPassword: string;
  confirmPassword: string;
}

const ResetSenha = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("Token") as string;
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showAlertResult, setAlertResult] = useState<boolean | null>(null);

  const [isActive, setIsActive] = useState(false);
  const [timeElapsed, setTimeElapsed] = useState(0);
  let intervalId: NodeJS.Timer;
  
  const [inputPasswordContent, setInputPasswordContent] = useState("");

  const handleFocusInputPassword = () => {
    setIsActive(true);
  };

  const handleBlurInputPassword = () => {
    setIsActive(false);
  };

  useEffect(() => {
    if (isActive) {
      intervalId = setInterval(() => {
        setTimeElapsed((prevTime) => prevTime + 1);
        const inputPassword = document.getElementById(
          "new-password"
        ) as HTMLInputElement;
        setInputPasswordContent(inputPassword?.value);
      }, 1000);
    } else {
      clearInterval(intervalId);
      setTimeElapsed(0);
    }

    return () => {
      clearInterval(intervalId);
      setTimeElapsed(0);
    };
  }, [isActive]);

  const handleClickResetSenha = async (
    values: IResetSenha,
    { resetForm }: FormikHelpers<IResetSenha>
  ) => {
    try {
      setLoading(true);
      const responseVerificacaoToken = (await atualizaSenha({
        token: token ?? "",
        newPassword: values.newPassword,
        confirmPassword: values.confirmPassword,
      })) as AxiosResponse;

      if (responseVerificacaoToken.status === 200) {
        setAlertResult(true);
      } else {
        setAlertResult(false);
      }
    } catch (error) {
      console.error("Erro ao redefinir senha:", error);
      setAlertResult(false);
    } finally {
      setLoading(false);
      resetForm();
    }
  };

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleToggleConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const strongPasswordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

  const validationResetSenha = yup.object().shape({
    newPassword: yup
      .string()
      .required("Este campo é obrigatório")
      .matches(
        strongPasswordRegex,
        "A senha deve conter pelo menos 8 caracteres, incluindo letras maiúsculas, minúsculas, números e caracteres especiais"
      ),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("newPassword"), null], "As senhas devem coincidir")
      .required("Este campo é obrigatório"),
  });

  return (
    <div className="ResetSenha" id="ResetSenha">
      <ResetSenhaHeader />
      {loading && <FullscreenLoading />}
      {showAlertResult !== null &&
        (showAlertResult ? (
          <Alert
            icon={<CheckIcon fontSize="inherit" />}
            severity="success"
            action={
              <IconButton
                aria-label="Fechar"
                color="inherit"
                size="small"
                onClick={() => setAlertResult(null)}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            Ação realizada com sucesso!
          </Alert>
        ) : (
          <Alert
            icon={<CheckIcon fontSize="inherit" />}
            severity="error"
            action={
              <IconButton
                aria-label="Fechar"
                color="inherit"
                size="small"
                onClick={() => setAlertResult(null)}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            Ocorreu um erro ao processar sua solicitação. Tente novamente ou
            gere um novo Token.
          </Alert>
        ))}
      <div className="ResetSenhaBox">
        <div className="ResetSenhaBoxWrapper">
          <div className="ResetSenhaBox-texto">Nova senha</div>

          <Formik
            initialValues={{
              token: token,
              newPassword: "",
              confirmPassword: "",
            }}
            validateOnChange={false}
            onSubmit={handleClickResetSenha}
            validationSchema={validationResetSenha}
          >
            {({ values, touched, errors }) => (
              <Form>
                <div className="reset-senha-form-group">
                  <div className="reset-senha-form-group-texto">Senha</div>
                  <Field name="newPassword">
                    {({ field }: { field: any }) => (
                      <TextField
                        {...field}
                        id="new-password"
                        style={{ width: "100%" }}
                        name="newPassword"
                        placeholder="Informe sua senha"
                        value={values.newPassword}
                        onFocus={handleFocusInputPassword}
                        onBlur={handleBlurInputPassword}
                        error={
                          touched.newPassword && Boolean(errors.newPassword)
                        }
                        helperText={touched.newPassword && errors.newPassword}
                        type={showPassword ? "text" : "password"}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton onClick={handleTogglePassword}>
                                {showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  </Field>
                  <PasswordCriteria password={inputPasswordContent} />
                </div>

                <div className="reset-senha-form-group">
                  <div className="reset-senha-form-group-texto">
                    Confirme sua senha
                  </div>
                  <Field name="confirmPassword">
                    {({ field }: { field: any }) => (
                      <TextField
                        {...field}
                        style={{ width: "100%" }}
                        name="confirmPassword"
                        placeholder="Confirme a senha digitada"
                        type={showConfirmPassword ? "text" : "password"}
                        error={
                          touched.confirmPassword &&
                          Boolean(errors.confirmPassword)
                        }
                        helperText={
                          touched.confirmPassword && errors.confirmPassword
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton onClick={handleToggleConfirmPassword}>
                                {showConfirmPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  </Field>
                </div>
                <button
                  id="buttonreset-senha"
                  className="reset-senha-form-button"
                  type="submit"
                >
                  Redefinir senha
                </button>
                <div className="redefinir-senha-opcoes">
                  <Box display="flex" justifyContent="center">
                    <Link to="/Login">
                      <Button
                        align-items="center"
                        size="small"
                        variant="text"
                        startIcon={<ArrowBackIcon />}
                      >
                        Voltar ao Login
                      </Button>
                    </Link>
                  </Box>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default ResetSenha;
