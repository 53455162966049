import React, { useState, useEffect, useContext } from "react";
import {
  Section,
  SearchButton,
  Body,
  NewUserButton,
  ExportarButton,
  ButtonsBox,
} from "./consultaServico_styles";
import "./consultaServico.scss";
import Header from "../../../components/header/header";
import {
  Breadcrumb,
  BreadcrumbItem,
} from "../../../components/breadcrumb/breadcrumb";
import { HomeContext } from "../../../contexts/homeContext";
import { useNavigate } from "react-router-dom";
import MultipleSelectCheckmarksid from "../../../components/seletorMultiploId/seletorMultiploId";
import { AxiosResponse } from "axios";
import StickyTable from "../../../components/stickyTable/stickyTable";
import { Alert, CircularProgress, IconButton } from '@mui/material';
import Collapse from '../../../components/collapse/collapse';
import CloseIcon from "@mui/icons-material/Close";
import Pagination from '../../../components/pagination/paginacao';
import _ from "lodash";
import { IGetServicos, IGetResponseServicos, IExportCSVServicos, IGetResponseServico } from "../types";
import { AuthContext } from "../../../contexts/auth";
import { getListaServicosComFiltro, exportCSV } from "../services";
import { smoothScroll } from "../../../helpers/smoothScroll";
import { Grupo_Servico } from "../../../contexts/types/farolTypes";
import { getGrouped_Services } from "../../farol/services";
import Input from "../../../components/Input/Input";
import AutocompleteMultiple from "../../../components/autocompleteMultiple/autocompleteMultiple";
import { ResponseApi } from "../../../contexts/types/responsesHttp";
import AlertMessage from "../../../components/AlertMessage/alertMessage";

const ConsultaServico = () => {
  const { setMenuLateral, setServicoSelecionado } = useContext(HomeContext);

  const { funcionalidadeDaTelaTemPermissao } = useContext(AuthContext);
  const profileHasPermission = (funcionalidade: string) => funcionalidadeDaTelaTemPermissao("Consultar Serviços", funcionalidade);

  const navigate = useNavigate();

  const [disableActions, setDisableActions] = useState(false);
  const [listaStatus, setListaStatus] = useState<{ name: string, id: string }[]>([
    { name: '--', id: '' },
    { name: 'Ativo', id: '0' },
    { name: 'Inativo', id: '1' },
  ]);
  const [listaGrupoServico, setListaGrupoServico] = useState<Grupo_Servico[]>([]);
  const [groupesServicesobject, setGroupesServicesobject] = useState<any>({});

  const [filtroStatus, setFiltroStatus] = useState<string[]>(['']);
  const [filtroGrupoServico, setFiltroGrupoServico] = useState<any[]>([]);
  const [filtroNome, setFiltroNome] = useState<string>("");

  const [dadosConsultaServico, setDadosConsultaServico] = useState<any[]>([]);

  const [openAlertError, setOpenAlertError] = useState(false);
  const [msgAlertError, setMsgAlertError] = useState("Nenhum dado encontrado");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [loading, setLoading] = useState<boolean>(false);
  const [qtdeRegistros, setQtdeRegistros] = useState<number>(0);
  const [loadingCSV, setLoadingCSV] = useState<boolean>(false);

  const handleButtonSearch = () => {
    if (currentPage !== 1) {
      setCurrentPage(1);
    } else {
      handleSearch();
    }
  };

  const handleSearch = async () => {
    setOpenAlertError(false);
    setLoading(true);
    setDisableActions(true);

    const statusBool = filtroStatus[0] === '' ? '' : filtroStatus[0] === '0' ? true : false;

    const params: IGetServicos = {
      ...(statusBool !== '' ? { Ativo: statusBool } : {}),
      ...(!!filtroGrupoServico.length && !!filtroGrupoServico[0]?.id ? { 'IdGrupoServico': Number(filtroGrupoServico[0].id) } : {}),
      ...(filtroNome !== '' ? { Nome: filtroNome } : {}),
    }

    try {
      const { data }: { data: IGetResponseServicos } = await getListaServicosComFiltro(params) as AxiosResponse;

      setLoading(false);
      setDisableActions(false);
      setDadosConsultaServico(data?.data ?? []);
      setQtdeRegistros(data?.data?.length ?? 0);

    } catch (error) {
      setLoading(false);
      setDisableActions(false);
      setDadosConsultaServico([]);
      setQtdeRegistros(0);
      setMsgAlertError("Nenhum dado encontrado");
      setOpenAlertError(true);
    }
  };

  const handleExport = async () => {
    setLoadingCSV(true);

    const statusBool = filtroStatus[0] === '' ? '' : filtroStatus[0] === '0' ? true : false;

    const filtros: IExportCSVServicos = {
      ...(statusBool !== '' ? { ativo: statusBool } : {}),
      ...(!!filtroGrupoServico.length && !!filtroGrupoServico[0]?.id ? { 'idGrupoServico': Number(filtroGrupoServico[0].id) } : {}),
      ...(filtroNome !== '' ? { nome: filtroNome } : {}),
    };

    try {
      const { data } = await exportCSV(filtros) as AxiosResponse;
      setLoadingCSV(false);

      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Services.csv');
      document.body.appendChild(link);
      link.click();
    } catch (e) {
      setMsgAlertError("Erro ao exportar CSV");
      setOpenAlertError(true);
      setLoadingCSV(false);
    }
  };

  const getItemByID = (ID: number | string) => {
    let resultadoDados = [...dadosConsultaServico];

    return resultadoDados.find(
      (item) => item.id === ID
    )!;
  };

  const handleClickItemEdit = (data: IGetResponseServico) => {
    setServicoSelecionado(getItemByID(data.id));
    navigate("/EditarServico");
  };

  const objTraducaoHeader = {
    ativo: "Status",
    id: "Código (ID) do serviço",
    grupoServico: "Grupo de Serviços",
    nome: "Nome do Serviço",
  } as any;

  const dadosFormatados = () => {
    let dados = [...dadosConsultaServico];

    /**Formata campos para tabela */
    dados = dados.map((item: any) => {
      return {
        ativo: !!item.ativo ? "Ativo" : "Inativo",
        id: item.id,
        grupoServico: groupesServicesobject[item.idGrupoServico] ?? "",
        nome: item.nome,
      };
    });

    return paginacaoLocal(dados);
  };

  const paginacaoLocal = (dados: any[]) => {
    const firstPageIndex = (currentPage - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;
    return dados.slice(firstPageIndex, lastPageIndex);
  };

  const handleGetGroupesServices = async () => {
    await getGrouped_Services({
      Ativo: true,
    }).then((resp: any) => {

      const respSuccess = resp?.data as ResponseApi<Grupo_Servico[]>;
      const grupos = respSuccess?.data as any[] ?? [];

      setListaGrupoServico(grupos);
      createGroupesServicesObject(grupos);
    }, err => {
      const respErr = err?.response?.data as ResponseApi<any>;
    });
  };

  const createGroupesServicesObject = (grupos: any[]) => {
    const gruposObject = grupos.reduce((inicial, item) => {
      if (!inicial[item.id]) inicial[item.id] = item.nome;
      return inicial;
    }, {});

    setGroupesServicesobject(gruposObject);
  };

  useEffect(() => {
    handleGetGroupesServices();
    setServicoSelecionado({} as IGetResponseServico);
  }, []);

  useEffect(() => {
    if (!!dadosConsultaServico.length) smoothScroll(document.getElementById('TabelaResultadoConsultaServico'));
  }, [dadosConsultaServico]);

  return (
    <Section id={"ConsultaServico"}>
      <Header
        setMenuLateral={() => setMenuLateral(true)}
        title="Serviços"
      />
      <Breadcrumb>
        <BreadcrumbItem>Serviços</BreadcrumbItem>
      </Breadcrumb>

      <Body>
        <div className="grid1">
          <MultipleSelectCheckmarksid
            dimension="sm"
            label={"Status"}
            nodata={"Nenhum filtroStatus encontrado"}
            options={listaStatus}
            value={filtroStatus}
            onChange={(value) => setFiltroStatus(value)}
            placeholder={""}
            id="multiple-checkbox-Status"
            idOption="multiple-checkbox-option-Status-"
            labelId="multiple-checkbox-label-Status"
            classes={{
              select: "ConsultaServico-Seletor error",
              primary: "Seletor-Option",
              group: "Seletor-OptionGroup",
            }}
            multiple={false}
            disabled={disableActions}
          />

          <AutocompleteMultiple
            dimension="sm"
            label={"Código Grupo de Serviços"}
            placeholder={""}
            noOptionsText="Nenhum grupo de serviço encontrado"
            options={listaGrupoServico?.map((item: any) => ({ name: item.nome, id: item.id.toString() }))!}
            value={filtroGrupoServico}
            onChange={setFiltroGrupoServico}
            id="multiple-checkbox-grupoServico"
            multiple={false}
          />

          <Input
            className={"ConsultaServico-Input"}
            dimension="sm"
            id="input-NomeDoServico"
            label={"Nome do serviço"}
            value={filtroNome}
            onChange={(e) => setFiltroNome(e.target.value)}
            maxLength={255}
            disabled={disableActions}
          />
        </div>
      </Body>

      <AlertMessage
        isOpenAlert={openAlertError}
        setOpenAlert={setOpenAlertError}
        alertType={"error"}
        msgAlert={msgAlertError}
      />

      {!!loading && <CircularProgress style={{ margin: '0px auto' }} />}

      {!!dadosConsultaServico.length && (
        <>
          <StickyTable
            id={"TabelaResultadoConsultaServico"}
            data={dadosFormatados()}
            handleClickEdit={handleClickItemEdit}
            objTraducaoHeader={objTraducaoHeader}
            allowEdit={!!profileHasPermission("Editar Registro")}
            edit={true}
            labelEdit={"Detalhes"}
          />

          <Pagination
            className="pagination-bar"
            currentPage={currentPage}
            totalCount={qtdeRegistros}
            pageSize={pageSize}
            setPageSize={(value: string) => setPageSize(Number(value))}
            onPageChange={(page: number) => setCurrentPage(Number(page))}
            showLabel={false}
          />
        </>
      )}

      <ButtonsBox>
        <div />

        {!!dadosConsultaServico.length ?
          <>
            {!!profileHasPermission("Exportar CSV") ?
              <ExportarButton
                color="secondary"
                variant="contained"
                size="small"
                onClick={handleExport}
                disabled={disableActions || loadingCSV}
              >
                {loadingCSV ? <CircularProgress color="inherit" size={20} /> : 'Exportar CSV'}
              </ExportarButton>
              :
              <ExportarButton
                color="secondary"
                variant="contained"
                size="small"
                disabled={true}
                className="bloqueado"
              >
                Exportar CSV
              </ExportarButton>
            }
          </>
          :
          <div />
        }

        {!!profileHasPermission("Novo Registro") ?
          <NewUserButton
            color="secondary"
            variant="contained"
            size="small"
            onClick={() => navigate("/IncluirServico")}
            disabled={disableActions}
          >
            Incluir
          </NewUserButton>
          :
          <NewUserButton
            color="secondary"
            variant="contained"
            size="small"
            disabled={true}
            className="bloqueado"
          >
            Incluir
          </NewUserButton>
        }

        <SearchButton
          variant="contained"
          size="small"
          onClick={handleButtonSearch}
          disabled={disableActions}
        >
          Consultar
        </SearchButton>
      </ButtonsBox>
    </Section>
  );
};

export default ConsultaServico;
