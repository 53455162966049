import { useState } from "react";
import { IGetResponseCidade } from "../pages/cidades/types";

export const CidadeContext = () => {
  const [cidadeSelecionada, setCidadeSelecionada] = useState({} as IGetResponseCidade);

  return {
    cidadeSelecionada,
    setCidadeSelecionada
  }
};