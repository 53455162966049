import { useState } from "react"

export const FamiliaContext = () => {
  
  const [familiaLista, setFamiliaLista] = useState<{name: string, id: string}[]>([])

  return{
    familiaLista,
    setFamiliaLista
  }
}