import { useContext, useState } from "react";
import { HomeContext } from "../../../contexts/homeContext";
import { AuthContext } from "../../../contexts/auth";
import { useNavigate } from "react-router-dom";
import { IGetRegioes, IGetResponseRegiao } from "../types";
import { getListaRegioesComFiltro } from "../services";
import { AxiosResponse } from "axios";
import {
  Body,
  ButtonsBox,
  FiltrosLabel,
  NewUserButton,
  SearchButton,
  Section,
} from "./consultaRegiao_Styles";
import Header from "../../../components/header/header";
import {
  Breadcrumb,
  BreadcrumbItem,
} from "../../../components/breadcrumb/breadcrumb";
import MultipleSelectCheckmarksid from "../../../components/seletorMultiploId/seletorMultiploId";
import Input from "../../../components/Input/Input";
import { CircularProgress } from "@mui/material";
import StickyTable from "../../../components/stickyTable/stickyTable";
import Pagination from "../../../components/pagination/paginacao";
import AlertMessage from "../../../components/AlertMessage/alertMessage";

const ConsultaRegiao = () => {
  const { setMenuLateral, setRegiaoSelecionada } = useContext(HomeContext);
  const { funcionalidadeDaTelaTemPermissao } = useContext(AuthContext);
  const profileHasPermission = (funcionalidade: string) =>
    funcionalidadeDaTelaTemPermissao("Consultar Regiões", funcionalidade);

  const navigate = useNavigate();

  const [disableActions, setDisableActions] = useState(false);
  const [listaStatus, setListaStatus] = useState<
    { name: string; id: string }[]
  >([
    { name: "--", id: "" },
    { name: "Ativo", id: "0" },
    { name: "Inativo", id: "1" },
  ]);

  const [filtroStatus, setFiltroStatus] = useState<string[]>([""]);
  const [filtroNome, setFiltroNome] = useState<string>("");

  const [dadosConsultaRegiao, setDadosConsultaRegiao] = useState<any[]>([]);

  const [openAlertError, setOpenAlertError] = useState(false);
  const [msgAlertError, setMsgAlertError] = useState("Nenhum dado encontrado");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [loading, setLoading] = useState<boolean>(false);
  const [qtdeRegistros, setQtdeRegistros] = useState<number>(0);

  const handleButtonSearch = () => {
    currentPage !== 1 ? setCurrentPage(1) : handleSearch();
  };

  const handleSearch = async () => {
    setOpenAlertError(false);
    setLoading(true);
    setDisableActions(true);

    const statusBool =
      filtroStatus[0] === "" ? "" : filtroStatus[0] === "0" ? true : false;

    const params: IGetRegioes = {
      ...(statusBool !== "" ? { Ativo: statusBool } : {}),
      ...(filtroNome !== "" ? { Nome: filtroNome } : {}),
    };

    try {
      const { data }: { data: IGetResponseRegiao[] } =
        (await getListaRegioesComFiltro(params)) as AxiosResponse;
      setLoading(false);
      setDisableActions(false);
      setDadosConsultaRegiao(data ?? []);
      setQtdeRegistros(data?.length ?? 0);
    } catch (error) {
      setLoading(false);
      setDisableActions(false);
      setDadosConsultaRegiao([]);
      setQtdeRegistros(0);
      setMsgAlertError("Nenhum dado encontrado");
      setOpenAlertError(true);
    }
  };

  const getItemByID = (ID: number | string) => {
    let resultadoDados = [...dadosConsultaRegiao];

    return resultadoDados.find((item) => item.id === ID)!;
  };

  const handleClickItemEdit = (data: IGetResponseRegiao) => {
    setRegiaoSelecionada(getItemByID(data.id));
    navigate("/EditarRegiao");
  };

  const objTraducaoHeader = {
    ativo: "Status",
    id: "Código (ID) da região",
    nome: "Nome da Região",
  } as any;

  const dadosFormatados = () => {
    let dados = [...dadosConsultaRegiao];

    /**Formata campos para tabela */
    dados = dados.map((item: any) => {
      return {
        ativo: !!item.ativo ? "Ativo" : "Inativo",
        id: item.id,
        nome: item.nome,
      };
    });

    return paginacaoLocal(dados);
  };

  const paginacaoLocal = (dados: any[]) => {
    const firstPageIndex = (currentPage - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;
    return dados.slice(firstPageIndex, lastPageIndex);
  };

  return (
    <Section id={"ConsultaRegiao"}>
      <Header setMenuLateral={() => setMenuLateral(true)} title="Regiões" />
      <Breadcrumb>
        <BreadcrumbItem>Regiões</BreadcrumbItem>
      </Breadcrumb>

      <Body>
      <FiltrosLabel>Filtros</FiltrosLabel>
        <div className="grid1">
          <MultipleSelectCheckmarksid
            dimension="sm"
            label={"Status"}
            nodata={"Nenhum filtroStatus encontrado"}
            options={listaStatus}
            value={filtroStatus}
            onChange={(value) => setFiltroStatus(value)}
            placeholder={""}
            id="multiple-checkbox-Status"
            idOption="multiple-checkbox-option-Status-"
            labelId="multiple-checkbox-label-Status"
            classes={{
              select: "ConsultaRegiao-Seletor error",
              primary: "Seletor-Option",
              group: "Seletor-OptionGroup",
            }}
            multiple={false}
            disabled={disableActions}
          />
          <Input
            className={"ConsultaRegiao-Input"}
            dimension="sm"
            id="input-NomeDaRegiao"
            label={"Nome da região"}
            value={filtroNome}
            onChange={(e) => setFiltroNome(e.target.value)}
            maxLength={255}
            disabled={disableActions}
          />
          {!!profileHasPermission("Novo Registro") ? (
            <NewUserButton
              color="secondary"
              variant="contained"
              size="small"
              onClick={() => navigate("/IncluirRegiao")}
              disabled={disableActions}
            >
              Incluir
            </NewUserButton>
          ) : (
            <NewUserButton
              color="secondary"
              variant="contained"
              size="small"
              disabled={true}
              className="bloqueado"
            >
              Incluir
            </NewUserButton>
          )}
          <SearchButton
            variant="contained"
            size="small"
            onClick={handleButtonSearch}
            disabled={disableActions}
          >
            Consultar
          </SearchButton>
        </div>
      </Body>

      <AlertMessage
        isOpenAlert={openAlertError}
        setOpenAlert={setOpenAlertError}
        alertType={"error"}
        msgAlert={msgAlertError}
      />

      {!!loading && <CircularProgress style={{ margin: "0px auto" }} />}

      {!!dadosConsultaRegiao.length && (
        <>
          <StickyTable
            id={"TabelaResultadoConsultaRegiao"}
            data={dadosFormatados()}
            handleClickEdit={handleClickItemEdit}
            objTraducaoHeader={objTraducaoHeader}
            allowEdit={!!profileHasPermission("Editar Registro")}
            edit={true}
            labelEdit={"Detalhes"}
          />

          <Pagination
            className="pagination-bar"
            currentPage={currentPage}
            totalCount={qtdeRegistros}
            pageSize={pageSize}
            setPageSize={(value: string) => setPageSize(Number(value))}
            onPageChange={(page: number) => setCurrentPage(Number(page))}
            showLabel={false}
          />
        </>
      )}
    </Section>
  );
};

export default ConsultaRegiao;
