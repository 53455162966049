import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import Paper from '@mui/material/Paper';
import { minHeight } from '@mui/system';

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.common.white,
    borderLeft: `1px solid ${theme.palette.secondary.main}`,
    '&.center': {
      textAlign: 'center',
    }
  },
  [`&.${tableCellClasses.body}:not(:first-of-type)`]: {
    borderLeft: `1px solid ${theme.palette.secondary.light}`,
    
    '&.center': {
      textAlign: 'center'
    }
  },
}));

export const StyledPaper = styled(Paper)(({theme}) => ({
  border: `1px solid ${theme.palette.secondary.light}`,
  maxHeight: '560px',

  [theme.breakpoints.up('lg')]: {
    maxHeight: '70vh',
  }
}))