import styled from "styled-components";
import { Button } from "@mui/material";
import { siteTheme } from "../../../styles/themeVariables";
import { ReactComponent as ExpandIcon } from "../../../assets/icons/ExpandIcon.svg";

const { color } = siteTheme;

export const Section = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
`;

export const ButtonWrapper = styled.div`
  margin: 7px 0px 28px 0px;
  padding: 0px 52px 0px 32px;
  display: flex;
  justify-content: flex-end;
`;

export const ArrowDown = styled(ExpandIcon)`
    width: 18px;
`;

export const ContainerButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
`;

export const SearchButton = styled(Button)`
  font-size: 14px !important;
  height: 44px !important;
  border-radius: 15px !important;
  padding: 0 40px 0 40px !important;
  margin: 31px 0px 30px 0px !important;
`;

export const NewUserButton = styled(Button)`
  background: #AFB1B1 !important;
  color: #FFF !important;
  font-size: 14px !important;
  height: 44px !important;
  border-radius: 15px !important;
  padding: 0 40px 0 40px !important;
`;

export const ExportarButton = styled(Button)`
  border: 1px solid ${color.primary} !important;
  font-size: 14px !important;
  height: 44px !important;
  border-radius: 15px !important;
  padding: 0 40px 0 40px !important;

  & .MuiButton-startIcon {
    margin-top: -4px;
    margin-right: 14px;
  }

  & .MuiButton-startIcon {
    font-size: 22px;
  }
`;

export const Body = styled.div`
  margin-top: 30px;
  margin-bottom: 50px;
  padding: 0px 35px;
  display: flex;
  flex-direction: column;
  width: 100%;

  /**Dados da OS */
  .AccordionDadosDaOS-grid1 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 20px;
    margin-bottom: 20px;
    -webkit-box-align: baseline;
    align-items: end;
  }
  
  .AccordionDadosDaOS-grid2 {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    column-gap: 20px;
    -webkit-box-align: baseline;
    align-items: end;
  }

  /**Produtos E Serviços */
  .AccordionProdutosEServicos-grid1 {
    display: grid;
    grid-template-columns: 1fr 164px 2fr;
    column-gap: 20px;
    margin-bottom: 20px;
    -webkit-box-align: baseline;
    align-items: end;
  }

  .AccordionProdutosEServicos-grid2 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-row: auto;
    gap: 20px;
    margin-bottom: 30px;
    -webkit-box-align: baseline;
    align-items: end;
    position: relative;

    border-radius: 15px;
    background: #F8F8F8;
    padding: 10px 30px 20px 30px;
  }

  .AccordionProdutosEServicos-grid3 {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-row: auto;
    gap: 20px;
    margin-bottom: 30px;
    -webkit-box-align: baseline;
    align-items: end;
    position: relative;

    border-radius: 15px;
    background: #F8F8F8;
    padding: 10px 30px 20px 30px;
  }

  /**Perído */
  .AccordionPeriodo-grid1 {
    display: grid;
    grid-template-columns: 2fr 30px 1fr;
    column-gap: 20px;
    margin-bottom: 30px;
    -webkit-box-align: baseline;
    align-items: end;

    border-radius: 15px;
    background: #F8F8F8;
    padding: 10px 30px 20px 30px;
  }
  
  .AccordionPeriodo-grid2 {
    display: grid;
    grid-template-columns: 1fr 164px 2fr;
    column-gap: 20px;
    margin-bottom: 20px;
    -webkit-box-align: baseline;
    align-items: end;
  }

  .AccordionPeriodo-grid3 {
    display: grid;
    grid-template-columns: 2fr 30px 1fr;
    column-gap: 20px;
    margin-bottom: 30px;
    -webkit-box-align: baseline;
    align-items: end;
    position: relative;

    border-radius: 15px;
    background: #F8F8F8;
    padding: 10px 30px 20px 30px;
  }

  /**Dados do Cliente */
  .AccordionDadosDoCliente-grid1 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 20px;
    margin-bottom: 20px;
    -webkit-box-align: baseline;
    align-items: end;
  }
  
  .AccordionDadosDoCliente-grid2 {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 20px;
    margin-bottom: 20px;
    -webkit-box-align: baseline;
    align-items: end;
  }
  
  /**Atendimento */
  .AccordionAtendimento-grid1 {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 20px;
    margin-bottom: 20px;
    -webkit-box-align: baseline;
    align-items: end;
  }

  .CloseIconMaisBusca {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }

  @media only screen and (max-width: 1000px) {
    grid-template-columns: 1fr;

    .gridresponsive {
      grid-template-columns: 1fr;
      gap: 44px 20px;
    }
  }
`;

export const ORLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  color: #797D8C;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const FiltrosLabel = styled.div`
  color: #797D8C;
  font-weight: 600;
  font-size: 22px;
  position: relative;
  top: -22px;
`;

export const SubHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 25px 0 35px;
`;

export const SubHeaderTitle = styled.div`
    font-family: Noto Sans;
    font-size: 2rem;
    font-weight: 700;
    color: #797d8c;
    letter-spacing: 0.9px;
`;

export const InputsRowDatePickers = styled.div`
  display: flex;
  align-items: start;
  gap: 8px;

  @media only screen and (max-width: 1000px) {
    flex-direction: column;
    gap: 20px;
  }
`;

export const InputLabel = styled.div`
  color: #797D8C;
  font-size: 0.75rem !important;
  font-weight: 600;
  margin-bottom: 27px;
`;

export const ResultLabel = styled.div`
  color: #000;
  font-size: 16px;
  margin-bottom: 50px;
`;

export const ExportContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 50px;
`;

export const PresetsContainer = styled.div`
  display: grid;
  grid-template-columns: 150px 1fr 2fr;
  column-gap: 20px;
  margin-bottom: 20px;
  -webkit-box-align: baseline;
  align-items: end;
`;