import { useState } from "react";
import _ from "lodash";
import { IOrdemServico } from "./types/farolTypes";
import { ITecnicos, IEncaminharOSContext } from './types/encaminharTypes'

export const EncaminharOSContext = ( listaos: IOrdemServico[] ) => {
  const [listaTecnicos, setListaTecnicos] = useState<ITecnicos[]>();
  const [selectedTecnico, setSelectedTecnico] = useState<ITecnicos>()

  const listaTecnicosByPrestadorTratada = () => {
    let resultadoDados;

    resultadoDados = listaTecnicos?.map((item: ITecnicos) => {
      return {
        label: item.nome || item.nomeCompleto,
        id: item?.idTecnico?.toString() || item.id?.toString(),
      };
    });

    /**Remove itens duplicados */
    resultadoDados = _.uniqBy(resultadoDados, 'id');

    return _.sortBy(resultadoDados, ["label"]);
  };

  const getOSsByID = (IDs = []) => {
    let resultadoDados = listaos;
    let newResults: IOrdemServico[] = [];

    IDs.forEach((id) => {
      let newItem: IOrdemServico = resultadoDados.find(
        (item) => item.chamado === id
      )!;

      if (!!newItem) newResults.push(newItem);
    });

    return newResults;
  };

  return {
    listaTecnicosByPrestadorTratada,
    setListaTecnicos,
    getOSsByID,
    setSelectedTecnico,
    selectedTecnico,
    listaTecnicos
  } as IEncaminharOSContext;
};
