import React from "react";
import classnames from "classnames";
import { usePagination, DOTS } from "./usePagination";
import { ReactComponent as ExpandIcon } from "../../assets/icons/ExpandIcon.svg";
import "./paginacao.scss";

interface IPagination {
  onPageChange: Function,
  totalCount: number,
  siblingCount?: number,
  currentPage: number,
  pageSize: number,
  setPageSize: Function,
  className: string,
  arrQtdPageSelector?: number[],
  showLabel?: boolean,
  labelQtdeLeft?: string,
  labelQtdeRight?: string,
  showQtdPageSelectBox?: boolean,
}

const Pagination = (props: IPagination) => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
    setPageSize,
    className,
    arrQtdPageSelector = [10, 25, 50, 100],
    showLabel = true,
    showQtdPageSelectBox = true,
    labelQtdeLeft = "Exibir",
    labelQtdeRight = "OS's por página.",
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  let lastPage = paginationRange![paginationRange!.length - 1];

  const handleSetPageSize = (value: string) => {
    if (setPageSize) {
      setPageSize(value);
      onPageChange(1);
    }
  };

  return (
    <div className="pagination-containerwrapper">
      {currentPage === 0 || paginationRange!.length < 2 ? (
        <div />
      ) : (
        <ul
          className={classnames("pagination-container", {
            [className]: className,
          })}
        >
          {/* Seta de navegação da esquerda */}
          {currentPage !== 1 && (
            <li
              className={classnames("pagination-item", {
                disabled: currentPage === 1,
              })}
              key={"Pagination_Arrow_left"}
              id={"Pagination_Arrow_left"}
              onClick={onPrevious}
            >
              <div className="arrow left" />
            </li>
          )}

          {paginationRange?.map((pageNumber, index) => {
            // Se o pageItem for um PONTO (DOT), renderize o caractere unicode DOTS
            if (pageNumber === DOTS) {
              return (
                <li
                  className="pagination-item dots"
                  key={`Pagination_DOTS_${index}`}
                  id={`Pagination__DOTS_${index}`}
                >
                  &#8230;
                </li>
              );
            }

            // Renderize a amostra de página
            return (
              <li
                className={classnames("pagination-item", {
                  selected: pageNumber === currentPage,
                })}
                onClick={() => onPageChange(pageNumber)}
                key={`Pagination_PAGE_${index}`}
                id={`Pagination_PAGE_${pageNumber}`}
              >
                {pageNumber}
              </li>
            );
          })}

          {/*  Seta de navegação da direita */}
          <li
            className={classnames("pagination-item", {
              disabled: currentPage === lastPage,
            })}
            key={"Pagination_Arrow_right"}
            id={"Pagination_Arrow_right"}
            onClick={onNext}
          >
            <div className="arrow right" />
          </li>
        </ul>
      )}

      <div className="pagination-containerQtdByPage">
        {!!showLabel && (
          <span className="pagination-containerQtdByPage-span spanLabel1">
            {labelQtdeLeft}
          </span>
        )}
        {!!showQtdPageSelectBox && (
          <div className="qtdPageselectDiv">
          <select
            id="SelectInput-QtdByPage"
            name="txtQtdByPage"
            className="qtdPageSelectBox"
            value={pageSize}
            onChange={(e) => handleSetPageSize(e.target.value)}
          >
            {arrQtdPageSelector.map((item) => (
              <option key={item} id={`SelectOptionID_${item}`} value={item}>
                {item}
              </option>
            ))}
          </select>
          <ExpandIcon className="qtdPageSelectBox-ExpandIcon" />
        </div>
        )}
        {!!showLabel && (
          <span className="pagination-containerQtdByPage-span spanLabel2">
            {labelQtdeRight}
          </span>
        )}
      </div>
    </div>
  );
};

export default Pagination;
