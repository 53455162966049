import React from "react";
import { PieChart, Pie, Cell, ResponsiveContainer, Label } from "recharts";
import { IPieChartSimple, ICustomLabel } from './types' 

const data0 = [
  { name: "Valor1", value: 60 },
  { name: "Valor2", value: 40 },
];

const COLORS0 = ["#0263FF", "#AFB1B1"];

const CustomLabel = ({ labelText, value, fontSize, color, viewBox }: ICustomLabel) => {
  const { cx, cy } = viewBox;
  const x1 = value == 100 ? 5 : value < 10 ? 3 : 4;
  const x2 = value == 100 ? 10 : value < 10 ? 6 : 8;

  return (
    <g className="recharts-text-G">
      <text
        x={cx - x1}
        y={cy + 1}
        className="recharts-text recharts-label"
        textAnchor="middle"
        dominantBaseline="central"
        alignmentBaseline="middle"
        fill={color}
        fontSize={fontSize}
      >
        {value}
      </text>
      <text
        x={cx + x2}
        y={cy + 1}
        className="recharts-text recharts-label"
        textAnchor="middle"
        dominantBaseline="central"
        alignmentBaseline="middle"
        fill={color}
        fontSize={fontSize}
      >
        {labelText}
      </text>
    </g>
  );
};

const PieChartSimple = ({
  id = "PieChartSimple",
  data = data0,
  colors = COLORS0,
  positionX = 100,
  positionY = 100,
  innerRadius = 80,
  outerRadius = 100,
  labelValue = 100,
  labelText = "%",
  labelFontSize = 14,
  labelFontColor = "#000000",
}: IPieChartSimple) => {
  return (
    <div style={{ width: "100%", height: "100%" }}>
      <ResponsiveContainer>
        <PieChart>
          <Pie
            id={id}
            data={data}
            dataKey="value"
            cx={positionX}
            cy={positionY}
            innerRadius={innerRadius}
            outerRadius={outerRadius}
          >
            {data.map((entry: {name: string, value: number}, index: number) => (
              <Cell
                key={`cell-${index}`}
                fill={colors[index % colors.length]}
              />
            ))}
            <Label
              position="center"
              content={
                <CustomLabel
                  labelText={labelText}
                  value={labelValue}
                  fontSize={labelFontSize}
                  color={labelFontColor}
                />
              }
            />
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

export default PieChartSimple;
