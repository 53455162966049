import React, { useContext } from 'react'
import { Table, TableRow } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import * as Styles from "../../../components/table/styles";
import {ReactComponent as EditIcon} from "../../../assets/icons/EditIcon.svg";
import { useNavigate } from 'react-router';
import { IGetPerfilResponse } from '../types'
import moment from 'moment';
import { HomeContext } from '../../../contexts/homeContext';

interface IConsultarPerfilTable {
  tableData: IGetPerfilResponse[]
  editar: boolean
}

const ConsultarPerfilTabela = ({tableData, editar}: IConsultarPerfilTable) => {

  const { selectedPerfil, setSelectedPerfil } = useContext(HomeContext)

  const navigate = useNavigate()

  const handleEditPerfil = (perfil: IGetPerfilResponse) => {
    setSelectedPerfil(perfil)
    navigate('/EditarPerfil')
  }

  const header = [
    'Status',
    'Nome do Perfil',
    'Data de Criação',
    'Criado Por',
    'Data de Modificação',
    'Modificado Por',
    'Editar'
  ]

  return(
    <TableContainer id={'TabelaConsultaPerfil'} component={Styles.StyledPaper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            {header?.map((title) => 
              <Styles.StyledTableCell className={`${title} center`} key={title}>{title}</Styles.StyledTableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {tableData?.map(item => (
            <TableRow key={item?.id}>
              <Styles.StyledTableCell component="th" scope="row">
                {item?.ativo ? 'Ativo' : 'Inativo'}
              </Styles.StyledTableCell>
              <Styles.StyledTableCell component="th" scope="row">
                {item?.nome}
              </Styles.StyledTableCell>
              <Styles.StyledTableCell component="th" scope="row">
                {moment(item?.dataCriacao).format('DD/MM/YYYY - HH:mm:ss')} 
              </Styles.StyledTableCell>
              <Styles.StyledTableCell component="th" scope="row">
                {item?.usuarioCriacaoNomecompleto}
              </Styles.StyledTableCell>
              <Styles.StyledTableCell component="th" scope="row">
                {moment(item?.dataModificacao).format('DD/MM/YYYY - HH:mm:ss')} 
              </Styles.StyledTableCell>
              <Styles.StyledTableCell component="th" scope="row">
                {item?.usuarioModificacaoNomeCompleto}
              </Styles.StyledTableCell>
              <Styles.StyledTableCell className='center' component="th" scope="row">
              {!!editar ?
                <EditIcon onClick={() => handleEditPerfil(item)} style={{cursor: 'pointer'}}/>
                :
                <EditIcon style={{cursor: 'not-allowed', opacity: 0.5}}/>
              }
              </Styles.StyledTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default ConsultarPerfilTabela