import styled from "styled-components";

export const Container = styled.div`
  padding: 24px 48px;
  gap: 16px;
  display: flex;
  flex-direction: column;
  width: 580px;

  & > .checkbox-container {
    display: flex;
  }
`

export const CheckboxContainer = styled.div`
  min-width: 110px;
  display: flex;
  justify-content: left;
`

export const ButtonList = styled.div`
  display: flex;
  width: 50%;
  gap: 8px;
`

export const ErrorMessage = styled.span`
  color: #FF2323;
  font-size: 12px;
  font-weight: bold;
`