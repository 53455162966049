import React, { useContext, useState, useEffect, useRef } from 'react'
import './consultaModelo.scss'
import './incluirEditarModelo.scss'
import { useNavigate } from 'react-router-dom';
import { HomeContext } from '../../../contexts/homeContext';
import Header from '../../../components/header/header'
import Button from '../../../components/Button/styles';
import MultipleSelectCheckmarksId from '../../../components/seletorMultiploId/seletorMultiploId';
import { Breadcrumb, BreadcrumbItem } from '../../../components/breadcrumb/breadcrumb';
import { AxiosResponse } from 'axios';
import _ from 'lodash'
import FullscreenLoading from '../../../components/fullscreenLoading/fullscreenLoading';
import Input from '../../../components/Input/Input';
import { getFamilias } from '../../familia/services';
import { IFamilia } from '../../familia/types';
import { getEquipamentoTipo } from '../../tipoEquipamento/services/services';
import { putEquipamentoModelo } from '../services/services';
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import Collapse from '../../../components/collapse/collapse';
import CloseIcon from "@mui/icons-material/Close";
import { IConsultarTipoEquipamentoTabela } from '../../tipoEquipamento/types';
import Checkbox from '../../../components/checkbox/checkbox';
import TipoEquipamentoTab from './tipoEquipamentoTab';
import { Tab, Tabs } from '@mui/material';
import TipoMascaraTab from './tipoMascaraTab';
import { TabPanelProps } from '../../perfil/types';
import DetalhesTab from './detalhesTab';

const IncluirEditarModelo = () => {
  const { 
    setMenuLateral, 
    modeloSelected,
    setFamiliaLista, 
    familiaLista,
    tipoEquipamentoLista, 
    setTipoEquipamentoLista 
  } = useContext(HomeContext);

  const navigate = useNavigate()

  const equip = familiaLista.find(familia => familia.id === modeloSelected?.idFamilia?.toString())?.id!

  const [ idModelo, setIdModelo ] = useState(modeloSelected?.id)
  const [ modelo, setModelo ] = useState(modeloSelected?.descricao)
  const [ numeroParte, setNumeroParte ] = useState(modeloSelected?.numeroParte)
  const [ codComercial, setCodComercial ] = useState('')
  const [ tipoModelo, setTipoModelo ] = useState(modeloSelected?.descricao)
  const [ equipamento, setEquipamento ] = useState<string[]>([equip])
  const [ versaoSoftware, setVersaoSoftware ] = useState<string>(modeloSelected?.versaoSoftware)
  const [ fabricante, setFabricante ] = useState(modeloSelected?.fabricante)
  const [ controleSerial, setControleSerial ] = useState<string[]>([Number(modeloSelected?.controleSerial)?.toString()])
  const [ customizacao, setCustomizacao ] = useState<string[]>([Number(modeloSelected?.aplicarCustomizacao)?.toString()])
  const [ tecnologia, setTecnologia ] = useState<string[]>([modeloSelected?.idTipoEquipamento?.toString()])
  const [ peso, setPeso ] = useState(modeloSelected?.peso)
  const [ valorUnit, setValorUnit  ] = useState(modeloSelected?.valorUnitario)
  const [ SAP, setSAP ] = useState(modeloSelected?.sapB1CodigoItem)

  const [ checkDescartavel, setCheckDescartavel ] = useState(modeloSelected?.descartavel)
  const [ checkBloqueiaReversa, setCheckBloqueiaReversa ] = useState(modeloSelected?.bloqueiaReversaNaoKit)
  const [ checkTriagem, setCheckTriagem ] = useState(modeloSelected?.criaTriagemAutomatico)
  const [ checkOMSucata, setCheckOMSucata ] = useState(modeloSelected?.omReversaSucata)
  const [ checkOMChip, setCheckOMChip ] = useState(false)
  const [ checkMobile, setCheckMobile ] = useState(modeloSelected?.omReversaChipMobileControleChip)
  const [ checkQRCode, setCheckQRCode ] = useState(modeloSelected?.qrCode)

  const [ tabValue, setTabValue ] = useState(0)

  const [ loading, setLoading ] = useState(false)

  const [tipomsgAlert, setTipoMsgAlert] = useState<'success' | 'info' | 'warning' | 'error'>("success");
  const [msgAlert, setMsgAlert] = useState("OS encaminhada com  sucesso!");
  const [openAlert, setOpenAlert] = useState(false);

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const handleSubmit = async () => {
    try{
      const { data } = await putEquipamentoModelo({
        idModelo: idModelo || 0,
        idTipoEquipamento: Number(equipamento[0]),
        descricao: modelo,
        ativo: true,
        numeroParte: numeroParte,
        codigoComercial: codComercial,
        versaoSoftware: versaoSoftware,
        idFabricante: modeloSelected?.idFabricante,
        controleSerial: Boolean(controleSerial[0]),
        aplicarCustomizacao: Boolean(customizacao[0]),
        peso: peso,
        valorUnitario: valorUnit,
        codigoSAP: SAP,
        tipoSimCard: 0,
        sapB1CodigoItem: SAP,
        descartavel: checkDescartavel,
        bloqueiaReversaNaoKit: checkBloqueiaReversa,
        criaTriagemAutomatico: checkTriagem,
        omReversaSucata: checkOMSucata,
        omReversaChipMobileControleChip: checkOMChip,
        qrCode: checkQRCode
      }) as AxiosResponse

      setTipoMsgAlert('success')
      setMsgAlert(data)
      setOpenAlert(true)

    } catch(e){
      console.log(e)
    }
  }

  const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        className='TabPanel'
        {...other}
      >
        {value === index && (
          <div>
            {children}
          </div>
        )}
      </div>
    );
  }

  const statusLista = [
    {
      name: '--',
      id: ''
    }, 
    {
      name: 'Ativo',
      id: '1'
    }, 
    {
      name: 'Inativo',
      id: '0'
    }
  ]

  const controleSerialLista = [
    {
      name: '--',
      id: ''
    }, 
    {
      name: 'Sim',
      id: '1'
    }, 
    {
      name: 'Não',
      id: '0'
    }
  ]


  const handleData = async () => {
    setLoading(true)
    handleGetFamilias()
    handleGetTipoEquipamento()
    setLoading(false)
  }

  const handleGetFamilias = async () => {
    try{

      const { data } = await getFamilias('', true) as AxiosResponse

      setFamiliaLista(data.map((item: IFamilia) => {
        return { name: item.descricao, id: item.idFamilia.toString() }
      }).sort(
        (a: {name: string, id: string},b: {name: string, id: string}) => 
          (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)
      ))

    } catch(e){
      setFamiliaLista([])
    }
  }

  const handleGetTipoEquipamento = async () => {
    try{
      const { data } = await getEquipamentoTipo({
        ativo: '',
        descricao: '',
        idFamilia: ''
      }) as AxiosResponse


      setTipoEquipamentoLista(data.map((item: IConsultarTipoEquipamentoTabela) => {
        return {name: item.descricao, id: item.id.toString()}
      }).sort(
        (a: {name: string, id: string},b: {name: string, id: string}) => 
          (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)
      ))

    }catch(e){
      setTipoEquipamentoLista([])
    }
  }

  const isIncluding = window.location.pathname.includes('IncluirModelo')

  useEffect(() => {
    handleData()
  }, [])

  return(
    <section className="ConsultarModelo">
      <Header setMenuLateral={() => setMenuLateral(true)} title={isIncluding ? 'Incluir Modelo' : `Editar Modelo ${modeloSelected.id}`} />
      <Breadcrumb>
        <BreadcrumbItem onClick={() => navigate("/Modelo")}>Modelo</BreadcrumbItem>
        <BreadcrumbItem>{isIncluding ? 'Incluir Modelo' : 'Editar modelo'}</BreadcrumbItem>
      </Breadcrumb>

      {loading && <FullscreenLoading />}

      <div className="Container">
        <div className="IncluirEditarModelo">
          <div className='PrimeiraLinha'>
            <Input
              label='ID Modelo'
              value={idModelo}
              onChange={(e) => setIdModelo(e.target.value)}
              dimension='sm'
              id='InputIDModelo'
            />
            <Input
              label='Modelo'
              value={modelo}
              onChange={(e) => setModelo(e.target.value)}
              dimension='sm'
              id='InputModelo'
            />
          </div>
          <div className='Linha'>
            <Input
              label='Número da Parte'
              value={numeroParte}
              onChange={(e) => setNumeroParte(e.target.value)}
              dimension='sm'
              id='InputNumParte'
            />
            <Input
              label='Código Comercial'
              value={codComercial}
              onChange={(e) => setCodComercial(e.target.value)}
              dimension='sm'
              id='InputCodComercial'
            />
            <Input
              label='Tipo Modelo'
              value={tipoModelo}
              onChange={(e) => setTipoModelo(e.target.value)}
              dimension='sm'
              id='InputTipoModelo'
            />
          
            <MultipleSelectCheckmarksId
              nodata={"Nenhum tipo de equipamento encontrado"}
              options={familiaLista}
              value={equipamento}
              onChange={(e: string[]) => setEquipamento(e)}
              label='Tipo Equipamento'
              id="multiple-checkbox-tipo-equipamento"
              idOption="multiple-checkbox-option-tipo-equipamento"
              labelId="multiple-checkbox-label-tipo-equipamento"
              multiple={false}
              className="ConsultarModeloSelectTipoEquipamento"
              dimension='sm'
            />
            
            <Input
              label='Versão de Software'
              value={versaoSoftware}
              onChange={(e) => setVersaoSoftware(e.target.value)}
              dimension='sm'
              id='InputVersaoSoftware'
            />
            <Input
              label='Fabricante'
              value={fabricante}
              onChange={(e) => setFabricante(e.target.value)}
              dimension='sm'
              id='InputFabricante'
            />
          
            <MultipleSelectCheckmarksId
              nodata={"Nenhum serial encontrado"}
              options={controleSerialLista}
              value={controleSerial}
              onChange={(e: string[]) => setControleSerial(e)}
              label='Controle Serial'
              id="multiple-checkbox-serial"
              idOption="multiple-checkbox-option-serial-"
              labelId="multiple-checkbox-label-serial"
              multiple={false}
              className="ConsultarModeloSelectSerial"
              dimension='sm'
            />
            <MultipleSelectCheckmarksId
              nodata={"Nenhuma customização encontrado"}
              options={controleSerialLista}
              value={customizacao}
              onChange={(e: string[]) => setCustomizacao(e)}
              label='Aplicar Customização'
              id="multiple-checkbox-customizacao"
              idOption="multiple-checkbox-option-customizacao-"
              labelId="multiple-checkbox-label-customizacao"
              multiple={false}
              className="ConsultarModeloSelectCustomizacao"
              dimension='sm'
            />
            
            <MultipleSelectCheckmarksId
              nodata={"Nenhuma tecnologia encontrada"}
              options={tipoEquipamentoLista}
              value={tecnologia}
              onChange={(e: string[]) => setTecnologia(e)}
              label='Tecnologia'
              id="multiple-checkbox-tipo-tecnologia"
              idOption="multiple-checkbox-option-tipo-tecnologia"
              labelId="multiple-checkbox-label-tipo-tecnologia"
              multiple={false}
              className="ConsultarModeloSelectTecnologia"
              dimension='sm'
            />
          
            <Input
              label='Peso'
              value={peso}
              onChange={(e) => setPeso(e.target.value)}
              dimension='sm'
              id='InputPeso'
            />
            <Input
              label='Valor Unitário'
              value={valorUnit}
              onChange={(e) => setValorUnit(e.target.value)}
              dimension='sm'
              id='InputValorUnitario'
            />
            
            <Input
              label='SAP B1 - Código do Item'
              value={SAP}
              onChange={(e) => setSAP(e.target.value)}
              dimension='sm'
              id='InputSAP'
            />
          
            <Checkbox
              id={"CheckboxID-Descartavel"}
              boxclassName={"CheckboxID-Descartavel"}
              label="Descartável"
              value={checkDescartavel}
              onChange={() => setCheckDescartavel(!checkDescartavel)}
            />
            <Checkbox
              id={"CheckboxID-Bloqueia-Reversa-Não-Kit"}
              boxclassName={"CheckboxID-Bloqueia-Reversa-Não-Kit"}
              label="Bloqueia Reversa Não Kit"
              value={checkBloqueiaReversa}
              onChange={() => setCheckBloqueiaReversa(!checkBloqueiaReversa)}
            />
            <Checkbox
              id={"CheckboxID-Header"}
              boxclassName={"CheckboxCardFarolLista"}
              label="Cria Triagem Automático"
              value={checkTriagem}
              onChange={() => setCheckTriagem(!checkTriagem)}
            />
          
            <Checkbox
              id={"CheckboxID-Header"}
              boxclassName={"CheckboxCardFarolLista"}
              label="OM de Reversa: Sucata"
              value={checkOMSucata}
              onChange={() => setCheckOMSucata(!checkOMSucata)}
            />
            <Checkbox
              id={"CheckboxID-Header"}
              boxclassName={"CheckboxCardFarolLista"}
              label="OM de Resersa: Chip"
              value={checkOMChip}
              onChange={() => setCheckOMChip(!checkOMChip)}
            />
            <Checkbox
              id={"CheckboxID-Header"}
              boxclassName={"CheckboxCardFarolLista"}
              label="Mobile - Controle de Chip"
              value={checkMobile}
              onChange={() => setCheckMobile(!checkMobile)}
            />
          
            <Checkbox
              id={"CheckboxID-Header"}
              boxclassName={"CheckboxCardFarolLista"}
              label="QR CODE"
              value={checkQRCode}
              onChange={() => setCheckQRCode(!checkQRCode)}
            />
            
          </div>

          <Collapse onClose={setOpenAlert} in={openAlert} sx={{width: '100%'}}>
            <Alert
             severity={tipomsgAlert}
             icon={<div />}
             variant="filled"
             sx={{
              mb: 2,
              bgcolor: tipomsgAlert === "success" ? "#178B319E" : "rgb(255, 35, 35, 0.7)",
              height: "67px",
              borderRadius: "15px",
              boxSizing: "border-box",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginTop: "20px",
              marginBottom: 0,
              width: '100%'
            }}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="medium"
                onClick={() => setOpenAlert(false)}
              >
                <CloseIcon
                  id={"ModalEncaminharOS-AlertCloseIcon"}
                  fontSize="inherit"
                  sx={{
                    color: "white",
                  }}
                />
              </IconButton>
            }
          >
            <span
              style={{
                color: "white",
                fontSize: "16px",
              }}
              dangerouslySetInnerHTML={{__html: msgAlert}}
            />
          </Alert>
        </Collapse>

          <Tabs className='TabsIncluirEditarModelo' value={tabValue} onChange={handleChangeTab} aria-label="basic tabs example">
            <Tab label="Tipo de equipamento"  />
            <Tab label="Tipo de máscara" />
            <Tab label='Detalhes'/>
          </Tabs>
          <TabPanel value={tabValue} index={0}>
            <TipoEquipamentoTab />
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <TipoMascaraTab />
          </TabPanel>
          <TabPanel value={tabValue} index={2}>
            <DetalhesTab />
          </TabPanel>

          <div className="ButtonSalvarContainer">
            <Button onClick={handleSubmit} variant='primary'>Salvar</Button>
          </div>
        </div>
        
      </div>
    </section>
  )
}

export default IncluirEditarModelo