import { useState } from "react";
import _ from "lodash";
import { getListaOSPendente, postReencaminharOS } from "../pages/osPendente/services/services";
import { AxiosResponse } from "axios";
import { IOrdemServico } from "./types/farolTypes";
import { IOSPendenteContext } from './types/osPendenteTypes'
import {
  listaOrdenarValorPadrao,
  IListaOrdenarValorPadrao
} from "./helpers/dadosPadrao";

export const OSPendenteContext = () => {
  const [loadingOSPendente, setLoadingOSPendente] = useState(false)
  const [osPendente, setOsPendente] = useState<IOrdemServico[]> ([])
  const [listaOrdenarValor, setListaOrdenarValor] = useState(
    listaOrdenarValorPadrao
  );
  const [ordenacaoOSPendente, setOrdenacaoOSPendente] = useState<string[]>([]);
  const [ordenacaoReverseOSPendente, setOrdenacaoReverseOSPendente] = useState(false);

  const getOSPendente = async () => {
    setLoadingOSPendente(true)
    const { data } = await getListaOSPendente() as AxiosResponse

    if(!!data.ordemServicos){
      setOsPendente(data.ordemServicos)
    }
    setLoadingOSPendente(false)
  }

  const reenviarOSPendente = async (item: IOrdemServico) => {
    const response = await postReencaminharOS({idOs: item.chamado}) as AxiosResponse

    return response
  }

  const listaOSFiltradaOSPendente = () => {
    let resultadoDados: IOrdemServico[] = osPendente;

    if (!!ordenacaoOSPendente.length) {
       resultadoDados = _.sortBy(resultadoDados, [
        listaOrdenarValor[ordenacaoOSPendente[0] as keyof IListaOrdenarValorPadrao],
      ]) as IOrdemServico[];

      if (!!ordenacaoReverseOSPendente) return resultadoDados.reverse();
    }

    return resultadoDados;
  };

  return {
    osPendente: listaOSFiltradaOSPendente(),
    loadingOSPendente,
    getOSPendente,
    reenviarOSPendente,
    ordenacaoOSPendente,
    setOrdenacaoOSPendente,
    ordenacaoReverseOSPendente,
    setOrdenacaoReverseOSPendente,
  }
}