import styled from "styled-components";
import { Button } from "@mui/material";
import { siteTheme } from "../../../styles/themeVariables";
import Paper from '@mui/material/Paper';

const { color } = siteTheme;

export const Section = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
`;

export const ButtonWrapper = styled.div`
  margin: 7px 0px 28px 0px;
  padding: 0px 52px 0px 32px;
  display: flex;
  justify-content: flex-end;
`;

export const SearchButton = styled(Button)`
  font-size: 14px !important;
  height: 44px !important;
  border-radius: 15px !important;
  padding: 0 40px 0 40px !important;
`;

export const NewUserButton = styled(Button)`
  background: #AFB1B1 !important;
  color: #FFF !important;
  font-size: 14px !important;
  height: 44px !important;
  border-radius: 15px !important;
`;

export const ExportarButton = styled(Button)`
  border: 1px solid ${color.primary} !important;
  font-size: 14px !important;
  height: 44px !important;
  border-radius: 15px !important;
  padding: 0 40px 0 40px !important;

  &.bloqueado {
    cursor: 'not-allowed' !important;
    opacity: 0.5;
  }
  
  & .MuiButton-startIcon {
    margin-top: -4px;
    margin-right: 14px;
  }

  & .MuiButton-startIcon {
    font-size: 22px;
  }
`;

export const Body = styled.div`
  margin-top: 30px;

  .grid1, .grid2 {
    display: grid;
    grid-template-columns:  200px 1.5fr 1fr 1fr 200px;
    column-gap: 20px;
    margin-bottom: 20px;
    -webkit-box-align: baseline;
    align-items: end;
  }

  .grid2 {
    grid-template-columns: 200px 200px 1fr;
    margin-bottom: 44px;
  }

  @media only screen and (max-width: 1500px) {
    .grid1 {
      grid-template-columns:  1fr 1.5fr 1fr 1fr 1fr;
    }
  }

  @media only screen and (max-width: 1000px) {
    grid-template-columns: 1fr;

    .grid1, .grid2 {
      grid-template-columns: 1fr;
      gap: 16px;
    }
  }
`;

export const FiltrosLabel = styled.div`
  color: #797D8C;
  font-weight: 600;
  font-size: 22px;
  position: relative;
  top: -22px;
`;

export const SubHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 25px 0 35px;
`;

export const SubHeaderTitle = styled.div`
    font-family: Noto Sans;
    font-size: 2rem;
    font-weight: 700;
    color: #797d8c;
    letter-spacing: 0.9px;
`;

export const StyledPaperCutom = styled(Paper)(({ theme }) => ({
  border: `1px solid ${theme.palette.secondary.light}`,
  maxHeight: '560px',
  borderRadius: '15px !important',

  [theme.breakpoints.up('lg')]: {
    maxHeight: '70vh',
  }
}))
