import { AxiosError } from "axios";
import { eventBus } from "../helpers/eventBus";

export const authVerify = (res: AxiosError) => {
  /**status == 401 Não autorizado e/ou token expirou */

  if (res?.response?.status == 401) {
    alert("Sua sessão se encerrou, realize o login novamente!");
    eventBus.dispatch("logout");
  }
};
