import * as React from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { IOSSwitch } from './switch_style';

interface ISwitchMUI {
    id?: string,
    type?: "default" | "IOS",
    label?: string,
    labelPlacement?: "top" | "start" | "bottom" | "end",
    disabled?: boolean,
    checked?: boolean,
    setChecked?: Function,
    sx?: any,
}

export default function SwitchMUI({
    id = "SwitchMUI",
    type = "default",
    label = "",
    labelPlacement = "end",
    disabled = false,
    checked = false,
    setChecked,
    sx = {},
}: ISwitchMUI) {

    const handleChange = (event: any) => {
        if (!!setChecked) setChecked(event.target.checked);
    };

    const switches = {
        "default": Switch,
        "IOS": IOSSwitch,
    };
    
    const SwitchComponent = switches[type];

    return (
        <FormGroup>
            <FormControlLabel
                sx={{
                    margin: 'auto',
                    '&.Mui-disabled': { backgroundColor: 'transparent' },
                    ...sx,
                }}
                label={label}
                labelPlacement={labelPlacement}
                disabled={disabled}
                checked={checked}
                onChange={handleChange}
                control={
                    <SwitchComponent id={id} />
                }
            />
        </FormGroup>
    );
};
