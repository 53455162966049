import React, { useState, useEffect, useContext } from "react";
import { getListaEquipamentos } from "../services/services";
import { Typography } from "@mui/material";
import {
  Section,
  TabelaEquipamentos,
  TabelaSemDados,
  ButtonWrapper,
  PrintButton,
  TableWrapper,
  PaperX,
  TableContainerX,
  TableRowHeaderX,
  TableCellHeaderX,
  TableRowX,
  TableRowCellX,
} from "./styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import Header from "../../../components/header/header";
import {
  Breadcrumb,
  BreadcrumbItem,
} from "../../../components/breadcrumb/breadcrumb";
import IosShareIcon from "@mui/icons-material/IosShare";
import { HomeContext } from "../../../contexts/homeContext";
import { AuthContext } from "../../../contexts/auth";
import { useNavigate } from "react-router-dom";
import { AxiosResponse } from "axios";
import { IMelhorEquipamento } from "../../../contexts/types/farolTypes";
import _ from 'lodash'

interface IListaEquipamentos {
  chamado: number,
  referencia: string,
  status: string,
  modelo: string,
  tipoModelo: string,
  tipoEquipamento: string,
  melhorEquipamento: IMelhorEquipamento[]
  qtdEstoque: number,
  qtdAtendimento: number,
  idTecnico: string,
  tecnico: number,
};

const ResumoEquipamentos = () => {
  const { iDsOSsParaResumoEquipamentos, getOSsByIDsFarol, setMenuLateral, listaOSFiltradaFarol } = useContext(HomeContext);
  const { windowInnerWidth } = useContext(AuthContext);

  const [listaOSResumo, setListaOSResumo] = useState<any[]>(getOSsByIDsFarol(iDsOSsParaResumoEquipamentos));
  const [listaEquipamentos, setListaEquipamentos] = useState<IListaEquipamentos[]>([]);
  const [semDados, setSemDados] = useState<string>("Carregando...");

  const navigate = useNavigate();

  useEffect(() => {
    requestListaEquipamentos();
  }, []);

  useEffect(() => {
    let css = `
    @media print {
      body * {
        visibility: hidden;
      }
      #TabelaResumoEquipamentos,
      #TabelaResumoEquipamentos * {
        visibility: visible;
        -webkit-print-color-adjust: exact;
        print-color-adjust: exact;
      }
      #TabelaResumoEquipamentos {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
      }
      @page {
        size: landscape;
      }
    }
    `,
      head = document.head || document.getElementsByTagName('head')[0],
      style = document.createElement('style');

    style.media = 'print';
    style.appendChild(document.createTextNode(css));
    head.appendChild(style);

    return () => {
      head.removeChild(style);
    }
  }, []);

  const requestListaEquipamentos = async () => {
    const ossGroupedByPrestador = listaOSResumo.reduce((group, os) => {
      const idPrestador = String(os?.idPrestador);
      group[idPrestador] = group[idPrestador] ?? [];
      group[idPrestador].push(String(os?.chamado));
      return group;
    }, {});

    try {
      let { data } = await getListaEquipamentos({
        "hashPrestadorOrdens": ossGroupedByPrestador
      }) as AxiosResponse;

      setListaEquipamentos( data.map((item: IListaEquipamentos) => (
        {
          chamado: item.chamado,
          referencia: item.referencia,
          status: item.status,
          modelo: item.modelo,
          tipoModelo: item.tipoModelo,
          tipoEquipamento: item.tipoEquipamento,
          melhorEquipamento: listaOSFiltradaFarol.find(
            os => os.chamado === item.chamado)?.melhorEquipamento?.map(
              equip => equip.descricao+`\n`).toString().replaceAll(',', '') || [],
          idTecnico: item.idTecnico
          }
      )));
    } catch (error: any) {
      setListaEquipamentos([]);

      if (!error?.response?.status) {
        return setSemDados("Não foi possível encontrar dados para as OSs selecionadas.");
      }

      const msg = error?.response?.data?.title || error?.message;

      switch (error?.response?.status) {
        case 404:
          return setSemDados(
            "Não foram encontrados dados de Equipamentos para as OSs selecionadas."
          );
        case 400:
          return setSemDados(`Erro status ${error?.response?.status}. Erro na validação de parâmetros obrigatórios.`);
        default:
          return setSemDados(`Erro status ${error?.response?.status}. ${!!msg ? "Mensagem: " + msg : ""}`);
      }
    }
  };

  const objTraducaoHeader = {
    chamado: "Chamado",
    referencia: "Referência",
    status: "Status",
    modelo: "Modelo",
    tipoModelo: "Tipo de Modelo",
    tipoEquipamento: "Tipo de Equipamento",
    melhorEquipamento: "Melhor Equipamento",
    // qtdEstoque: "QTD em Estoque",
    // qtdAtendimento: "QTD para Atendimento",
    idTecnico: "Cod. Técnico",
    // tecnico: "Técnico",
  } as any;

  return (
    <Section id={"ResumoEquipamentos"}>
      <Header
        setMenuLateral={() => setMenuLateral(true)}
        title="Resumo de equipamentos"
      />
      <Breadcrumb>
        <BreadcrumbItem onClick={() => navigate("/FarolOS")}>Visualização de OS's</BreadcrumbItem>
        <BreadcrumbItem>Resumo de equipamentos</BreadcrumbItem>
      </Breadcrumb>

      <PaperX>
        {!!listaEquipamentos.length && (
          <TableWrapper id={"TabelaResumoEquipamentos"}>
            <TableContainerX>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRowHeaderX>
                    {Object.keys(listaEquipamentos[0]).map(
                      (column, columnkey) => {
                        /**Largura da tela menos paddings laterais / quantidade de colunas  */
                        let width =
                          (windowInnerWidth - 66) /
                          Object.keys(listaEquipamentos[0]).length;

                        return (
                          <TableCellHeaderX
                            key={columnkey}
                            align={"center"}
                            style={{
                              minWidth: 90,
                              width: width,
                            }}
                          >
                            <span>
                              {!!objTraducaoHeader[column]
                                ? objTraducaoHeader[column]
                                : column}
                            </span>
                          </TableCellHeaderX>
                        );
                      }
                    )}
                  </TableRowHeaderX>
                </TableHead>

                <TableBody>
                  {listaEquipamentos.map((row, rowkey) => {
                    return (
                      <TableRowX
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={rowkey}
                      >
                        {Object.values(row).map((column, columnkey) => {
                          /**Largura da tela menos paddings laterais / quantidade de colunas  */
                          let width =
                            (windowInnerWidth - 66) / Object.values(row).length;
                          return (
                            <TableRowCellX
                              key={columnkey}
                              align={"center"}
                              style={{
                                minWidth: 90,
                                width: width
                              }}
                            >
                              
                              {/* {column || ""} */}
                              <span style={{display: 'flex', maxHeight: '100px', overflowY: 'auto'}}>{column || ""}</span>
                            </TableRowCellX>
                          );
                        })}
                      </TableRowX>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainerX>
          </TableWrapper>
        )}

        {!listaEquipamentos.length && (
          <>
            <TabelaEquipamentos id={"TabelaResumoEquipamentos"}>
              <TableHead>
                <TableRowHeaderX>
                  {Object.values(objTraducaoHeader).map((column: any, columnkey: number) => {
                    /**Largura da tela menos paddings laterais / quantidade de colunas  */
                    let width =
                      (windowInnerWidth - 66) /
                      Object.values(objTraducaoHeader).length;

                    return (
                      <TableCellHeaderX
                        key={columnkey}
                        align={"center"}
                        style={{
                          minWidth: 90,
                          width: width,
                        }}
                      >
                        <span>{column}</span>
                      </TableCellHeaderX>
                    );
                  })}
                </TableRowHeaderX>
              </TableHead>

              <TabelaSemDados>
                <Typography variant="h3">{semDados}</Typography>
              </TabelaSemDados>
            </TabelaEquipamentos>
          </>
        )}
      </PaperX>

      <ButtonWrapper id={"PrintButtonWrapper"}>
        <PrintButton
          variant="contained"
          size="small"
          startIcon={<IosShareIcon />}
          onClick={() => window.print()}
        >
          Exportar
        </PrintButton>
      </ButtonWrapper>
    </Section>
  );
};

export default ResumoEquipamentos;
