import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

export interface ICheckboxMUI {
    disabled?: boolean,
    id?: string,
    label?: string,
    value: boolean,
    onChange: () => void,
    color?: "success" | "info" | "warning" | "error" | "default" | "primary" | "secondary" | undefined,
    inputProps?: object,
    checkboxSX?: object,
    formControlLabelSX?: object,
}

export default function CheckboxMUI({
    disabled = false,
    id = "checkboxMUI",
    label = "",
    value,
    onChange,
    inputProps = { 'aria-label': 'controlled' },
    color = "primary",
    checkboxSX = { '& .MuiSvgIcon-root': { fontSize: 24 } },
    formControlLabelSX = { 
        marginLeft: '0',
        marginRight: '0',
        '& .MuiCheckbox-root': { padding: '0'}, 
        '& .MuiTypography-root': { color: '#797d8c'} 
    },

}: ICheckboxMUI) {

    return (
        <FormControlLabel control={
            <Checkbox
                disabled={disabled}
                id={id}
                checked={value}
                onChange={onChange}
                inputProps={inputProps}
                color={color}
                sx={checkboxSX}
            />
        } label={label} sx={formControlLabelSX} />
    );
}
