import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../contexts/auth";
import SectionCustom from "../../../components/section/section";
import { useLocation, useNavigate } from "react-router-dom";
import { atualizarModelo, getModelos } from "../services";
import Input from "../../../components/Input/Input";
import { NewButton, SearchButton } from "./consultaAgrupamentoModelo_Styles";
import Collapse from "../../../components/collapse/collapse";
import { Alert, CircularProgress, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import StickyTable from "../../../components/stickyTable/stickyTable";
import { HeaderTabelaIncluir, IModeloDTO, Modelo } from "../types";
import { ResponseApi } from "../../../contexts/types/responsesHttp";
import { AxiosResponse } from "axios";
import AutocompleteMultiple from "../../../components/autocompleteMultiple/autocompleteMultiple";
const EditarAgrupamentoModelos = () => {
  const { funcionalidadeDaTelaTemPermissao } = useContext(AuthContext);
  const location = useLocation();
  const dadosAgrupamento = location.state?.dadosAgrupamento || {};
  const nomeModeloPrincipal = dadosAgrupamento.nome;
  const nomeContratante =
    dadosAgrupamento.equipamentoModeloCompativels[0]?.contratante?.nome;

  const equipExistentes = dadosAgrupamento.equipamentoModeloCompativels.map(
    (item: any) => ({
      id: item.equipamentoModelo.id,
      nome: item.equipamentoModelo.nome,
    })
  ) as Modelo[];
  let idsExistentes = equipExistentes.map((d: Modelo) => Number(d.id));

  const isIncluding = window.location.pathname.includes(
    "EditarAgrupamentoModelos"
  );

  const [listaModelo, setListaModelo] = useState<
    { name: string; id: string }[]
  >([]);
  const [modeloAdicionar, setModeloAdicionar] = useState<any[]>([]);
  const [idModelosCompativeis, setIdModelosCompativeis] =
    useState<number[]>(idsExistentes);
  const [openAlertError, setOpenAlertError] = useState(false);
  const [disableActions, setDisableActions] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [openAlertSuccess, setOpenAlertSuccess] = useState(false);
  const [msgAlertSuccess, setMsgAlertSuccess] = useState("");
  const [msgAlertError, setMsgAlertError] = useState<string>(
    "Não foi encontrado nenhum Modelo compatível"
  );
  const navigate = useNavigate();
  const profileHasPermission = (funcionalidade: string) =>
    funcionalidadeDaTelaTemPermissao(isIncluding, funcionalidade);
  const adicionarnaTabela = () => {
    if (!idModelosCompativeis.includes(Number(modeloAdicionar[0].id)))
      setIdModelosCompativeis([
        ...idModelosCompativeis,
        Number(modeloAdicionar[0].id),
      ]);
    obterDadosParaTabela();
  };
  const obterDadosParaTabela = () => {
    let modelosFiltrados = obterModelosCompativeis(
      listaModelo,
      idModelosCompativeis
    );
    return modelosFiltrados ?? [];
  };

  const objTraducaoHeader = {
    id: "ID Modelo",
    name: "Nome Modelo",
  } as HeaderTabelaIncluir;
  const obterModelosCompativeis = (
    lista: Modelo[],
    idsCompativeis: number[]
  ) => {
    return lista.filter((modelo) => idsCompativeis.includes(Number(modelo.id)));
  };
  const handleClickItemRemover = (data: any) => {
    const idsExistentesAtualizado = idModelosCompativeis.filter(
      (id) => id !== Number(data.id)
    );
    setIdModelosCompativeis(idsExistentesAtualizado);
  };

  const handlerSalvar = async () => {
    const objSalvar = {
      idContratante: Number(
        dadosAgrupamento.equipamentoModeloCompativels[0]?.contratante?.id
      ),
      idEquipamentoModeloPrincipal: Number(dadosAgrupamento.id),
      idEquipamentoModelosCompativeis: idModelosCompativeis,
    } as any;
    if (
      objSalvar.idContratante === undefined ||
      objSalvar.idContratante === null ||
      objSalvar.idEquipamentoModeloPrincipal === undefined ||
      objSalvar.idEquipamentoModeloPrincipal === null
    ) {
      setLoading(false);
      setOpenAlertError(true);
      setMsgAlertError("Informe um modelo e um contratante antes de salvar");
      setDisableActions(false);
      return;
    } else if (
      objSalvar.idEquipamentoModelosCompativeis === undefined ||
      objSalvar.idEquipamentoModelosCompativeis === null ||
      objSalvar.idEquipamentoModelosCompativeis.length == 0
    ) {
      setLoading(false);
      setOpenAlertError(true);
      setMsgAlertError(
        "Informe pelo menos um modelo compatível antes de salvar"
      );
      setDisableActions(false);
      return;
    } else {
      try {
        const retorno = (await atualizarModelo(objSalvar)) as AxiosResponse;
        if (retorno.status === 200) {
          setMsgAlertSuccess("Modelo salvo com sucesso!!");
          setLoading(false);
          setDisableActions(false);
          setOpenAlertSuccess(true);
          navigate("/ConsultarAgrupamentoModelo");
        } else {
          setLoading(false);
          setDisableActions(false);
          setOpenAlertError(true);
          setMsgAlertError(retorno.data.errors[0].Message);
        }
      } catch (error: any) {
        setLoading(false);
        setDisableActions(false);
        setOpenAlertError(true);
        setMsgAlertError(error.response.data);
      }
    }
  };

  useEffect(() => {
    handleGetModelo();
  }, []);
  const handleGetModelo = async () => {
    await getModelos().then(
      (res: any) => {
        const respSuccess = res?.data;
        if (respSuccess) {
          const listaModelo =
            respSuccess?.equipamentoModelos?.map((item: any) => ({
              id: item.id.toString(),
              name: item.nome,
            })) || [];
          setListaModelo([...[], ...listaModelo]);
        }
      },
      (err) => {
        const respErr = err?.response?.data as ResponseApi<IModeloDTO[]>;
        if (respErr) {
          setMsgAlertError(respErr.errors[0].Message);
          setOpenAlertError(true);
        }
      }
    );
  };
  return (
    <SectionCustom
      id={"EditarAgrupamento"}
      className="EditarAgrupamento"
      titleHeader="Editar Agrupamento"
      titleBreadcrumbItem="Editar Modelo Compativel"
      titleBreadcrumbItemBack="Consultar Agrupamento de Modelos"
      rotaVoltar="/ConsultarAgrupamentoModelo"
    >
      <div className="consultarAgrupamentoModelo-Container">
        <div className="filtro-Modelo">
          <Input
            className={"NomeModelo-Input"}
            dimension="sm"
            id="input-IdOM"
            label={"Modelo"}
            value={nomeModeloPrincipal}
            maxLength={255}
            disabled={true}
          />
          <Input
            className={"Contratante-Input"}
            dimension="sm"
            id="input-IdOM"
            label={"Contratante"}
            value={nomeContratante}
            maxLength={255}
            disabled={true}
          />
        </div>
      </div>
      <div className="consultarAgrupamentoModelo-Container">
        <div className="filtro-Modelo">
          <AutocompleteMultiple
            dimension="sm"
            label={"Modelo"}
            placeholder="Selecione um Modelo"
            options={[{ name: "--", id: "" }, ...listaModelo]}
            value={modeloAdicionar}
            onChange={(value: any) => setModeloAdicionar(value)}
            fontSize={12}
            id="multiple-checkbox-Status"
            multiple={false}
            allSelected
            disabled={disableActions}
          />
          <div className="botao-PesquisarModelo-container">
            <SearchButton
              variant="contained"
              size="small"
              onClick={adicionarnaTabela}
              disabled={disableActions || modeloAdicionar.length == 0}
            >
              Adicionar
            </SearchButton>
            {!!profileHasPermission("Incluir Registro") ? (
              <NewButton
                color="primary"
                variant="contained"
                size="small"
                onClick={handlerSalvar}
              >
                Salvar
              </NewButton>
            ) : (
              <NewButton
                className={"bloqueado"}
                style={{ cursor: "not-allowed", opacity: 0.5 }}
                color="secondary"
                variant="contained"
                size="small"
              >
                Salvar
              </NewButton>
            )}
          </div>
        </div>
      </div>
      {openAlertSuccess && (
        <Collapse onClose={setOpenAlertSuccess} in={openAlertSuccess}>
          <Alert
            severity="success"
            icon={<div />}
            variant="filled"
            sx={{
              mb: 2,
              bgcolor: "#178B319E",
              borderRadius: "15px",
              boxSizing: "border-box",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: "16px",
            }}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="medium"
                onClick={() => setOpenAlertSuccess(false)}
              >
                <CloseIcon
                  id={"ModalTermoOS-AlertCloseIcon"}
                  fontSize="inherit"
                  sx={{
                    color: "white",
                  }}
                />
              </IconButton>
            }
          >
            <span
              style={{
                color: "white",
                fontSize: "16px",
              }}
            >
              {msgAlertSuccess}
            </span>
          </Alert>
        </Collapse>
      )}
      {openAlertError && (
        <div className="modalErro-Modelo">
          <Collapse onClose={setOpenAlertError} in={openAlertError}>
            <Alert
              severity="error"
              icon={<div />}
              variant="filled"
              sx={{
                mb: 2,
                bgcolor: "rgb(255, 35, 35, 0.7)",
                borderRadius: "15px",
                boxSizing: "border-box",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginBottom: "50px",
              }}
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="medium"
                  onClick={() => setOpenAlertError(false)}
                >
                  <CloseIcon
                    id={"ModalModeloCompativel-AlertCloseIcon"}
                    fontSize="inherit"
                    sx={{
                      color: "white",
                    }}
                  />
                </IconButton>
              }
            >
              <span
                style={{
                  color: "white",
                  fontSize: "16px",
                }}
                dangerouslySetInnerHTML={{ __html: msgAlertError }}
              />
            </Alert>
          </Collapse>
        </div>
      )}
      {!!loading && <CircularProgress style={{ margin: "0px auto" }} />}
      {!!idModelosCompativeis.length && (
        <>
          <div className="tabelaModelo">
            <StickyTable
              id={"TabelaResultadoConsultaModelo"}
              data={obterDadosParaTabela()}
              objTraducaoHeader={objTraducaoHeader}
              allowEdit={!!profileHasPermission("Excluir Registro")}
              iconeRemover={true}
              edit={true}
              labelEdit="Excluir"
              handleClickEdit={handleClickItemRemover}
            />
          </div>
        </>
      )}
    </SectionCustom>
  );
};
export default EditarAgrupamentoModelos;
