import React, { useContext, useState, useEffect, useRef } from 'react'
import './consultaModelo.scss'
import { useNavigate } from 'react-router-dom';
import { HomeContext } from '../../../contexts/homeContext';
import Header from '../../../components/header/header'
import Button from '../../../components/Button/styles';
import MultipleSelectCheckmarksId from '../../../components/seletorMultiploId/seletorMultiploId';
import { Breadcrumb, BreadcrumbItem } from '../../../components/breadcrumb/breadcrumb';
import { AxiosResponse } from 'axios';
import _ from 'lodash'
import FullscreenLoading from '../../../components/fullscreenLoading/fullscreenLoading';
import Input from '../../../components/Input/Input';
import { getFamilias } from '../../familia/services';
import { IFamilia } from '../../familia/types';
import ConsultaModeloTabela from './consultaModeloTabela';
import { getEquipamentoTipo } from '../../tipoEquipamento/services/services';
import { getEquipamentoModelo } from '../services/services';
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import Collapse from '../../../components/collapse/collapse';
import CloseIcon from "@mui/icons-material/Close";
import { IConsultarTipoEquipamentoTabela } from '../../tipoEquipamento/types';
import { IConsultaModeloTabela, IConsultaModelo } from '../types';
import Pagination from '../../../components/pagination/paginacao'

const ConsultaModelo = () => {
  const { 
    setMenuLateral, 
    familiaLista, 
    setFamiliaLista, 
    tipoEquipamentoLista, 
    setTipoEquipamentoLista 
  } = useContext(HomeContext);

  const navigate = useNavigate()
 
  const [ equipamento, setEquipamento ] = useState<string[]>([])
  const [ status, setStatus ] = useState<string[]>([])
  const [ familia, setFamilia ] = useState<string[]>([])
  
  const [ tableData, setTableData ] = useState<IConsultaModelo>({} as IConsultaModelo)
  const [ modelo, setModelo ] = useState('')

  const [ loading, setLoading ] = useState(false)

  const [tipomsgAlert, setTipoMsgAlert] = useState<'success' | 'info' | 'warning' | 'error'>("success");
  const [msgAlert, setMsgAlert] = useState("OS encaminhada com  sucesso!");
  const [openAlert, setOpenAlert] = useState(false);

  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)

  const statusLista = [
    {
      name: '--',
      id: ''
    }, 
    {
      name: 'Ativo',
      id: '1'
    }, 
    {
      name: 'Inativo',
      id: '0'
    }
  ]

  const handleData = async () => {
    setLoading(true)
    handleGetFamilias()
    handleGetTipoEquipamento()
    setLoading(false)
  }

  const handleGetFamilias = async () => {
    try{

      const { data } = await getFamilias('', true) as AxiosResponse

      setFamiliaLista(data.map((item: IFamilia) => {
        return { name: item.descricao, id: item.idFamilia.toString() }
      }).sort(
        (a: {name: string, id: string},b: {name: string, id: string}) => 
          (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)
      ))

    } catch(e){
      setFamiliaLista([])
    }
  }

  const handleGetTipoEquipamento = async () => {
    try{
      const { data } = await getEquipamentoTipo({
        ativo: '',
        descricao: '',
        idFamilia: ''
      }) as AxiosResponse


      setTipoEquipamentoLista(data.map((item: IConsultarTipoEquipamentoTabela) => {
        return {name: item.descricao, id: item.id.toString()}
      }).sort(
        (a: {name: string, id: string},b: {name: string, id: string}) => 
          (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)
      ))

    }catch(e){
      setTipoEquipamentoLista([])
    }
  }

  const handlePesquisar = async (page?: number, size?: number) => {
    setLoading(true)
    setOpenAlert(false)
    try{
      const { data } = await getEquipamentoModelo(
        Number(familia[0]),
        Number(equipamento[0]),
        modelo,
        !!status[0] ? Boolean(Number(status[0])) : null,
        page || 1,
        size || 10
      ) as AxiosResponse

      setTableData({
        pageNumber: data?.pageNumber,
        totalPages: data?.totalPages,
        hasPreviousPage: data?.hasPreviousPage,
        hasNextPage: data?.hasNextPage,
        totalCount: data?.totalCount,
        table: data?.items
      })
    } catch(e: any){
      setTableData({} as IConsultaModelo)
      setMsgAlert("Nenhum modelo encontrado")
      setTipoMsgAlert('error')
      setOpenAlert(true)
    }
    setLoading(false)
  }

  useEffect(() => {
    handleData()
  }, [])

  const firstUpdate = useRef(true);
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    handlePesquisar(currentPage, pageSize)
  }, [currentPage, pageSize])

  return(
    <section className="ConsultarModelo">
      <Header setMenuLateral={() => setMenuLateral(true)} title="Consultar Modelo" />
      <Breadcrumb>
        <BreadcrumbItem>Modelo</BreadcrumbItem>
      </Breadcrumb>

      {loading && <FullscreenLoading />}

      <div className="Container">
        <div className="ConsultarEquipamentoPesquisar">
          <span className='FiltroSpan'>Filtros</span>
          <MultipleSelectCheckmarksId
           nodata={"Nenhum status encontrado"}
           options={statusLista}
           value={status}
           onChange={(e: string[]) => setStatus(e)}
           placeholder={"Selecionar status"}
           id="multiple-checkbox-status"
           idOption="multiple-checkbox-option-status-"
           labelId="multiple-checkbox-label-status"
           multiple={false}
           className="ConsultarEquipamentoSelectStatus"
           dimension='sm'
          />
          <MultipleSelectCheckmarksId
           nodata={"Nenhum família encontrado"}
           options={familiaLista}
           value={familia}
           onChange={(e: string[]) => setFamilia(e)}
           placeholder={"Selecionar família"}
           id="multiple-checkbox-família"
           idOption="multiple-checkbox-option-família-"
           labelId="multiple-checkbox-label-família"
           multiple={false}
           className='ConsultarEquipamentoSelectFamilia'
           dimension='sm'
          />
          <MultipleSelectCheckmarksId
           nodata={"Nenhum tipo de equipamento encontrado"}
           options={tipoEquipamentoLista}
           value={equipamento}
           onChange={(e: string[]) => setEquipamento(e)}
           placeholder={"Selecionar tipo do equipamento"}
           id="multiple-checkbox-família"
           idOption="multiple-checkbox-option-família-"
           labelId="multiple-checkbox-label-família"
           multiple={false}
           className='ConsultarEquipamentoSelectFamilia'
           dimension='sm'
          />
          <Input
           placeholder="Nome do modelo" 
           value={modelo}
           onChange={(e) => setModelo(e.target.value)}
           dimension='sm'
          />
          <Button
           dimension='sm' 
           variant='primary' 
           onClick={() =>handlePesquisar()}
          >
            Pesquisar
          </Button>
          {/* <Button
           dimension='sm' 
           variant='grey' 
           onClick={() => navigate('/IncluirModelo')} 
          >
            Novo
          </Button> */}
        </div>
        <Collapse onClose={setOpenAlert} in={openAlert}>
          <Alert
            severity={tipomsgAlert}
            icon={<div />}
            variant="filled"
            sx={{
              mb: 2,
              bgcolor: tipomsgAlert === "success" ? "#178B319E" : "rgb(255, 35, 35, 0.7)",
              height: "67px",
              borderRadius: "15px",
              boxSizing: "border-box",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginTop: "20px",
              marginBottom: 0,
            }}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="medium"
                onClick={() => setOpenAlert(false)}
              >
                <CloseIcon
                  id={"ModalEncaminharOS-AlertCloseIcon"}
                  fontSize="inherit"
                  sx={{
                    color: "white",
                  }}
                />
              </IconButton>
            }
          >
            <span
              style={{
                color: "white",
                fontSize: "16px",
              }}
              dangerouslySetInnerHTML={{__html: msgAlert}}
            />
          </Alert>
        </Collapse>
        {!_.isEmpty(tableData) && (
          <>
            <ConsultaModeloTabela tableData={tableData.table}/>
            <Pagination
              className="pagination-bar"
              currentPage={currentPage}
              totalCount={tableData?.totalCount}
              pageSize={pageSize}
              setPageSize={(value: string) => setPageSize(Number(value))}
              onPageChange={(page: number) => setCurrentPage(Number(page))}
            />
          </>
        )}
      </div>
    </section>
  )
}

export default ConsultaModelo