import { objToQueryString } from "../../../helpers/util";
import { apiGet, apiPost, apiPut } from "../../../services/api";
import { IAtividadesTecnicoDadosExport, IAtividadesTecnicoDadosFiltro,IGetTecnicos, IPlacaMoto, IPostTecnico, IPrestadores, IPutTecnico, ITecnicoOS, ITipoMoto } from "../types";
import { apiGetGeneric } from "../../../services/apiGeneric";

const baseUrlAtendimento = process.env.REACT_APP_API_ATENDIMENTO;

export const getListaTecnicosComFiltro = async (params: IGetTecnicos, prestadores: number[] = []) =>
  await apiGet(
    `${baseUrlAtendimento}/Tecnico/consulta-com-filtros${!!prestadores.length ? `?` + prestadores.map((n) => `Prestadores=${n}`).join('&') : ''}`,
    params
  );

export const postTecnico = async (params: IPostTecnico) =>
  await apiPost(`${baseUrlAtendimento}/Tecnico`, params)

export const putTecnico = async (params: IPutTecnico, idTecnico: number | string) =>
  await apiPut(`${baseUrlAtendimento}/Tecnico/${idTecnico}`, params)

export const postVinculaTecnicoPrestador = async (prestadores: IPrestadores[], idTecnico: number | string) =>
  await apiPost(`${baseUrlAtendimento}/Tecnico/prestadores?idTecnico=${idTecnico}`, { prestadores: prestadores })

export const putEditaVinculoTecnicoPrestador = async (prestadores: IPrestadores[], idTecnico: number | string) =>
  await apiPut(`${baseUrlAtendimento}/Tecnico/${idTecnico}/prestadores`, { prestadores: prestadores})

export const getAtividadesTecnico = async (params: IAtividadesTecnicoDadosFiltro) => {
  let query = objToQueryString(params, {
    format: "YYYY-MM-DD"
  });
  return await apiGet(`${baseUrlAtendimento}/Tecnico/atividades?${query}`);
}

export const postAtividadesTecnicoExportar = async (params: IAtividadesTecnicoDadosExport) =>
  await apiPost(`${baseUrlAtendimento}/Tecnico/atividades/exportar`, params);


const baseUrlOrdemServico = process.env.REACT_APP_API_ORDEMSERVICO;

export const getListaTecnicosOS = async (params: {}): Promise<{ tecnicos: ITecnicoOS[] }> =>
  await apiGetGeneric<{ tecnicos: ITecnicoOS[] }>(`${baseUrlOrdemServico}/Tecnicos`, params)

export const getListaTipoMoto = async (params: {}): Promise<{ tipos: ITipoMoto[] }> =>
  await apiGetGeneric<{ tipos: ITipoMoto[] }>(`${baseUrlOrdemServico}/Tecnicos/TiposMoto`, params)

export const getListaPlacaMoto = async (params: {}): Promise<{ placas: IPlacaMoto[] }> =>
  await apiGetGeneric<{ placas: IPlacaMoto[] }>(`${baseUrlOrdemServico}/Tecnicos/placasMoto`, params)
