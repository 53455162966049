import { useState } from "react";
import { GrupoServico } from "../pages/grupoServico/types";

export const GrupoServicoContext = () => {
  
  const [grupoServicoSelected, setGrupoServicoSelected] = useState<GrupoServico>({} as GrupoServico);

  return {
    grupoServicoSelected,
    setGrupoServicoSelected
  };
};
