export const EPerguntaGrupo = {
    VISAO_GERAL: "FDB77C4F-D6CE-470B-A9D4-BEDBDC29B459",
    ITENS_MOTO: "59BE47E2-59B6-42A4-B869-1E9EACBF8291",
    EPIS: "7463EA83-55A6-4D20-AEB1-8A89789EE4AF",
}

export const EPergunta = {
    QUILOMETRAGEM: "B9625349-A63C-40FC-842D-1E7D29679BFA",
    ITENS_MOTO_OBSERVACOES: "2483E310-63B3-4B8F-85EA-DB91CF2D8289",
    ITENS_MOTO_FOTOS: "CD06AD66-A280-484C-A58F-FA16C9103C9D",
    EPIS_OBSERVACOES: "79C42604-21DF-4D9B-8D87-39A5E0927A49",
    EPIS_FOTOS: "5873CD05-966D-46B9-AD19-D1118132A6BC",
}

export const ETipoOpcao = {
    TEXT: 1,
    TEXT_AREA: 2,
    BOOLEAN: 3,
    DATE: 4,
    CHECKBOX: 5,
    MULTSELECT: 6,
}

export const EPerguntasNaoVisiveisNaTabela = [
    "2483E310-63B3-4B8F-85EA-DB91CF2D8289",
    "CD06AD66-A280-484C-A58F-FA16C9103C9D",
    "79C42604-21DF-4D9B-8D87-39A5E0927A49",
    "5873CD05-966D-46B9-AD19-D1118132A6BC",
];

export const RESPOSTAS_NEGATIVAS = [
    "NOK",
    "nao",
    "Não"
]