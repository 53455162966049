import { useContext, useEffect, useState, useRef } from "react";
import Header from "../../../components/header/header";
import { HomeContext } from "../../../contexts/homeContext";
import { AuthContext } from "../../../contexts/auth";
import MultipleSelectCheckmarksId from '../../../components/seletorMultiploId/seletorMultiploId'
import Button from "../../../components/Button/styles";
import Input from "../../../components/Input/Input";
import { useFormik } from "formik";
import * as yup from 'yup'
import { STATUS_OPTIONS } from "./constants";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { postStatus, putStatus } from '../services';
import { AxiosResponse } from 'axios';
import { Breadcrumb, BreadcrumbItem } from "../../../components/breadcrumb/breadcrumb";
import "./incluirEditarStatusOrdemServico.scss";
import AlertMessage from "../../../components/AlertMessage/alertMessage";


const registerOrderServiceStatusSchema = yup.object({
    ativo: yup.array().max(1).min(1, 'Campo obrigatório'),
    nome: yup.string().required('O nome do status é obrigatório.'),
    descricao: yup.string().required('A descrição é obrigatória.'),
})

interface IInitialValues {
    ativo: string[],
    nome: string,
    descricao: string,
    idOppay: string | number,
    usuarioCriacao: string | number,
    dataCriacao: string,
    usuarioModificacao: string | number,
    dataModificacao: string,
}

 const IncluirEditarStatusOrdemServico = () => {
    const { setMenuLateral, ordemServicoStatusSelecionado, setOrdemServicoStatusSelecionado } = useContext(HomeContext);
    const { funcionalidadeDaTelaTemPermissao, user } = useContext(AuthContext);

    const isIncluding = window.location.pathname.includes('IncluirStatusOS');

    const isInitialMount = useRef(true);
    const navigate = useNavigate();

    const profileHasPermission = (funcionalidade: string) => funcionalidadeDaTelaTemPermissao(
        isIncluding ? "Cadastrar Status de OS" : "Editar Status de OS",
        funcionalidade);

    const [loading, setLoading] = useState<boolean>(false);
    const [msgAlert, setMsgAlert] = useState<string>('');
    const [tipomsgAlert, setTipoMsgAlert] = useState<'success' | 'info' | 'warning' | 'error'>("success");
    const [openAlert, setOpenAlert] = useState(false);
    const [disableActions, setDisableActions] = useState(false);

    const initialValuesIncluir: IInitialValues = {
        ativo: ['1'],
        nome: '',
        descricao: '',
        idOppay: '',
        usuarioCriacao: '',
        dataCriacao: '',
        usuarioModificacao: '',
        dataModificacao: '',
    }

    const initialValuesEditar: IInitialValues = {
        ativo: [(ordemServicoStatusSelecionado?.ativo ? '0' : '1')],
        nome: ordemServicoStatusSelecionado?.nome || '',
        descricao: ordemServicoStatusSelecionado?.descricao || '',
        idOppay: ordemServicoStatusSelecionado?.idOppay || '',
        usuarioCriacao: ordemServicoStatusSelecionado?.nomeUsuarioCriacao,
        dataCriacao: ordemServicoStatusSelecionado?.dataCriacao ? moment(ordemServicoStatusSelecionado.dataCriacao).format("DD/MM/YYYY HH:mm:ss") : '',
        usuarioModificacao: ordemServicoStatusSelecionado?.nomeUsuarioModificacao,
        dataModificacao: ordemServicoStatusSelecionado?.dataModificacao ? moment(ordemServicoStatusSelecionado?.dataModificacao).format("DD/MM/YYYY HH:mm:ss") : '',
    }

    const formik = useFormik({
        initialValues: isIncluding ? initialValuesIncluir : initialValuesEditar,
        validationSchema: registerOrderServiceStatusSchema,
        enableReinitialize: true,
        onSubmit: async (values, actions) => {
            if (isIncluding) {
                if(!!profileHasPermission("Novo Registro")) return handleIncludeStatus(values);
            } else {
                if(!!profileHasPermission("Editar Registro")) return handleEditStatus(values);
            }
        }
    });

    const handleIncludeStatus = async (values: IInitialValues) => {
        setLoading(true)
        const statusBool: boolean = values.ativo[0] === '0' ? true : false;
        try {
            const { data } = await postStatus({
                nome: values.nome,
                descricao: values.descricao,
                ativo: statusBool,
            }) as AxiosResponse;

            setLoading(false);
            setMsgAlert(!!data && typeof data === "string" ? data : 'Status de Ordem de Serviço cadastrado com sucesso');
            setTipoMsgAlert('success');

            setOpenAlert(true);
            setDisableActions(false);

            formik.resetForm();

        } catch (e: any) {
            setLoading(false);
            setMsgAlert(e?.response?.data?.errors ? Object.values(e.response.data.errors).join("<br>") : "Erro ao cadastrar status de ordem de serviço");

            setTipoMsgAlert("error");
            setOpenAlert(true);
            setDisableActions(false);
        }
    }

    const handleEditStatus = async (values: IInitialValues) => {
        setDisableActions(true);
        setOpenAlert(false);
        setLoading(true);
        
        try {
            const statusBool: boolean = values.ativo[0] === '0' ? true : false;

            const { data } = await putStatus({
                nome: values.nome,
                descricao: values.descricao,
                ativo: statusBool,
                idOppay: Number(values.idOppay)
            },  ordemServicoStatusSelecionado.id) as AxiosResponse;

            setOrdemServicoStatusSelecionado({
                ...ordemServicoStatusSelecionado,
                nome: values.nome,
                ativo: statusBool,
                idUsuarioModificacao: user?.idUsuario,
                dataModificacao: moment().format("YYYY-MM-DD HH:mm:ss")
            })

            setLoading(false);
            if (!!data && typeof data === "string") {
                setMsgAlert(data);
            } else {
                setMsgAlert("Status alterado com sucesso!");
            }
            setTipoMsgAlert("success");
            setOpenAlert(true);
            setDisableActions(false);

        } catch (e: any) {
            setLoading(false);
            if (e?.response?.data?.errors) {
                setMsgAlert(Object.values(e.response.data.errors).join("<br>"));
            } else {
                setMsgAlert("Erro ao editar status");
            }
            setTipoMsgAlert("error");
            setOpenAlert(true);
            setDisableActions(false);
        }
    }

    useEffect(() => {
        if (isInitialMount.current) {

            if (!isIncluding && !ordemServicoStatusSelecionado?.id) navigate("/IncluirStatusOS");

            isInitialMount.current = false;
        } else {
            if (isIncluding) formik.setValues(initialValuesIncluir)
        }
    }, [isIncluding])

    return (
        <section className="IncluirEditarStatusOrdemServico">
            <Header 
                setMenuLateral={() => setMenuLateral(true)}
                title={isIncluding ? "Inclusão de Status de Ordem de Serviço" : "Edição de Status de Ordem de Serviço"}
            />
            <Breadcrumb>
                <BreadcrumbItem onClick={() => navigate("/ConsultarStatusOS")}>
                    Consultar Status de Ordem de Serviço
                </BreadcrumbItem>
                <BreadcrumbItem>
                    {isIncluding ? "Inclusão de Status de Ordem de Serviço" : "Edição de Status de Ordem de Serviço"}
                </BreadcrumbItem>
            </Breadcrumb>

            <form className="Container" onSubmit={formik.handleSubmit}>
                <div className="IncluirStatusOrdemServico">
                    <div className="firstRow grid">
                        <MultipleSelectCheckmarksId
                            nodata={"Nenhum status encontrado"}
                            options={STATUS_OPTIONS}
                            value={formik.values.ativo}
                            onChange={(e) => formik.setFieldValue('ativo', e)}
                            placeholder={"Selecionar status"}
                            id="multiple-checkbox-status"
                            idOption="multiple-checkbox-option-Status"
                            labelId="multiple-checkbox-label-Status"
                            multiple={false}
                            dimension='sm'
                            name="status"
                            label='Status'
                            error={formik.touched.ativo && Boolean(formik.errors.ativo)}
                            helperText={formik.touched.ativo && formik.errors.ativo}
                        />
                    </div>

                    <div className="secondRow grid">
                        <Input
                            label='Nome do Status'
                            id='statusName'
                            dimension='sm'
                            name='nome'
                            error={formik.touched.nome && Boolean(formik.errors.nome)}
                            helperText={formik.touched.nome && formik.errors.nome}
                            value={formik.values.nome}
                            maxLength={50}
                            onChange={formik.handleChange}
                            disabled={disableActions}
                        />

                        <Input
                            label='Descrição'
                            id='description'
                            dimension='sm'
                            name='descricao'
                            error={formik.touched.descricao && Boolean(formik.errors.descricao)}
                            helperText={formik.touched.descricao && formik.errors.descricao}
                            value={formik.values.descricao}
                            maxLength={200}
                            onChange={formik.handleChange}
                            disabled={disableActions}
                        />
                    </div>

                    <div className="thirdRow grid">
                        <Input
                            label='Criado por'
                            id='createdBy'
                            dimension='sm'
                            name='criadoPor'
                            error={formik.touched.usuarioCriacao && Boolean(formik.errors.usuarioCriacao)}
                            helperText={formik.touched.usuarioCriacao && formik.errors.usuarioCriacao}
                            value={formik.values.usuarioCriacao}
                            maxLength={255}
                            readOnly
                            onChange={formik.handleChange}
                            disabled={disableActions}
                        />

                        <Input
                            dimension="sm"
                            label='Criado em'
                            id="dataCriacao"
                            value={formik.values.dataCriacao}
                            onChange={(e) => (formik.setFieldValue('dataCriacao', e))}
                            error={formik.touched.dataCriacao && Boolean(formik.errors.dataCriacao)}
                            helperText={(formik.touched.dataCriacao && formik.errors.dataCriacao) ? "Campo obrigatório" : ""}
                            readOnly
                        />

                        <Input
                            label='Alterado por'
                            id='changedBy'
                            dimension='sm'
                            name='alteradoPor'
                            error={formik.touched.usuarioModificacao && Boolean(formik.errors.usuarioModificacao)}
                            helperText={formik.touched.usuarioModificacao && formik.errors.usuarioModificacao}
                            value={formik.values.usuarioModificacao}
                            maxLength={255}
                            onChange={formik.handleChange}
                            readOnly
                            disabled={disableActions}
                        />

                        <Input
                            dimension="sm"
                            label='Editado em'
                            id="dataModificacao"
                            value={formik.values.dataModificacao}
                            onChange={(e) => (formik.setFieldValue('dataModificacao', e))}
                            error={formik.touched.dataModificacao && Boolean(formik.errors.dataModificacao)}
                            helperText={(formik.touched.dataModificacao && formik.errors.dataModificacao) ? "Campo obrigatório" : ""}
                            readOnly
                        />
                    </div>

                    {!!isIncluding && (
                        <>
                        {!!profileHasPermission("Novo Registro") ? (
                            <Button
                            type="submit"
                            variant="primary"
                            dimension="sm"
                            width="146px"
                            >
                            Salvar
                            </Button>
                        ) : (
                            <Button
                            type="button"
                            variant="primary"
                            dimension="sm"
                            width="146px"
                            style={{ cursor: "not-allowed", opacity: 0.5 }}
                            disabled={true}
                            >
                            Salvar
                            </Button>
                        )}
                        </>
                    )}

                    {!isIncluding && (
                        <>
                        {!!profileHasPermission("Editar Registro") ? (
                            <Button
                                type="submit"
                                variant="primary"
                                dimension="sm"
                                width="146px"
                            >
                                Salvar
                            </Button>
                        ) : (
                            <Button
                                type="button"
                                variant="primary"
                                dimension="sm"
                                width="146px"
                                style={{ cursor: "not-allowed", opacity: 0.5 }}
                                disabled={true}
                            >
                                Salvar
                            </Button>
                        )}
                        </>
                    )}
                </div>
            </form>
            <AlertMessage
                isOpenAlert={openAlert}
                setOpenAlert={setOpenAlert}
                alertType={tipomsgAlert}
                msgAlert={msgAlert}
            />
        </section>
    )
}

export default IncluirEditarStatusOrdemServico;