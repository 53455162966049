import React, { useState, useEffect, useContext, useRef } from "react";
import {
  Section,
  SearchButton,
  Body,
  FiltrosLabel,
  NewUserButton,
  ExportarButton,
} from "./consultaUsuario_styles";
import "./consultaUsuario.scss";
import Header from "../../../components/header/header";
import {
  Breadcrumb,
  BreadcrumbItem,
} from "../../../components/breadcrumb/breadcrumb";
import { HomeContext } from "../../../contexts/homeContext";
import { useNavigate } from "react-router-dom";
import MultipleSelectCheckmarksid from "../../../components/seletorMultiploId/seletorMultiploId";
import Input from "../../../components/Input/Input";
import { AxiosResponse } from "axios";
import StickyTable from "../../../components/stickyTable/stickyTable";
import { Alert, CircularProgress, IconButton } from '@mui/material';
import Collapse from '../../../components/collapse/collapse';
import CloseIcon from "@mui/icons-material/Close";
import Pagination from '../../../components/pagination/paginacao';
import { getUsuarios } from "../services";
import moment from "moment";
import { getPrestadores } from "../../enviarTecnico/services/services";
import { ReactComponent as ExportIcon } from "../../../assets/icons/ExportIcon.svg";
import AutocompleteMultiple from "../../../components/autocompleteMultiple/autocompleteMultiple";
import _ from "lodash";
import { IGetUsuarios } from "../types";
import { AuthContext } from "../../../contexts/auth";

const ConsultaUsuario = () => {
  const { setMenuLateral, listaContratante, localGetContratantes } = useContext(HomeContext);

  const { user, funcionalidadeDaTelaTemPermissao } = useContext(AuthContext);
  const profileHasPermission = (funcionalidade: string) => funcionalidadeDaTelaTemPermissao("Consulta Usuarios", funcionalidade);

  const navigate = useNavigate();

  const isInitialMount = useRef(true);

  const [disableActions, setDisableActions] = useState(false);
  const [nome, setNome] = useState<string>("");
  const [login, setLogin] = useState<string>("");
  const [status, setStatus] = useState<string[]>(['0']);
  const [listaStatus, setListaStatus] = useState<{ name: string, id: string }[]>([
    { name: '--', id: '' },
    { name: 'Ativo', id: '0' },
    { name: 'Inativo', id: '1' },
  ]);
  const [contratante, setContratante] = useState<string[]>([]);
  const [prestadores, setPrestadores] = useState<any[]>([]);
  const [selectedPrestadores, setSelectedPrestadores] = useState<any[]>([]);
  const [dadosConsultaUsuario, setDadosConsultaUsuario] = useState<any[]>([]);

  const [openAlertError, setOpenAlertError] = useState(false);
  const [msgAlertError, setMsgAlertError] = useState("Nenhum dado encontrado");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [loading, setLoading] = useState<boolean>(false);
  const [qtdeRegistros, setQtdeRegistros] = useState<number>(0);

  const handleButtonSearch = () => {
    if (currentPage !== 1) {
      setCurrentPage(1);
    } else {
      handleSearch();
    }
  };

  const handleSearch = async () => {
    setOpenAlertError(false);
    setLoading(true);
    setDisableActions(true);

    const statusBool = status[0] === '' ? '' : status[0] === '0' ? true : false;

    const params: IGetUsuarios = {
      ...(statusBool !== '' ? { 'Filtro.Ativo': statusBool } : {}),
      ...(!!contratante.length && contratante[0] !== '' ? { 'IdContratante': Number(contratante[0]) } : {}),
      ...(!!nome ? { 'Filtro.NomeCompleto': nome } : {}),
      ...(!!login ? { 'Filtro.Login': login } : {}),
      'Filtro.Limit': pageSize,
      'Filtro.Offset': (currentPage - 1) * pageSize,
    }

    try {
      let retorno = await getUsuarios(params, selectedPrestadores.map((p: any) => Number(p.id))) as AxiosResponse;
      setLoading(false);
      setDisableActions(false);
      setDadosConsultaUsuario(retorno?.data?.itens || []);
      setQtdeRegistros(retorno?.data?.totalRegistros || 0);

    } catch (error) {
      setLoading(false);
      setDisableActions(false);
      setDadosConsultaUsuario([]);
      setQtdeRegistros(0);
      setMsgAlertError("Nenhum dado encontrado");
      setOpenAlertError(true);
    }
  };

  const objTraducaoHeader = {
    ativo: "Status",
    status: "Status",
    idFamilia: "ID usuário",
    descricao: "Descrição",
    dataCriacao: "Data de Criação",
    idUsuarioCriacao: "ID Usuário Criação",
    usuarioCriacao: "Criado Por",
    dataModificacao: "Data de Modificação",
    idUsuarioModificacao: "ID Usuário Modifição",
    usuarioModificacao: "Modificado Por",

    id: "ID Usuário",
    nomeCompleto: "Nome",
    login: "Login",
    cpf: "CPF",
    idContratante: "ID Contratante",
    nomeContratante: "Contratante",
    prestadores: "Lista de Prestadores",
    dataExpiracao: "Data de Expiração",
    ultimoAcesso: "Data  Último Acesso",
  } as any;

  const getItemByID = (ID: number) => {
    let resultadoDados = [...dadosConsultaUsuario];

    return resultadoDados.find(
      (item) => item.id === ID
    )!;
  };

  const handleClickItemEdit = (data: any) => {
    navigate("/IncluirUsuario", {
      state: {
        dadosUsuario: getItemByID(data.id),
        rota: {
          url: window.location.pathname,
          name: "Consultar Usuário",
        },
      }
    });
  };

  const handleClickNewUser = () => {
    navigate("/IncluirUsuario", {
      state: {
        rota: {
          url: window.location.pathname,
          name: "Consultar Usuário",
        },
      }
    });
  };

  const smoothScroll = (target: any) => {
    var scrollContainer = target;
    do { //find scroll container
      scrollContainer = scrollContainer?.parentNode;
      if (!scrollContainer) return;
      scrollContainer.scrollTop += 1;
    } while (scrollContainer.scrollTop == 0);

    var targetY = 0;
    do { //find the top of target relatively to the container
      if (target == scrollContainer) break;
      targetY += target.offsetTop;
    } while (target = target.offsetParent);

    const scroll = function (c: any, a: number, b: number, i: number) {
      i++; if (i > 30) return;
      c.scrollTop = a + (b - a) / 30 * i;
      setTimeout(function () { scroll(c, a, b, i); }, 20);
    }
    // start scrolling
    scroll(scrollContainer, scrollContainer.scrollTop, targetY, 0);
  };

  const dadosFormatados = () => {
    let dados = [...dadosConsultaUsuario];

    /**Formata campos para tabela */
    dados = dados.map((item: any) => {
      return {
        ativo: !!item.ativo ? "Ativo" : "Inativo",
        id: item.id,
        nomeCompleto: item.nomeCompleto,
        login: item.login,
        nomeContratante: item.nomeContratante,
        prestadores: (!!item.prestadores?.length && _.isArray(item.prestadores)) ?
          item.prestadores.reduce((a: string, b: { id: number, nome: string }) => {
            if (!!a) return a + ', ' + b.nome;
            return b.nome;
          }, '')
          : '',
        dataExpiracao: !!item.dataExpiracao ? moment(item.dataExpiracao, "YYYY-MM-DDTHH:mm:ss").format("DD/MM/YYYY HH:mm:ss") : "",
        ultimoAcesso: !!item.ultimoAcesso ? moment(item.ultimoAcesso, "YYYY-MM-DDTHH:mm:ss").format("DD/MM/YYYY HH:mm:ss") : "",
        dataCriacao: !!item.dataCriacao ? moment(item.dataCriacao, "YYYY-MM-DDTHH:mm:ss").format("DD/MM/YYYY HH:mm:ss") : "",
        usuarioCriacao: item.usuarioCriacao,
        dataModificacao: !!item.dataModificacao ? moment(item.dataModificacao, "YYYY-MM-DDTHH:mm:ss").format("DD/MM/YYYY HH:mm:ss") : "",
        usuarioModificacao: item.usuarioModificacao,
      };
    })

    return dados;
  };

  const getProvider = async () => {
    try {
      const { data } = await getPrestadores() as AxiosResponse

      let prestadores = [];

      if (!!data && !!data.length) {
        prestadores = data.filter((p: any) => !!p.ativo).map((m: any) => ({ ...m, name: m.nomePrestador, id: m.id?.toString() }))
          .sort((a: any, b: any) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
      }

      if(!_.isEmpty(user?.prestadores)){
        prestadores = prestadores.filter((prestador: any) => user?.prestadores.includes(Number(prestador.id)))
      }

      setPrestadores(prestadores);

    } catch (e) {
      setPrestadores([]);
    }
  }

  useEffect(() => {
    localGetContratantes();
    getProvider();
  }, []);

  useEffect(() => {
    if (!!dadosConsultaUsuario.length) smoothScroll(document.getElementById('TabelaResultadoConsultaUsuario'));
  }, [dadosConsultaUsuario]);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      handleSearch();
    }
  }, [
    currentPage,
    pageSize
  ]);

  return (
    <Section id={"ConsultaUsuario"}>
      <Header
        setMenuLateral={() => setMenuLateral(true)}
        title="Consultar Usuário"
      />
      <Breadcrumb>
        <BreadcrumbItem>Consultar Usuário</BreadcrumbItem>
      </Breadcrumb>

      <Body>
        <FiltrosLabel>Filtros</FiltrosLabel>
        <div className="grid1">
          <MultipleSelectCheckmarksid
            dimension="sm"
            label={"Status"}
            nodata={"Nenhum status encontrado"}
            options={listaStatus}
            value={status}
            onChange={(value) => setStatus(value)}
            placeholder={""}
            id="multiple-checkbox-Status"
            idOption="multiple-checkbox-option-Status-"
            labelId="multiple-checkbox-label-Status"
            classes={{
              select: "ConsultaUsuario-Seletor error",
              primary: "Seletor-Option",
              group: "Seletor-OptionGroup",
            }}
            multiple={false}
            disabled={disableActions}
          />

          <MultipleSelectCheckmarksid
            dimension="sm"
            label={"Contratante"}
            nodata={"Nenhum contratante encontrado"}
            options={[{ name: '--', id: '' }, ...listaContratante]}
            value={contratante}
            onChange={(value) => setContratante(value)}
            placeholder={""}
            id="multiple-checkbox-Contratante"
            idOption="multiple-checkbox-option-Contratante-"
            labelId="multiple-checkbox-label-Contratante"
            classes={{
              select: "ConsultaUsuario-Seletor error",
              primary: "Seletor-Option",
              group: "Seletor-OptionGroup",
            }}
            multiple={false}
            disabled={disableActions}
          />

          <AutocompleteMultiple
            dimension="sm"
            label={"Prestadores"}
            placeholder={""}
            noOptionsText={"Nenhum prestador encontrado"}
            id="multiple-checkbox-Prestadores"
            options={prestadores}
            value={selectedPrestadores}
            onChange={setSelectedPrestadores}
            fontSize={12}
            disabled={disableActions}
            allSelected
          />
        </div>
        <div className="grid2">
          <Input
            className={"ConsultaUsuario-Input"}
            dimension="sm"
            id="input-Nome"
            label={"Nome"}
            value={nome}
            onChange={(e) => setNome(e.target.value)}
            maxLength={255}
            disabled={disableActions}
          />

          <Input
            className={"ConsultaUsuario-Input"}
            dimension="sm"
            id="input-Login"
            label={"Login"}
            value={login}
            onChange={(e) => setLogin(e.target.value)}
            maxLength={255}
            disabled={disableActions}
          />

          <SearchButton
            variant="contained"
            size="small"
            onClick={handleButtonSearch}
            disabled={disableActions}
          >
            Pesquisar
          </SearchButton>

          {!!profileHasPermission("Novo Registro") ?
            <NewUserButton
              color="secondary"
              variant="contained"
              size="small"
              onClick={handleClickNewUser}
            >
              Novo usuário
            </NewUserButton>
            :
            <NewUserButton
              className={"bloqueado"}
              style={{ cursor: 'not-allowed', opacity: 0.5 }}
              color="secondary"
              variant="contained"
              size="small"
            >
              Novo usuário
            </NewUserButton>
          }

          {/* {!!dadosConsultaUsuario.length &&
            <>
            {!!profileHasPermission("Exportar CSV") ?
            <ExportarButton
              color="secondary"
              variant="contained"
              size="small"
              startIcon={<ExportIcon />}
              // onClick={handleExport}
              disabled={disableActions}
            >
              Exportar
            </ExportarButton>
            :
            <ExportarButton
              className={"bloqueado"}
              style={{ cursor: 'not-allowed', opacity: 0.5 }}
              color="secondary"
              variant="contained"
              size="small"
              startIcon={<ExportIcon />}
            >
              Exportar
            </ExportarButton>
          }</>
          } */}
        </div>
      </Body>

      {openAlertError &&
        <Collapse onClose={setOpenAlertError} in={openAlertError}>
          <Alert
            severity='error'
            icon={<div />}
            variant="filled"
            sx={{
              mb: 2,
              bgcolor: "rgb(255, 35, 35, 0.7)",
              borderRadius: "15px",
              boxSizing: "border-box",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: '50px',
            }}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="medium"
                onClick={() => setOpenAlertError(false)}
              >
                <CloseIcon
                  id={"ModalEncaminharOS-AlertCloseIcon"}
                  fontSize="inherit"
                  sx={{
                    color: "white",
                  }}
                />
              </IconButton>
            }
          >
            <span
              style={{
                color: "white",
                fontSize: "16px",
              }}
              dangerouslySetInnerHTML={{ __html: msgAlertError }}
            />
          </Alert>
        </Collapse>
      }

      {!!loading && <CircularProgress style={{ margin: '0px auto' }} />}

      {!!dadosConsultaUsuario.length && (
        <>
          <StickyTable
            id={"TabelaResultadoConsultaUsuario"}
            data={dadosFormatados()}
            objTraducaoHeader={objTraducaoHeader}
            allowEdit={!!profileHasPermission("Editar Registro")}
            edit={true}
            handleClickEdit={handleClickItemEdit}
          />

          <Pagination
            className="pagination-bar"
            currentPage={currentPage}
            totalCount={qtdeRegistros}
            pageSize={pageSize}
            setPageSize={(value: string) => setPageSize(Number(value))}
            onPageChange={(page: number) => setCurrentPage(Number(page))}
            showLabel={false}
          />
        </>
      )}
    </Section>
  );
};

export default ConsultaUsuario;
