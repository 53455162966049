import React, { useContext, useEffect, useState } from 'react'
import Header from '../../../components/header/header'
import { HomeContext } from '../../../contexts/homeContext';
import { Breadcrumb, BreadcrumbItem } from '../../../components/breadcrumb/breadcrumb';
import { useNavigate } from 'react-router';
import MultipleSelectCheckmarksId from '../../../components/seletorMultiploId/seletorMultiploId';
import Input from '../../../components/Input/Input';
import moment from 'moment';
import "./incluirEditarPrestadorResponsavel.scss"
import Button from '../../../components/Button/styles';
import _ from 'lodash'
import { useFormik } from "formik";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import Collapse from '../../../components/collapse/collapse';
import CloseIcon from "@mui/icons-material/Close";
import * as yup from "yup";
import { postPrestadorResponsavel, putPrestadorResponsavel } from '../services/services';
import { AuthContext } from '../../../contexts/auth';

interface IInitialValues {
  ativo: string[],
  idMobyan: string,
  descricao: string,
  idOppay?: string,
  idPrestadorResponsavel: number | string,
  dataModificacao?: string,
  usuarioModificacao: number | string
  dataCriacao?: string,
  usuarioCriacao?: string
}

const IncluirEditarPrestadorResponsavel = () => {

  const isIncluding = window.location.pathname.includes('IncluiPrestadorResponsavel')

  const { setMenuLateral, prestadorResponsavel } = useContext(HomeContext)

  const { user, funcionalidadeDaTelaTemPermissao } = useContext(AuthContext);
  const profileHasPermission = (funcionalidade: string) => funcionalidadeDaTelaTemPermissao(
    isIncluding ? 
    "Inclusão Prestador Responsável"
    :
    "Edição Prestador Responsável",
    funcionalidade);

  const navigate = useNavigate()

  const [tipomsgAlert, setTipoMsgAlert] = useState<'success' | 'info' | 'warning' | 'error'>("success");
  const [msgAlert, setMsgAlert] = useState("OS encaminhada com  sucesso!");
  const [openAlert, setOpenAlert] = useState(false);

  const validationSchema = yup.object({
    ativo: yup.array().max(1).min(1,'Campo obrigatório'),
    descricao: yup.string().required("Campo obrigatório"),
  })

  const initialValues: IInitialValues = {
    ativo: !!isIncluding ? ['1'] : [!_.isNil(prestadorResponsavel?.ativo) ? Number(prestadorResponsavel?.ativo).toString() : '1'],
    idMobyan: !!isIncluding ? '' : (prestadorResponsavel?.id?.toString() || ''),
    idOppay: !!isIncluding ? '' : (prestadorResponsavel?.idOppay?.toString() || ''),
    descricao: !!isIncluding ? '' : (prestadorResponsavel?.descricao || ''),
    idPrestadorResponsavel: !!isIncluding ? '' : (prestadorResponsavel?.id || ''),
    dataModificacao: !!isIncluding ? '' : (prestadorResponsavel?.dataModificacao && moment(prestadorResponsavel?.dataModificacao).format('DD/MM/YYYY - HH:mm:ss').toString() || ''),
    usuarioModificacao: !!isIncluding ? '' : prestadorResponsavel.usuarioModificacao!,
    dataCriacao: !!isIncluding ? '' : (prestadorResponsavel?.dataCriacao && moment(prestadorResponsavel?.dataCriacao).format('DD/MM/YYYY - HH:mm:ss').toString() || ''),
    usuarioCriacao: !!isIncluding ? '' : prestadorResponsavel.usuarioCriacao!
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async (values, actions) => {
      if (isIncluding) {
        if (!!profileHasPermission("Criar novo Registro")) return await handleIncludePrestadorResponsavel(values);
      } else {
        if (!!profileHasPermission("Editar Registro")) return await handleEditPrestadorResponsavel(values);
      }
    }
  })

  const handleIncludePrestadorResponsavel = async (values: IInitialValues) => {
    try{

      await postPrestadorResponsavel({
        descricao: values.descricao,
        ativo: Boolean(Number(values.ativo[0])),
        idOppay: values.idOppay,
        idUsuarioCriacao: user?.idUsuario!
      })

      setMsgAlert("Prestador Responsável criado com sucesso")
      setTipoMsgAlert("success")
      setOpenAlert(true)

      formik.resetForm()

    } catch(e) {
      setMsgAlert("Erro ao criar Prestador Responsável")
      setTipoMsgAlert("error")
      setOpenAlert(true)
    }
  }

  const handleEditPrestadorResponsavel = async (values: IInitialValues) => {
    try{

      await putPrestadorResponsavel(values.idMobyan, {
        idPrestadorResponsavel: values.idMobyan,
        descricao: values.descricao,
        ativo: Boolean(Number(values.ativo[0])),
        idOppay: values.idOppay,
        dataModificacao: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss").toString(),
        idUsuarioModificacao: user?.idUsuario!
      })

      setMsgAlert("Prestador Responsável alterado com sucesso")
      setTipoMsgAlert("success")
      setOpenAlert(true)

    } catch(e) {
      setMsgAlert("Erro ao editar Prestador Responsável")
      setTipoMsgAlert("error")
      setOpenAlert(true)
    }
  }

  const statusLista = [
    {
      name: 'Ativo',
      id: '1'
    }, 
    {
      name: 'Inativo',
      id: '0'
    }
  ]

  useEffect(() => {
    if(_.isEmpty(prestadorResponsavel) && !isIncluding) navigate('/ConsultaPrestadorResponsavel')
  }, [])

  return(
    <section className='IncluirEditarPrestadorResponsavel'>
      <Header setMenuLateral={() => setMenuLateral(true)} title={isIncluding ? 'Inclusão Prestador Responsável' : 'Edição Prestador Responsável'} />
      <Breadcrumb>
        <BreadcrumbItem onClick={() => navigate("/ConsultaPrestadorResponsavel")}>Prestador Responsável</BreadcrumbItem>
        <BreadcrumbItem>{isIncluding ? 'Inclusão Prestador Responsável' : 'Edição Prestador Responsável'}</BreadcrumbItem>
      </Breadcrumb>
      <form className="Container" onSubmit={formik.handleSubmit}>
        <div className="IncluirPrestadorResponsavel">
          <MultipleSelectCheckmarksId
           nodata={"Nenhum status encontrado"}
           options={statusLista}
           value={formik.values.ativo}
           onChange={(e) => formik.setFieldValue('ativo', e)}
           placeholder={"Selecionar status"}
           id="multiple-checkbox-status"
           idOption="multiple-checkbox-option-status"
           labelId="multiple-checkbox-label-status"
           multiple={false}
           label='Status'
           name="status"
           className='IncluirPerfilSelectStatus'
           dimension='sm'
           error={formik.touched.ativo && Boolean(formik.errors.ativo)}
           helperText={formik.touched.ativo && formik.errors.ativo}
           disabled={isIncluding}
          />
          {!isIncluding &&
            <Input
            label='Id Mobyan'
            id='idPrestadorResponsavel'
            value={formik.values.idMobyan}
            onChange={formik.handleChange}
            dimension='sm'
            name="idMobyan"
            error={formik.touched.idMobyan && Boolean(formik.errors.idMobyan)}
            helperText={formik.touched.idMobyan && formik.errors.idMobyan}
            readOnly={!isIncluding}
            />
          }
          <Input
           label='Id Oppay'
           id='idPrestadorResponsavel'
           value={formik.values.idOppay}
           onChange={formik.handleChange}
           dimension='sm'
           name="idOppay"
           error={formik.touched.idOppay && Boolean(formik.errors.idOppay)}
           helperText={formik.touched.idOppay && formik.errors.idOppay}
           readOnly={!isIncluding}
          />
          <Input
           label='Descrição'
           id='descricaoPrestadorResponsavel'
           dimension='sm'
           name='descricao'
           error={formik.touched.descricao && Boolean(formik.errors.descricao)}
           helperText={formik.touched.descricao && formik.errors.descricao}
           value={formik.values.descricao}
           maxLength={255}
           onChange={formik.handleChange}
          />
        </div>
        {!isIncluding &&
          <div className="IncluirPrestadorResponsavel">
            <Input
              id='createdAt' 
              label='Data de Criação' 
              value={formik.values.dataCriacao} 
              readOnly 
              dimension='sm'
            />
            <Input
              id='createdBy' 
              label='Criado Por' 
              value={formik.values.usuarioCriacao}
              dimension='sm' 
              readOnly
            />
            <Input 
              id='modifiedAt' 
              label='Data de Modificaçao' 
              value={formik.values.dataModificacao} 
              readOnly 
              dimension='sm'
            />
            <Input
              id='modifiedBy' 
              label='Modificado Por' 
              dimension='sm'
              value={formik.values.usuarioModificacao} 
              readOnly
            />
          </div>
        }
        <Collapse onClose={setOpenAlert} in={openAlert}>
          <Alert
            severity={tipomsgAlert}
            icon={<div />}
            variant="filled"
            sx={{
              mb: 2,
              bgcolor: tipomsgAlert === "success" ? "#178B319E" : "rgb(255, 35, 35, 0.7)",
              height: "67px",
              borderRadius: "15px",
              boxSizing: "border-box",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginTop: "20px",
              marginBottom: 0,
            }}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="medium"
                onClick={() => setOpenAlert(false)}
              >
                <CloseIcon
                  id={"ModalEncaminharOS-AlertCloseIcon"}
                  fontSize="inherit"
                  sx={{
                    color: "white",
                  }}
                />
              </IconButton>
            }
          >
            <span
              style={{
                color: "white",
                fontSize: "16px",
              }}
              dangerouslySetInnerHTML={{__html: msgAlert}}
            />
          </Alert>
        </Collapse>
        <div className='ButtonSalvarContainer'>
          {!!isIncluding &&
            <>
              {!!profileHasPermission("Criar novo Registro") ?
                <Button
                  type="submit"
                  variant='primary'
                >
                  Incluir
                </Button>
                :
                <Button
                  type="button"
                  variant='primary'
                  style={{ cursor: 'not-allowed', opacity: 0.5 }}
                  disabled={true}>
                  Incluir
                </Button>
              }
            </>
          }

          {!isIncluding &&
            <>
              {!!profileHasPermission("Editar Registro") ?
                <Button
                  type="submit"
                  variant='primary'
                >
                  Salvar
                </Button>
                :
                <Button
                  type="button"
                  variant='primary'
                  style={{ cursor: 'not-allowed', opacity: 0.5 }}
                  disabled={true}>
                  Salvar
                </Button>
              }
            </>
          }
        </div>
      </form>
    </section>
  )
}

export default IncluirEditarPrestadorResponsavel