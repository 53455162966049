import React, { useContext, useEffect, useState } from 'react';
import Header from '../../../components/header/header';
import { Breadcrumb, BreadcrumbItem } from '../../../components/breadcrumb/breadcrumb';
import { HomeContext } from '../../../contexts/homeContext';
import { AuthContext } from '../../../contexts/auth';
import Input from '../../../components/Input/Input';
import Button from '../../../components/Button/styles';
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import Collapse from '../../../components/collapse/collapse';
import CloseIcon from "@mui/icons-material/Close";
import "./metasProdutividade.scss";
import { AxiosResponse } from 'axios';
import { IPrestador } from '../../../contexts/types/homeTypes';
import _ from 'lodash';
import FullscreenLoading from '../../../components/fullscreenLoading/fullscreenLoading';
import { getPrestadores } from '../../enviarTecnico/services/services';
import { useNavigate } from 'react-router-dom';
import * as yup from "yup";
import { useFormik } from "formik";
import { IInitialValues } from '../types';
import { ITecnicos } from '../../../contexts/types/encaminharTypes';
import { getListaTecnicosByPrestador } from '../../encaminharOS/services/services';
import { buscaUFs, getBuscarPrestadoresPeloUsuario } from '../../prestador/services/services';
import { mesesDoAno } from '../../../contexts/helpers/dadosPadrao';
import CSVModal from './preencherManualmenteModal';
import { postMetricas } from '../services/services';
import AutocompleteMultiple from "../../../components/autocompleteMultiple/autocompleteMultiple";
import moment from 'moment';
import Modal from '../../../components/modal'
import { ContainerCSVModal, ButtonList } from './preencherManualmenteModalStyles';

interface IMsgModalCSV {
  openModal: boolean,
  mensagem: string;
  tipo: 'success' | 'info' | 'warning' | 'error';
}

const IncluirEditarMetasProdutividade = () => {
  const isIncluding = window.location.pathname.includes('IncluirMetasProdutividade');

  const { setMenuLateral } = useContext(HomeContext);

  const { user, funcionalidadeDaTelaTemPermissao } = useContext(AuthContext);
  const profileHasPermission = (funcionalidade: string) => funcionalidadeDaTelaTemPermissao(
    isIncluding ? "Incluir Métricas do Técnico" : "Editar Métricas do Técnico",
    funcionalidade);

  const navigate = useNavigate();

  const [estadoLista, setEstadoLista] = useState<{ name: string, id: string, sigla: string }[]>([]);
  const [prestadorList, setPrestadorList] = useState<{ name: string, id: string }[]>([]);
  const [listaTecnicos, setListaTecnicos] = useState<ITecnicos[]>();

  const [loading, setLoading] = useState(false);

  const [tipomsgAlert, setTipoMsgAlert] = useState<'success' | 'info' | 'warning' | 'error'>("success");
  const [msgAlert, setMsgAlert] = useState("OS encaminhada com  sucesso!");
  const [openAlert, setOpenAlert] = useState(false);
  const [openWarningModal, setOpenWarningModal] = useState(false);

  const [openCSVModal, setOpenCSVModal] = useState(false);

  const initialValues: IInitialValues = {
    estado: [],
    prestador: [],
    tecnicos: [],
    mes: [],
    metaAtendimento: '',
    metaSLAMensal: '',
    metaSucessoMensal: '',
    dataCriacao: '',
    dataModificacao: '',
    usuarioCriacao: '',
    usuarioModificacao: '',
    rechamado: '',
    postura: ''
  };

  const validationSchema = yup.object({
    rechamado: yup.number().typeError('Somente numeros').test(
      'rechamadoPostura',
      'Preencha um dos campos para "Metas de 30 Dias"',
      (val, ctx) => {
        if (!val && !ctx.parent?.postura) return false;
        return true;
      }),
    postura: yup.number().typeError('Somente numeros').test(
      'posturaRechamado',
      'Preencha um dos campos para "Metas de 30 Dias"',
      (val, ctx) => {
        if (!val && !ctx.parent?.rechamado) return false;
        return true;
      }),
    metaSLAMensal: yup.string().test('percentage', 'O valor deve ser entre 0 e 100.', (val) => {
      if (!val) return true;
      return Number(val) >= 0 && Number(val) <= 100;
    }),
    metaSucessoMensal: yup.string().test('percentage', 'O valor deve ser entre 0 e 100.', (val) => {
      if (!val) return true;
      return Number(val) >= 0 && Number(val) <= 100;
    }),
  });


  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    // enableReinitialize: true,
    onSubmit: () => setOpenWarningModal(true)
  });

  const onConfirmClick = async (value: boolean) => {
    if (!!value) {
      if (isIncluding) {
        if (!!profileHasPermission("Novo Registro")) return await handleIncluirMetricas(formik.values);
      } else {
        console.log('editar metas: ', formik.values);
        // if (!!profileHasPermission("Alterar registro")) return await handleEditMetricas(formik.values);
      }
    } else {
      setOpenWarningModal(false);
    }
  };

  const requestListaTecnicos = async (idPrestador?: string) => {
    let listaTemp: ITecnicos[] = []
    for (let i = 0; i < formik.values.prestador.length; i++) {
      const id = idPrestador || formik.values.prestador[i]?.id;
      const tecnicos = await requestTecnicosPorContratante(id) as ITecnicos[]
      listaTemp.push(...tecnicos)
    }
    setListaTecnicos(listaTemp || []);
    return true;
  };

  const requestTecnicosPorContratante = async (id: string) => {
    try {
      let retorno = await getListaTecnicosByPrestador(Number(id)) as AxiosResponse;
      return retorno?.data
    } catch (e) {
      return []
    }
  };

  const getAllProviders = async () => {
    if (_.isEmpty(user?.data_user.prestadores)) {
      try {

        const { data } = await getPrestadores() as AxiosResponse

        setPrestadorList(data.map((item: IPrestador) => {
          return { name: item.nomePrestador, id: item.id.toString() }
        }).sort((a: { name: string, id: string }, b: { name: string, id: string }) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)))

      } catch (e) {
        setPrestadorList([])
      }

    } else {
      try {
        const { data } = await getBuscarPrestadoresPeloUsuario(user?.idUsuario!) as AxiosResponse

        setPrestadorList(data.map((item: IPrestador) => {
          return { name: item.nomePrestador, id: item.id.toString() }
        }).sort((a: { name: string, id: string }, b: { name: string, id: string }) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)))

      } catch (e) {
        setPrestadorList([])
      }
    }

  };

  const listaTecnicosByPrestadorTratada = () => {
    let resultadoDados;

    resultadoDados = listaTecnicos?.map((item: ITecnicos) => {
      return {
        name: item.nome || item.nomeCompleto,
        id: item?.idTecnico?.toString() || item.id?.toString(),
      };
    });

    /**Remove itens duplicados */
    resultadoDados = _.uniqBy(resultadoDados, 'id');

    return _.sortBy(resultadoDados, ["name"]);
  };

  const handleGetUFs = async () => {
    try {
      const { data } = await buscaUFs() as AxiosResponse

      setEstadoLista(data.map((item: { id: number, nome: string, sigla: string }) => {
        return { name: item.nome, id: item.id.toString(), sigla: item.sigla.toLowerCase() }
      }).sort(
        (a: { name: string, id: string }, b: { name: string, id: string }) =>
          (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)
      ))
    } catch (e) {
      console.log(e)
    }
  };

  const handleResultCSV = (values: IMsgModalCSV) => {
    setOpenAlert(values.openModal)
    setTipoMsgAlert(values.tipo)
    setMsgAlert(values.mensagem)
  };

  const handleIncluirMetricas = async (values: IInitialValues) => {
    setLoading(true);

    try {
      const { data } = await postMetricas({
        idPrestador: !!values.prestador?.length ? Number(values.prestador[0].id) : null,
        idTecnico: !!values.tecnicos?.length ? values.tecnicos.map((t: any) => Number(t.id)) : null,
        anoMes: values.mes[0]?.date ?? null,
        atendimento: Number(values.metaAtendimento),
        sla: Number(values.metaSLAMensal),
        sucesso: Number(values.metaSucessoMensal),
        ...(!!values.rechamado ? { rechamado: Number(values.rechamado) } : {}),
        ...(!!values.postura ? { postura: Number(values.postura) } : {}),
      }) as AxiosResponse;

      setTipoMsgAlert('success');

      if (!!data && typeof data === 'string') {
        setMsgAlert(data);
      } else {
        setMsgAlert("Metas de produtividade atribuidas ao(s) técnico(s) com sucesso!");
      }

      setOpenAlert(true);

      // formik.setValues(initialValues);

    } catch (e: any) {
      if (e?.response?.data?.errors) {
        setMsgAlert(Object.values(e.response.data.errors).join("<br>"));
      } else {
        setMsgAlert("Erro ao incluir metas" + (e?.response?.status ? ` status: ${e?.response?.status}` : ""));
      }
      setTipoMsgAlert('error');
      setOpenAlert(true);
    }
    setLoading(false)
  };

  const monthList = () => {
    return mesesDoAno.slice(moment().month(), mesesDoAno.length).map((m) => ({ ...m, name: (m.dateName.charAt(0).toUpperCase() + m.dateName.slice(1)) }));
  };

  useEffect(() => {
    getAllProviders();
    handleGetUFs();
  }, []);

  useEffect(() => {
    requestListaTecnicos();
  }, [formik.values.prestador]);

  return (
    <section className="MetasProdutividade">
      <Header setMenuLateral={() => setMenuLateral(true)} title="Metas de Produtividade" />
      <Breadcrumb>
        {/* <BreadcrumbItem onClick={() => navigate("/MetasProdutividade")}>Metas de Produtividade</BreadcrumbItem> */}
        <BreadcrumbItem>Incluir Meta de Produtividade</BreadcrumbItem>
      </Breadcrumb>

      <ReplaceDataWarning
        isModalOpen={openWarningModal}
        handleCloseModal={() => setOpenWarningModal(false)}
        confirmWarningModal={onConfirmClick}
      />

      {loading && <FullscreenLoading />}
      <form onSubmit={formik.handleSubmit}>
        <div className="Container">
          <div>
            <AutocompleteMultiple

              dimension='sm'
              label={"Estado"}
              placeholder={" "}
              noOptionsText={"Nenhum estado encontrado"}
              id="multiple-checkbox-estado"
              options={estadoLista}
              value={formik.values.estado}
              onChange={(e: any) => (formik.setFieldValue('estado', e))}
              limitTags={1}
              fontSize={12}
              multiple={false}
              error={formik.touched.estado && Boolean(formik.errors.estado)}
              helperText={formik.touched.estado && formik.errors.estado}
            />
            <AutocompleteMultiple
              dimension='sm'
              label={"Prestador"}
              placeholder={" "}
              noOptionsText={"Nenhum prestador encontrado"}
              id="multiple-checkbox-prestador"
              options={prestadorList}
              value={formik.values.prestador}
              onChange={(e: any) => (formik.setFieldValue('prestador', e))}
              limitTags={1}
              fontSize={12}
              multiple={false}
              error={formik.touched.prestador && Boolean(formik.errors.prestador)}
              helperText={formik.touched.prestador && formik.errors.prestador}
            />
            <AutocompleteMultiple
              dimension='sm'
              label={"Técnico"}
              placeholder={" "}
              noOptionsText={"Não há técnicos ativos"}
              id="multiple-checkbox-tecnicos"
              //@ts-ignore
              options={listaTecnicosByPrestadorTratada()}
              value={formik.values.tecnicos}
              onChange={(e: any) => (formik.setFieldValue('tecnicos', e))}
              limitTags={1}
              fontSize={12}
              multiple={true}
              allSelected
              error={formik.touched.tecnicos && Boolean(formik.errors.tecnicos)}
              helperText={formik.touched.tecnicos && formik.errors.tecnicos}
            />
          </div>
          <div>
            <AutocompleteMultiple
              keepOptionInTheList={true}
              dimension='sm'
              label={"Mês*"}
              placeholder={" "}
              noOptionsText={"Nenhum mês encontrado"}
              id="multiple-checkbox-mes"
              options={monthList()}
              value={formik.values.mes}
              onChange={(e: any) => (formik.setFieldValue('mes', e))}
              limitTags={1}
              fontSize={12}
              multiple={false}
              error={formik.touched.mes && Boolean(formik.errors.mes)}
              helperText={formik.touched.mes && formik.errors.mes}
            />
            <Input
              type='number'
              value={formik.values.metaAtendimento}
              onChange={(e) => formik.setFieldValue('metaAtendimento', e.target.value)}
              dimension='sm'
              label='Meta de atendimento'
              name='metaAtendimento'
              id='metaAtendimento'
              error={formik.touched.metaAtendimento && Boolean(formik.errors.metaAtendimento)}
              helperText={formik.touched.metaAtendimento && formik.errors.metaAtendimento}
            />
            <Input
              maxLength={3}
              label="Meta de SLA mensal (0-100%)"
              value={formik.values.metaSLAMensal}
              onChange={(e) => formik.setFieldValue('metaSLAMensal', e.target.value.replace(/\D/g, ""))}
              dimension='sm'
              name='metaSLAMensal'
              id='metaSLAMensal'
              error={formik.touched.metaSLAMensal && Boolean(formik.errors.metaSLAMensal)}
              helperText={formik.touched.metaSLAMensal && formik.errors.metaSLAMensal}
            />
            <Input
              maxLength={3}
              label="Meta de Sucesso Mensal (0-100%)"
              value={formik.values.metaSucessoMensal}
              onChange={(e) => formik.setFieldValue('metaSucessoMensal', e.target.value.replace(/\D/g, ""))}
              dimension='sm'
              name='metaSucessoMensal'
              id='metaSucessoMensal'
              error={formik.touched.metaSucessoMensal && Boolean(formik.errors.metaSucessoMensal)}
              helperText={formik.touched.metaSucessoMensal && formik.errors.metaSucessoMensal}
            />
          </div>
        </div>

        <div className='Container'>
          <p className='Title'>Metas de 30 dias</p>
          <div>
            <Input
              label="Rechamado"
              value={formik.values.rechamado}
              onChange={(e) => formik.setFieldValue('rechamado', e.target.value.replace(/\D/g, ""))}
              dimension='sm'
              name='rechamado'
              id='rechamado'
              error={formik.touched.rechamado && Boolean(formik.errors.rechamado)}
              helperText={formik.touched.rechamado && formik.errors.rechamado}
            />
            <Input
              maxLength={3}
              label="Postura técnica"
              value={formik.values.postura}
              name='postura'
              id='postura'
              onChange={(e) => formik.setFieldValue('postura', e.target.value.replace(/\D/g, ""))}
              dimension='sm'
              error={formik.touched.postura && Boolean(formik.errors.postura)}
              helperText={formik.touched.postura && formik.errors.postura}
            />
          </div>
        </div>

        <div className='Container'>
          <div>
            <Input
              label='Criado por'
              value={formik.values.usuarioCriacao}
              dimension='sm'
              readOnly
              name='usuarioCriacao'
              id='usuarioCriacao'
              error={formik.touched.usuarioCriacao && Boolean(formik.errors.usuarioCriacao)}
              helperText={formik.touched.usuarioCriacao && formik.errors.usuarioCriacao}
            />
            <Input
              label='Criado em'
              value={formik.values.dataCriacao}
              readOnly
              dimension='sm'
              name='dataCriacao'
              id='dataCriacao'
              error={formik.touched.dataCriacao && Boolean(formik.errors.dataCriacao)}
              helperText={formik.touched.dataCriacao && formik.errors.dataCriacao}
            />
            <Input
              id='usuarioModificacao'
              label='Alterado por'
              dimension='sm'
              value={formik.values.usuarioModificacao}
              name='usuarioModificacao'
              error={formik.touched.usuarioModificacao && Boolean(formik.errors.usuarioModificacao)}
              helperText={formik.touched.usuarioModificacao && formik.errors.usuarioModificacao}
              readOnly
            />
            <Input
              label='Alterado em'
              value={formik.values.dataModificacao}
              readOnly
              dimension='sm'
              name='dataModificacao'
              id='dataModificacao'
              error={formik.touched.dataModificacao && Boolean(formik.errors.dataModificacao)}
              helperText={formik.touched.dataModificacao && formik.errors.dataModificacao}
            />
          </div>
        </div>

        <div className='ButtonContainer'>
          {!!isIncluding &&
            <>
              {/* <Button
                dimension='sm'
                variant='grey'
                margin='0'
                type='button'
                onClick={() => setOpenCSVModal(true)}
                style={{ opacity: (!formik.values.mes[0] ? "0.5" : "1") }}
                disabled={!formik.values.mes[0]}
              >
                Preencher manualmente (CSV)
              </Button> */}

              {!!profileHasPermission("Novo Registro") ?
                <Button
                  dimension='sm'
                  variant='primary'
                  margin='0'
                  type='submit'
                  style={{ opacity: (!formik.values.mes[0] ? 0.5 : 1) }}
                  disabled={!formik.values.mes[0]}
                >
                  Atribuir Metas
                </Button>
                :
                <Button
                  dimension='sm'
                  variant='primary'
                  margin='0'
                  type="button"
                  style={{ cursor: 'not-allowed', opacity: 0.5 }}
                  disabled={true}>
                  Atribuir Metas
                </Button>
              }
            </>
          }

          {!isIncluding &&
            <>
              {!!profileHasPermission("Alterar registro") ?
                <Button
                  dimension='sm'
                  variant='primary'
                  margin='0'
                  type="submit"
                  style={{ opacity: (!formik.values.mes[0] ? 0.5 : 1) }}
                  disabled={!formik.values.mes[0]}
                >
                  Salvar
                </Button>
                :
                <Button
                  dimension='sm'
                  variant='primary'
                  margin='0'
                  type="button"
                  style={{ cursor: 'not-allowed', opacity: 0.5 }}
                  disabled={true}>
                  Salvar
                </Button>
              }
            </>
          }

        </div>
        <CSVModal
          isModalOpen={openCSVModal}
          msgModalAlert={handleResultCSV}
          handleCloseModal={() => setOpenCSVModal(false)}
        />
        <Collapse onClose={setOpenAlert} in={openAlert}>
          <Alert
            severity={tipomsgAlert}
            icon={<div />}
            variant="filled"
            sx={{
              mb: 2,
              bgcolor: tipomsgAlert === "success" ? "#178B319E" : "rgb(255, 35, 35, 0.7)",
              height: "67px",
              borderRadius: "15px",
              boxSizing: "border-box",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginTop: "20px",
              marginBottom: 0,
            }}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="medium"
                onClick={() => setOpenAlert(false)}
              >
                <CloseIcon
                  id={"ModalEncaminharOS-AlertCloseIcon"}
                  fontSize="inherit"
                  sx={{
                    color: "white",
                  }}
                />
              </IconButton>
            }
          >
            <span
              style={{
                color: "white",
                fontSize: "16px",
              }}
              dangerouslySetInnerHTML={{ __html: msgAlert }}
            />
          </Alert>
        </Collapse>
      </form>

    </section>
  );
};

interface IWarningModal {
  isModalOpen: boolean,
  handleCloseModal: () => void,
  confirmWarningModal: (value: boolean) => void
}

const ReplaceDataWarning = ({
  isModalOpen,
  handleCloseModal,
  confirmWarningModal,
}: IWarningModal) => {

  const [isOpen, setIsOpen] = useState(isModalOpen);

  const onBtnClick = () => {
    confirmWarningModal(true);
    setTimeout(() => handleCloseModal(), 1000);
  };

  const interHandleClose = () => {
    confirmWarningModal(false);
    handleCloseModal();
  };

  useEffect(() => {
    setIsOpen(isModalOpen)
  }, [isModalOpen]);

  return (
    <Modal
      handleCloseModal={interHandleClose}
      title=''
      isOpen={isOpen}
      titleStyle={{
        m: 0,
        p: 2,
        padding: "16px 22px",
        bgcolor: "#FFF",
        color: "#797D8C",
        boxSizing: "border-box",
        height: "67px",
        paddingLeft: '80px',
      }}
    >
      <ContainerCSVModal>
        <h2 style={{ textAlign: 'center' }}>
          Caso haja dados atribuídos aos técnicos anteriormente, serão atualizados. Deseja prosseguir?
        </h2>
        <ButtonList>
          <Button onClick={onBtnClick}>Sim</Button>
          <Button variant='secondary' onClick={interHandleClose}>Cancelar</Button>
        </ButtonList>
      </ContainerCSVModal>
    </Modal>
  );
};

export default IncluirEditarMetasProdutividade;
