import React, { useState } from 'react'
import Modal from '../../../components/modal'
import Button from '../../../components/Button/styles';
import { ContainerErroModal } from './warningModalStyles';
import { useEffect } from 'react';

interface IWarningModal {
  isModalOpen: boolean,
  handleCloseModal: () => void,
  text: string
}

const WarningModal = ({
  isModalOpen,
  handleCloseModal,
  text,
}: IWarningModal) => {

  const [isOpen, setIsOpen] = useState(isModalOpen);

  useEffect(() => {
    setIsOpen(isModalOpen);
  }, [isModalOpen]);

  const handleText = () => {
    let arrText = text.split('.');

    if (arrText.length > 2) {
      return arrText.join("<br>");
    }

    return text;
  }

  return (
    <Modal
      handleCloseModal={handleCloseModal}
      title=''
      isOpen={isOpen}
      titleStyle={{
        m: 0,
        p: 2,
        padding: "16px 22px",
        bgcolor: "#FFF",
        color: "#797D8C",
        boxSizing: "border-box",
        height: "67px",
        paddingLeft: '80px',
      }}
    >
      <ContainerErroModal>
        <h2 style={{textAlign: 'center'}} dangerouslySetInnerHTML={{ __html: handleText() }}/>
        <Button onClick={handleCloseModal} width='50%'>Ok</Button>
      </ContainerErroModal>
    </Modal>
  )
}

export default WarningModal