import React, { useState, useContext, useEffect } from "react";
import _ from "lodash";
import { AuthContext } from "../../contexts/auth";
import Modal from "../../components/modal";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import Collapse from '../../components/collapse/collapse';
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import {
  Container,
  Body,
  InfoLabel,
  ButtonGrid,
} from "./modalSalvarPreset_Styles";
import { AxiosResponse } from "axios";
import { siteTheme } from "../../styles/themeVariables";
import Input from "../../components/Input/Input";
import { postPresets, putPresets } from "./services";
import { IGetPresetResponse, IPresetsModalCmp } from "./types";

const { color } = siteTheme

const ModalSalvarPreset = ({
  isModalOpen,
  handleClose,
  handleGetPresets,
  listaPresets = [],
  selectedPreset = null,
  newObjectToPreset = {},
  rotaTela,
}: IPresetsModalCmp) => {
  const { user, getTela } = useContext(AuthContext);

  const [isOpen, setIsOpen] = useState(isModalOpen);
  const [existingPreset, setExistingPreset] = useState<IGetPresetResponse | null>(selectedPreset);
  const [nomePreset, setNomePreset] = useState<string>(!!existingPreset?.id ? existingPreset?.nome ?? "" : "");
  const [updatePreset, setUpdatePreset] = useState<boolean>(false);
  const [presetJaCadastrado, setPresetJaCadastrado] = useState<boolean>(false);
  const [tipomsgAlert, setTipoMsgAlert] = useState<'success' | 'info' | 'warning' | 'error'>("success");
  const [msgAlert, setMsgAlert] = useState<string>("OS encaminhada com  sucesso!");
  const [openAlert, setOpenAlert] = useState<boolean>(false);
  const [savePresetDone, setSavePresetDone] = useState<boolean>(false);
  const [disableActions, setDisableActions] = useState<boolean>(false);

  const handleSalvarPreset = () => {
    if (!!existingPreset?.id || !!presetJaCadastrado) handleUpdatePreset();
    else handleIncludePreset();
  };

  const handleIncludePreset = async () => {
    setDisableActions(true);

    if (!!openAlert) {
      setOpenAlert(false);
      setMsgAlert('');
    }

    if (!nomePreset) {
      setTipoMsgAlert("error");
      setMsgAlert("Insira um nome para o preset!");
      setOpenAlert(true);
      setDisableActions(false);
      return;
    }

    const presetJaExiste = listaPresets.find(p => p.nome === nomePreset) || null;

    if (presetJaExiste) {
      setDisableActions(false);
      return setPresetJaCadastrado(true);
    }

    await postPresets({
      nome: nomePreset,
      filtro: JSON.stringify(newObjectToPreset),
      nomeTela: getTela(rotaTela)?.nome?.toLowerCase(),
      idUsuario: user?.idUsuario ?? 0
    })
      .then((retorno: any) => {
        const { data } = retorno as AxiosResponse;

        setTipoMsgAlert("success");
        if (!!data && typeof data === "string") {
          setMsgAlert(data);
        } else {
          setMsgAlert('O preset foi salvo com sucesso!');
        }
        setOpenAlert(true);
        setDisableActions(false);
        setSavePresetDone(true);

        handleGetPresets();
      })
      .catch((e: any) => {
        setTipoMsgAlert("error");
        if (e?.response?.data?.errors && _.isObject(e?.response?.data?.errors)) {
          setMsgAlert(Object.values(e.response.data.errors).join("<br>"));
        } else {
          setMsgAlert("Não foi possível salvar o Preset");
        }
        setOpenAlert(true);
        setDisableActions(false);
      });
  };

  const handleUpdatePreset = async () => {
    setDisableActions(true);

    if (!!openAlert) {
      setOpenAlert(false);
      setMsgAlert('');
    }

    if (!nomePreset) {
      setTipoMsgAlert("error");
      setMsgAlert("Insira um nome para o preset!");
      setOpenAlert(true);
      setDisableActions(false);
      return;
    }

    let idPresetParaAtualizar = existingPreset?.id ?? 0;

    if (!idPresetParaAtualizar) {
      const presetJaExiste = listaPresets.find(p => p.nome === nomePreset) || null;
      idPresetParaAtualizar = presetJaExiste?.id ?? 0;
    }

    await putPresets(idPresetParaAtualizar, {
      nome: nomePreset,
      filtro: JSON.stringify(newObjectToPreset),
      nomeTela: getTela(rotaTela)?.nome?.toLowerCase(),
      idUsuario: user?.idUsuario ?? 0
    })
      .then((retorno: any) => {
        const { data } = retorno as AxiosResponse;

        setTipoMsgAlert("success");
        if (!!data && typeof data === "string") {
          setMsgAlert(data);
        } else {
          setMsgAlert('O preset foi salvo com sucesso!');
        }

        setOpenAlert(true);
        setSavePresetDone(true);
        setDisableActions(false);
        handleGetPresets();
      })
      .catch((e: any) => {
        setTipoMsgAlert("error");

        if (e?.response?.data?.errors && _.isObject(e?.response?.data?.errors)) {
          setMsgAlert(Object.values(e.response.data.errors).join("<br>"));
        } else {
          setMsgAlert("Não foi possível atualizar o Preset");
        }

        setOpenAlert(true);
        setDisableActions(false);
      });
  };

  useEffect(() => {
    setIsOpen(isModalOpen);
    setExistingPreset(selectedPreset);
  }, [isModalOpen]);

  return (
    <Modal
      handleCloseModal={handleClose}
      title={(!!existingPreset?.id || !!presetJaCadastrado) ? "Salvar Preset" : "Salvar Novo Preset"}
      isOpen={isOpen}
      sx={{
        '& .MuiDialog-paper': {
          maxWidth: '650px',
        }
      }}
    >
      <Container>
        {(!existingPreset?.id && !presetJaCadastrado) &&
          <Body>
            <InfoLabel>
              Atribua um nome para essa configuração
            </InfoLabel>

            <Input
              className={"ConsultaOS-Input"}
              dimension="sm"
              id="input-nomePreset"
              label={""}
              placeholder={"Nome do Preset"}
              value={nomePreset}
              onChange={(e) => setNomePreset(e.target.value)}
              maxLength={255}
              disabled={disableActions || savePresetDone}
            />

            <ButtonGrid>
              <Button
                id={"ModalSalvarPreset-BotaoSalvar"}
                disabled={disableActions || savePresetDone}
                onClick={() => handleSalvarPreset()}
                sx={{
                  borderRadius: '15px',
                  bgcolor: color.primary,
                  color: "#FFF",
                  "&:hover": {
                    backgroundColor: color.primary,
                    color: "#FFF",
                  },
                  fontFamily: "Open Sans",
                }}
              >
                Salvar
              </Button>

              <Button
                id={"ModalSalvarPreset-BotaoCancelar"}
                disabled={disableActions}
                onClick={() => handleClose()}
                sx={{
                  border: `1px solid ${color.primary}`,
                  borderRadius: '15px',
                  fontFamily: "Open Sans",
                }}
              >
                {savePresetDone ? "Fechar" : "Cancelar"}
              </Button>
            </ButtonGrid>
          </Body>
        }

        {(!updatePreset && !!existingPreset?.id) &&
          <Body>
            <InfoLabel>
              Deseja atualizar essa configuração?
            </InfoLabel>

            <Input
              className={"ConsultaOS-Input"}
              dimension="sm"
              id="input-nomePreset"
              label={""}
              placeholder={"Nome do Preset"}
              value={nomePreset}
              onChange={(e) => setNomePreset(e.target.value)}
              maxLength={255}
              disabled={true}
            />

            <ButtonGrid>
              <Button
                id={"ModalSalvarPreset-BotaoSalvar"}
                disabled={disableActions || savePresetDone}
                onClick={() => handleSalvarPreset()}
                sx={{
                  borderRadius: '15px',
                  bgcolor: color.primary,
                  color: "#FFF",
                  "&:hover": {
                    backgroundColor: color.primary,
                    color: "#FFF",
                  },
                  fontFamily: "Open Sans",
                }}
              >
                Sim
              </Button>

              <Button
                id={"ModalSalvarPreset-BotaoCancelar"}
                disabled={disableActions}
                onClick={() => setUpdatePreset(true)}
                sx={{
                  border: `1px solid ${color.primary}`,
                  borderRadius: '15px',
                  fontFamily: "Open Sans",
                }}
              >
                Não
              </Button>
            </ButtonGrid>
          </Body>
        }

        {(!!updatePreset && !presetJaCadastrado) &&
          <Body>
            <InfoLabel>
              Escolha um novo nome para este preset
            </InfoLabel>

            <Input
              className={"ConsultaOS-Input"}
              dimension="sm"
              id="input-nomePreset"
              label={""}
              placeholder={"Nome do Preset"}
              value={nomePreset}
              onChange={(e) => setNomePreset(e.target.value)}
              maxLength={255}
              disabled={disableActions || savePresetDone}
            />

            <ButtonGrid>
              <Button
                id={"ModalSalvarPreset-BotaoSalvar"}
                disabled={disableActions || savePresetDone}
                onClick={handleIncludePreset}
                sx={{
                  borderRadius: '15px',
                  bgcolor: color.primary,
                  color: "#FFF",
                  "&:hover": {
                    backgroundColor: color.primary,
                    color: "#FFF",
                  },
                  fontFamily: "Open Sans",
                }}
              >
                Salvar
              </Button>
            </ButtonGrid>
          </Body>
        }

        {!!presetJaCadastrado &&
          <Body>
            <InfoLabel>
              Este nome já foi utilizado anteriormente. Deseja atualizar?
            </InfoLabel>

            <ButtonGrid>
              <Button
                id={"ModalSalvarPreset-BotaoSalvar"}
                disabled={disableActions || savePresetDone}
                onClick={() => handleSalvarPreset()}
                sx={{
                  borderRadius: '15px',
                  bgcolor: color.primary,
                  color: "#FFF",
                  "&:hover": {
                    backgroundColor: color.primary,
                    color: "#FFF",
                  },
                  fontFamily: "Open Sans",
                }}
              >
                Sim
              </Button>

              <Button
                id={"ModalSalvarPreset-BotaoCancelar"}
                disabled={disableActions}
                onClick={() => {
                  if (!existingPreset?.id) {
                    setUpdatePreset(false);
                    setPresetJaCadastrado(false);
                  } else {
                    setPresetJaCadastrado(false);
                  }
                }}
                sx={{
                  border: `1px solid ${color.primary}`,
                  borderRadius: '15px',
                  fontFamily: "Open Sans",
                }}
              >
                Não
              </Button>
            </ButtonGrid>
          </Body>
        }

        <Collapse
          onClose={setOpenAlert}
          in={openAlert}
          sx={{
            position: "absolute",
            left: 0,
            right: 0,
            bottom: 0,
          }}
        >
          <Alert
            severity={tipomsgAlert}
            icon={<div />}
            variant="filled"
            sx={{
              mb: 2,
              bgcolor: tipomsgAlert === 'success' ? "#178B319E" : "#FF23239E",
              height: "67px",
              boxSizing: "border-box",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: 0,
              borderRadius: 0,
            }}
            action={
              <IconButton
                id={"ModalEncaminharOS-AlertCloseIcon"}
                aria-label="close"
                color="inherit"
                size="medium"
                onClick={() => setOpenAlert(false)}
              >
                <CloseIcon
                  fontSize="inherit"
                  sx={{
                    color: "white",
                  }}
                />
              </IconButton>
            }
          >
            <span
              style={{
                color: "white",
                fontSize: "14px",
              }}
              dangerouslySetInnerHTML={{ __html: msgAlert }}
            />
          </Alert>
        </Collapse>
      </Container>
    </Modal>
  );
};

export default ModalSalvarPreset;
