import { useEffect, useState } from "react";
import Modal from "../../../components/modal";

import { Container, StyledTable, StyledTableCell, StyledTableRow, StyledTableHead, StyledTableBody } from "./modalChecklistProdutoStyles";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { consultarDetalheProdutosPartesPecas } from '../services/index';
import { ITipoProdutoConsultaOS } from "../../consultaOS/types";


interface IModalChecklistProduto {
    isModalOpen: boolean,
    handleCloseModal: () => void,
    produto: any
}

const tipoProdutoMap: Record<number, string> = {
    [ITipoProdutoConsultaOS.RETIRADA]: 'Retirado',
    [ITipoProdutoConsultaOS.MANUTENCAO]: 'Manutenção',
    [ITipoProdutoConsultaOS.ENTREGA]: 'Entrega',
};

const ModalChecklistProduto = ({isModalOpen, handleCloseModal, produto}: IModalChecklistProduto) => {
    const [isOpen, setIsOpen] = useState(isModalOpen);
    
    const [pecas, setPecas] = useState<any[]>([]);


    useEffect(() => {
        setIsOpen(isModalOpen);
    }, [isModalOpen]);

    useEffect(() => {
        
        if (produto && produto.id) {
            getPartesPecas(produto.id);  
        } 
    }, [produto])

    const getPartesPecas = async (id: any) => {
        consultarDetalheProdutosPartesPecas(id).then((response: any) => {
            setPecas(response.data || []);
        }).catch((error) => {
            console.error('Erro ao consultar detalhes do produto:', error);
        });
    }

    return (
        <Modal
            handleCloseModal={handleCloseModal}
            title='Checklist'
            titleStyle={{
                color: "#797D8C",
                bgcolor: 'none'
            }}
            isOpen={isOpen}
            sx={{ "& .MuiPaper-elevation": { maxWidth: "1000px" } }}
        >
            <Container>
                <TableContainer component={Paper}>
                    <StyledTable aria-label="simple table">
                        <StyledTableHead>
                            <TableRow>
                                <StyledTableCell component="th">Item</StyledTableCell>
                                <StyledTableCell component="th" align="center">Tipo</StyledTableCell>
                            </TableRow>
                        </StyledTableHead>
                        <TableBody>
                            {Array.isArray(pecas) && pecas.map((peca: any, index: number) => (
                                <StyledTableRow key={index}>
                                    <StyledTableCell component="td" scope="row">
                                        {peca.nomeModelo || 'N/A'}
                                    </StyledTableCell>
                                    <StyledTableCell align="center">{produto && produto.idTipo ? tipoProdutoMap[produto.idTipo] || 'Desconhecido' : 'Desconhecido'}</StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </StyledTable>
                </TableContainer>
            </Container>
        </Modal>
    )
}

export default ModalChecklistProduto;