import React, { useState, useEffect, useMemo } from 'react';
import { ButtonContainer, Container, Grid, GridLabel, GridScroll, AddIconStyled, GridLabelTop } from './editarCustodiaModalStyles';
import Modal from '../../../components/modal';
import Input from '../../../components/Input/Input';
import { TextArea } from '../../../components/Input/Input';
import { ICustodia, IGetCustodiaResponse, IPutCustodia } from '../types';
import _ from 'lodash';
import { AxiosResponse } from 'axios';
import Button from '../../../components/Button/styles';
import { useFormik } from "formik";
import * as yup from "yup";
import { IAutocompleteValue } from '../../../components/autocompleteMultiple/types';
import moment from 'moment';
import AutocompleteMultiple from '../../../components/autocompleteMultiple/autocompleteMultiple';
import DateTimePicker from "../../../components/dateTimePicker/dateTimePicker";
import { getListaTecnicosByLotePrestador } from '../../encaminharOS/services/services';
import { ITecnicos } from '../../../contexts/types/encaminharTypes';
import AlertMessage from '../../../components/AlertMessage/alertMessage';
import { createErrorMsg } from '../../../services/api';
import { getCustodia, putCustodias } from '../services/services';
import YesOrNoModal from '../../../components/yesOrNoModal/yesOrNoModal';

interface IEditarCustodiaModal {
  isModalOpen: boolean,
  handleCloseModal: () => void,
  custodia: ICustodia | null,
  listaPrestadores: IAutocompleteValue,
  listaStatus: IAutocompleteValue,
  listaUsuarios: IAutocompleteValue,
};

interface IInitialValues {
  "custodiaStatus": IAutocompleteValue,
  "numeroSerie": string,
  "tecnico": IAutocompleteValue,
  "dataRecebimentoTecnico": any,
  "dataBaixaOSEquipamento": any,
  "idOrdemServico": string,
  "dataEnvioTecnico": any,
  "dataCorrecaoEnvio": any,
  "responsavelEntregaEquipamentoAoTecnico": IAutocompleteValue,
  "responsavelConfirmacaoRecebimentoPA": IAutocompleteValue,
};

interface IAutocompleteField { name: string, id: string, type: string };

const EditarCustodiaModal = ({
  isModalOpen,
  handleCloseModal,
  custodia,
  listaPrestadores,
  listaStatus,
  listaUsuarios,
}: IEditarCustodiaModal) => {

  const [yesOrNoModalOpen, setYesOrNoModalOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(isModalOpen);
  const [custody, setCustody] = useState<ICustodia>({} as ICustodia);
  const [listFieldsToShow] = useState<IAutocompleteField[]>([
    { name: "Situação de custódia", id: "custodiaStatus", type: "autocomplete" },
    { name: "Número de série", id: "numeroSerie", type: "input" },
    { name: "Técnico", id: "tecnico", type: "autocomplete" },
    { name: "Data de recebimento", id: "dataRecebimentoTecnico", type: "date" },
    { name: "Data de atendimento da OS", id: "dataBaixaOSEquipamento", type: "date" },
    { name: "Id da OS", id: "idOrdemServico", type: "input" },
    { name: "Data de envio ao técnico", id: "dataEnvioTecnico", type: "date" },
    { name: "Data de correção", id: "dataCorrecaoEnvio", type: "date" },
    { name: "Enviado por:", id: "responsavelEntregaEquipamentoAoTecnico", type: "autocomplete" },
    { name: "Devolução confirmada por:", id: "responsavelConfirmacaoRecebimentoPA", type: "autocomplete" },
  ]);
  const [listFieldsToShowSelected, setListFieldsToShowSelected] = useState<Record<string, IAutocompleteField[]>>({
    field1: [],
    field2: [],
    field3: [],
    field4: [],
    field5: [],
    field6: [],
    field7: [],
    field8: [],
    field9: [],
    field10: [],
  });
  const [sliceNumber, setSliceNumber] = useState(1);
  const [disableActions, setDisableActions] = useState(false);
  const [selectedPrestadores, setSelectedPrestadores] = useState<any[]>([]);
  const [listaTecnicos, setListaTecnicos] = useState<any[]>([]);
  const [counter, setCounter] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [tipomsgAlert, setTipoMsgAlert] = useState<'success' | 'info' | 'warning' | 'error'>("success");
  const [msgAlert, setMsgAlert] = useState("");
  const [openAlert, setOpenAlert] = useState(false);
  const [motivo, setMotivo] = useState<string>("");

  const initialValues: IInitialValues = useMemo(() => ({
    "custodiaStatus": !!custody.idCustodiaStatus ? [{ name: custody.custodiaStatus, id: custody.idCustodiaStatus.toString() }] : [],
    "numeroSerie": !!custody.numeroSerie ? custody.numeroSerie : "",
    "tecnico": !!custody.idTecnico ? [{ name: custody.tecnico, id: custody.idTecnico.toString() }] : [],
    "dataRecebimentoTecnico": !!custody.dataRecebimentoTecnico && moment(custody.dataRecebimentoTecnico, "YYYY-MM-DDTHH:mm:ss").isValid() ? moment(custody.dataRecebimentoTecnico, "YYYY-MM-DDTHH:mm:ss") : null,
    "dataBaixaOSEquipamento": !!custody.dataBaixaOSEquipamento && moment(custody.dataBaixaOSEquipamento, "YYYY-MM-DDTHH:mm:ss").isValid() ? moment(custody.dataBaixaOSEquipamento, "YYYY-MM-DDTHH:mm:ss") : null,
    "idOrdemServico": !!custody.idOrdemServico ? String(custody.idOrdemServico) : "",
    "dataEnvioTecnico": !!custody.dataEnvioTecnico && moment(custody.dataEnvioTecnico, "YYYY-MM-DDTHH:mm:ss").isValid() ? moment(custody.dataEnvioTecnico, "YYYY-MM-DDTHH:mm:ss") : null,
    "dataCorrecaoEnvio": !!custody.dataCorrecaoEnvio && moment(custody.dataCorrecaoEnvio, "YYYY-MM-DDTHH:mm:ss").isValid() ? moment(custody.dataCorrecaoEnvio, "YYYY-MM-DDTHH:mm:ss") : null,
    "responsavelEntregaEquipamentoAoTecnico": !!custody.responsavelEntregaEquipamentoAoTecnico ? [{ name: custody.responsavelEntregaEquipamentoAoTecnico, id: custody.responsavelEntregaEquipamentoAoTecnico }] : [],
    "responsavelConfirmacaoRecebimentoPA": !!custody.responsavelConfirmacaoRecebimentoPA ? [{ name: custody.responsavelConfirmacaoRecebimentoPA, id: custody.responsavelConfirmacaoRecebimentoPA }] : [],
  }), [
    custody,
  ]);

  const validationSchema = yup.object({
    custodiaStatus: yup.array().max(1).min(1, 'Campo obrigatório'),
    dataRecebimentoTecnico: yup.string().nullable().test("dataInvalida", "Data inválida", (value) => (value !== 'Data inválida' && value !== 'Invalid date')),
    dataBaixaOSEquipamento: yup.string().nullable().test("dataInvalida", "Data inválida", (value) => (value !== 'Data inválida' && value !== 'Invalid date')),
    dataEnvioTecnico: yup.string().nullable().test("dataInvalida", "Data inválida", (value) => (value !== 'Data inválida' && value !== 'Invalid date')),
    dataCorrecaoEnvio: yup.string().nullable().test("dataInvalida", "Data inválida", (value) => (value !== 'Data inválida' && value !== 'Invalid date')),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async () => setYesOrNoModalOpen(true)
  });

  const validateDiffAutocomplete = (value: IAutocompleteValue, oldValue: IAutocompleteValue) => {
    if (_.isEmpty(value) || _.isEmpty(oldValue)) {
      return !_.isEmpty(value) || !_.isEmpty(oldValue);
    }
    return value[0]?.id !== oldValue[0]?.id;
  }

  const validateDiffAutocompleteUser = (value: IAutocompleteValue, oldValue: IAutocompleteValue) => {
    if (_.isEmpty(value) || _.isEmpty(oldValue)) {
      return !_.isEmpty(value) || !_.isEmpty(oldValue);
    }
    return value[0]?.name !== oldValue[0]?.name;
  }

  const validateDiffInput = (newValue: string, oldValue: string) => {
    return newValue !== oldValue;
  }

  const validateDiffDate = (value: moment.Moment | null, oldValue: moment.Moment | null) => {
    if (!value?.isValid() || !oldValue?.isValid()) {
      return value?.isValid() || oldValue?.isValid();
    }
    const newValueFormatted = value.format("YYYY-MM-DDTHH:mm");
    const oldValueFormatted = oldValue.format("YYYY-MM-DDTHH:mm");

    return newValueFormatted !== oldValueFormatted;
  }

  const handleGetDadosCustodia = async () => {
    setLoading(true);
    setDisableActions(true);

    try {
      const { data }: { data: IGetCustodiaResponse } = await getCustodia({ id: custodia?.id || 0 }) as AxiosResponse;

      const newCustody = data?.custodia ?? {};
      setCustody(newCustody as ICustodia);

      setLoading(false);
      setDisableActions(false);

    } catch (error: any) {
      setLoading(false);
      setDisableActions(false);
      setCustody(custodia as ICustodia);
    }
  };

  const changedParamsLabel: any = {
    "idCustodiaStatus": "Situação de custódia",
    "numeroSerie": "Número de série",
    "idTecnico": "Técnico",
    "dataRecebimentoTecnico": "Data de recebimento",
    "dataBaixaOSEquipamento": "Data de atendimento da OS",
    "idOrdemServico": "Id da OS",
    "dataEnvioTecnico": "Data de envio ao técnico",
    "dataCorrecaoEnvio": "Data de correção",
    "responsavelEntregaEquipamentoAoTecnico": "Enviado por:",
    "responsavelConfirmacaoRecebimentoPA": "Devolução confirmada por:",
  };

  const changedParams: IPutCustodia = useMemo(() => ({
    id: custody.id,
    ...(validateDiffAutocomplete(formik.values.custodiaStatus, formik.initialValues.custodiaStatus) ? { idCustodiaStatus: formik.values.custodiaStatus[0]?.id ? Number(formik.values.custodiaStatus[0]?.id) : null } : {}),
    ...(validateDiffInput(formik.values.numeroSerie, formik.initialValues.numeroSerie) ? { numeroSerie: formik.values.numeroSerie ? formik.values.numeroSerie : "" } : {}),
    ...(validateDiffAutocomplete(formik.values.tecnico, formik.initialValues.tecnico) ? { idTecnico: formik.values.tecnico[0]?.id ? Number(formik.values.tecnico[0]?.id) : null } : {}),
    ...(validateDiffDate(formik.values.dataRecebimentoTecnico, formik.initialValues.dataRecebimentoTecnico) ? { dataRecebimentoTecnico: (!!formik.values.dataRecebimentoTecnico && formik.values.dataRecebimentoTecnico.isValid()) ? formik.values.dataRecebimentoTecnico.format("YYYY-MM-DDTHH:mm:ss") : null } : {}),
    ...(validateDiffDate(formik.values.dataBaixaOSEquipamento, formik.initialValues.dataBaixaOSEquipamento) ? { dataBaixaOSEquipamento: (!!formik.values.dataBaixaOSEquipamento && formik.values.dataBaixaOSEquipamento.isValid()) ? formik.values.dataBaixaOSEquipamento.format("YYYY-MM-DDTHH:mm:ss") : null } : {}),
    ...(validateDiffInput(formik.values.idOrdemServico, formik.initialValues.idOrdemServico) ? { idOrdemServico: formik.values.idOrdemServico ? Number(formik.values.idOrdemServico) : null } : {}),
    ...(validateDiffDate(formik.values.dataEnvioTecnico, formik.initialValues.dataEnvioTecnico) ? { dataEnvioTecnico: (!!formik.values.dataEnvioTecnico && formik.values.dataEnvioTecnico.isValid()) ? formik.values.dataEnvioTecnico.format("YYYY-MM-DDTHH:mm:ss") : null } : {}),
    ...(validateDiffDate(formik.values.dataCorrecaoEnvio, formik.initialValues.dataCorrecaoEnvio) ? { dataCorrecaoEnvio: (!!formik.values.dataCorrecaoEnvio && formik.values.dataCorrecaoEnvio.isValid()) ? formik.values.dataCorrecaoEnvio.format("YYYY-MM-DDTHH:mm:ss") : null } : {}),
    ...(validateDiffAutocompleteUser(formik.values.responsavelEntregaEquipamentoAoTecnico, formik.initialValues.responsavelEntregaEquipamentoAoTecnico) ? { responsavelEntregaEquipamentoAoTecnico: formik.values.responsavelEntregaEquipamentoAoTecnico?.[0]?.name ?? "" } : {}),
    ...(validateDiffAutocompleteUser(formik.values.responsavelConfirmacaoRecebimentoPA, formik.initialValues.responsavelConfirmacaoRecebimentoPA) ? { responsavelConfirmacaoRecebimentoPA: formik.values.responsavelConfirmacaoRecebimentoPA?.[0]?.name ?? "" } : {}),
    motivoAlteracao: motivo,
  }), [
    custody,
    formik.values,
    motivo
  ]);

  const handleUpdateDadosCustodia = async () => {
    setLoading(true);
    setDisableActions(true);

    try {
      const { data }: { data: any } = await putCustodias(changedParams) as AxiosResponse;

      setLoading(false);
      setDisableActions(false);
      setOpenAlert(true);
      setMsgAlert(!!data && typeof data === "string" ? data : "Sucesso ao atualizar dados da custódia!");
      setTipoMsgAlert("success");

      setTimeout(() => {
        handleGetDadosCustodia();
      }, 3000);

    } catch (error: any) {
      setLoading(false);
      setDisableActions(false);
      const msg = createErrorMsg(error);
      setOpenAlert(true);
      setMsgAlert(msg ? msg : "Erro ao atualizar dados da custódia!");
      setTipoMsgAlert("error");
    }
  };

  const filterListFieldsToShow = useMemo(() => {
    let selectedFields: string[] = [];
    _.mapKeys(listFieldsToShowSelected, function (value: IAutocompleteField[], key: string) {
      if (value.length) {
        selectedFields.push(value[0].id);
      }
    });

    return listFieldsToShow.filter(field => !selectedFields.includes(field.id));

  }, [listFieldsToShow, listFieldsToShowSelected]);

  const handleChangeProvider = (prestadores: any[]) => {
    setSelectedPrestadores(prestadores);
  };

  const getInitialvalueField = (value: IAutocompleteField) => {
    //@ts-ignore
    const iValue: IAutocompleteValue | string | any = initialValues[value.id];
    switch (value.type) {
      case "autocomplete":
        return iValue[0]?.name ?? "";
      case "input":
        return iValue ?? "";
      case "date":
        return iValue ? iValue.format("DD/MM/YYYY HH:mm") : "";
      default:
        return "";
    }
  };

  const getOptionsAutocompleteField = (value: IAutocompleteField) => {
    switch (value.id) {
      case "tecnico":
        return listaTecnicos;
      case "custodiaStatus":
        return listaStatus;
      case "responsavelEntregaEquipamentoAoTecnico":
      case "responsavelConfirmacaoRecebimentoPA":
        return listaUsuarios;
      default:
        return [];
    }
  };

  const requestListaTecnicosByLotePrestador = async () => {
    let listAllPrestadores: number[] = [];

    if (!!selectedPrestadores.length) {
      listAllPrestadores = selectedPrestadores.map(p => parseInt(p.id));
    }

    const tecnicos = await requestTecnicosByLotePrestador(listAllPrestadores) as ITecnicos[];
    setListaTecnicos(tecnicos.map(p => ({ name: p?.nome ?? "", id: p?.idTecnico?.toString() ?? "" })));
  };

  const requestTecnicosByLotePrestador = async (ids: number[]) => {
    try {
      if (!!ids.length) {
        let retorno = await getListaTecnicosByLotePrestador(ids) as AxiosResponse;
        return retorno?.data ?? [];
      } else {
        return [];
      }
    } catch (e) {
      return [];
    }
  };

  useEffect(() => {
    setIsOpen(isModalOpen);
    handleGetDadosCustodia();
  }, [isModalOpen]);

  useEffect(() => {
    if (counter === 0) {
      if (custodia?.idPrestador) {
        setSelectedPrestadores([{ name: custodia.prestador, id: custodia.idPrestador.toString() }]);
      }
      setCounter(counter + 1);
    } else if (counter > 1) {
      requestListaTecnicosByLotePrestador();
      formik.setFieldValue("tecnico", []);
    } else {
      requestListaTecnicosByLotePrestador();
      setCounter(counter + 1);
    }
  }, [selectedPrestadores]);

  return (
    <Modal
      handleCloseModal={handleCloseModal}
      title='AJUSTE DE REGISTRO DE CUSTÓDIA'
      isOpen={isOpen}
      sx={{ '.MuiDialog-paper': { maxWidth: 'unset' } }}
    >
      <Container>
        <form className="Container" onSubmit={formik.handleSubmit}>
          {!!yesOrNoModalOpen && (
            <YesOrNoModal
              title={
                <div style={{ fontWeight: 400 }}>
                  Deseja concluir a edição da custódia?<br />
                  <br />
                  Campos editados:<br />
                  {Object.keys(changedParams).map(key => {
                    const name = changedParamsLabel[key] ? changedParamsLabel[key] : "";
                    if (!name) return <React.Fragment key={key}/>;

                    return (
                      <React.Fragment key={key}>
                        <strong>{name}</strong><br />
                      </React.Fragment>
                    );
                  })}
                </div>
              }
              isModalOpen={yesOrNoModalOpen}
              handleClose={() => setYesOrNoModalOpen(false)}
              handleClickYes={() => {
                setYesOrNoModalOpen(false);
                handleUpdateDadosCustodia();
              }}
            />
          )}

          <GridLabelTop>As alterações que serão realizadas não serão refletidas no estoque ou na ordem de serviço. Favor certificar-se de realizar os ajustes nos demais ambientes, se necessário.</GridLabelTop>

          <GridLabel>SELECIONE O CAMPO A SER EDITADO</GridLabel>

          <AlertMessage
            isOpenAlert={openAlert}
            setOpenAlert={setOpenAlert}
            alertType={tipomsgAlert}
            msgAlert={msgAlert}
          />

          <GridScroll>
            {listFieldsToShow.slice(0, sliceNumber).map((field, i) => {
              const iD: number = i + 1;
              const fieldName: string = `field${iD}`;
              const currentValue = listFieldsToShowSelected[fieldName] ?? [];
              const selectedValue = currentValue[0];
              //@ts-ignore
              const formikValue = selectedValue ? formik.values[selectedValue.id] : null;
              //@ts-ignore
              const formikErrors = selectedValue ? formik.errors[selectedValue.id] : null;
              //@ts-ignore
              const formikTouched = selectedValue ? formik.touched[selectedValue.id] : null;
              return (
                <Grid className='three' key={field.id}>
                  <AutocompleteMultiple
                    label={`${iD}`}
                    placeholder={""}
                    noOptionsText={"Nenhum campo de custódia encontrado"}
                    id={`multiple-checkbox-custódia${iD}`}
                    options={filterListFieldsToShow}
                    value={currentValue}
                    onChange={(value: any) => {
                      setListFieldsToShowSelected({ ...listFieldsToShowSelected, [fieldName]: value });
                      if (!!selectedValue && (_.isEmpty(value) || value[0]?.id !== selectedValue.id)) {
                        /**Reseta o valor 'Para:' se o seletor de campos for limpo ou alterado*/
                        //@ts-ignore
                        formik.setFieldValue(selectedValue.id, initialValues[selectedValue.id]);
                      }
                    }}
                    multiple={false}
                    dimension='sm'
                    keepOptionInTheList
                    disabled={disableActions}
                  />

                  <div />

                  <Input
                    dimension='sm'
                    label='De:'
                    value={!!selectedValue ? getInitialvalueField(selectedValue) : ""}
                    readOnly
                  />

                  {!selectedValue &&
                    <Input
                      dimension='sm'
                      label='Para:'
                      value={""}
                      readOnly
                    />
                  }

                  {!!selectedValue && selectedValue.type === "autocomplete" &&
                    <>
                      {/* {selectedValue.id === "tecnico" && <>
                        <AutocompleteMultiple
                          label={"Selecione um prestador"}
                          placeholder={""}
                          noOptionsText={"Nenhum prestador encontrado"}
                          id="multiple-checkbox-Prestadores-Estoque"
                          options={listaPrestadores}
                          value={selectedPrestadores}
                          onChange={handleChangeProvider}
                          disabled={(!!user?.prestadores && user.prestadores.length === 1)}
                          multiple={true}
                          dimension='sm'
                          limitTags={2}
                          showCheckbox
                          allSelected
                        />
                        <div />
                        <div />
                        <div />
                      </>
                      } */}

                      <AutocompleteMultiple
                        dimension='sm'
                        label='Para:'
                        placeholder={""}
                        noOptionsText={"Nenhum dado encontrado"}
                        id={`autocomplete_${iD}`}
                        options={getOptionsAutocompleteField(selectedValue)}
                        value={formikValue}
                        onChange={(e: any) => formik.setFieldValue(selectedValue.id, e)}
                        multiple={false}
                        error={formikTouched && Boolean(formikErrors)}
                        helperText={formikTouched && formikErrors}
                        disabled={disableActions}
                      />
                    </>
                  }

                  {!!selectedValue && selectedValue.type === "input" &&
                    <Input
                      type={selectedValue.id === "idOrdemServico" ? "number" : "text"}
                      dimension='sm'
                      label='Para:'
                      id={`input_${iD}`}
                      value={formikValue}
                      onChange={(e: any) => formik.setFieldValue(selectedValue.id, e.target.value)}
                      error={formikTouched && Boolean(formikErrors)}
                      helperText={formikTouched && formikErrors}
                      disabled={disableActions}
                    />
                  }

                  {!!selectedValue && selectedValue.type === "date" &&
                    <DateTimePicker
                      dimension='sm'
                      label='Para:'
                      value={formikValue}
                      onChange={(e: any) => formik.setFieldValue(selectedValue.id, e)}
                      error={formikTouched && Boolean(formikErrors)}
                      helperText={formikTouched && formikErrors}
                      disabled={disableActions}
                    />
                  }
                </Grid>
              );
            })}
          </GridScroll>

          {(sliceNumber < listFieldsToShow.length) &&
            <AddIconStyled onClick={() => setSliceNumber(sliceNumber + 1)} />
          }

          <Grid className='one'>
            <TextArea
              dimension='sm'
              name='motivo'
              label='Motivo:'
              placeholder=''
              maxLength={255}
              value={motivo}
              onChange={(e: any) => setMotivo(e.target.value)}
              error={!!motivo && motivo.length < 20}
              helperText={!!motivo && motivo.length < 20 ? 'Digite ao menos 20 caracteres' : false}
              disabled={disableActions}
            />
          </Grid>

          <ButtonContainer>
            <Button
              type='submit'
              variant='primary'
              disabled={disableActions || !formik.dirty || !motivo || motivo.length < 20}
            >
              FINALIZAR EDIÇÃO
            </Button>
          </ButtonContainer>
        </form>
      </Container>
    </Modal>
  );
};

export default EditarCustodiaModal;
