import { createTheme } from '@mui/material/styles';
import { siteTheme } from './themeVariables';

const {color} = siteTheme;

const theme = createTheme({
  palette: {
    primary: {
      main: color.primary,
      light: color.primaryLight,
      dark: color.primaryDark,
      contrastText: color.secondary,
    },
    secondary: {
      main: color.secondary,
      contrastText: color.primary,
      light: color.cinzaClaro
    },
    error: {
      main: '#FF2323',
      contrastText: '#fff',
    },
    info: {
      main: '#797d8c',
      contrastText: '#000',
    },
    success: {
      main: '#97A800',
      contrastText: '#fff',
    },
    text: {
      primary: '#797D8C',
      secondary: '#1A1A24',
      disabled: 'rgba(0, 0, 0, 0.38)',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontSize: '0.875rem',
          boxShadow: 'none',
        },
        sizeMedium: {
          padding: '14px 32px',
        },
        sizeSmall: {
          fontSize: '1rem',
          padding: '6px 26px',
          borderRadius: '8px'
        },
        // outlined: {},
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          background: ' #ffffff',
          boxShadow: '0px 1px 3px 0px #0000001f',
        },
        rounded: {
          borderRadius: '8px',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontWeightMedium: 400,
          fontStyle: 'normal',
        },
        h1: {
          fontFamily: 'Noto Sans',
          fontSize: '2rem',
          fontWeight: '700',
          color: '#797d8c',
          letterSpacing: '0.9px'
        },
        h3: {
          fontFamily: 'Open Sans',
          fontSize: '1rem',
          fontWeight: '400',
          color: '#797d8c',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: 'rgba(26, 26, 36, 0.78)',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          color: 'rgba(26, 26, 36, 0.98)',
          borderColor: '{$neutral}',
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        colorPrimary: {
          color: color.cinzaClaro,
          "&.Mui-checked": {
            color: color.primary,
          },
        },
        colorSecondary: {
          color: color.cinzaClaro,
          "&.Mui-checked": {
            color: color.cinzaClaro,
          },
        },
      },
    },
  },
});

export default theme;
