import React, { SyntheticEvent, useContext, useEffect, useLayoutEffect, useRef, useState } from 'react'
import Header from '../../../components/header/header'
import { Breadcrumb, BreadcrumbItem } from '../../../components/breadcrumb/breadcrumb'
import { HomeContext } from '../../../contexts/homeContext'
import { AuthContext } from '../../../contexts/auth'
import MultipleSelectCheckmarksId from '../../../components/seletorMultiploId/seletorMultiploId'
import MultipleSelectCheckmarks from '../../../components/seletorMultiplo/seletorMultiplo'
import Input from '../../../components/Input/Input'
import Button from '../../../components/Button/styles'
import Alert from "@mui/material/Alert"
import IconButton from "@mui/material/IconButton"
import Collapse from '../../../components/collapse/collapse';
import CloseIcon from "@mui/icons-material/Close"
import "./consultaPrestador.scss"
import { AxiosResponse } from 'axios'
import { IPrestador } from '../../../contexts/types/homeTypes'
import { getBuscarPrestadorFiltrado, getBuscarPrestadoresPeloUsuario } from '../services/services'
import ConsultaPrestadorTabela from './consultaPrestadorTabela'
import _ from 'lodash'
import { IConsultaPrestador, IConsultaPrestadorTabela } from '../types'
import Pagination from '../../../components/pagination/paginacao'
import FullscreenLoading from '../../../components/fullscreenLoading/fullscreenLoading'
import { getPrestadores } from '../../enviarTecnico/services/services'
import { useNavigate } from 'react-router-dom'
import { tipoPrestadorLista } from '../../../contexts/helpers/dadosPadrao'
import { NewButton } from './consultaPrestador_styles'

const ConsultaPrestador = () => {

  const { setMenuLateral } = useContext(HomeContext);

  const { user, funcionalidadeDaTelaTemPermissao } = useContext(AuthContext);
  const profileHasPermission = (funcionalidade: string) => funcionalidadeDaTelaTemPermissao("Consultar Prestador", funcionalidade);

  const navigate = useNavigate()

  const [status, setStatus] = useState<string[]>([])
  const [prestador, setPrestador] = useState<string[]>([])
  const [prestadorList, setPrestadorList] = useState<{name: string, id: string}[]>([])
  const [prestadorTipo, setPrestadorTipo] = useState<string[]>([])
  const [prestadorPai, setPrestadorPai] = useState<string[]>([])
  const [codFornecedor, setCodFornecedor] = useState('')
  const [codCT, setCodCT] = useState('')
  const [ tableData, setTableData ] = useState<IConsultaPrestador>({} as IConsultaPrestador)

  const [ loading, setLoading ] = useState(false)

  const [tipomsgAlert, setTipoMsgAlert] = useState<'success' | 'info' | 'warning' | 'error'>("success");
  const [msgAlert, setMsgAlert] = useState("OS encaminhada com  sucesso!");
  const [openAlert, setOpenAlert] = useState(false);

  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)

  const statusLista = [
    {
      name: '--',
      id: ''
    }, 
    {
      name: 'Ativo',
      id: '1'
    }, 
    {
      name: 'Inativo',
      id: '0'
    }
  ]

  const handleSearch = async (page?: number, size?: number) => {
    setLoading(true)
    setOpenAlert(false)
    try{
      const { data } = await getBuscarPrestadorFiltrado({
        nomePrestador: prestadorList.filter(item => item.id.toString() === prestador[0])[0]?.name,
        tipo: prestadorTipo[0]?.toUpperCase(),
        codigoCt: codCT,
        codigoFornecedor: codFornecedor,
        ativo: !!status[0] ? Boolean(Number(status[0])) : null,
        pageNumber: page || 1,
        pageSize: size || 10
      }) as AxiosResponse
  
      setTableData({
        totalCount: data?.totalCount,
        table: data?.items as IConsultaPrestadorTabela[]
      })
    } catch (e){
      setTableData({} as IConsultaPrestador)
      setMsgAlert("Nenhum prestador encontrado")
      setTipoMsgAlert('error')
      setOpenAlert(true)
    }
    setLoading(false)
  }

  const getAllProviders = async () => {
    if(_.isEmpty(user?.data_user.prestadores)){
      try{

        const { data } = await getPrestadores() as AxiosResponse

        setPrestadorList(data.map((item: IPrestador) => {
          return {name: item.nomePrestador, id: item.id.toString()}
        }).sort((a: {name: string, id: string},b: {name: string, id: string}) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)))
      
      } catch(e){
        setPrestadorList([])
      }
      
    } else {
      try {
        const { data } = await getBuscarPrestadoresPeloUsuario(user?.idUsuario!) as AxiosResponse

        setPrestadorList(data.map((item: IPrestador) => {
          return {name: item.nomePrestador, id: item.id.toString()}
        }).sort((a: {name: string, id: string},b: {name: string, id: string}) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)))
      
      } catch(e){
        setPrestadorList([])
      }
    }
    
  }

  const beforeSubmit = () => {
    if(currentPage !== 1) setCurrentPage(1)
    else handleSearch()
  }

  useEffect(() => {
    getAllProviders()
  }, [])

  const firstUpdate = useRef(true);
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    handleSearch(currentPage, pageSize)
  }, [currentPage, pageSize])

  return(
    <section className="ConsultaPrestador">
      <Header setMenuLateral={() => setMenuLateral(true)} title="Consultar Prestador" />
      <Breadcrumb>
        <BreadcrumbItem>Prestador</BreadcrumbItem>
      </Breadcrumb>

      {loading && <FullscreenLoading />}
      <div className="Container">
        <span className='FiltroSpan'>Filtros</span>
        <div className="ConsultarPrestadorPesquisar">
          <MultipleSelectCheckmarksId
          nodata={"Nenhum status encontrado"}
          options={statusLista} 
          value={status}
          onChange={(e: string[]) => setStatus(e)}
          id="multiple-checkbox-status"
          idOption="multiple-checkbox-option-status-"
          labelId="multiple-checkbox-label-status"
          multiple={false}
          className="ConsultarPrestadorSelectStatus"
          dimension='sm'
          label="Status"
          selectAll
          />
          <MultipleSelectCheckmarks
          options={tipoPrestadorLista}
          value={prestadorTipo}
          onChange={(e: string[]) => setPrestadorTipo(e)}
          id="multiple-checkbox-status"
          idOption="multiple-checkbox-option-Tipo Prestador-"
          labelId="multiple-checkbox-label-Tipo Prestador"
          multiple={false}
          dimension='sm'
          label='Tipo Prestador'
          />
          <MultipleSelectCheckmarksId
          nodata={"Nenhum Prestador Pai encontrado"}
          options={prestadorList}
          value={prestador}
          onChange={(e: string[]) => setPrestador(e)}
          id="multiple-checkbox-status"
          idOption="multiple-checkbox-option-Prestador"
          labelId="multiple-checkbox-label-Prestador"
          multiple={false}
          className="ConsultarPrestadorSelectPrestador"
          dimension='sm'
          label='Nome Prestador'
          />
        </div>
        <div className="ConsultarPrestadorPesquisar">
          <MultipleSelectCheckmarksId
          nodata={"Nenhum Prestador Pai encontrado"}
          options={[]}
          value={prestadorPai}
          onChange={(e: string[]) => setPrestadorPai(e)}
          id="multiple-checkbox-status"
          idOption="multiple-checkbox-option-Prestador-Pai-"
          labelId="multiple-checkbox-label-Prestador-Pai"
          multiple={false}
          className="ConsultarPrestadorSelectPrestadorPai"
          readOnly
          dimension='sm'
          label='Prestador Pai'
          />
          <Input
          value={codFornecedor}
          onChange={(e) => setCodFornecedor(e.target.value)}
          dimension='sm'
          label='Código Fornecedor'
          />
          <Input
          label="Código CT" 
          value={codCT}
          onChange={(e) => setCodCT(e.target.value)}
          dimension='sm'
          />
          <Button dimension='sm' variant='primary' margin='0' onClick={() => beforeSubmit()}>Pesquisar</Button>
          {!!profileHasPermission("Novo Registro") ?
            <NewButton variant="contained" size="small" onClick={() => navigate('/IncluiPrestador')} >Novo</NewButton>
            :
            <NewButton
              className={"bloqueado"}
              style={{ cursor: 'not-allowed', opacity: 0.5 }}
              variant="contained"
              size="small"
            >
              Novo
            </NewButton>
          }
        </div>
        <Collapse onClose={setOpenAlert} in={openAlert}>
          <Alert
            severity={tipomsgAlert}
            icon={<div />}
            variant="filled"
            sx={{
              mb: 2,
              bgcolor: tipomsgAlert === "success" ? "#178B319E" : "rgb(255, 35, 35, 0.7)",
              height: "67px",
              borderRadius: "15px",
              boxSizing: "border-box",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginTop: "20px",
              marginBottom: 0,
            }}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="medium"
                onClick={() => setOpenAlert(false)}
              >
                <CloseIcon
                  id={"ModalEncaminharOS-AlertCloseIcon"}
                  fontSize="inherit"
                  sx={{
                    color: "white",
                  }}
                />
              </IconButton>
            }
          >
            <span
              style={{
                color: "white",
                fontSize: "16px",
              }}
              dangerouslySetInnerHTML={{__html: msgAlert}}
            />
          </Alert>
        </Collapse>
        {!_.isEmpty(tableData) && 
          <>
            <ConsultaPrestadorTabela tableData={tableData?.table} allowEdit={!!profileHasPermission("Editar Registro")}/>
            <Pagination
              className="pagination-bar"
              currentPage={currentPage}
              totalCount={tableData?.totalCount}
              pageSize={pageSize}
              setPageSize={(value: string) => setPageSize(Number(value))}
              onPageChange={(page: number) => setCurrentPage(Number(page))}
              showLabel={false}
            />
          </>
        }
      </div>
    </section>
  )
}

export default ConsultaPrestador