import { useContext } from "react";
import "./checklistPopoverOrdenar.scss";
import Popover from "@mui/material/Popover";
import Button from "@mui/material/Button";
import { HomeContext } from "../../../contexts/homeContext";
import { IChecklistPopoverOrdenar } from "../types";
import { Form, Formik } from "formik";
import { checklistOrdenacaoPorLista } from "../../../contexts/helpers/dadosPadrao";
import AutocompleteMultiple from "../../../components/autocompleteMultiple/autocompleteMultiple";
import { IAutocompleteValue } from "../../../components/autocompleteMultiple/types";

interface IInitialValues {
  checklistTipoOrdenacaoFormik: IAutocompleteValue;
}

const ChecklistPopoverOrdenar = ({
  id = "Checklist-PopoverOrdenar",
  open = false,
  onClose,
  anchorEl = null,
  anchorOrigin = {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin = {
    vertical: "top",
    horizontal: "center",
  },
}: IChecklistPopoverOrdenar) => {
  const {
    setChecklistOrdenacaoPor,
    setChecklistOrdem,
  } = useContext(HomeContext);

  const initialValues: IInitialValues = {
    checklistTipoOrdenacaoFormik: []
  }

  return (
    <Popover
      id={id}
      open={open}
      onClose={onClose}
      anchorEl={anchorEl}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      classes={{ paper: "Checklist-PopoverOrdenar-box" }}
    >
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={(values) => {
          setChecklistOrdem('asc');
          setChecklistOrdenacaoPor(values.checklistTipoOrdenacaoFormik[0]);
          onClose();
        }}
      >
        {({ values, setFieldValue }) => (
          <Form>

            <div className="Checklist-PopoverOrdenar">
              <AutocompleteMultiple
                dimension="sm"
                label={"Ordenar por"}
                placeholder={""}
                noOptionsText="Nenhum status encontrado"
                options={checklistOrdenacaoPorLista}
                value={values.checklistTipoOrdenacaoFormik}
                onChange={(e: any) => setFieldValue('checklistTipoOrdenacaoFormik', e)}
                id="multiple-checkbox-Status"
                multiple={false}
                disabled={false}
                disableCloseOnSelect={false}
              />

              <Button
                id="Checklist-PopoverOrdenar-BotaoOrdenar"
                variant="contained"
                classes={{
                  root: "Checklist-PopoverOrdenar-BotaoOrdenar",
                }}
                type="submit"
              >
                Ordenar
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Popover>
  );
};

export default ChecklistPopoverOrdenar;
