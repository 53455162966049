import React, { useContext } from 'react'
import { Table, TableRow } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import * as Styles from "../../../components/table/styles";
import {ReactComponent as EditIcon} from "../../../assets/icons/EditIcon.svg";
import { IConsultaModeloTabela } from '../types'
import moment from 'moment';
import { HomeContext } from '../../../contexts/homeContext';
import { useNavigate } from 'react-router-dom';

interface IConsultarPerfilTable {
  tableData: IConsultaModeloTabela[]
}

const ConsultaModeloTabela = ({tableData}: IConsultarPerfilTable) => {

  const { setModeloSelected } = useContext(HomeContext)

  const navigate = useNavigate()

  const header = [
    'Status',
    'Nome do Modelo',
    'Família',
    'Tipo de Equipamento',
    'Data de Criação',
    'Criado Por',
    'Data de Modificação',
    'Modificado Por',
    // 'Editar'
  ]

  const handleEditModelo = (equipamento: IConsultaModeloTabela) => {
    setModeloSelected(equipamento)
    navigate('/EditarModelo')
  }

  return(
    <TableContainer component={Styles.StyledPaper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            {header?.map((title) => 
              <Styles.StyledTableCell className={`${title} center`} key={title}>{title}</Styles.StyledTableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {tableData?.map(item => (
            <TableRow key={item?.id}>
              <Styles.StyledTableCell component="th" scope="row">
                {item?.ativo ? 'Ativo' : 'Inativo'}
              </Styles.StyledTableCell>
              <Styles.StyledTableCell component="th" scope="row">
                {item?.descricao}
              </Styles.StyledTableCell>
              <Styles.StyledTableCell component="th" scope="row">
                {item?.familia}
              </Styles.StyledTableCell>
              <Styles.StyledTableCell component="th" scope="row">
                {item?.tipo}
              </Styles.StyledTableCell>
              <Styles.StyledTableCell component="th" scope="row">
                {item?.dataCriacao && moment(item?.dataCriacao)?.format('DD/MM/YYYY - HH:mm:ss')} 
              </Styles.StyledTableCell>
              <Styles.StyledTableCell component="th" scope="row">
                {item?.usuarioCriacao}
              </Styles.StyledTableCell>
              <Styles.StyledTableCell component="th" scope="row">
                {item?.dataModificacao && moment(item?.dataModificacao)?.format('DD/MM/YYYY - HH:mm:ss')} 
              </Styles.StyledTableCell>
              <Styles.StyledTableCell component="th" scope="row">
                {item?.usuarioModificacao}
              </Styles.StyledTableCell>
              {/* <Styles.StyledTableCell className='center' component="th" scope="row">
                <EditIcon onClick={() => handleEditModelo(item)} style={{cursor: 'pointer'}}/>
              </Styles.StyledTableCell> */}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default ConsultaModeloTabela