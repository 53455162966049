import React from "react";
import { Alert, IconButton } from "@mui/material";
import Collapse from "../collapse/collapse";
import CloseIcon from "@mui/icons-material/Close";

interface IAlertMessage {
    isOpenAlert: boolean,
    setOpenAlert: Function,
    msgAlert?: string,
    alertType?: 'success' | 'info' | 'warning' | 'error',
};

const alertColor = {
    'success': "#178B319E",
    'info': "#797d8c",
    'warning': "#FFE600",
    'error': "rgb(255, 35, 35, 0.7)",
};

const AlertMessage = ({
    isOpenAlert,
    setOpenAlert,
    msgAlert = "Sucesso!",
    alertType = 'success'
}: IAlertMessage) => {
    return (
        <Collapse onClose={setOpenAlert} in={isOpenAlert}>
            <Alert
                severity={alertType}
                icon={<div />}
                variant="filled"
                sx={{
                    mb: 2,
                    bgcolor: alertColor[alertType],
                    height: "67px",
                    borderRadius: "15px",
                    boxSizing: "border-box",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginTop: "20px",
                    marginBottom: 0,
                }}
                action={
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        size="medium"
                        onClick={() => setOpenAlert(false)}
                    >
                        <CloseIcon
                            id={"ModalEncaminharOS-AlertCloseIcon"}
                            fontSize="inherit"
                            sx={{
                                color: "white",
                            }}
                        />
                    </IconButton>
                }
            >
                <span
                    style={{
                        color: "white",
                        fontSize: "16px",
                    }}
                    dangerouslySetInnerHTML={{ __html: msgAlert }}
                />
            </Alert>
        </Collapse>
    );

};

export default AlertMessage;
