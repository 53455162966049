import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import _ from 'lodash';
import moment from 'moment';

const headCells = [
  {
    id: 'ID',
    cmpID: 'id',
    numeric: true,
    disablePadding: false,
    label: 'ID',
  },
  {
    id: 'EditadoPor',
    cmpID: 'nomeUsuario',
    numeric: true,
    disablePadding: false,
    label: 'Editado por',
  },
  {
    id: 'DataDeEdicao',
    cmpID: 'dataAlteracao',
    numeric: true,
    disablePadding: false,
    label: 'Data de edição',
  },
];

interface EnhancedTableProps {
  handleClickOrdenar: (sort: string) => void;
  activeArrowUP: (sort: string) => boolean;
  activeArrowDown: (sort: string) => boolean;
  ordenacao: string;
};

function EnhancedTableHead(props: EnhancedTableProps) {
  const { activeArrowUP, activeArrowDown, ordenacao, handleClickOrdenar } =
    props;
  const createSortHandler = (property: string) => (event: React.MouseEvent<unknown>) => {
    handleClickOrdenar(property);
  };

  return (
    <TableHead id={"customTableHead"}>
      <TableRow sx={{ minHeight: "70px", height: "70px", whiteSpace: "nowrap" }}>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={'center'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={activeArrowUP(headCell.id) ? 'asc' : activeArrowDown(headCell.id) ? 'desc' : false}
            sx={{ backgroundColor: '#AFB1B1', color: '#FFF' }}
          >
            <TableSortLabel
              id={"customTableHeadSort:" + (headCell.cmpID)}
              active={ordenacao === headCell.id}
              direction={activeArrowUP(headCell.id) ? 'asc' : 'desc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default function AuditCustodiaTable({
  id = "customTable",
  handleClickOrdenar,
  activeArrowUP,
  activeArrowDown,
  ordenacao,
  dataList,
  handleClickAudit,
  historicoSelecionado,
}: {
  id?: string,
  handleClickOrdenar: (sort: string) => void,
  activeArrowUP: (sort: string) => boolean,
  activeArrowDown: (sort: string) => boolean,
  ordenacao: string,
  dataList: any[],
  handleClickAudit: (custodia: any) => void,
  historicoSelecionado: any,
}) {
  const [dense] = React.useState(true);
 
  return (
    <Paper sx={{ width: '100%', overflow: 'hidden', marginTop: '20px' }}>
      <TableContainer sx={{ maxHeight: '400px' }}>
        <Table
          id={id}
          stickyHeader
          aria-label="sticky table"
          sx={{ minWidth: 750 }}
          aria-labelledby="tableTitle"
          size={dense ? 'small' : 'medium'}
        >
          <EnhancedTableHead
            handleClickOrdenar={handleClickOrdenar}
            activeArrowUP={activeArrowUP}
            activeArrowDown={activeArrowDown}
            ordenacao={ordenacao}
          />
          <TableBody id={"customTableBody"}>
            {dataList.map((row, index) => {
              const isSelected = row.id === historicoSelecionado.id;
              return (
                <TableRow
                  id={"customTableBodyRowOS:" + (row.numeroSerie) + (index)}
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={index}
                  sx={{ cursor: "pointer", backgroundColor: (isSelected ? 'rgba(0, 0, 0, 0.06)' : '#FFF'), minHeight: "42px", height: "42px", whiteSpace: "nowrap" }}
                  onClick={() => handleClickAudit(row)}
                >
                  <TableCell align="center">{row.id}</TableCell>
                  <TableCell align="center">{row.nomeUsuario ? row.nomeUsuario : 'N/A'}</TableCell>
                  <TableCell align="center">{row.dataAlteracao === 'N/A' ? row.dataAlteracao : moment(row.dataAlteracao, "YYYY-MM-DDTHH:mm:ss").format("DD/MM/YYYY HH:mm")}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};
