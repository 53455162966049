import React, { useContext } from "react";
import { Link as RouterLink } from 'react-router-dom';
import { ButtonMenu } from './styles'
import { HomeContext } from "../../contexts/homeContext";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const MenuLateralExpandOption = ({
  item,
  isActiveMenu
}: {
  item: any
  isActiveMenu: boolean
}) => {
  const { menuLateral, setMenuLateral } = useContext(HomeContext);

  return (
    <Accordion
      id={item.title}
      sx={{
        background: 'transparent',
        boxShadow: 'none',
        '&.Mui-expanded': {
          marginTop: '16px', //marginTop: '0px!important',
          '&:first-of-type': {
            marginTop: '16px'
          },
        },
        '& .MuiAccordionSummary-root': {
          padding: 0,
          minHeight: '40px',
          background: isActiveMenu ? '#fff' : 'inherit',
          borderRadius: '10px',
          '&:hover': {
            backgroundColor: isActiveMenu ? '#fff' : '#1e2e25',
            boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)'
          },
          '&.Mui-expanded': {
            minHeight: '40px'
          },
          '& .MuiButtonBase-root': {
            backgroundColor: 'inherit',
            boxShadow: 'none',
          },
          '& .MuiAccordionSummary-expandIconWrapper': {
            padding: '5px'
          },
          '& .MuiSvgIcon-root path': {
            fill: isActiveMenu ? '#1e2e25' : '#fff',
          },
        },
        '& .MuiAccordionSummary-content': {
          margin: 0,
          '&.Mui-expanded': {
            margin: 0,
          },
        },
        '& .MuiAccordionDetails-root': {
          padding: '0px 0px 0px 10px',
        }
      }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <ButtonMenu
          disableRipple={true}
          key={item.title}
          variant="contained"
          startIcon={item.icon}
          className={`${isActiveMenu ? "isActive" : ''}`}
        >
          {item.title}
        </ButtonMenu>
      </AccordionSummary>
      <AccordionDetails>
        {item.subMenu.map((subItem: any) => {
          if (!!subItem) {
            return (
              <RouterLink id={item.link} key={subItem.link} to={subItem.link} >
                <ButtonMenu
                  sx={{
                    marginTop: '8px',
                    textAlign: 'initial',
                    fontSize: '12px !important',
                    lineHeight: 1.2,
                    padding: '10px 5px 10px 30px',
                    "& .MuiButton-startIcon": {
                      marginRight: '20px',
                    }
                  }}
                  variant="contained"
                  size="small"
                  startIcon={subItem.icon}
                  className={`${window.location.pathname === subItem.link ? "isActive" : ''}`}
                  onClick={() => setMenuLateral(false)}
                >
                  {subItem.title}
                </ButtonMenu>
              </RouterLink>
            );
          }
        })}
      </AccordionDetails>
    </Accordion>
  );
};

export default MenuLateralExpandOption;
