import React from 'react'
import { ReactComponent as OSVencidaIconComponent } from "../assets/icons/OSVencidaIscon.svg";
import { ReactComponent as OSVenceHojeIconComponent } from "../assets/icons/OSVenceHojeIscon.svg";
import { ReactComponent as OSAVencerIconComponent } from "../assets/icons/OSAVencerIscon.svg";
import { ReactComponent as OSEmergencialIconComponent } from "../assets/icons/OSEmergencialIcon.svg"
import { FormatDateToApi } from './formatDate';
import OSVencidaIcon from "../assets/icons/OSVencidaIscon.svg";
import OSVenceHojeIcon from "../assets/icons/OSVenceHojeIscon.svg";
import OSAVencerIcon from "../assets/icons/OSAVencerIscon.svg";
import OSEmergencialIcon from "../assets/icons/OSEmergencialIcon.svg"
import { ReactComponent as AtendimentoDentroDoPrazoCmp } from "../assets/icons/AtendimentoDentroDoPrazo.svg";
import { ReactComponent as AtendimentoForaDoPrazoCmp } from "../assets/icons/AtendimentoForaDoPrazo.svg";
import { ReactComponent as OSCanceladaCmp } from "../assets/icons/OSCancelada.svg";
import AtendimentoDentroDoPrazoIcon from "../assets/icons/AtendimentoDentroDoPrazo.svg";
import AtendimentoForaDoPrazoIcon from "../assets/icons/AtendimentoForaDoPrazo.svg";
import OSCanceladaIcon from "../assets/icons/OSCancelada.svg";
import { LightTooltip } from '../components/tooltip/tooltip';

export const renderStatusOS = (statusFarol: string) => {
  if (statusFarol === "BaixadaDentroDoPrazo")
    return {
      label: "Atendimento dentro do prazo",
      icon: <LightTooltip title="Atendimento dentro do prazo" arrow><AtendimentoDentroDoPrazoCmp /></LightTooltip>,
      iconSrc: AtendimentoDentroDoPrazoIcon
    };
  if (statusFarol === "BaixadaForaDoPrazo")
    return {
      label: "Atendimento fora do prazo",
      icon: <LightTooltip title="Atendimento fora do prazo" arrow><AtendimentoForaDoPrazoCmp /></LightTooltip>,
      iconSrc: AtendimentoForaDoPrazoIcon
    };
  if (statusFarol === "Cancelada")
    return {
      label: "OS cancelada",
      icon: <LightTooltip title="OS cancelada" arrow><OSCanceladaCmp /></LightTooltip>,
      iconSrc: OSCanceladaIcon
    };
  if (statusFarol === "OSEmergencial")
    return {
      label: 'Emergêncial',
      icon: <OSEmergencialIconComponent className="FarolOS-CardOS-StatusIcon OSEmergencial" />,
      iconSrc: OSEmergencialIcon
    };
  if (statusFarol === "OSVencida")
    return {
      label: 'Vencida',
      icon: <OSVencidaIconComponent className="FarolOS-CardOS-StatusIcon" />,
      iconSrc: OSVencidaIcon
    };
  if (statusFarol === "OSVenceHoje")
    return {
      label: 'Vence hoje',
      icon: <OSVenceHojeIconComponent className="FarolOS-CardOS-StatusIcon" />,
      iconSrc: OSVenceHojeIcon
    };
  if (statusFarol === "OSAVencer")
    return {
      label: 'À vencer',
      icon: <OSAVencerIconComponent className="FarolOS-CardOS-StatusIcon" />,
      iconSrc: OSAVencerIcon
    };
};