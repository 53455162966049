import React, { useContext, useState, useEffect } from 'react'
import Header from '../../../components/header/header'
import { HomeContext } from '../../../contexts/homeContext';
import { ResponsiveContainer, BarChart, XAxis, YAxis, Bar, LabelList } from 'recharts';
import { siteTheme } from "../../../styles/themeVariables";
import { AxiosResponse } from 'axios';
import { getAderencia, getContagemOS } from '../services/services';
import DatePicker from '../../../components/datePicker/datePicker';
import _ from 'lodash'
import moment, { Moment } from 'moment'
import Button from '../../../components/Button/styles';
import { Table, TableRow } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import * as Styles from "../../../components/table/styles";
import AutocompleteMultiple from '../../../components/autocompleteMultiple/autocompleteMultiple';
import './resumoApp.scss'
import { AuthContext } from '../../../contexts/auth';
import { getPrestadores } from '../../enviarTecnico/services/services';
import { Atendimento, IGetAderenciaResponse, OrganizadoPorPrestadorETecnico } from '../types';
import { FormatDateToApi, FormatDayMonthYear } from '../../../helpers/formatDate';
import { Alert, IconButton } from '@mui/material';
import Collapse from '../../../components/collapse/collapse';
import CloseIcon from "@mui/icons-material/Close";
import FullscreenLoading from '../../../components/fullscreenLoading/fullscreenLoading';

const { color } = siteTheme;

const ResumoApp = () => {

  const { setMenuLateral } = useContext(HomeContext);
  const { user } = useContext(AuthContext);

  const [contagem, setContagem] = useState<{name: string, value: string | number, label: string}[]>([])
  const [dataInicial, setDataInicial] = useState<Date | Moment>(moment(new Date()))
  const [dataFinal, setDataFinal] = useState<Date | Moment>(moment(new Date()))
  const [listaPrestadores, setListaPrestadores] = useState<{name: string, id: string}[]>([])
  const [prestadorSelected, setPrestadorSelected] = useState<{name: string, id: string }[]>([])
  const [loading, setLoading] = useState(false)

  const [versaoApp, setVersaoApp] = useState<{name: string, id: string}[]>([])
  const [totalDiario, setTotalDiario] = useState<{[data: string]: {atendimentoMobyan: number, atendimentoOppay: number}}>({})
  const [contagemDiaLista, setContagemDiaLista] = useState<{name: string, id: string}[]>([])

  const [listaUnica, setListaUnica] = useState<OrganizadoPorPrestadorETecnico>()

  const [aderencia, setAderencia] = useState<IGetAderenciaResponse[][]>([])
  const [versionAppList, setVersionAppList] = useState<{name: string, id: string}[]>([])

  const[totalMobyan, setTotalMobyan] = useState(0)
  const[totalOppay, setTotalOppay] = useState(0)

  const [openAlertError, setOpenAlertError] = useState(false);
  const [msgAlertError, setMsgAlertError] = useState("Nenhum dado encontrado");

  const getProvider = async () => {
    try {
      const { data } = await getPrestadores() as AxiosResponse

      let prestadores = [];

      if (!!data && !!data.length) {
        prestadores = data.filter((p: any) => !!p.ativo).map((m: any) => ({ ...m, name: m.nomePrestador, id: m.id?.toString() }))
          .sort((a: any, b: any) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
      }

      if (!_.isEmpty(user?.prestadores)) {
        prestadores = prestadores.filter((prestador: any) => user?.prestadores.includes(Number(prestador.id)))
      }

      setListaPrestadores(prestadores);

      if(_.isEmpty(prestadorSelected)) setPrestadorSelected(prestadores)

    } catch (e) {
      setListaPrestadores([]);
    }
  };

  const getAderenciaService = async () =>{
    setLoading(true)
    try{
      
      const { data } = await getAderencia({
        dataInicial: FormatDateToApi(dataInicial.toString()),
        dataFinal: FormatDateToApi(dataFinal.toString()),
        prestadores: user?.prestadores ? user?.prestadores : []
      }) as AxiosResponse

      setAderencia(data)

      let totalMobyanLocal: number = 0
      let totalOppayLocal: number = 0

      const dadosOrganizados: OrganizadoPorPrestadorETecnico = data.reduce((result: any, item: any) => {
        item.forEach((atendimento: any) => {
            const { idPrestador, nomePrestador, idTecnico, nomeTecnico, atendimentoMobyan, versionApp } = atendimento;
            
            if(atendimentoMobyan) {
              totalMobyanLocal += 1
            } else {
              totalOppayLocal += 1
            }


            // Organiza por prestador
            if (!result[idPrestador]) {
                result[idPrestador] = {
                    nomePrestador,
                    atendimentos: [],
                    tecnicos: {},
                };
            }
    
            result[idPrestador].atendimentos.push(atendimento);
    
            // Organiza por técnico
            if (idTecnico !== null) {
                if (!result[idPrestador].tecnicos[idTecnico]) {
                    result[idPrestador].tecnicos[idTecnico] = {
                        nomeTecnico: nomeTecnico || '',
                        atendimentos: [],
                    };
                }
    
                result[idPrestador].tecnicos[idTecnico].atendimentos.push(atendimento);
            }
        });
    
        return result;
    }, {} as OrganizadoPorPrestadorETecnico);

      let contagemDiaListaLocal: string[] = []
      let totalDia: {[data: string]: {atendimentoMobyan: number, atendimentoOppay: number}} = {}
     

      data.map((groupByPrestador: IGetAderenciaResponse[]) => {
        groupByPrestador.map(item => {
          
          if(!contagemDiaListaLocal?.includes(FormatDateToApi(item?.dataAtendimento))){
            contagemDiaListaLocal.push(FormatDateToApi(item?.dataAtendimento))
          }

          if(!totalDia[FormatDateToApi(item.dataAtendimento)]){
            if(item.atendimentoMobyan){
              totalDia[FormatDateToApi(item.dataAtendimento)] = {
                atendimentoMobyan: 1,
                atendimentoOppay: 0
              }
            } else {
              totalDia[FormatDateToApi(item.dataAtendimento)] = {
                atendimentoMobyan: 0,
                atendimentoOppay: 1
              }
            }
          } else {
            if(item.atendimentoMobyan){
              totalDia[FormatDateToApi(item.dataAtendimento)] = {
                atendimentoMobyan: totalDia[FormatDateToApi(item.dataAtendimento)].atendimentoMobyan + 1,
                atendimentoOppay: totalDia[FormatDateToApi(item.dataAtendimento)].atendimentoOppay
              }
            } else {
              totalDia[FormatDateToApi(item.dataAtendimento)] = {
                atendimentoMobyan: totalDia[FormatDateToApi(item.dataAtendimento)].atendimentoMobyan,
                atendimentoOppay: totalDia[FormatDateToApi(item.dataAtendimento)].atendimentoOppay + 1
              }
            }
          }
        })
      })

      setTotalMobyan(totalMobyanLocal)
      setTotalOppay(totalOppayLocal)
      setTotalDiario(totalDia)
      setContagemDiaLista(contagemDiaListaLocal.sort().map(item => ({name: FormatDayMonthYear(item), id: item})))
      
      setListaUnica(dadosOrganizados);
    } catch(e: any){
      setLoading(false)
      setMsgAlertError(e.response.data)
      setOpenAlertError(true)
    }
    setLoading(false)
  }

  const getResumoApp = async () => {
    try{
      const { data } = await getContagemOS({
        dataInicial: moment(dataInicial).format("YYYY-MM-DD"),
        dataFinal: moment(dataFinal).format("YYYY-MM-DD")
      }) as AxiosResponse

      const totalOs = Object.values(data?.contagemOS).reduce((partialSum: any, a: any) => partialSum + a, 0)

      const values: {name: string, value: string | number, label: string}[] = 
        Object.entries(data?.contagemOS)
         .map((resumoApp: any[]) => { 
          return {name: resumoApp[0], value: resumoApp[1], label: `${resumoApp[1]} ${ Number(totalOs) ? `(${((resumoApp[1]/Number(totalOs))*100).toFixed(2)}%)` : ''}`}
        })

      setContagem(values)

    } catch(e){
      console.log(e)
    }
  }

  const handleAplicar = () => {
    getResumoApp()
    getAderenciaService()
  }
  useEffect(() => {
    getResumoApp()
    getAderenciaService()
    getProvider()
  },[])

  return(
    <section className="ResumoApp">
      <Header setMenuLateral={() => setMenuLateral(true)} title="Resumo do App" />
      {loading && <FullscreenLoading />}
      <div className='AderenciaFiltrosContainer'>
        <AutocompleteMultiple
          dimension="sm"
          label={"Prestadores"}
          placeholder={""}
          noOptionsText={"Nenhum prestador encontrado"}
          id="multiple-checkbox-Prestadores-Farol"
          options={listaPrestadores}
          value={prestadorSelected}
          onChange={setPrestadorSelected}
          fontSize={12}
          disabled={(!!user?.prestadores && user.prestadores.length === 1)}
        />
        <AutocompleteMultiple
          dimension="sm"
          label={"Versão APP"}
          noOptionsText="Nenhuma versão encontrada"
          options={versionAppList}
          value={versaoApp}
          onChange={setVersaoApp}
          fontSize={12}
        />
       <div className='DatePickerContainer'>
          <DatePicker
           dimension='sm'
           value={dataInicial} 
           minDate={moment(new Date('2023-07-21'))} 
           onChange={setDataInicial}
           label='Data inicial'
          />
          <DatePicker
           dimension='sm'
           value={dataFinal} 
           minDate={dataInicial} 
           onChange={setDataFinal} 
           disabled={_.isEmpty(dataInicial)}
           label='Data final'
          />
        </div>
        <Button dimension='sm' onClick={handleAplicar}>Aplicar</Button>
      </div>
      <div className='ChartContainer'>
        <ResponsiveContainer width='60%' height={425}>
          <BarChart data={contagem}>
            <XAxis dataKey="name" />
            <YAxis padding={{ top: 15 }}/>
            <Bar
              radius={[10, 10, 0, 0]} 
              dataKey="value" 
              fill={color.primary} 
              isAnimationActive={false}
              barSize={100}
            >
              <LabelList
                dataKey="label" 
                position="top" 
                fontSize={16} 
                fontWeight={600} 
                color={color.textCinza}
              />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>
      <p className='TotalLabel'>Total Geral</p>
      <TableContainer component={Styles.StyledPaper} sx={{ maxWidth: 250 }}>
        <Table className='TotalGeralContainer'>
          <TableBody>
            <TableRow>
              <Styles.StyledTableCell className={`center totalTitulo`}>
                Oppay
              </Styles.StyledTableCell>
              <Styles.StyledTableCell className={`center ValorTotal`}>
                {totalOppay}
              </Styles.StyledTableCell>
            </TableRow>
            <TableRow>
              <Styles.StyledTableCell className={`center totalTitulo`}>
                Mobyan
              </Styles.StyledTableCell>
              <Styles.StyledTableCell className={`center ValorTotal`}>
                {totalMobyan}
              </Styles.StyledTableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      {!_.isEmpty(listaUnica) &&
      <TableContainer component={Styles.StyledPaper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <Styles.StyledTableCell className={`center`} />
                  {contagemDiaLista.map(item => {
                    return (
                      <>
                        <Styles.StyledTableCell className={`center`}>
                          {item.name} <br /> Oppay
                        </Styles.StyledTableCell>
                        <Styles.StyledTableCell className={`center`}>
                          {item.name} <br /> Mobyan
                        </Styles.StyledTableCell>
                      </>
                  )})}
              </TableRow>
            </TableHead>
            <TableBody>
              {!_.isEmpty(aderencia) && (
                <>
                  <TableRow className='PrestadorRow'>
                    <Styles.StyledTableCell className={`PrestadorName`}>
                      TOTAL
                    </Styles.StyledTableCell>
                    {contagemDiaLista.map((contagemMap) => {
                      return(
                        <>
                          <Styles.StyledTableCell className={`center`}>
                            {totalDiario[contagemMap.id].atendimentoOppay !== 0 ? totalDiario[contagemMap.id].atendimentoOppay : ''}
                          </Styles.StyledTableCell>
                          <Styles.StyledTableCell className={`center`}>
                            {totalDiario[contagemMap.id].atendimentoMobyan !== 0 ? totalDiario[contagemMap.id].atendimentoMobyan : ''}
                          </Styles.StyledTableCell>
                        </>
                      )
                    })}
                  </TableRow>
                  {Object.values(listaUnica)?.map(item => {
                    return (
                      <>
                        <TableRow className='PrestadorRow'>
                          <Styles.StyledTableCell className={`PrestadorName`}>{item?.nomePrestador}</Styles.StyledTableCell>
                          {contagemDiaLista.map(contagemMap => {
                            const atendimentoOppay = item.atendimentos
                            .filter((atendimentoFilter: IGetAderenciaResponse) => 
                              !atendimentoFilter.atendimentoMobyan &&
                              contagemMap.name === FormatDayMonthYear(atendimentoFilter.dataAtendimento)
                            )

                            const atendimentoMobyan = item.atendimentos
                            .filter((atendimentoFilter: IGetAderenciaResponse) => 
                              atendimentoFilter.atendimentoMobyan &&
                              contagemMap.name === FormatDayMonthYear(atendimentoFilter.dataAtendimento)
                            )
                            return (
                              <>
                                <Styles.StyledTableCell className={`center`}>
                                  {atendimentoOppay.length !== 0 ? atendimentoOppay.length : ''}
                                </Styles.StyledTableCell>
                                <Styles.StyledTableCell className={`center`}>
                                  {atendimentoMobyan.length !== 0 ? atendimentoMobyan.length : ''}
                                </Styles.StyledTableCell>
                              </>
                            )
                          })}
                        </TableRow>
                        {Object.values(item.tecnicos)
                          .sort((a: any, b: any) => (a.nomeTecnico > b.nomeTecnico) ? 1 : ((b.nomeTecnico > a.nomeTecnico) ? -1 : 0))
                          .map((tecnico: any) => {
                            return(
                              tecnico.nomeTecnico !== '' &&
                              <TableRow>
                                <Styles.StyledTableCell className={`center`}>{tecnico.nomeTecnico}</Styles.StyledTableCell>
                                {contagemDiaLista.map((contagemMap) => {
                                  
                                  const atendimentoOppay = tecnico.atendimentos.filter( (atendimentoFilter: IGetAderenciaResponse) =>
                                  !atendimentoFilter.atendimentoMobyan &&
                                  contagemMap.name === FormatDayMonthYear(atendimentoFilter.dataAtendimento)
                                  )
      
                                  const atendimentoMobyan = tecnico.atendimentos.filter( (atendimentoFilter: IGetAderenciaResponse) =>
                                  atendimentoFilter.atendimentoMobyan &&
                                  contagemMap.name === FormatDayMonthYear(atendimentoFilter.dataAtendimento)
                                  )
                                  return (
                                    <>
                                      <Styles.StyledTableCell className={`center`}>
                                        {atendimentoOppay.length !== 0 ? atendimentoOppay.length : ''}
                                      </Styles.StyledTableCell>
                                      <Styles.StyledTableCell className={`center`}>
                                        {atendimentoMobyan.length !== 0 ? atendimentoMobyan.length : ''}
                                      </Styles.StyledTableCell>
                                    </>
                                  )
                                })}
                              </TableRow>
                              
                            )
                          }
                        
                        )}
                      </>
                    )
                  })}
                </>
              )}
            </TableBody>
          </Table>
      </TableContainer>
      }
       <Collapse in={openAlertError} onClose={setOpenAlertError}>
          <Alert
            severity='error'
            icon={<div />}
            variant="filled"
            sx={{
              mb: 2,
              bgcolor: "rgb(255, 35, 35, 0.7)",
              borderRadius: "15px",
              boxSizing: "border-box",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: '50px',
            }}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="medium"
                onClick={() => setOpenAlertError(false)}
              >
                <CloseIcon
                  id={"ModalEncaminharOS-AlertCloseIcon"}
                  fontSize="inherit"
                  sx={{
                    color: "white",
                  }}
                />
              </IconButton>
            }
          >
            <span
              style={{
                color: "white",
                fontSize: "16px",
              }}
              dangerouslySetInnerHTML={{ __html: msgAlertError }}
            />
          </Alert>
        </Collapse>
    </section>
  )
}

export default ResumoApp