import { apiDelete, apiGet, apiPost, apiPut } from "../../../services/api";
import { TermoPostPut } from "../types";

const baseUrl = process.env.REACT_APP_API_ORDEMSERVICO;

export const consultarTermos = async (idContratante: number) =>
    await apiGet(`${baseUrl}/termoOrdemServico?IdContratante=${idContratante}`);


export const salvarTermo = async (termo: TermoPostPut) =>
    await apiPost(`${baseUrl}/termoOrdemServico`,termo);


export const atualizarTermo = async (termo: TermoPostPut,idTermo:number) =>
    await apiPut(`${baseUrl}/termoOrdemServico/${idTermo}`,termo);


export const excluirTermo = async (idTermo:number) =>
    await apiDelete(`${baseUrl}/termoOrdemServico/${idTermo}`);