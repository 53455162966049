import { apiGet, apiPost } from "../../../services/api";

const orderServicoUrl = process.env.REACT_APP_API_ORDEMSERVICO;

export interface IPostGetListaOSFarol {
    chamadoReferencia?: string
    prestadores: number[]
    contratante: number[]
    status: number[]
    grupoDeServicos: number[]
    servico: number[]
    tecnico: number[]
    estados: number[]
    cidades: number[]
    tipoSLA: number
    pageNumber: number
    pageSize: number
};

export const getListaOSs = async (params: IPostGetListaOSFarol) => {
    return apiPost(`${orderServicoUrl}/OrdemServico_/farol`, params);
};
