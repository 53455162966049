import React, { useEffect, useContext, useRef } from "react";
import { Outlet } from "react-router-dom";
import "./home.scss";
import { HomeProvider, HomeContext } from "../../../contexts/homeContext";
import MenuLateralComponent from "../../../components/menuLateral/menuLateral";
import { ThemeProvider } from "styled-components";
import theme from "../../../styles/theme";

const HomeComponent = () => {
  const { menuLateral, setMenuLateral } = useContext(HomeContext);

  function useOutsideAlerter(ref: React.RefObject<HTMLElement>) {
    useEffect(() => {
      function handleClickOutside(event: MouseEvent) {
        const target = event.target as HTMLElement;
        if (ref.current && !ref.current.contains(target)) {
          setMenuLateral(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  return (
    <ThemeProvider theme={theme}>
      <div className="Home">
        {process.env.REACT_APP_AMBIENTE !== "PROD" && (
          <div className="Ambiente">{process.env.REACT_APP_AMBIENTE}</div>
        )}

        <div ref={wrapperRef}>
          <MenuLateralComponent />
        </div>

        <main className="Home-VisaoInicial">
          <div className="wrapper">
            <Outlet />
          </div>
        </main>
      </div>
    </ThemeProvider>
  );
};

const Home = () => {
  return (
    <HomeProvider>
      <HomeComponent />
    </HomeProvider>
  );
};

export default Home;
